import React, { useState, useRef } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "devextreme-react";
import { BASEPATH } from "../../../../config/config";
import { jwtDecode } from "jwt-decode";
const BulkMemberModal = ({ show, handleClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const resetFile = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const downloadTemplate = async () => {
    try {
      const response = await axios.get(
        `${BASEPATH}mailer/members/get_bulk_upload_template`
      );

      // ---------------------- Extract the Base64 string----------------------
      const base64Data = response.data.data;
      //-----------------------Decode Base64 string--------------------------------
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      //------------------- Create a Blob from the byte array--------------------------
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      console.log(blob, "Base64Data");
      //------------------Create a link element to trigger the download------------------
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.data.file_name); // The filename to be downloaded
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the template:", error);
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    try {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = async () => {
        const base64Data = reader.result.split(",")[1];

        const payload = {
          login_id: user?.login_id,
          role_id: user?.role_id,
          upload_data: base64Data,
        };

        await axios.post(
          `${BASEPATH}mailer/members/bulk_upload_by_template`,
          payload
        );
        alert("File uploaded successfully!");
        handleClose();
        resetFile();
      };
    } catch (error) {
      console.error("Error uploading the file:", error);
    }
  };

  const handleCloseModal = () => {
    handleClose();
    resetFile();
  };

  return (
    <Modal show={show} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Bulk Upload Members</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-row justify-content-between mt-5">
          <div className="text-center iq-button">
            <button
              className="btn btn-success rounded py-2"
              onClick={downloadTemplate}
            >
              Download Bulk Upload Format
            </button>
          </div>
        </div>
        <div className="col-lg-12 mt-5">
          <div className="col-lg-10 mx-auto">
            <div className="wrappers">
              <header>CSV Uploader</header>
              <form action="#">
                <input
                  className="file-input"
                  type="file"
                  name="file"
                  ref={fileInputRef}
                  hidden
                  onChange={handleFileChange}
                />
                <div
                  className="drop-zone"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                  onClick={handleClick}
                >
                  <i className="fas fa-cloud-upload-alt"></i>

                  {selectedFile ? (
                    <p className="text-center mt-2">
                      Uploaded file: {selectedFile.name}
                    </p>
                  ) : (
                    <p className="mx-auto text-center"> browse CSV to upload</p>
                  )}
                </div>
              </form>
              <section className="progress-area"></section>
              <section className="uploaded-area"></section>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="secondary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkMemberModal;
