import React from "react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import {
  GET_MY_VIDEOS,
  GET_THUMBNAIL_VIDEOS_IN_UPLOAD,
} from "../../../Api/api";
import { BASEPATH } from "../../../config/config";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import { IMAGE_URL, VideoRecords } from "../../../Subpages/MainPage/constant";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../global/NoDataFound";
import Noimage from "../../../global/NoImage";


export default function MyVideos_myprofile() {
  const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);

  const token = localStorage.getItem("MDnD_auth");
  const navigate = useNavigate();

  const [thumbnail, setThumbnail] = useState({});
  const [profilephoto, setProfilephoto] = useState({});
  const [videoCollection, setVideoCollection] = useState({ finalData: "" });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      mutationgetMyVideos.mutate();
    }
  }, [user]);

  const GetallVideos = () => GET_VIDEOS_FROM_BACKEND(GET_MY_VIDEOS);

  const GET_VIDEOS_FROM_BACKEND = async (GET_MY_VIDEOS) => {
    let param1 = user ? user.role_id : "";
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_MY_VIDEOS}/${param1}`,
        headers: { MDnD_auth: `${token}` },
        timeout: 60000,
        responseType: "json",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetMyVideos = useMutation({
    mutationKey: ["get_All_MyVideos"],
    mutationFn: () => GetallVideos(),
    onSuccess: (data) => {
      setIsLoading(false);
      if (data.status === "success") {
        setVideoCollection({ finalData: data.data });
      } else {
        setVideoCollection({ finalData: "" });
      }
    },
    onError: (error) => {
      setIsLoading(false);
      setVideoCollection({ finalData: "" });
      console.error(error);
    },
  });

  useEffect(() => {
    const fetchThumbnail = async (item, index) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${BASEPATH}${GET_THUMBNAIL_VIDEOS_IN_UPLOAD}/${item.id}`,
          headers: { MDnD_auth: `${token}` },
          timeout: 60000,
          responseType: "json",
        });
        if (response.data.status === "success") {
          const base64Data = response.data.data.thumbnail;
          const dataURL = `data:image/jpeg;base64,${base64Data}`;
          const blob = await (await fetch(dataURL)).blob();
          const url = URL.createObjectURL(blob);
          if (url) {
            setThumbnail((prevThumbnail) => ({
              ...prevThumbnail,
              [index]: url,
            }));
          }
        } else {
          setThumbnail((prevThumbnail) => ({
            ...prevThumbnail,
            [index]: "",
          }));
        }
      } catch (error) {
        console.error("Thumbnail pictures cannot be fetched", error);
        setThumbnail((prevThumbnail) => ({
          ...prevThumbnail,
          [index]: "",
        }));
      }
    };

    if (videoCollection?.finalData && videoCollection.finalData?.length > 0) {
      videoCollection.finalData.forEach((item, index) => {
        fetchThumbnail(item, index);
      });
    }
  }, [videoCollection.finalData]);

  useEffect(() => {
    const fetchProfilePic = async (item, index) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${BASEPATH}profiles/get_user_profile/${item.role_id}`,
          headers: { MDnD_auth: `${token}` },
          timeout: 60000,
          responseType: "json",
        });
        if (response.data.status === "success") {
          const base64Data = response.data.data.profile_image;
          const dataURL = `data:image/jpeg;base64,${base64Data}`;
          const blob = await (await fetch(dataURL)).blob();
          const url = URL.createObjectURL(blob);
          if (url) {
            setProfilephoto((prev) => ({
              ...prev,
              [index]: url,
            }));
          }
        } else {
          setProfilephoto((prev) => ({
            ...prev,
            [index]: "",
          }));
        }
      } catch (error) {
        console.error("Profile picture cannot be fetched", error);
        setProfilephoto((prev) => ({
          ...prev,
          [index]: "",
        }));
      }
    };

    if (videoCollection.finalData && videoCollection.finalData.length > 0) {
      videoCollection.finalData.forEach((item, index) => {
        fetchProfilePic(item, index);
      });
    }
  }, [videoCollection.finalData]);

  const handleMDNDRecords = (item) => {
    const json_string = JSON.stringify({
      video_id: item?.id,
      role_id: item?.role_id ?? null,
      maincatid: item?.main_category_id,
    });
    const slug = btoa(json_string);
    navigate(`/RecordsVideo/videos/${slug}`);
  };

  function timeDifference(createdAt) {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);

    const diffMs = currentDate - createdDate;
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    if (diffSeconds < 60) {
      return `${diffSeconds} seconds ago`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hours ago`;
    } else if (diffDays < 7) {
      return `${diffDays} days ago`;
    } else if (diffWeeks < 4) {
      return `${diffWeeks} weeks ago`;
    } else if (diffMonths < 12) {
      return `${diffMonths} months ago`;
    } else {
      return `${diffYears} years ago`;
    }
  }

  const [ThumbnailImageError, setThumbnailImageError] = useState(false);
  const handleThumbnailImageError_myvideo = () => {
    setThumbnailImageError(true);
  };
  const handleThumbnailImageSuccess_myvideo = () => {
    setThumbnailImageError(false);
  };

  const [profileError, setprofileError] = useState(false);
  const handleprofileError_myvideo = () => {
    setprofileError(true);
  };
  const handleprofileSuccess_myvideo = () => {
    setprofileError(false);
  };

  return (
    <>
      <div className="overflow-hidden">
        <div className="d-flex align-items-center justify-content-between my-2">
        </div>

        {isLoading ? (
          <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 g-4">
            {[...Array(8)].map((_, index) => (
              <div className="col" key={index}>
                <div className="iq-watching-block mb-lg-3">
                  <div className="block-images position-relative">
                    <div
                      className="iq-image-box overly-images w-100"
                      style={{ height: "100%" }}
                    >
                      <Skeleton height={180} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between p-2 align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="videoProfileImg overflow-hidden"
                        style={{ width: "36px", height: "36px" }}
                      >
                        <Skeleton circle height={36} width={36} />
                      </div>
                      <div>
                        <Skeleton width={150} />
                        <Skeleton width={100} />
                        <Skeleton width={80} />
                      </div>
                    </div>
                    <div>
                      <Skeleton width={20} height={20} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : videoCollection?.finalData && videoCollection?.finalData?.length > 0 ? (
          <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3 row-cols-xxl-4 g-4">
            {videoCollection.finalData.map((item, index) => (
              <div className="col" key={index}>
                <div className="iq-watching-block mb-lg-3">
                  <div className="block-images position-relative">
                    <div
                      className="iq-image-box overly-images w-100"
                      style={{ height: "100%" }}
                    >
                      {thumbnail[index] ? (
                        <img
                          src={thumbnail[index] || ""}
                          onClick={() => handleMDNDRecords(item)}
                          className="img-fluid object-cover w-100 d-block border-0"
                          //onError={handleThumbnailImageError_myvideo}
                          //onLoad={handleThumbnailImageSuccess_myvideo}
                        />
                      ) : (
                        <div onClick={() => handleMDNDRecords(item)}>
                          <Noimage />
                        </div>
                      )}
                    </div>
                    <div className="iq-preogress">
                      <div className="d-flex justify-content-between px-2 mb-1"></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between p-2 align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <div
                        className="videoProfileImg overflow-hidden"
                        style={{ width: "36px", height: "36px" }}
                      >
                        {profilephoto[index]  ? (
                          <div style={{ width: "36px", height: "36px" }}>
                            <img
                              src={profilephoto[index] || ""}
                              className="img-fluid"
                              style={{ width: "36px", height: "36px" }}
                              //onError={handleprofileError_myvideo}
                              //onLoad={handleprofileSuccess_myvideo}
                            />
                          </div>
                        ) : (
                          <div
                            style={{ width: "36px", height: "36px" }}
                            onClick={() => handleMDNDRecords(item)}
                          >
                            <Noimage />
                          </div>
                        )}
                      </div>
                      <div>
                        <h6 className="m-0 p-0 overflow-hidden" style={{ height: "22px" }}>
                          <b>{item?.video_title}</b>
                        </h6>
                        <p className="m-0 p-0">{item?.publisher?.profile_name}</p>
                        <p className="m-0 p-0 videoMinuts">
                          {timeDifference(item?.created_at)}
                        </p>
                      </div>
                    </div>
                    <div>
                      <i className="fas fa-ellipsis-v fs-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
}
