import React from "react";
import { HeadProvider, Meta } from 'react-head';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, } from 'react-share';
import { BASEPATH } from "../../../config/config";
import { Helmet } from 'react-helmet';
import { useRef } from "react";

export default function ShareToSocialMedia_myprofile({ postdescr, postimage, shareUrl }) {

  //const { postdescr, postimage } = props;
  //const shareUrl = `${BASEPATH}/MyProfile`;


  // useEffect(() => {

  //   const ogUrl = document.querySelector('meta[property="og:url"]');
  //   const ogType = document.querySelector('meta[property="og:type"]');
  //   const ogTitle = document.querySelector('meta[property="og:title"]');
  //   const ogDescription = document.querySelector('meta[property="og:description"]');
  //   const ogImage = document.querySelector('meta[property="og:image"]');

  //   if (ogUrl) ogUrl.setAttribute("content", shareUrl);
  //   if (ogType) ogType.setAttribute("content", "website");
  //   if (ogTitle) ogTitle.setAttribute("content", postdescr);
  //   if (ogDescription) ogDescription.setAttribute("content", postdescr);
  //   if (ogImage && postimage) ogImage.setAttribute("content", postimage);
  // }, [postdescr, postimage, shareUrl]);

  // new code..

  const handleOpenWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? `whatsapp://send?text=${encodeURIComponent(shareUrl)}`
      : `https://web.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`;
    window.open(whatsappUrl, '_blank');
  };

  //preview not working in whatsapp

  const handleOpenInstagram = () => {
    window.open('https://www.instagram.com/', '_blank');
  };

  // const isUrl = (text) => {
  //   const urlRegex = /^(https?:\/\/)?([\da-z\.-]+\.[a-z\.]{2,6})([\/\w \.-]*)*\/?$/i;
  //   return urlRegex.test(text);
  // };

  const isUrl = (text) => {
    try {
      new URL(text);
      return true;
    } catch (e) {
      return false;
    }
  }

  const ogUrl = isUrl(postdescr) ? postdescr : shareUrl;

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputRef.current.value).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const inputRef = useRef(null);

  return (
    <>
       {/* <Helmet>
        <meta property="og:url" content={ogUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={postdescr || "Check out this post!"} />
        <meta property="og:description" content={postdescr || "Check out this content!"} />
        <meta property="og:image" content={postimage} />
        <meta property="og:video" content={ogUrl} />
        <meta property="og:image:width" content="600" /> 
        <meta property="og:image:height" content="315" /> 
        <meta property="og:video:width" content="640" />
      <meta property="og:video:height" content="360" />
      <meta property="og:video:type" content="text/html" />
      </Helmet>  */}

{/* <HeadProvider>
  <Meta property="og:url" content={ogUrl} />
  <Meta property="og:type" content="website" />
  <Meta property="og:title" content={postdescr} />
  <Meta property="og:description" content={postdescr} />
  <Meta property="og:image" content={postimage} />
  <Meta property="og:image:width" content="600" />
  <Meta property="og:image:height" content="315" />
  <Meta property="og:video" content={ogUrl} />
  <Meta property="og:video:width" content="640" />
  <Meta property="og:video:height" content="360" />
  <Meta property="og:video:type" content="text/html" />
</HeadProvider> */}
   
      <div>
        <div className="d-flex gap-3 justify-content-around mx-3">

        {isUrl(postdescr) ? (
          <>
            
            <WhatsappShareButton style={{ color: "green" }} url={postdescr}>
              <i
                className="fa-brands fa-whatsapp"
                style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              ></i>
            </WhatsappShareButton>
            <FacebookShareButton style={{color: "blue", }} url={postdescr}>
            
           <i className="fa-brands fa-facebook" style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}></i>
                                      
            </FacebookShareButton>
            <TwitterShareButton style={{color: "black"}} url={postdescr}>
              <i className="fa-brands fa-x-twitter"
              style={{
                fontSize: "40px",
                cursor: "pointer",
              }}></i>
            </TwitterShareButton>
          </>
        ) : (
          <>
            
            <WhatsappShareButton style={{color: "green"}} url={shareUrl}>
            <i
                                          className="fa-brands fa-whatsapp"
                                          style={{
                                            fontSize: "40px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
            </WhatsappShareButton>

            <FacebookShareButton style={{color: "blue"}} url={shareUrl} >
            <i className="fa-brands fa-facebook" style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}></i>
            </FacebookShareButton>
            <TwitterShareButton style={{color: "black"}} url={shareUrl} >
            
              <i className="fa-brands fa-x-twitter" style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}></i>
              </TwitterShareButton>
          </>
        )}
        </div>

           <div className="copy-link-container">
                        <div className="copy-header"></div>
                        <div className="copy-link">
                          <div className="copy-link-inner">
                            <form onSubmit={handleCopy} className="d-flex">
                              <input
                                type="text"
                                value={shareUrl}
                                ref={inputRef}
                                className="w-100 text-nowrap overflow-hidden"
                                onClick={() => inputRef.current.select()}
                                readOnly
                              />
                              <input
                                type="submit"
                                className="text-nowrap"
                                value="Copy"
                              />
                            </form>
                          </div>
                        </div>
                      </div>





      </div>
    </>

  )

}