import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton'; // Import the skeleton loader
import 'react-loading-skeleton/dist/skeleton.css'; // Import the skeleton CSS
import CreateNewTempletes from './CreateNewTempletes';
import TemplateDetailsModal from './TamplateDetailsModal';
import { jwtDecode } from 'jwt-decode';
import { BASEPATH } from '../../../../config/config';
import axios from 'axios';
import "../mailer.css";

function Templates() {
  const [templates, setTemplates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [allDataFetched, setAllDataFetched] = useState(false);
  const limit = 7; // Number of items to fetch per request
  const user = localStorage.getItem("MDnD_auth")
    ? jwtDecode(localStorage.getItem("MDnD_auth"))
    : null;
  const role_id = user?.role_id;

  const handleCreateClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTemplate(null);
  };

  const handleTemplateClick = (event, template) => {
    event.preventDefault();
    event.stopPropagation();
    setSelectedTemplate(template);
  };

  const fetchTemplates = useCallback(async () => {
    if (loading || allDataFetched) return;

    setLoading(true);

    try {
      const token = localStorage.getItem('MDnD_auth');
      const response = await axios.get(
        `${BASEPATH}mailer/promo_template/${role_id}/read_all/${limit}/${offset}`,
        {
          headers: {
            'MDnD_auth': token,
            'Content-Type': 'application/json',
          },
        }
      );

      const newTemplates = response.data.data.map((template) => ({
        ...template,
        renderedContent: template.content && template.content.status !== 404 ? decodeBase64(template.content) : 'No content available',
      }));

      if (newTemplates.length < limit) {
        setAllDataFetched(true); // No more data to fetch
      }

      setTemplates((prevTemplates) => [...prevTemplates, ...newTemplates]);

      // Update the offset after successful data fetch
      setOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      console.error('Error fetching templates:', error);
    }

    setLoading(false);
  }, [offset, loading, allDataFetched, role_id]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 100 >= document.documentElement.scrollHeight &&
      !loading &&
      !allDataFetched
    ) {
      fetchTemplates();
    }
  }, [loading, allDataFetched, fetchTemplates]);

  const decodeBase64 = (content) => {
    try {
      return atob(content);
    } catch (error) {
      console.error('Error decoding base64:', error);
      return 'Error decoding content';
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchTemplates(); 
  }, [fetchTemplates]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="w-100 bg-primary"></div>
      </div>
      <div className="d-flex flex-wrap">
        <div className="col-12 col-lg-6 col-xl-4 col-xxl-3 p-2">
          <div className="h-15 create-template" onClick={handleCreateClick}>
            <div className="fa fa-plus fa-lg" />
            <div className="text-black">Create New Template</div>
          </div>
        </div>
        {loading
          ? Array(limit).fill().map((_, idx) => ( // Show skeleton loader when loading
              <div key={idx} className="col-12 col-lg-6 col-xl-4 col-xxl-3 p-2">
                <Skeleton height={200} /> {/* Skeleton loader height */}
                <Skeleton count={2} /> {/* Skeleton lines */}
              </div>
            ))
          : templates.map((template) => (
            <div key={template.id} className="col-12 col-lg-6 col-xl-4 col-xxl-3 p-2">
              <div className="h-15 template" onClick={(event) => handleTemplateClick(event, template)}>
                <img className="temp-image" src={template.imageUrl} alt="" />
                <div className="temp-content">
                  <div className="temp-name">{template.name}</div>
                  <div className="temp-detail">
                    <div>Status: {template.status}</div>
                    <div dangerouslySetInnerHTML={{ __html: template.renderedContent }} />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <CreateNewTempletes show={showModal} handleClose={handleCloseModal} />
      <TemplateDetailsModal show={!!selectedTemplate} handleClose={handleCloseModal} template={selectedTemplate} />
    </>
  );
}

export default Templates;
