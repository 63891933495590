import React from "react";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { BASEPATH } from "../../../config/config";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import { useMutation } from "@tanstack/react-query";
import { GET_TAGGEDBLOGS } from "../../../Api/api";
import AuthContext from "../../../context/AuthContext";
import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Scrolling,
    Form,
    SearchPanel,
    Export,
    Selection,
} from 'devextreme-react/data-grid';
import { IoIosEye } from 'react-icons/io';

export default function TaggedBlogs_artist(props) {

    let { roleid } = props;
    const token= localStorage.getItem("MDnD_auth");

    //console.log(roleid, "roleid from pervious");
    const navigate = useNavigate();
    const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);

    const [taggedBlogsCollectionOrganiser, setTaggedBlogsCollectionOrganiser] = useState({
        taggedBlogs: "",
    })

    useEffect(() => {
        if (user && roleid) {
            mutationGetTaggedBlogs.mutate();
        }
    }, [roleid])

    const GetTaggedBlogs = () =>
        GET_TAGGEDBLOGS_FROM_BACKEND(GET_TAGGEDBLOGS);

    const GET_TAGGEDBLOGS_FROM_BACKEND = async (
        GET_TAGGEDBLOGS) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_TAGGEDBLOGS}`,

                data: {
                    master_role_id: 2,
                    user_role_id: roleid ? roleid : ""


                },
                headers: {
                    'MDnD_auth': `${token}`, 
                   
                },
    
                timeout: 60000,
                responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationGetTaggedBlogs = useMutation({
        mutationKey: ["get_tagged_blogs"],
        mutationFn: () => GetTaggedBlogs(),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {
                //console.log(data,"dt");
                //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
                //SuccessNewToaster("success", "Message Sent Successfully!", 3000);


                let filteredArray = data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

                setTaggedBlogsCollectionOrganiser((prevState) => ({ ...prevState, taggedBlogs: filteredArray }));
            }
            else {
                setTaggedBlogsCollectionOrganiser((prevState) => ({ ...prevState, taggedBlogs: "" }));
            }
        },
        onError: (error) => {
            //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            HandleErrorToast("error", "Tagged Blogs Could not be Fetched. Something went Wrong!", 3000);
            setTaggedBlogsCollectionOrganiser((prevState) => ({ ...prevState, taggedBlogs: "" }));
        },
    });

    //console.log(taggedBlogsCollectionOrganiser, "taggedBlogsCollectionOrganiser")

    const actionButtonTemplate = (cellData) => {
        // const buttonStyle = {
        //   backgroundColor: "#FFDAB9",
        // };

        const buttonStyle = {
            backgroundColor: "blue", 
            color: "white", 
            borderRadius: "50%", 
            padding: "5px" 
          };
    
        return (
          <IoIosEye
           
            stylingMode="contained"
            //elementAttr={{ style: buttonStyle }}
            style={buttonStyle}
            //className="btn py-2"
            onClick={() => handleActionButtonClick(cellData.data)}
          />
        );
      };

      const handleActionButtonClick = async (rowData) => {
        //console.log(rowData,"rowData");

        navigate(`/blog/${rowData.blog_name}`, {
            state: { blogid: rowData.blog_id }
          });


      }

      const renderBlogPhoto = ({ data }) => {
        const imageUrl = `${BASEPATH}public/images/blog_images/${data.blog_photo}`;
        return (
          <img
            src={imageUrl}
            alt="Blog Photo"
            style={{ width: '50px', height: '50px', cursor: 'pointer', borderRadius: '50%' }}
            onClick={() => handleImageClick(data.blog_id, data.blog_name)}
          />
        );
      };

      const renderDate = ({ data }) => {

        const date = new Date(data.created_date);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleImageClick = async (blogId, blogname) => {

        //console.log(blogId,"BLOG ID");

        
        //window.location.href = `${BASEPATH}blog/get_blog/${blogId}`;
        navigate(`/blog/${blogname}`, {
            state: { blogid: blogId }
          });

      }


    return (
        <div>
            {user &&

                <DataGrid
                    dataSource={taggedBlogsCollectionOrganiser.taggedBlogs || []}
                    //dataSource={""}
                    showBorders={true}
                    //onRowInserting={(e) => handleRowInsert(e)}

                    defaultPageSize={7}

                    //onRowUpdated={(e) => handleRowUpdate(e)}
                    paging={{ pageSize: 3 }}
                    rowAlternationEnabled={true}
                    columnHidingEnabled={true}
                    selectionFilter={true}>
                    <Paging enabled={true} />

                    <Column
        caption="S.no"
        cellRender={(cellInfo) => {
          const { rowIndex, component } = cellInfo;
          const pageSize = component.pageSize();
          const pageIndex = component.pageIndex();
          const totalCount = component.totalCount();

          
          const descendingIndex = totalCount - (pageIndex * pageSize + rowIndex);

          return descendingIndex;
        }}
        width={60}
        allowEditing={false}
      />

                    <Column
                        caption="Blog ID"

                        dataField="blog_id"
                        //width={50} 
                        //alignment="center" 
                        visible={false}
                        allowEditing={false} />
                    <Column
                        caption="Date"
                        cellRender={renderDate}
                        dataField="created_date"
                        //width={50} 
                        //alignment="center" 
                        allowEditing={false} />


                    <Column
                        dataField="blog_name"
                        caption="Blog Name"
                    //width={20} 
                    />

                    <Column
                        dataField="blog_write_by"
                        caption="Blog Created By"
                    //width={20} 
                    />

                    <Column
                        dataField="blog_photo"
                        caption="Blog image"
                        cellRender={renderBlogPhoto}
                    //width={20} 
                    />
                    <Column
                        alignment="center"
                        caption="View"
                        minWidth={70}
                        cellRender={actionButtonTemplate}
                    />



                </DataGrid>



            }



        </div>
    )
}