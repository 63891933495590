import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../../../../global/ToastMsgConstants.tsx";


const blogValidationSchema = Yup.object().shape({
    blogtitle: Yup.string().required(COMMONFIELDREQUIRED),
    blogmaincate: Yup.string().required(COMMONFIELDREQUIRED),
    blogcontent: Yup.string().required(COMMONFIELDREQUIRED),
    // blogtagart: Yup.string().required(COMMONFIELDREQUIRED),
    // blogtagorg: Yup.string().required(COMMONFIELDREQUIRED),
    blogimage:Yup.string().required("Choose image")
})


export default blogValidationSchema;