import { makePostRequest } from "../../../../Api/requestMethods";
import { BLOG_DETAILS_ORGANISERS,ARTIST_DETAILS } from "../../../../Api/api";


const TagedOrganiserDetailFetch = async (tagartist) => {
    try {
        const response = await makePostRequest(BLOG_DETAILS_ORGANISERS(),tagartist);
        return response;
    } catch (error) {
        throw error
    }

}

const TagArtistDetailFetch = async (accompanistDetails) => {
    try {
        const response = await makePostRequest(ARTIST_DETAILS(), accompanistDetails);
        return response;
    } catch (error) {
        throw error
    }

}

export{
    TagedOrganiserDetailFetch,
    TagArtistDetailFetch
}