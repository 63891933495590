import React from 'react';
import "./styles/Loader.css"

const Loading = () => {
  return (
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  );
}

export default Loading