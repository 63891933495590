import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { TextBox, SelectBox, DateBox, Button } from 'devextreme-react';
import { useFormik } from 'formik';
import { makeGetRequest, makePostRequest } from '../../../../Api/requestMethods';
import { CITY, COUNTRY, MEMBER_LIST, STATE, SUBMIT_MEMBERS_DETAIL } from '../../../../Api/api';
import { DURATION, ERROR, SUCCESS } from '../../../../global/ToastMsgConstants.tsx';
import notify from 'devextreme/ui/notify';
import { useMutation, useQuery } from '@tanstack/react-query';
import {jwtDecode} from 'jwt-decode';
import membershipValidationSchema from './Validation.js';



const initialValues = {
  membership_id: "",
  member_name: "",
  member_email: "",
  membership_type: "",
  member_mobile: "",
  member_address: "",
  membership_date: "",
  country: "",
  state: "",
  city: "",
  ticket_qty: "",
  status: "Y"
};

const IndividualMemberModal = ({ show, handleClose }) => {
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const roleid = user?.role_id;
  const loginid = user?.login_id;
  const [dataSource, setDataSource] = useState(initialValues);
  const [focusInput, setFocusInput] = useState({
    countryFocusIn: false,
    stateFocusIn: false,
    cityFocusIn: false,
    memberListIn: false,
    hasFocusedIn: false,
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues,
    validationSchema: membershipValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const updatedValues = {
        ...values,
        role_id: roleid,
        login_id: loginid
      };
      try {
        await memberPostMutationDetail.mutateAsync(updatedValues);
      } catch (error) {
        console.log('Error:', error);
      } finally {
        setSubmitting(false);
        resetForm();
      }
    },
  });

  const MemberPostFunction = async (data) => {
    try {
      const endPoint = await makePostRequest(SUBMIT_MEMBERS_DETAIL(), data);
      return endPoint;
    } catch (error) {
      throw error;
    }
  }

  const memberPostMutationDetail = useMutation({
    mutationKey: ["memberDetail"],
    mutationFn: (memberdetail) => MemberPostFunction(memberdetail),
    onSuccess: (data) => {
      if (data.status === 200) {
        notify(data.message, SUCCESS, DURATION);
      } else {
        notify(data.message, ERROR, DURATION);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  });

  const countryMember = async () => {
    if (focusInput.countryFocusIn) {
      const response = await makeGetRequest(COUNTRY());
      return response.data;
    }
    return [];
  };

  const { data: countryDataSource } = useQuery({
    queryKey: ["country"],
    queryFn: () => countryMember(),
    enabled: focusInput.countryFocusIn,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    }
  });

  useEffect(() => {
    if (focusInput.countryFocusIn) {
      console.log("Fetching countries data...");
    }
    console.log("Country data source:", countryDataSource);
  }, [focusInput.countryFocusIn, countryDataSource]);

  const stateFetchForMember = async (countryId) => {
    try {
      const response = await makePostRequest(STATE(), countryId);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const stateMutation = useMutation({
    mutationKey: ["fetchmemberState"],
    mutationFn: (country_id) => stateFetchForMember(country_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, state: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const cityFetchForMember = async (state_id) => {
    try {
      const response = await makePostRequest(CITY(), state_id);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const cityMutation = useMutation({
    mutationKey: ["fetchmembercity"],
    mutationFn: (state_id) => cityFetchForMember(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, city: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (values.country && values.country !== " " && values.country !== null) {
      const body = {
        country_id: values.country,
      };
      stateMutation.mutate(body);
    }
  }, [values.country]);

  useEffect(() => {
    if (values.state && values.state !== " " && values.state !== null) {
      const body = {
        state_id: values.state,
      };
      cityMutation.mutate(body);
    }
  }, [values.state]);

  const GET_MEMBERS_DETAIL = `mailer/members/${roleid}/read_groups`;

  const fetchingMember = async () => {
    if (focusInput.memberListIn) {
      const response = await makeGetRequest(GET_MEMBERS_DETAIL);
      console.log(response); // Log the response
      return response.data;
    }
    return [];
  };

  // const { data: memberDetail } = useQuery({
  //   queryKey: ["membersDetail"],
  //   queryFn: () => fetchingMember(),
  //   enabled: focusInput.memberListIn,
  //   onError: (errors) => {
  //     console.log(errors);
  //   }
  // });

  // useEffect(() => {
  //   console.log('Member detail:', memberDetail); // Log the fetched member details
  // }, [memberDetail]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Add Individual Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row row-cols-1 row-cols-lg-2">
          <div className="form-group mb-2">
            <TextBox
              type="text"
              name="membership_id"
              className="dx-field-item-required"
              required="required"
              label="Member ID"
              labelMode="floating"
              value={values.membership_id}
              onValueChanged={(e) => handleChange({ target: { name: "membership_id", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "membership_id", value: e.value } })}
            />
            {errors.membership_id && touched.membership_id && (<p className="error-message">{errors.membership_id}</p>)}
          </div>
          <div className="form-group mb-2">
            <TextBox
              type="text"
              name="member_name"
              className="dx-field-item-required"
              required="required"
              labelMode="floating"
              label="Member Name"
              value={values.member_name}
              onValueChanged={(e) => handleChange({ target: { name: "member_name", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "member_name", value: e.value } })}
            />
            {errors.member_name && touched.member_name && (<p className="error-message">{errors.member_name}</p>)}
          </div>
          <div className="form-group mb-2">
            <TextBox
              className="dx-field-item-required"
              required="required"
              valueExpr="membership_type"
              displayExpr="membership_type"
              labelMode="floating"
              label="Member Type"
              value={values.membership_type}
              onValueChanged={(e) => handleChange({ target: { name: "membership_type", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "membership_type", value: e.value } })}
            />
            {errors.membership_type && touched.membership_type && (<p className="error-message">{errors.membership_type}</p>)}
          </div>
          <div className="form-group mb-2">
            <DateBox
              type="date"
              className="dx-field-item-required"
              label="Membership Date"
              labelMode="floating"
              value={values.membership_date}
              onValueChanged={(e) => handleChange({ target: { name: "membership_date", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "membership_date", value: e.value } })}
            />
            {errors.membership_date && touched.membership_date && (<p className="error-message">{errors.membership_date}</p>)}
          </div>
          <div className="form-group mb-2">
            <TextBox
              type="text"
              name="member_email"
              className="dx-field-item-required"
              required="required"
              labelMode="floating"
              label="E-mail ID"
              value={values.member_email}
              onValueChanged={(e) => handleChange({ target: { name: "member_email", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "member_email", value: e.value } })}
            />
            {errors.member_email && touched.member_email && (<p className="error-message">{errors.member_email}</p>)}
          </div>
          <div className="form-group mb-2">
            <TextBox
              type="text"
              name="member_mobile"
              className="dx-field-item-required"
              required="required"
              labelMode="floating"
              label="Phone No"
              value={values.member_mobile}
              onValueChanged={(e) => handleChange({ target: { name: "member_mobile", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "member_mobile", value: e.value } })}
            />
            {errors.member_email && touched.member_email && (<p className="error-message">{errors.member_email}</p>)}
          </div>
          <div className="form-group mb-2">
            <TextBox
              type="text"
              name="member_address"
              className="dx-field-item-required"
              required="required"
              labelMode="floating"
              label="Address"
              value={values.member_address}
              onValueChanged={(e) => handleChange({ target: { name: "member_address", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "member_address", value: e.value } })}
            />
            {errors.member_address && touched.member_address && (<p className="error-message">{errors.member_address}</p>)}
          </div>
          <div className="form-group mb-2">
            <SelectBox
              onFocusIn={() => setFocusInput((prev) => ({ ...prev, countryFocusIn: true }))}
              className="dx-field-item-required"
              displayExpr="country_name"
              valueExpr="country_id"
              dataSource={countryDataSource || []}
              searchEnabled={true}
              name="country"
              label="Country"
              labelMode="floating"
              value={values.country}
              onValueChanged={(e) => handleChange({ target: { name: "country", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "country", value: e.value } })}
            />
            {errors.country && touched.country && (<p className="error-message">{errors.country}</p>)}
          </div>
          <div className="form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              label="State"
              dataSource={dataSource?.state || []}
              searchEnabled={true}
              displayExpr="state_name"
              valueExpr="state_id"
              labelMode="floating"
              value={values.state}
              onValueChanged={(e) => handleChange({ target: { name: "state", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "state", value: e.value } })}
            />
            {errors.state && touched.state && (<p className="error-message">{errors.state}</p>)}
          </div>
          <div className="form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              label="City"
              labelMode="floating"
              dataSource={dataSource?.city || []}
              displayExpr="city_name"
              valueExpr="city_id"
              value={values.city}
              onValueChanged={(e) => handleChange({ target: { name: "city", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "city", value: e.value } })}
            />
            {errors.city && touched.city && (<p className="error-message">{errors.city}</p>)}
          </div>
          <div className="form-group mb-2">
            <TextBox
              type="text"
              name="ticket_qty"
              className="dx-field-item-required"
              required="required"
              labelMode="floating"
              label="Ticket Quantity"
              value={values.ticket_qty}
              onValueChanged={(e) => handleChange({ target: { name: "ticket_qty", value: e.value } })}
              onFocusOut={(e) => handleBlur({ target: { name: "ticket_qty", value: e.value } })}
            />
            {errors.ticket_qty && touched.ticket_qty && (<p className="error-message">{errors.ticket_qty}</p>)}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="secondary" onClick={handleSubmit}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IndividualMemberModal;
