import React from 'react'
import "./ProductCountdown.css"

function ProductCountdown() {
  return (
    <div>
        <div className="section-padding height-auto-100 image-flip-rtl countDownImg">
          <div className="container h-100">
            <div className="d-flex flex-column justify-content-center h-100">
              <h4 className="fw-500 mb-0 text-center text-md-start text-white">40th year Ramanavami Utsavam - Discourses by Trichy Sri Kalyanaraman</h4>
              <h2 className="fw-500 mb-0 text-center text-md-start text-white">Countdown to event</h2>
              <ul className="countdown d-flex align-items-center list-inline count-down my-5 py-3" data-date="May 30 2025 20:20:22">
                <li className="position-relative d-inline-lock">
                  <span data-days className="numberDisplay">23</span>
                  <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Days</span>
                </li>
                <li className="position-relative d-inline-block">
                  <span data-hours className="numberDisplay">140</span>
                  <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Hours</span>
                </li>
                <li className="position-relative d-inline-block">
                  <span data-minutes className="numberDisplay">60</span>
                  <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Minutes</span>
                </li>
                <li className="position-relative d-inline-block">
                  <span data-seconds className="numberDisplay">35</span>
                  <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">Seconds</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ProductCountdown