import React, { useState, useEffect } from "react";
import axios from "axios"; // axios for HTTP requests
import { BASEPATH } from "../../config/config";

const GameComponent = ({ gameType, onDataFetch }) => {
  const [error, setError] = useState(null);

  // Function to fetch data based on gameType
  const fetchGameData = async (gameType) => {
    try {
      const response = await axios.post(`${BASEPATH}ragariddle/getRagams`, {
        gameType, // Directly pass gameType in the body
      });

      const data = response.data;

      onDataFetch(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data: " + error.message);
    }
  };

  // Fetch data when gameType changes
  useEffect(() => {
    fetchGameData(gameType);
  }, [gameType]);

  return <div>{error && <p>{error}</p>}</div>;
};

export default GameComponent;
