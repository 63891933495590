import React, { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { makePostRequest } from "../../../Api/requestMethods";
import { editEventDetails } from "../../../Api/api";
import CreateSeatingLayouts from "../createSeatingLayouts/createSeatingLayouts";
import { isValidValue } from "../../../utils/commonUtils";
import {
  ERROR,
  DURATION,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import { GlobalContext } from "../../../global/provider.js";
import { numberedDetailsUpdate } from "../../../Api/api";
import notify from "devextreme/ui/notify";

const EditNumberedLayout = () => {
  const { eventsDatasource } = useContext(GlobalContext);
  const [searchParams] = useSearchParams();
  const event_id = searchParams.get("event_id");
  const country_id = searchParams.get("country_id");
  const [dataSource, setDataSource] = useState({
    venueLayoutsDataSource: "",
    seatingLayoutDataSource: "",
    overAllSeatingLayoutDs: "",
    promoCodeDataSource: "",
    submitting: false,
    lookupDetails: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!isValidValue(country_id) && !isValidValue(event_id)) return;
      try {
        const body = {
          country_id: country_id,
          event_id: event_id,
          is_season_event: "N",
        };
        const response = await makePostRequest(editEventDetails, body);
        if (response.status === SUCCESS) {
          function appendIdToData(data, id) {
            const isArrayOfObjects = (arr) => {
              return (
                Array.isArray(arr) &&
                arr.length > 0 &&
                typeof arr[0] === "object"
              );
            };

            return Object.entries(data).reduce((acc, [key, value]) => {
              if (isArrayOfObjects(value)) {
                acc[key] = value.map((item) => ({
                  ...item,
                  numbered_id: id,
                }));
              } else {
                acc[key] = value;
              }
              return acc;
            }, {});
          }

          const transformedData = response.data.reduce((finalAcc, section) => {
            const sectionData = section.ticket_details.reduce((acc, data) => {
              const parsedTickets = JSON.parse(data.balance_tickets);
              acc[data.row_name] = appendIdToData(parsedTickets, data.id);
              return acc;
            }, {});
            return { ...finalAcc, ...sectionData };
          }, {});
          const lookupData =
            response.data &&
            response.data[0].ticket_details[0].seating_order_lookup;
          setDataSource((prev) => ({
            ...prev,
            seatingLayoutDataSource: transformedData,
            lookupDetails: lookupData,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [event_id, country_id]);

  const updateNumberedEvent = async () => {
    if (
      eventsDatasource.updatedNumberedDetails &&
      eventsDatasource.updatedNumberedDetails.length === 0
    ) {
      notify(
        "No changes detected. Please make updates before submitting.",
        ERROR,
        DURATION
      );
      return;
    }
    try {
      setDataSource((prev) => ({ ...prev, submitting: true }));
      const body = {
        event_id: event_id,
        seat_details: eventsDatasource.updatedNumberedDetails || [],
      };
      const response = await makePostRequest(numberedDetailsUpdate, body);
      if (response.status === SUCCESS) {
        setDataSource((prev) => ({ ...prev, submitting: false }));
        notify(response?.message, SUCCESS, DURATION);
      } else {
        setDataSource((prev) => ({ ...prev, submitting: false }));
      }
    } catch (error) {
      console.error(error);
      setDataSource((prev) => ({ ...prev, submitting: false }));
    }
  };

  return (
    <>
      <CreateSeatingLayouts
        propsDataSource={dataSource.seatingLayoutDataSource}
        setDataSourceEvents={setDataSource}
        isticketpurchase={false}
        isViewEvent={false}
        lookupDetails={dataSource.lookupDetails}
      />
      <div className="container d-flex justify-content-end mb-4">
        <button
          className="button"
          onClick={updateNumberedEvent}
          disabled={
            dataSource.submitting &&
            eventsDatasource.updatedNumberedDetails.length === 0
          }
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default EditNumberedLayout;
