import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const TemplateDetailsModal = ({ show, handleClose, template }) => {

  if (!template) return null;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <h5 className='text-center m-auto border-light'>{template.name}</h5>
      </Modal.Header>

      <Modal.Body>
        <img src={template.imageUrl} alt={template.name} className="img-fluid" />
        <p
          className='w-100'
          style={{ wordWrap: 'break-word', wordBreak: 'break-word', whiteSpace: 'normal' }}
          dangerouslySetInnerHTML={{ __html: template.renderedContent }}
        ></p>
        <div>Status: {template.status}</div>
      </Modal.Body>
      <Modal.Footer>
        <button className='CancelBtn' type="button" variant="secondary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateDetailsModal;
