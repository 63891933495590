import React, { useEffect } from "react";
import "./styles/Login.css";
import { useState } from "react";
import Login from "./Login";
import VerificationRegistration from "./VerificationRegistration";
import { useNavigate } from "react-router-dom";

function Front() {
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();

  const toggleForm = () => {
    setIsLogin(!isLogin);
    const url = isLogin ? "/Register" : "/Login";
    navigate(url);
  };

  return (
    <div>
      <div className="loginBgBanner d-flex ">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center height-self-center mt-lg-5">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 col-md-12 align-self-center">
              <div className="user-login-card bg-body shadow-sm">
                <div className="">
                  <div className="">
                    {isLogin ? <Login /> : <VerificationRegistration />}
                  </div>
                  {isLogin ? (
                    <p className="mt-3 text-center">
                      Don't have an account?
                      <span
                        onClick={toggleForm}
                        style={{ color: "#3f00ff" }}
                        className="mouseOverElement"
                      >
                        {" "}
                        Register now
                      </span>{" "}
                    </p>
                  ) : (
                    <p className="text-center">
                      Already registered?{" "}
                      <span
                        onClick={toggleForm}
                        className="mouseOverElement"
                        style={{ color: "#3f00ff" }}
                      >
                        Login
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Front;
