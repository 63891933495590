import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../../../../global/ToastMsgConstants.tsx";


const videoValidationSchema = Yup.object().shape({

        video_title: Yup.string()
        .matches(/^[a-zA-Z0-9\s]+$/, "Video title can only contain letters and numbers")
        .required(COMMONFIELDREQUIRED),
        main_category_id: Yup.string().required(COMMONFIELDREQUIRED),
        // sub_category_id: Yup.string().required(COMMONFIELDREQUIRED),
        // artist_ids: Yup.array().required("This field is required / Enter Artist-name to show Option"),
        // organiser_ids: Yup.array().required("This field is required / Enter Organiser-name to show Option"),
        //   description:Yup.string().required(COMMONFIELDREQUIRED),
        thumbnail: Yup.string().required('Image required'),
      //   video_url: Yup.mixed().required('Video required')
})

export default videoValidationSchema