import React from "react";

function TagItem({ name, image }) {
  return (
    <div className="organizer-item">
      <span>{name}</span>
    </div>
  );
}

function Tag({ name, image }) {
  return (
    <React.Fragment>
      <div className={`dx-tag-content`}>
        {image ? (
          <>
            <span>{name}</span>
          </>
        ) : (
          <>
            <span>{name}</span>
          </>
        )}
        <div className="dx-tag-remove-button"></div>
      </div>
    </React.Fragment>
  );
}

export { Tag, TagItem };
