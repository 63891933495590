import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { UploadVideosHeaderRoutes } from '../../../global/Routes';

const UploadVideosHeader = () => {
    const CurrentLocation = useLocation()
    const ExactPath = CurrentLocation?.pathname
  return (
      <div className="content-details trending-info">
          <ul
              className="nav col-12 w-100 nav-underline d-flex nav nav-pills align-items-center text-center mb-lg-0 gap-2 navBarBg py-2"
              role="tablist"
          >

              {UploadVideosHeaderRoutes && UploadVideosHeaderRoutes?.map((item, index) => (
                  <li className="nav-item w-auto col-5 col-md-4 col-lg-3 col-xl-2 me-2 me-md-4 me-lg-5 " key={index} >
                      <Link to={item?.route} className={`nav-link ${ExactPath === "/" + item?.route ? "active" : ""}`}>
                          {item.name}

                      </Link>
                  </li>
              ))}

          </ul>
          <Outlet />

      </div>
  )
}

export default UploadVideosHeader