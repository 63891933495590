// Dashboard

const LOGIN = "login";

const REGISTRATION = `register/createuser`;

const COUNTRY = () => `location/get_countries`;

const EVENT_MAIN_CATEGORY = `category/get_Event_maincategories`;

const EVENT_SUB_CATEGORY = `category/get_Event_maincategories`;

const ARTIST_DETAILS = () => `profiles/get_artist_byname`;

const STATE = () => `location/get_states`;

const CITY = () => `location/get_cities`;

const TIMEZONE = () => `events/get_timezone`;

const ACCOMPANISTS = () => `common/get_artists`;

const ARTIST_CATEGORIES = `profiles/get_artistscategories`;

const GET_ARTIST = `profiles/get_artists_by_artcategory`;

const ARTIST_MAIN_CATEGORIES = `category/get_Artist_maincategories`;

const ARTIST_SUB_CATEGORIES = `category/get_Event_subcategory`;

const PROFILE_URL = (id) => `login/get_logindetails?loginid=${id}`;

const EVENT_ARTISTS = `profiles/get_artist_byname`;

const CHECK_VALID_EMAIL = `register/checkuseremail`;

const FORGOT_PASSWORD = `login/forgotpassword`;

const UPDATEPASSWORD = `login/updatepassword`;

const PROFILE_ORGANISER_BY_NAME = `profiles/get_organiser_byname`;

// ----------------------Blog---------------------------------------------------------------------------------
const BLOG_DETAILS_ORGANISERS = () => `profiles/get_organiser_byname`;

const BLOGPOST_UPLOAD = () => `blog/create_blog`;

const GET_BlOG_CATEGORIES = `blog/get_blogcategories`;

const GET_BlOG_MAIN_CATEGORIES = () => `category/get_Blog_maincategories`;

const VIEW_BLOG_CATEGORY = () => `blog/get_blogbyroleid`;

const UPDATE_BLOG_CATEGORY = () => `blog/update_blog`;

const DELETE_BLOG_CATEGORY = () => `blog/delete_blog`;

const TOGGLE_BLOG_CATEGORY = () => `blog/change_showtopublic`;

export const ALL_ARTIST_DETAIL_RAGA = () => `videos/raga_bank/artists`;

const ALL_ARTIST_DETAIL = () => `profiles/get_all_artist`;

const GET_BLOG_PERTICULAR_CATEGORY = () => `blog/get_blogs`;

const GET_BLOG_PERTICULAR_PROFILE = () => `blog/get_blog`;

const ALL_ORGSNISER_TAG_DETAIL = () => `profiles/get_all_organiser`;
// ----------------------Add-on-Code---------------------------------------------------------------------------
const ADDONCREATE_API = () => `addon_code/create_addoncode`;

const GET_ADDON_CATEGORIES = () => `addon_code/list_all_addoncodes`;

const UPDATE_ADDON_CARD = () => `addon_code/get_addoncode_byid`;

const DELETE_ADDON_CART = () => `addoncode/delete_addoncode_byid`;
// -----------------------PROMOCODE----------------------------------------------------------------------------
const PROMOCODE_CREATE_API = () => `promocode/create_promocode`;

const GET_PROMOCODE_CATEGORIES = () => `promocode/promocodes_byrole_id`;

const UPDATE_PROMO_CARD = () => `promocode/update_promocode_byid`;

const DELETE_PROMO_CARD = () => `promocode/delete_promocode_byid`;

const GET_USER_PROFILE_DETAILS = `profiles/get_user_profiledetails`;

const GET_COUNTRIES = `events/get_available_countries`;

const GET_CITIES_BY_COUNTRIES = `events/get_available_cities`;

const GET_EVENTS = `events/get_events`;

const GET_EVENTS_BY_CATEGORIES = `events/get_events_by_categories`;

const GET_EVENT_DETAILS = `events/get_event_details`;

const GET_TICKET_DETAILS = `events/get_ticket_details`;

const PROFILE_DETAILS = `profiles/get_roleiddetails`;

// payment
const BUY_TICKETS_FREE_WITH_REGISTRATION = `purchase/get_free_tickets`;
const GET_PAYMENT_DETAILS = `purchase/pay_now`;

export const PROFILE_REGISTRATION = () => {
  return {
    Artist: {
      URL: `profiles/add_artistprofile`,
    },

    // Organisers: {
    //   URL: `profiles/add_organiserprofile`,
    // },
    Organiser: {
      URL: `profiles/add_organiserprofile`,
    },

    Fan: {
      URL: `profiles/add_fanprofile`,
    },
  };
};
//----------------------------------UplodesVideos-----------------------------------------------
const UPDATE_VIDEOS_UPLOAD = () => `videos/record/insert`;
const GET_VIDEOEDIT_DETAILS = `videos/record/read_all_user_videos`;
const GET_THUMBNAIL_VIDEOS_IN_UPLOAD = `videos/record/get_thumbnail`;
const VEDIO_VIEW_ROW_DETAIL_UPDATE = () => "videos/record/update";
const DELETE_RECORD_ROW_VIDEOS = () => "videos/record/delete";
// -----------------------------------MdndRecord------------------------------------------------
const GET_MDND_RECORDS = () =>
  `videos/record/filter/eyJsaW1pdCI6MTAsIm9mZnNldCI6M30=`;
// -----------------------------------MdndRecord------------------------------------------------
const UPDATE_VIDEOS_VIEW = () => `videos/record/view/insert`;

const GET_THUMBNAITL_RECORDS = () => `videos/record/get_thumbnail`;

const GET_PERTICULAR_RECORDS = () => `videos/record/read`;

const GET_CATEGORY_FOR_RECORDS = `videos/record/videos/categories`;

const CREATE_EVENTS = () => `events/create_events`;

const UPDATE_EVENTS = `events/update_events`;

const INSERT_COMMENT_DETAIL = () => `videos/record/comment/insert`;

const DELETE_COMMENT_DETAIL = () => `videos/record/comment/delete`;

const POST_VOTE_DETALIL = () => `videos/record/vote`;

const GET_PROFILE_FOR_RECORD = () => `profiles/get_user_profile`;
// ------------------------------------RagaBank--------------------------------------------------
const GET_RAGABANK_RECORD = () => `videos/raga_bank/filter`;

const UPDATE_RAGA_VIEWS = () => `videos/raga_bank/view/insert`;

const GET_PERTICULAR_RAGABANK = () => `videos/raga_bank/read`;

const GET_RECORD_DELETE_COMMENT = () => `videos/record/comment/delete`;

const GET_THUMNAIL_FOR_RAGA = () => `videos/raga_bank/get_thumbnail`;

const GET_RAGABANK_DELETE_COMMENT = () => `videos/raga_bank/comment/delete`;

const INSERT_COMMENT_FOR_RAGA = () => `videos/raga_bank/comment/insert`;

const POST_VOTE_RAGA_DETAIL = () => `videos/raga_bank/vote`;

const RAGA_ITEMS = `videos/raga_bank/ragas`;

const LANGUAGE_ITEMS = `videos/raga_bank/languages`;

const V_TYPE_ITEMS = `videos/raga_bank/vtypes`;

const COMPOSERS_ITEM = `videos/raga_bank/composers`;

const THALA_ITEM = `videos/raga_bank/thalas`;
// ---------------------------------------adver-------------------------------------------------------
const GET_ADS_BEFORE_VIDEO = () =>
  `advertisement/video/get_record_video_advertisement`;
const GET_WATCHED_ID = () =>
  `advertisement/video/update_last_watched_record_video_add`;
// ------------------------------------------MailerApi------------------------------------------------
const INSERT_TEMPLATE_DETALI = () => `mailer/promo_template/insert`;
const SUBMIT_MEMBERS_DETAIL = () => `mailer/members/insert`;
const MAILER_MEMBERS_UPDATE_DETAIL = () => `mailer/members/update`;
const MAILER_MEMBERS_DELETE = () => `mailer/members/delete`;
const SELECT_TAMPLATE_MAIL = () =>
  `mailer/promo_template/1/read_all_template_name`;
const HISTORY_DETAIL_MAIL = () => `mailer/read_mailer_mails`;
const INDIVIDUAL_MAIL = () => `mailer/send_mail`;
const READ_PERTICULAR_TAMPLATE = () => `mailer/promo_template/read`;
const BULK_MAILER_DOWNLOADER = `mailer/members/get_bulk_upload_template`;
// -----------------------------------------StreamingApi--------------------------------------------------
const POST_STREAM_VOTE_DETAIL = () => `stream/stream_video_likes`;
const GET_STREAM_LOGIN_TOKEN = () => `profiles/get_stream_login_token`;
const ADD_STREAM_MESSAGE_GO = () => `stream/stream_video_comments`;
const GET_STREAM_COMMENT_DETAIL = `stream/get_video_comments`;
// -----------------------------------------StreamingApi--------------------------------------------------
const GET_PERTICULAR_USER = () => `login/get_logindetails?loginid`;
const UPDATE_ADS_UPLOAD = () => `advertisement/video/pay_now`;
const GET_ADDS_DETAILS = () => `advertisement/video/read_all`;
const UPDATE_ADS_VIDEOSEDIT_DATA = () => `advertisement/video/update`;
const UPDATE_ADS_VIDEOSEDIT_DATA_IFONLINE = () => `advertisement/video/topup`;
const INSERT_ADS_DETAIL_TOPUP = () => `advertisement/video/topup`;
const GETTING_TRUE_API = () => `stream/login_streamed_user`;
// ----------------------------------------------------------------------------------------------

//POST -PROFILE

export const createLandingPage = `landingpage/create_landingpage`;

export const landingPageDetailsApi = `landingpage/get_landingpage`;

export const updateLandingPage = `landingpage/update_landingpage`;

// Members

export const getMemberType = `events/get_membertype`;

export const getMemberDetails = `events/get_memberdetails`;

// Season events

export const getSeasonEventAvailableCountries = `seasonevents/get_season_countries`;

export const getOrganiserDetailsById = (country_id) =>
  `seasonevents/get_season_organisers?country_id=${country_id}`;

export const getOrganiserLandingPageDetails = `seasonevents/get_seasonevent_by_roleid`;

export const POST_SINGLE_PROFILE = `post/create_post/`;

export const GET_POSTS_PROFILE = `post/list_post`;

export const GET_IMAGES_PROFILE = `public/images/post_images/`;

export const DELETE_SINGLE_POST = `post/delete_post`;

export const COMMENT_SINGLE_POST = `post/add_postcomments`;

export const EDITCOMMENT_SINGLE_POST = `post/edit_postcomments`;

export const DELETECOMMENT_SINGLE_POST = `post/delete_postcomments`;

const LIKE_SINGLE_POST = `post/add_postlike`;

const GET_POSTS_COMMENTS = `post/list_postcomments`;

const GET_LIKES = `post/list_postlikes`;

const SWITCH_ROLE = `profiles/create_new_role`;

const REPORT_POST = `post/add_postreports`;

const GET_LIKES_BY_USER = `post/check_userpostlikestatus`;

const GET_USERPROFILE = `profiles/get_user_profiledetails`;

const CHANGE_PROFILE_PIC_USER = `profiles/change_profilephoto`;

export const ABOUT_PROFILE = `profiles/change_profileabout`;

export const FOLLOW_A_USER = `post/user_follow`;

export const UNFOLLOW_A_USER = `post/user_unfollow`;

export const GET_ABOUTS = `profiles/get_profileabout`;

export const FOLLOW_INITIAL_CHECK = `post/check_user_follow`;

const SOCIAL_MEDIA_LINK = `profiles/addupdate_profilesocial`;

export const GET_SOCIALMEDIA = `profiles/get_profilesocial`;

export const GET_FEED = `post/get_feedpostdatas`;

export const SEND_MESSAGE_TO_CONTACT = `contact/add_contact_message`;

export const GET_MESSAGES_CONTACT = `contact/get_contact_message`;

export const GET_TAGGEDBLOGS = `blog/get_taggedblogbyroleid`;

export const GET_WHOTOFOLLOW = `post/get_whotofollow`;

export const GET_CALENDAR = `calender/get_mycalender_event`;

export const GET_NAMES_BY_LOGINID = `profiles/get_user_profile_info`;

//DYNAMIC FORMS..

export const LIST_FORM_FIELDS = `form/list_form_field`;

export const SEND_CREATEFORM_SUBMIT = `form/create_form`;

export const GET_FORMS_FROM_BACKEND = `form/list_created_forms`;
export const GET_DETAILED_FORM_FROM_BACKEND = `form/get_form_details`;
export const COUNTRIES_FROM_BACKEND = `location/get_countries`;

export const STATES_FROM_BACKEND = `location/get_states`;

export const CITIES_FROM_BACKEND = `location/get_cities`;

export const SAVE_FORM_DATA = `form/save_form_data`;

export const SHOWTOPUBLIC_STATUS_BACKEND = `form/form_showtopublic`;

export const DELETE_DYNAMIC_FORM_BACKEND = `form/delete_created_form`;

export const DYNAMIC_PARTICULAR_LIST = `form/get_form_data_details`;
export const EDIT_PARTICULAR_FORM = `form/edit_form_data`;
export const CREATE_SAVEDRAFT = `drafts/create_draft`;
export const GET_DRAFTS_FROM_BACKEND = `drafts/get_my_drafts`;

//GLOBAL SEARCH...............
export const ARTIST_GLOBAL_SEARCH = `global_search/artists`;
export const ORGANISER_GLOBAL_SEARCH = `global_search/organisers`;
export const RECORDS_GLOBAL_SEARCH = `global_search/records`;
export const RAGABANK_GLOBAL_SEARCH = `global_search/raga_bank`;
export const EVENTS_GLOBAL_SEARCH = `global_search/events`;
export const SEASON_EVENTS_GLOBAL_SEARCH = `global_search/season_events`;

//ADVERTISER POST.......

export const POST_ADVERTISER = `profiles/add_advertiserprofile`;

// ACCOUNT DETAILS- MY PROFILE
export const ADD_ACCOUNT_DETAILS = `profiles/add_account_details`;
export const GET_ACCOUNT_DETAILS = `profiles/get_account_details`;
export const UPDATE_ACCOUNT_DETAILS = `profiles/update_account_details`;

//
// MY VIDEOS- MY PROFILE
export const GET_MY_VIDEOS = `videos/record/read_all_user_videos`;

//DASHBOARD
export const GET_DASHBOARD = `user_dashboard/read_info`;
export const GET_FOLLOWERS = `post/get_followerlist`;
export const GET_FOLLOWING = `post/get_followinglist`;

//FILTERS- ARTIST
export const GET_COUNTRIES_ARTIST = `profiles/get_artist_country`;
export const GET_STATES_ARTIST = `profiles/get_artist_state`;
export const GET_CITIES_ARTIST = `profiles/get_artist_city`;
export const ARTIST_FILTER = `profiles/get_artist_filter`;
export const FETCH_ALL_ARTIST_MAINCATEGORY = `profiles/list_artist_maincategory`;
export const GET_ALL_ARTIST_SUBCATEGORY = `profiles/list_artist_subcategory`;

// FILTERS- ORGANISERS
export const GET_COUNTRIES_ORGANISER = `profiles/get_organiser_country`;
export const GET_STATES_ORGANISER = `profiles/get_organiser_state`;
export const GET_CITIES_ORGANISER = `profiles/get_organiser_city`;
export const ORGANISER_FILTER = `profiles/get_organiser_filter`;

//EDIT PROFILE
export const EDIT_YOUR_PROFILE = `profiles/update_userprofile`;

//FILTER-SEASON TICKETS
export const SEASON_TICKETS_FILTER = `seasonevents/get_season_events_listing`;

// Seating Layout

export const createVenue = `venue_layout/create_venue_layout`;

const getVenuSeatingLayout = `venue_layout/get_seating_layout`;

// add to cart

const addToCart = `cart/add_to_cart`;

const getCartList = `cart/get_cart_list`;

const deleteCartItem = `cart/remove_cart_item`;

const getCartCount = `cart/get_cart_count`;

// View Events

const viewEvents = `events/get_myevent_list`;

const editEvents = `/events/event_showtopublic`;

export const showToPublic = `/events/event_showtopublic`;

const get_all_events_title = `events/get_all_events_title`;

export const viewTicketDetailsApi = (country_id, event_id) => {
  const encodedCountryId = encodeURIComponent(country_id || "");
  const encodedEventId = encodeURIComponent(event_id || "");
  return `/events/view_ticket_list?country_id=${encodedCountryId}&event_id=${encodedEventId}`;
};

export const viewSeasonDetailsApi = (country_id, event_id) =>
  `seasonevents/view_ticket_list_season?country_id=${country_id}&event_id=${event_id}`;

// Events - numbered

const getNumberedEventDetails = `venue_layout/get_venue_lists`;

// Save as drafts

const createSaveAsDrafts = `drafts/create_draft`;

const getDraftDetails = `drafts/get_my_drafts`;

// single cart Details

const singleCartDetails = `cart/check_single_item`;

//Events

const eventPromoCode = `promocode/promocodes_byrole_id`;

const eventAddOnCode = `addon_code/list_all_addoncodes`;

const getAllOrganiserDetails = `profiles/get_all_organisers`;

const editEventDetails = `events/edit_event`;

const getGstConfig = `events/get_gst_config`;

// Season events
const seasonEventListing = (country_id, city_id) =>
  `/seasonevents/get_season_events_listing?country_id=${country_id}&city_id=${city_id}`;

const seasonEventDetails = (
  country_id,
  season_event_id,
  multiple_tickets,
  status
) =>
  `seasonevents/get_season_events_details?country_id=${country_id}&season_event_id=${season_event_id}&multiple_tickets=${multiple_tickets}&show_to_public=${status}`;

// sumary promocode

const summaryPromoCode = (country_id, event_id) =>
  `/promocode/summary_promocode?event_id=${event_id}&country_id=${country_id}`;

const appliedPromoCode = `promocode/apply_promocode`;

const checkAvailPromoCode = (country_id, event_id, promo_code) =>
  `promocode/check_availpromocode?event_id=${event_id}&country_id=${country_id}&promo_code=${promo_code}`;

const deletePromoCode = (cart_id) =>
  `promocode/delete_promocode?cart_id=${cart_id}`;

// summary addonCode

const summaryAddonCode = (country_id, event_id) =>
  `/addon_code/summary_addoncode?country_id=${country_id}&event_id=${event_id}`;

const summaryDeleteAddOnCode = (cart_id, item_id, addon_id) =>
  `addon_code/delete_applied_addoncode?cart_id=${cart_id}&event_id=${item_id}&addon_id=${addon_id}`;

const applyAddOnCodeData = `addon_code/apply_addoncode`;

// Numbered add to cart

const numbered_addToCart = `cart/numbered_cart`;

const delete_numbered_cart = (role_id, session_id, cart_id) =>
  `cart/delete_numbered_cart?role_id=${role_id}&session_id=${session_id}&cart_id=${cart_id}`;

const mainAndSubCategory = `category/get_main_sub_categories`;

const deleteEventApi = `events/delete_event`;

const numberedDetailsUpdate = `events/update_seat_details`;

//-----------------------------------------------------------------------------------------------------

export const INDI_EVENTS_GET = `events/get_individualevents_by_roleid`;
export const VIEW_SEASON_MULTPLE_TKTLIST = `seasonevents/view_multiple_ticket_list`;

const getSeatingLayout = `/venue_layout/get_seating_layout`;

export {
  getSeatingLayout,
  numberedDetailsUpdate,
  deleteEventApi,
  mainAndSubCategory,
  UPDATE_EVENTS,
  delete_numbered_cart,
  numbered_addToCart,
  summaryDeleteAddOnCode,
  getGstConfig,
  applyAddOnCodeData,
  deletePromoCode,
  appliedPromoCode,
  seasonEventDetails,
  checkAvailPromoCode,
  summaryAddonCode,
  summaryPromoCode,
  seasonEventListing,
  editEventDetails,
  getAllOrganiserDetails,
  getCartCount,
  deleteCartItem,
  eventAddOnCode,
  getCartList,
  eventPromoCode,
  getVenuSeatingLayout,
  singleCartDetails,
  getDraftDetails,
  createSaveAsDrafts,
  getNumberedEventDetails,
  editEvents,
  viewEvents,
  addToCart,
  get_all_events_title,
  LOGIN,
  ARTIST_DETAILS,
  EVENT_SUB_CATEGORY,
  COUNTRY,
  EVENT_MAIN_CATEGORY,
  STATE,
  CITY,
  TIMEZONE,
  CREATE_EVENTS,
  ACCOMPANISTS,
  REGISTRATION,
  ARTIST_CATEGORIES,
  GET_ARTIST,
  ARTIST_MAIN_CATEGORIES,
  ARTIST_SUB_CATEGORIES,
  PROFILE_URL,
  EVENT_ARTISTS,
  CHECK_VALID_EMAIL,
  FORGOT_PASSWORD,
  UPDATEPASSWORD,
  PROFILE_ORGANISER_BY_NAME,
  PROMOCODE_CREATE_API,
  GET_PROMOCODE_CATEGORIES,
  UPDATE_PROMO_CARD,
  DELETE_PROMO_CARD,
  UPDATE_ADDON_CARD,
  GET_ADDON_CATEGORIES,
  ADDONCREATE_API,
  DELETE_BLOG_CATEGORY,
  UPDATE_BLOG_CATEGORY,
  VIEW_BLOG_CATEGORY,
  GET_BlOG_CATEGORIES,
  BLOGPOST_UPLOAD,
  BLOG_DETAILS_ORGANISERS,
  GET_USER_PROFILE_DETAILS,
  GET_COUNTRIES,
  GET_CITIES_BY_COUNTRIES,
  GET_EVENTS,
  GET_EVENTS_BY_CATEGORIES,
  GET_EVENT_DETAILS,
  GET_TICKET_DETAILS,
  GET_PAYMENT_DETAILS,
  GET_THUMBNAITL_RECORDS,
  GET_PERTICULAR_RECORDS,
  GET_RAGABANK_RECORD,
  GET_THUMNAIL_FOR_RAGA,
  POST_VOTE_DETALIL,
  INSERT_COMMENT_DETAIL,
  DELETE_COMMENT_DETAIL,
  GET_RAGABANK_DELETE_COMMENT,
  GET_PERTICULAR_RAGABANK,
  PROFILE_DETAILS,
  GET_POSTS_COMMENTS,
  GET_LIKES,
  LIKE_SINGLE_POST,
  SWITCH_ROLE,
  REPORT_POST,
  GET_LIKES_BY_USER,
  GET_USERPROFILE,
  CHANGE_PROFILE_PIC_USER,
  GET_CATEGORY_FOR_RECORDS,
  BUY_TICKETS_FREE_WITH_REGISTRATION,
  TOGGLE_BLOG_CATEGORY,
  ALL_ARTIST_DETAIL,
  GET_BLOG_PERTICULAR_CATEGORY,
  GET_BLOG_PERTICULAR_PROFILE,
  GET_BlOG_MAIN_CATEGORIES,
  INSERT_COMMENT_FOR_RAGA,
  POST_VOTE_RAGA_DETAIL,
  GET_PROFILE_FOR_RECORD,
  ALL_ORGSNISER_TAG_DETAIL,
  UPDATE_VIDEOS_UPLOAD,
  SOCIAL_MEDIA_LINK,
  GET_VIDEOEDIT_DETAILS,
  GET_THUMBNAIL_VIDEOS_IN_UPLOAD,
  VEDIO_VIEW_ROW_DETAIL_UPDATE,
  GET_RECORD_DELETE_COMMENT,
  DELETE_RECORD_ROW_VIDEOS,
  UPDATE_VIDEOS_VIEW,
  INSERT_TEMPLATE_DETALI,
  SUBMIT_MEMBERS_DETAIL,
  MAILER_MEMBERS_UPDATE_DETAIL,
  MAILER_MEMBERS_DELETE,
  RAGA_ITEMS,
  LANGUAGE_ITEMS,
  V_TYPE_ITEMS,
  COMPOSERS_ITEM,
  THALA_ITEM,
  GET_PERTICULAR_USER,
  UPDATE_ADS_UPLOAD,
  GET_ADDS_DETAILS,
  UPDATE_ADS_VIDEOSEDIT_DATA,
  UPDATE_ADS_VIDEOSEDIT_DATA_IFONLINE,
  INSERT_ADS_DETAIL_TOPUP,
  SELECT_TAMPLATE_MAIL,
  HISTORY_DETAIL_MAIL,
  INDIVIDUAL_MAIL,
  READ_PERTICULAR_TAMPLATE,
  BULK_MAILER_DOWNLOADER,
  DELETE_ADDON_CART,
  // -------------stream---------------------
  POST_STREAM_VOTE_DETAIL,
  GET_STREAM_LOGIN_TOKEN,
  GET_ADS_BEFORE_VIDEO,
  ADD_STREAM_MESSAGE_GO,
  GET_STREAM_COMMENT_DETAIL,
  GETTING_TRUE_API,
  // -------------------------------------------------------------------
  GET_WATCHED_ID,
  UPDATE_RAGA_VIEWS,
};
