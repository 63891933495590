import React, { useRef } from "react";
import { useState, useEffect, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import IMAGE1 from "../assets/images/genre/03.webp";
import Modal from "react-bootstrap/Modal";
import VideoPath from "./HLSVideoPlayer.js";
import { LuIndianRupee } from "react-icons/lu";
import { MdAddToQueue } from "react-icons/md";
import { CiFlag1 } from "react-icons/ci";
import mlogo from "./Static/mlogo.png";
import { IoShareSocial } from "react-icons/io5";
import {
  DELETE_COMMENT_DETAIL,
  FOLLOW_A_USER,
  FOLLOW_INITIAL_CHECK,
  GET_COMMENT_DETAIL,
  GET_PERTICULAR_RECORDS,
  GET_PROFILE_FOR_RECORD,
  GET_RECORD_DELETE_COMMENT,
  GET_THUMBNAITL_RECORDS,
  INSERT_COMMENT_DETAIL,
  POST_VOTE_DETALIL,
  UNFOLLOW_A_USER,
  UPDATE_VIDEOS_VIEW,
} from "../Api/api.js";
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
} from "../Api/requestMethods.js";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
// import notify from "devextreme/ui/notify.js";
import { DURATION, ERROR } from "../global/ToastMsgConstants.tsx";
import { Link, useLocation } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { jwtDecode } from "jwt-decode";
import { BsFillSendFill } from "react-icons/bs";
import {
  parseISO,
  format,
  formatDistanceToNowStrict,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import ShareModal from "./ShareModel.js";
import { one } from "devextreme/events";
import profile from "./Static/user.png";
import NoImage from "./Static/NoImage.png";
import { debounce } from "lodash";
function OffCanvasExample({ name, show, handleClose, ...props }) {
  return (
    <>
      <Offcanvas
        className="commentMobileHeight"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Comments</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-inline m-0 p-0">
            <li className="pb-3 mb-3">
              <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
                <div className="flex-shrink-0 videoCommentImg">
                  <img src={IMAGE1} alt="user-image" className="img-fluid" />
                </div>
                <div className="about-user">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <h5 className="mb-0">Jack Stark</h5>
                    <span className="text-uppercase fst-italic fw-semibold published-date">
                      <i className="fas fa-minus fa-xs"></i> march 2, 2022
                    </span>
                  </div>
                  <p className="mt-2 mb-0">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form.
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-3">
              <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
                <div className="flex-shrink-0 videoCommentImg">
                  <img src={IMAGE1} alt="user-image" className="img-fluid" />
                </div>
                <div className="about-user">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <h5 className="mb-0">Jhon Deo</h5>
                    <span className="text-uppercase fst-italic fw-semibold published-date">
                      <i className="fas fa-minus fa-xs"></i> march 2, 2022
                    </span>
                  </div>
                  <p className="mt-2 mb-0">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div className="d-flex align-items-center mt-2 mobileCommFixed">
            <div className="videoCommentImg">
              <img src={IMAGE1}></img>
            </div>
            <div className="ms-2 w-100">
              <textarea
                className="w-100 textareaBorder"
                placeholder="Add a comment..."
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
// --------------------------------------------------------------------------------------------------------------------------------------------------

const ReadMore = ({ html }) => {
  const carLmt = 300;
  const readMoreTxt = " ...read more";
  const readLessTxt = " read less";
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const textContent = html?.replace(/(<([^>]+)>)/gi, "");
  if (!textContent || textContent.length <= carLmt) {
    return (
      <div
        className="add-read-more"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  const firstSet = textContent.substring(0, carLmt);
  const secdHalf = textContent.substring(carLmt);

  return (
    <div
      className={`add-read-more ${
        isReadMore ? "show-less-content" : "show-more-content"
      }`}
    >
      <span dangerouslySetInnerHTML={{ __html: firstSet }} />
      {!isReadMore && (
        <span
          dangerouslySetInnerHTML={{ __html: secdHalf }}
          className="second-section"
        />
      )}
      <span
        className="read-more"
        onClick={toggleReadMore}
        title="Click to Show More"
      >
        {isReadMore ? readMoreTxt : ""}
      </span>
      <span
        className="read-less"
        onClick={toggleReadMore}
        title="Click to Show Less"
      >
        {!isReadMore ? readLessTxt : ""}
      </span>
    </div>
  );
};

const VideoStore = () => {
  const [value, setValue] = useState("");
  const textareaRef = useRef(null);

  useEffect(() => {
    const resizeTextarea = () => {
      const textarea = textareaRef.current;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    resizeTextarea();

    const textarea = textareaRef.current;
    textarea.addEventListener("input", resizeTextarea);

    return () => {
      textarea.removeEventListener("input", resizeTextarea);
    };
  }, []);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const [likeStatus, setLikeStatus] = useState(0);
  const [likeNumber, setlikeNumber] = useState(0);
  const [dislikeStatus, setDislikeStatus] = useState(0);
  const [count, setCount] = useState(0);
  const [videoCount, setVideoCount] = useState(0);
  const [videourlis, setVidioUrl] = useState("");
  const [commentValue, setCommentValue] = useState();
  const [commentsDetail, setCommentsDetail] = useState([]);
  const [profileImages, setProfileImages] = useState({});
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const [mdndData, setMdndData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [titleforPerticular, setTitleforPerticular] = useState("");
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [searchString, setSearchString] = useState("");
  const limit = 20;
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = window.location.href;

  const slug_parms = useParams();
  const vid_obj = JSON.parse(atob(slug_parms.slug));
  const video_id = vid_obj.video_id;
  const [videoid, setVideoId] = useState(vid_obj.video_id);
  const [roleid, setRoleId] = useState(vid_obj.role_id);
  const [mainCatId, setMainCatId] = useState(vid_obj.maincatid);
  const [checkFollow, setCheckFollow] = useState("Follow");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [sideOpenMenuId, setOpenSideMenu] = useState(null);
  // ---------------------------------------------JWT-----------------------------------------------------------
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  console.log(user, "jwtToken");
  //------------------------------------VideoFetch---------------------------------------------------------------
  const prevUrlRef = useRef(window.location.href);

  const fetchParticularVideo = async (videoid, roleid) => {
    const endpoint = `${GET_PERTICULAR_RECORDS()}/${videoid}/${roleid}`;
    return await makeGetRequest(endpoint);
  };

  const { data: ParticularDisplay, refetch } = useQuery({
    queryKey: ["ParticularDisplay", videoid, roleid],
    queryFn: () => fetchParticularVideo(videoid, roleid),
    enabled: !!videoid && !!roleid, // Ensure that the query runs only when both IDs are available
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    const extractParamsFromSlug = (slug) => {
      try {
        const decoded = atob(slug);
        const { video_id, role_id } = JSON.parse(decoded);
        return { video_id, role_id };
      } catch (error) {
        console.error("Failed to parse slug:", error);
        return null;
      }
    };

    const slug = location.pathname.split("/").pop(); // Extract slug from URL
    const params = extractParamsFromSlug(slug);

    if ((params && location.pathname !== prevUrlRef.current) || videoid) {
      setVideoId(params.video_id);
      setRoleId(params.role_id);
      refetch();
      prevUrlRef.current = location.pathname;
    }
  }, [location.pathname, refetch, count]);

  const handleSideVideo = (e, sidedata) => {
    const json_string = JSON.stringify({
      video_id: sidedata.id,
      role_id: user.role_id,
      maincatid: sidedata.main_category_id,
    });
    const slug = btoa(json_string);

    setVideoId(sidedata.id);
    setRoleId(user.role_id);

    navigate(`/RecordsVideo/videos/${slug}`);
  };

  // ---------------------------------sideVideo---------------------------------------------
  const fetchVideos = async (page, searchString) => {
    const data = { limit: 20, page, video_id, search_string: searchString };
    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString); // Use btoa for Base64 encoding in the browser
    const GET_MDND_RECORDS = `videos/record/filter/${base64String}`;

    try {
      const response = await makeGetRequest(GET_MDND_RECORDS);
      const records = response.data;

      const recordsWithThumbnails = await Promise.all(
        records.map(async (record) => {
          const thumbnailResponse = await makeGetRequest(
            `${GET_THUMBNAITL_RECORDS()}/${record.id}`
          );
          return {
            ...record,
            thumbnail: thumbnailResponse.data.thumbnail,
          };
        })
      );

      return recordsWithThumbnails;
    } catch (error) {
      throw new Error(`Error fetching videos: ${error.message}`);
    }
  };

  const loadMoreVideos = useCallback(async () => {
    if (isFetching) return;

    setIsFetching(true);
    try {
      const newRecords = await fetchVideos(currentPage, debouncedSearchString);
      setMdndData((prevData) => [...prevData, ...newRecords]);
      setCurrentPage((prevPage) => prevPage + 1);
    } catch (error) {
      notify(error.message);
    } finally {
      setIsFetching(false);
    }
  }, [currentPage, isFetching, debouncedSearchString]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchString(searchString);
    }, 2000);
    return () => clearTimeout(timer);
  }, [searchString]);

  useEffect(() => {
    loadMoreVideos();
  }, [debouncedSearchString]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
          document.documentElement.scrollHeight &&
        !isFetching
      ) {
        loadMoreVideos();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreVideos, isFetching]);
  //---------------------------HandleSerach--------------------------------------------
  const handleSearchChange = (e) => {
    setSearchString(e.target.value);
  };

  // -----------------------------------------commentApi----------------------------------------
  const GET_COMMENT_DETAIL = `videos/record/${videoid}/comments/read_all`;

  const fetchingCommentMessage = async () =>
    await makeGetRequest(GET_COMMENT_DETAIL);
  const { data: CommentDataSource } = useQuery({
    queryKey: ["commentDetail"],
    queryFn: () => fetchingCommentMessage(),
    onError: () => {
      console.log("error.message", ERROR, DURATION);
    },
  });

  const fetchData = async () => {
    try {
      const response = await fetchingCommentMessage();
      setCommentsDetail(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [videoid]);

  // ------------------------------------------------------------------------------------------------------

  const formatTime = (dateString) => {
    if (!dateString) return "Invalid date";

    const date = parseISO(dateString);
    const now = new Date();

    const seconds = differenceInSeconds(now, date);
    const minutes = differenceInMinutes(now, date);
    const hours = differenceInHours(now, date);
    const days = differenceInDays(now, date);
    const weeks = differenceInWeeks(now, date);
    const months = differenceInMonths(now, date);
    const years = differenceInYears(now, date);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? "s" : ""} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? "s" : ""} ago`;
    } else {
      return `${years} year${years !== 1 ? "s" : ""} ago`;
    }
  };

  // -----------------------------------------------------------------------------------------------------------------------------

  const likeordisLikedata = async (voteDetail) => {
    try {
      const response = await makePostRequest(POST_VOTE_DETALIL(), voteDetail);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const ALLVoteMutation = useMutation({
    mutationKey: ["votingdata"],
    mutationFn: (data) => likeordisLikedata(data),
    onSuccess: (data) => {
      console.log("success fully liked");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleVote = (action, data1) => {
    if (!user) {
      notify("Please register OR logIn  to perform this action.", ERROR);
    } else {
      const ambivalent = action === "like" ? 1 : 0;
      console.log(ambivalent, "ambivalent");
      const data = {
        video_id: videoid,
        login_id: user.login_id,
        role_id: user.role_id,
        ambivalent: ambivalent,
      };

      ALLVoteMutation.mutate(data, {
        onSuccess: () => {
          if (action === "like") {
            setLikeStatus(1);
            setCount(count + 1);
            setDislikeStatus("inactive");
          } else {
            setDislikeStatus(1);
            if (!likeNumber === 0) {
              setLikeStatus(1);
            } else {
              setlikeNumber(data1);
              setCount(count - 1);
            }
            setLikeStatus(null);
          }
        },
        onError: (error) => {
          console.error("Error posting vote:", error);
        },
      });
    }
  };
  const debouncedHandleVote = debounce(handleVote, 300);
  // ---------------------------------------AT Enter Button-----------------------------------------------

  const insertingCommentMessage = async (message) => {
    try {
      const response = await makePostRequest(INSERT_COMMENT_DETAIL(), message);

      return response;
    } catch (error) {
      throw error;
    }
  };

  const InsertMessage = useMutation({
    mutationKey: ["inserMessage"],
    mutationFn: (data) => insertingCommentMessage(data),
    onSuccess: (data) => {
      setCommentValue("");
      console.log("success fully got", data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleComment = () => {
    if (!user) {
      notify("Please register OR log in to perform comment operation", "ERROR");
      return;
    }

    // Check if the message is empty
    if (!value.trim()) {
      notify("Comment message before send", "ERROR");
      return;
    }

    const newComment = {
      video_id: videoid,
      login_id: user.login_id,
      role_id: user.role_id,
      message: value.replace(/\n/g, "<br />"),
    };

    InsertMessage.mutate(newComment, {
      onSuccess: (data) => {
        setCommentsDetail([
          ...commentsDetail,
          { ...newComment, id: data.id, created_at: data.created_at },
        ]);
        setValue("");
        fetchData();
      },
      onError: (error) => {
        console.error("Error posting comment:", error);
      },
    });
  };

  const handleCommentClear = () => {
    setValue("");
  };

  // -----------------------------------------------------------------------------------------
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand("copy");
  };

  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const videoDisplayData = ParticularDisplay?.data;

  console.log(videourlis, "PerticularDisplay");
  console.log(videoDisplayData, "videoDisplayData");

  let videorole_id = videoDisplayData?.role_id;
  const { data: ProfileVideo } = useQuery({
    queryKey: ["PerticularCommetRecordProfile", videorole_id],
    queryFn: () => {
      return fetchingProfile(videorole_id);
    },
    onError: (error) => {
      console.log("Error occurred for roleid:", videorole_id);
      console.error(error);
    },
  });

  console.log(videoDisplayData,"VideoDisplayData")
  // ------------------------------------------Delete Comments--------------------------------------------------------------------

  const DeleteRagaComment = async (commentid) => {
    try {
      const response = await makeDeleteRequest(
        `${GET_RECORD_DELETE_COMMENT()}/${commentid}`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  const deletCommetMutation = useMutation({
    mutationKey: ["deleteRagaCommet"],
    mutationFn: DeleteRagaComment,
    onSuccess: (data, commentid) => {
      const updatedComments = commentsDetail.filter(
        (comment) => comment.id !== commentid
      );
      setCommentsDetail(updatedComments);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const handleDelete = async (commentid) => {
    if (commentid.login_id === user.login_id) {
      const commentedid = commentid.id;
      try {
        await deletCommetMutation.mutateAsync(commentedid);
      } catch (error) {
        console.error("Error deleteting comment :", error);
      }
    }
  };

  const toggleMenu = (toggle) => {
    if (toggle.login_id === user.login_id) {
      setOpenMenuId(openMenuId === toggle.id ? null : toggle.id);
    }
  };

  const toggleMenuSide = (id) => {
    setOpenSideMenu(sideOpenMenuId === id ? null : id);
  };

  // ---------------------------------------------------GetloginbasedProfileCommentIcon--------------------------------------------------

  const fetchingProfile = async (roleid) => {
    const endPoint = `${GET_PROFILE_FOR_RECORD()}/${roleid}`;
    return await makeGetRequest(endPoint);
  };
  const { data: ProfileComment } = useQuery({
    queryKey: ["PerticularCommetRecordProfile", roleid],
    queryFn: () => {
      return fetchingProfile(roleid);
    },
    onError: (error) => {
      console.error(error);
    },
  });
  //-------------------------------------------------------commentProfilePhoto ---------------------------------------------------------------------------

  const fetchAllProfileData = async (comments) => {
    const profileDataPromises = comments.map((comment) =>
      fetchingProfile(comment.role_id).then((data) => ({
        role_id: comment.role_id,
        profile_data: data.data,
      }))
    );
    const profileDataArray = await Promise.all(profileDataPromises);
    return profileDataArray.reduce((acc, { role_id, profile_data }) => {
      acc[role_id] = profile_data;
      return acc;
    }, {});
  };

  useEffect(() => {
    const fetchingProfile = async () => {
      if (commentsDetail.length > 0) {
        try {
          const images = await fetchAllProfileData(commentsDetail);
          setProfileImages(images);
        } catch (error) {
          console.error("Error fetching profile images:", error);
        }
      }
    };

    fetchingProfile();
  }, [commentsDetail]);
  //------------------------------------------------------
  const handleNextPlay = async () => {
    try {
      const currentVideoIndex = mdndData?.findIndex(
        (video) => video.id === ParticularDisplay?.data?.id
      );

      if (currentVideoIndex === -1 || currentVideoIndex === undefined) {
        console.error("Current video not found in the list.");
        return;
      }
      const nextVideoIndex = currentVideoIndex + 1;

      if (nextVideoIndex < mdndData.length) {
        const nextVideo = mdndData[nextVideoIndex];

        setVideoId(nextVideo.id);

        const json_string = JSON.stringify({
          video_id: nextVideo.id,
          role_id: nextVideo.role_id,
          maincatid: mainCatId,
        });
        const slug = btoa(json_string);
        console.log(slug, "SLUGDINKAR");
        navigate(`/RecordsVideo/videos/${slug}`);
      } else {
        console.log("This is the last video in the list.");
      }
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };
  // =========================================================================================================================
  const videoId = ParticularDisplay?.data?.id;
  //----------------------------------------ViewsWork-----------------------------------------------------
  const viewChangesCode = async (data) => {
    try {
      const endPoint = await makePostRequest(UPDATE_VIDEOS_VIEW(), data);
      return endPoint;
    } catch (error) {
      throw new Error(`Failed to update view: ${error.message}`);
    }
  };

  const viewUpdateMutation = useMutation({
    mutationKey: ["viewDetail"],
    mutationFn: (viewData) => viewChangesCode(viewData),
    onSuccess: (data) => {
      if (data && data.status === 200) {
        console.log("Mutation Success Data:", data);
      }
    },
    onError: (error) => {
      console.error("Error updating view:", error);
    },
  });

  useEffect(() => {
    const fetchIPAndUpdateView = async () => {
      try {
        const videoId = ParticularDisplay && ParticularDisplay?.data?.id;
        const viewValue = {
          video_id: videoId,
          login_id: user.login_id,
          role_id: user.role_id,
        };
        viewUpdateMutation.mutate(viewValue);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAndUpdateView();
  }, [videoId]);
  // ------------------------------------HandleFollow--------------------------------------------------
  const CheckFollowStatus = async (data) => {
    try {
      const endpoint = await makePostRequest(FOLLOW_INITIAL_CHECK, data);
      return endpoint;
    } catch (error) {
      throw error;
    }
  };

  const mutationFollowFollerStatus = useMutation({
    mutationFn: async (followfollerData) => CheckFollowStatus(followfollerData),
    onSuccess: (data) => {
      if (data.status === "Failed") {
        setCheckFollow("Follow");
      } else if (data.status === "Success") {
        setCheckFollow("Unfollow");
      }
    },
  });

  useEffect(() => {
    const followCheckData = {
      user1_loginid: user?.login_id,
      user2_loginid: ParticularDisplay?.data?.login_id,
    };
    mutationFollowFollerStatus.mutate(followCheckData);
  }, []); 
  const FollowPostFuncton = async (data) => {
    try {
      const endPoint = await makePostRequest(FOLLOW_A_USER, data);
      return endPoint;
    } catch (error) {
      throw error;
    }
  };
  const UnFollwoPostFunction = async (data) => {
    try {
      const endPoint = await makePostRequest(UNFOLLOW_A_USER, data);
      return endPoint;
    } catch (error) {
      throw error;
    }
  };
  const mutationFollow = useMutation({
    mutationKey: ["FollowKey"],
    mutationFn: async (followData) => FollowPostFuncton(followData),
    mutationKey: ["FollowKey"],
    mutationFn: async (followData) => FollowPostFuncton(followData),
    onSuccess: () => {
      setCheckFollow("Unfollow");
    },
  });
  const mutationUnfollow = useMutation({
    mutationKey: ["UnFollowKey"],
    mutationFn: async (unFollowData) => UnFollwoPostFunction(unFollowData),
    onSuccess: () => {
      setCheckFollow("Follow");
    },
  });
  const handleFollow = () => {
    if (checkFollow === "Follow") {
      const followData = {
        user1_loginid: ParticularDisplay?.data?.login_id,
        user2_loginid: user?.login_id,
        user1_roleid: ParticularDisplay?.data?.role_id,
        user2_roleid: user?.role_id,
        follower_roleid: ParticularDisplay?.data?.role_id,
        following_roleid: user?.role_id,
      };
      mutationFollow.mutate(followData);
    } else if (checkFollow === "Unfollow") {
      const unfollowData = {
        user1_loginid: user?.login_id,
        user2_loginid: ParticularDisplay?.data?.login_id,
        user1_roleid: user?.role_id,
        user2_roleid: ParticularDisplay?.data?.role_id,
        unfollower_roleid: ParticularDisplay?.data?.role_id,
        unfollowing_roleid: user?.role_id,
      };
      mutationUnfollow.mutate(unfollowData);
    }
  };
  // --------------------------------------------------------------------------------------
  return (
    <div className="videoOverflow">
      <div className="videoPageMargin section-padding pt-lg-3">
        <div className="container-fluid">
          <div className="mdndrecords-pg">
            <div className="mdndrecords-ls d-flex flex-column gap-2">
              {/* -------------------------------------------------------------------------------------------------- */}
              <div className="bg-white">
                <VideoPath
                  videoUrl={
                    ParticularDisplay && ParticularDisplay?.data?.video_url
                  }
                  categoryId={mainCatId}
                  videoId={videoid}
                />
                <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                  <div className="w-100 my-3 d-flex align-items-center justify-content-between gap-3">
                    <h5 className="m-0 p-0">{videoDisplayData?.video_title}</h5>
                    <div className="next-play-button" onClick={handleNextPlay}>
                      <i className="fa fa-step-forward" aria-hidden="true" onClick={handleNextPlay}/>{" "}
                      Next
                    </div>
                  </div>
                  <div className="search mb-3 d-lg-none">
                    <input
                      type="text"
                      className="searchTerm"
                      placeholder="Search Videos..."
                      id="input_text"
                      onChange={handleSearchChange}
                    />
                    <button type="submit" className="searchButton">
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* --------------------------------------------------------------------------------------------------------- */}
              <div className="mt-1">
                <div className="d-flex flex-column flex-xl-row justify-content-between">
                  <div className="d-flex justify-content-start align-items-center gap-3">
                    <div className="flex-shrink-0 videoCommentImg">
                      <img
                        src={
                          ProfileVideo?.data?.profile_image &&
                          ProfileVideo.data.profile_image.status !== 404
                            ? `data:image/png;base64,${ProfileVideo.data.profile_image}`
                            : profile 
                        }
                      />
                    </div>
                    <h6 className="p-0 m-0">
                      <b>{videoDisplayData?.publisher_profile_name}</b>
                    </h6>
                    <button
                      className="border-button py-0"
                      onClick={handleFollow}
                    >
                      {checkFollow}
                    </button>
                  </div>

                  <div className="d-flex justify-content-end align-items-center gap-3 mt-2 mt-lg-0">
                    <div className="">
                      <div className="like_dislike" id="like_dislike">
                        <div
                          className={`like ${
                            videoDisplayData?.like_status === 1 && user.login_id
                              ? "fa-solid"
                              : "fa-regular"
                          } touch_feedback`}
                          id="like"
                          onClick={() => debouncedHandleVote("like")}
                          data-status={likeStatus}
                        >
                          <i
                            id="like__icon"
                            className={`fa-regular fa-thumbs-up ${
                              videoDisplayData?.like_status === 1 &&
                              user.login_id
                                ? "like__animation"
                                : ""
                            }`}
                          ></i>
                          <span className="Likenumber" id="like-number">
                            {videoDisplayData?.likes}
                          </span>
                        </div>
                        <span className="divider"></span>
                        <div
                          className={`dislike ${
                            videoDisplayData?.like_status === 0 && user.login_id
                              ? "fa-solid"
                              : "fa-regular"
                          } touch_feedback`}
                          id="dislike"
                          onClick={() => debouncedHandleVote("dislike")}
                          data-status={dislikeStatus}
                        >
                          <i
                            id="dislike__icon"
                            className={`fa-regular fa-thumbs-down ${
                              videoDisplayData?.like_status === 0 &&
                              user.login_id
                                ? "dislike__animation"
                                : ""
                            }`}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <button
                      className="button d-flex align-items-center"
                      style={{ borderRadius: "4px", height: "36px" }}
                      variant="primary"
                      onClick={handleShow}
                    >
                      <i aria-hidden="true">
                        {" "}
                        <IoShareSocial />
                      </i>
                      <span className="Likenumber">Share</span>
                    </button>
                    <ShareModal
                      show={show}
                      handleClose={handleClose}
                      currentUrl={currentUrl}
                      handleCopy={handleCopy}
                      inputRef={inputRef}
                      mlogo={mlogo}
                    />
                  </div>
                </div>

                <div className="bg-light commentBgBorder p-2 mt-2">
                  <p>
                    <b>
                      {videoDisplayData?.views} views{" "}
                      {formatTime(videoDisplayData?.created_at)}
                    </b>
                  </p>
                  <div>
                    <ReadMore html={videoDisplayData?.description} />
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p className="mb-0">
                    <b>{commentsDetail.length} Comments</b>
                  </p>
                  <div className="linkBlueText">
                    <p
                      className="commentMobileView mb-0"
                      onClick={handleShowOffcanvas}
                    >
                      Show Comments
                    </p>
                  </div>
                </div>
                <div className="product-review-list">
                  <ul className="list-inline m-0 p-0">
                    <li className="pb-3 mb-0">
                      <div className="d-flex flex-column gap-1">
                        <div className="d-flex gap-2 align-items-center justify-content-between mt-2">
                          <div className="videoCommentImg">
                            <img
                              src={
                                ProfileComment?.data?.profile_image &&
                                ProfileComment?.data?.profile_image.status !==
                                  404
                                  ? `data:image/png;base64,${ProfileComment?.data?.profile_image}`
                                  : profile
                              }
                            />
                          </div>
                          <div className="w-100">
                            <OffCanvasExample
                              name="bottom"
                              show={showOffcanvas}
                              handleClose={handleCloseOffcanvas}
                              placement="bottom"
                            />
                            <textarea
                              className="autosize"
                              ref={textareaRef}
                              value={value}
                              onChange={handleChange}
                              rows={1}
                              placeholder="Add Comments"
                            />
                          </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-end align-items-center">
                          <button
                            className="border-button"
                            onClick={handleCommentClear}
                          >
                            Cancel
                          </button>
                          <button className="button" onClick={handleComment}>
                            Send
                          </button>
                        </div>
                      </div>
                    </li>
                    <ul className="list-inline m-0 p-0">
                      {commentsDetail &&
                        commentsDetail
                          .slice()
                          .reverse()
                          .map((comment) => {
                            const profile = profileImages[comment.role_id];
                            const profilephoto = profile?.profile_image;

                            return (
                              <li
                                key={comment.id}
                                className="pb-3 d-noneSmallDevice"
                              >
                                <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center align-items-start gap-2 w-100">
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="flex-shrink-0 videoCommentImg">
                                      <img
                                        src={`data:image/png;base64,${profilephoto}`}
                                        alt="user-image"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="about-user ">
                                      <div className="d-flex align-items-center flex-wrap gap-2">
                                        <h6 className="mb-0">
                                          {profile?.profile_name}
                                          {comment.login_id}
                                        </h6>
                                        <p className="m-0 p-0 published-date">
                                          {" "}
                                          {formatTime(comment?.created_at)}
                                        </p>
                                      </div>
                                      <p
                                        className="mt-0 mb-0"
                                        dangerouslySetInnerHTML={{
                                          __html: comment?.message,
                                        }}
                                      ></p>
                                    </div>
                                  </div>
                                  <div id="container">
                                    <div id="menu-wrap">
                                      <input
                                        type="checkbox"
                                        className="toggler"
                                        checked={openMenuId === comment.id}
                                        onChange={() => toggleMenu(comment)}
                                      />
                                      <div className="dots">
                                        <div></div>
                                      </div>
                                      {user && (
                                        <div className="menu ">
                                          <div>
                                            <button
                                              onClick={() =>
                                                handleDelete(comment)
                                              }
                                              className="p-0"
                                            >
                                              <li>Delete</li>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                    </ul>
                  </ul>
                </div>
              </div>
            </div>
            {/* ----------------------------------------------------------------------------------------------- */}
            <div className="mdndrecords-rs">
              <div className="search mb-3 videoTitleDesk">
                <input
                  type="text"
                  className="searchTerm"
                  placeholder="Search Videos..."
                  id="input_text"
                  onChange={handleSearchChange}
                />
                <button type="submit" className="searchButton">
                  <i className="fa fa-search"></i>
                </button>
              </div>
              {mdndData &&
                mdndData.length > 0 &&
                mdndData?.map((item, index) => (
                  <div className="iq-watching-block d-flex mdndrecords-video overflow-hidden">
                    <div className="position-relative">
                      <div className="video-thumbnailsm overflow-hidden imgRadius">
                        <img
                          src={
                            item?.thumbnail && item?.thumbnail.status !== 404
                              ? `data:image/png;base64,${item?.thumbnail}`
                              : NoImage
                          }
                          alt={item?.video_title}
                          className="w-100 d-flex align-items-center"
                          onClick={(e) => handleSideVideo(e, item)}
                        />
                      </div>
                    </div>
                    <div className="w-100 d-flex justify-content-between p-2">
                      <div
                        className="w-100 me-3"
                        onClick={(e) => handleSideVideo(e, item)}
                      >
                        <p className="mb-0">
                          <b>{item.video_title}</b>
                        </p>
                        <p className="mb-0">{item.profile_name}</p>
                        <p className="mb-0 videoMinuts">
                          {formatTime(item.created_at)}
                        </p>
                      </div>
                      <div>
                        <div id="container">
                          <div id="menu-wrap">
                            <input
                              type="checkbox"
                              className="toggler"
                              checked={sideOpenMenuId === item.id}
                              onChange={() => toggleMenuSide(item.id)}
                            />
                            <div className="dots">
                              <div></div>
                            </div>
                            <div className="menu p-1">
                              <div>
                                <ul className="p-0">
                                  <li>
                                    <i
                                      className="fa fa-share"
                                      aria-hidden="true"
                                    ></i>
                                    <a
                                      href="#"
                                      className="link ms-2"
                                      onClick={handleShow}
                                    >
                                      Share
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {/* --------------------------------------------------------------------------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoStore;
