import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const TestPage = () => {
  const { shabaName, encryptedData } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/SeasonTickets/OrganizerLandingPage?ci=101&r_id=2400");
  }, []);

  return <div></div>;
};

export default TestPage;
