import React from "react";
import eventnoimage from "./eventnoimage.jpg"

export default function EventNoimage(){

    return(
        <>
         <img src={eventnoimage} alt="No image Found"  style={{height: "auto", width: "100%"}}/>
        </>
    )
}