import React from "react";
import Kauvery_Hospital_logo_data from "./Kauvery_Hospital_logo.png"

export default function Kauvery_Hospital_logo(){

    return(
        <>
         <img src={Kauvery_Hospital_logo_data} alt="No Data Found" className="BannerImg"/>
        
        </>
    )
}