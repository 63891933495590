import React from "react";
import { TextBox } from "devextreme-react";
import TextArea from "devextreme-react/text-area";
import { useState, useContext, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AuthContext from "../../../context/AuthContext";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { BASEPATH } from "../../../config/config";
import "./MyProfile.css";
import { GET_MESSAGES_CONTACT } from "../../../Api/api";
import DataGrid, {
  Column,
  Paging,
} from "devextreme-react/data-grid";
import Modal from "react-bootstrap/Modal";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Messages_myprofile(props) {
  const { user } = useContext(AuthContext);
  const [fetchMessagesCollection, setFetchMessagesCollection] = useState({
    ReceiveData: "",
  });
  const [loading, setLoading] = useState(true); // Loader state
  const [selectedRowData, setSelectedRowData] = useState({});
  const [popupVisible, setPopupVisible] = useState(false);
  const token = localStorage.getItem("MDnD_auth");

  useEffect(() => {
    if (user) {
      mutationGetAllMessages.mutate();
    }
  }, []);

  const GetMessage = () => GET_MESSAGES_FROM_BACKEND(GET_MESSAGES_CONTACT);

  const GET_MESSAGES_FROM_BACKEND = async (GET_MESSAGES_CONTACT) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_MESSAGES_CONTACT}`,
        data: {
          toroleid: user ? user.role_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },
        timeout: 60000,
        responseType: "json",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationGetAllMessages = useMutation({
    mutationKey: ["get_messages"],
    mutationFn: () => GetMessage(),
    onSuccess: (data) => {
      setLoading(false); // Stop loading after data is fetched

      if (data.status === "success") {
        let filteredArray = data.data.sort(
          (a, b) => new Date(b.created_date) - new Date(a.created_date)
        );

        setFetchMessagesCollection((prevState) => ({
          ...prevState,
          ReceiveData: filteredArray,
        }));
      } else {
        setFetchMessagesCollection((prevState) => ({
          ...prevState,
          ReceiveData: "",
        }));
      }
    },
    onError: (error) => {
      setLoading(false); // Stop loading on error
      setFetchMessagesCollection((prevState) => ({
        ...prevState,
        ReceiveData: "",
      }));
    },
  });

  const renderDate = ({ data }) => {
    const date = new Date(data.created_date);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderTime = (e) => {
    let filteredTime;
    if (e.value) {
      const date = new Date(e.value);
      const hours = date.getUTCHours().toString().padStart(2, "0");
      const minutes = date.getUTCMinutes().toString().padStart(2, "0");
      const seconds = date.getUTCSeconds().toString().padStart(2, "0");
      filteredTime = `${hours}:${minutes}:${seconds}`;
    }
    return filteredTime;
  };

  const handleRowClick = (e) => {
    if (e.data) {
      setSelectedRowData(e.data);
      setPopupVisible(true);
    }
  };

  const handleClose = () => {
    setPopupVisible(false);
    setSelectedRowData({});
  };

  const renderDate_popup = (param_date) => {
    if (param_date) {
      const date = new Date(param_date);
      const day = date.getUTCDate().toString().padStart(2, "0");
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    }
  };

  return (
    <>
      {user && (
        <div className="row">
          {loading ? (
            <div>
              <Skeleton height={50} count={10} />
            </div>
          ) : (
            <DataGrid
              dataSource={fetchMessagesCollection.ReceiveData || []}
              showBorders={true}
              paging={{ pageSize: 5 }}
              rowAlternationEnabled={true}
              columnHidingEnabled={true}
              onRowClick={handleRowClick}
            >
              <Paging enabled={true} />
              <Column
                caption="Sl.No"
                cellRender={(cellInfo) => {
                  const { rowIndex, component } = cellInfo;
                  const pageSize = component.pageSize();
                  const pageIndex = component.pageIndex();
                  const totalCount = component.totalCount();
                  const descendingIndex =
                    totalCount - (pageIndex * pageSize + rowIndex);
                  return descendingIndex;
                }}
                width={60}
                allowEditing={false}
              />
              <Column caption="Date" cellRender={renderDate} dataField="created_date" allowEditing={false} />
              <Column dataField="created_date" caption="Time" cellRender={renderTime} allowEditing={false} />
              <Column dataField="name" caption="Name" />
              <Column dataField="email" caption="Email" />
              <Column dataField="mobile" caption="Phone" />
              <Column dataField="message" caption="Message" />
            </DataGrid>
          )}
          <Modal show={popupVisible} onHide={handleClose} centered>
            <Modal.Header closeButton
              className="m-0 d-flex align-items-center text-center border-light"
              style={{ padding: "10px 10px", }}
            >
              <h5 className="text-nowrap text-center m-auto">Row Details</h5>
            </Modal.Header>
            <Modal.Body className="m-0 d-flex flex-column gap-2" style={{ padding: '20px 24px' }}>
              {selectedRowData && (
                <div>
                  <TextArea value={renderDate_popup(selectedRowData?.created_date)} label="Date" labelMode="floating" readOnly />
                  <TextArea label="Name" labelMode="floating" value={selectedRowData?.name} readOnly />
                  <TextArea label="Email" labelMode="floating" value={selectedRowData?.email} readOnly />
                  <TextArea label="Phone" labelMode="floating" value={selectedRowData?.mobile} readOnly />
                  <TextArea label="Message" labelMode="floating" value={selectedRowData?.message} readOnly />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <button onClick={handleClose} className="button">
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
}
