import React from 'react';
import './ShareComponent.css';
import { FaFacebook } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faFacebook, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const ShareComponent = ({ shareMessage }) => {
  const handleShare = (platform) => {
    let shareUrl = '';
    const urlToShare = window.location.href; // Get the current URL
    const encodedMessage = encodeURIComponent(shareMessage); // Encode the share message

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}&quote=${encodedMessage}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}&url=${urlToShare}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodedMessage} ${urlToShare}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, '_blank');
  };

  return (
    <div className="d-flex justify-content-center" >
      {/* <FontAwesomeIcon icon={faFacebook} onClick={() => handleShare('facebook')} style={{ margin: '5px', cursor: 'pointer' }} /> */}<FaFacebook  onClick={() => handleShare('facebook')} style={{ margin: '5px', cursor: 'pointer' }} />
      {/* <FontAwesomeIcon icon={faTwitter} onClick={() => handleShare('twitter')} style={{ margin: '5px', cursor: 'pointer' }} /> */}<FaXTwitter onClick={() => handleShare('twitter')} style={{ margin: '5px', cursor: 'pointer' }} />
      {/* <FontAwesomeIcon icon={faWhatsapp} onClick={() => handleShare('whatsapp')} style={{ margin: '5px', cursor: 'pointer' }} /> */}<IoLogoWhatsapp onClick={() => handleShare('whatsapp')} style={{ margin: '5px', cursor: 'pointer' }}/>
    </div>
  );
};

export default ShareComponent;
