import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import successVideo from "../../global/Success.mp4";
import './payment.css';
import confetti from 'canvas-confetti';

const Success = () => {
  // Confetti effect
  const startConfetti = () => {
    const duration = 2 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 560, ticks: 120, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(() => {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);

      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
        })
      );
    }, 250);
  };

  useEffect(() => {
    startConfetti();
  }, []);

  return (
    <div>
      <div className="payment">
        <div>
          <video
          style={{ maxWidth:"100%" }}
            width="auto"
            height="300vh"
            autoPlay
            muted
            onEnded={(e) => e.target.pause()}
          >
            <source src={successVideo} type="video/mp4" />
          </video>
        </div>
        <h3 className="mb-3" style={{ color: "#00b74b" }}>
          <b>Payment Successful!</b>
        </h3>
        <p className="mb-5" style={{ color: "#00b74b" }}>
          Please check your email for ticket confirmation.
        </p>
        <Link
          to="/"
          className="text-decoration-none text-decoration-underline"
          style={{ color: "blue" }}
        >
          Back to home
        </Link>
      </div>

    </div>
  );
};

export default Success;
