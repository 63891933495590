import React, { useState, useEffect } from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { dataSource } from '../../../global/constants';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const VideoPurchased = () => {
  // State to handle loading
  const [loading, setLoading] = useState(true);

  // Simulate data fetching
  useEffect(() => {
    // Simulate a delay for loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay for example

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <Skeleton
          height={50} 
          count={10} 
        />
      ) : (
        <DataGrid
          dataSource={dataSource}
          showBorders={true}
          columnHidingEnabled={true}
          columnAutoWidth={true}
          rowAlternationEnabled={true}
        >
          <Column width={80} dataField="ID" caption='S.no' />
          <Column
            dataField="StartDate"
            dataType="Date"
          />
          <Column
            dataField="Status"
            caption='Transaction ID'
          />
          <Column dataField="Priority" />
          <Column dataField="Subject" />
          <Column dataField="Status" />
        </DataGrid>
      )}
    </div>
  );
}

export default VideoPurchased;
