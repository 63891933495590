import React, { useState, useEffect } from "react";
import DataGrid, {
  Column,
  Selection,
  FilterRow,
  Paging,
} from "devextreme-react/data-grid";

const AddOnCodeGrid = ({ addOnCodeDataSource, setFieldValue }) => {
  const handleSelectionChanged = (e) => {
    const selectedAddOnCode = [];
    if (e && e.selectedRowsData && e.selectedRowsData.length > 0) {
      e.selectedRowsData.map((data, index) => selectedAddOnCode.push(data.id));
    }
    setFieldValue("addon_codes", selectedAddOnCode);
  };
  return (
    <>
      <DataGrid
        dataSource={addOnCodeDataSource || []}
        showBorders={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        onSelectionChanged={(e) => handleSelectionChanged(e)}
      >
        <Selection mode="multiple" />
        <Paging enabled={true} />
        <Column dataField="addon_code" caption="Add-on code" />
        <Column dataField="description" caption="Description" />

        <Column
          dataField="original_value"
          caption="Original Value"
        />
        <Column
          dataField="amount_inr"
          caption="Amount (INR)"
        />
      </DataGrid>
    </>
  );
};

export default AddOnCodeGrid;
