import notify from "devextreme/ui/notify";

function SuccessNewToaster(success, message, timeout) {
  return notify({
    message: message,
    type: success,
    displayTime: timeout,
    height: 50,
    width: 600,
  });
}

export default SuccessNewToaster;
