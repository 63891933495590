import React, { useEffect,useState } from "react";
// import image1 from "../../assets/images/blog/blog1.webp";
import image1 from "../../../assets/images/blog/blog1.webp";
import "./Blog.css";
import { useMutation } from "@tanstack/react-query";
import { makePostRequest } from "../../../Api/requestMethods";
import notify from "devextreme/ui/notify";
import { DURATION, ERROR } from "../../../global/ToastMsgConstants.tsx";
import { useLocation } from "react-router-dom";
import { GET_BLOG_PERTICULAR_PROFILE } from "../../../Api/api";
import { BlogImage } from "../../../Subpages/MainPage/constant.js";

export default function SingleBlog() {

const location = useLocation();
const blogId = location?.state?.blogid
console.log (blogId,"blogins")

  const [dataSource, setDataSource] = useState({
    PerticulatBlogData: "",
  });

  const PerticularBlogProfile = async(blogProfile)=>{
    try{
      const response= await makePostRequest(GET_BLOG_PERTICULAR_PROFILE(),blogProfile);
      return response;
    }catch (error){
      throw error
    }
  }

  const BlogperticularDetail = useMutation({
    mutationKey:["fetchperticularblog"],
    mutationFn:(data) => PerticularBlogProfile(data),
    onSuccess:(data)=>{
      data && setDataSource((prev)=>({...prev,PerticulatBlogData:data.data}))
    },
    onError:(error)=>{
      notify(error.message,ERROR,DURATION)
    }
  })

  useEffect(()=>{
  if(blogId && blogId!=="" && blogId!== null){
    const body ={
      blog_id : blogId,
    }
    BlogperticularDetail.mutate(body);
  }
  },[blogId])

console.log(dataSource?.PerticulatBlogData)
  return (
    <>
      <div className="section p-lg-0 mobileTopMargin">
        <div className="container-fluid">
          <div className="row">
            <h3>{dataSource?.PerticulatBlogData[0]?.blog_name}</h3>
            <div className="col-xl-8">
              <div className="iq-blog blog-detail">
                <a
                  className="blog-image d-block overflow-hidden"
                >
                  <img
                    src={`${BlogImage}/${dataSource?.PerticulatBlogData[0]?.blog_photo}`}
                    alt="blog-image"
                    className="img-fluid w-100"
                  />
                </a>

                <div className="iq-blog-box pt-4">
                  <div className="iq-blog-meta d-flex justify-content-between mb-3">
                    <ul className="list-inline mb-0 ">
                      <li className="m-0 p-0">
                        <a
                          className="text-black fs-5"
                        >
                          {" "}
                          <i
                            className="far fa-calendar-alt me-1"
                            aria-hidden="true"
                          ></i>
                          17 Jan 2024
                        </a>
                      </li>
                    </ul>
                    <div className="d-flex align-items-center">
                      <div className="demo-container">
                        <div className="buttons">
                          <i className="fas fa-share shareBtn"></i>
                          <div className="fb soc-but">
                            <a className="back" href="#">
                              <i className="fa-brands fa-facebook"></i>
                            </a>
                          </div>
                          <div className="tw soc-but">
                            <a className="back" href="#">
                              <i className="fa-brands fa-linkedin-in"></i>
                            </a>
                          </div>
                          <div className="gp soc-but">
                            <a className="back" href="#">
                              <i className="fa-brands fa-x-twitter"></i>
                            </a>
                          </div>
                          <div className="vk soc-but">
                            <a className="back" href="#">
                              <i className="fa-brands fa-whatsapp"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                   {dataSource?.PerticulatBlogData[0]?.blog_description?.replace(/<\/?[^>]+(>|$)/g, "")}
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-xl-4 mt-5 mt-xl-0">
              <div className="widget-area">
                <div className="widget iq-widget-blog">
                  <ul className="list-inline p-0 m-0">
                    <li className="d-flex align-items-center gap-4">
                      <div className="img-holder">
                        <a href="#">
                          <img
                            src={image1}
                            alt=""
                            className="img-fluid h-100 w-100 rounded-circle object-cover"
                          />
                        </a>
                      </div>
                      <div className="post-blog">
                        <a className="new-link">
                          <h5 className="widget-title position-relative mb-0">
                            By {dataSource?.PerticulatBlogData[0]?.organiser_names}
                          </h5>
                        </a>
                      </div>
                    </li>
                  </ul>
                  <ul className="list-inline p-0 m-0">
                    <li className="p-0 mb-4">
                      <h5>
                        <b>{dataSource?.PerticulatBlogData[0]?.organiser_names}</b>
                      </h5>
                    </li>
                    <li className="d-flex align-items-center gap-4 m-0 p-0">
                      <div className="post-blog">
                        <a className="new-link">
                          <h6 className="post-title">
                            The Most Anticipated Movies
                          </h6>
                        </a>
                        <ul className="list-inline mb-2">
                          <li className="list-inline-item border-0 mb-0 pb-0">
                            <a href="#" className="blog-data">
                              {" "}
                              <i
                                className="far fa-calendar-alt me-1"
                                aria-hidden="true"
                              ></i>
                              September 23, 2022
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="d-flex align-items-center gap-4 m-0 p-0">
                      <div className="post-blog">
                        <a className="new-link">
                          <h6 className="post-title">
                            Amy Adams Always Dreamed
                          </h6>
                        </a>
                        <ul className="list-inline mb-2">
                          <li className="list-inline-item border-0 mb-0 pb-0">
                            <a href="#" className="blog-data">
                              {" "}
                              <i
                                className="far fa-calendar-alt me-1"
                                aria-hidden="true"
                              ></i>
                              September 23, 2022{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <hr></hr>
                    <li className="d-flex align-items-center gap-4 m-0 p-0">
                      <div className="post-blog">
                        <a className="new-link">
                          <h6 className="post-title">
                            WandaVision Will Reveal Scarlet Witch’s Untapped
                            Powers{" "}
                          </h6>
                        </a>
                        <ul className="list-inline mb-2">
                          <li className="list-inline-item  border-0 mb-0 pb-0">
                            <a href="#" className="blog-data">
                              {" "}
                              <i
                                className="far fa-calendar-alt me-1"
                                aria-hidden="true"
                              ></i>
                              September 23, 2022
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
