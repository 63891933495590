import React from "react";
import { HeadProvider, Meta } from 'react-head';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, } from 'react-share';
import { useRef } from "react";

export default function ShareSeasonEvent({ shareUrl }) {



    return (

        <>
            <WhatsappShareButton style={{ color: "green" }} url={shareUrl}>
                <i
                    className="fa-brands fa-whatsapp"
                    style={{
                        fontSize: "50px",
                        cursor: "pointer",
                    }}
                ></i>
            </WhatsappShareButton>

            <FacebookShareButton style={{ color: "blue" }} url={shareUrl} >
                <i className="fa-brands fa-facebook" style={{
                    fontSize: "50px",
                    cursor: "pointer",
                }}></i>
            </FacebookShareButton>
            <TwitterShareButton style={{ color: "black" }} url={shareUrl} >

                <i className="fa-brands fa-x-twitter" style={{
                    fontSize: "50px",
                    cursor: "pointer",
                }}></i>
            </TwitterShareButton>




        </>)

}