import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

export default function ShareToSocialMedia_myform({ shareUrl, postdescr }) {
  return (
    <>

<WhatsappShareButton style={{ color: "green" }} url={shareUrl}>
        <i
          className="fa-brands fa-whatsapp"
          style={{
            fontSize: "40px",
            cursor: "pointer",
            marginRight: "40px",
          }}
        ></i>
      </WhatsappShareButton>
      <FacebookShareButton style={{ color: "blue" }} url={shareUrl}>
        <i
          className="fa-brands fa-facebook"
          style={{
            fontSize: "40px",
            cursor: "pointer",
            marginRight: "40px",
          }}
        ></i>
      </FacebookShareButton>
      <TwitterShareButton style={{ color: "black" }} url={shareUrl}>
        <i
          className="fa-brands fa-x-twitter"
          style={{
            fontSize: "40px",
            cursor: "pointer",
            marginRight: "40px",
          }}
        ></i>
      </TwitterShareButton>
      
    </>
  );
}
