export const ERROR : string = "error";
export const WARNING : string = "warning";
export const SUCCESS : string = "success";

export const DURATION: number = 4000;

export const COMMONFIELDREQUIRED: string = "This field is required";
export const INVALIDURL = "Invalid URL"


