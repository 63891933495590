import React from "react";
import { HeadProvider, Meta } from 'react-head';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, } from 'react-share';
import mdndlogo from "../../global/mdndlogo.png"

export default function ShareSocialMediaEvents({ eventdescr, eventimage, shareUrl }){
    
    
    return(
    <>

{/* <HeadProvider>
  <Meta property="og:url" content={shareUrl} />
  <Meta property="og:type" content="website" />
  <Meta property="og:title" content={eventdescr} />
  <Meta property="og:description" content={eventdescr} />
  <Meta property="og:image" content={eventimage} />
  <Meta property="og:image:width" content="600" />
  <Meta property="og:image:height" content="315" />
  </HeadProvider> */}




<WhatsappShareButton style={{color: "green"}} url={shareUrl}>
            <i
                                          className="fa-brands fa-whatsapp"
                                          style={{
                                            fontSize: "50px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
            </WhatsappShareButton>

            <FacebookShareButton style={{color: "blue"}} url={shareUrl} >
            <i className="fa-brands fa-facebook" style={{
                  fontSize: "50px",
                  cursor: "pointer",
                }}></i>
            </FacebookShareButton>
            <TwitterShareButton style={{color: "black"}} url={shareUrl} >
            
              <i className="fa-brands fa-x-twitter" style={{
                  fontSize: "50px",
                  cursor: "pointer",
                }}></i>
              </TwitterShareButton>

        </>)
}