const getEventKeys = () => {
   
      return {
        countryKey: process.env.REACT_APP_EVENT_COUNTRY_KEY || null,
        eventKey: process.env.REACT_APP_EVENT_EVENT_KEY || null,
      };
   
  
   
  };

  export default getEventKeys;