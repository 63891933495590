import React from "react";
import IMAGE1 from "../assets/images/genre/01.webp";
import "./MDndStore.css";
import { Link, NavLink } from "react-router-dom";

function MDndStore() {
  return (
    <div>
      <section className="continue-watching-block section-padding-top pt-lg-3">
        <div className="container-fluid">
          <div className="overflow-hidden">
            <div className="d-flex align-items-center justify-content-between px-1">
              <h6 className="main-title text-capitalize">
                Video Rental / Bharatnatyam
              </h6>
            </div>

            <div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-5">
              <div className="iq-watching-block mb-lg-3">
                <NavLink to="/VideoStore">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                </NavLink>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  <div><i class="fas fa-ellipsis-v fs-5"></i></div>
                </div>
              </div>

              <div className="iq-watching-block mb-lg-3">
                <div className="block-images position-relative">
                  <div className="iq-image-box overly-images">
                    <a href="./movie-detail.html" className="d-block">
                      <img
                        src={IMAGE1}
                        alt="movie-card"
                        className="img-fluid object-cover w-100 d-block border-0"
                      />
                    </a>
                  </div>
                  <div className="iq-preogress">
                    <div className="d-flex justify-content-between px-2 mb-1">
                    <p className="mb-0 videoViewBg">350 views</p>
                    <span className="data-left-timing font-size-14 fw-500 text-lowercase">
                      70 of 230 m
                    </span>
                    </div>
                    <div
                      className="progress"
                      role="progressbar"
                      aria-label="Example 2px high"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="progress-bar w-50"></div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between p-2">
                  <div className="videoProfileImg">
                    <img src={IMAGE1}></img>
                  </div>
                  <div className="w-100 ms-2">
                    <h6 className="mb-0"><b>Weekend Raga by Karthick</b></h6>
                    <p className="mb-0">Music of Madras</p>
                    <p className="mb-0 videoMinuts">31 minutes ago</p>
                    
                  </div>
                  
                  <div><i class="fas fa-ellipsis-v fs-5"></i>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MDndStore;
