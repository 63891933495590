const getHostAPIUrl = () => {
  if (process.env.REACT_APP_NODE_ENV === "production") {
    return {
      basePath: "https://api.mdnd.in/",
      sharePath: "https://mdnd.in",
    };
  }
  if (process.env.REACT_APP_NODE_ENV === "staging") {
    return {
      basePath: "https://apistaging.musicofmadras.in/",
      sharePath: "https://staging.musicofmadras.in",
    };
  }

  return "";
};

export default getHostAPIUrl;
