import DOMPurify from "dompurify";
import { useEffect, useState } from "react";

const EventDetailsDescription = ({ dataSource }) => {
  const [sanitizedHTML, setSanitizedHTML] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const charLimit = 100; 

  useEffect(() => {
    try {
      const eventDescription =
        dataSource?.eventDetails &&
        dataSource?.eventDetails[0]?.event_description;
      if (eventDescription) {
        const sanitizedHTML = DOMPurify.sanitize(eventDescription);
        setSanitizedHTML(sanitizedHTML);
      }
    } catch (error) {
      console.error("Error processing event description:", error);
    }
  }, [dataSource]);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const truncatedHTML = sanitizedHTML.substring(0, charLimit) + "...";

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: isExpanded ? sanitizedHTML : truncatedHTML,
        }}
      />
      {sanitizedHTML.length > charLimit && (
        <button onClick={toggleReadMore} className="read-more-btn">
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export default EventDetailsDescription;
