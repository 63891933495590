import image1 from "../../assets/images/cast/01.webp";
import image2 from "../../assets/images/cast/02.webp";
import image3 from "../../assets/images/cast/03.webp";
import image4 from "../../assets/images/cast/04.webp";
import image5 from "../../assets/images/cast/05.webp";
import image6 from "../../assets/images/cast/06.webp";
import image7 from "../../assets/images/cast/07.webp";
import image8 from "../../assets/images/cast/08.webp";
import image9 from "../../assets/images/cast/09.webp";
import image10 from "../../assets/images/cast/10.webp";
import image11 from "../../assets/images/cast/11.webp";
import image12 from "../../assets/images/cast/12.webp";
//   ----------------------------------------------------------------------------------------------------------------------------------------   

import toimage1 from "../../assets/images/genre/New_admin/Abhang.jpg";
import toimage2 from "../../assets/images/genre/New_admin/Arangetram.jpg";
import toimage3 from "../../assets/images/genre/New_admin/Bardo_Chham.jpg";
import toimage4 from "../../assets/images/genre/New_admin/Bhajan.jpg";
import toimage5 from "../../assets/images/genre/New_admin/Bhangra.jpg";
import toimage6 from "../../assets/images/genre/New_admin/Bharatanatyam.jpg";
import toimage7 from "../../assets/images/genre/New_admin/Bharatanatyam.jpg";
import toimage8 from "../../assets/images/genre/New_admin/Bharatanatyam.jpg";
import toimage9 from "../../assets/images/genre/New_admin/Bharatanatyam.jpg";
import toimage10 from "../../assets/images/genre/New_admin/Bharatanatyam.jpg";
import toimage11 from "../../assets/images/genre/New_admin/Bharatanatyam.jpg";

//---------------------------------------------------------------------------------------------------------------------------------------------------------

import profileimage1 from "../../assets/images/cast/01.webp";
import profileimage2 from "../../assets/images/cast/02.webp";
import profileimage3 from "../../assets/images/cast/03.webp";
import profileimage4 from "../../assets/images/cast/04.webp";
import profileimage5 from "../../assets/images/cast/05.webp";
import profileimage6 from "../../assets/images/cast/06.webp";
import profileimage7 from "../../assets/images/cast/07.webp";
import profileimage8 from "../../assets/images/cast/08.webp";
import profileimage9 from "../../assets/images/cast/09.webp";
import profileimage10 from "../../assets/images/cast/10.webp";


// -----------------------------------------------------------------------------------------------------------------------------------------------------
export const  bharatArtist =[
   {
     id: 1,
     mainCatId : 1,
     Image_name: image1,
     artistName : "Karen Gilchrist",
     profileName : "karengilchrist"       
   },
   {
     id: 2,
     mainCatId : 2,
     Image_name: image2,
     artistName: "Debbi Bossi",
     profileName : "debbibossi"
   },
   {
     id: 3,
     mainCatId : 4,
     Image_name: image3,
     artistName: "James Chinlund",
     profileName : "jameschinlund"
   },
   {
     id: 4,
     mainCatId : 3,
     Image_name: image4,
     artistName: "Brenda Chapman",
     profileName : "brendachapman"
   },
   {
     id: 5,
     mainCatId : 2,
     Image_name: image5,
     artistName: "Mark Livolsi",
     profileName : "marklivolsi"
   },
   {
     id: 6,
     mainCatId : 5,
     Image_name: image6,
     artistName: "Hans Zimmer",
     profileName : "hanszimmer"
   },
   {
     id: 7,
     mainCatId : 1,
     Image_name: image7,
     artistName: "Caleb Deschanel",
     profileName : "calebdeschanelr"
   },
   {
     id: 8,
     mainCatId : 7,
     Image_name: image8,
     artistName: "John Bartnicki",
     profileName : "johnbartnicki"
   },
   {
     id: 9,
     mainCatId : 6,
     Image_name: image9,
     artistName: "Jeffrey Silver",
     profileName : "jeffreysilver"
   },  {
     id: 2,
     mainCatId : 7,
     Image_name: image10,
     artistName: "Debbi Bossi",
     profileName : "debbibossi"
   },
   {
     id: 3,
     mainCatId : 6,
     Image_name: image11,
     artistName: "James Chinlund",
     profileName : "jameschinlund"
   },
   {
     id: 4,
     mainCatId : 4,
     Image_name: image12,
     artistName: "Brenda Chapman",
     profileName : "brendachapman"
   }
]

// --------------------------------------------------------------------------------------------------------------------------------------------------

export const geners = [
   {
     id: 1,
     genName : "Bharatnatyam",
     Image_name: toimage1, 
   },
   {
     id: 2,
     genName : "Kathak",
     Image_name: toimage2, 
   },
   {
     id: 3,
     genName : "Kuchipudi",
     Image_name: toimage3, 
   },
   {
     id: 4,
     genName : "Odissi",
     Image_name: toimage4, 
   },
   {
     id: 5,
     genName : "Kathakali",
     Image_name: toimage5, 
   },
   {
     id: 6,
     genName : "Sattriya",
     Image_name: toimage6, 
   },
   {
     id: 7,
     genName : "Manipuri",
     Image_name: toimage7, 
   },
   {
     id: 8,
     genName : "Mohiniyattam",
     Image_name: toimage8,
 
   },
   {
    id: 9,
    genName : "Mohiniyattam",
    Image_name: toimage9
  },
  {
    id: 10,
    genName : "Mohiniyattam",
    Image_name: toimage10 
  },
  {
    id: 11,
    genName : "Mohiniyattam",
    Image_name: toimage11 
  },
  {
    id: 12,
    genName : "Mohiniyattam",
    Image_name: toimage4 
  },
  {
    id: 13,
    genName : "Mohiniyattam",
    Image_name: toimage7 
  },
  {
    id: 14,
    genName : "Mohiniyattam",
    Image_name: toimage4 
  },
  {
    id: 15,
    genName : "Mohiniyattam",
    Image_name: toimage7 
  },
]

export const profile = [
 {
   id: 1,
   profileName : "Gopi",
   image_name : profileimage1
 },
 {
   id: 2,
   profileName : "Debbi",
   image_name : profileimage2
 },
 {
   id: 3,
   profileName : "Sudhakar",
   image_name : profileimage3
 },
 {
   id: 4,
   profileName : "Raji Bossi",
   image_name : profileimage4
 },
 {
   id: 5,
   profileName : "Sanjeev",
   image_name : profileimage5
 },
 {
   id: 6,
   profileName : "Joti thakur",
   image_name : profileimage6
 },
 {
   id: 7,
   profileName : "Vijay",
   image_name : profileimage7
 },
 {
   id: 8,
   profileName : "Askitama",
   image_name : profileimage8
 },
 {
   id: 9,
   profileName : "Sambath",
   image_name : profileimage9
 },
 {
   id: 10,
   profileName : "Rakhi Bossi",
   image_name : profileimage10
 },
]

export const profilebuttons = [
    {
      id: 1,
      buttonName : "About",
    },
    {
      id: 2,
      buttonName : "Post",
    },
    {
      id: 3,
      buttonName : "Contact",
    },
    {
      id: 3,
      buttonName : "My Calendar",
    },
    {
      id: 3,
      buttonName : "Videos",
    },
    {
      id: 3,
      buttonName : "Blogs",
    },
    {
      id: 3,
      buttonName : "Tagged Blogs",
    }
]
