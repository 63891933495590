import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../global/ToastMsgConstants.tsx";

export const signupSchema = Yup.object({
  name: Yup.string().required(COMMONFIELDREQUIRED),
  email: Yup.string()
    .email("Please enter valid email.")
    .required(COMMONFIELDREQUIRED),
  password: Yup.string().min(2).required(COMMONFIELDREQUIRED),
  mobile: Yup.string().required(COMMONFIELDREQUIRED),
});

export const LandingPageSchema = Yup.object({
  content: Yup.string().required(COMMONFIELDREQUIRED),
  show_to_public: Yup.string().required(COMMONFIELDREQUIRED),
});

export const organiser_landing_page_Schema = Yup.object({
  member_name: Yup.string().required(COMMONFIELDREQUIRED),
  member_email: Yup.string()
    .email("Please enter valid email.")
    .required(COMMONFIELDREQUIRED),
  member_mobile: Yup.string().required(COMMONFIELDREQUIRED),
});
