import React from 'react';
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Lookup,
  Form,
  ColumnChooser,
  CursorOffset,
} from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { useEffect, useRef } from 'react';
import 'devextreme-react/text-area';
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { Modal} from "react-bootstrap";
import { sizeValues, fontValues, headerValues, fontSizeOptions, fontFamilyOptions, headerOptions, timeLabel } from "../../../components/others";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
} from "devextreme-react/html-editor";
import { Item } from 'devextreme-react/form';
import { useState } from 'react';
import { employees } from './data';
import { openEditor } from "react-profile";
import "./Blog.css";
import { TextBox, SelectBox, TagBox } from 'devextreme-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import blogValidationSchema from './Validation/Auth';
import notify from 'devextreme/ui/notify';
import { DURATION, ERROR, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import { makeGetRequest, makePostRequest } from '../../../Api/requestMethods';
import { ALL_ARTIST_DETAIL, ALL_ORGSNISER_TAG_DETAIL, DELETE_BLOG_CATEGORY, GET_BlOG_CATEGORIES, GET_BlOG_MAIN_CATEGORIES, TOGGLE_BLOG_CATEGORY, UPDATE_BLOG_CATEGORY, VIEW_BLOG_CATEGORY } from '../../../Api/api';
import { markup, tabs } from "../../../components/others";
import 'react-datepicker/dist/react-datepicker.css';
import { ArtistallImg, BlogImage } from '../../../Subpages/MainPage/constant.js';
import { HurrayImage } from '../../../Subpages/MainPage/constant.js';
import { TagArtistDetailFetch, TagedOrganiserDetailFetch } from './Validation/BlogsFetch.js';
import Switch, { SwitchTypes } from 'devextreme-react/switch';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { Scrolling, Pager} from 'devextreme-react/data-grid';
import { useFormik } from 'formik';
import { jwtDecode } from 'jwt-decode';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';





const ViewBlogs = () => {

  const [blogs, setBlogs] = useState([]);
  const [isMultiline, setIsMultiline] = useState(true);
  const [index, setIndex] = useState();
  const [blogId, setBlogid] = useState(null);
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const findInput = useRef(null);
  const [toggleState, setToggleState] = useState({});
  const [update, SetUpdate] = useState(1);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState({
    addCategory: '',
    adingandupdate: "",
    selectedRowImage: "",
    ifEditimagepoint: false,
    openThePopup: false,
    selectallData: "",
    imageFileChanged: false
  })

  const [formData, setFormData] = useState({
    createddate: "",
    blblogtitleog: "",
    blogmaincate: "",
    blogmaincatename: null,
    blogimage: null,
    blogstatus: "",
    blogshowtopublic: "",
    blogcontent: "",
    blogtagart: "",
    blogtagorg: "",
    toggleData: true
  })

  const [formErrors, setFormErrors] = useState({
    createddate: '',
    blblogtitleog: '',
    blogmaincate: '',
    blogmaincatename: '',
    blogimage: '',
    blogcontent: '',
    blogtagart: '',
    blogtagorg: '',
    toggleData: '',
  });

  // -------------------------------------------------------------------------------------
  const [tagArtist, setTagArtist] = useState("");
  const [tagOrganiser, setTagOrganiser] = useState("");

  let TagArtistarray = tagArtist.split(",").map(Number);
  let TagOrganiserarray = tagOrganiser.split(",").map(Number);
  // -----------------------------------TOkenState--------------------------------------------------
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  console.log(user, "USER from BLOG jwt");
  // -----------------------------------TOkenState--------------------------------------------------

  const handleRowUpdate = (e) => {
    const data = e.data
    const body = {
      "dateCategory": data.createddate,
      "blog": data.blogtitle,
      "category": data.category,
      "picture": data.picture,
      "state": data.state
    }
    const formDataToSend = new FormData();
    for (let key in body) {
      formDataToSend.append(key, body[key]);
    }
  }


  const cellRender = (data) => {
    const imageSrc = data.value.startsWith('data:') ? data.value : `${BlogImage}/${data.value}`;
    return (
      imageSrc !== "" ? (
        <img src={imageSrc} alt="Blog" />
      ) : (
        <p>No image</p>
      )
    );
  };



  let imageurl;

  if (!category.addCategory) {
    if (formData.blogimage && typeof formData.blogimage === 'string' && formData.blogimage.substring(0, 5) === 'data:') {
      imageurl = formData.blogimage;
    } else {
      imageurl = `${BlogImage}/${formData.blogimage || ''}`;
    }
  } else {
    imageurl = formData.blogimage;
  }

  // -----------------------------------------------------------------------------------------------------------------

  const onEditButtonClick = (data) => {
    setBlogid(data.blogid);
    setFormErrors({});
    setTagArtist(data?.blogtagartist);
    setTagOrganiser(data?.blogtagorganiser)
    const { createddate, blogtitle, blogmaincatename, blogmaincateid
      , blogphoto, blogdesc, blogtagartist, blogtagorganiser
    } = data;
    setFormData({
      createddate: createddate,
      blogtitle: blogtitle,
      blogmaincatename: blogmaincatename,
      blogmaincate: blogmaincateid,
      blogimage: blogphoto,
      blogcontent: blogdesc,
      blogtagart: blogtagartist,
      blogtagorg: blogtagorganiser
    });
    setCategory(prev => ({ ...prev, openThePopup: true, selectedRowImage: blogphoto }));
  };


  // ----------------------------DeleteFunddtio and mutationFunction ------------------------------
  const DeleteMutation = useMutation({
    mutationKey: "Delete",
    mutationFn: ({ blogid, status, roleid }) => makePostRequest(DELETE_BLOG_CATEGORY(), { blogid, roleid, status }),
    onSuccess: (data) => {
      if (data.state === 'failed') {
        notify(data.message, ERROR, DURATION);
      } else if (data.state === 'success') {
        notify(data.message, SUCCESS)
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  })
  // ----------------------------------ToggleMutation------------------------------------------------------------
  const ToggleFunction = async (toggleDetail) => {
    try {
      const response = await makePostRequest(TOGGLE_BLOG_CATEGORY(), toggleDetail);
      return response;
    } catch (error) {
      throw error;
    }
  }

  const ToggleMutation = useMutation({
    mutationKey: "Toggleing",
    mutationFn: (data) => ToggleFunction(data),
    onSuccess: (data) => {
      setFormData(prev => ({ ...prev, blogshowtopublic: data.blogshowtopublic }))
      notify(data.message, "Blog visibility toggled successfully", SUCCESS, DURATION);
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION)
    }
  })

  const vlaueToggled = async (e, data) => {
    const updatedBlogs = blogs.map(blog =>
      blog.blogid === data.blogid ? { ...blog, blogshowtopublic: e.value ? 'Y' : 'N' } : blog
    );
    setBlogs(updatedBlogs);
    setFormData(prev => ({ ...prev, blogshowtopublic: e.value, }))


    const response = await ToggleMutation.mutateAsync({
      blogid: data.blogid,
      showtopublic: e.value ? 'Y' : 'N',
      roleid: 1
    })
  };





  // ------------------------------------TaggedArtist---------------------------------------------------------
  const fetchArtist = async () => await makeGetRequest(ALL_ARTIST_DETAIL());
  const {
    data: ArtistCatDataSource,

  } = useQuery({
    queryKey: ["artistCategory"],
    queryFn: () => fetchArtist(),
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });


  // ------------------------------------TaggedOrganiser---------------------------------------------------------
  const fetchOrganiser = async () => await makeGetRequest(ALL_ORGSNISER_TAG_DETAIL());
  const {
    data: OrganiserCatDataSource,

  } = useQuery({
    queryKey: ["organiserCategory"],
    queryFn: () => fetchOrganiser(),
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });


  //--------------------------------------DeleteMutation----------------------------------------------------------
  const onDeleteButtonClick = (data) => {
    const selectedRow = data.blogid;
    const roleid = 1;

    data.status = 'N';

    const updatedBlogs = blogs
      .filter((item) => item.blogid !== selectedRow)
      .map((blog, index, arr) => ({
        ...blog,
        serialNumber: arr.length - index
      }));
    setBlogs(updatedBlogs);

    DeleteMutation.mutate({ blogid: data.blogid, roleid, status: data.status });
  };


  // ------------------------------------------------------------------------------------------------------------

  const ExitPopup = () => {
    setCategory(prev => ({ ...prev, openThePopup: false }))
  }
  // --------------------------------------Display Mutation ------------------------------------------------------------------------

  const viewblogMutation = useMutation({
    mutationKey: 'viewBlog',
    mutationFn: ({ loginid, roleid }) => makePostRequest(VIEW_BLOG_CATEGORY(), { loginid, roleid }),
    onSuccess: (data) => {
      setLoading(false);
      setBlogs(data.data);
      if (data.state === 'failed') {
        notify(data.message, ERROR, DURATION);
      } else if (data.state === 'success') {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      setLoading(false);
      notify(error.message, ERROR, DURATION);
    }
  });

  useEffect(() => {
    const loginid = user?.login_id;
    const roleid = user?.role_id;

    viewblogMutation.mutate({ loginid, roleid }, {
      onSuccess: (data) => {
        const sortedData = data.data.sort((a, b) => new Date(b.createddate) - new Date(a.createddate));

        const blogsWithSerialNumbers = data.data.map((blog, index) => ({
          ...blog,
          serialNumber: sortedData.length - index
        }));
        setBlogs(blogsWithSerialNumbers);
      },
      onError: (error) => {
        notify(error.message, ERROR, DURATION);
      }
    });
  }, [update]);


  // ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const adingandupdate = (flag, e) => {
    flag === "Update" && setCategory(prev => ({ ...prev, ADD_CATEGORY_OR_UPDATE: flag, selectedRowImage: e.data.blogphoto, ifEditimagepoint: true, selectallData: e.data }))
    flag === "Create" && setCategory(prev => ({ ...prev, ADD_CATEGORY_OR_UPDATE: flag }))
  }

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const viewUpdatedFunction = async (data) => {
    try {
      const response = await makePostRequest(UPDATE_BLOG_CATEGORY(), data);
      return response
    } catch (error) {
      throw error
    }
  }

  const viewblogDetailsUpdate = useMutation({

    mutationKey: ["viewBlog"],
    mutationFn: (viewUpdatedData) => viewUpdatedFunction(viewUpdatedData),
    onSuccess: (data) => {
      if (data.status === 'failed') {

        notify(data.message, ERROR, DURATION);
      }
      if (data.status === 'success') {

        notify(data.message, SUCCESS);

      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION)
    }
  })

  // ---------------------------------------------OnSubmit------------------------------------------------
  const validateForm = (formData) => {
    const errors = {};
    if (!formData.createddate) errors.createddate = 'Created date is required';
    if (!formData.blogtitle) errors.blogtitle = 'Blog title is required';
    if (!formData.blogmaincate) errors.blogmaincate = 'Main category is required';
    if (!formData.blogmaincatename) errors.blogmaincatename = 'Main category name is required';
    // if (!formData.blogstatus) errors.blogstatus = 'Status is required';
    // if (!formData.blogshowtopublic) errors.blogshowtopublic = 'Show to public status is required';
    if (!formData.blogcontent) errors.blogcontent = 'Content is required';
    if (!formData.blogtagart) errors.blogtagart = 'Tag art is required';
    if (!formData.blogtagorg) errors.blogtagorg = 'Tag org is required';
    return errors;
  };
  // -----------------------------------------------------------------------------
  const handleUpdatRowData = async (e) => {

    const updatedValues = {
      ...formData,
      loginid: user?.login_id,
      roleid: user?.role_id,
      blogid: blogId,
    };

    if (!category.imageFileChanged) {
      delete updatedValues.blogimage;
    }

    const errors = validateForm(formData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      await viewblogDetailsUpdate.mutateAsync(updatedValues);
      SetUpdate(update + 1)
      setBlogs((prevBlogs) =>
        prevBlogs.map((blog) =>
          blog.blogid === blogId ? { ...blog, ...updatedValues } : blog
        )
      );

    } catch (error) {
      console.log("error", error);
    } finally {
      console.log("finally is running");
      setCategory((prev) => ({ ...prev, openThePopup: false }));
    }
  };

  // --------------------------------MainCategory----------------------------------------------------------------------------------------------------------
  const mainCategoryBlog = async () => await makeGetRequest(GET_BlOG_MAIN_CATEGORIES())
  const {
    data: mainCategoryBlogSource,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["blogmaincate"],
    queryFn: () => mainCategoryBlog(),
    onError: () => {
      notify(error.message, ERROR, DURATION)
    },
  });
  // ------------------------------------------------------------------------------------------------------------------------------------------------------

  const handleImageEditButtonClick = () => {
    findInput.current.click();

  };


  const handleImageChange = async (event) => {
    try {
      const file = event.target.files?.[0];
      setCategory(prev => ({ ...prev, openThePopup: false, imageFileChanged: true }));
      if (!file) {
        console.log("No file selected");
        return;
      }

      const blobImage = await openEditor({ src: file });
      setCategory(prev => ({ ...prev, openThePopup: true, imageFileChanged: true }));
      const blob = await blobImage?.editedImage?.getBlob?.();
      if (!blob) {
        throw new Error("No new image");
      }

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        // setBlogs(prevBlogs => prevBlogs.map(blog => blog.blogmaincateid === formData.blogmaincate ? { ...blog, blogphoto: base64String } : blog));
        setFormData(prev => ({ ...prev, blogimage: base64String }));
        setCategory(prev => ({ ...prev, imageFileChanged: true }));
      };
      reader.readAsDataURL(blob);


    } catch (error) {
      console.error("Error while processing image:", error);
    }
  };
  // -------------------------------ARTIST-------------------------------------------------------


  const TagArtist = useMutation({
    mutationKey: ["fetchTagedArtist"],
    mutationFn: (tagArtistDetail) => TagArtistDetailFetch(tagArtistDetail),
    onSuccess: (data) => {
      data && setFormData((prev) => ({ ...prev, blogtagart: data.data }))
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  })

  const artistinput = (e) => {
    const inputValue = e.component.option("text");

    if (inputValue.trim() !== "") {
      const body = {
        artistname: inputValue,
      };
      TagArtist.mutate(body);
    }
  }
  // -------------------------------ORGANISER-------------------------------------------------------
  const TagOrgaganiserM = useMutation({
    mutationKey: ["fetchTagedOrganiser"],
    mutationFn: (tagOrganiserDetail) => TagedOrganiserDetailFetch(tagOrganiserDetail),
    onSuccess: (data) => {
      data && setFormData((prev) => ({ ...prev, blogtagorg: data.data }));
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  });
  const organiserinput = (e) => {
    const inputValue = e.component.option("text");
    if (inputValue.trim() !== "") {
      const body = {
        organisername: inputValue,
      };
      TagOrgaganiserM.mutate(body);
    }
  };
  // --------------------------------------------------------------------------------------

  console.log(blogs, "blogs")
  return (
    <div id="data-grid-demo">
             {loading ? (
        <Skeleton count={10} height={80} />
      ) : (
      <DataGrid
        id="gridContainer"
        dataSource={blogs}
        rowAlternationEnabled={true}
        columnHidingEnabled={true}
        keyExpr="blogid"
        onRowUpdated={(e) => handleRowUpdate(e)}
        showBorders={true}
        onInitNewRow={(e) => adingandupdate('Create', e)}
        onEditingStart={(e) => adingandupdate("Update", e)}
      >
        <Scrolling rowRenderingMode='virtual'/>
        <Paging defaultPageSize={4} />
        <Pager
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Column dataField="serialNumber" width={50} caption='Sl' />
        <Column dataField="createddate" dataType="date" caption="Date of Creation" format="dd/MM/yyyy" />
        <Column dataField="blogtitle" caption="Blog Title" />
        <Column dataField="blogmaincatename" caption="Category" />
        <Column
          dataField="blogphoto"
          caption='Photo'
          width={100}
          allowSorting={false}
          cellRender={cellRender}
        />
        <Column
          dataField="showtopublic"
          width={140}
          cellRender={({ data }) => (
            <Switch
              defaultValue={data.blogshowtopublic === 'Y'}
              onValueChanged={(e) => vlaueToggled(e, data)}
              style={{ cursor: 'default' }}
            />
          )}
        />
        <Column
          caption="Edit"
          cellRender={({ data }) => (
            
            <div>
              <MdModeEditOutline
                className="EditIcon"
                onClick={() => onEditButtonClick(data)}
              />
            </div>
          )}
          width={80}
        />
        <Column
          caption="Delete"
          cellRender={({ data }) => (
            <div>
              <RiDeleteBinLine
                className="DeleteIcon"
                onClick={() => onEditButtonClick(data)}
              />
            </div>
          )}

        />
      </DataGrid>
            )}
      <Modal
        size="lg"
        show={category.openThePopup}
        centered
      >
        <Modal.Header onClick={ExitPopup} closeButton >
           <h5 className='text-center m-auto border-light'>Edit Blog</h5>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className="form-group mb-3 col-lg-6">
              <TextBox
                label='Blog Title'
                labelMode='floating'
                onValueChanged={(e) => setFormData((prev) => ({ ...prev, blogtitle: e.value }))}
                value={formData.blogtitle}
              />
              {formErrors.blogtitle && <span className="text-danger">{formErrors.blogtitle}</span>}
            </div>
            <div className="form-group mb-3 col-lg-6">
              <SelectBox
                label='Category'
                labelMode='floating'
                dataSource={mainCategoryBlogSource?.data}
                displayExpr="main_cat_name"
                valueExpr="main_cat_id"
                defaultValue={[8]}
                value={formData.blogmaincate}
                onValueChanged={(e) => setFormData((prev) => ({ ...prev, blogmaincate: e.value }))}
              />
              {formErrors.blogmaincate && <span className="text-danger">{formErrors.blogmaincate}</span>}
            </div>
            {/* -----------------------------------------------------------------------------------------             */}
            <div className="form-group mb-2">
              <label className="mb-2">
                Blog Content&nbsp;{" "}
                <span className="text-danger">*</span>
              </label>
              <div className="widget-container">
                <HtmlEditor
                  height="auto"
                  defaultValue={markup}
                  label="Blog Description"
                  value={formData.blogcontent}
                  onValueChanged={(e) => setFormData((prev) => ({ ...prev, blogcontent: e.value }))}
                >
                  <MediaResizing enabled={true} />
                  <ImageUpload
                    tabs={currentTab}
                    fileUploadMode="base64"
                  />
                  <Toolbar multiline={isMultiline}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                      name="size"
                      acceptedValues={sizeValues}
                      options={fontSizeOptions}
                    />
                    <Item
                      name="font"
                      acceptedValues={fontValues}
                      options={fontFamilyOptions}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item
                      name="header"
                      acceptedValues={headerValues}
                      options={headerOptions}
                    />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    <Item name="separator" />
                    <Item name="insertTable" />
                    <Item name="deleteTable" />
                    <Item name="insertRowAbove" />
                    <Item name="insertRowBelow" />
                    <Item name="deleteRow" />
                    <Item name="insertColumnLeft" />
                    <Item name="insertColumnRight" />
                    <Item name="deleteColumn" />
                  </Toolbar>
                </HtmlEditor>

              </div>
              {formErrors.blogcontent && <span className="text-danger">{formErrors.blogcontent}</span>}

            </div>


            {/* ---------------------------------------------------------------------------------------------------- */}
            <div className=' col-lg-6'>
              <TagBox
                onInput={artistinput}
                labelMode='floating'
                label="Tag Artist"
                valueExpr="artistid"
                dataSource={ArtistCatDataSource?.data}
                defaultValue={TagArtistarray}
                searchEnabled={true}
                displayExpr="artist_name"
                onValueChanged={(e) => {
                  const stringifiedValue = Array.isArray(e.value) ? e.value.join(",") : e.value;
                  setFormData(prev => ({ ...prev, blogtagart: stringifiedValue }));
                }}
              />
              {formErrors.blogtagart && <span className="text-danger">{formErrors.blogtagart}</span>}
            </div>
            <div className=' col-lg-6'>
              <TagBox
                onInput={organiserinput}
                labelMode='floating'
                label="Tag Organiser"
                valueExpr="organiserid"
                dataSource={OrganiserCatDataSource?.data}
                defaultValue={TagOrganiserarray}
                searchEnabled={true}
                displayExpr="organiser_name"
                onValueChanged={(e) => {
                  const stringifiedValue = Array.isArray(e.value) ? e.value.join(",") : e.value;
                  setFormData(prev => ({ ...prev, blogtagorg: stringifiedValue }));
                }}
              />
              {formErrors.blogtagorg && <span className="text-danger">{formErrors.blogtagorg}</span>}
            </div>
            <div>
              <img src={imageurl} alt="image" class="img-thumbnail w-auto h-50 mt-3"/>
            
              <MdModeEditOutline
                className="EditIcon align-item-baseline"
                onClick={handleImageEditButtonClick}
              />
            
              <input
                type="file"
                accept='image/*'
                onChange={(e) => handleImageChange(e)}
                style={{ display: 'none' }}
                ref={findInput}
              />
              {formErrors.blogtagorg && <span className="text-danger">{formErrors.blogtagorg}</span>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='CancelBtn' varient="secondary" onClick={() => ExitPopup()}>
            Cancel
          </Button>
          <Button  className='button' varient="secondary" onClick={(e) => handleUpdatRowData(e)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ViewBlogs;