import React from "react";
import NoResultFilterEvent from "./NoResultFilterEvent.png"

export default function NodataforEvent(){

    return(
        < div className="d-flex flex-column align-items-center justify-content-center">
         <img src={NoResultFilterEvent} alt="No Event Found" style={{ width: '100%', height: '400px', maxHeight:'600px' }} />
         <h4 className="text-center">Can't escape the filters!</h4>
         <p className="text-center">Your filter combination didnt fetch any result.<br/>
             Kindly reset to discover more Events on MDnD!</p>
        
        </div>
    )
}