import React, { useContext, useEffect, useState } from "react";
import { SelectBox } from "devextreme-react";
import { getNumberedEventDetails, getSeatingLayout } from "../../../Api/api";
import AuthContext from "../../../context/AuthContext";
import { makePostRequest } from "../../../Api/requestMethods";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { isValidValue } from "../../../utils/commonUtils";
import { SOMETHING_WENT_WRONG } from "../../../Schemas/errorMessageConstants";
import CreateSeatingLayouts from "./createSeatingLayouts";

const EditSeatingLayout = () => {
  const { user } = useContext(AuthContext);
  const [dataSource, setDataSource] = useState({
    venueDetails: "",
    selectedVenu: "",
    seatingDs: "",
    layoutDs: "",
  });
  const fetchVenueDetails = async () => {
    try {
      const body = {
        role_id: user.role_id,
      };
      const response = await makePostRequest(getNumberedEventDetails, body);
      if (response && response.status === SUCCESS) {
        setDataSource((prev) => ({
          ...prev,
          venueDetails: response.data || [],
        }));
      } else {
        notify(SOMETHING_WENT_WRONG, ERROR, DURATION);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isValidValue(user.role_id)) fetchVenueDetails();
  }, []);

  const handleChange = async (value) => {
    try {
      setDataSource((prev) => ({ ...prev, selectedVenu: value }));
      const body = {
        venue_id: value,
        role_id: user.role_id,
      };
      const response = await makePostRequest(getSeatingLayout, body);
      if (response && response.status === SUCCESS) {
        const venuDetails = (response.data && response.data[0]) || [];
        const seating_Ds = JSON.parse(venuDetails.seating_details) || [];
        const layout_Ds = JSON.parse(venuDetails.seating_layout) || [];
        setDataSource((prev) => ({
          ...prev,
          seatingDs: seating_Ds,
          layoutDs: layout_Ds,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <div className="col-lg-6 d-flex align-items-center">
        <div className="form-group d-flex align-items-center mb-2 w-100">
          <SelectBox
            label="Choose Venue layouts"
            className="dx-field-item-required w-100"
            labelMode="floating"
            dataSource={dataSource.venueDetails || []}
            displayExpr="venue_name"
            valueExpr="venue_id"
            onValueChanged={(e) => handleChange(e.value)}
            value={dataSource.selectedVenu}
          />
        </div>
      </div>
    </>
  );
};

export default EditSeatingLayout;
