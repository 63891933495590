import React, { useEffect, useState, useContext } from "react";
import { NumberBox } from "devextreme-react";
import { GlobalContext } from "../../../global/provider";
import { freewithregistration } from "../../../global/constants";

const OnlineDenomination = ({
  dataSource,
  formData,
  ticketLimitationEvent,
  capitalizeFirstLetter,
  EventModeOnline,
}) => {
  const { setEventDataSource } = useContext(GlobalContext);
  const [grandTotal, setGrandTotal] = useState(0);
  const [rowTotals, setRowTotals] = useState([]);

  const calculateGrandTotal = (formData) => {
    let total = 0;
    if (formData) {
      formData.forEach((data) => {
        total += (data.amount || 0) * (data.no_of_tickets || 0);
      });
    }
    setGrandTotal(total);
    setEventDataSource((prev) => ({
      ...prev,
      GrandTotal: total,
    }));
  };

  useEffect(() => {
    if (formData) {
      const initialRowTotals = formData.map(
        (data) => (data.amount || 0) * (data.no_of_tickets || 0)
      );
      setEventDataSource((prev) => ({
        ...prev,
        RowTotal: initialRowTotals,
      }));
      setRowTotals(initialRowTotals);
      calculateGrandTotal(formData);
    }
  }, [formData]);

  const handleQuantityChange = (index, value) => {
    const newFormData = [...formData];
    newFormData[index].no_of_tickets = value;

    const newRowTotals = [...rowTotals];
    newRowTotals[index] = (newFormData[index].amount || 0) * value;
    setEventDataSource((prev) => ({ ...prev, RowTotal: newRowTotals }));
    setRowTotals(newRowTotals);

    calculateGrandTotal(newFormData);
    ticketLimitationEvent(
      index,
      value,
      dataSource.gridData[index].ticket_limitations,
      dataSource.gridData[index].row_name
        ? dataSource.gridData[index].row_name
        : capitalizeFirstLetter(EventModeOnline),
      dataSource.gridData[index].balance_tickets
    );
  };

  const conditionCheck = dataSource && dataSource.eventDetails && dataSource.eventDetails[0];

  return (
    <table
      id="example1"
      className="table table-bordered table-striped text-center mt-2"
    >
      <thead>
        <tr>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration &&
            formData &&
            formData[0]?.amount !== null &&
            formData[0]?.amount !== "" &&
            formData[0]?.amount !== 0 && <th>₹</th>}

          <th>Qty</th>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration && (
              <th>Total</th>
            )}
        </tr>
      </thead>
      <tbody>
        {dataSource && dataSource.gridData && dataSource.gridData.length > 0
          ? dataSource.gridData.map((data, index) => (
              <tr key={index}>
                {data.row_name && <td>{data.row_name}</td>}

                {/* Amount */}
                {conditionCheck &&
                  conditionCheck?.ticketing_option !== freewithregistration && (
                    <>
                      {formData &&
                        formData[0]?.amount !== null &&
                        formData[0]?.amount !== "" &&
                        formData[0]?.amount !== 0 && (
                          <td>
                            <NumberBox
                              min={0}
                              readOnly={true}
                              value={formData[index]?.amount || 0}
                            />
                          </td>
                        )}
                    </>
                  )}

                <td>
                  <NumberBox
                    min={0}
                    showSpinButtons={true}
                    onValueChanged={(e) => handleQuantityChange(index, e.value)}
                    value={formData[index]?.no_of_tickets || 0}
                  />
                </td>

                {conditionCheck &&
                  conditionCheck?.ticketing_option !== freewithregistration && (
                    <td>
                      <NumberBox
                        className="textAlignNumberBox"
                        readOnly={true}
                        value={rowTotals[index] || 0}
                      />
                    </td>
                  )}
              </tr>
            ))
          : null}
        <tr>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration && (
              <>
                <td
                  className="text-start"
                  colSpan={
                    conditionCheck?.event_mode === EventModeOnline ? 2 : 3
                  }
                >
                  Grand Total
                </td>
                <td style={{ textAlign: "right" }}>
                  <NumberBox
                    alignment="right"
                    readOnly={true}
                    value={grandTotal}
                  />
                </td>
              </>
            )}
        </tr>
      </tbody>
    </table>
  );
};

export default OnlineDenomination;
