import { Export } from 'devextreme-react/cjs/bar-gauge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header  className='text-center d-block' closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        <h6><b>Plan details</b></h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <table className="table table-primary tableBorder mt-2">
                <thead>
                  <tr>
                    <th colSpan="2" scope="col" className="text-center">
                      Views
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="w-50">
                      <b>View count</b>
                    </td>
                    <td className="w-50 text-end">
                      <b>Price(INR)</b>
                    </td>
                  </tr>
                  <tr className="">
                    <td scope="row">100</td>
                    <td className="text-end">₹500</td>
                  </tr>
                  <tr className="">
                    <td scope="row">500</td>
                    <td className="text-end">₹1500</td>
                  </tr>
                  <tr className="">
                    <td scope="row">1000</td>
                    <td className="text-end">₹2500</td>
                  </tr>
                </tbody>
              </table>
              <table className="table table-primary tableBorder">
                <thead>
                  <tr>
                    <th colSpan="2" scope="col" className="text-center">
                      Duration
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="">
                    <td className="w-50">
                      <b>Days</b>
                    </td>
                    <td className="w-50 text-end">
                      <b>Price(INR)</b>
                    </td>
                  </tr>
                  <tr>
                    <td scope="row">1-14 days</td>
                    <td className="text-end">₹50/day</td>
                  </tr>
                  <tr className="">
                    <td scope="row">15-30 days</td>
                    <td className="text-end">₹40/day</td>
                  </tr>
                  <tr className="">
                    <td scope="row">Above 30 days</td>
                    <td className="text-end">₹30/day</td>
                  </tr>
                </tbody>
              </table>
      </Modal.Body>
      <Modal.Footer>
        <button className="CancelBtn" onClick={props.onHide}>Close</button>
      </Modal.Footer>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;