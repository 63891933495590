import React, { useEffect, useState, useContext } from "react";
import { NumberBox } from "devextreme-react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { freewithregistration, countryIdIndia } from "../../global/constants";
import { GlobalContext } from "../../global/provider";
import { IoMdAddCircleOutline } from "react-icons/io";
import { HiOutlineMinusCircle } from "react-icons/hi";

const SeasonOfflineDenomination = ({
  dataSource,
  formData,
  ticketLimitationEvent,
  capitalizeFirstLetter,
  EventModeOnline,
}) => {
  const [searchParams] = useSearchParams();
  const countryId = searchParams.get("ci");
  const { setEventDataSource } = useContext(GlobalContext);
  const [grandTotal, setGrandTotal] = useState(0);
  const [rowTotals, setRowTotals] = useState([]);

  const calculateGrandTotal = (formData) => {
    let total = 0;
    if (formData) {
      formData.forEach((data) => {
        total += (data.amount || 0) * (data.no_of_tickets || 0);
      });
    }
    setEventDataSource((prev) => ({
      ...prev,
      seasonGrandTotal: total,
    }));

    setGrandTotal(total);
  };

  useEffect(() => {
    if (formData) {
      const initialRowTotals = formData.map(
        (data) => (data.amount || 0) * (data.no_of_tickets || 0)
      );
      setEventDataSource((prev) => ({
        ...prev,
        seasonRowTotal: initialRowTotals,
      }));
      setRowTotals(initialRowTotals);
      calculateGrandTotal(formData);
    }
  }, [formData]);

  const handleQuantityChange = (index, value) => {
    const newFormData = [...formData];
    newFormData[index].no_of_tickets = value || 0;
    const newRowTotals = [...rowTotals];
    newRowTotals[index] = (newFormData[index].amount || 0) * value;
    setRowTotals(newRowTotals);
    setEventDataSource((prev) => ({
      ...prev,
      seasonRowTotal: newRowTotals,
    }));

    calculateGrandTotal(newFormData);
    ticketLimitationEvent(
      index,
      value,
      dataSource.gridData[index].ticket_limitations,
      dataSource.gridData[index].row_name
        ? dataSource.gridData[index].row_name
        : capitalizeFirstLetter(EventModeOnline),
      dataSource.gridData[index].balance_tickets
    );
  };

  const conditionCheck =
    dataSource && dataSource.eventDetails && dataSource.eventDetails[0];


  return (
    <table
      id="example1"
      className="table table-bordered table-striped text-center mt-2"
    >
      <thead>
        <tr>
          <th>Row name</th>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration &&
            formData &&
            formData[0]?.amount !== null &&
            formData[0]?.amount !== "" &&
            formData[0]?.amount !== 0 && <th>₹</th>}

          <th>Qty</th>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration && (
              <th>Total</th>
            )}
        </tr>
      </thead>
      <tbody>
        {dataSource && dataSource.gridData && dataSource.gridData.length > 0
          ? dataSource.gridData.map((data, index) => (
            <tr key={index}>
              {data.row_name && <td>{data.row_name}</td>}

              {/* Amount */}
              {conditionCheck &&
                conditionCheck?.ticketing_option !== freewithregistration && (
                  <>
                    {formData &&
                      formData[0]?.amount !== null &&
                      formData[0]?.amount !== "" &&
                      formData[0]?.amount !== 0 && (
                        <td>
                          <NumberBox
                            min={0}
                            readOnly={true}
                            value={formData[index]?.amount || 0}
                          />
                        </td>
                      )}
                  </>
                )}

              <td>
                <div className="quantity-control text-center rounded" style={{ border: "1px solid #e0e0e0" }}>
                  <button
                    style={{ borderRadius: "4px 0px 0px 4px", backgroundColor: "#ffffff" }}
                    className="btn-decrement text-dark px-1 px-sm-2"
                    onClick={() => {
                      const currentValue = formData[index]?.no_of_tickets || 0;
                      if (currentValue > 0) {
                        handleQuantityChange(index, currentValue - 1);
                      }
                    }}
                  >
                    <HiOutlineMinusCircle style={{ color: "#757575" }} />
                  </button>
                  <NumberBox
                    min={0}
                    max={10}
                    showSpinButtons={false}
                    onValueChanged={(e) => handleQuantityChange(index, e.value)}
                    value={formData[index]?.no_of_tickets || 0}
                  />
                  <button
                    style={{ borderRadius: "0px 4px 4px 0px", backgroundColor: "#ffffff" }}
                    className="btn-increment text-dark px-1 px-sm-2"
                    onClick={() => {
                      const currentValue = formData[index]?.no_of_tickets || 0;
                      if (currentValue < 10) {
                        handleQuantityChange(index, currentValue + 1);
                      }
                    }}
                  >
                    <IoMdAddCircleOutline style={{ color: "#757575" }} />
                  </button>
                </div>
              </td>

              {conditionCheck &&
                conditionCheck?.ticketing_option !== freewithregistration && (
                  <td>
                    <NumberBox
                      className="textAlignNumberBox"
                      readOnly={true}
                      value={rowTotals[index] || 0}
                    />
                  </td>
                )}
            </tr>
          ))
          : null}
        <tr>
          {conditionCheck &&
            conditionCheck?.ticketing_option !== freewithregistration && (
              <>
                <td
                  className="text-start"
                  colSpan={
                    conditionCheck?.event_mode === EventModeOnline ? 2 : 3
                  }
                >
                  Grand Total
                </td>
                <td style={{ textAlign: "right" }}>
                  <NumberBox
                    alignment="right"
                    readOnly={true}
                    value={grandTotal}
                  />
                </td>
              </>
            )}
        </tr>
      </tbody>
    </table>
  );
};

export default SeasonOfflineDenomination;
