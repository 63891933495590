import React from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { BASEPATH } from "../../../config/config";
import { GET_CALENDAR } from "../../../Api/api";
import Scheduler, { Resource, View } from 'devextreme-react/scheduler';
import NoDataFound from "../../../global/NoDataFound";
import { customEncrypt } from "../../../utils/encryptAndDecrypt";
import { useNavigate } from "react-router-dom";
import getEventKeys from "../../../Api/getEventKeys";

export default function MyCalendar_artist(props) {

    const COUNTRY_KEY= getEventKeys().countryKey;
 const EVENT_KEY= getEventKeys().eventKey;

    let { roleid } = props;

    const navigate = useNavigate();

    const views = ['month', 'week'];
    const token = localStorage.getItem("MDnD_auth");

    const [calendarCollection, setCalendarCollection] = useState({
        calendarData: [],
        firstdate: null,
    })


    useEffect(() => {
        if (roleid) { mutationGetCalendar.mutate(); }

    }, [])

    const GetCalendar = () =>
        GET_MYCALENDAR_FROM_BACKEND(GET_CALENDAR);

    const GET_MYCALENDAR_FROM_BACKEND = async (
        GET_CALENDAR) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_CALENDAR}`,

                data: {
                    country_id: 101,
                    role_id: roleid ? roleid : ""


                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationGetCalendar = useMutation({
        mutationKey: ["get_my_calendar"],
        mutationFn: () => GetCalendar(),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {
                //console.log(data,"dt");
                //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
                //SuccessNewToaster("success", "Message Sent Successfully!", 3000);


                let filteredArray = data.data.sort((a, b) => new Date(a.event_date) - new Date(b.event_date));


                let formattedData = filteredArray && filteredArray.map(event => ({

                    text: event.event_title,
                    startDate: new Date(event.event_date),
                    endDate: new Date(new Date(event.event_date).setHours(new Date(event.event_date).getHours() + 1)), 
                    event_id: event.event_id,
                }));



                setCalendarCollection((prevState) => ({
                    ...prevState, calendarData: formattedData,
                    firstdate: formattedData.length > 0 ? formattedData[0].startDate : null

                }));
            }
            else {
                setCalendarCollection((prevState) => ({ ...prevState, calendarData: "" }));
            }
        },
        onError: (error) => {
            //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            //HandleErrorToast("error", "My Calendar Could not be Fetched. Something went Wrong!", 3000);
            //setCalendarCollection((prevState) => ({ ...prevState, calendarData: "" }));
        },
    });


    const handleAppointmentClick = (e) => {
         //console.log(e,"event");
        const encyptedData = customEncrypt("no");
        const modifiedTitle = e.appointmentData.text ? e.appointmentData.text.split(" ").join("_") : "Event"; 
        navigate(`/event/${modifiedTitle}?ci=${encodeBase64_new(101, COUNTRY_KEY)}&e=${encodeBase64_new(e.appointmentData.event_id, EVENT_KEY)}&editEvent=${encyptedData}`);
    };

    const encodeBase64 = (str ,key) => {
        const prefix = "xy4AT";
        const combinedStr = prefix + str.toString();
        return btoa(combinedStr);
      };

      const encodeBase64_new = (str ,key) => {
        const prefix = key?.toString();
        const combinedStr = prefix + str.toString();
        return btoa(combinedStr);
      };
 

    return (
        <>
            <div>


                {calendarCollection.calendarData && calendarCollection.calendarData.length > 0 ? (
                    <>

                        <Scheduler
                            timeZone="America/Los_Angeles"
                            dataSource={calendarCollection.calendarData}
                            views={['month']}
                            defaultCurrentView="month"
                            defaultCurrentDate={calendarCollection.firstdate || null}
                            height={730}
                            showCurrentTimeIndicator={false}
                            startDayHour={1}
                            onAppointmentDblClick={(e) => (e.cancel = true)}
                            onAppointmentAdding={(e) => (e.cancel = true)}
                            onAppointmentFormOpening={(e) => (e.cancel = true)}
                            onAppointmentDeleting={(e) => (e.cancel = true)}
                            onAppointmentDeleted={(e) => (e.cancel = true)}
                            onCellClick={(e) => (e.cancel = true)}
                            onDragStart={(e) => (e.cancel = true)}
                            onRemove={(e) => (e.cancel = true)}
                            onDragEnd={(e) => (e.cancel = true)}
                            onAdd={(e) => (e.cancel = true)}
                            editing={{
                                allowDragging: false
                            }}
                            appointmentDragging={{
                                onDragStart: (e) => {
                                    e.cancel = true;
                                },
                            }}
                            onAppointmentClick={handleAppointmentClick}
                        >


                        </Scheduler></>) : (
                    <NoDataFound />
                )}




            </div>


        </>
    )
}