import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../../../../global/ToastMsgConstants.tsx";

const membershipValidationSchema = Yup.object().shape({
  membership_id: Yup.string().required(COMMONFIELDREQUIRED),
  member_name: Yup.string().required(COMMONFIELDREQUIRED),
  member_email: Yup.string().email("Invalid email format").required(COMMONFIELDREQUIRED),
  membership_type: Yup.string().required(COMMONFIELDREQUIRED),
  member_mobile: Yup.string().required(COMMONFIELDREQUIRED),
  member_address: Yup.string().required(COMMONFIELDREQUIRED),
  membership_date: Yup.date().required(COMMONFIELDREQUIRED),
  country: Yup.string().required(COMMONFIELDREQUIRED),
  state: Yup.string().required(COMMONFIELDREQUIRED),
  city: Yup.string().required(COMMONFIELDREQUIRED),
  ticket_qty: Yup.number().required(COMMONFIELDREQUIRED).positive("Must be a positive number").integer("Must be an integer"),
  status: Yup.string().oneOf(["Y", "N"]).required(COMMONFIELDREQUIRED) // Assuming status can be "Y" or "N"
});

export default membershipValidationSchema;
