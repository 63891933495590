import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../global/provider";
// import QrCodeWithLogo from "qrcode-with-logos";
import { InvoiceHtmlTemplate } from "./components/invoiceHtmlTemplate";

const Invoice = () => {
  const { eventsDatasource } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const paymentMode = queryParams.get("payment-mode");
  const id = queryParams.get("id");
  const logoUrl = `https://www.mdnd.in/public/uploads/live_favicon1627102186.ico`;

  useEffect(() => {
    if (eventsDatasource && eventsDatasource.invoiceDetails) {
      if (eventsDatasource.invoiceDetails.ticket_pass !== id) navigate("/");
    }
  }, [paymentMode, id, eventsDatasource]);

  useEffect(() => {
    if (id) {
      const data = {
        id: paymentMode,
        ticket_pass: id,
      };
      const canvas = document.getElementById("qrcode-canvas");
      const logo = new Image();
      logo.src = logoUrl;
      logo.onload = () => {
        // const qrcode = new QrCodeWithLogo({
        //   canvas: canvas,
        //   content: JSON.stringify(data),
        //   width: 256,
        //   logo: {
        //     src: logoUrl,
        //     logoRadius: 8,
        //   },
        // });
        // qrcode.draw();
      };
    }
  }, [id, logoUrl]);

  return (
    <section className={`section-padding pt-lg-3`}>
      <div className="container mt-3 pb-5">
        <div className="row">
          <InvoiceHtmlTemplate
            htmlElement={<canvas id="qrcode-canvas"></canvas>}
            data={eventsDatasource.invoiceDetails}
          />
        </div>
      </div>
    </section>
  );
};

export default Invoice;
