import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DateBox, TagBox, TextBox, SelectBox } from 'devextreme-react';
import { DURATION, ERROR, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import { EVENT_MAIN_CATEGORY, GET_PERTICULAR_USER, INSERT_ADS_DETAIL_TOPUP } from '../../../Api/api';
import notify from 'devextreme/ui/notify';
import { makeGetRequest, makePostRequest } from '../../../Api/requestMethods';
import { useMutation, useQuery } from '@tanstack/react-query';
import {jwtDecode} from 'jwt-decode';
import moment from 'moment';
import RazorpayPayment from '../../../Pages/paymentGateWay/razorPay.js';


const ViewValues = [
  { value_id: 1, value_data: 100, rate: 500 },
  { value_id: 2, value_data: 500, rate: 1500 },
  { value_id: 3, value_data: 1000, rate: 2500 }
];

const calculateDurationAmount = (startDate, endDate) => {
  const dayInMillis = 24 * 60 * 60 * 1000;
  const diffDays = Math.round((endDate - startDate) / dayInMillis);

  if (diffDays <= 14) {
    return diffDays * 50;
  } else if (diffDays <= 30) {
    return 14 * 50 + (diffDays - 14) * 40;
  } else {
    return 14 * 50 + 16 * 40 + (diffDays - 30) * 30;
  }
};

const TopUpPopup = ({ data, isOpen, onClose }) => {
  const [planType, setPlanType] = useState(null);
  const [selectedViewValue, setSelectedViewValue] = useState(null);
  const [selectChequeNumber, setSelectChequeNumber] = useState(null);
  const [totalAmount, setTotalAmount] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [userInformation, setUserInformation] = useState({});
  const [redirectLink, setRedirectLink] = useState('');
  const [currency, setCurrency] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);
  const [chequeNumber, setChequeNumber] = useState('');
  
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );


   
  const loginid = user.login_id;
  const roleid = user.role_id;

  const razorpayRef = useRef(null);

  const handleRazorpaySuccess = (response) => {
    console.log("Payment Successful!");
    console.log("Payment ID:", response.razorpay_payment_id);
    console.log("Order ID:", response.razorpay_order_id);
    console.log("Signature:", response.razorpay_signature);
    notify("Payment Successful!", "success");
  };

  const handleRazorpayFailure = () => {
    console.log("Payment Failed or Cancelled");
    notify("Payment Failed or Cancelled", "error");
  };

  useEffect(() => {
    setTotalAmount('');
  }, [planType]);

  useEffect(() => {
    if (selectedViewValue && planType === 1) {
      const selectedValue = ViewValues.find(item => item.value_id === selectedViewValue);
      setTotalAmount(selectedValue ? selectedValue.rate : '');
    }
  }, [selectedViewValue, planType]);

  useEffect(() => {
    if (startDate && endDate && planType === 2) {
      const total = calculateDurationAmount(new Date(startDate), new Date(endDate));
      setTotalAmount(total);
    }
  }, [startDate, endDate, planType]);

  const TargetCategories = async () => (await makeGetRequest(EVENT_MAIN_CATEGORY));

  const { data: TargetCategoriesData, isLoading } = useQuery({
    queryKey: ["targetcategoryads"],
    queryFn: () => TargetCategories(),
    onError: (errors) => {
      notify(errors.message, ERROR, DURATION);
    }
  });

  const fetchUserInformation = async (loginid) => {
    const endpoint = `${GET_PERTICULAR_USER()}=${loginid}`;
    return await makeGetRequest(endpoint);
  };

  const { data: userData } = useQuery({
    queryKey: ['userInfoadv', loginid],
    queryFn: () => fetchUserInformation(loginid),
    onError: (error) => {
      console.log(error);
    }
  });

  useEffect(() => {
    setUserInformation(userData?.data);
  }, [userData]);

  console.log(userInformation, "UserInformation")
//------------------------------------------------dataSubmitMutation----------------------------------------------------
  const adsPosttopupfunction = async (data) => {
    try {
      const endpoint = await makePostRequest(INSERT_ADS_DETAIL_TOPUP(), data);
      return endpoint;
    } catch (error) {
      throw error;
    }
  }

  const addTopUpMutation = useMutation({
    mutationKey: ["addTOPUp"],
    mutationFn: (topupdata) => adsPosttopupfunction(topupdata),
    onSuccess: (data) => {
      if (data.status !== 200) {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === 200) {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      console.log(error, "errorpost");
      notify(error.message, ERROR, DURATION);
    }
  })
// --------------------------------------------------Submit-------------------------------------------------------------
const handleSubmit = async () => {
  const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
  const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;

  let updateValue = {
    payment_mode: paymentMode,
    redirect_link: redirectLink,
    payment_for: "advertisement",
    role_id: roleid,
    login_id: loginid,
    id: data?.advertisement_id
  };

  if (planType === 2) {
    updateValue.start_date = formattedStartDate;
    updateValue.end_date = formattedEndDate;
  } else if (planType === 1) {
    updateValue.impressions_promised = ViewValues.find(item => item.value_id === selectedViewValue)?.value_data;
  }

  if (paymentMode === "online") {
    updateValue.user_name = userInformation?.name;
    updateValue.user_email = userInformation?.email;
    updateValue.user_mobile = userInformation?.mobile;
    updateValue.payable_inr = totalAmount;
    updateValue.payment_gateway = "razorpay";
  } else if (paymentMode === "cheque") {
    if (planType === 1) {
      updateValue.impressions_promised = ViewValues.find(item => item.value_id === selectedViewValue)?.value_data;
    } else if (planType === 2) {
      updateValue.start_date = formattedStartDate;
      updateValue.end_date = formattedEndDate;
    }
    updateValue.payable_usd = totalAmount;
    updateValue.cheque_number = chequeNumber;
  }

  console.log(updateValue);
  try {
    const response = await addTopUpMutation.mutateAsync(updateValue);
    console.log(response, "RESPONSE");
    if (response.data.status === 200) {
      if (paymentMode === "online") {
        razorpayRef.current.triggerPayment(response.data);
      } else {
        notify(response.message, SUCCESS);
      }
    } else {
      notify(response.message, ERROR, DURATION);
    }
  } catch (error) {
    console.log("Error", error);
  }
};



  return (
    <Modal size='lg' show={isOpen} centered onHide={onClose}>
     
      <Modal.Header closeButton
        className="m-0 d-flex align-items-center text-center border-light"
        style={{ padding: "10px 10px", }}
      >
        <h5 className="text-nowrap text-center m-auto">TopUp Ads</h5>
      </Modal.Header>     
      <Modal.Body>
        <div className='row'>
          <div className="col-lg-6 form-group mb-3">
            <TextBox
              className="dx-field-item-required"
              label='Title'
              labelMode='floating'
              value={data?.title}
              readOnly
            />
          </div>
          <div className="col-lg-6 form-group mb-3">
            <TagBox
              dataSource={TargetCategoriesData?.data}
              label="Target Category"
              labelMode='floating'
              displayExpr="main_cat_name"
              valueExpr="main_cat_id"
              defaultValue={data?.record_categories}
              readOnly
            />
          </div>
          <div className="col-lg-6 form-group mb-3">
            <SelectBox
              items={[
                { state_id: 1, state_name: "View Type" },
                { state_id: 2, state_name: "Duration Type" },
              ]}
              className="dx-field-item-required"
              label="Plan Type"
              labelMode="floating"
              valueExpr="state_id"
              displayExpr="state_name"
              onValueChanged={(e) => setPlanType(e.value)}
            />
          </div>

          {planType === 1 && (
            <div className="col-lg-6 form-group mb-3">
              <SelectBox
                items={ViewValues}
                label="View count"
                className="dx-field-item-required"
                valueExpr="value_id"
                displayExpr="value_data"
                labelMode="floating"
                onValueChanged={(e) => setSelectedViewValue(e.value)}
              />
            </div>
          )}
          {planType === 2 && (
            <>
              <div className="col-lg-6 form-group mb-3">
                <DateBox
                  className="dx-field-item-required"
                  label="Start date"
                  labelMode="floating"
                  onValueChanged={(e) => setStartDate(e.value)}
                />
              </div>
              <div className="col-lg-6 form-group mb-3">
                <DateBox
                  className='dx-field-item-required'
                  label='End date'
                  labelMode='floating'
                  onValueChanged={(e) => setEndDate(e.value)}
                />
              </div>
            </>
          )}
          <div className="col-lg-6 form-group mb-3">
            <SelectBox
              items={[
                { id: "inr", name: "INR" },
                { id: "usd", name: "USD" }
              ]}
              label='Currency'
              className='dx-field-item-required'
              labelMode='floating'
              displayExpr="name"
              valueExpr="id"
              onValueChanged={(e) => setCurrency(e.value)}
            />
          </div>
          <div className="col-lg-6 form-group mb-3">
            <TextBox
              label='Total Amount'
              className='dx-field-item-disabled'
              labelMode='floating'
              value={totalAmount}
              readOnly={true}
            />
          </div>
          <div className="col-lg-6 form-group mb-3">
            <SelectBox
              items={[
                { id: "cheque", name: "Cheque" },
                { id: "online", name: "Online" }
              ]}
              label='Payment mode'
              className='dx-field-item-disabled'
              labelMode="floating"
              displayExpr="name"
              valueExpr="id"
              onValueChanged={(e) => setPaymentMode(e.value)}
            />
          </div>
          {paymentMode === "cheque" && (
            <div className="col-lg-6 form-group mb-3">
              <TextBox
                type="text"
                labelMode='floating'
                className='dx-field-item-required'
                required="required"
                label="Cheque Number"
                onValueChanged={(e) => setChequeNumber(e.value)}
              />
            </div>
          )}
          
          <div className="col-lg-6 form-group mb-3">
            <TextBox
              type="text"
              label='Click through link'
              labelMode="floating"
              onValueChanged={(e) => setRedirectLink(e.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='CancelBtn' variant="secondary" onClick={onClose}>
          Cancel
        </button>
        <button className='button' variant="primary" onClick={handleSubmit}>
          Submit
        </button>
      </Modal.Footer>
      <RazorpayPayment
        ref={razorpayRef}
        onSuccess={handleRazorpaySuccess}
        onFailure={handleRazorpayFailure}
      />
    </Modal> 
  );
};

export default TopUpPopup;
