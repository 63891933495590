import React from "react";
import { Modal } from "react-bootstrap";

const MapPopup = ({ show, onClose, mapLocation }) => {
  return (
    <Modal show={show} onHide={onClose} centered className="rounded">
      <Modal.Header className="p-1 text-center" style={{ border: "none" }}>
        <Modal.Title
          style={{ fontSize: "22px", border: "none", justifyContent: "center" }}
        >
          Venue Location
        </Modal.Title>
      </Modal.Header>
      <iframe
        src={`${mapLocation}`}
        style={{
          height: "60vh",
          width: "auto",
          maxHeight: "60vh",
          maxWidth: "60vh",
        }}
        className="rounded"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Location Map"
      ></iframe>
      <Modal.Footer style={{ border: "none" }}></Modal.Footer>
    </Modal>
  );
};

export default MapPopup;
