import React from "react";
import { TextArea } from "devextreme-react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import axios from "axios";
import { BASEPATH } from "../../../config/config";
import { useMutation } from "@tanstack/react-query";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import { useQueryClient } from "@tanstack/react-query";
import { ABOUT_PROFILE, GET_ABOUTS } from "../../../Api/api";
import { MdEdit } from "react-icons/md";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function About_myProfile() {
  const { user, authTokens } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const token = localStorage.getItem("MDnD_auth");

  const [AboutCollection, setAboutCollection] = useState({
    about_textbox: "",
    validation_msg_abouttextbox: "",
  });

  const [Aboutfrombackend, setAboutfrombackend] = useState({
    first_text: "",
    description: "",
    validation_for_description: "",
  });
  const [checker, setChecker] = useState(true);
  const [counter, setCounter] = useState(0);
  const [checkEditing, setcheckEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Loader state

  const handleValue_about_textbox = (e) => {
    setAboutCollection((prev) => ({ ...prev, about_textbox: e.value }));

    if (e?.value.length > 0) {
      setAboutCollection((prev) => ({ ...prev, validation_msg_abouttextbox: "" }));
    }
  };

  const handleCloseafterSubmit = () => {
    setAboutCollection((prev) => ({ ...prev, validation_msg_abouttextbox: "", about_textbox: "" }));
    setAboutfrombackend((prevState) => ({
      ...prevState,
      validation_for_description: "",
      first_text: "",
    }));
    setcheckEditing(false);
  };

  const handleAbouttoBackend = () => {
    if (!AboutCollection.about_textbox) {
      setAboutCollection((prevState) => ({
        ...prevState,
        validation_msg_abouttextbox: "Please Fill out this Field!",
      }));
    } else {
      setAboutCollection((prevState) => ({
        ...prevState,
        validation_msg_abouttextbox: "",
      }));
      mutationAboutProfile.mutate();
    }
  };

  const PostAboutProfile = () => ABOUTTEXTBOXPROFILE(ABOUT_PROFILE);

  const mutationAboutProfile = useMutation({
    mutationKey: ["About_in_profile"],
    mutationFn: () => PostAboutProfile(),
    onSuccess: (data) => {
      if (data.status === "success") {
        SuccessNewToaster("success", "Succesfully Updated!", 3000);
        handleCloseafterSubmit();
        setCounter(counter + 1);
      }
    },
    onError: () => {
      handleCloseafterSubmit();
    },
  });

  const ABOUTTEXTBOXPROFILE = async (ABOUT_PROFILE) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${ABOUT_PROFILE}`,
        data: {
          roleid: user ? user.role_id : "",
          profileabout: checker ? AboutCollection.about_textbox : Aboutfrombackend.description,
        },
        headers: {
          MDnD_auth: `${token}`,
        },
        timeout: 60000,
        responseType: "json",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  // Fetching the About data
  useEffect(() => {
    if (user) {
      mutationgetAbout.mutate();
    }
  }, [counter]);

  const GetallAbout = () => GET_ABOUTS_FROM_BACKEND(GET_ABOUTS);

  const GET_ABOUTS_FROM_BACKEND = async (GET_ABOUTS) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_ABOUTS}`,
        data: {
          roleid: user ? user.role_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },
        timeout: 60000,
        responseType: "json",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetAbout = useMutation({
    mutationKey: ["get_All_About"],
    mutationFn: () => GetallAbout(),
    onSuccess: (data) => {
      if (data.status === "success") {
        setIsLoading(false); // Stop loading once data is fetched
        if (data.data.description !== "") {
          setChecker(false);
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description: data.data.description,
            first_text: data.data.description,
          }));
        } else {
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description: "",
            first_text: "",
          }));
        }
      }
    },
    onError: () => {
      setcheckEditing(false);
      setIsLoading(false); // Stop loading if there’s an error
    },
  });

  const handleAboutduringUpdate = (e) => {
    setAboutfrombackend((prevState) => ({
      ...prevState,
      description: e.value,
    }));
  };

  const handlefinalEdittoBackend = () => {
    if (Aboutfrombackend.description === Aboutfrombackend.first_text) {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_for_description: "Please enter new text!",
      }));
    } else {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_for_description: "",
      }));
      mutationAboutProfile.mutate();
    }
  };

  const handleEditing = () => {
    setcheckEditing(true);
  };

  const handleCancel = () => {
    setcheckEditing(false);
    setAboutfrombackend((prevState) => ({
      ...prevState,
      validation_for_description: "",
    }));
  };

  return (
    <>
      <div className="description-content">
        <div className="">
          <div className="d-flex mt-2 justify-content-between pb-2 mb-3 GrayBorder">
            <h6 className="font-size-18 fw-500 align-content-center mb-0">
              About Me
              <br />
            </h6>
            {checkEditing !== true && (
              <button
                onClick={handleEditing}
                className="button"
                style={{ padding: "4px 8px" }}
              >
                <MdEdit />
              </button>
            )}
          </div>
          
          {isLoading ? (
            <Skeleton height={140} count={1} /> // Show skeleton while loading
          ) : (
            <>
              {checkEditing !== true && (Aboutfrombackend.description || null)}
              {checker ? (
                <>
                  <TextArea
                    value={AboutCollection.about_textbox}
                    height={"140px"}
                    placeholder="Write Something About Yourself!"
                    onValueChanged={handleValue_about_textbox}
                    style={{ marginBottom: "6px" }}
                  />
                  {AboutCollection.validation_msg_abouttextbox && (
                    <span className="error-message" style={{ margin: "0", marginBottom: "6px" }}>
                      {AboutCollection.validation_msg_abouttextbox}
                    </span>
                  )}
                  <button
                    onClick={handleAbouttoBackend}
                    className="button"
                    style={{ marginBottom: "6px" }}
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  {checkEditing && (
                    <TextArea
                      value={Aboutfrombackend.description}
                      height={"140px"}
                      onValueChanged={handleAboutduringUpdate}
                      style={{ marginBottom: "6px" }}
                    />
                  )}
                  {Aboutfrombackend.validation_for_description && (
                    <span className="error-message" style={{ margin: "0", marginBottom: "6px" }}>
                      {Aboutfrombackend.validation_for_description}
                    </span>
                  )}
                  {checkEditing === true && (
                    <div className="button-row">
                      <button onClick={handlefinalEdittoBackend} className="button">
                        Save
                      </button>
                      <button onClick={handleCancel} className="CancelBtn">
                        Cancel
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
