import React from "react";
import { useEffect } from "react";
import jsPDF from 'jspdf';

export default function ViewTicketPdf(){



   

    useEffect(() => {
        const ticketData = {
          name: 'sudhakar org',
          email: 'sudhakar@tandemdigital.in',
          mobile: '8973192797',
          event_title: 'Free with reg',
          event_date: '2024-10-25',
          event_time: '02:49:00',
          ticket_count: 1,
          ticket_pass: 'NOAAXMM',
          free_ticket_id: 18,
        };
    
        const generatePdf = () => {
          const doc = new jsPDF();
          doc.text(`Name: ${ticketData.name}`, 10, 10);
          doc.text(`Email: ${ticketData.email}`, 10, 20);
          doc.text(`Mobile: ${ticketData.mobile}`, 10, 30);
          doc.text(`Event: ${ticketData.event_title}`, 10, 40);
          doc.text(`Date: ${ticketData.event_date}`, 10, 50);
          doc.text(`Time: ${ticketData.event_time}`, 10, 60);
          doc.text(`Ticket Pass: ${ticketData.ticket_pass}`, 10, 70);
    
          // Generate Base64 PDF and open in new tab
          const pdfBlob = doc.output('blob');
          const pdfUrl = URL.createObjectURL(pdfBlob);
          window.location.href = pdfUrl;
        };
    
        generatePdf();
      }, []);

  
    return(<>
    <p>PDF view</p>
    </>)
}