import React, { useState, useContext, useRef, useEffect } from "react";
import "./ProductSummary.css";
import { GlobalContext } from "../../global/provider";
import { TextBox } from "devextreme-react";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { CHECKOUT_VALIDATION_SCHEMA } from "../../Schemas/Validations/checkout";
import { CheckOutSchema } from "../../Schemas/Forms/pages";
import RazorpayPayment from "../paymentGateWay/razorPay";
import { RAZORPAY, seatingPlanNumbered, STRIPE } from "../../global/constants";
import { GET_PAYMENT_DETAILS } from "../../Api/api";
import { SUCCESS, ERROR, DURATION } from "../../global/ToastMsgConstants.tsx";
import { IoMdAddCircleOutline } from "react-icons/io";
import { HiOutlineMinusCircle } from "react-icons/hi";
import {
  makePostRequest,
  makeGetRequest,
  makeDeleteRequest,
} from "../../Api/requestMethods.js";
import {
  ALLEVENT_DETAILS_IMAGES,
  handlingChargesPercentage,
  handlingChargesAmount,
  INCREMENT,
  DECREMENT,
  deleteData,
  updateData,
} from "../../global/constants";
import {
  freewithregistration,
  ticketOptionFree,
  isAddonPromoCodeEnable,
} from "../../global/constants";
import {
  BUY_TICKETS_FREE_WITH_REGISTRATION,
  summaryAddonCode,
} from "../../Api/api";
import notify from "devextreme/ui/notify";
import { useNavigate } from "react-router-dom";
import {
  getCartList,
  summaryPromoCode,
  checkAvailPromoCode,
  deleteCartItem,
  appliedPromoCode,
  deletePromoCode,
  applyAddOnCodeData,
  addToCart,
  summaryDeleteAddOnCode,
} from "../../Api/api";
import AuthContext from "../../context/AuthContext.js";
import {
  calculateOverAllTotal,
  appliedCouponCodeStructure,
  isValidValue,
} from "../../utils/commonUtils.js";
import { RiDeleteBinLine } from "react-icons/ri";
import { IoCloseCircleOutline } from "react-icons/io5";
import PromoCodeSummary from "../Components/promoCode/promoCodeSummary.js";
import AddOnCodeSummary from "../Components/add-onCode/addOnCodeSummary.js";
import { LIMITATION_ERROR } from "../../Schemas/errorMessageConstants.js";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { isValidArray } from "../../utils/commonUtils.js";

function ProductSummary() {
  const location = useLocation();
  const cartDataSource = [];
  const [isCartDisabled, setIsCartDisabled] = useState(false);
  const sessionId = localStorage.getItem("mdnd_cId");
  const { user } = useContext(AuthContext);
  const [selectedItem, setSelectedItem] = useState(RAZORPAY);
  const [dataSource, setDataSource] = useState({
    isLoading: false,
    payementData: "",
    country_code: "",
    mobile: "",
    cartListDataSource: "",
    promoCodeDataSource: "",
    addonCodeDataSource: "",
    addOnCodeCount: "",
    subTotal: "",
    appliedCoupon: "",
    currentEventId: "",
    currentCountryCode: "",
    availablePromoCodeError: "",
    selectedAddonCartId: "",
  });
  const [settings, setSettings] = useState({
    isSubmitEnable: false,
    buttonName: "",
    isAddonPopupEnabled: false,
    isPromoCodePopEnabled: false,
    isSubmitting: false,
    trigger: 0,
  });
  const checkOutPhRef = useRef(null);
  const razorpayRef = useRef();
  const stripeRef = useRef();
  const navigate = useNavigate();
  const {
    userDetails,
    eventsDatasource,
    setEventDataSource,
    setCartDetails,
    cartDetails,
  } = useContext(GlobalContext);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setTouched,
    errors,
    touched,
    setValues,
    setFieldValue,
    isSubmitting,
  } = useFormik({
    initialValues: CheckOutSchema,
    validationSchema: CHECKOUT_VALIDATION_SCHEMA,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await handleTriggerPayment();
      setSubmitting(false);
    },
  });

  const fetchCartList = async () => {
    try {
      if (user || sessionId) {
        const body = {
          session_id: user?.role_id ? "" : sessionId,
          role_id: user?.role_id || "",
          country_id: 101,
        };
        const response = await makePostRequest(getCartList, body);
        if (response && response.status === SUCCESS) {
          setDataSource((prev) => ({
            ...prev,
            cartListDataSource: response?.data,
          }));
        } else {
          notify(response.response.data.message, ERROR, DURATION);
        }
      }
    } catch (error) {
      notify(error.response.data.message, ERROR, DURATION);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCartList();
    const metaTitle = `Select row/seat for ${dataSource?.cartListDataSource[0]?.event_title}`;
    document.title = metaTitle;
    const metaDescription = `Select your row/seat for ${dataSource?.cartListDataSource[0]?.event_title}.Reserve your seat and book tickets for ${dataSource.cartListDataSource[0]?.event_title}`;
    const descriptionTag = document.querySelector('meta[name="description"]');
    if (descriptionTag) {
      descriptionTag.setAttribute("content", metaDescription);
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = metaDescription;
      document.head.appendChild(meta);
    }
  }, [sessionId, user?.role_id]);

  const CheckCondtion = eventsDatasource && eventsDatasource?.eventDetails;

  useEffect(() => {
    setEventDataSource((prev) => ({
      ...prev,
      numberedTicketAmountDetails: [],
    }));
    if (userDetails.profileDetails) {
      const profile = { ...userDetails.profileDetails };
      setDataSource((prev) => ({
        ...prev,
        country_code: profile.countrycode,
        mobile: profile.mobile,
      }));

      if (profile.countrycode) {
        profile.countrycode = profile.countrycode.startsWith("+")
          ? (profile.mobile = `${profile.countrycode} ${profile.mobile}`)
          : (profile.mobile = `+${profile.countrycode} ${profile.mobile}`);
      }
      const body = {
        name: profile.name,
        email: profile.email,
        mobile: profile.mobile,
        countrycode: profile.countrycode
          ? profile.countrycode.split(" ")[0]
          : "",
      };

      setValues(body);
    } else {
      const newErrors = {
        name: "",
        email: "",
        mobile: "",
      };
      const errorFields = Object.keys(newErrors);
      const touchedFields = { ...touched };
      errorFields.forEach((field) => {
        touchedFields[field] = true;
      });

      setTouched(touchedFields);
      setFieldValue("mobile", "+91");
    }
  }, []);

  useEffect(() => {
    try {
      if (
        isValidValue(eventsDatasource?.membersOnlyName) &&
        isValidValue(eventsDatasource?.membersOnlyMobile) &&
        isValidValue(eventsDatasource?.membersOnlyEmail)
      ) {
        const body = {
          name: eventsDatasource?.membersOnlyName,
          email: eventsDatasource?.membersOnlyEmail,
          mobile: eventsDatasource?.membersOnlyMobile,
          countrycode: "+91",
        };

        setValues(body);
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    eventsDatasource?.membersOnlyName,
    eventsDatasource?.membersOnlyMobile,
    eventsDatasource?.membersOnlyEmail,
  ]);

  const handleMobileChange = (value) => {
    let countryCode = "";
    let internationalCode = "";

    if (checkOutPhRef && checkOutPhRef.current) {
      countryCode =
        checkOutPhRef.current?.state?.selectedCountry?.dialCode || "";
      internationalCode = "+" + countryCode;

      setFieldValue("country_code", countryCode);
      setDataSource((prev) => ({
        ...prev,
        country_code: countryCode,
      }));
    }

    const mobileNumber = value.startsWith(internationalCode)
      ? value.slice(internationalCode.length)
      : value;

    handleChange({ target: { name: "mobile", value: mobileNumber } });
    setDataSource((prev) => ({
      ...prev,
      mobile: mobileNumber,
    }));
  };

  const handleMobileBlur = async (value) => {
    if (values && values.mobile && values.countrycode) {
      if (values.mobile.length === values.countrycode.length - 1) {
        setFieldValue("mobile", "");
      }
    }
    handleBlur({
      target: {
        name: "mobile",
        value: value,
      },
    });
  };

  const calculateEventTotal = (data) => {
    try {
      if (data && data?.slug && data.slug.length > 0) {
        let totalAmount = 0;
        data.slug.forEach((data) => {
          if (data.ticket_quantity !== 0 && data.ticket_quantity !== "0") {
            totalAmount +=
              parseFloat(data.amount) * Number(data.ticket_quantity);
          }
        });
        return totalAmount;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const applyIndidualPromoCode = async (isClosed, data) => {
    try {
      setSettings((prev) => ({
        ...prev,
        isPromoCodePopEnabled: !prev.isPromoCodePopEnabled,
      }));

      if (!isClosed) {
        const { country_id, item_id } = data;
        const response = await makeGetRequest(
          summaryPromoCode(country_id, item_id)
        );
        if (response && response.status === SUCCESS) {
          const compareEligibility = calculateEventTotal(data);
          if (response && isValidArray(response.data)) {
            const updatedPromoCodes = response.data.map((promoCodeData) => ({
              ...promoCodeData,
              promoCodeEnabled:
                compareEligibility >= promoCodeData.eligible_amount || 0,
            }));
            setDataSource((prev) => ({
              ...prev,
              promoCodeDataSource: updatedPromoCodes,
              currentEventId: item_id,
              currentCountryCode: country_id,
            }));
          } else {
            setDataSource((prev) => ({
              ...prev,
              promoCodeDataSource: response.data,
              currentEventId: item_id,
              currentCountryCode: country_id,
            }));
          }
        } else {
          console.error();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const applyIndidualAddonCode = async (isClosed, data) => {
    try {
      setSettings((prev) => ({
        ...prev,
        isAddonPopupEnabled: !prev.isAddonPopupEnabled,
      }));
      if (!isClosed) {
        const { country_id, item_id } = data;
        const response = await makeGetRequest(
          summaryAddonCode(country_id, item_id)
        );
        if (response && response.status === SUCCESS) {
          const modifiedData =
            response.data &&
            response.data.map((data) => ({
              ...data,
              addon_count:
                data?.addon_count === null ? 0 : Number(data?.addon_count),
            }));
          setDataSource((prev) => ({
            ...prev,
            addonCodeDataSource: modifiedData,
            currentEventId: item_id,
            currentCountryCode: country_id,
            selectedAddonCartId: data?.cart_id,
          }));
        } else {
          notify();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPromoCodeAvailability = async (searchQuery) => {
    setDataSource((prev) => ({
      ...prev,
      availablePromoCodeError: "",
    }));
    try {
      if (
        isValidValue(searchQuery) &&
        isValidValue(dataSource.currentEventId) &&
        isValidValue(dataSource.currentCountryCode)
      ) {
        const response = await makeGetRequest(
          checkAvailPromoCode(
            dataSource.currentCountryCode,
            dataSource.currentEventId,
            searchQuery
          )
        );
        if (response && response.status === SUCCESS) {
          const body = {
            searchQueryResEventId: dataSource.currentEventId,
            searchQueryRescountryCode: dataSource.currentCountryCode,
            CouponCodeApplied: "Yes",
          };
          dataSource.appliedCoupon.push(body);
        } else {
          setDataSource((prev) => ({
            ...prev,
            availablePromoCodeError: response.message,
          }));
        }
      } else {
      }
    } catch (error) {
      setDataSource((prev) => ({
        ...prev,
        availablePromoCodeError: error?.response?.data?.message,
      }));
      console.error(error);
    }
  };

  const applyPromoCode = async (data, isOverAllPromoCode) => {
    try {
      const { promo_id, discount_value, event_id, promo_code } = data;
      dataSource?.appliedCoupon.map((item) => {
        if (item.event_id === event_id) {
          return {
            ...item,
            promo_code: promo_code,
            couponValue: discount_value,
          };
        }
        return item;
      });

      const getCartId =
        dataSource &&
        dataSource.cartListDataSource
          .filter((data) => data.item_id === event_id)
          .map((data) => data.cart_id);
      const body = { cart_id: getCartId[0], promo_id: promo_id };
      const response = await makePostRequest(appliedPromoCode, body);
      if (response && response.status === SUCCESS) {
        fetchCartList();
      }
      setSettings((prev) => ({ ...prev, isPromoCodePopEnabled: false }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (dataSource) {
      setDataSource((prev) => ({
        ...prev,
        subTotal:
          calculateOverAllTotal(dataSource, undefined, undefined) || 0.0,
        appliedCoupon: appliedCouponCodeStructure(dataSource),
      }));
    }
  }, [dataSource.cartListDataSource]);

  const deleteOrUpdateSingleDenomination = async (
    data,
    slug,
    condition,
    mainIndex
  ) => {
    var updatedSlug;
    if (condition === deleteData) {
      updatedSlug =
        dataSource.cartListDataSource &&
        dataSource.cartListDataSource
          .map((item) => {
            let updated = false;
            const updatedSlugs = item.slug.map((slugData) => {
              if (!updated && slugData.id === slug.id) {
                updated = true;
                return { ...slugData, ticket_quantity: "0" };
              }
              return slugData;
            });
            return updatedSlugs;
          })
          .find((item) => item);
    }
    const dataToUse = condition === deleteData ? updatedSlug : slug;
    const ticketData = dataToUse?.map((data) => {
      return {
        id: data.id,
        qty: data.ticket_quantity,
      };
    });

    const body = {
      role_id: Number(user?.role_id) || "",
      login_id: Number(user?.login_id) || "",
      session_id: !user?.role_id && !user?.login_id ? sessionId : null,
      country_id: Number(data?.country_id),
      item_id: Number(data?.item_id),
      cart_purpose: "ticket",
      total_quantity: ticketData,
      season_flag: data?.season_flag,
    };

    try {
      if (user?.role_id || sessionId) {
        setSettings((prev) => ({ ...prev, isSubmitting: true }));
        const response = await makePostRequest(addToCart, [body]);
        if (response && response?.status === SUCCESS) {
          setSettings((prev) => ({ ...prev, isSubmitting: false }));
          fetchCartList();
          const cartCount = response?.data && response?.data?.cart_count;
          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
        }
      }
    } catch (error) {
      setSettings((prev) => ({ ...prev, isSubmitting: false }));
      console.error(error);
    }
  };

  const deletePromoCodeFn = async (data) => {
    try {
      const response = await makeDeleteRequest(deletePromoCode(data?.cart_id));
      if (response && response.status === SUCCESS) {
        fetchCartList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const applyAddOnCode = async () => {
    try {
      const cartID = dataSource && dataSource?.selectedAddonCartId;
      const modifiedData =
        dataSource &&
        dataSource.addonCodeDataSource.filter((data) => data.addon_count !== 0);

      const body = {
        cart_id: cartID,
        role_id: Number(user?.role_id) || "",
        session_id: !user?.role_id && !user?.login_id ? sessionId : null,
        login_id: Number(user?.login_id) || "",
        addonCodeDataSource: modifiedData,
      };
      const response = await makePostRequest(applyAddOnCodeData, body);
      if (response && response?.status === SUCCESS) {
        fetchCartList();
        setSettings((prev) => ({ ...prev, isAddonPopupEnabled: false }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete entire events row
  const entierRowData = async (entireRowData) => {
    setIsCartDisabled(true);
    const body = {
      role_id: Number(user?.role_id) || "",
      session_id: !user?.role_id && !user?.login_id ? sessionId : null,
      cart_id: entireRowData.cart_id,
      login_id: Number(user?.login_id) || "",
    };
    try {
      if (user?.role_id || sessionId) {
        const response = await makePostRequest(deleteCartItem, body);
        if (response && response?.status === SUCCESS) {
          fetchCartList();
          const cartCount = response?.data && response?.data?.cart_count;

          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
          cartCount === 0 ? navigate("/") : fetchCartList();
          setIsCartDisabled(false);
        }
      }
    } catch (error) {
      setIsCartDisabled(false);
      console.error(error);
    }
  };
  const ticketLimitationEvent = async (
    condition,
    mainArrayIndex,
    slugIndex
  ) => {
    const mainArray = dataSource.cartListDataSource[mainArrayIndex];
    const slugArray = dataSource.cartListDataSource[mainArrayIndex]?.slug;
    if (!slugArray) {
      console.error("Slug array not found");
      return;
    }
    const getIndexObject = slugArray[slugIndex];
    if (!getIndexObject) {
      console.error("Slug object not found");
      return;
    }
    const { balance_tickets, ticket_limitations, ticket_quantity, row_name } =
      getIndexObject;
    const totalTicketBalance = balance_tickets;
    const maxLimit = ticket_limitations;

    if (condition === INCREMENT) {
      const newTicketLimit =
        balance_tickets <= ticket_limitations ? totalTicketBalance : maxLimit;
      if (Number(ticket_quantity) >= newTicketLimit) {
        notify(LIMITATION_ERROR(newTicketLimit, row_name), ERROR, DURATION);
        return;
      } else {
        slugArray[slugIndex].ticket_quantity = String(
          Number(ticket_quantity) + 1
        );
      }
    }

    if (condition === DECREMENT) {
      if (
        Number(ticket_quantity) > 0 &&
        slugArray[slugIndex].ticket_quantity !== "1" &&
        slugArray[slugIndex].ticket_quantity !== 1
      ) {
        slugArray[slugIndex].ticket_quantity = String(
          Number(ticket_quantity) - 1
        );
      } else {
        return;
      }
    }
    try {
      await deleteOrUpdateSingleDenomination(
        mainArray,
        slugArray,
        updateData,
        null
      );
    } catch (error) {
      console.error("Error updating slug array:", error);
    }
  };

  const addOnCodeDelete = async (data, addOnData) => {
    try {
      const response = await makeDeleteRequest(
        summaryDeleteAddOnCode(
          data?.cart_id,
          data?.item_id,
          addOnData?.addon_id
        )
      );
      if (response && response.status === SUCCESS) {
        fetchCartList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const calculatePromoCodeCharges = (currentItem, cartArray) => {
    try {
      let promoCodeAmount = 0;
      // Only calculate for the current item's promo code
      const denominationTotal = calucateOverAllRowTotal(currentItem);

      // Only process promo code if it exists for this item
      if (currentItem.promo_code) {
        const promoDetails = cartArray.find(
          (item) => item.event_id === currentItem.event_id && item.promo_code
        );

        if (promoDetails) {
          if (
            promoDetails.discount_type === handlingChargesPercentage ||
            promoDetails.discount_type === "% of Ticket Value" ||
            promoDetails.discount_type === "% of ticket value"
          ) {
            promoCodeAmount =
              (promoDetails.discount_value * denominationTotal) / 100 || 0;
          } else if (
            promoDetails.discount_type === handlingChargesAmount ||
            promoDetails.discount_type === "Fixed Amount per ticket"
          ) {
            promoCodeAmount = parseFloat(promoDetails.discount_value) || 0;
          }
        }
      }

      // Update cart data for this specific item
      updateCartDataSource(
        updateCartlist(
          currentItem,
          "promo_code_discount",
          parseFloat(promoCodeAmount.toFixed(2))
        )
      );
      updateCartDataSource(
        updateCartlist(
          currentItem,
          "event_subtotal",
          parseFloat(denominationTotal - promoCodeAmount.toFixed(2))
        )
      );

      return {
        promoCodeAmount: promoCodeAmount,
        promoCodeAppliedTotal: denominationTotal - promoCodeAmount,
      };
    } catch (error) {
      console.error("Error calculating promo code charges:", error);
      return {
        promoCodeAmount: "0.00",
        promoCodeAppliedTotal: "0.00",
      };
    }
  };
  const calculateGSTPercentage = (slugArray) => {
    try {
      let gstTax = 0;
      if (slugArray && slugArray.length > 0) {
        for (const item of slugArray) {
          if (
            item.gst_tax !== null &&
            item.gst_tax !== 0 &&
            item.ticket_quantity !== "0" &&
            item.ticket_quantity !== 0
          ) {
            // Return the first instance of the gst_tax value
            return parseFloat(item.gst_tax);
          }
        }
      }
      // If all gst_tax are null, return 0
      return gstTax;
    } catch (error) {
      console.error(error);
      return "0";
    }
  };

  const calculateEventGST = (promoCodeAppliedTotal, slugArray) => {
    try {
      let gstTax = 0;
      let gstApplied = false;
      if (
        slugArray &&
        Array.isArray(slugArray.slug) &&
        slugArray.slug.length > 0
      ) {
        for (const item of slugArray.slug) {
          if (
            !gstApplied &&
            item.gst_tax !== null &&
            item.gst_tax !== 0 &&
            item.ticket_quantity !== "0" &&
            item.ticket_quantity !== 0
          ) {
            const gstTaxValue = item.gst_amount || 0;
            gstTax += gstTaxValue;
            gstApplied = true;

            // 09-20-2024 gst issues
            // const gstTaxValue = item.gst_amount || 0;
            // console.log(gstTaxValue, "gstTaxValue");
            // gstTax += (gstTaxValue * promoCodeAppliedTotal) / 100;
            // gstApplied = true;
          }
        }
      }

      const subTotalWithGST = promoCodeAppliedTotal + gstTax;
      updateCartDataSource(
        updateCartlist(slugArray, "tax_amount", parseFloat(gstTax.toFixed(2)))
      );
      return {
        gstTax: gstTax.toFixed(2),
        subTotalWithGST: subTotalWithGST.toFixed(2),
      };
    } catch (error) {
      console.error("Error calculating GST:", error);
      return {
        gstTax: "0.00",
        subTotalWithGST: "0.00",
      };
    }
  };

  const calculateAddonAmount = (slugArray, slugObject) => {
    try {
      let addonAmount = 0;
      if (slugArray && Array.isArray(slugArray)) {
        slugArray.forEach((data) => {
          addonAmount +=
            parseFloat(data.addon_count) * parseFloat(data.amount) || 0;
        });

        updateCartDataSource(
          updateCartlist(
            slugObject,
            "add_on_amount",
            parseFloat(addonAmount.toFixed(2))
          )
        );
      }
      return addonAmount;
    } catch (error) {
      console.log(error);
    }
  };

  const calculateHandlingCharges = (slugObject) => {
    try {
      let handlingAmount = 0;
      let overAllSubTotal = 0;
      let GSTAmount = 0;
      if (
        slugObject &&
        Array.isArray(slugObject.slug) &&
        slugObject.slug.length > 0
      ) {
        for (const item of slugObject.slug) {
          if (item.ticket_quantity !== "0" && item.ticket_quantity !== 0) {
            const totalAmount = item.amount * item.ticket_quantity;
            if (item.hc_type === handlingChargesPercentage) {
              const hcWithoutGST = (item.hc_value * totalAmount) / 100 || 0;
              handlingAmount += hcWithoutGST;
              GSTAmount += (hcWithoutGST * (item.hc_gst_tax || 0)) / 100;
            } else if (item.hc_type === handlingChargesAmount) {
              const hcWithoutGST = parseFloat(item.hc_value) || 0;
              handlingAmount += hcWithoutGST * item.ticket_quantity;
              GSTAmount += (hcWithoutGST * (item.hc_gst_tax || 0)) / 100;
            }
            overAllSubTotal += totalAmount;
          }
        }
      }
      updateCartDataSource(
        updateCartlist(
          slugObject,
          "hc_charge",
          parseFloat(handlingAmount.toFixed(2))
        )
      );

      return {
        handlingAmount: parseFloat(handlingAmount) + parseFloat(GSTAmount),
        handlingAddedSubTotal:
          parseFloat(overAllSubTotal) +
          parseFloat(handlingAmount) +
          parseFloat(GSTAmount),
      };
    } catch (error) {
      console.error("Error calculating handling charges:", error);
      return {
        handlingAmount: 0.0,
        handlingAddedSubTotal: 0.0,
      };
    }
  };

  const updateCartDataSource = (updatedCartList) => {
    try {
      updatedCartList &&
        updatedCartList.forEach((updatedItem) => {
          const existingIndex = cartDataSource.findIndex(
            (item) => item.cart_id === updatedItem.cart_id
          );
          if (existingIndex > -1) {
            cartDataSource[existingIndex] = {
              ...cartDataSource[existingIndex],
              ...updatedItem,
            };
          } else {
            cartDataSource.push(updatedItem);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const calucateOverAllRowTotal = (slugObject) => {
    try {
      let denominationTotal = 0;
      if (slugObject && Array.isArray(slugObject.slug)) {
        slugObject.slug.forEach((data) => {
          denominationTotal +=
            parseFloat(data.amount) * Number(data.ticket_quantity) || 0;
        });
        updateCartDataSource(
          updateCartlist(
            slugObject,
            "denominationTotal",
            parseFloat(denominationTotal.toFixed(2))
          )
        );
      }
      return denominationTotal;
    } catch (error) {
      console.error(error);
    }
  };

  const updateCartlist = (overAllData, key, subtotal) => {
    try {
      const filteredCart =
        dataSource.cartListDataSource &&
        dataSource.cartListDataSource.length > 0 &&
        overAllData &&
        dataSource.cartListDataSource.filter(
          (data) => data.cart_id === overAllData?.cart_id
        );
      const updatedCartList =
        filteredCart &&
        filteredCart.map((data) => {
          return {
            ...data,
            [key]: parseFloat(subtotal),
          };
        });
      return updatedCartList;
    } catch (error) {
      console.error(error);
    }
  };

  const calculateFinalSubTotal = (
    handlingAmount,
    promoCodeAppliedTotal,
    gstTax,
    addonAmount,
    itemData
  ) => {
    try {
      // Ensure we're working with numbers
      handlingAmount = parseFloat(handlingAmount) || 0;
      promoCodeAppliedTotal = parseFloat(promoCodeAppliedTotal) || 0;
      gstTax = parseFloat(gstTax) || 0;
      addonAmount = parseFloat(addonAmount) || 0;

      // Calculate subtotal for this specific item
      const subtotal =
        promoCodeAppliedTotal + gstTax + handlingAmount + addonAmount;

      return subtotal.toFixed(2);
    } catch (error) {
      console.error("Error calculating final subtotal:", error);
      return "0.00";
    }
  };

  const toNumber = (value) => {
    if (value === null || value === undefined || value === "") return 0;
    const num = parseFloat(value);
    return isNaN(num) ? 0 : num;
  };

  const { setPrevURL } = useContext(AuthContext);

  useEffect(() => {
    setPrevURL(`${location.pathname}${location.search}`);
  }, [location]);

  useEffect(() => {
    try {
      const handlePromoCodeDeletion = async () => {
        if (
          dataSource.cartListDataSource &&
          dataSource.cartListDataSource.length > 0
        ) {
          for (const data of dataSource.cartListDataSource) {
            const getEventTotal = calculateEventTotal(data);
            if (data.promo_code && getEventTotal < data.eligible_amount) {
              await deletePromoCodeFn(data);
            }
          }
        }
      };
      handlePromoCodeDeletion();
    } catch (error) {}
  }, [dataSource.cartListDataSource]);

  const calculateCartTotal = (cartItems) => {
    try {
      if (!cartItems || !Array.isArray(cartItems)) {
        return {
          subtotal: "0.00",
          totalPromoDiscount: "0.00",
          totalGST: "0.00",
          totalHandlingCharges: "0.00",
          totalAddonCharges: "0.00",
          grandTotal: "0.00",
        };
      }

      let cartTotals = cartItems.reduce(
        (totals, item) => {
          // Get promo code calculations for this item
          const promoResult = calculatePromoCodeCharges(item, cartItems);

          // Get GST for this item
          const gstResult = calculateEventGST(
            toNumber(promoResult?.promoCodeAppliedTotal),
            item
          );

          // Get handling charges for this item
          const handlingResult = calculateHandlingCharges(item);

          // Get addon amount for this item
          const addonAmount = calculateAddonAmount(item?.addon_details);

          // Calculate raw subtotal before any discounts
          const rawSubtotal = calucateOverAllRowTotal(item);

          updateCartDataSource(
            updateCartlist(
              item,
              "event_subtotal",
              parseFloat(rawSubtotal.toFixed(2))
            )
          );

          return {
            subtotal: toNumber(totals.subtotal) + toNumber(rawSubtotal),
            totalPromoDiscount:
              toNumber(totals.totalPromoDiscount) +
              toNumber(promoResult?.promoCodeAmount),
            totalGST: toNumber(totals.totalGST) + toNumber(gstResult?.gstTax),
            totalHandlingCharges:
              toNumber(totals.totalHandlingCharges) +
              toNumber(handlingResult?.handlingAmount),
            totalAddonCharges:
              toNumber(totals.totalAddonCharges) + toNumber(addonAmount),
          };
        },
        {
          subtotal: 0,
          totalPromoDiscount: 0,
          totalGST: 0,
          totalHandlingCharges: 0,
          totalAddonCharges: 0,
        }
      );

      // Calculate grand total with safe number conversion
      const grandTotal =
        toNumber(cartTotals.subtotal) -
        toNumber(cartTotals.totalPromoDiscount) +
        toNumber(cartTotals.totalGST) +
        toNumber(cartTotals.totalHandlingCharges) +
        toNumber(cartTotals.totalAddonCharges);

      // Ensure all values are numbers before formatting
      return {
        subtotal: toNumber(cartTotals.subtotal).toFixed(2),
        totalPromoDiscount: toNumber(cartTotals.totalPromoDiscount).toFixed(2),
        totalGST: toNumber(cartTotals.totalGST).toFixed(2),
        totalHandlingCharges: toNumber(cartTotals.totalHandlingCharges).toFixed(
          2
        ),
        totalAddonCharges: toNumber(cartTotals.totalAddonCharges).toFixed(2),
        grandTotal: toNumber(grandTotal).toFixed(2),
      };
    } catch (error) {
      console.error("Error calculating cart total:", error);
      return {
        subtotal: "0.00",
        totalPromoDiscount: "0.00",
        totalGST: "0.00",
        totalHandlingCharges: "0.00",
        totalAddonCharges: "0.00",
        grandTotal: "0.00",
      };
    }
  };

  const cartTotals = calculateCartTotal(
    dataSource?.cartListDataSource
  ).grandTotal;

  const handleTriggerPayment = async () => {
    const bookedDetails =
      (await eventsDatasource) &&
      eventsDatasource?.formdata !== "" &&
      eventsDatasource?.formdata
        ?.filter((data) => data?.no_of_tickets !== null)
        ?.map((data) => {
          return {
            id: data.id,
            qty: data.no_of_tickets,
          };
        });
    const modifyOrdersData =
      cartDataSource &&
      cartDataSource.map((cartList) => {
        // Basic structure of the modified data
        const modifiedData = {
          country_id: cartList?.country_id,
          event_id: cartList?.item_id,
          seating_option: cartList?.seating_option,
          event_mode: cartList?.event_mode,
          amount: cartList?.denominationTotal || 0.0, // denomination total
          tax_amount: cartList?.tax_amount || 0.0, // gst applied amount
          hc_charge: cartList?.hc_charge || 0.0, // handling charge amount
          promo_code_discount: cartList?.promo_code_discount || 0.0, // promo code applied amount
          add_on_amount: cartList?.add_on_amount || 0.0, //  AddOn Applied amount
          total_amount: cartList?.event_subtotal || 0.0, // overall total sub-total
          promo_id: cartList?.promo_id === 0 ? null : cartList.promo_id, // applied promo code ID
          addon_details: cartList?.addon_details, // applied add on details
          season_flag: cartList?.season_flag,

          event_details: {
            event_title: cartList?.event_title,
            event_start_date: cartList?.event_start_date,
            event_end_date: cartList?.event_end_date,
            event_time: cartList?.event_time,
            venue_name: cartList?.venue_name,
            organiser_name: "",
            event_id: cartList?.item_id,
            season_flag: cartList?.season_flag,
          },
        };

        // Logic for seating options
        if (
          cartList?.seating_option === "non_numbered" ||
          cartList?.seating_option === ""
        ) {
          modifiedData.booked_tickets = cartList.slug.map((data) => ({
            id: data?.id, // id
            qty: data?.ticket_quantity, // ticket qty
            amount: data?.amount, // amount
            row_name: data?.row_name, // row name
            event_total_amount: data?.amount * data?.ticket_quantity, //denomination total amount
          }));
        }

        if (cartList?.seating_option === "numbered") {
          modifiedData.booked_tickets = {
            total_quantity: cartList.slug.map((data) => ({
              numbered_id: data?.numbered_id, // Numbered ID
              rowname: data?.row_name || "", // Row name
              seat_unique: data?.id || "", // Unqiue ID
              floor_name: data?.floor_name, // Floor name
              amount: data?.amount, // Amount
              seat: data?.seat, // Seat
            })),
          };
        }

        return modifiedData;
      });

    const body = {
      payment_data: {
        role_id: user?.role_id || "",
        login_id: user?.login_id || "",
        payment_for: "event",
        total_amount: parseFloat(cartTotals),
        session_id: !user?.role_id && !user?.login_id ? sessionId : null,
        user_name: eventsDatasource.membersOnlyName || values?.name,
        user_email: eventsDatasource.membersOnlyEmail || values?.email,
        user_country_code: dataSource?.country_code,
        user_mobile: eventsDatasource?.membersOnlyMobile || dataSource?.mobile,
        payment_gateway: "razorpay",
      },
      orders_data: modifyOrdersData,
    };

    try {
      if (
        CheckCondtion &&
        CheckCondtion?.ticketing_option === freewithregistration
      ) {
        const freeWithRegistrationBody = {
          user_name: values?.name,
          user_email: values?.email,
          user_country_code: dataSource?.country_code,
          user_mobile: dataSource?.mobile,

          role_id: userDetails?.profileDetails?.role_id || "",
          login_id: userDetails?.profileDetails?.login_id || "",

          country_id: eventsDatasource?.eventDetails?.country_id,

          seating_option: eventsDatasource?.eventDetails?.seating_option,
          event_mode: eventsDatasource?.eventDetails?.event_mode,
          event_id: eventsDatasource?.eventDetails?.event_id,
          booked_tickets: bookedDetails,
        };

        const response = await makePostRequest(
          BUY_TICKETS_FREE_WITH_REGISTRATION,
          freeWithRegistrationBody
        );
        if (response && response.status === SUCCESS) {
          const finalResponse = response && response?.data && response.data[0];
          setEventDataSource((prev) => ({
            ...prev,
            invoiceDetails: finalResponse,
          }));
          navigate(
            `/invoice/?payment-mode=free&id=${finalResponse.ticket_pass}`
          );
        } else {
          notify(
            response && response?.data && response?.data[0]?.error_message,
            ERROR
          );
          setSettings((prev) => ({
            ...prev,
            isSubmitEnable: true,
            buttonName: response?.data[0]?.error_message,
          }));
        }
      } else {
        const response = await makePostRequest(GET_PAYMENT_DETAILS, body);
        if (response && response.status === SUCCESS) {
          if (razorpayRef.current && selectedItem === RAZORPAY) {
            response.data.success_url = "/Success";
            razorpayRef.current.triggerPayment(response.data);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{document?.title}</title>
        <meta name="title" content={document?.title} />
        <meta
          name="description"
          content={`Select your row/seat for ${document?.title}`}
        />
      </Helmet>
      <section className={`section-padding pt-lg-3`}>
        <div className="container mt-3 pb-5">
          <div className="row">
            <div className="col-lg-7 col-md-6 order-lg-1 order-2 mt-2 mt-lg-0">
              <form action="order-received.html">
                <h5 className="mb-4">User details</h5>
                <div className="mb-3">
                  <TextBox
                    type="text"
                    label="First Name"
                    labelMode="floating"
                    className="dx-field-item-required"
                    value={eventsDatasource.membersOnlyName || values.name}
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "name", value: e.value },
                      })
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "name", value: e.value },
                      })
                    }
                    readOnly={eventsDatasource?.membersOnlyName ? true : false}
                  />
                  {errors.name && touched.name && (
                    <p className="m-0 p-0 error-message mt-1">{errors.name}</p>
                  )}
                </div>
                <div className="mb-3">
                  <PhoneInput
                    defaultCountry="IN"
                    value={`${
                      eventsDatasource?.membersOnlyMobile
                        ? "+91" + eventsDatasource.membersOnlyMobile
                        : values.mobile
                    }`}
                    enableSearch={true}
                    onChange={(e) => handleMobileChange(e)}
                    onBlur={(e) => {
                      handleMobileBlur(e);
                    }}
                    countryCodeEditable={false}
                    className="phoneNumberFieldPad dx-texteditor-input phoneFieldBox"
                    areaCodes={true}
                    ref={checkOutPhRef}
                    disabled={
                      eventsDatasource?.membersOnlyMobile ? true : false
                    }
                  />
                  {errors.mobile && touched.mobile && (
                    <p className="m-0 p-0 error-message mt-1">
                      {errors.mobile}
                      {touched.mobile}
                    </p>
                  )}
                </div>
                <div className="mb-3">
                  <TextBox
                    type="text"
                    name="email"
                    label="Email"
                    labelMode="floating"
                    value={eventsDatasource.membersOnlyEmail || values.email}
                    className="dx-field-item-required"
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "email", value: e.value },
                      })
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "email", value: e.value },
                      })
                    }
                    readOnly={
                      eventsDatasource?.membersOnlyMobile ? true : false
                    }
                  />
                  {errors.email && touched.email && (
                    <p className="m-0 p-0 error-message mt-1">
                      {errors.email}
                      {touched.email}
                    </p>
                  )}
                </div>
              </form>
              {/* <StripePayment ref={stripeRef} /> */}

              <div className="d-flex justify-content-end w-100 mb-5 mb-lg-3">
                <button
                  className="button"
                  onClick={handleSubmit}
                  disabled={settings.isSubmitEnable || isSubmitting}
                >
                  {CheckCondtion?.ticketing_option === freewithregistration
                    ? "Get tickets"
                    : settings.isSubmitEnable || isSubmitting
                    ? "Processing"
                    : "Pay Now"}
                </button>
              </div>
            </div>

            {((CheckCondtion &&
              CheckCondtion?.ticketing_option !== freewithregistration &&
              CheckCondtion?.ticketing_option !== ticketOptionFree) ||
              cartDetails.fromCheckOutCart === true) && (
              <div className="col-lg-5 col-md-6 shadow-sm order-lg-2 order-1">
                <h5 className="mt-3">Summary</h5>
                <div className="d-flex flex-column gap-3 align-items-start mb-4">
                  {dataSource &&
                    dataSource.cartListDataSource &&
                    dataSource.cartListDataSource.length > 0 &&
                    dataSource.cartListDataSource.map((data, index) => {
                      const promoCodeResult = calculatePromoCodeCharges(
                        data,
                        dataSource.cartListDataSource
                      );
                      const gstResult = calculateEventGST(
                        promoCodeResult.promoCodeAppliedTotal,
                        data
                      );
                      const handlingResult = calculateHandlingCharges(data);
                      const addonResult = calculateAddonAmount(
                        data?.addon_details
                      );

                      const itemSubTotal = calculateFinalSubTotal(
                        handlingResult.handlingAmount,
                        promoCodeResult.promoCodeAppliedTotal,
                        gstResult.gstTax,
                        addonResult,
                        data
                      );
                      return (
                        <div
                          className={`w-100 card-border d-flex flex-column gap-3  ${
                            isCartDisabled ? "disabledcart" : ""
                          }`}
                          style={{
                            padding: "16px 0",
                            pointerEvents: isCartDisabled ? "none" : "auto",
                            opacity: isCartDisabled ? 0.5 : 1,
                          }}
                          key={index}
                        >
                          <div
                            className="w-100 d-flex gap-2 justify-content-between align-items-center"
                            style={{ padding: "0 12px" }}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <div
                                className="border"
                                style={{
                                  minWidth: "56px",
                                  height: "56px",
                                  borderRadius: "6px",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  className="w-100 h-100"
                                  src={`${ALLEVENT_DETAILS_IMAGES}${data?.event_image}`}
                                  loading="lazy"
                                />
                              </div>
                              <div className="d-flex flex-column">
                                <p
                                  className="m-0 p-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {data?.event_title}
                                </p>
                                <p
                                  className="m-0 p-0"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                ></p>
                              </div>
                            </div>
                            <div
                              style={{ color: "red" }}
                              onClick={() =>
                                !isCartDisabled && entierRowData(data)
                              }
                            >
                              <RiDeleteBinLine />
                            </div>
                          </div>
                          <div
                            className="w-100 d-flex flex-column gap-3 justify-content-between align-items-center"
                            style={{ padding: "0 12px" }}
                          >
                            {data &&
                              data?.slug &&
                              data?.slug?.map((item, itemIndex) => {
                                const nonZeroTicketCount = data?.slug?.filter(
                                  (slugItem) =>
                                    slugItem?.ticket_quantity !== "0" &&
                                    slugItem?.ticket_quantity !== 0
                                )?.length;

                                return (
                                  item?.ticket_quantity !== "0" &&
                                  item?.ticket_quantity !== 0 && (
                                    <div
                                      className="w-100 d-flex gap-1 align-items-center"
                                      key={itemIndex}
                                    >
                                      <div className="w-100 d-flex gap-2 align-items-center">
                                        <div
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                            backgroundColor: "#ddd",
                                            borderRadius: "50%",
                                          }}
                                        ></div>
                                        <p className="p-0 m-0">
                                          {data.seating_option ===
                                          seatingPlanNumbered
                                            ? `${item?.row_name}-${item.seat}`
                                            : item?.row_name}
                                        </p>
                                        <p
                                          className="p-0 m-0"
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            color: "gray",
                                          }}
                                        >
                                          {item.gst_amount !== 0 &&
                                          item.gst_amount !== null
                                            ? "(Taxable)"
                                            : ""}
                                        </p>

                                        {data.seating_option !==
                                          seatingPlanNumbered && (
                                          <div style={{ borderColor: "gray" }}>
                                            {nonZeroTicketCount > 1 && (
                                              <IoCloseCircleOutline
                                                onClick={() =>
                                                  !isCartDisabled &&
                                                  deleteOrUpdateSingleDenomination(
                                                    data,
                                                    item,
                                                    deleteData,
                                                    index
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      {data.seating_option !==
                                      seatingPlanNumbered ? (
                                        <div className="d-flex gap-3 align-items-center justify-content-end">
                                          <div
                                            className="quantity-control text-center rounded"
                                            style={{
                                              border: "1px solid #e0e0e0",
                                              display: "inline-flex",
                                            }}
                                          >
                                            {data &&
                                              data?.season_flag &&
                                              data?.season_flag !== "Y" && (
                                                <button
                                                  className="btn-decrement text-dark px-0 px-sm-1"
                                                  style={{
                                                    borderRadius:
                                                      "4px 0px 0px 4px",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  disabled={
                                                    settings.isSubmitting ||
                                                    isCartDisabled
                                                  }
                                                  onClick={() =>
                                                    !isCartDisabled &&
                                                    ticketLimitationEvent(
                                                      DECREMENT,
                                                      index,
                                                      itemIndex
                                                    )
                                                  }
                                                >
                                                  <HiOutlineMinusCircle
                                                    style={{ color: "#757575" }}
                                                  />
                                                </button>
                                              )}

                                            <input
                                              name="quantity"
                                              type="text"
                                              className="quantity__input text-center"
                                              style={{
                                                width: "40px",
                                                border: "none",
                                                textAlign: "center",
                                                backgroundColor: "#ffffff",
                                                outline: "none",
                                              }}
                                              value={item?.ticket_quantity}
                                              readOnly
                                            />

                                            {data &&
                                              data?.season_flag &&
                                              data?.season_flag !== "Y" && (
                                                <button
                                                  className="btn-increment text-dark px-0 px-sm-1"
                                                  style={{
                                                    borderRadius:
                                                      "0px 4px 4px 0px",
                                                    backgroundColor: "#ffffff",
                                                  }}
                                                  disabled={
                                                    settings.isSubmitting ||
                                                    isCartDisabled
                                                  }
                                                  onClick={() =>
                                                    !isCartDisabled &&
                                                    ticketLimitationEvent(
                                                      INCREMENT,
                                                      index,
                                                      itemIndex
                                                    )
                                                  }
                                                >
                                                  <IoMdAddCircleOutline
                                                    style={{ color: "#757575" }}
                                                  />
                                                </button>
                                              )}
                                          </div>

                                          <p
                                            className="p-0 m-0"
                                            style={{
                                              fontSize: "14px",
                                              fontWeight: "400",
                                              color: "gray",
                                            }}
                                          >
                                            ₹
                                            {item?.amount *
                                              item?.ticket_quantity}
                                          </p>
                                        </div>
                                      ) : (
                                        <p>{item?.amount?.toFixed(2) || 0.0}</p>
                                      )}
                                    </div>
                                  )
                                );
                              })}
                          </div>

                          {/* ADD ON CODE AND PROMOCODE */}

                          <div
                            className="w-100 d-flex flex-column gap-3"
                            style={{
                              backgroundColor: "#f6f6f6",
                              padding: "8px 12px",
                            }}
                          >
                            {data?.promo_code !== null && (
                              <div className="w-100 d-flex justify-content-between gap-2">
                                <div className="d-flex gap-2 align-items-center">
                                  <p
                                    className="p-0 m-0"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                      color: "#32A61F",
                                    }}
                                  >
                                    Applied Promocode : {data?.promo_code}
                                  </p>
                                  <div
                                    style={{
                                      borderColor: "gray",
                                      width: "14px",
                                    }}
                                    onClick={() =>
                                      !isCartDisabled && deletePromoCodeFn(data)
                                    }
                                  >
                                    <RiDeleteBinLine />
                                  </div>
                                </div>
                                <p
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  ₹{" "}
                                  {
                                    calculatePromoCodeCharges(
                                      data,
                                      dataSource.cartListDataSource
                                    ).promoCodeAmount
                                  }
                                </p>
                              </div>
                            )}

                            {data?.is_promo_code === isAddonPromoCodeEnable &&
                              data?.promo_code === null && (
                                <button
                                  className="border-button mb-2"
                                  style={{
                                    borderColor: "#B16666",
                                    color: "#B16666",
                                  }}
                                  onClick={() =>
                                    !isCartDisabled &&
                                    applyIndidualPromoCode(false, data)
                                  }
                                >
                                  Apply Promo Code
                                </button>
                              )}
                          </div>

                          {/* GST Percentage and GST amount */}

                          {calculateEventGST(
                            calculatePromoCodeCharges(
                              data,
                              dataSource.cartListDataSource
                            ).promoCodeAppliedTotal,
                            data
                          ).gstTax !== 0.0 &&
                            calculateEventGST(
                              calculatePromoCodeCharges(
                                data,
                                dataSource.cartListDataSource
                              ).promoCodeAppliedTotal,
                              data
                            ).gstTax !== "0.00" && (
                              <div className="w-100 d-flex justify-content-between gap-2 px-3">
                                <div className="d-flex gap-1">
                                  <p
                                    className="p-0 m-0"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    GST
                                  </p>
                                  <p
                                    className="p-0 m-0"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                      color: "gray",
                                    }}
                                  >
                                    ({calculateGSTPercentage(data?.slug)}%)
                                  </p>
                                </div>
                                <p
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  ₹
                                  {
                                    calculateEventGST(
                                      calculatePromoCodeCharges(
                                        data,
                                        dataSource.cartListDataSource
                                      ).promoCodeAppliedTotal,
                                      data
                                    ).gstTax
                                  }
                                </p>
                              </div>
                            )}

                          {data?.addon_details &&
                            data?.addon_details !== null && (
                              <>
                                <div className="w-100 d-flex flex-column gap-1 px-3">
                                  <div className="w-100 justify-content-between align-items-center gap-2 d-flex">
                                    <div className="d-flex gap-2 align-items-center">
                                      <p
                                        className="p-0 m-0"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          color: "#32A61F",
                                        }}
                                      >
                                        AddOn Applied
                                      </p>
                                      <div
                                        style={{
                                          borderColor: "gray",
                                          width: "14px",
                                        }}
                                      >
                                        {/* <RiDeleteBinLine /> */}
                                      </div>
                                    </div>
                                    <p
                                      className="p-0 m-0"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "gray",
                                      }}
                                    >
                                      ₹{" "}
                                      {calculateAddonAmount(
                                        data?.addon_details,
                                        data
                                      )}
                                    </p>
                                  </div>
                                  <hr></hr>

                                  {data &&
                                    data?.addon_details?.map((addOnData) => (
                                      <div className="d-flex justify-content-between">
                                        <div
                                          className="d-flex gap-1"
                                          style={{ borderColor: "gray" }}
                                          onClick={() =>
                                            !isCartDisabled &&
                                            addOnCodeDelete(data, addOnData)
                                          }
                                        >
                                          <IoCloseCircleOutline className="" />
                                          <span
                                            class=""
                                            style={{ fontSize: "14px" }}
                                          >
                                            {addOnData?.description} {""}Qty({" "}
                                            {addOnData?.addon_count})
                                          </span>
                                        </div>
                                        <p
                                          className="p-0 m-0"
                                          style={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            color: "gray",
                                          }}
                                        >
                                          ₹{" "}
                                          {addOnData?.amount *
                                            addOnData?.addon_count}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                                <hr></hr>
                              </>
                            )}
                          {data.is_addon_code === isAddonPromoCodeEnable && (
                            <button
                              className="border-button mx-3"
                              onClick={() =>
                                !isCartDisabled &&
                                applyIndidualAddonCode(false, data)
                              }
                            >
                              Select AddOns
                            </button>
                          )}

                          {/* Handling Charges */}

                          {calculateHandlingCharges(data).handlingAmount !==
                            0 &&
                            calculateHandlingCharges(data).handlingAmount !==
                              "0" && (
                              <div className="w-100 d-flex justify-content-between gap-2 px-3">
                                <p
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Handling Charges
                                </p>
                                <p
                                  className="p-0 m-0"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "gray",
                                  }}
                                >
                                  ₹{" "}
                                  {
                                    calculateHandlingCharges(data)
                                      .handlingAmount
                                  }
                                </p>
                              </div>
                            )}

                          {/* Sub total */}

                          <div
                            className="w-100 d-flex flex-column gap-3"
                            style={{
                              backgroundColor: "#f6f6f6",
                              padding: "8px 12px",
                            }}
                          >
                            <div className="w-100 d-flex justify-content-between gap-2">
                              <p
                                className="p-0 m-0"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                }}
                              >
                                Sub Total
                              </p>
                              <p
                                className="p-0 m-0"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "gray",
                                }}
                              >
                                <p>₹{itemSubTotal}</p>
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {settings?.isPromoCodePopEnabled && (
        <PromoCodeSummary
          isOpen={settings?.isPromoCodePopEnabled}
          applyIndidualPromoCode={applyIndidualPromoCode}
          promoCodeDataSource={dataSource.promoCodeDataSource}
          applyPromoCode={applyPromoCode}
          checkPromoCodeAvailability={checkPromoCodeAvailability}
          availablePromoCodeError={dataSource.availablePromoCodeError}
        />
      )}

      {settings?.isAddonPopupEnabled && (
        <AddOnCodeSummary
          isOpen={settings?.isAddonPopupEnabled}
          applyIndidualAddonCode={applyIndidualAddonCode}
          addonCodeDataSource={dataSource.addonCodeDataSource}
          applyPromoCode={applyPromoCode}
          setDataSource={setDataSource}
          applyAddOnCode={applyAddOnCode}
        />
      )}

      {selectedItem === RAZORPAY && <RazorpayPayment ref={razorpayRef} />}
    </>
  );
}

export default ProductSummary;
