import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import {
  sizeValues,
  fontValues,
  headerValues,
  fontSizeOptions,
  fontFamilyOptions,
  headerOptions,
} from "../../../components/others";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import { markup, tabs } from "../../../components/others";
import Button from "devextreme-react/button";
import { DateBox, TextBox, SelectBox, TagBox } from "devextreme-react";
import {
  EVENT_MAIN_CATEGORY,
  COUNTRY,
  TIMEZONE,
  ALL_ARTIST_DETAIL,
} from "../../../Api/api";
import {
  TicketingOptionMockData,
  EventMode,
  freewithregistration,
  ticketOptionFree,
  ticketedonmdnd,
  ticketedonotherwebsites,
  SingleEvent,
  JointEvent,
  MDnDLocation,
  OpenExternalURL,
  eventButtonAction,
  EventModeOnline,
  EventModeOffline,
  bookingWillOpenSoon,
  seatingPlan,
  PromoCode,
  AddOn,
  seatingPlanNonNumbered,
  seatingPlanNumbered,
  seasonStartDate,
  mdndCountryRestriction,
  eventFreeText,
  eventFreeWithReg,
  eventTicketed,
  eventOtherWeb,
  ALLEVENT_DETAILS_IMAGES,
} from "../../../global/constants";
import { makeGetRequest, makePostRequest } from "../../../Api/requestMethods";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import "react-loading-skeleton/dist/skeleton.css";
import {
  subCategoryFetch,
  stateFetch,
  cityFetch,
  profileDetails,
  organiserDetailsAllFetch,
  createDrafts,
  getVenueLayout,
  getPromoCode,
  getAddOnCode,
} from "./EventsFetch.js";
import FileUploader from "devextreme-react/file-uploader";
import { CheckBox } from "devextreme-react/check-box";
import "./Events.css";
import TicketDetailsGrid from "./ticketDetailsGrid.js";
import { useFormik } from "formik";
import { FORMATED_DATE_AND_TIME } from "../../../utils/dateFormatUtils.js";
import { openEditor } from "react-profile";
import "react-profile/themes/default";
import { EVENTS_SCHEMA } from "../../../Schemas/Events.js";
import QuickAddGrid from "./QuickAddGrid.js";
import {
  CREATE_EVENTS,
  editEventDetails,
  UPDATE_EVENTS,
} from "../../../Api/api";
import { CREATE_EVENTS_SCHEMA } from "../../../Schemas/Forms/LoginDashboard.js";
import Switch from "devextreme-react/switch";
import AuthContext from "../../../context/AuthContext.js";
import { Tag, TagItem } from "../../../components/TagBox.js";
import SearchLocationInput from "../../../components/Maps/googlePlaces.js";
import MapComponent from "../../../components/Maps/Map.js";
import {
  MapLocationEmbeded,
  JsonParser,
  isValidValue,
} from "../../../utils/commonUtils.js";
import { getNumberEventFetch } from "./EventsFetch.js";
import CreateSeatingLayouts from "../createSeatingLayouts/createSeatingLayouts.js";
import AddPromoCodeGrid from "./addPromoCodeGrid.js";
import AddOnCodeGrid from "./addAddOnCodeGrid.js";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../global/provider.js";
import NumberedDenominationNotification from "../../../components/NumberedDenominationError.js";
import nonNumberedErrorImage from "../../../assets/images/numberedError.png";
import PreviewEvent from "../../../components/previewEvent.js";
import previewEventImage from "../../../assets/images/previewEvent.png";
import JointEventGrid from "./jointEventGrid.jsx";

const CreateEvents = (props) => {
  const { user } = useContext(AuthContext);
  const { eventsDatasource, setEventDataSource } = useContext(GlobalContext);
  const eventOrganiserRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });

  const [dataSource, setDataSource] = useState({
    mainCategoryDataSource: "",
    subCategoryDataSource: "",
    artistDetails: "",
    stateDataSource: "",
    cityDataSource: "",
    eventMode: "",
    accompanistDetails: "",
    organiserDetails: "",
    quickAddDataSource: [],
    seating_nonNumbered: [],
    tempOrganiserDetail: "",
    venueLayoutsDataSource: "",
    seatingLayoutDataSource: "",
    overAllSeatingLayoutDs: "",
    promoCodeDataSource: "",
    addOnCodeDataSource: "",
    countryDataSource: "",
    numberedErrors: "",
    ticketingDetails: "",
    editEventDetailsDs: "",
    buttonAction: "",
    joint_event_datasource: [],
    lookupDetails: "",
  });

  const [settings, setSettings] = useState({
    hasFocusedIn: false,
    timeZoneFocusIn: false,
    countryFocusIn: false,
    stateFocusIn: false,
    cityFocusIn: false,
    multipleCheckBox: false,
    timeZoneFocus: false,
    singleOrJoint: SingleEvent,
    buttonActionEnable: false,
    quickAddEnable: false,
    imageName: "",
    isTicketLimitationEnabled: false,
    isSubCategoryValid: false,
    IsartistFocusIn: false,
    venueLayoutsDetails: false,
    isNumberedError: false,
    previewEvent: false,
    quickAddTrigger: 0,
    previewEventDetails: "",
    isStreamURLEnabled: false,
    buttonActionCounter: 0,
    quickAddTriggerEdit: 0,
  });

  const switchEvent = (e) => {
    setSettings((prev) => ({
      ...prev,
      quickAddEnable: !prev.quickAddEnable,
    }));

    setDataSource((prev) => ({ ...prev, quickAddDataSource: [] }));
  };

  const numberedErrorClose = () =>
    setSettings((prev) => ({ ...prev, isNumberedError: false }));

  const previewEventClose = () =>
    setSettings((prev) => ({ ...prev, previewEvent: false }));

  const now = new Date();
  const [dateAndTime, setDateAndTime] = useState(new Date());
  const [isMultiline, setIsMultiline] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[2].value);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    resetForm,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues: CREATE_EVENTS_SCHEMA(MDnDLocation, dateAndTime, settings),
    validationSchema: EVENTS_SCHEMA,
    onSubmit: async (values, { setSubmitting }) => {
      if (
        (values.ticketing_option === ticketedonmdnd &&
          values.seating_option === seatingPlanNonNumbered &&
          dataSource.seating_nonNumbered &&
          dataSource.seating_nonNumbered.length === 0) ||
        (values.ticketing_option === ticketedonmdnd &&
          values.event_mode === EventModeOnline &&
          dataSource.seating_nonNumbered &&
          dataSource.seating_nonNumbered.length === 0) ||
        (values.ticketing_option === freewithregistration &&
          dataSource.seating_nonNumbered &&
          dataSource.seating_nonNumbered.length === 0)
      ) {
        return notify("Ticket configuration is required", ERROR, DURATION);
      }
      if (
        values.sub_catid === "" ||
        (dataSource?.subCategoryDataSource &&
          dataSource?.subCategoryDataSource.length === 0)
      ) {
        values.sub_catid = 0;
      }
      await createEventssubmit(dataSource.quickAddDataSource);
      setSubmitting(false);
    },
  });

  function safeJSONParse(value) {
    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  }

  const transformEditableData = React.useCallback((editableData) => {
    const new_event_date = new Date(editableData.event_date);
    const current_date = new Date();
    const event_date_utc = new_event_date.getTime();
    const current_date_utc = current_date.getTime();

    let transformedData = { ...editableData };
    if (event_date_utc > current_date_utc) {
      const combinedDateTime = new Date(
        editableData.event_date.split("T")[0] + " " + editableData.display_time
      );
      transformedData.dateAndTime = combinedDateTime;
    } else if (event_date_utc < current_date_utc) {
      transformedData.dateAndTime = current_date_utc;
    }
    ["organiser_id", "artist_ids", "accompanist_ids"].forEach((field) => {
      if (
        transformedData[field]?.length > 0 &&
        transformedData[field][0] === 0
      ) {
        transformedData[field] = [];
      }
    });
    transformedData.is_promo_code = editableData.is_promo_code === "Y";
    transformedData.is_addon_code = editableData.is_addon_code === "Y";

    return {
      ...transformedData,
      isImageEditor: false,
      is_season_event: "N",
    };
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        if (!props.isEditEvent && props?.data?.data?.draft_data) {
          setValues(props.data.data.draft_data);
          return;
        }

        if (props.isEditEvent) {
          const body = {
            country_id: props.country_id,
            event_id: props.data.data.event_id,
            is_season_event: "N",
          };

          const response = await makePostRequest(editEventDetails, body);

          if (response?.status === SUCCESS) {
            const editableData = response.data[0];
            const transformedData = transformEditableData(editableData);
            setDataSource((prev) => ({
              ...prev,
              editEventDetailsDs: transformedData,
            }));

            if (props.data?.data) {
              const quickAddData = {
                quickadd_accompanists: safeJSONParse(
                  transformedData.quickadd_accompanists
                ),
                quickadd_artists: safeJSONParse(
                  transformedData.quickadd_artists
                ),
                quickadd_organisers: safeJSONParse(
                  transformedData.quickadd_organisers
                ),
              };

              props.data.data = {
                ...props.data.data,
                draft_data: quickAddData,
              };
            }
          }
        }
      } catch (error) {
        console.error("Error in loadInitialData:", error);
      }
    };

    loadInitialData();
  }, []);

  useEffect(() => {
    if (dataSource.editEventDetailsDs) {
      setValues(dataSource.editEventDetailsDs);
    }
  }, [dataSource.editEventDetailsDs]);

  useEffect(() => {
    try {
      if (
        props &&
        !props.isEditEvent &&
        isValidValue(values.ticketing_option) &&
        isValidValue(values.event_mode) &&
        dataSource.ticketingDetails
      ) {
        setDataSource((prev) => ({
          ...prev,
          seating_nonNumbered: dataSource.ticketingDetails,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [props, dataSource.ticketingDetails]);

  useEffect(() => {
    setFieldValue(
      "isSubCategoryValid",
      dataSource?.subCategoryDataSource?.length > 0
    );
  }, [values, dataSource]);

  const imageOnchange = async (e) => {
    setSettings((prev) => ({ ...prev, imageName: e?.value[0]?.name }));
    try {
      const imageFile = e?.value[0];
      if (!imageFile) {
        console.error("No image file provided");
      }
      const initialCrop = {
        x: 0,
        y: 0,
        width: 400,
        height: 400,
        unit: "px",
      };

      const editedImageBlob = await openEditor({
        src: URL.createObjectURL(imageFile),
        cropOptions: {
          aspectRatio: 1,
          maxWidth: 400,
          maxHeight: 400,
          minWidth: 400,
          minHeight: 400,
          center: true,
        },
        initCrop: initialCrop,
      });

      if (
        editedImageBlob &&
        typeof editedImageBlob.editedImage.getBlob === "function"
      ) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          setFieldValue("event_image", base64String);
          setFieldValue("isImageEditor", true);
        };
        reader.readAsDataURL(await editedImageBlob.editedImage.getBlob());
      } else {
        console.error(
          "Failed to obtain the edited image Blob or getBlob method is not available."
        );
      }
    } catch (error) {
      console.error("Error while processing image:", error);
    }
  };

  useEffect(() => {
    // Free
    if (values.ticketing_option === ticketOptionFree) {
      if (settings.buttonActionCounter !== 0) {
        setSettings((prev) => ({
          ...prev,
          buttonActionEnable: true,
        }));
      }

      setFieldValue("isButtonTextEnabled", true);

      if (values.event_mode === EventModeOffline) {
        setFieldValue(
          "isButtonUrlEnabled",
          values.button_action === "open_URL" &&
            values.event_mode === EventModeOffline
            ? true
            : false
        );
      }

      setFieldValue("isTicketLimitationEnabled", false);
      setFieldValue("isINREnabled", false);
      setFieldValue("isUSDEnabled", false);

      setDataSource((prev) => ({
        ...prev,
        buttonAction:
          values.event_mode === EventModeOffline
            ? eventButtonAction
            : [eventButtonAction[1]],
      }));

      setSettings((prev) => ({
        ...prev,
        isStreamURLEnabled: values.event_mode === EventModeOnline,
      }));
    }

    // ticked on other websites
    if (
      values.ticketing_option === ticketedonotherwebsites &&
      values.event_mode === EventModeOffline
    ) {
      setFieldValue("isButtonActionValid", true);
      setFieldValue("isButtonTextEnabled", true);
      setFieldValue(
        "isButtonUrlEnabled",
        values.button_action === "open_URL" ? true : false
      );
      setFieldValue("isTicketLimitationEnabled", false);
      setFieldValue("isINREnabled", false);
      setFieldValue("isUSDEnabled", false);

      setSettings((prev) => ({
        ...prev,
        buttonActionEnable: true,
        isTicketLimitationEnabled: false,
      }));

      setFieldValue("isStreamLinkEnabled", false);

      setDataSource((prev) => ({
        ...prev,
        buttonAction: eventButtonAction,
      }));
    }

    // Free with registration
    if (values.ticketing_option === freewithregistration) {
      setSettings((prev) => ({ ...prev, buttonActionEnable: false }));
      setFieldValue(
        "isRowAndTicketQty",
        values.event_mode === EventModeOffline
      );
      setFieldValue("isButtonActionValid", false);
      setFieldValue(
        "isTicketLimitationEnabled",
        values.event_mode === EventModeOffline
      );

      setFieldValue("isButtonTextEnabled", true);
      setFieldValue("isSeatingEnabled", false);

      if (values.event_mode) {
        setSettings((prev) => ({
          ...prev,
          isTicketLimitationEnabled: values.event_mode !== EventModeOnline,
        }));
      }
      setDataSource((prev) => ({
        ...prev,
        buttonAction: eventButtonAction,
      }));
      setFieldValue("isStreamLinkEnabled", false);
    }

    // Ticked on MDnD
    if (values.ticketing_option === ticketedonmdnd) {
      setSettings((prev) => ({ ...prev, buttonActionEnable: false }));

      if (values.event_mode === EventModeOnline) {
        setFieldValue("isINREnabled", values.country_id === 101 ? true : false);
        setFieldValue("isUSDEnabled", values.country_id === 101 ? false : true);
        setFieldValue("isButtonUrlEnabled", false);
        setFieldValue("button_url", "");
        setFieldValue("isStreamLinkEnabled", true);
      } else {
        setFieldValue("isINREnabled", false);
        setFieldValue("isUSDEnabled", false);
        setFieldValue("isStreamLinkEnabled", false);
      }
      setFieldValue("isButtonTextEnabled", true);
      setFieldValue("isButtonActionValid", false);
      setFieldValue("isTicketLimitationEnabled", true);
      setFieldValue("isSeatingEnabled", values.event_mode === EventModeOffline);
      setSettings((prev) => ({ ...prev, isTicketLimitationEnabled: true }));

      setDataSource((prev) => ({
        ...prev,
        buttonAction: eventButtonAction,
      }));
    }

    if (
      values.ticketing_option === ticketOptionFree &&
      values.event_mode === EventModeOffline
    ) {
      setSettings((prev) => ({ ...prev, isTicketLimitationEnabled: false }));
    }

    if (
      values.ticketing_option === ticketOptionFree &&
      values.event_mode === EventModeOnline
    ) {
      setSettings((prev) => ({ ...prev, isTicketLimitationEnabled: false }));
    }
    if (values.event_mode === "") {
      setSettings((prev) => ({ ...prev, buttonActionEnable: false }));
    }
    setSettings((prev) => ({
      ...prev,
      buttonActionCounter: prev.buttonActionCounter + 1,
    }));
  }, [
    values.ticketing_option,
    values.event_mode,
    values.button_action,
    values.country_id,
    // values,
  ]);

  useEffect(() => {
    if (
      values.ticketing_option === ticketOptionFree &&
      values.event_mode === EventModeOnline
    ) {
      if (isValidValue(values.button_url)) {
        setSettings((prev) => ({ ...prev, isStreamURLEnabled: false }));
        setFieldValue("stream_link", "");
      }
      if (values.button_action === OpenExternalURL) {
        setFieldValue("isButtonUrlEnabled", true);
      }
    }
  }, [values.button_url, values.button_action]);

  useEffect(() => {
    if (
      values.ticketing_option === ticketOptionFree &&
      values.event_mode === EventModeOnline
    ) {
      if (values.stream_link !== "") {
        setSettings((prev) => ({
          ...prev,
          buttonActionEnable: false,
          buttonActionCounter: 0,
        }));
        setFieldValue("button_url", "");
        setFieldValue("button_action", "");
        setFieldValue("isButtonActionValid", false);
        setFieldValue("isButtonUrlEnabled", false);
      }
      if (values.stream_link === "") {
        setSettings((prev) => ({
          ...prev,
          buttonActionEnable: true,
          buttonActionCounter: prev.buttonActionCounter + 1,
        }));
      }
    }
  }, [values.stream_link]);

  useEffect(() => {
    if (
      values.ticketing_option === ticketOptionFree &&
      values.event_mode === EventModeOnline
    ) {
      setFieldValue("isButtonActionValid", false);
    }
    if (
      values.ticketing_option === ticketOptionFree &&
      values.event_mode !== EventModeOnline
    ) {
      setFieldValue("isButtonActionValid", true);
    }
  }, [values.event_mode, values.ticketing_option]);

  const getAmountsNullWhereAisle = async (data) => {
    const issues = [];
    Object.keys(data).forEach((key) => {
      Object.values(data[key]).forEach((array) => {
        array.forEach((seat) => {
          if (!seat.isAisle && seat.amount === null) {
            issues.push({ key, ...seat });
          }
        });
      });
    });
    return issues;
  };

  const resetQuickAddDetails = async () => {
    setFieldValue("quickadd_artists", "");
    setFieldValue("quickadd_accompanists", "");
    setFieldValue("quickadd_organisers", "");
  };

  const appendQuickAddDetails = async (dataSource) => {
    try {
      const quickAdd = [];
      const quickAddAccom = [];
      const quickAddOrg = [];
      if (dataSource && dataSource.length > 0) {
        dataSource.forEach((data, index) => {
          quickAdd.push(data.quickadd_artists);
          quickAddAccom.push(data.quickadd_accompanists);
          quickAddOrg.push(data.quickadd_organisers);
        });
        values.quickadd_artists = quickAdd;
        values.quickadd_accompanists = quickAddAccom;
        values.quickadd_organisers = quickAddOrg;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const valuesManipulation = async () => {
    try {
      values.role_id = user?.role_id || null;
      values.ticket_details =
        values.ticketing_option === ticketedonmdnd &&
        values.seating_option === seatingPlanNumbered
          ? dataSource?.overAllSeatingLayoutDs
          : dataSource.seating_nonNumbered;

      values.seating_option =
        values.ticketing_option === freewithregistration
          ? ""
          : values.seating_option;
    } catch (error) {
      console.log(error);
    }
  };

  const resetPostSubmit = async () => {
    resetForm();
    setDataSource((prev) => ({ ...prev, seating_nonNumbered: [] }));
    setSettings((prev) => ({ ...prev, imageName: "" }));
    handleDateAndTimeChange(now);
    if (eventOrganiserRef?.current) {
      eventOrganiserRef.current.instance.reset();
    }
    handleChange({
      target: { name: "organiser_id", value: [] },
    });
    handleChange({
      target: { name: "accompanist_ids", value: [] },
    });
  };

  const createEventssubmit = async (formData) => {
    try {
      await resetQuickAddDetails();
      await appendQuickAddDetails(formData);
      await valuesManipulation();
      if (
        values.ticketing_option === ticketedonmdnd &&
        values.seating_option === seatingPlanNumbered
      ) {
        const issues = await getAmountsNullWhereAisle(values.ticket_details);
        if (issues.length > 0) {
          setDataSource((prev) => ({ ...prev, numberedErrors: issues }));
          setSettings((prev) => ({ ...prev, isNumberedError: true }));
          return false;
        }
      }
      const apiToUse =
        props && props.isEditEvent ? UPDATE_EVENTS : CREATE_EVENTS();
      const response = await makePostRequest(apiToUse, values);
      if (response && response.status === SUCCESS) {
        notify(response?.message, SUCCESS, DURATION);
        await resetPostSubmit();
        setSettings((prev) => ({
          ...prev,
          previewEvent: Object.keys(props).length === 0 ? true : false,
          previewEventDetails: response && response.data && response.data[0],
          buttonActionEnable: true,
        }));
      }
    } catch (error) {
      notify(error);
      notify(error.response.data.message, ERROR, DURATION);
    }
  };

  const handleDateAndTimeChange = (e) => {
    const newDate = e.value instanceof Date ? e.value : new Date();
    const date = FORMATED_DATE_AND_TIME(newDate);
    setDateAndTime(newDate);
    setFieldValue("dateAndTime", newDate);
    setFieldValue("event_date", date.formattedDate);
    setFieldValue("event_time", date.formattedTime);
  };

  useEffect(() => {
    if (selectedLocation.lat && selectedLocation.lng) {
      const MapDetails = MapLocationEmbeded(
        selectedLocation.lat,
        selectedLocation.lng
      );
      setFieldValue("location", MapDetails);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (
      values.ticketing_option &&
      values.ticketing_option !== "" &&
      values.ticketing_option !== null
    ) {
      if (!props) {
        setFieldValue("event_mode", "");
      }
      if (values.ticketing_option === ticketOptionFree) {
        setDataSource((prev) => ({ ...prev, eventMode: EventMode }));
      }
      if (values.ticketing_option === freewithregistration) {
        setDataSource((prev) => ({ ...prev, eventMode: [EventMode[0]] }));
      }
      if (values.ticketing_option === ticketedonmdnd) {
        setDataSource((prev) => ({ ...prev, eventMode: EventMode }));
      }
      if (values.ticketing_option === ticketedonotherwebsites) {
        setDataSource((prev) => ({ ...prev, eventMode: [EventMode[0]] }));
      }
    }
  }, [values.ticketing_option]);

  const mainCategoryEvent = async () =>
    await makeGetRequest(EVENT_MAIN_CATEGORY);
  const { data: mainCategoryDataSource } = useQuery({
    queryKey: ["mainCategory"],
    queryFn: () => mainCategoryEvent(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // To Fetch Countries
  const countryEvent = async () => await makeGetRequest(COUNTRY());
  const { data: countryDataSource } = useQuery({
    queryKey: ["country"],
    queryFn: () => countryEvent(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (
      props &&
      props.isEditEvent !== undefined &&
      !props.isEditEvent &&
      countryDataSource &&
      countryDataSource.data.length > 0
    ) {
      const draftData =
        props &&
        props.data &&
        props?.data?.data &&
        props?.data.data?.draft_data;
      setFieldValue("country_id", draftData?.country_id);
    }
  }, [dataSource.countryDataSource, props]);

  useEffect(() => {
    if (
      dataSource.editEventDetailsDs &&
      countryDataSource &&
      countryDataSource.data.length > 0
    ) {
      setFieldValue("country_id", dataSource?.editEventDetailsDs?.country_id);
    }
  }, [dataSource.countryDataSource, dataSource.editEventDetailsDs]);

  useEffect(() => {
    //  setFieldValue("country_id", "");
    if (
      countryDataSource &&
      countryDataSource.data &&
      countryDataSource.data?.length > 0
    ) {
      if (values.ticketing_option === ticketedonmdnd) {
        const countryIds = [101, 231];
        const countryForTicketed =
          countryDataSource &&
          countryDataSource.data.filter((data) =>
            countryIds.includes(data.country_id)
          );

        setDataSource((prev) => ({
          ...prev,
          countryDataSource: countryForTicketed,
        }));
      } else {
        setDataSource((prev) => ({
          ...prev,
          countryDataSource: countryDataSource.data,
        }));
      }
    }
  }, [countryDataSource, values.ticketing_option]);

  // TO fetch timezone
  const TimeZoneFetch = async () => await makeGetRequest(TIMEZONE());
  const { data: TimeZoneDataSource } = useQuery({
    queryKey: ["timeZone"],
    queryFn: () => TimeZoneFetch(),
    // enabled: settings.timeZoneFocus,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // Subcategory mutation
  const mutation = useMutation({
    mutationKey: ["fetchSubquery"],
    mutationFn: (mainCatId) =>
      values.main_catid !== "" &&
      values.main_catid !== null &&
      subCategoryFetch(mainCatId),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, subCategoryDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // State mutation - to fetch state details
  const stateMutation = useMutation({
    mutationKey: ["fetchState"],
    mutationFn: (state_id) => stateFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, stateDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // TO get profile details
  const profileMutation = useMutation({
    mutationFn: (state_id) => profileDetails(state_id),
    onSuccess: (data) => {
      const newData = data.data;
      const tempBody = {
        organiser_name: newData.name,
        photo: newData.profilephoto,
        organiserid: newData.userid,
      };
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    handleDateAndTimeChange(now);
    const body = {
      role_id: user?.role_id,
    };
    profileMutation.mutate(body);
  }, []);

  // To get city details
  const cityMutation = useMutation({
    mutationKey: ["fetchcity"],
    mutationFn: (state_id) => cityFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, cityDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // Main category trigger
  useEffect(() => {
    if (
      values.main_catid &&
      values.main_catid !== "" &&
      values.main_catid !== null
    ) {
      const body = {
        maincatid: values.main_catid,
      };
      mutation.mutate(body);
    }
  }, [values.main_catid]);

  // Country Trigger
  useEffect(() => {
    if (
      values.country_id &&
      values.country_id !== "" &&
      values.country_id !== null
    ) {
      const body = {
        country_id: values.country_id,
      };
      stateMutation.mutate(body);
    }
  }, [values.country_id]);

  useEffect(() => {
    if (values.state_id && values.state_id !== "" && values.state_id !== null) {
      const body = {
        state_id: values.state_id,
      };
      cityMutation.mutate(body);
    }
  }, [values.state_id]);

  // Promo code mutation
  const promoCodeMutation = useMutation({
    mutationFn: (promoCodeData) => getPromoCode(promoCodeData),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, promoCodeDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // addOn code mutation
  const addOnCodeMutation = useMutation({
    mutationFn: (addOncodeData) => getAddOnCode(addOncodeData),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, addOnCodeDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    const body = {
      role_id: user.role_id,
    };
    if (user.role_id && values.is_promo_code) {
      promoCodeMutation.mutate(body);
    }
    if (user.role_id && values.is_addon_code) {
      addOnCodeMutation.mutate(body);
    }
  }, [user.role_id, values.is_promo_code, values.is_addon_code]);

  const numberedEventMutation = useMutation({
    mutationFn: (body) => getNumberEventFetch(body),
    onSuccess: (data) => {
      data &&
        setDataSource((prev) => ({
          ...prev,
          venueLayoutsDataSource: data.data,
          seatingLayoutDataSource: [],
        }));
    },
    onError: (error) => {
      setDataSource((prev) => ({
        ...prev,
        venueLayoutsDataSource: [],
        seatingLayoutDataSource: [],
      }));
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  // TO fetch Artist Details
  const artistDetailsFetch = async () =>
    await makeGetRequest(ALL_ARTIST_DETAIL());
  const { data: artistDataSource } = useQuery({
    queryKey: ["artistDetails"],
    queryFn: () => artistDetailsFetch(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const Organisermutation = useMutation({
    mutationFn: (organiserDetails) =>
      organiserDetailsAllFetch(organiserDetails),
    onSuccess: (data) => {
      data &&
        setDataSource((prev) => ({ ...prev, organiserDetails: data.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const handleLayoutFocusIn = () => {
    if (user?.role_id && user?.role_id !== "" && user?.role_id !== null) {
      const body = {
        role_id: user.role_id,
      };
      numberedEventMutation.mutate(body);
    } else {
      console.error("Role_id not found!");
    }
  };

  useEffect(() => {
    if (values.seating_option === seatingPlanNumbered) {
      handleLayoutFocusIn();
    }
  }, [values.seating_option]);

  const handleFocusIn = (e) => {
    setSettings((prev) => ({ ...prev, hasFocusedIn: true }));
    handleBlur({ target: { name: "event_title", value: e.value } });
  };

  const handleTimeZoneFocusIn = () => {
    setSettings((prev) => ({ ...prev, timeZoneFocus: true }));
  };

  const countryFocusIn = () => {
    setSettings((prev) => ({ ...prev, countryFocusIn: true }));
  };

  useEffect(() => {
    if (
      values &&
      isValidValue(values.venue_id) &&
      user.role_id !== null &&
      props !== undefined
    ) {
      const body = {
        role_id: user.role_id,
        venue_id: values.venue_id,
      };
      const fetchLayoutDetails = async () => {
        try {
          const response = await getVenueLayout(body);
          if (response && response.status === SUCCESS) {
            const parsedDrafts = await JsonParser(
              response?.data[0]?.seating_layout
            );
            setDataSource((prev) => ({
              ...prev,
              seatingLayoutDataSource: parsedDrafts,
              lookupDetails:
                (response.data && response.data[0].seating_order_lookup) || [],
            }));
          }
        } catch (e) {
          console.error(e);
        }
      };
      fetchLayoutDetails();
    }
  }, [values.venue_id]);

  useEffect(() => {
    const body = { user_role: "organiser" };
    Organisermutation.mutate(body);
  }, []);

  const handleSaveDraft = async () => {
    await resetQuickAddDetails();
    await appendQuickAddDetails(dataSource.quickAddDataSource);
    await valuesManipulation();
    if (user && user?.role_id) {
      const body = {
        role_id: user?.role_id,
        draft_data: values,
        draft_from: "event",
        draft_id: props?.data?.data?.draft_id || "",
      };
      const response = await createDrafts(body);
      if (response && response.status === SUCCESS) {
        notify(response.message, SUCCESS, DURATION);
        if (props && props.isEditEvent === false) {
          setEventDataSource((prev) => ({
            ...prev,
            saveDraftRefetchTrigger: prev.saveDraftRefetchTrigger + 1,
          }));
          await resetPostSubmit();
        }
      } else {
        notify(response.data.message, ERROR, DURATION);
      }
    } else {
      console.error("Role id is missing while creating Drafts");
    }
  };

  const seatingPlanOnChange = (value) => {
    handleChange({
      target: {
        name: "seating_option",
        value: value,
      },
    });
    setDataSource((prev) => ({ ...prev, seatingLayoutDataSource: "" }));
    setFieldValue("venue_id", "");
  };

  useEffect(() => {
    if (
      (dataSource.quickAddDataSource && settings.quickAddEnable) ||
      values.event_type
    ) {
      if (values.event_type !== "single") {
        setFieldValue("isArtistEnabled", true);
      } else {
        setFieldValue(
          "isArtistEnabled",
          dataSource.quickAddDataSource.length > 0
        );
      }
    }
  }, [
    dataSource.quickAddDataSource,
    settings.quickAddEnable,
    settings.quickAddTrigger,
    values.event_type,
  ]);

  const SingleOrJointEventHandler = (e) => {
    try {
      setFieldValue("event_type", e.target.value);
    } catch (error) {
      console.error(error);
    }
  };
  const isViewEvent = props?.isSaveAsDraft ?? true;
  return (
    <div className="tab-content tabBodyBg">
      <div
        id="createevent"
        className="tab-pane animated fadeInUp active show"
        role="tabpanel"
      >
        <div className="col-lg-12 pb-3">
          <div className="d-flex gap-3">
            <div className="">
              <div
                className={`createEventsBtn ${
                  values?.event_type === SingleEvent ? "createEventActive" : ""
                }`}
              >
                <div className="d-flex align-items-center px-3">
                  <input
                    className="form-check-input mt-0 me-3"
                    type="radio"
                    onChange={SingleOrJointEventHandler}
                    value={SingleEvent}
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={values.event_type === SingleEvent}
                  />
                  <p className="mt-3">Single Event</p>
                </div>
              </div>
            </div>
            <div className="">
              <div
                className={`createEventsBtn ${
                  settings?.singleOrJoint === JointEvent
                    ? "createEventActive"
                    : ""
                }`}
              >
                <div className="d-flex align-items-center px-3">
                  <input
                    className="form-check-input mt-0 me-3"
                    type="radio"
                    onChange={SingleOrJointEventHandler}
                    value={JointEvent}
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={values.event_type === JointEvent}
                  />
                  <p className="mt-3">Joint Event</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="description-content">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-2">
                  <TextBox
                    type="text"
                    labelMode="floating"
                    className="dx-field-item-required"
                    required="required"
                    mode="floating"
                    label="Title"
                    value={values.event_title}
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "event_title", value: e.value },
                      })
                    }
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "event_title", value: e.value },
                      })
                    }
                  />
                  {errors.event_title && touched.event_title && (
                    <p className="error-message">{errors.event_title}</p>
                  )}
                </div>
              </div>

              <>
                <div className="col-lg-6">
                  <div className="form-group mb-2">
                    <SelectBox
                      className="dx-field-item-required"
                      label=" Main Category"
                      labelMode="floating"
                      onFocusIn={handleFocusIn}
                      dataSource={mainCategoryDataSource?.data || null}
                      searchEnabled={true}
                      displayExpr="main_cat_name"
                      valueExpr="main_cat_id"
                      value={values.main_catid}
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "main_catid", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "main_catid", value: e.value },
                        })
                      }
                    />
                    {errors.main_catid && touched.main_catid && (
                      <p className="error-message">{errors.main_catid}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group mb-2">
                    <SelectBox
                      label=" Sub Category"
                      labelMode="floating"
                      className={
                        dataSource?.subCategoryDataSource?.length !== 0
                          ? "dx-field-item-required"
                          : ""
                      }
                      dataSource={dataSource?.subCategoryDataSource || null}
                      searchEnabled={true}
                      displayExpr="sub_cat_name"
                      valueExpr="sub_cat_id"
                      value={values.sub_catid}
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "sub_catid", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "sub_catid", value: e.value },
                        })
                      }
                      disabled={dataSource?.subCategoryDataSource?.length === 0}
                    />
                    {errors.sub_catid && touched.sub_catid && (
                      <p className="error-message">{errors.sub_catid}</p>
                    )}
                  </div>
                </div>
              </>

              <div className="col-lg-6">
                <DateBox
                  //    defaultValue={now}
                  acceptCustomValue={false}
                  labelMode="floating"
                  className="dx-field-item-required"
                  type="datetime"
                  label="Event Date and Time"
                  min={seasonStartDate}
                  value={values.dateAndTime}
                  displayFormat="dd/MM/yyyy hh:mm a"
                  onValueChanged={handleDateAndTimeChange}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "dateAndTime", value: e.value },
                    })
                  }
                />
              </div>

              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <SelectBox
                    label="Event Time Zone"
                    labelMode="floating"
                    className="dx-field-item-required"
                    dataSource={TimeZoneDataSource?.data || null}
                    displayExpr="timezone"
                    valueExpr="id"
                    value={values.timezone}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "timezone", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "timezone", value: e.value },
                      })
                    }
                    searchEnabled={true}
                    onFocusIn={handleTimeZoneFocusIn}
                  />
                  {errors.timezone && touched.timezone && (
                    <p className="error-message">{errors.timezone}</p>
                  )}
                </div>
              </div>

              {/* Event Artists */}

              {values.event_type !== JointEvent && (
                <>
                  <div className="col-lg-4">
                    <div className="form-group mb-2">
                      <TagBox
                        labelMode="floating"
                        label="Artists"
                        className={
                          dataSource?.quickAddDataSource &&
                          dataSource?.quickAddDataSource?.length > 0
                            ? ""
                            : "dx-field-item-required"
                        }
                        dataSource={artistDataSource?.data || []}
                        displayExpr="artist_name"
                        valueExpr="artistid"
                        searchEnabled={true}
                        value={values.artist_ids}
                        virtualModeEnabled={true}
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "artist_ids", value: e.value },
                          })
                        }
                        itemRender={(item) => (
                          <TagItem name={item.artist_name} image={item.photo} />
                        )}
                        tagRender={(data) => (
                          <Tag name={data.artist_name} image={data.photo} />
                        )}
                      />
                      {errors.artist_ids && touched.artist_ids && (
                        <p className="error-message">{errors.artist_ids}</p>
                      )}
                    </div>
                  </div>

                  {/* Event Accompanist */}
                  <div className="col-lg-4">
                    <div className="form-group mb-2">
                      <TagBox
                        type="text"
                        dataSource={artistDataSource?.data || null}
                        labelMode="floating"
                        label="Accompanists"
                        displayExpr="artist_name"
                        valueExpr="artistid"
                        value={values.accompanist_ids}
                        virtualModeEnabled={true}
                        searchEnabled={true}
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "accompanist_ids", value: e.value },
                          })
                        }
                        itemRender={(item) => (
                          <TagItem name={item.artist_name} image={item.photo} />
                        )}
                        tagRender={(data) => (
                          <Tag name={data.artist_name} image={data.photo} />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}

              {/*Event Organizer */}
              <div
                className={
                  values.event_type !== JointEvent ? "col-lg-4" : "col-lg-6"
                }
              >
                <div className="form-group mb-2">
                  <TagBox
                    type="text"
                    dataSource={dataSource?.organiserDetails}
                    labelMode="floating"
                    label="Organisers"
                    displayExpr="organiser_name"
                    valueExpr="organiser_id"
                    searchEnabled={true}
                    value={values.organiser_id}
                    virtualModeEnabled={true}
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "organiser_id", value: e.value },
                      })
                    }
                    itemRender={(item) => (
                      <TagItem
                        name={item.organiser_name}
                        image={item.profile_photo}
                      />
                    )}
                    tagRender={(data) => (
                      <Tag
                        name={data.organiser_name}
                        image={data.profile_photo}
                      />
                    )}
                  />
                </div>
              </div>

              {/* Quick Add */}
              <div
                className={
                  settings.quickAddEnable
                    ? "col-lg-6 form-group iq-button QuickAddFalse"
                    : "col-lg-6 form-group iq-button QuickAddTrue"
                }
              >
                <label className="">Quick Add &nbsp;</label>
                <Switch
                  value={settings.quickAddEnable}
                  onValueChanged={switchEvent}
                />
              </div>

              {settings.quickAddEnable && (
                <QuickAddGrid
                  dataSource={dataSource}
                  condition={settings.quickAddEnable}
                  setSettings={setSettings}
                  props={props}
                  setDataSource={setDataSource}
                  isJointEvent={values.event_type === JointEvent}
                />
              )}

              {values.event_type === JointEvent ? (
                <div className="col-lg-12">
                  <div className="form-group my-2 ">
                    <JointEventGrid
                      artistAndAccomDs={artistDataSource?.data}
                      mainCategoryDs={mainCategoryDataSource?.data}
                      subCategoryDs={dataSource?.subCategoryDataSource}
                      organiserDs={dataSource?.organiserDetails}
                      props={props}
                      values={values}
                      dataSource={dataSource}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-lg-12">
                  <div className="form-group my-2 ">
                    <div className="widget-container">
                      <HtmlEditor
                        height="auto"
                        defaultValue={markup}
                        label="Event Description"
                        className="dx-field-item-required"
                        labelMode="floating"
                        placeholder="Event Description"
                        value={values.event_desc}
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "event_desc", value: e.value },
                          })
                        }
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "event_desc", value: e.value },
                          })
                        }
                      >
                        <MediaResizing enabled={true} />
                        <ImageUpload
                          tabs={currentTab}
                          fileUploadMode="base64"
                        />
                        <Toolbar multiline={isMultiline}>
                          <Item name="undo" />
                          <Item name="redo" />
                          <Item name="separator" />
                          <Item
                            name="size"
                            acceptedValues={sizeValues}
                            options={fontSizeOptions}
                          />
                          <Item
                            name="font"
                            acceptedValues={fontValues}
                            options={fontFamilyOptions}
                          />
                          <Item name="separator" />
                          <Item name="bold" />
                          <Item name="italic" />
                          <Item name="strike" />
                          <Item name="underline" />
                          <Item name="separator" />
                          <Item name="alignLeft" />
                          <Item name="alignCenter" />
                          <Item name="alignRight" />
                          <Item name="alignJustify" />
                          <Item name="separator" />
                          <Item name="orderedList" />
                          <Item name="bulletList" />
                          <Item name="separator" />
                          <Item
                            name="header"
                            acceptedValues={headerValues}
                            options={headerOptions}
                          />
                          <Item name="separator" />
                          <Item name="color" />
                          <Item name="background" />
                          <Item name="separator" />
                          <Item name="link" />
                          <Item name="image" />
                          <Item name="separator" />
                          <Item name="clear" />
                          <Item name="codeBlock" />
                          <Item name="blockquote" />
                          <Item name="separator" />
                          <Item name="insertTable" />
                          <Item name="deleteTable" />
                          <Item name="insertRowAbove" />
                          <Item name="insertRowBelow" />
                          <Item name="deleteRow" />
                          <Item name="insertColumnLeft" />
                          <Item name="insertColumnRight" />
                          <Item name="deleteColumn" />
                        </Toolbar>
                      </HtmlEditor>
                      {errors.event_desc && touched.event_desc && (
                        <p className="error-message">{errors.event_desc}</p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-6">
                <div className="form-group mb-2">
                  <SelectBox
                    label="Ticketing Option"
                    className="dx-field-item-required"
                    dataSource={TicketingOptionMockData}
                    displayExpr="name"
                    valueExpr="value"
                    readOnly={isViewEvent !== true}
                    searchEnabled={true}
                    labelMode="floating"
                    onValueChanged={(e) =>
                      handleChange({
                        target: { name: "ticketing_option", value: e.value },
                      })
                    }
                    onFocusOut={(e) =>
                      handleBlur({
                        target: { name: "ticketing_option", value: e.value },
                      })
                    }
                    value={values.ticketing_option}
                  />
                  {errors.ticketing_option && touched.ticketing_option && (
                    <p className="error-message">{errors.ticketing_option}</p>
                  )}
                </div>
                {values.ticketing_option &&
                  values.ticketing_option !== ticketedonmdnd &&
                  values.ticketing_option !== ticketOptionFree && <p></p>}
              </div>

              {values.ticketing_option &&
                values.ticketing_option !== "" &&
                values.ticketing_option !== null && (
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <SelectBox
                        label="Event Mode"
                        className="dx-field-item-required"
                        labelMode="floating"
                        dataSource={dataSource.eventMode}
                        displayExpr="mode"
                        readOnly={isViewEvent !== true}
                        valueExpr="value"
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "event_mode", value: e.value },
                          })
                        }
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "event_mode", value: e.value },
                          })
                        }
                        value={values.event_mode}
                      />
                      {errors.event_mode && touched.event_mode && (
                        <p className="error-message">{errors.event_mode}</p>
                      )}
                    </div>
                  </div>
                )}

              {values.ticketing_option && (
                <>
                  <div className="col-lg-4">
                    <div className="form-group mb-2">
                      <SelectBox
                        label="Country"
                        onFocusIn={countryFocusIn}
                        displayExpr="country_name"
                        className="dx-field-item-required"
                        labelMode="floating"
                        valueExpr="country_id"
                        value={values.country_id}
                        dataSource={dataSource?.countryDataSource || []}
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "country_id", value: e.value },
                          })
                        }
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "country_id", value: e.value },
                          })
                        }
                        searchEnabled={true}
                      />
                      {errors.country_id && touched.country_id && (
                        <p className="error-message">{errors.country_id}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group mb-2">
                      <SelectBox
                        label="State"
                        displayExpr="state_name"
                        className="dx-field-item-required"
                        labelMode="floating"
                        valueExpr="state_id"
                        value={values.state_id}
                        dataSource={dataSource.stateDataSource || null}
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "state_id", value: e.value },
                          })
                        }
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "state_id", value: e.value },
                          })
                        }
                        searchEnabled={true}
                      />
                      {errors.state_id && touched.state_id && (
                        <p className="error-message">{errors.state_id}</p>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="form-group mb-2">
                      <SelectBox
                        label="City"
                        displayExpr="city_name"
                        className="dx-field-item-required"
                        valueExpr="city_id"
                        labelMode="floating"
                        value={values.city_id}
                        dataSource={dataSource?.cityDataSource || null}
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "city_id", value: e.value },
                          })
                        }
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "city_id", value: e.value },
                          })
                        }
                        searchEnabled={true}
                      />
                      {errors.city_id && touched.city_id && (
                        <p className="error-message">{errors.city_id}</p>
                      )}
                    </div>
                  </div>
                </>
              )}
              {values.ticketing_option === ticketedonmdnd && (
                <p className="CheckBoxText mb-2">
                  {mdndCountryRestriction}{" "}
                  <a
                    href="mailto:events@mdnd.in"
                    className="seatingLayoutNavLink"
                  >
                    events@mdnd.in
                  </a>
                </p>
              )}

              {/* Enter Button Text */}

              {values.event_mode &&
                values.event_mode !== "" &&
                values.event_mode !== null && (
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <TextBox
                        label="Enter text to appear in the button"
                        className="dx-field-item-required"
                        labelMode="floating"
                        onValueChanged={(e) =>
                          handleChange({
                            target: { name: "button_text", value: e.value },
                          })
                        }
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "button_text", value: e.value },
                          })
                        }
                        value={values.button_text}
                      />
                      {errors.button_text && touched.button_text && (
                        <p className="error-message">{errors.button_text}</p>
                      )}

                      {values.event_mode && values.ticketing_option && (
                        <p className="exampleText">
                          {(() => {
                            switch (values.ticketing_option) {
                              case ticketOptionFree:
                                return eventFreeText;
                              case freewithregistration:
                                return eventFreeWithReg;
                              case ticketedonmdnd:
                                return eventTicketed;
                              case ticketedonotherwebsites:
                                return eventOtherWeb;
                              default:
                                return null;
                            }
                          })()}
                        </p>
                      )}
                    </div>
                  </div>
                )}

              {/* Button Action */}

              {values.isButtonActionValid && (
                <div className="col-lg-6">
                  <div className="form-group mb-2">
                    <SelectBox
                      label="Button Action"
                      className="dx-field-item-required"
                      labelMode="floating"
                      dataSource={dataSource.buttonAction || []}
                      displayExpr="action"
                      valueExpr="value"
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "button_action", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "button_action", value: e.value },
                        })
                      }
                      value={values.button_action}
                    />
                    {errors.button_action && touched.button_action && (
                      <p className="error-message">{errors.button_action}</p>
                    )}
                  </div>
                </div>
              )}

              {values.event_mode === EventModeOnline && (
                <div className="col-lg-6">
                  <div className="form-group mb-2">
                    <TextBox
                      label="Stream Link"
                      labelMode="floating"
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "stream_link", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "stream_link", value: e.value },
                        })
                      }
                      value={values.stream_link}
                    />
                    {/* {errors.stream_link && touched.stream_link && (
                        <p className="error-message">{errors.stream_link}</p>
                      )} */}
                  </div>
                </div>
              )}

              {/* External Url or No action */}
              {values.button_action === OpenExternalURL &&
                values.button_action !== "" &&
                values.button_action !== null && (
                  <div className="col-lg-6">
                    <div className="form-group mb-2">
                      <TextBox
                        label="External URL"
                        className={`${
                          values.ticketing_option === ticketOptionFree &&
                          values.event_mode === EventModeOnline &&
                          values.isButtonUrlEnabled === true
                            ? "dx-field-item-required"
                            : ""
                        }`}
                        labelMode="floating"
                        onValueChanged={(e) => {
                          const urlRegex =
                            /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
                          if (urlRegex.test(e.value)) {
                            handleChange({
                              target: {
                                name: "button_url",
                                value: e.value,
                              },
                            });
                            setFieldError("button_url", "");
                          } else {
                            setFieldValue("button_url", "");
                          }
                        }}
                        onFocusOut={(e) =>
                          handleBlur({
                            target: { name: "button_url", value: e.value },
                          })
                        }
                        value={values.button_url}
                      />

                      {errors.button_url && touched.button_url && (
                        <p className="error-message">{errors.button_url}</p>
                      )}
                    </div>
                  </div>
                )}

              {/* 
              Venue Name */}

              {values.event_mode === EventModeOffline && (
                <div className="col-lg-6">
                  <div className="form-group mb-2">
                    <TextBox
                      label="Venue Name"
                      className="dx-field-item-required"
                      labelMode="floating"
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "venue_name", value: e.value },
                        })
                      }
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "venue_name", value: e.value },
                        })
                      }
                      value={values.venue_name}
                    />
                    {errors.venue_name && touched.venue_name && (
                      <p className="error-message">{errors.venue_name}</p>
                    )}
                  </div>
                </div>
              )}

              {/* Location */}

              {values.event_mode === EventModeOffline && (
                <div className="col-lg-6 pt-2">
                  <div className="form-group mb-2">
                    <SearchLocationInput
                      setSelectedLocation={setSelectedLocation}
                      setFieldValue={setFieldValue}
                      tempLocationData={values.tempLocationData}
                    />
                    {errors.location && touched.location && (
                      <p className="error-message">{errors.location}</p>
                    )}
                  </div>
                </div>
              )}

              {values.ticketing_option === ticketedonmdnd && (
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="form-group d-flex align-items-center mb-0">
                    <CheckBox
                      value={values.bookingWillOpenSoon}
                      onValueChanged={(e) =>
                        handleChange({
                          target: {
                            name: "bookingWillOpenSoon",
                            value: e.value,
                          },
                        })
                      }
                    />

                    <span className="ms-2 mb-0 fs-6">
                      <p className="CheckBoxText">{bookingWillOpenSoon}</p>
                    </span>
                  </div>
                </div>
              )}

              {values.ticketing_option === ticketedonmdnd &&
                values.event_mode === EventModeOffline && (
                  <div className="col-lg-6 d-flex align-items-center">
                    <div className="form-group d-flex align-items-center mb-0 w-100">
                      <SelectBox
                        label="Seating Plan"
                        className="dx-field-item-required  w-100"
                        labelMode="floating"
                        dataSource={seatingPlan}
                        displayExpr="plan"
                        valueExpr="value"
                        onValueChanged={(e) => seatingPlanOnChange(e.value)}
                        value={values.seating_option}
                      />
                      {errors.seating_option && touched.seating_option && (
                        <p className="error-message">{errors.seating_option}</p>
                      )}
                    </div>
                  </div>
                )}
            </div>

            {((values.ticketing_option === ticketedonmdnd &&
              values.event_mode === EventModeOffline &&
              values.seating_option === seatingPlanNonNumbered) ||
              // free with registration offline
              (values.ticketing_option === freewithregistration &&
                values.event_mode === EventModeOffline) ||
              (values.ticketing_option === ticketedonmdnd &&
                values.event_mode === EventModeOnline)) && (
              <TicketDetailsGrid
                dataSource={dataSource.seating_nonNumbered}
                values={values}
                setDataSource={setDataSource}
                props={props}
                data={dataSource}
              />
            )}

            {values.ticketing_option === ticketedonmdnd && (
              <div className="col-lg-6 mt-2 d-flex align-items-center">
                <div className="form-group d-flex align-items-center mb-0">
                  <CheckBox
                    onValueChanged={(e) =>
                      handleChange({
                        target: {
                          name: "is_promo_code",
                          value: e.value,
                        },
                      })
                    }
                    value={values.is_promo_code}
                  />

                  <span className="ms-2 mb-0 fs-6 CheckBoxText">
                    {PromoCode}
                  </span>
                </div>
              </div>
            )}
            {values.is_promo_code && (
              <AddPromoCodeGrid
                promoCodeDataSource={dataSource.promoCodeDataSource}
                setFieldValue={setFieldValue}
              />
            )}

            {values.ticketing_option === ticketedonmdnd &&
              values.event_mode === EventModeOffline &&
              values.seating_option === seatingPlanNonNumbered && (
                <div className="col-lg-6 mt-2 d-flex align-items-center">
                  <div className="form-group d-flex align-items-center mb-0">
                    <CheckBox
                      onValueChanged={(e) =>
                        handleChange({
                          target: {
                            name: "is_addon_code",
                            value: e.value,
                          },
                        })
                      }
                      defaultValue={values.is_addon_code}
                    />

                    <span className="ms-2 mb-0 fs-6 CheckBoxText">{AddOn}</span>
                  </div>
                </div>
              )}

            {values.is_addon_code && (
              <AddOnCodeGrid
                addOnCodeDataSource={dataSource.addOnCodeDataSource}
                setFieldValue={setFieldValue}
              />
            )}

            {values.event_mode === EventModeOffline &&
              selectedLocation &&
              selectedLocation.lat !== null &&
              selectedLocation.lng !== null && (
                <div class="col-lg-6 mapPosAlign">
                  <div class="mapPosAlign">
                    <MapComponent selectedLocation={selectedLocation} />
                  </div>
                </div>
              )}

            {values.ticketing_option === ticketedonmdnd &&
              values.event_mode === EventModeOffline &&
              values.seating_option === seatingPlanNumbered && (
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="form-group d-flex align-items-center mb-2 w-100">
                    <SelectBox
                      label="Choose Venue layouts"
                      className="dx-field-item-required w-100"
                      labelMode="floating"
                      // onFocusIn={handleLayoutFocusIn}
                      dataSource={dataSource.venueLayoutsDataSource || []}
                      displayExpr="venue_name"
                      valueExpr="venue_id"
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "venue_id", value: e.value },
                        })
                      }
                      value={values.venue_id}
                    />
                    {errors.venue_id && touched.venue_id && (
                      <p className="error-message">{errors.venue_id}</p>
                    )}
                  </div>
                </div>
              )}
            {values.seating_option === seatingPlanNumbered &&
              values.event_mode === EventModeOffline &&
              values.ticketing_option === ticketedonmdnd && (
                <p className="exampleText">
                  If you don't find the layout for your venue, you can create a
                  layout on your own at{" "}
                  <Link
                    to="/createSeatingLayout"
                    className="seatingLayoutNavLink"
                  >
                    Create seating layout
                  </Link>
                  . The layout that you create will appear first in the drop
                  down list
                </p>
              )}

            {dataSource &&
              dataSource.seatingLayoutDataSource !== "" &&
              Object.keys(props).length === 0 &&
              values.seating_option === seatingPlanNumbered &&
              values.event_mode === EventModeOffline &&
              values.ticketing_option === ticketedonmdnd && (
                <CreateSeatingLayouts
                  propsDataSource={dataSource.seatingLayoutDataSource}
                  setDataSourceEvents={setDataSource}
                  isticketpurchase={false}
                  isViewEvent={isViewEvent}
                  lookupDetails={dataSource.lookupDetails}
                />
              )}

            {/* Image upload */}

            <div className="col-lg-6 ">
              <div className="form-group my-2">
                <FileUploader
                  label="event_image"
                  className=" p-0"
                  labelMode="floating"
                  labelText=""
                  onValueChanged={(e) => imageOnchange(e)}
                  accept="image/*"
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "event_image", value: e.value },
                    })
                  }
                  selectButtonText="Upload Event Poster / Image or Drop image here"
                />
                {settings?.imageName && <p>{settings.imageName}</p>}

                {errors.event_image && touched.event_image && (
                  <p className="error-message">{errors.event_image}</p>
                )}
              </div>
            </div>
            {values?.event_image &&
              values?.event_image !== null &&
              values?.event_image !== "" && (
                <div className="col-lg-2">
                  <div className="form-group mb-2">
                    <p>Preview</p>
                    <img
                      src={
                        values?.event_image?.startsWith("data:image/")
                          ? values.event_image
                          : `${ALLEVENT_DETAILS_IMAGES}${values.event_image}`
                      }
                      alt="Event"
                    />
                  </div>
                </div>
              )}
            <div className="mt-0"></div>
            <Button
              text="Submit"
              className="jointEventAddBtn me-2"
              onClick={handleSubmit}
              disabled={isSubmitting}
            />

            <Button
              text={
                props.isEditEvent === false ? "Update Draft" : "Save as Draft"
              }
              className="jointEventAddBtn me-1"
              onClick={handleSaveDraft}
              disabled={isSubmitting}
            />
          </div>
          {settings && settings.isNumberedError && (
            <NumberedDenominationNotification
              show={settings.isNumberedError}
              onClose={numberedErrorClose}
              message={dataSource.numberedErrors}
              imageSource={nonNumberedErrorImage}
            />
          )}

          {settings && settings.previewEvent && (
            <PreviewEvent
              show={settings.previewEvent}
              onClose={previewEventClose}
              message={settings.previewEventDetails}
              imageSource={previewEventImage}
              isSeasonEvent={false}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateEvents;
