import * as Yup from "yup";
import {
  COMMONFIELDREQUIRED,
  INVALIDURL,
} from "../global/ToastMsgConstants.tsx";
import { EventModeOffline, EventModeOnline } from "../global/constants.js";

export const EVENTS_SCHEMA = Yup.object().shape({
  isSubCategoryValid: Yup.bool(),
  isButtonActionValid: Yup.bool(),
  isButtonUrlEnabled: Yup.bool(),
  isTicketLimitation: Yup.bool(),

  event_title: Yup.string().required(COMMONFIELDREQUIRED),

  main_catid: Yup.string().required(COMMONFIELDREQUIRED),

  sub_catid: Yup.string().when(
    "isSubCategoryValid",
    (isSubCategoryValid, schema) => {
      if (isSubCategoryValid[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }

      return schema.nullable();
    }
  ),

  event_date: Yup.date().required(COMMONFIELDREQUIRED),

  event_time: Yup.string().required(COMMONFIELDREQUIRED),

  artist_ids: Yup.array().when("isArtistEnabled", (isArtistEnabled, schema) => {
    if (isArtistEnabled[0] !== true) {
      return schema.required(COMMONFIELDREQUIRED);
    }
    return schema
      .nullable()
      .transform((curr, orig) => (orig === "" ? [] : curr));
  }),

  timezone: Yup.string().required(COMMONFIELDREQUIRED),
  country_id: Yup.string().required(COMMONFIELDREQUIRED),
  state_id: Yup.string().required(COMMONFIELDREQUIRED),
  city_id: Yup.string().required(COMMONFIELDREQUIRED),

  event_image: Yup.string().required(COMMONFIELDREQUIRED),

  ticketing_option: Yup.string().required(COMMONFIELDREQUIRED),

  event_mode: Yup.string().when(
    "ticketing_option",
    (ticketing_option, schema) => {
      if (
        ticketing_option[0] !== "" &&
        ticketing_option !== "" &&
        ticketing_option !== undefined
      ) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema;
    }
  ),

  button_text: Yup.string().when(
    "isButtonTextEnabled",
    (isButtonTextEnabled, schema) => {
      if (isButtonTextEnabled[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema;
    }
  ),

  button_action: Yup.string().when(
    "isButtonActionValid",
    (isButtonActionValid, schema) => {
      if (isButtonActionValid[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema;
    }
  ),

  button_url: Yup.string().when(
    "isButtonUrlEnabled",
    (isButtonUrlEnabled, schema) => {
      if (isButtonUrlEnabled[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema;
    }
  ),

  venue_name: Yup.string().when("event_mode", (event_mode, schema) => {
    if (event_mode[0] === EventModeOffline) {
      return schema.required(COMMONFIELDREQUIRED);
    }
    return schema;
  }),

  // stream_link: Yup.string().when(
  //   "isStreamLinkEnabled",
  //   (isStreamLinkEnabled, schema) => {
  //     if (isStreamLinkEnabled[0] === true) {
  //       return schema.required(COMMONFIELDREQUIRED);
  //     }
  //     return schema;
  //   }
  // ),

  // show_to_public: Yup.string().required(COMMONFIELDREQUIRED),

  location: Yup.string().when("event_mode", (event_mode, schema) => {
    if (event_mode[0] === EventModeOffline) {
      return schema.required(COMMONFIELDREQUIRED);
    }
    return schema;
  }),

  seating_option: Yup.string().when(
    "isSeatingEnabled",
    (isSeatingEnabled, schema) => {
      if (isSeatingEnabled[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema;
    }
  ),

  event_mode: Yup.string().required(COMMONFIELDREQUIRED),
});

export const SEASON_EVENTS_SCHEMA = Yup.object().shape({
  season_event_title: Yup.string().required(COMMONFIELDREQUIRED),
  season_start_date: Yup.string().required(COMMONFIELDREQUIRED),

  season_end_date: Yup.date().required(COMMONFIELDREQUIRED),
  season_description: Yup.string(),

  season_event_ids: Yup.mixed().when('multiple_tickets', {
    is: true,
    then: () => Yup.number().required(COMMONFIELDREQUIRED),
    otherwise: () => Yup.mixed().nullable().transform((curr, orig) => {
      if (orig === '') return null;
      return curr;
    })
  }),
  country_id: Yup.string().required(COMMONFIELDREQUIRED),
  state_id: Yup.string().required(COMMONFIELDREQUIRED),
  city_id: Yup.string().required(COMMONFIELDREQUIRED),

  event_image: Yup.string().required(COMMONFIELDREQUIRED),

  season_button_text: Yup.string().required(COMMONFIELDREQUIRED),

  // Ticked on MDnD - offline

  location: Yup.string().when(
    "isLocationEnable",
    (isLocationEnable, schema) => {
      if (isLocationEnable[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema;
    }
  ),
  venue_name: Yup.string().when(
    "isVenueNameEnable",
    (isVenueNameEnable, schema) => {
      if (isVenueNameEnable[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema;
    }
  ),

  row_name: Yup.number().when(
    "isFreeWithRegistrationEnabled",
    (isFreeWithRegistrationEnabled, schema) => {
      if (isFreeWithRegistrationEnabled[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema.nullable();
    }
  ),

  totalqty: Yup.number().when(
    "isFreeWithRegistrationEnabled",
    (isFreeWithRegistrationEnabled, schema) => {
      if (isFreeWithRegistrationEnabled[0] === true) {
        return schema.required(COMMONFIELDREQUIRED);
      }
      return schema.nullable();
    }
  ),
});

export const validation_venueDetails = Yup.object().shape({
  venue_name: Yup.string().required(COMMONFIELDREQUIRED),
  venue_description: Yup.string().required(COMMONFIELDREQUIRED),

  address: Yup.string().required(COMMONFIELDREQUIRED),
  country_id: Yup.number().required(COMMONFIELDREQUIRED),

  state_id: Yup.number().required(COMMONFIELDREQUIRED),

  city_id: Yup.number().required(COMMONFIELDREQUIRED),
  status: Yup.string().required(COMMONFIELDREQUIRED),
});
