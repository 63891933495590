import React, { useState, useEffect, useCallback, useContext } from "react";
import { SelectBox } from "devextreme-react";
import { useMutation } from "@tanstack/react-query";
import { ArtistallImg, ORGANISER_URL } from "../Subpages/MainPage/constant";
import { FaFilter } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import NoDataFound from "../global/NoDataFound";
import {
  getSeasonEventAvailableCountries,
  getOrganiserDetailsById,
} from "../Api/api";
import "../index.js";
import { makeGetRequest, makePostRequest } from "../Api/requestMethods";
import { ERROR, DURATION, SUCCESS } from "../global/ToastMsgConstants.tsx";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { isValidValue } from "../utils/commonUtils";
import { useNavigate } from "react-router-dom";
import { generateAlphabetCharacters } from "../utils/commonUtils";
import { isValidImage } from "../global/ImageCheck.js";
import { imageExists } from "../global/ImageCheck.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Banner1 from "../Subpages/MainPage/BannerImage/SeasonMobile.png"
import Banner2 from "../Subpages/MainPage/BannerImage/SeasonWeb.png";
import { IoChevronBack } from "react-icons/io5";

const SeasonOrganizerLandingPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [dataSource, setDataSource] = useState({
    SeasonEventData: "",

    selectedEventMode: "",
    country: "",
    selectedCountry: "",
    city: "",

    selectedCity: "",
    selectedDate: "",
    startDate: null,
    endDate: null,
    checked: false,

    selectedDate_range: "",
    selectedPricing: "",
  });

  const [organiserCollection, setOrganiserCollection] = useState({
    finalData: [],
    country: "",
    selectedCountry: "",
    state: "",
    city: "",
    selectedCity: "",
  });

  const getAvailCountries = async () =>
    await makeGetRequest(getSeasonEventAvailableCountries);
  const { data: availCountriesDs } = useQuery({
    queryKey: ["__seasonEventsAvailCountries__"],
    queryFn: () => getAvailCountries(),
    onError: (error) => {
    },
  });

  useEffect(() => {
    if (availCountriesDs && availCountriesDs) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: 101,
      }));
    }
  }, [availCountriesDs]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await makeGetRequest(getOrganiserDetailsById(organiserCollection.selectedCountry));
        setOrganiserCollection((prev) => ({
          ...prev,
          finalData: response?.data || [],
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (organiserCollection.selectedCountry) {
      fetchData();
    }
  }, [organiserCollection.selectedCountry]);


  const handleClick = (data) => {
    try {
      const { role_id } = data;
      navigate(
        `/SeasonTickets/OrganizerLandingPage?ci=${organiserCollection.selectedCountry}&r_id=${role_id}`
      );
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    (async () => {
      try {
        if (isValidValue(organiserCollection.selectedCountry)) {
          const response = await makeGetRequest(
            getOrganiserDetailsById(organiserCollection.selectedCountry)
          );
          if (response.status === SUCCESS || response) {
            setOrganiserCollection((prev) => ({
              ...prev,
              finalData: response?.data || [],
            }));
          } else {
          }
        }
      } catch (error) {
      }
    })();
  }, [organiserCollection.selectedCountry]);

  const handleCountry = (e) => {
    if (e.value) {
      setOrganiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setOrganiserCollection((prevState) => ({
        ...prevState,
        city: [],
        selectedCity: "",
      }));
    }
  };

  useEffect(() => {
    if (
      organiserCollection.selectedCountry

    ) {
      const queryParams = new URLSearchParams({
        countryid: Number(organiserCollection.selectedCountry),


      });

      const newUrl = `/SeasonTickets/organiserListing?${queryParams}`;
      navigate(newUrl);
    }
  }, [
    organiserCollection.selectedCountry,
    navigate,
  ]);

  const handlebackButton =()=>{
    
    navigate(`/`);
  }

  const [filter, setFilter] = useState(false);
  const doFilter = () => {
    setFilter(!filter);
  };

  useEffect(()=>{
    const metaTitle = `Season Tickets 2024`;
        document.title = metaTitle;

        const metaDescription = `Top Sabhas conducting Carnatic concerts & Bharatanatyam events during December Margazhi season in Chennai.|Narada Gana Sabha, Madarasana, Kartik Fine Arts, Mylapore Fine Arts Club, Sri Parthasarathy Swamy Sabha, Brahma Gana Sabha, Krishna Gana Sabha, Music Academy, Indian Fine Arts Society, Hamsadhwani, Nada Sudha, Tamil Isai Sangam, Sri Tyaga Brahma Gana Sabha, Madhuradhwani, Arkay Convention Centre, Kalakshetra Foundation,Rasikapriya`;
        const descriptionTag = document.querySelector('meta[name="description"]');
        if (descriptionTag) {
            descriptionTag.setAttribute("content", metaDescription);
        } else {
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = metaDescription;
            document.head.appendChild(meta);
        }
  },[])

  return (
    <>
      <div className="position-relative">
        <div className="d-none d-md-block mt-lg-0 ">
          <img
            src={Banner2}
            className="filter-page"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        {/* Mobile image */}
        <div className="d-block d-md-none">
          <img
            src={Banner1}
            className="filter-page"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div className="text-center w-100 text-white" style={{ top: "30%", position: "absolute", zIndex: "1" }}>
          <h4 className="text-white">Season Tickets</h4>
          <p className="mb-0">Experience the world famous Chennai kutcheri season's concerts and canteens. Search by sabha, organiser, canteen.</p>
          <p>Search by sabha, organiser, canteen.</p>
        </div>
      </div>
      <section className="section-padding pt-4">
        <div className="container-fluid">
          <div className="row">
            <div
              className="w-100 filter-sticky d-lg-none d-flex flex-nowrap align-items-center gap-3 justify-content-between"
            >
              <div
                className="w-100 d-flex align-items-center hide-scrollbar"
                style={{ gap: "2px", overflow: "scroll" }}
              ></div>
              
              <div
                className="filter-button align-items-center"
                onClick={doFilter}
              >
                
                <FaFilter />Filters
              </div>
             
            </div>
            {loading ? (
                    <div className="col-lg-3 col-xl-2 sideBarFilter">
                    <Skeleton height={30} width="100%" className="mt-2" />
                    <Skeleton height={30} width="100%" className="mt-2" />
                    <Skeleton height={30} width="100%" className="mt-2" />
                    <Skeleton height={30} width="100%" className="mt-2" />
                    </div>
                  ) : (
                <div className="col-lg-3 col-xl-2 sideBarFilter">
                  <div className="filterBg pb-2">
                 
                    <div className="" >
                    
                      <h5>
                      
                        <FaFilter  /> Filters
                      </h5>
                      
                    </div>
                    <div className="mt-4 d-flex flex-column gap-2">
                      <>
                        <SelectBox
                          dataSource={availCountriesDs?.data || []}
                          displayExpr="country_name"
                          valueExpr="country_id"
                          label="Select Country"
                          searchEnabled={true}
                          onValueChanged={handleCountry}
                          labelMode="floating"
                          value={organiserCollection?.selectedCountry}
                        />
                      </>
                    </div>
                  </div>
                </div>
                  )}
            <div className="col-xl-10 col-lg-9 col-12">
              <div>
                <Modal
                  className="filterPopUp"
                  style={{ zIndex: "10001" }}
                  show={filter}
                    onHide={doFilter}
                >
                  <Modal.Header className="py-1 px-2 border-0" closeButton>
                    <Modal.Title className="ms-2">Filters</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="p-3">
                    <div className="mt-4 d-flex flex-column gap-2">
                      <h6 className="mb-2">Location</h6>

                      <SelectBox
                        dataSource={availCountriesDs?.data}
                        displayExpr="country_name"
                        valueExpr="country_id"
                        label="Select Country"
                        searchEnabled={true}
                        onValueChanged={handleCountry}
                        labelMode="floating"
                        value={organiserCollection?.selectedCountry}
                        dropDownOptions={{ container: ".modal" }}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer className="py-1 px-2 border-0">
                    <Button
                      className="px-3 py-1 bg-green text-white btn border-0"
                    >
                      Apply
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div
                    className="p-auto mb-4 me-3 rounded  d-md-block backBtn cursor-pointer"
                    style={{ border: "1px solid #ddd" }}
                  >
                    <IoChevronBack
                      style={{ fontSize: "24px" }}
                    onClick={handlebackButton}
                    />
                  </div>

              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 filtermh">
              
                <>
                {loading ? (
                  Array.from({ length: 20 }).map((_, index) => (
                    <div className="col" key={index}>
                      <Skeleton height={200} />
                      <Skeleton height={20} style={{ marginTop: 8,marginBottom: 10 }} />
                    </div>
                  ))
                ) :
                  organiserCollection?.finalData &&
                    organiserCollection?.finalData?.length > 0 ? (
<>

                    {organiserCollection?.finalData.map((person, index) => (
                      <div className="col" key={index}>
                        <div className="iq-cast rounded-3 overflow-hidden">
                          <div
                            className="img-container artistImgSize"
                            style={{
                              backgroundColor: person.profile_photo
                                ? "transparent"
                                : "#dddddd",
                              color: "#ffffff",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {person.role_name === "artist" ? (
                              isValidImage(person.profile_photo) &&
                                imageExists(
                                  `${ArtistallImg}/${person.profile_photo}`
                                ) ? (
                                <img
                                  src={`${ArtistallImg}/${person?.profile_photo}`}
                                  onClick={() => handleClick(person)}
                                  className="img-fluid w-100"
                                  alt={person?.name}
                                  style={{
                                    objectFit: "cover",
                                    height: "100%",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <>
                                <h1
                                  style={{ color: '#ffffff', fontSize: '4rem', cursor: 'pointer' }}
                                  onClick={() => handleClick(person)}
                                >
                                  {generateAlphabetCharacters(person?.name)}
                                </h1>
                                </>)
                            ) : person.role_name === "organiser" ? (
                              isValidImage(person.profile_photo) &&
                                imageExists(
                                  `${ORGANISER_URL}/${person?.profile_photo}`
                                ) ? (
                                <img
                                  src={`${ORGANISER_URL}/${person?.profile_photo}`}
                                  onClick={() => handleClick(person)}
                                  className="img-fluid w-100"
                                  alt={person?.name}
                                  style={{
                                    objectFit: "cover",
                                    height: "100%",
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <h1
                                  style={{
                                    color: "#ffffff",
                                    fontSize: "4rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleClick(person)}
                                >
                                  {generateAlphabetCharacters(person?.name)}
                                </h1>
                              )
                            ) : null}
                          </div>

                          <div className="card-img-overlay d-flex justify-content-between artistTextBg position-relative">
                            <p
                              className="cast-title fw-500 text-black mb-0"
                              onClick={() => handleClick(person)}
                            >
                              {person.name}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>) : (
                    <NoDataFound />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeasonOrganizerLandingPage;