import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../../global/ToastMsgConstants.tsx";
import { PASSWORD_LENGTH, INVALID_EMAIL } from "../errorMessageConstants.js";
import {
  ARTIST_ROLE,
  ORGANISER_ROLE,
  FAN_ROLE,
} from "../../global/constants.js";

const LOGIN_SCHEMA = Yup.object({
  email: Yup.string().email(INVALID_EMAIL).required(COMMONFIELDREQUIRED),
  password: Yup.string().min(2, PASSWORD_LENGTH).required(COMMONFIELDREQUIRED),
});

const FORGOT_PASSWORD_SCHEMA = Yup.object({
  email: Yup.string().email(INVALID_EMAIL).required(COMMONFIELDREQUIRED),
});

const REGISTRATION_SCHEMA = (selectedRole) => {
  let schema;

  switch (selectedRole) {
    case ARTIST_ROLE:
      schema = Yup.object().shape({
        artist_main_cat: Yup.number().required(COMMONFIELDREQUIRED),
        artist_sub_cat: Yup.number().required(COMMONFIELDREQUIRED),
        dob: Yup.string().required(COMMONFIELDREQUIRED),
        gender: Yup.string().required(COMMONFIELDREQUIRED),
        mobile: Yup.number().required(COMMONFIELDREQUIRED),
        country_id: Yup.number().required(COMMONFIELDREQUIRED),
        state_id: Yup.number().required(COMMONFIELDREQUIRED),
        city_id: Yup.string().required(COMMONFIELDREQUIRED),
      });
      break;
    case FAN_ROLE:
      schema = Yup.object().shape({
        gender: Yup.string().required(COMMONFIELDREQUIRED),
        mobile: Yup.number().required(COMMONFIELDREQUIRED),
        country_id: Yup.number().required(COMMONFIELDREQUIRED),
        state_id: Yup.number().required(COMMONFIELDREQUIRED),
        city_id: Yup.string().required(COMMONFIELDREQUIRED),
      });
      break;
    case ORGANISER_ROLE:
      schema = Yup.object().shape({
        mobile: Yup.number().required(COMMONFIELDREQUIRED),
        country_id: Yup.number().required(COMMONFIELDREQUIRED),
        state_id: Yup.number().required(COMMONFIELDREQUIRED),
        city_id: Yup.string().required(COMMONFIELDREQUIRED),
      });
      break;
    default:
      schema = Yup.object().shape({});
      break;
  }

  return schema;
};

//new code change...

const FORGOT_SCHEMA = Yup.object().shape({
  email: Yup.string().email(INVALID_EMAIL).required(COMMONFIELDREQUIRED),
  password: Yup.string()
    .min(2, "Password must be atleast 2 characters")
    .required(COMMONFIELDREQUIRED),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(COMMONFIELDREQUIRED),
});

export {
  LOGIN_SCHEMA,
  REGISTRATION_SCHEMA,
  FORGOT_PASSWORD_SCHEMA,
  FORGOT_SCHEMA,
};
