import React from 'react';
import './HowToPlay.css'; // Create this CSS file to style the modal

export default function HowToPlay({ onClose }) {
  return (
    <div className="how-to-play-container">
      <div style={{ position: "relative" }} className="how-to-play-content">
        <button className="close-button" onClick={onClose}>
          &times; {/* Close button */}
        </button>
        <h3 className="mb-4">How to play the RagaRiddle?</h3>
        <p>This game doesn't require registration or login.</p>
        <p>You can play by 2 modes:</p>
        <ol>
          <li>
            <b>Play by Raga name:</b> Guess today's Raga name in 5 chances.The
            number of blanks is equal to the number of letters in the raga name.
            Go ahead and fill in the blanks, even if the resultant word doesn't
            make sense. When you submit a guess, the letters in the right place
            will be highlighted in Green. If you get a letter correct but the
            position is wrong, then the letter is highlighted in Blue. If the
            letter is not present in today's raga name, it will be highlighted
            in Grey.
          </li>
          <li>
            <b>Play by Swaras:</b> Guess today's Raga name in 5 chances by
            guessing the Swara sequence. When you submit a guess, the swaras in
            the right place will be highlighted in Green. If you get a swara
            correct but the position is wrong, then the swara is highlighted in
            Blue. If the swara is not present in today's raga, it will be
            highlighted in Grey.
          </li>
        </ol>
        <p className="text-center">
          You can play both every day! <b>Happy RagaRiddling!</b>
        </p>
      </div>
    </div>
  );
}
