import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { geners } from '../../Subpages/profiles/constent'; 
import { GET_BlOG_CATEGORIES } from '../../Api/api';
import { useQuery } from '@tanstack/react-query';
import notify from 'devextreme/ui/notify';
import { DURATION, ERROR } from '../../global/ToastMsgConstants.tsx';
import { makeGetRequest } from '../../Api/requestMethods';
import { BlogCategoryImage } from '../../Subpages/MainPage/constant.js';
// import './artist.css';

const Blog = () => {
  const navigate = useNavigate();


  const handleClick = (blogName) => {
    navigate(`/blogs/${blogName.blog_cate_name}`,{state:{blogName,blog_main_cate_id:blogName.blog_cate_id}});
  }
// ---------------------------------------------------------Getting Blog Data-------------------------------------------------------------------------
 const fetchBlog = async()=> await makeGetRequest(GET_BlOG_CATEGORIES);
 const {
  data: BlogDataSource,
  error,
  isLoading,
 }= useQuery({
  queryKey:["blogCategory"],
  queryFn:()=> fetchBlog(),
  onError:()=>{
    notify(error.message,ERROR,DURATION);
  },
 });
 

 console.log(BlogDataSource,"blooog")
//====================================================================================================================================================
  return (
    <>
      <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">Blog</h2>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section-padding pt-lg-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mb-4">
          </div>
          <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
            {BlogDataSource && BlogDataSource?.data?.length> 0 && BlogDataSource?.data?.map(data => (
              <div className="col mb-4" key={data.blog_cate_id}>
                <div className="iq-card-geners card-hover-style-two" onClick={() => handleClick(data)}>
                  <div className="block-images position-relative w-100">
                    <div className="img-box rounded position-relative">
                      <img src={`${BlogCategoryImage}/${data?.blog_cate_img}`} alt="genre-img" className="img-fluid object-cover w-100 rounded" />
                      <div className="blog-description">
                        {/* <h6 className="mb-0 iq-title text-black" >
                            {data.blog_cate_name}
                        </h6> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
