import { makePostRequest } from "../../../Api/requestMethods";
import {
  ARTIST_DETAILS,
  ARTIST_SUB_CATEGORIES,
  CITY,
  STATE,
  CREATE_EVENTS,
  createVenue,
  EVENT_ARTISTS,
  PROFILE_ORGANISER_BY_NAME,
  PROFILE_DETAILS,
  get_all_events_title,
  viewEvents,
  editEvents,
  createSaveAsDrafts,
  getDraftDetails,
  getNumberedEventDetails,
  getVenuSeatingLayout,
  eventPromoCode,
  eventAddOnCode,
  getAllOrganiserDetails,
} from "../../../Api/api";

const subCategoryFetch = async (mainCatId) => {
  try {
    if (mainCatId) {
      const response = await makePostRequest(ARTIST_SUB_CATEGORIES, mainCatId);
      return response;
    }
  } catch (error) {
    throw error;
  }
};

const organiserDetailsAllFetch = async (organiserData) => {
  try {
    if (organiserData) {
      const response = await makePostRequest(
        getAllOrganiserDetails,
        organiserData
      );
      return response;
    }
  } catch (error) {
    throw error;
  }
};

const profileDetails = async (data) => {
  try {
    const response = await makePostRequest(PROFILE_DETAILS, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const ArtistDetailsFetch = async (artistDetails) => {
  try {
    const response = await makePostRequest(EVENT_ARTISTS, artistDetails);
    return response;
  } catch (error) {
    throw error;
  }
};

const AccompanistDetailsFetch = async (accompanistDetails) => {
  try {
    const response = await makePostRequest(EVENT_ARTISTS, accompanistDetails);
    return response;
  } catch (error) {
    throw error;
  }
};

const OrganiserDetailsFetch = async (organiserDetails) => {
  try {
    const response = await makePostRequest(
      PROFILE_ORGANISER_BY_NAME,
      organiserDetails
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const stateFetch = async (countryId) => {
  try {
    const response = await makePostRequest(STATE(), countryId);
    return response;
  } catch (error) {
    throw error;
  }
};

const cityFetch = async (stateId) => {
  try {
    const response = await makePostRequest(CITY(), stateId);
    return response;
  } catch (error) {
    throw error;
  }
};

const createEvents = async (data) => {
  try {
    const response = await makePostRequest(CREATE_EVENTS(), data);
    return response;
  } catch (error) {
    throw error;
  }
};

const getAllEventsTitle = async (data) => {
  try {
    const response = await makePostRequest(get_all_events_title, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const viewEventsFetch = async (data) => {
  try {
    const response = await makePostRequest(viewEvents, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const editEventsFetch = async (data) => {
  try {
    const response = await makePostRequest(editEvents, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const viewSeasonEvents = async (data) => {
  try {
    const response = await makePostRequest(viewEvents, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const createVenueLayout = async (data) => {
  try {
    const response = await makePostRequest(createVenue, data);
    return response;
  } catch (error) {
    throw error;
  }
};
const seatingLayoutNumbered = async (data) => {
  try {
    const response = await makePostRequest(createVenue, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const createDrafts = async (data) => {
  try {
    const response = await makePostRequest(createSaveAsDrafts, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const getDraftsDetails = async (data) => {
  try {
    const response = await makePostRequest(getDraftDetails, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const getNumberEventFetch = async (data) => {
  try {
    const response = await makePostRequest(getNumberedEventDetails, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const getVenueLayout = async (data) => {
  try {
    const response = await makePostRequest(getVenuSeatingLayout, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const getPromoCode = async (data) => {
  try {
    const response = await makePostRequest(eventPromoCode, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const getAddOnCode = async (data) => {
  try {
    const response = await makePostRequest(eventAddOnCode, data);
    return response;
  } catch (error) {
    throw error;
  }
};







export {
  organiserDetailsAllFetch,
  getAddOnCode,
  getPromoCode,
  getVenueLayout,
  getNumberEventFetch,
  getDraftsDetails,
  createDrafts,
  seatingLayoutNumbered,
  createVenueLayout,
  viewSeasonEvents,
  editEventsFetch,
  viewEventsFetch,
  cityFetch,
  stateFetch,
  ArtistDetailsFetch,
  subCategoryFetch,
  createEvents,
  AccompanistDetailsFetch,
  OrganiserDetailsFetch,
  profileDetails,
  getAllEventsTitle,
};
