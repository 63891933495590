import React from "react";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton,  } from 'react-share';
import { useRef } from "react";
export default function ShareSocMedia_fan({ postdescr, postimage, shareUrl }){


    const isUrl = (text) => {
        try {
          new URL(text);
          return true;
        } catch (e) {
          return false;
        }
      };

      const ogUrl = isUrl(postdescr) ? postdescr : shareUrl;

      const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputRef.current.value).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };
  return(
    <>
    
      
      <div>
        <div className="d-flex gap-3 justify-content-around mx-3">
      {isUrl(postdescr) ? (
              <>
                
                <WhatsappShareButton style={{ color: "green" }} url={postdescr}>
                <i
                    className="fa-brands fa-whatsapp"
                    style={{
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  ></i>
                </WhatsappShareButton>
                <FacebookShareButton style={{ color: "blue" }} url={postdescr}>
                <i className="fa-brands fa-facebook"></i>
                </FacebookShareButton>
                <TwitterShareButton  style={{ color: "black" }} url={postdescr}>
                <i className="fa-brands fa-x-twitter"></i>
                </TwitterShareButton>
              </>
            ) : (
              <>
                
                <WhatsappShareButton style={{ color: "green" }} url={shareUrl}>
                <i
                    className="fa-brands fa-whatsapp"
                    style={{
                      fontSize: "40px",
                      cursor: "pointer",
                    }}
                  ></i>
                </WhatsappShareButton>
                <FacebookShareButton style={{ color: "blue" }} url={shareUrl}>
                <i className="fa-brands fa-facebook"></i>
                </FacebookShareButton>
                <TwitterShareButton style={{ color: "black" }} url={shareUrl}>
                <i className="fa-brands fa-x-twitter"></i>
                </TwitterShareButton>
              </>
            )}
    
    </div>
    <div className="copy-link-container">
                            <div className="copy-header"></div>
                            <div className="copy-link">
                              <div className="copy-link-inner">
                                <form onSubmit={handleCopy} className="d-flex">
                                  <input
                                    type="text"
                                    value={shareUrl}
                                    ref={inputRef}
                                    className="w-100 text-nowrap overflow-hidden"
                                    onClick={() => inputRef.current.select()}
                                    readOnly
                                  />
                                  <input
                                    type="submit"
                                    className="text-nowrap"
                                    value="Copy"
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
    
    
    
    
        
      </div>
    </>
    
          )
}