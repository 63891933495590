import React from "react";
import { Popover } from "devextreme-react/popover";

const PopOver = ({target, message}) => {
  const animationConfig = {
    show: { type: "pop", from: { scale: 0 }, to: { scale: 1 } },
    hide: { type: "fade", from: 1, to: 0 },
  };

  return (
    <Popover
      target={target}
      showEvent="mouseenter"
      hideEvent="mouseleave"
      position="top"
      width={300}
      animation={animationConfig}
    >
      {message}
    </Popover>
  );
};

export default PopOver;
