import React, { useEffect, useState } from "react";
import { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import Notification from "../../../components/Notification";
import tropImg from "../../../components/static/tropy.png";
import Feed from "../Feed/Feed";
import { useNavigate, Outlet } from "react-router-dom";
import { DashboardEvents } from "../../../global/constants";
import {
  dashboardNotificationDetails,
  dashboardMyFavDetails,
} from "../../../global/constants";
import { GET_DASHBOARD, GET_FOLLOWERS, GET_FOLLOWING } from "../../../Api/api";
import axios from "axios";
import { BASEPATH } from "../../../config/config";
import { useMutation } from "@tanstack/react-query";
import Modal from "react-bootstrap/Modal";
import { ArtistallImg, IMAGE_URL } from "../../../Subpages/MainPage/constant";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [settings, setSettings] = useState({
    popupShow: false,
    popupMsg: "",
    notificationTrigger: false,
  });

  const closePopup = () => {
    setSettings((prev) => ({ ...prev, popupShow: false }));
    localStorage.removeItem("welcome");
  };

  useEffect(() => {
    const getWelcomeMsg = localStorage.getItem("welcome");
    if (getWelcomeMsg && getWelcomeMsg !== "" && getWelcomeMsg !== null) {
      setSettings((prev) => ({
        ...prev,
        popupMsg: getWelcomeMsg,
        popupShow: true,
      }));
    }
  }, []);

  const EventNotification = (eventData) => {
    navigate(eventData.route);
  };

  //new code....
  const { user, setUser, authTokens, setAuthTokens, logoutUser } =
    useContext(AuthContext);
  const token = localStorage.getItem("MDnD_auth");

  const [DashboardCollection, setDashboardCollection] = useState({
    finalData: "",
  });

  //fetch to get dashboard details......................
  useEffect(() => {
    mutationfetchDashBoard.mutate();
  }, []);

  const Getting_Dashboard = () => GET_DASHBOARD_ALL(GET_DASHBOARD);

  const GET_DASHBOARD_ALL = async (GET_DASHBOARD) => {
    let param1 = user ? user.role_id : "";
    let param2 = user.country_id ? user.country_id : 101;
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_DASHBOARD}/${param1}/${param2}`,

        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationfetchDashBoard = useMutation({
    mutationKey: ["get_Dashboard"],
    mutationFn: (fid) => Getting_Dashboard(),

    onSuccess: (data) => {
      if (data.status === "success") {
        setDashboardCollection((prevState) => ({
          ...prevState,
          finalData: data.data,
        }));
        setLoading(true);
      } else {
        setDashboardCollection((prevState) => ({
          ...prevState,
          finalData: "",
        }));
        setLoading(false);
      }
    },
    onError: (error) => {
      setLoading(false);
      setDashboardCollection((prevState) => ({ ...prevState, finalData: "" }));
    },
  });

  //onClick functions...
  const handlePosts = () => {
    navigate("/MyProfile");
  };

  const handleViewForms = () => {
    navigate("/viewForms");
  };

  const handleVideos = () => {
    navigate(`/MyProfile/video`);
  };

  const handleEvents = () => {
    navigate(`/viewEvents`);
  };

  const handleFollowers = () => {
    navigate(`/MyProfile/followers`);
  };

  const handleFollowing = () => {
    navigate(`/MyProfile/following`);
  };

  return (
    <>
      <div className="tab-pane fade show active" id="dashboard" role="tabpanel">
        <h4>
          <b>Dashboard</b>
        </h4>
        <div className="row row-cols-3 row-cols-md-2 row-cols-lg-5 dashboardText">
          {!loading ? (
            <Skeleton height={140} />
          ) : (
            <div className="col my-2">
              <div
                onClick={handlePosts}
                className="shadow-sm rounded p-2 text-center mouseHoverAnim"
              >
                <div className="d-flex justify-content-center">
                  <i className="fas fa-pen-to-square iconPost"></i>
                </div>
                <div>
                  <h3 className="mb-0">
                    <b>{DashboardCollection.finalData?.post_count || 0}</b>
                  </h3>
                </div>
                <div className="">
                  <p className="mb-0">Posts</p>
                </div>
              </div>
            </div>
          )}
          {!loading ? (
            <Skeleton height={140} />
          ) : (
            <div className="col my-2">
              <div onClick={handleFollowers}>
                <div className="shadow-sm rounded p-2 text-center mouseHoverAnim">
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-user-plus iconPost iconRotate"></i>
                  </div>

                  <div>
                    <h3 className="mb-0">
                      <b>
                        {DashboardCollection.finalData?.follower_count || 0}
                      </b>
                    </h3>
                  </div>
                  <div className="">
                    <p className="mb-0">Followers</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!loading ? (
            <Skeleton height={140} />
          ) : (
            <div className="col my-2">
              <div onClick={handleFollowing}>
                <div className="shadow-sm rounded p-2 text-center mouseHoverAnim">
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-user-plus iconPost"></i>
                  </div>

                  <div>
                    <h3 className="mb-0">
                      <b>
                        {DashboardCollection.finalData?.following_count || 0}
                      </b>
                    </h3>
                  </div>
                  <div className="">
                    <p className="mb-0">Following</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!loading ? (
            <Skeleton height={140} />
          ) : (
            <div className="col my-2">
              <div onClick={handleEvents}>
                <div className="shadow-sm rounded p-2 text-center mouseHoverAnim">
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-calendar-days iconPost"></i>
                  </div>

                  <div>
                    <h3 className="mb-0">
                      <b>{DashboardCollection.finalData?.upcoming || 0}</b>
                    </h3>
                  </div>
                  <div className="">
                    <p className="mb-0">Upcoming Events</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!loading ? (
            <Skeleton height={140} />
          ) : (
            <div className="col my-2">
              <div onClick={handleVideos}>
                <div className="shadow-sm rounded p-2 text-center mouseHoverAnim">
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-video iconPost"></i>
                  </div>

                  <div>
                    <h3 className="mb-0">
                      <b>
                        {DashboardCollection.finalData?.videos_uploads || 0}
                      </b>
                    </h3>
                  </div>
                  <div className="">
                    <p className="mb-0">Videos Uploaded</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!loading ? (
            <Skeleton height={140} className="mt-4"/>
          ) : (
            <div className="col my-2">
              <div onClick={handleViewForms}>
                <div className="shadow-sm rounded p-2 text-center mouseHoverAnim">
                  <div className="d-flex justify-content-center">
                    <i className="fas fa-address-card iconPost"></i>
                  </div>

                  <div>
                    <h3 className="mb-0">
                      <b>{DashboardCollection.finalData?.created_form || 0}</b>
                    </h3>
                  </div>
                  <div className="">
                    <p className="mb-0">Forms</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;