import React, { useState, useEffect } from "react";
import "./Landing.css";
import { landingPageImagesCarosal, LandingPageBannerCarosal } from "../global/constants";
import { Helmet } from "react-helmet";
import { Carousel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid'; 
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { ADMIN_BACK_BASEPATH, BASEPATH } from "../config/config";

const Landing = () => {
  const [loadedImages, setLoadedImages] = useState({});
  const [bannerImages, setBannerImages] = useState({});
  const [isLoaded, setIsLoaded] = useState(false); 
   
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { user} = useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => ({ ...prev, [index]: true }));
  };

  const handleLandingBanner = (index) => {
    setBannerImages((prevState) => ({
      ...prevState,
      [index]: true,
    }));
  };

  const navigate = useNavigate();
  const handleClick = (path, index) => {
    if (path.startsWith("http")) {
      window.open(path, "_blank");
    } else if (path === "/SeasonTickets" || path === `/organisers`) {
      navigate(path, { state: { MID: "hello" } });
    } else {
      navigate(path);
    }
  };
  useEffect(() => {
    if (Object.keys(bannerImages).length === LandingPageBannerCarosal.length) {
      setIsLoaded(true);
    }
  }, [bannerImages]);

  // new code..
  const handleBannerClick = async (data) => {
    console.log(data,"data");
    try {
      
      let uniqueId = localStorage.getItem("mdnd_cId");
      if (!uniqueId) {
       
        uniqueId = uuidv4();
        localStorage.setItem("mdnd_cId", uniqueId);  
      }
  
     
      const ipResponse = await axios.get("https://api.ipify.org?format=json");
      const ipAddress = ipResponse.data.ip;
  
      if(ipAddress && uniqueId)
      {const response = await axios.post(`${ADMIN_BACK_BASEPATH}ads/insert_ads_click`, {
        ad_id: 1,  
        session_id: uniqueId ? uniqueId : "",  
        ip_address: ipAddress ? ipAddress : '',  
        login_id : user ? user.login_id : "",
        role_id: user ? user.role_id : ""
      });
  
    }
      
    } catch (error) {
    
      console.error('Error during Axios request:', error);
    }
    if (data?.linkUrl) {
      window.location.href = data.linkUrl;
    }
  };
  

  return (
    <>
      <Helmet>
        <title>MDnD | Discover the world of Indian performing arts.</title>
        <meta
          name="description"
          content="Step onto the global stage of Indian performing arts. Explore upcoming events, buy tickets, connect with talented artists, and immerse yourself in live streamed events and videos."
        />
      </Helmet>
      <div className="section-padding carouselMobDots p-0 mt-5 mt-lg-0" style={{ minHeight: '220px' }}>
      <Carousel
        style={{ minHeight: '220px' }}
        interval={1500}
        indicators={true}
        controls={true}
      >
        {LandingPageBannerCarosal.map((data, index) => (
          <Carousel.Item
            key={index}
            interval={1500}
            style={{ minHeight: '220px' }}
          >
            {!bannerImages[index] ? (
              <Skeleton
                className="skeleton-responsive"
                width="100%"
                baseColor="#ddd"
                highlightColor="#ffffff"
              />
            ) : (
              <>
                <img
                  src={screenWidth < 768 ? data.srcMobile : data.srcDesktop} 
                  alt={`Slide ${index + 1}`}
                  className="d-block w-100"
                  style={{ minHeight: '220px', objectFit: 'cover' }}
                  onLoad={() => handleLandingBanner(index)}
                  //onClick={() => data.linkUrl && (window.location.href = data.linkUrl)}
                  onClick={() => handleBannerClick(data)}
                />
                <div className="landing-caption">
                  <h3 className="text-white text-center">{data.content}</h3>
                </div>
              </>
            )}
            <img
              src={screenWidth < 768 ? data.srcMobile : data.srcDesktop} 
              alt={`Preload Slide ${index + 1}`}
              style={{ display: 'none' }}
              onLoad={() => handleLandingBanner(index)}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>


      <section className="section-padding bg-white mt-0 pt-lg-5 pt-3">
        <div className="container-fluid">
          <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-5">
            {landingPageImagesCarosal.map(({ src, path, title, textColor }, index) => (
              <div className="col mb-2 mb-lg-4 px-2" key={index}>
                <div
                  className="iq-card-geners card-hover-style-two overflow-hidden"
                  style={{ borderRadius: "8px" }}
                >
                  <div className="blockImages position-relative w-100">
                    <div
                      className="img-box rounded position-relative border-0 p-0 bg-transparent"
                      onClick={() => handleClick(path, index)}
                      style={{ cursor: "pointer" }}
                    >
                      {!loadedImages[index] && (
                        <Skeleton
                          className="skeleton-loader-box"
                          width="100%"
                          baseColor="#ddd"
                          highlightColor="#ffffff"
                        />
                      )}
                      <img
                        src={src}
                        alt={`${title.toLowerCase()}-img`}
                        className={`img-fluid object-cover w-100 rounded ${
                          loadedImages[index] ? "" : "d-none"
                        }`}
                        onLoad={() => handleImageLoad(index)}
                      />
                      <div className="blog-description">
                        <h6
                          className="w-100 mb-0 text-wrap"
                          style={{ fontWeight: "400", marginTop: "4px", color: textColor }}
                        >
                          {title}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
export default Landing;
