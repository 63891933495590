import React, { useState,useEffect} from 'react';
import { SelectBox, TextBox, FileUploader } from 'devextreme-react';
import HtmlEditor, { Toolbar, MediaResizing, ImageUpload, Item } from "devextreme-react/html-editor";
import { useFormik } from 'formik';
import MailerValidationSchema from './MailerValidation';
import { tabs, markup, sizeValues, fontValues, headerValues, fontSizeOptions, fontFamilyOptions, headerOptions } from '../../../../components/others';
import { useLocation } from 'react-router-dom';
import { makeGetRequest, makePostRequest } from '../../../../Api/requestMethods';
import { useMutation, useQuery } from '@tanstack/react-query';
import notify from 'devextreme/ui/notify';
import { DURATION, ERROR, SUCCESS } from '../../../../global/ToastMsgConstants.tsx';
import { INDIVIDUAL_MAIL, READ_PERTICULAR_TAMPLATE } from '../../../../Api/api';
import { Button } from 'react-bootstrap';
import { jwtDecode } from 'jwt-decode';
import TemplateDetailsModal from '../Templates/TamplateDetailsModal.js';
import CreateNewTempletes from '../Templates/CreateNewTempletes.js';
import { BASEPATH } from '../../../../config/config.js';
const initialValues = {
  mail_ids: "",
  membership_type: "",
  subject: "",
  template_id: "",
  message: "",
  attachment: null,
};

const Mailer = () => {
  const [mailType, setMailType] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [focusInput, setFocusInput] = useState({
    memberListIn: false,
    hasFocusedIn: false,
  });
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const roleid = user?.role_id;
  const loginid = user?.login_id;
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    resetForm
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: MailerValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {

      const mailArray = values.mail_ids.split(',').map(email => email.trim());
      const submitValues = {
        ...values,
        login_id :loginid,
        role_id : roleid,
        mail_ids :mailArray,
        is_bulk: mailType === 2? true:false,
      };

      if (mailType !== 2) {
        delete submitValues.membership_type;
      } else if (mailType === 2) {
        delete submitValues.mail_ids;
      }

      console.log('Submitted values:', submitValues);
      try{
        await SendMailDetailMutation.mutateAsync(submitValues);
      }catch(error){
        console.log('Error:',error)
      }finally{
        setSubmitting(false);
        resetForm();
      }
      
    }
  });

  const SendMailDetail = async (data) => {
    try {
      const endPoint = await makePostRequest(INDIVIDUAL_MAIL(),data);
      return endPoint;
    } catch (error) {
      throw error;
    }
  };

  const SendMailDetailMutation = useMutation({
    mutationKey: ["mailSend"],
    mutationFn: (mailSendDetail) => SendMailDetail(mailSendDetail),
    onSuccess: (data) => {
      if (data.status === 200) {
        notify(data.message, SUCCESS, DURATION);
      } else {
        notify(data.message, ERROR, DURATION);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  });

  const SelectTamplate = async () => {
    const endPoint = await makeGetRequest(`${BASEPATH}mailer/promo_template/${roleid}/read_all_template_name`);
    return endPoint;
  };

  const { data: selectTamplateData } = useQuery({
    queryKey: ["tamplateselect"],
    queryFn: () => SelectTamplate(),
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  });

  const handleFileChange = (e) => {
    const file = e.value[0];
    setSelectedFileName(file.name);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFieldValue('attachment', reader.result);
    };
    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error);
    };
  };
  // -------------------------------------------------------------------------------------------
  const decodeBase64 = (str) => {
    try {
      return atob(str);
    } catch (e) {
      console.error("Failed to decode base64 string:", e);
      return 'No content available';
    }
  };
 
 
  const GettingTamplate = async(tamplateId)=>{
  try{
    const endPoint = await makeGetRequest(`${READ_PERTICULAR_TAMPLATE()}/${tamplateId}/${loginid}`)
    return endPoint;
  }catch(error){
    throw error;
  }
 }   
  
 const {data:tamplateView, refetch} = useQuery({
  queryKey:["TAMPLATDETAIL"],
  queryFn:()=>GettingTamplate(templateId),
  enabled:false,
  onError:(error)=>{
    notify(error.message, ERROR, DURATION);
  },
  onSuccess:(data)=>{
    notify(data.message,SUCCESS,DURATION);
    setShowModal(true);
  }
 })

 const newTemplates = tamplateView?.data && tamplateView?.data?.status !== 404 
 ? {
     renderedContent: decodeBase64(tamplateView?.data?.content),
     name: tamplateView?.data?.name,
     description: tamplateView?.data?.description,
     created_at: tamplateView?.data?.created_at,
     // Add other properties as needed
   }  
 : {
     content: 'No content available',
     name: 'Unknown Template',
     description: 'No description available',
     created_at: 'Unknown date',
     // Default values for other properties
   };
  const handleTemplateClick=()=>{
      if(values.template_id){
        setShowModal(true);
        setTemplateId(values.template_id)
        refetch();
      }else{
        notify("Please select a tamplate first",ERROR,DURATION)
      }
    };
  // ----------------------------------------------------------------------------------
  const handleCreateClick = () => {
    setShowNewModal(true);
  };

  const handleCloseModal = () => {
    setShowNewModal(false);
  };
// ---------------------------------GettingMemberDetail-----------------------------------------------------------------
const GET_MEMBERS_DETAIL = `mailer/members/${roleid}/read_groups`;

const fetchingMember = async () => {
  if (focusInput.memberListIn) {
    const response = await makeGetRequest(GET_MEMBERS_DETAIL);
    console.log(response); // Log the response
    return response.data;
  }
  return [];
};

const { data: memberDetail } = useQuery({
  queryKey: ["membersDetail"],
  queryFn: () => fetchingMember(),
  enabled: focusInput.memberListIn,
  onError: (errors) => {
    console.log(errors);
  }
});

useEffect(() => {
  console.log('Member detail:', memberDetail); // Log the fetched member details
}, [memberDetail]);
// --------------------------------------------------------------------------------------------------
  return (
    <div className="d-flex flex-column gap-3 tab-content tabBodyBg">
      
      <div className="form-group mb-2 col-4 col-lg-3 col-xl-2">
        <SelectBox
          items={[
            { id: 1, name: "Individual" },
            { id: 2, name: "Bulk" }
          ]}
          label="Mail Type"
          className="dx-field-item-required w-"
          labelMode="floating"
          displayExpr="name"
          valueExpr="id"
          defaultValue={1}
          onValueChanged={(e) => setMailType(e.value)}
        />
      </div>

      <div className="d-flex flex-column gap-2">
        {mailType !== 2 && (
          <div className="form-group mb-2">
            <TextBox
              className="dx-field-item-required"
              required={true}
              mode="floating"
              label="Select Member Email Address"
              value={values.mail_ids}
              onValueChanged={(e) => handleChange({ target: { name: "mail_ids", value: e.value } })}
            />
            {touched.mail_ids && errors.mail_ids && (
              <div className="text-danger">{errors.mail_ids}</div>
            )}
          </div>
        )}
        {mailType === 2 && (
          <div className="form-group mb-2">
            <SelectBox
            onFocusIn={() => setFocusInput((prev) => ({ ...prev, memberListIn: true }))}
            dataSource={memberDetail || []}
            className="dx-field-item-required"
            required="required"
            valueExpr="membership_type"
            displayExpr="membership_type"
              mode="floating"
              label="Select Member Type"
              value={values.membership_type}
              onValueChanged={(e) => handleChange({ target: { name: "membership_type", value: e.value } })}
            />
            {touched.membership_type && errors.membership_type && (
              <div className="text-danger">{errors.membership_type}</div>
            )}
          </div>
        )}
        <div className="text-start">Compose your mail</div>
        <div className="form-group mb-2">
          <TextBox
            className="dx-field-item-required"
            required={true}
            mode="floating"
            label="Subject"
            value={values.subject}
            onValueChanged={(e) => handleChange({ target: { name: "subject", value: e.value } })}
          />
          {touched.subject && errors.subject && (
            <div className="text-danger">{errors.subject}</div>
          )}
        </div>
        <div className="row row-cols-1 row-cols-lg-2 align-items-center">
          <div className="form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              dataSource={selectTamplateData?.data }
              label="Select Template"
              displayExpr="name"
              valueExpr="id"
              value={values.template_id}
              onValueChanged={(e) => handleChange({ target: { name: "template_id", value: e.value } })}
            />
            {touched.template_id && errors.template_id && (
              <div className="text-danger">{errors.template_id}</div>
            )}
          </div>
          <div className="justify-content-start mt-3 iq-button">
            <button className="border-btn rounded mx-2" onClick={handleTemplateClick}>Preview Template</button>
            <button className="btn rounded py-2 mx-2" onClick={handleCreateClick}>Create New Template</button>
          </div>
        </div>
        <div className="form-group mb-2">
          <label className="mb-2">Message&nbsp;<span className="text-danger">*</span></label>
          <div className="widget-container">
            <HtmlEditor
              height="350px"
              defaultValue={markup}
              label="Event Description"
              onValueChange={(e) => handleChange({ target: { name: "message", value: e } })}
            >
              <MediaResizing enabled={true} />
              <ImageUpload tabs={tabs} fileUploadMode="base64" />
              <Toolbar multiline={true}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
                <Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item name="header" acceptedValues={headerValues} options={headerOptions} />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="link" />
                <Item name="image" />
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" />
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" />
                <Item name="deleteTable" />
                <Item name="insertRowAbove" />
                <Item name="insertRowBelow" />
                <Item name="deleteRow" />
                <Item name="insertColumnLeft" />
                <Item name="insertColumnRight" />
                <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>
            {touched.message && errors.message && (
              <div className="text-danger">{errors.message}</div>
            )}
          </div>
        </div>
        <div className="form-group mb-2">
          <div>Attachments</div>
          <FileUploader
            label="Choose File"
            className="dx-field-item-required m-0 p-0"
            labelMode="floating"
            onValueChanged={handleFileChange} 
          />
          {selectedFileName && (
            <div className="mt-2">Selected File: {selectedFileName}</div>
          )}
          {touched.selectFile && errors.selectFile && (
            <div className="text-danger">{errors.selectFile}</div>
          )}
        </div>
        <div className="row row-cols-4 gap-3 justify-content-center align-items-center">
          <button type="button" className="button" onClick={handleSubmit}>Send Mail</button>
        </div>
      </div>
      <CreateNewTempletes show={showNewModal} handleClose={handleCloseModal} />
     {showModal && (
        <TemplateDetailsModal
        show ={showModal}
        handleClose={()=> setShowModal(false)}
        template={newTemplates}
        />
      )}
    </div>
  );
};

export default Mailer;
