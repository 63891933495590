import React from "react";
import noimage from "./noimage.jpg"

export default function Noimage(){

    return(
        <>
         <img src={noimage} alt="No image Found"  style={{height: "auto", width: "100%"}}/>
        </>
    )
}