import React from "react";
import "./FooterTermsPage.css";

const Faqs = () => {
  return (
    <>
      {/* <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">FAQs</h2>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid">
        <div className="mt-5 ">
        <div className="row align-items-center mb-4">
              <div className="text-center">
                <h2>FAQs</h2>
                {/* <p className="text-center">MDnD stands for ‘Music, Dance and Drama’.</p> */}
                {/* <p>
                  On <a href="https://www.mdnd.in">www.mdnd.in</a>, you can-
                </p> */}
              </div>
          </div>
          <div className="container">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    What are the types of performing arts that this website
                    caters to?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="mb-0">
                      {" "}
                      Music, Dance 'n' Drama is in our name. Accordingly, we
                      cover every type of Indian performing art. If you do not
                      find your sub-category in our existing list, please email
                      us at events@mdnd.in.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    As a fan of Music, Dance & Drama, how can I use this site?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="mb-0">
                      As a fan, you can- <br/> a. Discover upcoming events of your
                      interest <br/> b. Buy tickets / Donor Passes. You can buy tickets without registering as 'Fan'. However, an account is automatically created for you using your email ID. You can, at any time, login to your account by entering your email ID in the 'Forgot Password' window. 
                      <br/> c. Watch a variety of interesting content on 'MDnD Records'. 
                      <br/> d. Enjoy our curated collection of Carnatic songs on 'Raga Bank'. <br/>
                      e. Discover artists and organisers and follow them to get updates.
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    As a performing artist, how do I use this website?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="mb-0">
                    If you are a performing artist, we welcome you to use this website to build your fanbase and promote your career for FREE. You can Register as 'Artist, create your profile and share your profile link to friends and family so they can 'Follow' you
                    </p>
                    <p className="mb-0">
                    In your Account, click on 'Create Events' and list all your upcoming events, whether Online (Virtual) or Offline (In person), Ticketed or Free. Before listing, please make sure that the same has not already been listed by your co-artist or Organiser. Your events will then appear in the Events page that is visited by thousands of fans. Presently, ticketing facility on MDnD is available only for events in India.                    </p>
                    <p className="mb-0">
                    There are a host of new and exciting features that will go live soon. Watch this space for announcements.                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    As an organiser of events, how do I use this website?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p className="mb-0">
                      <b>As an organiser, you can register and create your profile for FREE. Once you create your profile, you can do the following-</b>
                    </p>
                    <p className="mb-0">
                    a.	Create Events -In your Account, click on 'Create Events' and list all your upcoming events, whether Online (Virtual) or Offline (In person), Ticketed or Free. Your events will then appear in the Events page that is visited by thousands of fans. Presently, ticketing facility on MDnD is available only for events in India.
                    </p>
                    <p className="mb-0">
                    b.	Create Forms - Forms can be used for getting registrations or enrolments to your programs, contests, etc. In your Account, click on 'Create Forms' and create any form(s) for getting registrations, with or without registration fees. You can share the form link on social media or by WhatsApp. Presently, Forms with Fees can be created only for events in India.
                    </p>
                    <p className="mb-0">
                    c.	Build fanbase - Share link of your profile on social media and increase 'Followers'
                    </p>
                    <p className="mb-0">
                      <b>To experience the power of MDnD, register now!</b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
