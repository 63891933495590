import React from "react";
import NoDataFoundPic from "./NoDataFoundPic.png"

export default function NoDataFound(){

    return(
        <>
         <img src={NoDataFoundPic} alt="No Data Found" style={{ width: 'auto', height: '100%', maxHeight:'700px' }} />
        
        </>
    )
}