import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import "./createSeatingLayout.css";
import { useFormik } from "formik";
import { forms_venueDetails } from "../../../Schemas/Forms/LoginDashboard";
import { capitalizeFirstLetter } from "../../../utils/commonUtils";
import SeatingLayoutForms from "./SeatingLayoutForms";
import { validation_venueDetails } from "../../../Schemas/Events";
import { createVenue } from "../../../Api/api";
import { makePostRequest } from "../../../Api/requestMethods";
import { GlobalContext } from "../../../global/provider";
import {
  SUCCESS,
  ERROR,
  DURATION,
} from "../../../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { SOMETHING_WENT_WRONG } from "../../../Schemas/errorMessageConstants";
import { NumberBox, SelectBox } from "devextreme-react";
import {
  seatingType,
  labelType,
  choiceGST,
  screenSvg,
  statusBlocked,
  statusBooked,
  statusSelected,
  numberedSeatingExpire,
  HcType,
} from "../../../global/constants.js";
import DropDownBox from "devextreme-react/drop-down-box";
import DataGrid, { Selection, Column } from "devextreme-react/data-grid";
import ColorBox from "devextreme-react/color-box";
import { v4 as uuidv4 } from "uuid";
import { makeGetRequest } from "../../../Api/requestMethods";
import { getGstConfig } from "../../../Api/api";
import { useQuery } from "@tanstack/react-query";
import LayoutMiniMap from "./LayoutMiniMap.js";
import { Aisle } from "../../../global/constants.js";
import { seatingLayoutStatus } from "../../../global/constants.js";
import { sortSeatingLayouts } from "../../../utils/commonUtils";

const CreateSeatingLayouts = ({
  propsDataSource,
  setDataSourceEvents,
  isticketpurchase,
  isViewEvent,
  lookupDetails,
}) => {
  const seatingLayoutRef = useRef(null);
  const scrollTimeout = useRef(null);
  const [activeKey, setActiveKey] = useState(null);
  const [scrollPosition, setScrollPosition] = useState({
    top: 0,
    left: 0,
    clientWidth: 0,
    clientHeight: 0,
    scrollWidth: 0,
    scrollHeight: 0,
  });
  const {
    userDetails,
    eventsDatasource,
    setEventDataSource,
    dataSource,
    setDataSource,
  } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    seatingType: "",
    labelType: "",
    amount: "",
    columnDetails: [],
    currentSelectedType: "",
    colorCode: "",
    status: "",
    gst_tax: 0,
    hc_charges: 0,
    hc_gst_tax: 0,
    hc_type: "amount",
  });
  const [settings, setSettings] = useState({
    trigger: 0,
    countryFocusIn: false,
    choosedSeatingValue: "",
    isScrolling: false,
    isVenuDetailsOpened: "1",
  });

  const scrollEvent = () => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }
    setSettings((prev) => ({ ...prev, isScrolling: true }));
    scrollTimeout.current = setTimeout(() => {
      setSettings((prev) => ({ ...prev, isScrolling: false }));
    }, 550);
  };

  useEffect(() => {
    setDataSource((prev) => ({
      ...prev,
      rowData: "",
      columnData: "",
      floorDetails: [],
      seatColorings: [],
      rowLables: "",
      aisleColumns: "",
      position: "",
      layout: {},
      stateDataSource: "",
      cityDataSource: "",
      seatingTypeDataSource: "",
      seatingLableDataSource: "",
      seatingColumnDetailsDs: "",
      excludedRowLables: [],
    }));
  }, []);

  const getObjectKeyOrder = (dataSource) => {
    try {
      const orderedLookupDataSource = [];
      Object.keys(dataSource).map((parentKey, index) => {
        Object.keys(dataSource[parentKey]).map((childKey) => {
          if (childKey !== "AisleDetails")
            orderedLookupDataSource.push({
              [Object.keys(dataSource)[index]]: childKey,
            });
        });
      });
      setDataSource((prev) => ({
        ...prev,
        numbered_layout_lookup: orderedLookupDataSource,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (propsDataSource && !isticketpurchase) {
      const newSeatingType = Object.keys(propsDataSource).map(
        (data, index) => ({
          id: index + 1,
          seatingType: data,
        })
      );
      const sortedDs = sortSeatingLayouts(lookupDetails, propsDataSource);
      setDataSource((prev) => ({
        ...prev,
        layout: sortedDs,
        seatingTypeDataSource: newSeatingType,
        layoutLookupDetails: lookupDetails,
      }));
    }

    if (propsDataSource && isticketpurchase) {
      const sortedDs = sortSeatingLayouts(lookupDetails, propsDataSource);
      setDataSource((prev) => ({
        ...prev,
        layout: sortedDs,
        layoutLookupDetails: lookupDetails,
      }));
    }
  }, [propsDataSource]);

  const handleInputChange = (name, value) => {
    setFormData((prev) => {
      const updatedFormData = {
        ...prev,
        currentSelectedType: name,
        [name]: value,
      };
      return updatedFormData;
    });
  };
  useEffect(() => {
    if (
      formData.seatingType !== "" &&
      formData.currentSelectedType === seatingType
    ) {
      const selectedSeatingType =
        dataSource && dataSource.layout[formData.seatingType];
      const newLabelType = Object.entries(selectedSeatingType)
        .filter(([_, seats]) =>
          seats.some((seat) => seat.seat !== null && seat.seat !== "")
        )
        .map(([label], index) => ({
          id: index + 1,
          seatingLabel: label,
        }));
      const filteredAisle =
        newLabelType &&
        newLabelType.filter((data) => data.seatingLabel !== "AisleDetails");

      setDataSource((prev) => ({
        ...prev,
        seatingLableDataSource: filteredAisle,
        seatingColumnDetailsDs: "",
      }));
    }

    if (
      formData.labelType !== "" &&
      formData.currentSelectedType === labelType
    ) {
      const selectedLabelType =
        dataSource &&
        dataSource.layout[formData.seatingType][formData.labelType];
      const newColumnDetails =
        selectedLabelType &&
        selectedLabelType.map((data, index) => ({
          id: index + 1,
          columnDetails: `${formData.labelType}-${data.seat}`,
          columnDetailsValue: `${data.seat}`,
          amount: data.amount,
          colorCode: data.colorCode,
          status: data.status,
        }));
      setDataSource((prev) => ({
        ...prev,
        seatingColumnDetailsDs: newColumnDetails,
      }));
    }
  }, [
    formData.seatingType,
    formData.labelType,
    formData.currentSelectedType,
    formData.colorCode,
    formData.status,
  ]);

  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isValid,
    setSubmitting,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: forms_venueDetails,
    validationSchema: validation_venueDetails,
    onSubmit: async (values, { setSubmitting }) => {
      if (isValid) {
        await submitLayout();
      } else {
        handleErrorAction();
      }
      setSubmitting(false);
    },
  });

  const initializeLayoutNumbers = (floorDetails) => {
    try {
      const layout = {};
      floorDetails.forEach((floor) => {
        const {
          floorName,
          rowName,
          aisleRows,
          numberOfColumns,
          SeatNumberingFormat,
        } = floor;
        if (SeatNumberingFormat === "alphabetic") return;
        const rowLabels = rowName.split(",").map((label) => label.trim());
        const aisleRowsArray = aisleRows
          ? aisleRows.split(",").map((aisleRow) => parseInt(aisleRow.trim()))
          : [];
        layout[floorName] = {};
        rowLabels.forEach((label, index) => {
          const rowArray = Array.from(
            { length: numberOfColumns },
            (_, colIndex) => ({
              seat: `${colIndex + 1}`,
              row_label: `${label}`,
              isAisle: false,
              status: "",
              amount: null,
              isFullyAisle: false,
              isAlphabetic: false,
              colorCode: "",
              gst_tax: 0,
              hc_charges: 0,
              hc_gst_tax: 0,
              hc_type: "amount",
              ticket_quantity: 1,
              seat_label: Aisle,
              floorName: floorName,
              unique_id: uuidv4(),
            })
          );
          layout[floorName][label] = rowArray;
          layout[floorName]["AisleDetails"] = aisleRowsArray;
        });
      });

      return layout;
    } catch (error) {
      console.error("Error in initializeLayout:", error);
      return null;
    }
  };

  const initializeLayoutAlphabets = (floorDetails) => {
    try {
      const layout = {};
      floorDetails.forEach((floor) => {
        const {
          floorName,
          rowName,
          aisleRows,
          numberOfColumns,
          SeatNumberingFormat,
        } = floor;
        if (SeatNumberingFormat !== "alphabetic") return;
        layout[floorName] = {};
        const rowLabels = rowName.split(",").map((name) => name.trim());
        const aisleRowsArray = aisleRows
          ? aisleRows.split(",").map((row) => row.trim())
          : [];
        rowLabels.forEach((label, rowIndex) => {
          const rowArray = Array.from(
            { length: numberOfColumns },
            (_, colIndex) => ({
              seat: `${colIndex + 1}`,
              label: `${label}`,
              row_label: `${label}`,
              isAisle: false,
              status: "",
              amount: null,
              isFullyAisle: false,
              isAlphabetic: true,
              colorCode: "",
              gst_tax: 0,
              hc_charges: 0,
              hc_gst_tax: 0,
              seat_label: Aisle,
              hc_type: "amount",
              ticket_quantity: 1,
              unique_id: uuidv4(),
            })
          );
          layout[floorName][label] = rowArray;
          layout[floorName]["AisleDetails"] = aisleRowsArray;
        });
        setDataSource((prev) => ({
          ...prev,
          excludedRowLables: aisleRowsArray.map((row) => `Aisle ${row}`),
        }));
      });

      return layout;
    } catch (error) {
      console.error("Error in initializeLayout:", error);
      return null;
    }
  };

  const initializeCombinedLayout = (floorDetails) => {
    try {
      const numericLayout = initializeLayoutNumbers(floorDetails);
      const alphabeticLayout = initializeLayoutAlphabets(floorDetails);
      const combinedLayout = { ...numericLayout, ...alphabeticLayout };
      getObjectKeyOrder(combinedLayout);
      return combinedLayout;
    } catch (error) {
      console.error("Error in initializeCombinedLayout:", error);
      return null;
    }
  };

  useEffect(() => {
    if (dataSource.floorDetails.length > 0) {
      const layout = initializeCombinedLayout(dataSource.floorDetails);
      setDataSource((prev) => ({ ...prev, layout }));
    }
  }, [dataSource.floorDetails, settings.trigger]);

  // FOR ODD EVEN MANUAL LABLE AND STATUS UPDATE
  const onLableChange = (
    floorName,
    rowLabel,
    colIndex,
    seatLabel,
    isInputField
  ) => {
    if (isInputField) {
      setDataSource((prevDataSource) => {
        const newLayout = { ...prevDataSource.layout };
        newLayout[floorName][rowLabel][colIndex].seat_label = seatLabel;
        return { ...prevDataSource, layout: newLayout };
      });
    } else {
      setDataSource((prevDataSource) => {
        const newLayout = { ...prevDataSource.layout };
        newLayout[floorName][rowLabel][colIndex].status = seatLabel;
        return { ...prevDataSource, layout: newLayout };
      });
    }
  };

  const handleCheckboxChange = (floorName, rowLabel, colIndex, seatDetails) => {
    if (seatDetails.status === statusBlocked) {
      return;
    }

    if (!propsDataSource && !isticketpurchase) {
      setDataSource((prevDataSource) => {
        // Preserve the original floor order from the previous state
        const floorOrder = Object.keys(prevDataSource.layout);

        // Create a new layout object while maintaining object references
        const newLayout = { ...prevDataSource.layout };
        newLayout[floorName] = { ...newLayout[floorName] };
        newLayout[floorName][rowLabel] = [...newLayout[floorName][rowLabel]];
        newLayout[floorName][rowLabel][colIndex] = {
          ...newLayout[floorName][rowLabel][colIndex],
          isAisle: !newLayout[floorName][rowLabel][colIndex].isAisle,
        };

        // Reconstruct the layout in the original order
        const orderedLayout = {};
        floorOrder.forEach((floor) => {
          orderedLayout[floor] = newLayout[floor];
        });

        return {
          ...prevDataSource,
          layout: orderedLayout,
        };
      });
    }

    if (propsDataSource && isticketpurchase) {
      setDataSource((prevDataSource) => {
        // Preserve the original floor order
        const floorOrder = Object.keys(prevDataSource.layout);

        // Create a new layout object while maintaining object references
        const newLayout = { ...prevDataSource.layout };
        newLayout[floorName] = { ...newLayout[floorName] };
        newLayout[floorName][rowLabel] = [...newLayout[floorName][rowLabel]];

        const currentStatus = newLayout[floorName][rowLabel][colIndex].status;
        newLayout[floorName][rowLabel][colIndex] = {
          ...newLayout[floorName][rowLabel][colIndex],
          status: currentStatus === "" ? statusSelected : "",
        };

        // Reconstruct the layout in the original order
        const orderedLayout = {};
        floorOrder.forEach((floor) => {
          orderedLayout[floor] = newLayout[floor];
        });

        return {
          ...prevDataSource,
          layout: orderedLayout,
        };
      });

      if (seatDetails?.amount !== null) {
        setEventDataSource((prev) => {
          const currentTickets = [...prev.numberedTicketAmountDetails];
          const seatIndex = currentTickets.findIndex(
            (item) => item.unique_id === seatDetails.unique_id
          );

          let updatedTicketDetails;
          let updatedAmount;

          if (seatIndex === -1) {
            // Add new ticket while preserving the structure
            updatedTicketDetails = [
              ...currentTickets,
              {
                floorName,
                rowLabel,
                ...seatDetails,
              },
            ];
            updatedAmount =
              (prev.numberedChoosedTicketAmount || 0) + seatDetails.amount;
          } else {
            // Remove ticket while maintaining the original order
            updatedTicketDetails = currentTickets.filter(
              (_, index) => index !== seatIndex
            );
            updatedAmount =
              (prev.numberedChoosedTicketAmount || 0) - seatDetails.amount;
          }

          return {
            ...prev,
            numberedTicketAmountDetails: updatedTicketDetails,
            numberedChoosedTicketAmount: updatedAmount,
          };
        });
      }

      setDataSourceEvents(floorName, rowLabel, colIndex, seatDetails?.seat);
    }
  };
  const addDenominations = (e) => {
    e.preventDefault();
    try {
      const {
        seatingType,
        labelType,
        columnDetails,
        amount,
        colorCode,
        status,
        hc_charges,
        gst_tax,
        hc_gst_tax,
        hc_type,
      } = formData;
      if (
        seatingType &&
        labelType &&
        columnDetails &&
        columnDetails?.length > 0 &&
        amount !== null
      ) {
        setDataSource((prev) => {
          const newLayout = { ...prev.layout };
          const updateArray = newLayout[seatingType][labelType];
          if (updateArray) {
            newLayout[seatingType][labelType] = updateArray.map((data) =>
              columnDetails.includes(data.seat.toString())
                ? {
                    ...data,
                    amount,
                    colorCode,
                    status,
                    hc_charges,
                    gst_tax,
                    hc_gst_tax,
                    hc_type,
                  }
                : data
            );

            if (isViewEvent !== true) {
              const modifiedEntries = newLayout[seatingType][labelType]
                .filter((data) => columnDetails.includes(data.seat.toString()))
                .map((data) => ({
                  floor_name: seatingType,
                  unique_id: data.unique_id,
                  status: data.status,
                  row_label: data.row_label,
                  seat_label: data.seat_label,
                  amount: data.amount,
                  colorCode: data.colorCode,
                  gst_tax: data.gst_tax,
                  hc_charges: data.hc_charges,
                  hc_gst_tax: data.hc_gst_tax,
                  hc_type: data.hc_type,
                  isAisle: data.isAisle,
                  numbered_id: data.numbered_id,
                }));
              setEventDataSource((prev) => {
                const existingDetails = prev.updatedNumberedDetails || [];
                const detailsMap = existingDetails.reduce((acc, item) => {
                  acc[item.unique_id] = item;
                  return acc;
                }, {});
                const updatedDetails = modifiedEntries.map((data) => {
                  if (detailsMap[data.unique_id]) {
                    return { ...detailsMap[data.unique_id], ...data };
                  }
                  return data;
                });
                const mergedDetails = [
                  ...updatedDetails,
                  ...existingDetails.filter(
                    (item) =>
                      !modifiedEntries.some(
                        (data) => data.unique_id === item.unique_id
                      )
                  ),
                ];
                return {
                  ...prev,
                  updatedNumberedDetails: mergedDetails,
                };
              });
            }
          }

          const newSeatingColumnDetailsDs = (
            prev.seatingColumnDetailsDs || []
          ).map((data) =>
            columnDetails.includes(data?.columnDetailsValue.toString())
              ? {
                  ...data,
                  amount,
                  colorCode,
                  status,
                  hc_charges,
                  gst_tax,
                  hc_gst_tax,
                  hc_type,
                }
              : data
          );
          return {
            ...prev,
            layout: newLayout,
            seatingColumnDetailsDs: newSeatingColumnDetailsDs,
          };
        });
        setFormData((prev) => ({
          ...prev,
          columnDetails: "",
          amount: "",
          colorCode: "",
          status: "",
          hc_charges: 0,
          gst_tax: 0,
          hc_gst_tax: 0,
          hc_type: "amount",
        }));
        setDataSourceEvents((prev) => ({
          ...prev,
          overAllSeatingLayoutDs: dataSource.layout,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitLayout = async () => {
    values.seating_layout = dataSource?.layout;
    values.role_id = userDetails?.profileDetails?.role_id;
    values.seating_details = dataSource?.floorDetails;
    values.seating_order_lookup = dataSource?.numbered_layout_lookup;
    try {
      setSubmitting(true);
      const response = await makePostRequest(createVenue, values);
      if (response && response.status === SUCCESS) {
        notify(response.message, SUCCESS, DURATION);
        resetForm();
        setDataSource((prev) => ({
          ...prev,
          rowData: "",
          columnData: "",
          floorDetails: [],
          seatColorings: [],
          rowLables: "",
          aisleColumns: "",
          position: "",
          layout: {},
          stateDataSource: "",
          cityDataSource: "",
          seatingTypeDataSource: "",
          seatingLableDataSource: "",
          seatingColumnDetailsDs: "",
          excludedRowLables: [],
        }));
      } else {
        notify(response.message || SOMETHING_WENT_WRONG, ERROR, DURATION);
      }
    } catch (error) {
      setSubmitting(false);
      throw error;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(e);
  };
  const handleErrorAction = () => {
    setActiveKey("0");
  };

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setActiveKey("0");
    }
  }, [errors]);
  const onDataSaved = () => {
    setSettings((prev) => ({
      ...prev,
      trigger: prev.trigger + 1,
    }));
  };

  const handleDataGridSelection = ({ selectedRowKeys }) => {
    handleInputChange("columnDetails", selectedRowKeys);
  };

  // GST config
  const gstConfigEvent = async () => await makeGetRequest(getGstConfig);
  const { data: gstConfig } = useQuery({
    queryKey: ["__gstConfig__"],
    queryFn: () => gstConfigEvent(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    try {
      if (
        eventsDatasource &&
        eventsDatasource?.numberedEventTempBookedDetails &&
        eventsDatasource?.numberedEventTempBookedDetails.length > 0
      ) {
        eventsDatasource?.numberedEventTempBookedDetails.map((data) => {
          const currentTime = new Date();
          const seatTimer = new Date(data.timer);
          const timeDifference = (currentTime - seatTimer) / 1000 / 60;
          if (timeDifference <= numberedSeatingExpire) {
            setDataSource((prevDataSource) => {
              const newLayout = { ...prevDataSource.layout };
              if (
                newLayout[data.floor_name] &&
                newLayout[data.floor_name][data.row_name]
              ) {
                const seatToUpdate = newLayout[data.floor_name][
                  data.row_name
                ].find((filterData) => filterData.seat === data.seat_number);

                if (seatToUpdate) {
                  seatToUpdate.status = statusBooked;
                }
              } else {
                console.error(
                  `Floor name ${data.floor_name} or row name ${data.row_name} not found in layout.`
                );
              }
              return { ...prevDataSource, layout: newLayout };
            });
          }
        });
      }
    } catch (error) {
      console.log(error, "Error occurred while updating seat status.");
    }
  }, [eventsDatasource.numberedEventTempBookedDetails]);

  useEffect(() => {
    setEventDataSource((prev) => ({
      ...prev,
      numberedTicketAmountDetails: [],
      numberedChoosedTicketAmount: null,
    }));
  }, []);

  const colorCodeCellRender = (cellData) => {
    const colorCode = cellData.data.colorCode || "";
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: colorCode || "",
            border: `${colorCode ? "1px solid #ddd" : ""}`,
            marginRight: 8,
            borderRadius: "50%",
          }}
        ></div>
        <span>{colorCode || ""}</span>
      </div>
    );
  };

  const dataGridRender = () => {
    return (
      <DataGrid
        dataSource={dataSource.seatingColumnDetailsDs || []}
        keyExpr="columnDetailsValue"
        selectedRowKeys={formData.columnDetails}
        onSelectionChanged={handleDataGridSelection}
        width={"100%"}
      >
        <Selection mode="multiple" />
        <Column dataField="columnDetails" caption="Column Details" />
        <Column dataField="amount" caption="Amount" />
        <Column dataField="status" caption="Status" visible={!isViewEvent} />
        <Column
          dataField="colorCode"
          caption="Color"
          cellRender={colorCodeCellRender}
        />
      </DataGrid>
    );
  };

  const gstData = (gstConfig && gstConfig.data && gstConfig?.data[0]) || null;

  const handleScroll = () => {
    if (seatingLayoutRef.current) {
      requestAnimationFrame(() => {
        const {
          scrollTop,
          scrollLeft,
          clientWidth,
          clientHeight,
          scrollWidth,
          scrollHeight,
        } = seatingLayoutRef.current;
        setScrollPosition({
          top: scrollTop,
          left: scrollLeft,
          clientWidth,
          clientHeight,
          scrollWidth,
          scrollHeight,
        });
      });
    }
  };

  const handleResize = () => {
    if (seatingLayoutRef.current) {
      handleScroll();
    }
  };

  useLayoutEffect(() => {
    const layoutElement = seatingLayoutRef.current;
    if (layoutElement) {
      layoutElement.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleResize);
      handleScroll();
    }
    return () => {
      if (layoutElement) {
        layoutElement.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const minimapBox = {
    x:
      (scrollPosition.left / scrollPosition.scrollWidth) *
      eventsDatasource.svgWidth,
    y:
      (scrollPosition.top / scrollPosition.scrollHeight) *
      eventsDatasource.svgHeight,
    width:
      (scrollPosition.clientWidth / scrollPosition.scrollWidth) *
      eventsDatasource.svgWidth,
    height:
      (scrollPosition.clientHeight / scrollPosition.scrollHeight) *
      eventsDatasource.svgHeight,
  };

  const seatingLayout = () => {
    return (
      <div className={!isViewEvent ? "" : ""}>
        {propsDataSource && !isticketpurchase && (
          <div className="row">
            <div className={!isViewEvent ? "col-lg-6" : "col-lg-3"}>
              <SelectBox
                label="Seating Type"
                labelMode="floating"
                dataSource={dataSource.seatingTypeDataSource || []}
                displayExpr="seatingType"
                valueExpr="seatingType"
                onValueChanged={(e) =>
                  handleInputChange("seatingType", e.value)
                }
                value={formData.seatingType}
              />
            </div>

            <div className={!isViewEvent ? "col-lg-6" : "col-lg-3"}>
              <SelectBox
                label="Label"
                labelMode="floating"
                displayExpr="seatingLabel"
                valueExpr="seatingLabel"
                dataSource={dataSource.seatingLableDataSource || []}
                onValueChanged={(e) => handleInputChange("labelType", e.value)}
                value={formData.labelType}
              />
            </div>

            <div className={!isViewEvent ? "col-lg-6 mt-2" : "col-lg-6 mt-2"}>
              <DropDownBox
                value={formData.columnDetails}
                deferRendering={false}
                displayExpr="columnDetails"
                valueExpr="columnDetailsValue"
                showClearButton={true}
                placeholder="select column Details"
                dataSource={dataSource.seatingColumnDetailsDs || []}
                onValueChanged={(e) =>
                  handleInputChange("columnDetails", e.value)
                }
                contentRender={dataGridRender}
                width={"auto"}
              />
            </div>

            {isViewEvent !== true && (
              <div className={!isViewEvent ? "col-lg-6" : "col-lg-4"}>
                <SelectBox
                  label="Status"
                  labelMode="floating"
                  displayExpr="name"
                  valueExpr="value"
                  dataSource={seatingLayoutStatus || []}
                  onValueChanged={(e) => handleInputChange("status", e.value)}
                  value={formData.status}
                />
              </div>
            )}

            <div className={!isViewEvent ? "col-lg-6" : "col-lg-4"}>
              <NumberBox
                label="Amount"
                labelMode="floating"
                value={formData.amount}
                onValueChanged={(e) => handleInputChange("amount", e.value)}
              />
            </div>

            <div className={!isViewEvent ? "col-lg-6" : "col-lg-4"}>
              <SelectBox
                label="GST tax"
                labelMode="floating"
                displayExpr="text"
                valueExpr="id"
                dataSource={
                  [
                    { id: 0, text: "0%" },
                    {
                      id: gstData?.gst_max || choiceGST,
                      text: `${gstData?.gst_max || choiceGST}%`,
                    },
                  ] || []
                }
                onValueChanged={(e) => handleInputChange("gst_tax", e.value)}
                value={formData.gst_tax}
              />
            </div>

            <div className={!isViewEvent ? "col-lg-3 " : "col-lg-4 mt-2"}>
              <SelectBox
                label="HC Type"
                labelMode="floating"
                displayExpr="type"
                valueExpr="value"
                dataSource={HcType || []}
                onValueChanged={(e) => handleInputChange("hc_type", e.value)}
                value={formData.hc_type}
              />
            </div>

            <div className={!isViewEvent ? "col-lg-3 " : "col-lg-4 mt-2"}>
              <NumberBox
                label="HC Value (Enter % / amount)"
                labelMode="floating"
                value={formData.hc_charges}
                min={0}
                max={formData.hc_type === "percentage" ? 100 : null}
                onValueChanged={(e) => handleInputChange("hc_charges", e.value)}
              />
            </div>

            <div className={!isViewEvent ? "col-lg-3 " : "col-lg-4"}>
              <NumberBox
                label="Handling charge Gst (Enter % )"
                labelMode="floating"
                value={formData.hc_gst_tax}
                min={0}
                max={100}
                onValueChanged={(e) => handleInputChange("hc_gst_tax", e.value)}
              />
            </div>

            <div className={!isViewEvent ? "col-lg-3 mt-2" : "col-lg-4 mt-2"}>
              <ColorBox
                value={formData.colorCode || "#3f00ff"}
                defaultValue="#3f00ff"
                onValueChanged={(e) => handleInputChange("colorCode", e.value)}
              />
            </div>
            <div>
              <button
                className="button mt-2"
                type="button"
                onClick={(e) => addDenominations(e)}
              >
                Add
              </button>
            </div>
          </div>
        )}

        <div>
          {<div className="screenSvg">{screenSvg}</div>}
          <div
            id="seating-layout"
            className="seatingLayoutScroll w-100"
            ref={seatingLayoutRef}
            onScroll={scrollEvent}
          >
            {settings && settings?.isScrolling && isticketpurchase && (
              <div
                style={{
                  position: "fixed",
                  top: "160px",
                  right: "20px",
                  zIndex: 999,
                  width: "80%",
                }}
              >
                <LayoutMiniMap
                  dataSource={dataSource}
                  minimapBox={minimapBox}
                />
              </div>
            )}

            <div className="seating-layout-scrollable m-auto">
              {Object.keys(dataSource.layout).map((floorName, index) => (
                <div className="overflowScrollBar" key={index}>
                  <div className="d-flex justify-content-start SeatingFloorNameborder">
                    <p className="SeatingFloorName mb-2 me-5">
                      {capitalizeFirstLetter(floorName)}
                    </p>
                    <div className="d-flex mt-md-0 mt-2">
                      {Object.keys(dataSource.layout[floorName]).map(
                        (rowLabel, rowIndex) => {
                          const row = dataSource.layout[floorName][rowLabel];
                          const displayedLegends = [];

                          return row.map((seat) => {
                            if (seat.amount > 0) {
                              const color = seat.colorCode
                                ? seat.colorCode
                                : "#3f00ff";
                              const amount = seat.amount;
                              if (
                                !displayedLegends.some(
                                  (legend) =>
                                    legend.color === color &&
                                    legend.amount === amount
                                )
                              ) {
                                displayedLegends.push({ color, amount });

                                return (
                                  <div
                                    key={seat.unique_id}
                                    className="d-flex align-items-center me-2"
                                  >
                                    <span
                                      className="Legend-colorBox me-1"
                                      style={{
                                        backgroundColor: color,
                                      }}
                                    ></span>
                                    <span className="Legend-label me-2">
                                      ₹{amount}
                                    </span>
                                    <div className="vr h-50 d-flex align-items-center mt-2"></div>
                                  </div>
                                );
                              }
                              return null;
                            }
                            return null;
                          });
                        }
                      )}
                    </div>
                  </div>

                  {/* Seating Layout */}
                  <table className="mx-auto">
                    {Object.keys(dataSource.layout[floorName]).map(
                      (rowLabel, rowIndex) => {
                        const row = dataSource.layout[floorName][rowLabel];
                        const rowAisleDetails =
                          dataSource.layout[floorName]["AisleDetails"];

                        const isAislePresent = rowAisleDetails.some(
                          (aisleDetail) =>
                            String(aisleDetail) === String(rowLabel)
                        );
                        const hasValidRowLabel = row.some(
                          (seat) => seat.row_label !== undefined
                        );
                        return (
                          <>
                            <tr key={rowLabel}>
                              <td>
                                {hasValidRowLabel &&
                                  row &&
                                  row.map((seat, columnIndex) => (
                                    <React.Fragment key={columnIndex}>
                                      {columnIndex === 0 && (
                                        <span
                                          style={{
                                            color: "#3f00ff",
                                            marginRight: "10px",
                                          }}
                                          className="row-label"
                                        >
                                          {seat.row_label}
                                        </span>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </td>

                              {row.map((seat, colIndex) => (
                                <td
                                  key={colIndex}
                                  className={`seatingColumn  ${
                                    propsDataSource !== undefined
                                      ? ""
                                      : "d-none"
                                  }`}
                                  style={{
                                    visibility:
                                      seat.seat_label === "Aisle" ||
                                      seat.seat_label === "aisle"
                                        ? "hidden"
                                        : "",
                                    display: hasValidRowLabel
                                      ? "d-block"
                                      : "none",
                                  }}
                                >
                                  <div
                                    className={`seatingBoxOutside d-flex justify-content-start ${
                                      seat.isAisle
                                        ? propsDataSource
                                          ? "addSeatColoringWithCondition"
                                          : "addSeatColoring"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (seat.status !== statusBooked) {
                                        handleCheckboxChange(
                                          floorName,
                                          rowLabel,
                                          colIndex,
                                          seat
                                        );
                                      }
                                    }}
                                    style={{
                                      visibility: seat.isFullyAisle
                                        ? "hidden"
                                        : "",
                                    }}
                                  >
                                    <div
                                      className={`${
                                        seat.colorCode
                                          ? `1px solid ${seat.colorCode}`
                                          : "seatingBoxInside"
                                      }`}
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        border: `${
                                          seat.status === statusBooked
                                            ? ""
                                            : seat.colorCode
                                            ? `1px solid ${seat.colorCode}`
                                            : seat.status === ""
                                            ? "1px solid #3f00ff"
                                            : ""
                                        }`,
                                        cursor:
                                          seat.status === statusBooked ||
                                          seat.status === statusBlocked
                                            ? "not-allowed"
                                            : "pointer",
                                        fontSize: "10px",
                                        verticalAlign: "top",
                                        display: "inline-block",
                                        textAlign: "center",
                                        lineHeight: "25px",
                                        borderRadius: "2px",
                                        backgroundColor:
                                          seat.status === statusBlocked
                                            ? "rgb(0, 0, 0)"
                                            : seat.status === ""
                                            ? ""
                                            : seat.status === "Selected"
                                            ? "rgb(63 0 255)"
                                            : "#dddddd",

                                        color:
                                          seat.status === statusBlocked
                                            ? "#ffffff"
                                            : seat.status === ""
                                            ? ""
                                            : "#ffffff",
                                      }}
                                    >
                                      {seat?.seat_label}
                                    </div>
                                  </div>
                                </td>
                              ))}

                              {/* Seating Columns */}
                              {row.map((seat, colIndex) => {
                                if (seat.row_label === undefined) {
                                  return null;
                                }
                                return (
                                  <td
                                    key={colIndex}
                                    className={`seatingColumn p-1 ${
                                      propsDataSource === undefined
                                        ? ""
                                        : "d-none"
                                    }`}
                                  >
                                    <input
                                      style={{
                                        width: "40px",
                                        visibility: seat.isFullyAisle
                                          ? "hidden"
                                          : "",
                                      }}
                                      value={seat?.seat_label}
                                      onChange={(e) =>
                                        onLableChange(
                                          floorName,
                                          rowLabel,
                                          colIndex,
                                          e.target.value,
                                          true
                                        )
                                      }
                                    />
                                  </td>
                                );
                              })}
                            </tr>

                            {isAislePresent && hasValidRowLabel && (
                              <tr
                                key={`aisle-${rowLabel}`}
                                className="aisle-row"
                              >
                                <td
                                  colSpan={row.length}
                                  style={{ visibility: "hidden" }}
                                >
                                  Aisle Space
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      }
                    )}
                  </table>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      <>
        <div
          className={`content-block ${
            !propsDataSource && !isticketpurchase ? "" : "condtionScreenSeating"
          }`}
        >
          {!propsDataSource && !isticketpurchase && (
            <SeatingLayoutForms
              settings={settings}
              dataSource={dataSource}
              setSettings={setSettings}
              setFieldValue={setFieldValue}
              values={values}
              setDataSource={setDataSource}
              submitLayout={submitLayout}
              handleBlur={handleBlur}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              onDataSaved={onDataSaved}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
            />
          )}
        </div>
        {seatingLayout()}

        {!propsDataSource &&
          !isticketpurchase &&
          dataSource.floorDetails &&
          dataSource.floorDetails.length > 0 && (
            <form onSubmit={handleFormSubmit}>
              <div className="d-flex justify-content-end">
                <button
                  className="button"
                  onClick={handleFormSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          )}
      </>
    </React.Fragment>
  );
};

export default CreateSeatingLayouts;
