import React from "react";
import { HeadProvider, Meta } from 'react-head';
import { BASEPATH } from "../../config/config";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, } from 'react-share';

export default function ShareToSocialMedia_organiser({ postdescr, postimage, shareUrl }) {
  // useEffect(() => {

  //   const ogUrl = document.querySelector('meta[property="og:url"]');
  //   const ogType = document.querySelector('meta[property="og:type"]');
  //   const ogTitle = document.querySelector('meta[property="og:title"]');
  //   const ogDescription = document.querySelector('meta[property="og:description"]');
  //   const ogImage = document.querySelector('meta[property="og:image"]');

  //   if (ogUrl) ogUrl.setAttribute("content", shareUrl);
  //   if (ogType) ogType.setAttribute("content", "website");
  //   if (ogTitle) ogTitle.setAttribute("content", postdescr);
  //   if (ogDescription) ogDescription.setAttribute("content", postdescr);
  //   if (ogImage) ogImage.setAttribute("content", postimage);
  // }, [postdescr, postimage, shareUrl]);

  //new code

  const handleOpenWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? `whatsapp://send?text=${encodeURIComponent(shareUrl)}`
      : `https://web.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`;
    window.open(whatsappUrl, '_blank');
  };

  const isUrl = (text) => {
    try {
      new URL(text);
      return true;
    } catch (e) {
      return false;
    }
  };

  const ogUrl = isUrl(postdescr) ? postdescr : shareUrl;

  const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputRef.current.value).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  return (
    <>
     {/* <HeadProvider>
  <Meta property="og:url" content={ogUrl} />
  <Meta property="og:type" content="website" />
  <Meta property="og:title" content={postdescr} />
  <Meta property="og:description" content={postdescr} />
  <Meta property="og:image" content={postimage} />
  <Meta property="og:video" content={ogUrl} />
  <Meta property="og:image:width" content="600" />
  <Meta property="og:image:height" content="315" />
  <Meta property="og:video:width" content="640" />
  <Meta property="og:video:height" content="360" />
  <Meta property="og:video:type" content="text/html" />
</HeadProvider> */}

      <div>
      <div className="d-flex gap-3 justify-content-around mx-3">
      {isUrl(postdescr) ? (
          <>
            
            <WhatsappShareButton style={{ color: "green" }} url={postdescr}>
            <i
                className="fa-brands fa-whatsapp"
                style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              ></i>
            </WhatsappShareButton>
            <FacebookShareButton style={{ color: "blue" }} url={postdescr}>
            <i className="fa-brands fa-facebook"></i>
            </FacebookShareButton>
            <TwitterShareButton style={{ color: "black" }} url={postdescr}>
            <i className="fa-brands fa-x-twitter"></i>
            </TwitterShareButton>
          </>
        ) : (
          <>
            
            <WhatsappShareButton url={shareUrl}>
            <i
                className="fa-brands fa-whatsapp"
                style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}
              ></i>
            </WhatsappShareButton>
            <FacebookShareButton url={shareUrl} quote={postdescr}>
            <i className="fa-brands fa-facebook"></i>
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={postdescr}>
            <i className="fa-brands fa-x-twitter"></i>
            </TwitterShareButton>
          </>
        )}
        </div>

<div className="copy-link-container">
                        <div className="copy-header"></div>
                        <div className="copy-link">
                          <div className="copy-link-inner">
                            <form onSubmit={handleCopy} className="d-flex">
                              <input
                                type="text"
                                value={shareUrl}
                                ref={inputRef}
                                className="w-100 text-nowrap overflow-hidden"
                                onClick={() => inputRef.current.select()}
                                readOnly
                              />
                              <input
                                type="submit"
                                className="text-nowrap"
                                value="Copy"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
      
      </div>
    </>

  )
}