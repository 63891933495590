import React from "react";
import { TextBox } from "devextreme-react";
import TextArea from "devextreme-react/text-area";
import AuthContext from "../../context/AuthContext";
import { useState, useContext, useEffect } from "react";
import { GET_USERPROFILE, SEND_MESSAGE_TO_CONTACT, GET_MESSAGES_CONTACT } from "../../Api/api";
import { BASEPATH } from "../../config/config";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import SuccessNewToaster from "../../Toaster/Success/SuccessNewToaster";
import HandleErrorToast from "../../Toaster/Failure/Failure";
import DataGrid, {
    Column,
    Editing,
    Popup,
    Paging,
    Scrolling,
    Form,
    SearchPanel,
    Export,
    Selection,
} from 'devextreme-react/data-grid';

export default function Contact_organiser(props) {
    const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);

    const token = localStorage.getItem("MDnD_auth");
    const [tokenerProfileCollection, setTokenerProfileCollection] = useState({
        fetchData: "",
        message: "",
        validation_msg: ""
    })

    const [fetchMessagesCollection, setFetchMessagesCollection] = useState({
        ReceiveData: ""
    })

    let { roleid, loginid, name } = props;
    // console.log(roleid,"roleid inside Contact");
    // console.log(loginid,"loginid inisde Contact");

    //console.log(user, "JWT");

    //to fetch the userprofile details of token guy
    useEffect(() => {
        const fetchProfileofTokener = async () => {

            try {
                const response = await axios({
                    method: "POST",
                    url: `${BASEPATH}${GET_USERPROFILE}`,

                    data: {
                        roleid: user ? user.role_id : "",
                    },
                    headers: {
                        'MDnD_auth': `${token}`,

                    },

                    timeout: 60000,
                    responseType: 'json',
                });

                if (response.data.status === "success") {
                    //console.log(response.data.data[0], "RD");
                    setTokenerProfileCollection((prevState) => ({ ...prevState, fetchData: response.data.data[0] }));

                }
                else {
                    setTokenerProfileCollection((prevState) => ({ ...prevState, fetchData: "" }));
                }
            }
            catch (error) {
                console.error("profile of Tokener is not fetched", error);
            }

        }
        if (user) {
            fetchProfileofTokener();
        }

    }, [])

    //console.log(tokenerProfileCollection, "tokenerProfileCollection");

    const handleMessage = (e) => {
        setTokenerProfileCollection((prevState) => ({ ...prevState, message: e?.value }));

    }

    const handleSubmit = () => {
        if (tokenerProfileCollection.message === "") {
            setTokenerProfileCollection((prevState) => ({ ...prevState, validation_msg: "This field is required" }));
        }
        else {

            setTokenerProfileCollection((prevState) => ({ ...prevState, validation_msg: "" }));

            mutationSendMessage.mutate();

        }

    }

    const SendAMessage = () =>
        SEND_MESSAGE_TO_BACKEND(SEND_MESSAGE_TO_CONTACT);

    const SEND_MESSAGE_TO_BACKEND = async (
        SEND_MESSAGE_TO_CONTACT) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${SEND_MESSAGE_TO_CONTACT}`,

                data: {
                    tologinid: loginid ? loginid : "",
                    toroleid: roleid ? roleid : "",
                    fromloginid: user ? user.login_id : "",
                    fromroleid: user ? user.role_id : "",
                    name: tokenerProfileCollection.fetchData.name ? tokenerProfileCollection.fetchData.name : "",
                    email: tokenerProfileCollection.fetchData.email ? tokenerProfileCollection.fetchData.email : "",
                    countrycode: tokenerProfileCollection.fetchData.countrycode ? tokenerProfileCollection.fetchData.countrycode : "",
                    mobile: tokenerProfileCollection.fetchData.mobile ? tokenerProfileCollection.fetchData.mobile : "",
                    message: tokenerProfileCollection.message,
                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationSendMessage = useMutation({
        mutationKey: ["send_message"],
        mutationFn: () => SendAMessage(),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {
                //console.log(data,"dt");
                setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
                SuccessNewToaster("success", "Message Sent Successfully!", 3000);

            }
        },
        onError: (error) => {
            setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            HandleErrorToast("error", "Message Could not be Sent. Something went Wrong!", 3000);
        },
    });

    //displaying the messages.....

    useEffect(() => {
        if (user) {
            mutationGetAllMessages.mutate();
        }
    }, [])


    const GetMessage = () =>
        GET_MESSAGES_FROM_BACKEND(GET_MESSAGES_CONTACT);

    const GET_MESSAGES_FROM_BACKEND = async (
        GET_MESSAGES_CONTACT) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_MESSAGES_CONTACT}`,

                data: {
                    toroleid: user ? user.role_id : "",

                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationGetAllMessages = useMutation({
        mutationKey: ["get_messages"],
        mutationFn: () => GetMessage(),

        onSuccess: (data) => {
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {
                //console.log(data,"dt");
                //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
                //SuccessNewToaster("success", "Message Sent Successfully!", 3000);


                let filteredArray = data.data.sort((a, b) => new Date(b.created_date) - new Date(a.created_date));

                setFetchMessagesCollection((prevState) => ({ ...prevState, ReceiveData: filteredArray }));
            }
            else {
                setFetchMessagesCollection((prevState) => ({ ...prevState, ReceiveData: "" }));
            }
        },
        onError: (error) => {
            //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            HandleErrorToast("error", "Messages Could not be Fetched. Something went Wrong!", 3000);
            setFetchMessagesCollection((prevState) => ({ ...prevState, ReceiveData: "" }));
        },
    });



    // const renderDate = (e) => {
    //     let filteredDate;
    //     console.log(e.value, "e inside renderDate");
    //     if (e.value) {
    //         filteredDate = new Date(e.value).toISOString().split('T')[0];
    //     }
    //     return filteredDate;
    // }

    const renderDate = ({ data }) => {

        const date = new Date(data.created_date);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero based
        const year = date.getUTCFullYear();

        return `${day}-${month}-${year}`;
    };

    const renderTime = (e) => {

        let filteredTime;
        if (e.value) {
            const date = new Date(e.value);
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            const seconds = date.getUTCSeconds().toString().padStart(2, '0');
            filteredTime = `${hours}:${minutes}:${seconds}`;
        }
        return filteredTime;

    }


    return (

        <div>
            {user ? (
                user.role_id !== roleid ? (
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group mb-2">
                               
                                <TextBox
                                    //defaultValue="John Smith"
                                    value={tokenerProfileCollection.fetchData.name || null}
                                    readOnly
                                    label="Name"
                                labelMode="floating"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-2">
                               
                                <TextBox
                                    readOnly
                                    value={tokenerProfileCollection.fetchData.mobile || null}
                              label="Phone"
                                labelMode="floating"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-2">
                               
                                <TextBox
                                    readOnly
                                    value={tokenerProfileCollection.fetchData.email || null}
                                    label="Email"
                                    labelMode="floating"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-2">

                                <TextBox
                                    readOnly
                                    value={tokenerProfileCollection.fetchData.countrycode || null}
                                    label="Country Code"
                                    labelMode="floating"
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group m-0 p-0">

                                <TextArea
                                    height={90}
                                    value={tokenerProfileCollection.message}
                                    onValueChanged={handleMessage}
                                    label={`Type your message to ${name}`}
                                    labelMode="floating"
                                />
                            </div>
                            {tokenerProfileCollection.validation_msg && <p className="error-message m-0 p-0 mt-1"> {tokenerProfileCollection.validation_msg}  </p>}
                        </div>

                        <div className="col-12 d-flex justify-content-center mt-3">
                            <button
                                onClick={handleSubmit}
                                className="btn px-3 py-1 float-end videoPostBtn"
                            >

                                Send
                            </button>
                        </div>
                        


                    </div>
                ) :
                    (
                        <div>
                            <h5>People who have left a message for You!</h5>
                            <DataGrid
                                dataSource={fetchMessagesCollection.ReceiveData || []}
                                //dataSource={""}
                                showBorders={true}
                                //onRowInserting={(e) => handleRowInsert(e)}

                                //defaultPageSize={3}
                                //onRowUpdated={(e) => handleRowUpdate(e)}
                                paging={{ pageSize: 3 }}
                                rowAlternationEnabled={true}
                                selectionFilter={true}>

                                <Paging enabled={true} />

                                <Column
                                    caption="Sl.No"
                                    cellRender={(cellInfo) => {
                                        const { rowIndex, component } = cellInfo;
                                        const pageSize = component.pageSize();
                                        const pageIndex = component.pageIndex();
                                        const totalCount = component.totalCount();

                                        // Calculate the descending serial number
                                        const descendingIndex = totalCount - (pageIndex * pageSize + rowIndex);

                                        return descendingIndex;
                                    }}
                                    width={60}
                                    allowEditing={false}
                                />

                                <Column
                                    caption="Date"
                                    cellRender={renderDate}
                                    dataField="created_date"
                                    //width={50} 
                                    //alignment="center" 
                                    allowEditing={false} />
                                <Column
                                    dataField="created_date"
                                    caption="Time"
                                    cellRender={renderTime}
                                    //visible={false} 
                                    allowEditing={false} />

                                <Column
                                    dataField="name"
                                    caption="Name"
                                //width={20} 
                                />

                                <Column
                                    dataField="email"
                                    caption="Email"
                                //width={20} 
                                />

                                <Column
                                    dataField="mobile"
                                    caption="Phone"
                                //width={20} 
                                />
                                <Column
                                    dataField="message"
                                    caption="Message"
                                //width={20} 
                                />


                            </DataGrid>




                        </div>
                    )
            ) : (<p>Please login or register to send a message to {name || null}</p>

            )}








        </div>
    )
}