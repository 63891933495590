import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { customEncrypt } from "../utils/encryptAndDecrypt";
import getEventKeys from "../Api/getEventKeys";
import getSeasonTicketKey from "../Api/getSeasonTicketKey";

const PreviewEvent = ({
  show,
  onClose,
  message,
  imageSource,
  isSeasonEvent,
}) => {
  const COUNTRY_KEY = getEventKeys().countryKey;
  const EVENT_KEY = getEventKeys().eventKey;
    const SEASONTICKETCOUNTRYKEY = getSeasonTicketKey().SEASONTICKET_KEY;
    const SEASONEVENTKEY = getSeasonTicketKey().SEASONEVENT_KEY;
  const navigate = useNavigate();
  const encodeBase64_new = (str, key) => {
    try {
      const prefix = key?.toString();
      const combinedStr = prefix + str.toString();
      return btoa(combinedStr);
    } catch (error) {
      console.log(error);
    }
  };

  const navigatePreview = () => {
    try {
      if (message && message[0]) {
        const { country_id, event_id, event_title } = message[0];
        const modifiedTitle = event_title
          ? event_title.split(" ").join("_")
          : "Event";
        const encyptedData = customEncrypt("yes");
        if (!isSeasonEvent) {
          navigate(
            `/event/${modifiedTitle}?ci=${encodeBase64_new(
              country_id,
              COUNTRY_KEY
            )}&e=${encodeBase64_new(
              event_id,
              EVENT_KEY
            )}&editEvent=${encyptedData}`
          );
        } else {
          navigate(
            `/SeasonTickets/${modifiedTitle}?ci=${encodeBase64_new(
              country_id,
              SEASONTICKETCOUNTRYKEY
            )}&se=${encodeBase64_new(
              message[0].season_event_id,
              SEASONEVENTKEY
            )}&r_id=${message[0].role_id}&is_multiple=${
              message[0].multiple_tickets
            }&editEvent=${encyptedData}`
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="mx-auto warningPopupWidth">
        <Modal.Body className="text-center">
          <img src={imageSource} className="w-100"></img>
          <div className="d-flex justify-content-end mt-3">
            <button
              className={
                "btn py-1 px-3 filterBtn m-0 text-nowrap me-2 brandcolor"
              }
              onClick={() => onClose()}
            >
              Cancel
            </button>

            <button className="button" onClick={(e) => navigatePreview(e)}>
              Preview Event
            </button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default PreviewEvent;
