import Offcanvas from "react-bootstrap/Offcanvas";
import noFoodItems from "../../../assets/images/noFoodItems.png";
import { CiCirclePlus } from "react-icons/ci";
             import { CiCircleMinus } from "react-icons/ci";
function AddOnCodeSummary({
  isOpen,
  applyIndidualAddonCode,
  addonCodeDataSource,
  applyPromoCode,
  setDataSource,
  applyAddOnCode,
}) {
  const addOnCodeAdd = (index, data) => {
    setDataSource((prev) => {
      const newData = [...prev.addonCodeDataSource];
      newData[index].addon_count += 1;
      return {
        ...prev,
        addonCodeDataSource: newData,
      };
    });
  };

  const increaseAddOnCount = (index) => {
    setDataSource((prev) => {
      const newData = [...prev.addonCodeDataSource];
      newData[index].addon_count += 1;
      return {
        ...prev,
        addonCodeDataSource: newData,
      };
    });
  };
  const decreaseAddOnCount = (index) => {
    setDataSource((prev) => {
      const newData = [...prev.addonCodeDataSource];
      if (newData[index].addon_count > 0) {
        newData[index].addon_count -= 1;
      }
      return {
        ...prev,
        addonCodeDataSource: newData,
      };
    });
  };

  return (
    <>
      <Offcanvas
        show={isOpen}
        onHide={() => applyIndidualAddonCode(true)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add ons</Offcanvas.Title>
        </Offcanvas.Header>
        <hr></hr>
        <Offcanvas.Body>
          <div className="d-flex flex-column gap-3">
            {addonCodeDataSource &&
              addonCodeDataSource.some((data) => data.addon_count > 0) && (
                <button
                  onClick={() => applyAddOnCode()}
                  className="border-button"
                >
                  Apply
                </button>
              )}

            {addonCodeDataSource &&
              addonCodeDataSource.map((data, index) => (
                <div className="d-flex flex-column p-2 px-3 gap-3 card-border">
                  <div className="w-100 d-flex gap-2 align-items-center">
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    >
                      <img
                        src={noFoodItems}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column gap-1">
                      <p className="m-0 p-0" style={{ fontWeight: "500" }}>
                        {data?.addon_code}{" "}
                        <span style={{ fontWeight: "400" }}>
                          ({data?.addon_type})
                        </span>
                      </p>
                      <p
                        className="m-0 p-0"
                        style={{
                          color: "#727272",
                          maxHeight: "72px",
                          overflow: "hidden",
                        }}
                      >
                        {data?.description}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between ">
                    <p className="m-0 p-0">
                      {`₹ ${data?.amount} `}
                      <span
                        className="text mx-1"
                        style={{ textDecorationLine: "line-through" }}
                      >
                        {`₹ ${data?.original_value} `}
                      </span>{" "}
                      <span style={{ color: "green" }}>
                        {data?.percentage}% Off
                      </span>
                    </p>

                    {addonCodeDataSource &&
                      addonCodeDataSource[index]?.addon_count === 0 && (
                        <button
                          className="border-button"
                          onClick={() => addOnCodeAdd(index, data)}
                        >
                          Add
                        </button>
                      )}

                    {addonCodeDataSource &&
                      addonCodeDataSource[index]?.addon_count !== 0 && (
                        <div className="d-flex gap-1 align-items-center">
                          {addonCodeDataSource &&
                            addonCodeDataSource[index]?.addon_count !== 0 && (
                              <button onClick={() => decreaseAddOnCount(index)}>
                                <CiCircleMinus />
                              </button>
                            )}
                          <p className="m-0 p-0 " style={{color: '#3f00ff'}}>
                            {addonCodeDataSource &&
                              addonCodeDataSource[index]?.addon_count}
                          </p>
                          <button onClick={() => increaseAddOnCount(index)}>
                            <CiCirclePlus />
                          </button>
                        </div>
                      )}
                  </div>
                </div>
              ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AddOnCodeSummary;
