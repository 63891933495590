import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import img from "../Toaster/hurray.jpg";
const NotificationWithRoute = ({
  show,
  onClose,
  message,
  onSecondaryAction,
}) => {
  const history = useNavigate();

  const handleSecondaryAction = () => {
    if (onSecondaryAction) {
      onSecondaryAction();
    } else {
      history("/some-path");
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="mx-auto warningPopupWidth">
        <Modal.Header closeButton className="p-3">
          <Modal.Title className="warningPopFont">Blog Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={img} alt="alterImage" />
          <p className="warningPopFont">{message}</p>
        </Modal.Body>
        <Modal.Footer className="warningFooterLine">
          <button className="warningPopFont OKBtn" onClick={onClose}>
            Remain in WriteBlog
          </button>
          <button
            className="warningPopFont secondaryBtn"
            onClick={handleSecondaryAction}
          >
            Go to ViewBlogs
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default NotificationWithRoute;
