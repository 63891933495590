import React from "react";
import noimage from "./noimage.jpg"

export default function Noimage_profilepic(){

    return(
        <>
         <img src={noimage} alt="No image Found" 
         className="rounded-circle"
         style={{ maxWidth: "200px", maxHeight: "200px" }}
         />
        
        </>
    )
}