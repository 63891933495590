import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../../../../global/ToastMsgConstants.tsx";

const MailerValidationSchema = Yup.object().shape({
  memberEmailAddress: Yup.string().required(COMMONFIELDREQUIRED),
  subject: Yup.string().required(COMMONFIELDREQUIRED),
  template_id: Yup.string().required(COMMONFIELDREQUIRED),
  message: Yup.string().required(COMMONFIELDREQUIRED),
  attachment: Yup.string() 
});

const MailMemberValidationSchema = Yup.object().shape({
  selectmemberType: Yup.string().required(COMMONFIELDREQUIRED),
  subject: Yup.string().required(COMMONFIELDREQUIRED),
  template_id: Yup.string().required(COMMONFIELDREQUIRED),
  message: Yup.string().required(COMMONFIELDREQUIRED),
  attachment: Yup.string() 
})








  export  { 
    MailerValidationSchema,
    MailMemberValidationSchema
  }