import React from "react";
import { TextArea } from "devextreme-react";
import { useState,useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import { BASEPATH } from "../../config/config";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { ABOUT_PROFILE,GET_ABOUTS } from "../../Api/api";
import SuccessNewToaster from "../../Toaster/Success/SuccessNewToaster";
import NoDataFound from "../../global/NoDataFound";


export default function About_organiser(props) {

    const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);
    const queryClient = useQueryClient();
    const token= localStorage.getItem("MDnD_auth");
    let { roleid} = props;

   

    const [Aboutfrombackend, setAboutfrombackend] = useState({
        first_text:"",
        description:"",
        validation_for_description:"",
      })
      const[counter,setCounter]= useState(0);
      const[checkEditing, setcheckEditing] = useState(false);

      const handlefinalEdittoBackend=()=>{

        if(Aboutfrombackend.description === Aboutfrombackend.first_text ){
          setAboutfrombackend((prevState) => ({
              ...prevState,
              validation_for_description: "Please enter new text!",
            }));
      }
      else{
        setAboutfrombackend((prevState) => ({
              ...prevState,
              validation_for_description: "",
            }));
            mutationAboutProfile.mutate();
      }
      
      }  

      const handleAboutduringUpdate=(e)=>{

        setAboutfrombackend((prevState) => ({
            ...prevState,
            description: e.value,
          }));

      }

      const handleCloseafterSubmit=()=>{
      
    
        setAboutfrombackend((prevState) => ({
          ...prevState,
          validation_for_description: "",
          first_text:""
          
        }));

        setcheckEditing(false);
      }

      const PostAboutProfile = () =>
        ABOUTTEXTBOXPROFILE(ABOUT_PROFILE);
    
      const mutationAboutProfile = useMutation({
        mutationKey: ["About_in_profile"],
        mutationFn: () => PostAboutProfile(),
    
        onSuccess: (data) => {
        //   queryClient.invalidateQueries({
        //     queryKey: ["fetchUser"],
        //   });
          
    
          //console.log(data, "DATA");
    
          if (data.status === "success") {
           SuccessNewToaster("success","Succesfully Posted",3000);
            handleCloseafterSubmit();
            setCounter(counter+1);
          }
        },
        onError: (error) => {
         
          //alert("Did not post profile");
          handleCloseafterSubmit();
        },
      });
    
      const ABOUTTEXTBOXPROFILE = async (
        ABOUT_PROFILE) => {
        try {
          const response = await axios({
            method: "POST",
            url: `${BASEPATH}${ABOUT_PROFILE}`,
    
            data: {
              roleid: roleid ? Number(roleid) :"",
              profileabout: Aboutfrombackend.description ? Aboutfrombackend.description : null
            },
            headers: {
              'MDnD_auth': `${token}`, 
             
          },

          timeout: 60000,
          responseType: 'json',
          });
          //console.log(response, "RESPONSE");
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };

// fetching the About........
      useEffect(()=>{
        mutationgetAbout.mutate();
  
  
        },[roleid,counter ])
  
        const GetallAbout = () =>
          GET_ABOUTS_FROM_BACKEND(GET_ABOUTS);
  
        const  GET_ABOUTS_FROM_BACKEND = async (
          GET_ABOUTS) => {
          try {
            const response = await axios({
              method: "POST",
              url: `${BASEPATH}${GET_ABOUTS}`,
      
              data: {
                roleid: roleid ? Number(roleid) :""
              
              },
              headers: {
                'MDnD_auth': `${token}`, 
               
            },

            timeout: 60000,
            responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
          } catch (error) {
            console.error(error);
          }
        };
      
        const  mutationgetAbout = useMutation({
          mutationKey: ["get_All_About"],
          mutationFn: () => GetallAbout(),
      
          onSuccess: (data) => {
          //   queryClient.invalidateQueries({
          //     queryKey: ["fetchUser"],
          //   });
            
      
            //console.log(data, "DATA");
      
            if (data.status === "success") {
              //console.log(data,"dt");
  
             
             
              setAboutfrombackend((prevState) => ({
                ...prevState,
                description: data.data.description ,
                first_text: data.data.description 
              }));
            
            
            
                //setChecker(false);
                 
              
  
           
  
              
           
              //handleCloseafterSubmit();
            }
          },
          onError: (error) => {
           
            //alert("Did not post profile");
            //handleCloseafterSubmit();
            setAboutfrombackend((prevState) => ({
                ...prevState,
                description: "" ,
                first_text: ""
              }));
              setcheckEditing(false);
          },
        });
      

        const isEditable = user?.role_id === roleid;

      

        const handleEditing=()=>{
            setcheckEditing(true);
        }
        const handleCancel=()=>{
            setcheckEditing(false);

            setAboutfrombackend((prevState) => ({
                ...prevState,
                validation_for_description: "",
              }));  
        }
    

    return (
        <>
        <div className="d-flex mt-2">
                           
                           
                          </div>
           
                          {checkEditing !== true && (
  Aboutfrombackend.description ? (
    <div>{Aboutfrombackend.description}</div>
  ) : (
    <NoDataFound />
  )
)}
        {checkEditing &&
            <TextArea
                value={Aboutfrombackend.description}

                height={90}
                //placeholder="Write Something About Yourself!"
                //onValueChanged={handleAboutduringUpdate}
                onValueChanged={isEditable ? handleAboutduringUpdate : null}
                disabled={!isEditable}
               
            /> }
            {/* <input
                type="text"
                value={Aboutfrombackend.description}
                style={{ border: isEditable ? '1px solid #ccc' : 'none', outline: 'none' }} // Apply border conditionally
                onChange={isEditable ? handleAboutduringUpdate : null}
                disabled={!isEditable}
            /> */}
            {Aboutfrombackend.validation_for_description && (
                <span>{Aboutfrombackend.validation_for_description}</span>
            )}

{ user?.role_id === roleid  && checkEditing !== true &&
            <button
                onClick={handleEditing}
                className="button"
            >
                Edit
            </button> }


{ user?.role_id === roleid  && checkEditing === true &&
<>
            <button
                onClick={handlefinalEdittoBackend}
                className="button"
            >
                Save
            </button> 
            <button
            onClick={handleCancel}
            className="button"
        >
            Cancel
        </button> 
     </>       
            }


        </>
    )
}