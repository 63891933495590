import React from 'react';
import { useNavigate } from 'react-router-dom';


const SuccessFormPage = () => {
  const navigate = useNavigate();





  const handleGoHome = () => {
    navigate('/'); 
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Success!</h1>
      <p style={styles.message}>Your form has been successfully submitted.</p>
      <p style={styles.message}> Please check your email for registration confirmation.</p> 

      <button style={styles.button} onClick={handleGoHome}>
        Go to Home
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f8ff',
    textAlign: 'center',
  },
  header: {
    fontSize: '2.5rem',
    color: '#4caf50',
  },
  message: {
    fontSize: '1.25rem',
    margin: '20px 0',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: '#4caf50',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default SuccessFormPage;