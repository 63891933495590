import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { RAGABANK_IMAGEPATH } from "../../config/config"; 
import { MdForward10, MdReplay10 } from "react-icons/md";

const HLSVideoPlayer = ({ videoUrl, onActionPlayForward, onActionPlayRewine, thumbNail }) => {
  const playerRef = useRef(null);
  const audioRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(1.0);
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [qualityVisible, setQualityVisible] = useState(false);
  const [qualityOptions, setQualityOptions] = useState([]);

  const rewind = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - 10, 0));
    }
  };

 
  const forward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + 10);
    }
  };

  useEffect(() => {
    if (videoUrl) {
      setCurrentUrl(videoUrl);
    }
  }, [videoUrl]);

  useEffect(() => {
    if (playerRef.current && currentUrl) {
      playerRef.current.seekTo(0);
    }
  }, [currentUrl]);

  const isAudioFile = (url) => {
    return url && url.endsWith(".mp3");
  };

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleAudioEnded = () => {
    setPlaying(false);
  };
  // ------------------------------------------------------------------------
  const playForward = async () => {
    onActionPlayForward(true);
  };
   
  const playPrevious = () => {
    onActionPlayRewine(true);
  };

  const handlePlayPause = () => {
    setPlaying((prev) => !prev);
    if (playerRef.current) {
      if (playing) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
    }
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };

  
  const changeQuality = (url) => {
    setCurrentUrl(url);
    setPlaying(true); 
  };
  // ------------------------------------------------------------------------
  const thumbnailUrl = `${RAGABANK_IMAGEPATH}/${thumbNail}`;

  return (
    <div className="player-wrapper" style={{ position: 'relative' }}
       onMouseEnter={() => setControlsVisible(true)}
       onMouseLeave={() => setControlsVisible(false)}
     >
      {currentUrl ? (
        isAudioFile(currentUrl) ? (
          <div
          className="audio-player-background"
          style={{
            backgroundImage: `url('${thumbnailUrl}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            width: "100%",
            position: "relative",
            paddingTop: "57.25%",
            height: "0",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="audio-controls-wrapper"
            style={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
              zIndex: 10,
            }}
          >
            <div
              style={{
                width: "100%",
                borderRadius: "0px 0px 5px 5px",
                overflow: "hidden",
                backgroundColor: "#f1f3f4"
              }}
            >
              <audio
                ref={audioRef}
                controls
                style={{ width: "100%" }}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                src={currentUrl}
              >
                <source src={currentUrl} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </div>
          </div>
        </div>
        ) : (
          <ReactPlayer
            ref={playerRef}
            className="react-player"
            url={currentUrl}
            playing={playing}
            controls={true}
            volume={volume}
            playbackRate={playbackRate}
            width="100%"
            height="400px"
            onPlay={handlePlay}
            onPause={handlePause}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
                forceHLS: true, // Force HLS streaming for .m3u8 files
                hlsOptions: {
                  startLevel: -1,
                },
              },
            }}
            onError={(e) => console.error("Error playing video:", e)}
          />
        )
      ) : (
        <div>No video or audio found</div>
      )}
      {/* ---------------------------------------------------------------------------------------------- */}
      <div
        className={`play-pause d-flex align-items-center ${controlsVisible ? "visible" : ""}`}
      >
        <i aria-hidden="true" style={{ margin: "0 48px 0 0" }}>
          <MdReplay10
            style={{ fontSize: "36px", color: "rgba(128, 128, 128, 0.50)" }}
            onClick={rewind}
          />
        </i>
        <i
          className="fa fa-step-backward m-0 p-0"
          style={{ color: "rgba(128, 128, 128, 0.50)" }}
          aria-hidden="true"
          onClick={playPrevious}
        />
        <i
          className={`fa ${playing ? "fa-pause-circle" : "fa-play-circle"}`}
          style={{ color: "rgba(128, 128, 128, 0.50)" }}
          aria-hidden="true"
          onClick={handlePlayPause} // Use the custom play/pause handler
        />
        <i
          className="fa fa-step-forward m-0 p-0"
          style={{ color: "rgba(128, 128, 128, 0.50)" }}
          aria-hidden="true"
          onClick={playForward}
        />
        <i aria-hidden="true" style={{ margin: "0 0 0 48px" }}>
          <MdForward10
            style={{ fontSize: "36px", color: "rgba(128, 128, 128, 0.50)" }}
            onClick={forward}
          />
        </i>
      </div>
      {/* ----------------- */}
      <div
        className={`quality-settings ${qualityVisible ? "visible" : ""}`}
        onClick={() => setQualityVisible(!qualityVisible)}
      >
        <i className="fa fa-cog" aria-hidden="true" />
        {qualityVisible && (
          <div className="quality-options">
            {qualityOptions.map((option) => (
              <div key={option.url} onClick={() => changeQuality(option.url)}>
                {option.resolution}p
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HLSVideoPlayer;
