import "./LoginDashboard.css";
import { TextBox, SelectBox } from "devextreme-react";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { DashboardRoutes, ExcludedForFans } from "../global/Routes";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

import { useState, useEffect } from "react";

const LoginDashboard = () => {
  const CurrentLocation = useLocation();
  const ExactPath = CurrentLocation?.pathname;
  const { user, setUserDetails } = useContext(AuthContext);

  function filterRoutesForRole(routes, excludedRoutes, role) {
    if (role === "fan") {
      return routes.filter((route) => !excludedRoutes.includes(route.route));
    }
    if (role === "") {
    }
    if (role === "") {
    }
    return routes;
  }

  

  const [filteredDashBoardRoutes, setfilteredDashBoardRoutes] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
 

 

  useEffect(() => {
    if (DashboardRoutes && user) {
      let newArray = [];
      let tempNumber = 0; 
  
      if (user.rolename === "artist") {
        for (let i = 0; i < DashboardRoutes.length; i++) {
          if (
            DashboardRoutes[i]['name'] === "Dashboard" ||
            DashboardRoutes[i]['name'] === "My Profile" ||
            DashboardRoutes[i]['name'] === "Feed" ||
            DashboardRoutes[i]['name'] === "Transactions" ||
            DashboardRoutes[i]['name'] === "Create Events" ||
            DashboardRoutes[i]['name'] === "Create Forms" ||
            DashboardRoutes[i]['name'] === "Write Blogs" ||
            DashboardRoutes[i]['name'] === "Upload Videos" 
          ) {
            newArray[tempNumber] = DashboardRoutes[i];
            tempNumber++;
          }
        }
      } else if (user.rolename === "organiser") {
        for (let i = 0; i < DashboardRoutes.length; i++) {
          if (DashboardRoutes[i]['name'] !== "Ads") {
            newArray[tempNumber] = DashboardRoutes[i];
            tempNumber++;
          }
        }
      } else if (user.rolename === "fan") {
        for (let i = 0; i < DashboardRoutes.length; i++) {
          if (
            DashboardRoutes[i]['name'] === "Dashboard" ||
            DashboardRoutes[i]['name'] === "My Profile" ||
            DashboardRoutes[i]['name'] === "Feed" ||
            DashboardRoutes[i]['name'] === "Transactions" ||
            DashboardRoutes[i]['name'] === "Write Blogs"
          ) {
            newArray[tempNumber] = DashboardRoutes[i];
            tempNumber++;
          }
        }
      } else if (user.rolename === "advertiser") {
        for (let i = 0; i < DashboardRoutes.length; i++) {
          if (
            DashboardRoutes[i]['name'] === "Dashboard" ||
            DashboardRoutes[i]['name'] === "My Profile" ||
            DashboardRoutes[i]['name'] === "Feed" ||
            DashboardRoutes[i]['name'] === "Transactions" ||
            DashboardRoutes[i]['name'] === "Ads"
          ) {
            newArray[tempNumber] = DashboardRoutes[i];
            tempNumber++;
          }
        }
      } else {
       
        newArray = DashboardRoutes;
      }
  
     
      if (newArray.length > 0) {
        setfilteredDashBoardRoutes(newArray);
      }
    }
  }, [DashboardRoutes, user]);

  const handleToggleClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleItemClick = () => {
    
    setIsSidebarOpen(false);
  };



  return (
    <div>
      <div className={`section-padding service-details} pt-lg-5`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-md-4">
              <div className="acc-left-menu p-1 mb-lg-5 mb-lg-0 mb-md-0">
                <div className="product-menu">
                  <nav className="navbar navbar-expand-md navbar-light flex-md-column">
                    <button
                      className={`navbar-toggler ${isSidebarOpen ? '' : 'collapsed'}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNav"
                      aria-controls="navbarNav"
                      //aria-expanded="false"
                      onClick={handleToggleClick}
                      aria-expanded={isSidebarOpen}
                      aria-label="Toggle navigation"
                    >
                      <span className="fas fa-bars"></span>
                    </button>
                    <div
                      //className="collapse navbar-collapse w-100"
                      className={`collapse navbar-collapse w-100 ${isSidebarOpen ? 'show' : ''}`}
                      id="navbarNav"
                    >
                      <ul
                        className="navbar-nav flex-column list-inline m-0 nav nav-tabs flex-column bg-transparent border-0"
                        role="tablist"
                      >
                        {/* {DashboardRoutes &&
                        {/* {DashboardRoutes &&
                          DashboardRoutes?.map((item, index) => (
                            <li li className="nav-item py-2" key={index}>
                              <Link
                                to={item?.route}
                                className={`nav-link ${ExactPath === "/" + item?.route
                                className={`nav-link ${ExactPath === "/" + item?.route
                                    ? "active"
                                    : ""
                                  }`}
                                  }`}
                              >
                                <i className={`${item?.icon} me-2`}></i>
                                {item.name}
                              </Link>
                            </li>
                          ))} */}

                        {/* {DashboardRoutes &&
                          DashboardRoutes.map((item, index) => (
                            <li className="nav-item py-2" key={index}>
                              <Link
                                to={item.route}
                                className={`nav-link ${ExactPath === `/${item.route}` ||
                                  (item.dashboardSubPath && item.dashboardSubPath.includes(ExactPath.replace('/', ''))) ? "active" : ""}`}
                              >
                                <i className={`${item.icon} me-2`}></i>
                                {item.name}
                              </Link>

                            </li>
                          ))} */}

               {filteredDashBoardRoutes &&
                          filteredDashBoardRoutes.map((item, index) => (
                            <li className="nav-item py-2" key={index}>
                              <Link
                                to={item.route}
                                onClick={handleItemClick}
                                className={`nav-link ${ExactPath === `/${item.route}` ||
                                  (item.dashboardSubPath && item.dashboardSubPath.includes(ExactPath.replace('/', ''))) ? "active" : ""}`}
                              >
                                <i className={`${item.icon} me-2`}></i>
                                {item.name}
                              </Link>

                            </li>
                          ))}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-md-8">
              <div className="tab-content" id="">
                <div
                  className="tab-pane fade"
                  id="create-events"
                  role="tabpanel"
                ></div>
                <div className="tab-pane fade" id="forms" role="tabpanel"></div>

                <div
                  className="tab-pane fade"
                  id="uploadvideos"
                  role="tabpanel"
                >
                  <div className="orders-table text-body ">
                    <div
                      className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingOne"
                        >
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            My Video List
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingOne"
                        >
                          <div className="accordion-body">
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped text-center">
                                <thead>
                                  <tr className="">
                                    <th className="fw-bolder p-3">Sl. No.</th>
                                    <th className="fw-bolder p-3">
                                      Date of Upload
                                    </th>
                                    <th className="fw-bolder p-3">Category</th>
                                    <th className="fw-bolder p-3">
                                      Video Title
                                    </th>
                                    <th className="fw-bolder p-3">Artists</th>
                                    <th className="fw-bolder p-3">
                                      Organisers
                                    </th>
                                    <th className="fw-bolder p-3">Image</th>
                                    <th className="fw-bolder p-3">Status</th>
                                    <th className="fw-bolder p-3">Views</th>
                                    <th className="fw-bolder p-3">Likes</th>
                                    <th className="fw-bolder p-3">Comments</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="">
                                    <td className="text-primary fs-6">
                                      #32604
                                    </td>
                                    <td>October 28, 2022</td>
                                    <td>Cancelled</td>
                                    <td>$215.00 For 0 Items</td>
                                    <td>$215.00</td>
                                    <td>$215.00</td>
                                    <td>$215.00</td>
                                    <td>$215.00</td>
                                    <td>$215.00</td>
                                    <td>$215.00</td>
                                    <td>$215.00</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            Upload Videos
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div className="accordion-body"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="promo-codes" role="tabpanel">
                  <h4>
                    <b>Add-on & Promo Code</b>
                  </h4>
                  <div className="orders-table text-body ">
                    <div
                      className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingOne"
                        >
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            Promo Code Event List
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseOne"
                          className="accordion-collapse collapse show"
                          aria-labelledby="panelsStayOpen-headingOne"
                        >
                          <div className="accordion-body">
                            <div className="table-responsive">
                              <table className="table table-bordered table-striped text-center">
                                <thead>
                                  <tr className="">
                                    <th className="fw-bolder p-3">Sl. No.</th>
                                    <th className="fw-bolder p-3">Promocode</th>
                                    <th className="fw-bolder p-3">
                                      Promocode Description
                                    </th>
                                    <th className="fw-bolder p-3">
                                      Discount Type
                                    </th>
                                    <th className="fw-bolder p-3">
                                      Discount Value
                                    </th>
                                    <th className="fw-bolder p-3">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="">
                                    <td className="text-primary fs-6">
                                      #32604
                                    </td>
                                    <td>October 28, 2022</td>
                                    <td>Cancelled</td>
                                    <td>$215.00 For 0 Items</td>
                                    <td>$215.00</td>
                                    <td>$215.00</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingTwo"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseTwo"
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseTwo"
                          >
                            Create Promo Code
                          </button>
                        </h2>
                        <div
                          id="panelsStayOpen-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingTwo"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group mb-2">
                                  <label className="mb-2">
                                    Promocode&nbsp;{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <TextBox defaultValue="John Smith" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group mb-2">
                                  <label className="mb-2">
                                    Description&nbsp;{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <TextBox defaultValue="John Smith" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group mb-2">
                                  <label className="mb-2">
                                    Discount Type&nbsp;{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <SelectBox />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group mb-2">
                                  <label className="mb-2">
                                    Discount value&nbsp;{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <TextBox defaultValue="John Smith" />
                                </div>
                              </div>
                              <div className="col-lg-12 mt-3 iq-button rounded">
                                <button className="btn rounded">Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginDashboard;
