import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { viewEventsFetch, editEventsFetch } from "./EventsFetch";
import notify from "devextreme/ui/notify";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";
import { GlobalContext } from "../../../global/provider";
import {
  generateReverseSerialNumber,
  ImageRender,
  isValidValue,
} from "../../../utils/commonUtils.js";
import { useSearchParams } from "react-router-dom";
import { ViewEventImagePath } from "../../../config/config.js";
import { convertDateFormatDDMMYYYY } from "../../../utils/dateFormatUtils.js";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import DataGrid, {
  Column,
  SearchPanel,
  MasterDetail,
} from "devextreme-react/data-grid";
import { APPLICABLE, NOT_APPLICABLE } from "../../../global/constants";
import Switch from "devextreme-react/switch";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import CreateEvents from "./createEvents.js";
import { GET_COUNTRIES } from "../../../Api/api.js";
import {
  makeGetRequest,
  makePostRequest,
} from "../../../Api/requestMethods.js";
import { SelectBox } from "devextreme-react";
import AuthContext from "../../../context/AuthContext.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FaTicketAlt } from "react-icons/fa";
import { GrRevert } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { deleteEventApi } from "../../../Api/api.js";
import { SOMETHING_WENT_WRONG } from "../../../Schemas/errorMessageConstants.js";
import { LuLayoutDashboard } from "react-icons/lu";
import { URLParamsEncrypt } from "../../../utils/encryptAndDecrypt.js";

const ViewEvents = () => {
  const navigate = useNavigate();
  const viewEventRef = useRef(null);
  const [searchParams] = useSearchParams();
  const event_id = searchParams.get("event_id");
  const { userDetails, setEventDataSource } = useContext(GlobalContext);
  const { user } = useContext(AuthContext);
  const [dataSouce, setDataSource] = useState({
    viewEvent: "",
    viewEventTrigger: 0,
    selectedCountry: 101,
  });
  const viewDataMutation = useMutation({
    mutationFn: (state_id) => viewEventsFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, viewEvent: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const editDataMutation = useMutation({
    mutationFn: (data) => editEventsFetch(data),
    onSuccess: (data) => {
      setDataSource((prev) => ({
        ...prev,
        viewEventTrigger: prev.viewEventTrigger + 1,
      }));
      notify(data.message, SUCCESS, DURATION);
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    setEventDataSource((prev) => ({ ...prev, updatedNumberedDetails: [] }));
  }, []);

  useEffect(() => {
    if (user && user?.role_id) {
      const body = {
        role_id: user?.role_id,
        country_id: 101,
        is_season_event: "N",
      };
      viewDataMutation.mutate(body);
    }
  }, [userDetails, dataSouce.viewEventTrigger]);

  const showToPublicEvent = (e, row) => {
    const event_id = row && row?.data?.event_id;
    const body = {
      show_to_public: e.target.checked ? APPLICABLE : NOT_APPLICABLE,
      country_id: 101,
      event_id: event_id,
      role_id: user?.role_id,
      is_season_event: "N",
    };
    editDataMutation.mutate(body);
  };

  const switchElement = (row) => {
    const isApplicable = row.value === APPLICABLE ? true : false;
    return (
      <div className="form-check form-switch d-flex">
        <input
          className="form-check-input m-auto"
          type="checkbox"
          role="switch"
          id="flexSwitchCheckChecked"
          checked={isApplicable}
          onChange={(e) => showToPublicEvent(e, row)}
        />
      </div>
    );
  };

  const countryFetch = async () => await makeGetRequest(GET_COUNTRIES);
  const {
    data: countryData,
    isLoading: isCountryDataLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["__Edit_countryFetch___"],
    queryFn: () => countryFetch(),
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const EditEventsWrapper = (props) => {
    const isEditEvent = {
      isEditEvent: true,
      country_id: dataSouce.selectedCountry,
      isSaveAsDraft: false,
    };
    return <CreateEvents {...props} {...isEditEvent} />;
  };

  const handleCountryEvent = (e) => {
    setDataSource((prev) => ({ ...prev, selectedCountry: e.value }));
  };

  useEffect(() => {
    try {
      if (
        event_id &&
        isValidValue(event_id) &&
        Array.isArray(dataSouce?.viewEvent)
      ) {
        const dataGridInstance = viewEventRef.current?.instance;
        if (!dataGridInstance) {
          console.error("DataGrid instance is not available.");
          return;
        }
        const rowIndex = dataSouce.viewEvent.findIndex(
          (row) => Number(row.event_id) === Number(event_id)
        );
        if (rowIndex !== -1) {
          const matchingRowKey = dataSouce.viewEvent[rowIndex].event_id;
          dataGridInstance.selectRowsByIndexes([matchingRowKey]);

          dataGridInstance.navigateToRow(matchingRowKey);
        } else {
          console.warn("No matching row found for event_id:", event_id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [event_id, dataSouce.viewEvent]);

  const [isRowOpened, setRowOpened] = useState(false);
  const editEventsRef = useRef(null);

  const handleEditClick = (data) => {
    try {
      if (editEventsRef.current) {
        const dataGridInstance = editEventsRef.current.instance;
        const selectedKey = dataGridInstance.getKeyByRowIndex(data.rowIndex);
        if (!dataGridInstance.isRowExpanded(selectedKey)) {
          dataGridInstance.expandRow(selectedKey);
          setRowOpened(true);
        } else {
          dataGridInstance.collapseRow(selectedKey);
          setRowOpened(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onViewTicketDetails = useCallback(
    (data) => {
      // Country id hardcoded as per sudhakars suggestion
      navigate(
        `/viewTicketDetails?event_id=${data.event_id}&country_id=${101}`
      );
    },
    [navigate]
  );

  const handleNumberedEdit = useCallback(
    (data) => {
      navigate(
        `/EditNumberedLayout?event_id=${data.data.event_id}&country_id=${101}`
      );
    },
    [navigate]
  );

  // const handleNumberedEdit = useCallback(
  //   (data) => {
  //     const encryptedEventId = URLParamsEncrypt(data.data.event_id);
  //     const encryptedCountryId = URLParamsEncrypt(101);
  //     navigate(
  //       `/EditNumberedLayout?event_id=${encryptedEventId}&country_id=${encryptedCountryId}`
  //     );
  //   },
  //   [navigate]
  // );

  const deleteEvent = async (data) => {
    try {
      const { event_id } = data;
      const body = {
        country_id: 101, // i have hardcoded country ID As per sudhakar's guidance
        event_id: event_id,
        role_id: user?.role_id,
        is_season_event: "N",
      };
      const response = await makePostRequest(deleteEventApi, body);
      if (response && response.status === SUCCESS) {
        setDataSource((prev) => ({
          ...prev,
          viewEventTrigger: prev.viewEventTrigger + 1,
        }));
        notify(response.message, SUCCESS, DURATION);
      } else {
        notify(SOMETHING_WENT_WRONG, ERROR, DURATION);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="col-lg-3 Viewevents">
        <SelectBox
          dataSource={countryData?.data || []}
          displayExpr="country_name"
          valueExpr="country_id"
          label="country"
          labelMode="floating"
          defaultValue={101}
          searchEnabled={true}
          onValueChanged={handleCountryEvent}
          value={dataSouce?.selectedCountry || null}
        />
      </div>

      <DataGrid
        dataSource={dataSouce.viewEvent || []}
        showBorders={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        selectionFilter={true}
        rowAlternationEnabled={true}
        ref={editEventsRef}
        onRowExpanding={(event) => {
          event.component.collapseAll(-1);
        }}
      >
        <MasterDetail enabled={true} component={EditEventsWrapper} />
        <SearchPanel visible={true} width={240} placeholder="Search..." />
        <Column
          width={80}
          dataField="ID"
          caption="S.no"
          calculateCellValue={(rowData) =>
            generateReverseSerialNumber(rowData, dataSouce.viewEvent)
          }
        />
        <Column
          dataField="event_date"
          caption="Event date"
          calculateCellValue={(rowData) =>
            convertDateFormatDDMMYYYY(rowData.event_date)?.fromDate
          }
        />
        <Column dataField="event_title" caption="Event title" />
        <Column dataField="event_mode" caption="Event mode" />
        <Column
          dataField="event_image"
          caption="Image"
          cellRender={(data) =>
            ImageRender(ViewEventImagePath, data, "event_image")
          }
        />
        <Column
          dataField="show_to_public"
          cellRender={switchElement}
          caption="Show to public"
        />
        <Column
          dataField="created_date"
          caption="Created on"
          calculateCellValue={(rowData) =>
            convertDateFormatDDMMYYYY(rowData.created_date)?.fromDate
          }
        />

        <Column
          caption="View Ticket Details"
          cellRender={({ data }) => (
            <div style={{ cursor: "pointer" }}>
              <FaTicketAlt
                className="EditIcon"
                onClick={() => onViewTicketDetails(data)}
              />
            </div>
          )}
          width={180}
        />

        <Column
          caption="Edit Layout"
          cellRender={({ data, rowIndex }) =>
            data.seating_option === "numbered" ? (
              <div>
                <LuLayoutDashboard
                  className="EditIcon"
                  onClick={() => handleNumberedEdit({ data })}
                />
              </div>
            ) : null
          }
          width={140}
        />

        <Column
          caption={!isRowOpened ? "Edit" : "Cancel"}
          cellRender={({ data, rowIndex }) => (
            <div>
              {!isRowOpened ? (
                <MdModeEditOutline
                  className="EditIcon"
                  onClick={() => handleEditClick({ rowIndex })}
                />
              ) : (
                <GrRevert
                  className="EditIcon"
                  onClick={() => handleEditClick({ rowIndex })}
                />
              )}
            </div>
          )}
          width={80}
        />
        <Column
          caption="Delete"
          cellRender={({ data }) => (
            <div>
              <RiDeleteBinLine
                className="DeleteIcon"
                onClick={() => deleteEvent(data)}
              />
            </div>
          )}
          width={80}
        />
      </DataGrid>
    </>
  );
};

export default ViewEvents;
