import Aulerth from "./BannerImage/Banner2.jpg";
import AulerthMobile from "./BannerImage/Banner1.jpg";
import jwelImage from "./BannerImage/WebPoster1.png";
import jwelImage2 from "./BannerImage/WebPoster2.jpg";
export const carouselData = [
  {
    type: "image",
    // title: "Events",
    // description: "Discover Music, Dance & Drama events",
    desktopImageUrl: Aulerth,
    id:2,
    mobileImageUrl: AulerthMobile,
    linkUrl: "https://www.aulerth.in"
  },
  {
    type: "image",
    // title: "Jewelry Collection",
    // description: "Discover exclusive jewelry designs by Aulerth",
    desktopImageUrl: jwelImage,
    id: 3,
    mobileImageUrl: jwelImage2,
    linkUrl: "https://www.aulerth.in"
  },
];

  