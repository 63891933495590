import React from "react";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import { SelectBox } from "devextreme-react";
import { useState, useEffect, useContext } from "react";
import notify from "devextreme/ui/notify";
import { markup, tabs } from "../../../components/others";
import { useFormik } from "formik";
import { LandingPageSchema } from "../../../Schemas";
import { landingPageDetails } from "../../../Schemas/Forms/LoginDashboard";
import {
  sizeValues,
  fontValues,
  headerValues,
  fontSizeOptions,
  fontFamilyOptions,
  headerOptions,
} from "../../../components/others";
import { seasonShowToPublic } from "../../../global/constants";
import { createLandingPage } from "../../../Api/api";
import { makePostRequest } from "../../../Api/requestMethods";
import AuthContext from "../../../context/AuthContext";
import {
  SUCCESS,
  ERROR,
  DURATION,
} from "../../../global/ToastMsgConstants.tsx";
import { landingPageDetailsApi, updateLandingPage } from "../../../Api/api";
import { isValidValue } from "../../../utils/commonUtils.js";

const CreateLandingPageDetails = () => {
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const [isMultiline, setIsMultiline] = useState(true);
  const { user } = useContext(AuthContext);
  const [prevlPageDataSource, setPreviousDs] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Submit");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [trigger, setTrigger] = useState(0);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: landingPageDetails,
    validationSchema: LandingPageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await eventSubmit();
      setSubmitting(false);
    },
  });

  const eventSubmit = async () => {
    try {
      const body = {
        show_to_public: values.show_to_public,
        content: values.content,
        roleid: user.role_id,
        loginid: user.login_id,
        landpageid: buttonLabel === "Update" ? values.landpage_id : "",
      };
      const response = await makePostRequest(
        buttonLabel === "Update" ? updateLandingPage : createLandingPage,
        body
      );
      if (response.status === SUCCESS) {
        notify(response.message, SUCCESS, DURATION);
        setTrigger(trigger + 1);
      } else {
        notify(response?.error, ERROR, DURATION);
      }
    } catch (error) {
      notify(error.response.data.message, ERROR, DURATION);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const body = {
          roleid: user.role_id,
        };
        if (isValidValue(user.role_id)) {
          const response = await makePostRequest(landingPageDetailsApi, body);
          if (response.status === SUCCESS) {
            setValues(
              response.data && response.data[0] ? response.data[0] : {}
            );
            setPreviousDs(
              response.data && response.data[0] ? response.data[0] : {}
            );
          } else {
            notify(response?.error, ERROR, DURATION);
          }
        }
      } catch (error) {
        notify(error.response.data.message, ERROR, DURATION);
      }
    })();
  }, [trigger]);

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    if (areObjectsEqual(values, prevlPageDataSource)) {
      setButtonLabel("Submit");
      setIsButtonDisabled(true);
    } else if (
      !prevlPageDataSource.content ||
      !prevlPageDataSource.show_to_public
    ) {
      setButtonLabel("Submit");
      setIsButtonDisabled(false);
    } else {
      setButtonLabel("Update");
      setIsButtonDisabled(false);
    }
  }, [values, prevlPageDataSource]);

  return (
    <div className="row ">
      <div className="pb-2 mt-3">
        <HtmlEditor
          height="auto"
          defaultValue={markup}
          label="Event Description"
          className="dx-field-item-required"
          labelMode="floating"
          placeholder="Event Description"
          value={values.content}
          onFocusOut={(e) =>
            handleBlur({
              target: { name: "content", value: e.value },
            })
          }
          onValueChanged={(e) =>
            handleChange({
              target: { name: "content", value: e.value },
            })
          }
        >
          <MediaResizing enabled={true} />
          <ImageUpload tabs={currentTab} fileUploadMode="base64" />
          <Toolbar multiline={isMultiline}>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item
              name="size"
              acceptedValues={sizeValues}
              options={fontSizeOptions}
            />
            <Item
              name="font"
              acceptedValues={fontValues}
              options={fontFamilyOptions}
            />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item
              name="header"
              acceptedValues={headerValues}
              options={headerOptions}
            />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            <Item name="link" />
            <Item name="image" />
            <Item name="separator" />
            <Item name="clear" />
            <Item name="codeBlock" />
            <Item name="blockquote" />
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
          </Toolbar>
        </HtmlEditor>
        {errors.content && touched.content && (
          <p className="error-message">{errors.content}</p>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <div className="col-lg-6">
          <div className="form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              label="Show to public"
              labelMode="floating"
              dataSource={seasonShowToPublic || []}
              searchEnabled={true}
              displayExpr="name"
              valueExpr="value"
              value={values.show_to_public}
              onValueChanged={(e) =>
                handleChange({
                  target: { name: "show_to_public", value: e.value },
                })
              }
              onFocusOut={(e) =>
                handleBlur({
                  target: { name: "show_to_public", value: e.value },
                })
              }
            />
            {errors.show_to_public && touched.show_to_public && (
              <p className="error-message">{errors.show_to_public}</p>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="button ms-3 px-5"
            style={{ cursor: isButtonDisabled ? "not-allowed" : "pointer" }}
            disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateLandingPageDetails;
