import React from "react";
import { Modal } from "react-bootstrap";

const NumberedNotification = ({ show, onClose, message, imageSource }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="mx-auto warningPopupWidth">
        <Modal.Header closeButton className="p-1 border-bottom-0">
          <Modal.Title className="warningPopFont text-white">Note</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={imageSource} className="w-50"></img>
          <h6 className="warningPopFont">{message}</h6>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default NumberedNotification;
