import React from "react";
import DataGrid, {
  Scrolling,
  Column,
  Paging,
  Sorting,
  FilterRow,
  HeaderFilter,
  Pager,
  Summary,
  TotalItem,
  Export,
} from "devextreme-react/data-grid";
import { ERROR, DURATION } from "../../global/ToastMsgConstants.tsx";
import { viewTicketDetailsApi } from "../../Api/api";
import { makeGetRequest } from "../../Api/requestMethods";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { useSearchParams } from "react-router-dom";
import ChartView from "./viewTicketDetailsChart.js";
import { useState } from "react";
import { ViewEventImagePath } from "../../config/config.js";
import { BASEPATH } from "../../config/config.js";
import Skeleton from "react-loading-skeleton";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";

import "react-loading-skeleton/dist/skeleton.css";

import { AiFillEye } from 'react-icons/ai';
import jsPDF from 'jspdf';
import "jspdf-autotable";
import axios from "axios";
import { FaEnvelope } from 'react-icons/fa';
import { FaTicketAlt } from 'react-icons/fa';
import SuccessNewToaster from "../../Toaster/Success/SuccessNewToaster.js";

const ViewTicketDetails = () => {
  const [searchParams] = useSearchParams();
  const event_id = searchParams.get("event_id");
  const countryId = searchParams.get("country_id");
  const validCountryId = countryId || "";
  const validEventId = event_id ? event_id.split("?")[0] : "";
  const [isChartView, setIsChartView] = useState(false);

  const allowedPageSizes = [10, 25, 50, 100, "all"];

  const handleToggleChange = (e) => {
    setIsChartView(e.target.checked);
  };

  const getViewTicketDetails = async () => {
    try {
      return await makeGetRequest(
        viewTicketDetailsApi(validCountryId, validEventId)
      );
    } catch (error) {
      notify(error.response.data.message, ERROR, DURATION);
      throw error;
    }
  };
  const { data: dataSource, isLoading } = useQuery({
    queryKey: ["__Ticket_details__"],
    queryFn: getViewTicketDetails,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const AmountDetailsCellRender = (cellInfo, columnAmount, isNumberedEvent) => {
    const { data } = cellInfo;
    if (!data) return <p>0</p>;
    let qty = 0;
    try {
      if (isNumberedEvent) {
        const amountDetailsNumbered = JSON.parse(
          data.booked_tickets
        ).total_quantity;
        const matchingItems = amountDetailsNumbered.filter(
          (item) => item.amount === columnAmount
        );
        qty = matchingItems.length;
      } else {
        const amountDetails = JSON.parse(data.booked_tickets);
        const matchingItem = amountDetails.find(
          (item) => item.amount === columnAmount
        );
        qty = matchingItem ? matchingItem.qty : 0;
      }
    } catch (error) {
      console.error("Error in parsing booked_tickets:", error);
    }
    return <p>{qty || 0}</p>;
  };

  const generateGrossAmount = (cellInfo) => {
    try {
      const { data } = cellInfo;
      if (!data) return <p>0</p>;
      try {
        if (data.seating_option !== "numbered") {
          const amountDetails = JSON.parse(data?.booked_tickets);
          const totalAmount =
            amountDetails.filter((data) => data.event_total_amount !== 0)[0]
              .event_total_amount || 0;
          const formattedAmount = new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(totalAmount);
          return <p>{formattedAmount}</p>;
        }
      } catch (error) {
        console.error("Error in parsing booked_tickets:", error);
      }
    } catch (error) {
      console.log(error);
    }
    return <p>0</p>;
  };

  const NonNumberedDetails =
    dataSource &&
      dataSource &&
      dataSource.data[1][0].event_details.non_numbered_details
      ? dataSource.data[1][0]?.event_details.non_numbered_details
      : [];

  const EventDetails =
    dataSource && dataSource && dataSource.data[1][0].event_details
      ? dataSource.data[1][0]?.event_details
      : [];

  const bookedTickets =
    dataSource && dataSource.data[0][0]?.ticket_orders
      ? dataSource.data[0][0]?.ticket_orders
      : [];

  const parsedBookedTickets = bookedTickets.map((data) => {
    try {
      if (data.seating_option !== "numbered") {
        return [...JSON.parse(data?.booked_tickets)];
      }
      if (data.seating_option === "numbered") {
        return false;
      }
    } catch (error) {
      console.error("Failed to parse booked_tickets:", error);
      return { ...data, ParsedData: [] };
    }
  });

  const combinedArray = [];
  bookedTickets.forEach((data) => {
    try {
      if (data.seating_option === "numbered") {
        const parsedItems = JSON.parse(data?.booked_tickets).total_quantity;
        combinedArray.push(...parsedItems);
      }
    } catch (error) {
      console.error("Failed to parse booked_tickets:", error);
    }
  });

  const filterByAmountArr = combinedArray.map(({ amount }) => amount);
  const uniqueArray = combinedArray.filter(
    ({ amount }, index) => !filterByAmountArr.includes(amount, index + 1)
  );

  let maxArray = [];
  if (
    parsedBookedTickets &&
    parsedBookedTickets.length > 0 &&
    parsedBookedTickets[0] !== false
  ) {
    maxArray = parsedBookedTickets.reduce((acc, cur) =>
      cur.length > acc.length ? cur : acc
    );
  }

  const serialNumberCellRender = (cellInfo) => {
    const pageIndex = cellInfo.component.pageIndex();
    const pageSize = cellInfo.component.pageSize();
    const totalRecords = cellInfo.component.totalCount();
    const recordsBeforePage = pageIndex * pageSize;
    const serialNumber = totalRecords - (recordsBeforePage + cellInfo.rowIndex);
    return <p>{serialNumber}</p>;
  };

  const calculateDynamicValue = (gridCell, columnAmount, seating_option) => {
    const { data } = gridCell;
    if (!data) return 0;

    let qty = 0;
    try {
      const JsonToParse =
        seating_option === "numbered"
          ? JSON.parse(data.booked_tickets).total_quantity
          : JSON.parse(data.booked_tickets);
      const matchingItems = JsonToParse.filter(
        (item) => item.amount.toString() === columnAmount.toString()
      );
      qty =
        seating_option === "numbered"
          ? matchingItems.length
          : matchingItems[0].qty;
    } catch (error) {
      console.error("Error in calculating dynamic value:", error);
    }

    return qty;
  };

  const onExporting = (e, seating_option, event_title) => {
    try {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(
        seating_option === "numbered"
          ? "Numbered_details"
          : "Non_numbered_details"
      );
      const dataGrid = e.component;
      const visibleColumns = dataGrid.getVisibleColumns();
      const headerRow = worksheet.addRow(
        visibleColumns.map(
          (column) => column.caption || column.dataField || "Unnamed"
        )
      );
      headerRow.font = { bold: true };
      const exportOptions = {
        component: dataGrid,
        worksheet: worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 1 },
        customizeCell: function (options) {
          const { gridCell, excelCell } = options;

          if (gridCell && gridCell.column) {
            const columnCaption = gridCell.column.caption
              ? gridCell.column.caption.toString()
              : gridCell.column.dataField || "Unnamed";
            excelCell.value = gridCell.value;
            if (
              gridCell.column.dataField === "Gross" ||
              gridCell.column.dataField === "total_amount"
            ) {
              excelCell.numFmt = "#,##0.00";
            }

            if (columnCaption === "Seat Details" && gridCell.data) {
              const bookedDetails = JSON.parse(gridCell.data.booked_tickets);
              const totalQuantity = bookedDetails?.total_quantity;

              if (totalQuantity && totalQuantity.length > 0) {
                const groupedDetails = totalQuantity.reduce((acc, ticket) => {
                  const { floor_name, rowname, seat } = ticket;
                  const key = `${floor_name}-${rowname}`;
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key].push(seat);
                  return acc;
                }, {});
                const formattedDetails = Object.entries(groupedDetails)
                  .map(([key, seats]) => `${key}-${seats.join(",")}`)
                  .join("\n");

                excelCell.value = formattedDetails;
              } else {
                excelCell.value = "No seats booked";
              }
            }
            const dataToUse =
              seating_option === "numbered" ? uniqueArray : maxArray;
            if (
              dataToUse &&
              dataToUse.some((item) => item.amount.toString() === columnCaption)
            ) {
              const qty = calculateDynamicValue(
                gridCell,
                columnCaption,
                seating_option
              );
              excelCell.value = qty;
            }
          } else {
            console.log(`Invalid cell data at row ${options.rowIndex + 1}`);
          }
        },
      };

      exportDataGrid(exportOptions).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `${event_title}.xlsx`
          );
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const generateSeatDetails = (cellInfo) => {
    try {
      const { data } = cellInfo;
      if (!data) return <p></p>;
      const bookedDetails = JSON.parse(data?.booked_tickets);
      const totalQuantity = bookedDetails?.total_quantity;
      if (totalQuantity && totalQuantity.length > 0) {
        const groupedDetails = totalQuantity.reduce((acc, ticket) => {
          const { floor_name, rowname, seat, row_name } = ticket;
          const key = `${floor_name}-${rowname === "" ? row_name : rowname}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(seat);
          return acc;
        }, {});
        const formattedDetails = Object.entries(groupedDetails).map(
          ([key, seats]) => {
            return `${key}-${seats.join(",")}`;
          }
        );
        return (
          <p>
            {formattedDetails.map((detail, index) => (
              <React.Fragment key={index}>
                {detail}
                <br />
              </React.Fragment>
            ))}
          </p>
        );
      }

      return <p>No seats booked</p>;
    } catch (error) {
      console.log(error);
      return <p>Error loading seat details</p>;
    }
  };




  //new code..

  const token = localStorage.getItem("MDnD_auth");

  const [base64img, setbase64img] = useState('');

  const renderEyeIcon = (cellData) => {
    return (
      <button
        onClick={() => handleEyeClick(cellData)}
        style={{ border: 'none', cursor: 'pointer' }}
      >
        <AiFillEye size={20} color="#a48ed9" />
      </button>
    );
  };

  const handleEyeClick = async (cellData) => {



    if (cellData?.data?.ticket_pass) {
      try {

        const response = await axios({
          method: "POST",
          url: `${BASEPATH}events/get_base64_qrcode_image`,
          data: {

            ticket_pass: `${cellData?.data?.ticket_pass}`
          },
          headers: {
            'MDnD_auth': `${token}`,

          },
          //responseType: 'json',

        });

        if (response?.data?.status === "success") {


          setbase64img(response?.data?.data?.encodedImage);


          if (base64img) {
            await generatePdf(cellData?.data);
          }




        }
      }

      catch (error) {
        console.error("base64 fetch error", error);

        await generatePdf(cellData?.data);
      }

    }



  };


  const isValidValue_eventDetails = (value) => {
    if (value !== null) {

      const isNonEmptyObject = Object.keys(value).length > 0;

      const hasValidString = Object.values(value).some(
        (item) => item.trim() !== ''
      );
      return isNonEmptyObject && hasValidString;
    }
    return false;
  };

  const isValidValue_Array = (value) => {
    try {
      const parsedValue = JSON.parse(value);
      return (
        Array.isArray(parsedValue) &&
        parsedValue.length > 0 &&
        parsedValue.some(
          (item) =>
            item !== null && item.id !== ""
        )
      );
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return false;
    }
  };

  function formatDateToDDMMYYYY(isoDateString) {

    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (!isoDateRegex.test(isoDateString)) {
      return "Invalid ISO date string";
    }

    const date = new Date(isoDateString);


    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();


    return `${day}-${month}-${year}`;
  }



  const generatePdf = async (rowData) => {



    const doc = new jsPDF();


    let event_data = {};

    let booked_data = [];
    let overall_booked_data = [];

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();


    const borderMargin = 10;
    const borderWidth = pageWidth - (1.5 * borderMargin);
    const borderHeight = pageHeight - (1.5 * borderMargin);


    const centerX = borderMargin + (borderWidth / 2);
    const centerY = borderMargin + (borderHeight / 2);


    doc.setDrawColor(0, 0, 0);
    doc.rect(borderMargin, borderMargin, borderWidth, borderHeight);


    const imageWidth = borderWidth * 0.25;
    const imageHeight = borderHeight * 0.15;


    const imageX = centerX - (imageWidth / 2);
    const imageY = centerY - (imageHeight / 2);



    const imageX_below = centerX - (imageWidth / 2);
    const imageY_below = centerY - (imageHeight / 2);

    const textX_below = imageX_below + (imageWidth / 2);
    const textY1_below = imageY_below + imageHeight + 10;
    const textY2_below = textY1_below + 10;
    const textY3_below = textY2_below + 10;


    if (rowData.booked_tickets) {
      if (isValidValue_Array(rowData.booked_tickets) === true) {

        overall_booked_data = JSON.parse(rowData.booked_tickets);

        booked_data = overall_booked_data && overall_booked_data.filter((item) => item.qty !== 0);


      }
      else {
        booked_data = null;
      }
    }



    const columns = [
      { header: 'Row Name', dataKey: 'row_name' },
      { header: 'Quantity', dataKey: 'qty' },
      { header: 'Amount', dataKey: 'amount' },
      { header: 'Event Total Amount', dataKey: 'event_total_amount' }
    ];


    if (booked_data && booked_data.length > 0) {

      const body = booked_data.map(item => [
        item.row_name,
        item.qty,
        item.amount,
        item.event_total_amount
      ]);



      doc.autoTable({
        head: [
          [{
            content: 'Booking Summary', colSpan: 4, styles: {
              halign: 'center',
              fontSize: 12,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              lineWidth: 0.2,
              lineColor: [169, 169, 169]
            }
          }],
          columns.map(col => col.header)
        ],
        body: body,
        startY: (textY2_below + 20),
        theme: 'grid',
        styles: { cellPadding: 2, fontSize: 10 },
      });

    } else {
      console.log('No booked tickets to display in the table.');
    }




    // Title********************************************************/
    // Set title styles
    const titleY = 25;
    const titleFontSize = 30;


    doc.setFillColor(0, 0, 255);


    const titleHeight = titleFontSize * 0.75;


    const borderX = borderMargin;
    doc.rect(borderX, borderMargin, borderWidth, titleHeight, 'F');


    doc.setTextColor(255, 255, 255);


    doc.setFontSize(titleFontSize);
    doc.setFont("Helvetica", "bold");


    const titleText = 'Event Ticket';
    const textWidth = doc.getTextWidth(titleText);
    const titleX = borderX + (borderWidth - textWidth) / 2;


    doc.text(titleText, titleX, titleY);


    doc.setTextColor(0, 0, 0);
    doc.setFontSize(12);


    ///*** end of TITLE *************************************** */

    // Position the text just above the image
    const textY1 = imageY - 10;
    const textY2 = imageY - 10;
    const textY3 = imageY - 20;

    doc.text(`Dear ${rowData.customer_name} ,`, 15, (textY3 - 30));


    doc.text(`ID: ${rowData.ticket_pass}`, imageX + (imageWidth / 2), textY2, { align: 'center' });


    if (rowData.event_details) {
      if (isValidValue_eventDetails(rowData.event_details) === true) {
        event_data = JSON.parse(rowData.event_details);

        if (event_data) {


          const eventText = `Here is your e-ticket!`;
          const wrappedText = doc.splitTextToSize(eventText, 180);
          doc.text(wrappedText, 15, (textY3 - 20));

          //event name..

          const nextText = `${event_data.event_title}`;
          const wrapped_event = doc.splitTextToSize(nextText, 180);
          doc.text(wrapped_event, imageX + (imageWidth / 2), textY3, { align: 'center' })


          //event time
          const eventTime = `${formatDateToDDMMYYYY(event_data.event_start_date)}   ${event_data.event_time}`;
          const wrapped_time = doc.splitTextToSize(eventTime, 180);
          doc.text(wrapped_time, textX_below, textY1_below, { align: "center" })





          doc.text(`${event_data.venue_name}`, textX_below, textY2_below, { align: "center" });
        }
      }
    }




    try {


      if (base64img) {
        doc.addImage(base64img,
          'PNG', imageX, imageY, imageWidth, imageHeight);
        doc.text(`Regards,`, pageWidth * 0.07, pageHeight - 20);
        doc.text(`Team MDnD`, pageWidth * 0.07, pageHeight - 15);


        converttoblob(doc);

      }


    }
    catch (error) {
      console.error('Error fetching or adding the image:', error);
      doc.text(`Regards,`, (pageWidth * 0.07), (pageHeight - 20));
      doc.text(`Team MDnD`, (pageWidth * 0.07), (pageHeight - 12));
      converttoblob(doc);
    }


  };


  const converttoblob = (doc) => {
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");

  }

  const renderEmailIcon = (cellData) => {
    if (cellData?.data?.ticket_pass !== "" && cellData?.data?.payment_status === "success") {
      return (
        <button
          onClick={() => handleSendMail(cellData)}
          style={{ border: 'none', cursor: 'pointer' }}
        >
          <FaEnvelope size={24} color="#a48ed9" />
        </button>
      );
    }
    else if (cellData?.data?.ticket_pass === "" && cellData?.data?.payment_status === "success") 
      {
        return (
          // <button 
          // onClick={() => handleGenerateTicket(cellData)} 
          // style={{ border: 'none', cursor: 'pointer' }}>
          //   <FaTicketAlt size={24} color="#4CAF50" /> 
          
          // </button>
          <p style={{ margin: 0, lineHeight: '24px', display: 'flex', alignItems: 'center' }}>Ticket Not Generated</p>
        );
    }
  }

  const handleSendMail = async (cellData) => {
   

    try{
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}payments/generate_non_numbered_ticket_only_mail`,
        data:{
          transaction_id : `${cellData?.data?.transaction_id}`

        },
        responseType: 'json',
        headers: {
          'MDnD_auth': `${token}`,

        },
      

      });
      if(response?.data?.status === "success"){
         SuccessNewToaster("success","Email Successfully Sent", 4000);
      }
    }
    catch(error){
      console.error("error is",error);
    }
  }

  const handleGenerateTicket =(cellData)=>{
   console.log(cellData.data,"R");
  }



  return (
    <div className="container-fluid my-4">
      <div className="row tabBodyBg mt-2 d-flex mx-lg-1">
        <div className="col-12 col-lg-1">
          {isLoading ? (
            <Skeleton height={100} />
          ) : (
            <img
              className="img-fluid product-detail-image rounded"
              alt=""
              src={`${BASEPATH}${ViewEventImagePath}${EventDetails?.event_image}`}
            />
          )}
        </div>
        <div className="col-12 mb-2 col-lg-11">
          <h4>
            {isLoading ? <Skeleton width={200} /> : EventDetails?.event_title}
          </h4>
          <div className="d-flex mt-2 gap-2 gap-lg-5">
            <div className="despWidth">
              {isLoading ? <Skeleton width={150} /> : <b>Date </b>}
            </div>
            <p className="despSecondWidth">
              {isLoading ? (
                <Skeleton width={150} />
              ) : (
                `${EventDetails?.event_date} - ${EventDetails?.display_time}`
              )}
            </p>
          </div>
          <div className="d-flex gap-2 gap-lg-5">
            <div className="despWidth">
              {isLoading ? <Skeleton width={150} /> : <b>venue </b>}
            </div>
            <p className="despSecondWidth">
              {" "}
              {isLoading ? <Skeleton width={150} /> : EventDetails?.venue_name}
            </p>
          </div>
          <div className="d-flex gap-2 gap-lg-5">
            <div className="despWidth">
              {isLoading ? <Skeleton width={150} /> : <b>Event type </b>}
            </div>
            <p className="despSecondWidth">
              {isLoading ? (
                <Skeleton width={200} />
              ) : (
                `${EventDetails?.main_cate_name} - ${EventDetails?.sub_cate_name}`
              )}
            </p>
          </div>
        </div>
      </div>

      <div>
        {isLoading ? (
          <Skeleton count={10} height={40} />
        ) : (
          <DataGrid
            columnAutoWidth={true}
            dataSource={
              dataSource && dataSource.data[0][0]?.ticket_orders
                ? dataSource.data[0][0]?.ticket_orders
                : []
            }
            showBorders={true}
            alignment="right"
            rowAlternationEnabled={true}
            onExporting={(e) =>
              onExporting(
                e,
                dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]?.seating_option,
                EventDetails?.event_title
              )
            }
            onRowExpanding={(event) => {
              event.component.collapseAll(-1);
            }}
            onRowPrepared={(e) => {
              if (e.rowType === "data" && 
          e.data?.ticket_pass === "" && e.data?.payment_status === "success" ) {
                e.rowElement.style.backgroundColor = "#ffcccc";
              }
            }}
          >
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Sorting mode="multiple" />
            <Scrolling
              className="text-start"
              useNative={true}
              scrollByContent={true}
              scrollByThumb={true}
              showScrollbar="onHover"
            />
            <Column
              caption="S.No"
              alignment="left"
              cellRender={serialNumberCellRender}
              width={80}
            />
            <Column
              caption="View Ticket"
              alignment="left"
              cellRender={renderEyeIcon}
            />


            <Column dataField="payment_on" caption="Date" alignment="left" />
            <Column
              dataField="customer_name"
              caption="Customer Name"
              alignment="left"
            />
            <Column
              dataField="customer_email"
              caption="Customer Email"
              alignment="left"
            />
            <Column
              caption="Action"
              alignment="left"
              cellRender={renderEmailIcon}
            />

            <Column
              dataField="customer_mobile"
              caption="Mobile"
              alignment="left"
            />
            <Column
              dataField="ticket_pass"
              caption="Ticket Number"
              alignment="left"
            />
            <Column
              dataField="payment_response_id"
              caption="Payment Id"
              alignment="left"
            />
            <Column
              dataField="discount"
              caption="Discount"
              alignment="left"
              visible={
                dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                  ?.seating_option !== "numbered"
              }
            />

            <Column
              dataField=""
              caption="Seat Details"
              alignment="left"
              visible={
                dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                  ?.seating_option === "numbered"
              }
              cellRender={generateSeatDetails}
            />

            {maxArray &&
              maxArray.map((slugArray, index) => (
                <Column
                  key={`${slugArray.id}_${slugArray.amount}_${index}`}
                  caption={slugArray.amount}
                  alignment="right"
                  cellRender={(cellInfo) =>
                    AmountDetailsCellRender(cellInfo, slugArray.amount, false)
                  }
                  width={80}
                  visible={
                    dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                      ?.seating_option !== "numbered"
                  }
                />
              ))}

            {uniqueArray &&
              uniqueArray.map((slugArray, index) => (
                <Column
                  key={`${slugArray.id}_${slugArray.amount}_${index}`}
                  caption={slugArray.amount}
                  alignment="right"
                  cellRender={(cellInfo) =>
                    AmountDetailsCellRender(cellInfo, slugArray.amount, true)
                  }
                  width={80}
                  visible={
                    dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                      ?.seating_option === "numbered"
                  }
                />
              ))}
            <Column
              dataField="Gross"
              caption="Gross"
              alignment="right"
              format={{ type: "fixedPoint", precision: 2 }}
              cellRender={generateGrossAmount}
              visible={
                dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                  ?.seating_option !== "numbered"
              }
            />

            <Column
              dataField="tax_amount"
              caption="GST"
              alignment="left"
              visible={
                dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                  ?.seating_option !== "numbered"
              }
            />
            <Column
              dataField="hc_charge"
              caption="Handling Charges"
              alignment="right"
              visible={
                dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                  ?.seating_option !== "numbered"
              }
            />
            <Column
              dataField="hc_tax"
              caption="Handling Charges GST"
              alignment="right"
              visible={
                dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                  ?.seating_option !== "numbered"
              }
            />
            <Column
              dataField="total_amount"
              caption="Total"
              alignment="right"
              format={{ type: "fixedPoint", precision: 2 }}
            />

            <Paging defaultPageSize={10} />
            <Export enabled={true} allowExportSelectedData={true} />
            <Summary>
              <TotalItem
                column="total_amount"
                summaryType="sum"
                displayFormat="Total: {0}"
                valueFormat={{
                  type: "fixedPoint",
                  precision: 2,
                  currency: "INR",
                }}
                alignment="right"
              />
            </Summary>
          </DataGrid>
        )}
      </div>

      {dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]?.seating_option !==
        "numbered" && (
          <div className="pb-5">
            <div className="d-flex justify-content-between mt-lg-3">
              {isLoading ? (
                <Skeleton width={200} />
              ) : (
                <h4 className="col-lg-10 col-2">Summary</h4>
              )}
            </div>

            {isLoading ? (
              <Skeleton height={200} />
            ) : (
              dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]?.seating_option !==
              "numbered" && (
                <DataGrid
                  columnAutoWidth={true}
                  dataSource={NonNumberedDetails || []}
                  showBorders={true}
                  alignment="right"
                  rowAlternationEnabled={true}
                  onExporting={(e) =>
                    onExporting(
                      e,
                      dataSource?.data?.[0]?.[0]?.ticket_orders?.[0]
                        ?.seating_option,
                      EventDetails?.event_title
                    )
                  }
                  onRowExpanding={(event) => {
                    event.component.collapseAll(-1);
                  }}
                >
                  <Paging defaultPageSize={10} />
                  <Pager
                    visible={true}
                    allowedPageSizes={allowedPageSizes}
                    displayMode={"full"}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                  />
                  <FilterRow visible={true} />
                  <HeaderFilter visible={true} />
                  <Sorting mode="multiple" />
                  <Scrolling
                    useNative={true}
                    scrollByContent={true}
                    scrollByThumb={true}
                    showScrollbar="onHover"
                  />
                  <Column
                    dataField="row_name"
                    caption="Category"
                    dataType="date"
                    alignment="right"
                  />
                  <Column
                    dataField="amount"
                    caption="Amount"
                    alignment="right"
                    format={{ type: "fixedPoint", precision: 2 }}
                  />
                  <Column
                    dataField="total_quantity"
                    caption="Total Quantity"
                    alignment="right"
                  />
                  <Column
                    caption="Ticket Sold"
                    alignment="right"
                    calculateCellValue={(rowData) => {
                      const totalQuantity =
                        parseInt(rowData.total_quantity, 10) || 0;
                      const balanceTickets =
                        parseInt(rowData.balance_tickets, 10) || 0;
                      return totalQuantity - balanceTickets;
                    }}
                  />
                  <Column
                    dataField="balance_tickets"
                    caption="Balance Tickets"
                    alignment="right"
                  />
                  <Column
                    caption="Gross"
                    alignment="right"
                    calculateCellValue={(rowData) => {
                      const totalQuantity =
                        parseInt(rowData.total_quantity, 10) || 0;
                      const balanceTickets =
                        parseInt(rowData.balance_tickets, 10) || 0;
                      const ticketSold = totalQuantity - balanceTickets;
                      const amount = parseFloat(rowData.amount) || 0;
                      return ticketSold * amount;
                    }}
                    format={{ type: "fixedPoint", precision: 2 }}
                  />
                  <Summary>
                    <TotalItem
                      column="total_quantity"
                      summaryType="sum"
                      displayFormat="Total: {0}"
                      alignment="right"
                    />

                    <TotalItem
                      column="Gross"
                      summaryType="sum"
                      displayFormat="Total: {0}"
                      valueFormat={{
                        type: "fixedPoint",
                        precision: 2,
                        currency: "INR",
                      }}
                      alignment="right"
                    />
                  </Summary>

                  <Paging defaultPageSize={7} />
                  <Export enabled={true} allowExportSelectedData={true} />
                </DataGrid>
              )
            )}
          </div>
        )}
    </div>
  );
};

export default ViewTicketDetails;
