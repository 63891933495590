import React from "react";
import "./invoiceHtmlTemplate.css";
import DownloadImage from "./Download e-T.png";
import html2canvas from "html2canvas";
import { Link } from 'react-router-dom';

import {
  dayDayinNumberAndYear,
  timeFormater,
} from "../../../utils/dateFormatUtils";
import { FREE_WITH_REGISTRATION_QRCODE } from "../../../global/constants";
import jsPDF from "jspdf";
export const InvoiceHtmlTemplate = ({ htmlElement, data }) => {
  function generatePDF() {
    try {
      const input = document.getElementsByClassName("email-container")[0];
      const downloadButtonImage = document.querySelector(".no-pdf");
  
      // Hide the download image before generating the PDF
      downloadButtonImage.style.display = "none";
  
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.html(input, {
        callback: function (doc) {
          doc.save(`${data?.event_title}.pdf`);
  
          // Show the download image again after PDF is generated
          downloadButtonImage.style.display = "block";
        },
        x: 10,
        y: 10,
        width: 190,
        windowWidth: input.scrollWidth,
        html2canvas: {
          useCORS: true,
        },
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }

  return (
    <>
      <center
        style={{ width: "auto", backgroundColor: "#f1f1f1", margin: "auto" }}
      >
        <div
          style={{
            display: "none",
            fontSize: "1px",
            maxHeight: "0px",
            maxWidth: "0px",
            opacity: 0,
            overflow: "hidden",
            fontFamily: "sans-serif",
          }}
        >
          &zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
        </div>
        <div
          style={{ maxWidth: "600px", margin: "0 auto" }}
          className="email-container"
        >
          <table
            align="center"
            role="presentation"
            cellSpacing="0"
            cellPadding="0"
            border="0"
            width="100%"
            style={{
              margin: "auto",
              background: "#ffffff",
              borderSpacing: "0",
              borderCollapse: "collapse",
              tableLayout: "fixed",
            }}
          >
            <tbody>
              <tr>
                <td
                  valign="top"
                  className="bg_white"
                  style={{
                    background: "#3f00ff",
                    msoTableLspace: "0pt",
                    msoTableRspace: "0pt",
                  }}
                >
                  <table
                    role="presentation"
                    border="0"
                    cellPadding="0"
                    cellSpacing="0"
                    width="100%"
                    style={{
                      borderSpacing: "0",
                      borderCollapse: "collapse",
                      background: "unset",
                      tableLayout: "fixed",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="logo"
                          style={{
                            textAlign: "center",
                            height: "60px",
                            msoTableLspace: "0pt",
                            msoTableRspace: "0pt",
                          }}
                        >
                          <h4
                            style={{
                              color: "#fff",
                              marginBottom: 0,
                              fontWeight: 600,
                            }}
                          >
                            Booking Confirmation
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  valign="middle"
                  className="hero hero-2 bg_white"
                  style={{
                    background: "#f1f1f1",
                    borderRadius: 0,
                    msoTableLspace: "0pt",
                    msoTableRspace: "0pt",
                  }}
                >
                  <table
                    style={{
                      borderSpacing: "0",
                      borderCollapse: "collapse",
                      tableLayout: "fixed",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <div className="text" style={{ textAlign: "center" }}>
                            <img
                              src=""
                              alt=""
                              style={{
                                width: "100%",
                                maxWidth: "600px",
                                height: "auto",
                                margin: "auto",
                                display: "block",
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td
                  className="bg_white"
                  style={{
                    background: "#ffffff",
                    msoTableLspace: "0pt",
                    msoTableRspace: "0pt",
                  }}
                >
                  <table
                    role="presentation"
                    cellSpacing="0"
                    cellPadding="0"
                    border="0"
                    width="100%"
                    style={{
                      borderSpacing: "0",
                      borderCollapse: "collapse",
                      tableLayout: "fixed",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td
                          className="bg_white email-section"
                          style={{
                            msoTableLspace: "0pt",
                            msoTableRspace: "0pt",
                          }}
                        >
                          <div
                            className="heading-section"
                            style={{ textAlign: "left", padding: "0 0px" }}
                          >
                            <p>
                              <span style={{ float: "right" }}>
                                <button onClick={generatePDF}>
                                  <img
                                    src={DownloadImage}
                                    alt="Download e-Ticket"
                                    style={{ width: "auto", height: "5vh" }}
                                    className="no-pdf"
                                  />
                                </button>
                              </span>
                            </p>

                            <h5>Dear {data.name},</h5>
                            <p style={{ color: "black" }}>
                              Here is your e-ticket!
                            </p>
                            <p
                              style={{
                                color: "black",
                                textAlign: "center",
                                marginBottom: 0,
                              }}
                            >
                              {data?.event_title}
                            </p>
                            <p
                              style={{
                                color: "black",
                                textAlign: "center",
                                margin: 0,
                              }}
                            >
                              ID - {data?.ticket_pass}
                            </p>
                            <p
                              style={{
                                textAlign: "center",
                                marginBottom: "0px",
                                marginTop: "1px",
                              }}
                            >
                              <img
                                src={`data:image/png;base64,${data?.base64_logo}`}
                                alt="QR Code"
                              />
                            </p>
                            <p
                              className="mb-1"
                              style={{
                                color: "black",
                                textAlign: "center",
                                margin: "0",
                              }}
                            >
                              Ticket Quantity - {data.ticket_count}
                            </p>
                            <p
                              className="mb-1"
                              style={{
                                color: "black",
                                textAlign: "center",
                                margin: "0",
                              }}
                            >
                              {timeFormater(data.event_time)}{" "}
                              {dayDayinNumberAndYear(data?.event_date)}
                            </p>
                            <p
                              className="mb-3"
                              style={{
                                color: "black",
                                textAlign: "center",
                                margin: "0",
                              }}
                            >
                              Music of Madras
                            </p>
                            <table
                              className="tableBorder"
                              style={{ color: "black", width: "100%" }}
                            >
                              <thead>
                                <tr>
                                  <th className="py-2" colSpan="7">
                                    <center>Booking Summary</center>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    className="py-1"
                                    style={{
                                      textAlign: "center",
                                      width: "20%",
                                    }}
                                  >
                                    SL No
                                  </td>
                                  <td
                                    className="py-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    Seats / Row
                                  </td>
                                  <td
                                    style={{
                                      textAlign: "center",
                                      width: "20%",
                                    }}
                                  >
                                    Qty
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="py-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    1
                                  </td>
                                  <td
                                    className="py-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    Entry Pass
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data?.ticket_count}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <p className="mt-5" style={{ color: "black" }}>
                              Regards
                            </p>
                            <p className="mt-0" style={{ color: "black" }}>
                              Team MDnD.
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <hr style={{ margin: 0 }} />
        </div>
      </center>
      <div>
        <p className="text-center mt-5">
          <Link
            to="/"
            className="text-decoration-none text-decoration-underline"
            style={{ color: "blue" }}
          >
            Back to home
          </Link>
        </p>
      </div>
    </>
  );
};
