import React, { useState } from 'react';
import FileUploader from 'devextreme-react/file-uploader';
import { sizeValues, fontValues, headerValues, fontSizeOptions, fontFamilyOptions, headerOptions } from "../../../components/others";
import HtmlEditor, {
    Toolbar,
    MediaResizing,
    ImageUpload,
    Item,
} from "devextreme-react/html-editor";
import { TagedOrganiserDetailFetch, TagArtistDetailFetch } from './Validation/BlogsFetch.js';
import notify from 'devextreme/ui/notify';
import Button from "devextreme-react/button";
import { TagBox, TextBox, SelectBox } from "devextreme-react";
import { openEditor } from 'react-profile';
import { makeGetRequest, makePostRequest } from '../../../Api/requestMethods';
import { BLOGPOST_UPLOAD, GET_BlOG_CATEGORIES, GET_BlOG_MAIN_CATEGORIES } from '../../../Api/api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DURATION, ERROR, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import { useFormik } from 'formik';
import blogValidationSchema from './Validation/Auth.js';
import NotificationWithRoute from "../../../components/NotificationWithRoute.js"
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
const initialValues = {
    blogtitle: '',
    blogmaincate: '',
    blogcontent: '',
    blogtagart: '',
    blogtagorg: '',
    blogimage: '',
};

const WriteBlogs = () => {
    const [showNotification, setShowNotification] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [imageBase64, setImageBase64] = useState('');
    const [imageName, setImageName] = useState('');
    const [blogData, setBlogData] = useState({
        tagArtistDetail: '',
        tagOrganiserDetail: ""
    });
    const history = useNavigate();
    // -----------------------------------------------------------------------------
    const [user, setUser] = useState(() =>
        localStorage.getItem("MDnD_auth")
          ? jwtDecode(localStorage.getItem("MDnD_auth"))
          : null
      );
      console.log(user, "USER from jwt");
    // ----------------------------------------------------------------------------
    const {
        values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting, setFieldValue, resetForm,
    } = useFormik({
        initialValues: initialValues,
        validationSchema: blogValidationSchema,
        
        onSubmit: async (values, { setSubmitting }) => {
            const updatedValues = {
                ...values,
                blogtagart: values.blogtagart.join(', '), // Convert array to string
                blogtagorg: values.blogtagorg.join(', '),
                loginid: user?.login_id,
                roleid: user?.role_id
            };
            console.log(values,"jkjhlfkshkf")
            try {
                await blogallDetailsMutation.mutateAsync(updatedValues);
                console.log(updatedValues, "C");
                setShowNotification(true);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setSubmitting(false);
                resetForm();
            }
        }
    });

    const handleCloseNotification = () => {
        setShowNotification(false);
    };

    const handleSecondaryAction = () => {
        history('/viewBlogs');
        console.log("Secondary action triggered");
    };

    const now = new Date();
    const [isMultiline, setIsMultiline] = useState(true);
    const [currentTab, setCurrentTab] = useState("base64"); // Assuming "base64" is the initial tab
    const [editorValue, setEditorValue] = useState('');
    const markup = ''; // Assuming initial value is an empty string

    const handleEditorValueChanged = (e) => {
        setEditorValue(e.value);
        handleChange({ target: { name: "blogcontent", value: e.value } });
        handleBlur({ target: { name: "blogcontent", value: e.value } });
    };

    const mainCategoryBlog = async () => await makeGetRequest(GET_BlOG_MAIN_CATEGORIES());
    const {
        data: mainCategoryBlogSource,
        error,
        isLoading,
    } = useQuery({
        queryKey: ["blogmaincate"],
        queryFn: () => mainCategoryBlog(),
        onError: () => {
            notify(error.message, ERROR, DURATION);
        },
    });

    const TagOrgaganiserM = useMutation({
        mutationKey: ["fetchTagedOrganiser"],
        mutationFn: (tagOrganiserDetail) => TagedOrganiserDetailFetch(tagOrganiserDetail),
        onSuccess: (data) => {
            data && setBlogData((prev) => ({ ...prev, tagOrganiserDetail: data.data }));
        },
        onError: (error) => {
            notify(error.message, ERROR, DURATION);
        }
    });

    const TagArtist = useMutation({
        mutationKey: ["fetchTagedArtist"],
        mutationFn: (tagArtistDetail) => TagArtistDetailFetch(tagArtistDetail),
        onSuccess: (data) => {
            data && setBlogData((prev) => ({ ...prev, tagArtistDetail: data.data }));
            console.log(data.data, "dataaaaaaaaaaaaaaaaaaaaaa");
        },
        onError: (error) => {
            notify(error.message, ERROR, DURATION);
        }
    });

    const handleFileChange = async (e) => {
        try {
            const file = e?.value?.[0];
            if (!file) {
                console.log("No file selected");
                return;
            }

            const blobImage = await openEditor({ src: file });
            if (!blobImage || typeof blobImage.editedImage.getBlob !== "function") {
                console.log("No new image");
                return;
            }

            const [blob] = await Promise.all([blobImage.editedImage.getBlob()]);
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                setFieldValue("blogimage", base64String);
                setImageBase64(base64String);
                setImageName(file.name);
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error("Error while processing image:", error);
        }
    };

    const artistinput = (e) => {
        console.log(e.component.option("text"), "eleph");
        const inputValue = e.component.option("text");

        if (inputValue.trim() !== "") {
            const body = {
                artistname: inputValue,
            };
            TagArtist.mutate(body);
        }
    };

    const organiserinput = (e) => {
        console.log(e.component.option("text"), "eleph");
        const inputValue = e.component.option("text");
        if (inputValue.trim() !== "") {
            const body = {
                organisername: inputValue,
            };
            TagOrgaganiserM.mutate(body);
        }
    };

    const blogPostfunction = async (data) => {
        console.log(data, "t");
        try {
            const response = await makePostRequest(BLOGPOST_UPLOAD(), data);
            console.log(response);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const blogallDetailsMutation = useMutation({
        mutationKey: ["blogdetail"],
        mutationFn: (blogdata) => blogPostfunction(blogdata),
        onSuccess: (data) => {
            if (data.status !== 200) {
                notify(data.message, ERROR, DURATION);
                setSuccessMessage(data.message);
            }
            if (data.status === 200) {
                notify(data.message, SUCCESS);
            }
            resetForm();
            console.log(data, 'data');
        },
        onError: (error) => {
            console.log(error, "errorpost");
            notify(error.message, ERROR, DURATION);
        }
    });

    return (
        <form className="tab-content tabBodyBg" onSubmit={handleSubmit}>
            <NotificationWithRoute
                show={showNotification}
                onClose={handleCloseNotification}
                message={successMessage}
                onSecondaryAction={handleSecondaryAction}
            />
            <div id="createevent" className="tab-pane animated fadeInUp active show" role="tabpanel">
                <div className="description-content">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group mb-2">
                                <TextBox
                                    type="text"
                                    name="blogtitle"
                                    className="dx-field-item-required"
                                    labelMode="floating"
                                    label="Title"
                                    value={values.blogtitle}
                                    onFocusOut={(e) =>
                                        handleBlur({
                                            target: { name: "blogtitle", value: e.value },
                                        })
                                    }
                                    onValueChanged={(e) => handleChange({
                                        target: { name: 'blogtitle', value: e.value }
                                    })}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-2">
                                <SelectBox
                                    label=" Main Category"
                                    className="dx-field-item-required"
                                    labelMode="floating"
                                    dataSource={mainCategoryBlogSource?.data}
                                    displayExpr="main_cat_name"
                                    valueExpr="main_cat_id"
                                    onValueChanged={(e) => handleChange({
                                        target: { name: 'blogmaincate', value: e.value }
                                    })}
                                    onFocusOut={(e) =>
                                        handleBlur({
                                            target: { name: "blogmaincate", value: e.value },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group mb-2">
                                <label className="required-label">Blog Content <span className="text-danger">*</span></label>
                                <div className="widget-container">
                                    <HtmlEditor
                                        height="auto"
                                        defaultValue={markup}
                                        label="Blog Description"
                                        onValueChanged={handleEditorValueChanged}
                                    >
                                        <MediaResizing enabled={true} />
                                        <ImageUpload
                                            tabs={currentTab}
                                            fileUploadMode="base64"
                                        />
                                        <Toolbar multiline={isMultiline}>
                                            <Item name="undo" />
                                            <Item name="redo" />
                                            <Item name="separator" />
                                            <Item
                                                name="size"
                                                acceptedValues={sizeValues}
                                                options={fontSizeOptions}
                                            />
                                            <Item
                                                name="font"
                                                acceptedValues={fontValues}
                                                options={fontFamilyOptions}
                                            />
                                            <Item name="separator" />
                                            <Item name="bold" />
                                            <Item name="italic" />
                                            <Item name="strike" />
                                            <Item name="underline" />
                                            <Item name="separator" />
                                            <Item name="alignLeft" />
                                            <Item name="alignCenter" />
                                            <Item name="alignRight" />
                                            <Item name="alignJustify" />
                                            <Item name="separator" />
                                            <Item name="orderedList" />
                                            <Item name="bulletList" />
                                            <Item name="separator" />
                                            <Item
                                                name="header"
                                                acceptedValues={headerValues}
                                                options={headerOptions}
                                            />
                                            <Item name="separator" />
                                            <Item name="color" />
                                            <Item name="background" />
                                            <Item name="separator" />
                                            <Item name="link" />
                                            <Item name="image" />
                                            <Item name="separator" />
                                            <Item name="clear" />
                                            <Item name="codeBlock" />
                                            <Item name="blockquote" />
                                            <Item name="separator" />
                                            <Item name="insertTable" />
                                            <Item name="deleteTable" />
                                            <Item name="insertRowAbove" />
                                            <Item name="insertRowBelow" />
                                            <Item name="deleteRow" />
                                            <Item name="insertColumnLeft" />
                                            <Item name="insertColumnRight" />
                                            <Item name="deleteColumn" />
                                        </Toolbar>
                                    </HtmlEditor>
                                    {errors.blogcontent && touched.blogcontent && <div className="text-danger">{errors.blogcontent}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-2">
                                <TagBox
                                    onInput={artistinput}
                                    type="text"
                                    labelMode="floating"
                                    label="Tag Artists"
                                    dataSource={blogData.tagArtistDetail || null}
                                    displayExpr="artist_name"
                                    valueExpr="artistid"
                                    searchEnabled={true}
                                    value={values.blogtagart}
                                    onValueChanged={(e) =>
                                        handleChange({
                                            target: { name: "blogtagart", value: e.value },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group mb-2">
                                <TagBox
                                    onInput={organiserinput}
                                    type="text"
                                    labelMode="floating"
                                    label="Tag Organiser"
                                    dataSource={blogData.tagOrganiserDetail || null}
                                    displayExpr="organiser_name"
                                    valueExpr="organiserid"
                                    searchEnabled={true}
                                    value={values.blogtagorg}
                                    onValueChanged={(e) =>
                                        handleChange({
                                            target: { name: "blogtagorg", value: e.value },
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-2">
                                <FileUploader
                                    selectButtonText="Select photo"
                                    labelText="" accept="image/*"
                                    uploadMode="useForm"
                                    onValueChanged={handleFileChange}
                                />
                                {imageName && <p>Selected file: {imageName}</p>}
                                {imageBase64 && (
                                    <div>
                                        <img src={imageBase64} alt="Uploaded" style={{ width: '100%', height: 'auto' }} />
                                    </div>
                                )}
                                {errors.blogimage && touched.blogimage && (
                                    <p className="error-message">{errors.blogimage}</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center mt-4 iq-button">
                <Button
                    type="submit"
                    text="Submit"
                    className="btn position-relative h-auto"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                />
            </div>
        </form>
    );
};

export default WriteBlogs;
