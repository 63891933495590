import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import blogspa from "./constant.js";
import { useMutation } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { DURATION, ERROR } from "../../../global/ToastMsgConstants.tsx";
import { makePostRequest } from "../../../Api/requestMethods.js";
import { useLocation } from "react-router-dom";
import { GET_BLOG_PERTICULAR_CATEGORY } from "../../../Api/api.js";
import { BlogImage } from "../../../Subpages/MainPage/constant.js";





export default function BlogCategory() {
  const navigate = useNavigate();
  const location = useLocation();
  const blog_main_cate_id = location?.state?.blog_main_cate_id;

  const [selectedGenre, setSelectedGenre] = useState("");

  const [dataSource, setDataSource] = useState({
    allBlogData: "",
  });

  const handleClick = (title) => {
    console.log(title,"tiiionodjfsn")
    setSelectedGenre(title);
    navigate(`/blog/${title.blog_name}`,{
      state:{title,blogid:title.blog_id}
    });
    console.log(title,"title")
  };
  // ------------------------------------AllBlogSecond--------------------------------------------
  const AllBlogFetch = async(blogDetail)=>{
    try{
      const response = await makePostRequest(GET_BLOG_PERTICULAR_CATEGORY(),blogDetail);
      return response;
    }catch (error){
      throw error;
    }
  }

  const AllBlogMutation = useMutation({
    mutationKey:["fetchBlogAll"],
    mutationFn: (data)=> AllBlogFetch(data),
    onSuccess: (data)=>{
      data && setDataSource((prev)=>({...prev,allBlogData:data.data}))
    },
    onError:(error)=>{
      notify(error.message,ERROR,DURATION);
    },
  });
useEffect(()=>{
  if(blog_main_cate_id && blog_main_cate_id !== "" && blog_main_cate_id !== null){
    const body ={
      blog_main_cate_id : blog_main_cate_id,
    };
    AllBlogMutation.mutate(body);
  }
},[blog_main_cate_id])


console.log(dataSource.allBlogData,"fhgjgj")

// ------------------------------------------------------------------------------------------------------

  return (
    <>
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-2 sideBarFilter">
          <div className="filterBg">
            <h5>Discover Events</h5>
            <select className="mt-0 p-1 w-100">
              <option value="someOption">India</option>
              <option value="otherOption">America</option>
            </select>

            <select className="mt-2 p-1 w-100">
              <option value="someOption">Chennai - Tamil Nadu</option>
              <option value="otherOption">Mumbai</option>
            </select>
          </div>
        </div>
        <div className="col-xl-10">
          <div className="section-padding pt-3">
            <div className="">
              <div className="row row-cols-lg-5 row-cols-md-2 row-cols-1">
                {dataSource && dataSource?.allBlogData && dataSource?.allBlogData?.map((blog, index) => (
                  <div className="col" key={index}>
                    <div
                      className="iq-blog-box"
                      onClick={() => handleClick(blog)}
                    >
                      <div className="iq-blog-image clearfix">
                        <a href={blog.link}>
                          <img
                            src={`${BlogImage}/${blog.blog_photo}`}
                            alt={`blogImg-${index}`}
                            className="img-fluid w-100"
                          />
                        </a>
                      </div>
                      <div className="iq-blog-detail">
                        <a href={blog.link}>
                          <h6 className="mb-2 line-count-1">{blog.blog_description.replace(/<\/?[^>]+(>|$)/g, "")}</h6>
                        </a>
                        <p className="line-count-2">{blog.main_cate_name}</p>
                        <div className="iq-button link-button">
                          <a
                            onClick={() => handleClick(blog)}
                            className="btn text-capitalize position-relative"
                          >
                            <span className="button-text">
                              Read More{" "}
                              <i
                                className="fa fa-angle-right ml-2"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
