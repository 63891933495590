import axios from "axios";
import { getAPIKey } from "./getApiKey";
import { BASEPATH } from "../config/config";

const appAxios = axios.create({
  baseURL: BASEPATH,
  timeout: 60000,
  responseType: "json",
});
appAxios.interceptors.request.use(
  async (config) => {
    const apiKey = getAPIKey();
    if (apiKey) {
      config.headers["MDnD_auth"] = apiKey;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default appAxios;
