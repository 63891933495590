import React, { useCallback, useState, useEffect } from "react";
import DataGrid, {
  Column,
  Scrolling,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import { SelectBox } from "devextreme-react";
import IndividualMemberModal from "./IndividualMemberModal";
import EditMemberModal from "./EditMemberModal";
import BulkMemberModal from "./BulkMemberModal";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import Skeleton from "react-loading-skeleton"; // Import the Skeleton loader
import "react-loading-skeleton/dist/skeleton.css"; // Import Skeleton styles
import {
  makeDeleteRequest,
  makeGetRequest,
} from "../../../../Api/requestMethods";
import { MAILER_MEMBERS_DELETE } from "../../../../Api/api";
import notify from "devextreme/ui/notify";
import { DURATION, ERROR } from "../../../../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";

const MailMember = () => {
  const [showIndividualModal, setShowIndividualModal] = useState(false);
  const [showMemberEditModal, setShowMemberEditModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [selectedMemberType, setSelectedMemberType] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [mdndData, setMdndData] = useState([]);
  const [limit] = useState(10);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedMember, setSelectedMember] = useState(null);

  const loadMoreData = useCallback(async (offset = 0) => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      const GET_MEMBERS_LIST = `mailer/members/1/read_all/${limit}/${offset}`;
      const newRecords = await makeGetRequest(GET_MEMBERS_LIST);
      setMdndData(newRecords.data);
      setTotalCount(newRecords.totalCount);
      setCurrentOffset(offset);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  }, [isFetching, limit]);

  useEffect(() => {
    loadMoreData();
  }, []);

  const handleOpenEditModal = (data) => {
    setSelectedMember(data);
    setShowMemberEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowMemberEditModal(false);
    setSelectedMember(null);
    loadMoreData(currentOffset);
  };

  const deleteMemberMutation = useMutation({
    mutationFn: async (memberId) => {
      await makeDeleteRequest(`${MAILER_MEMBERS_DELETE()}/${memberId}`);
    },
    onError: (error) => notify(error.response.data.message, ERROR, DURATION),
  });

  const onDeleteButtonClick = async (memid) => {
    const memberRowid = memid.id;
    try {
      await deleteMemberMutation.mutateAsync(memberRowid);
    } catch (error) {
      notify(error, ERROR, DURATION);
    }
  };

  return (
    <div className="d-flex flex-column gap-3 tab-content tabBodyBg">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div></div>
        <div className="form-group mb-2 col-4 col-lg-3 col-xl-2">
          <SelectBox
            items={[
              { id: 1, name: "Individual" },
              { id: 2, name: "Bulk" },
            ]}
            label="Add Members"
            displayExpr="name"
            valueExpr="name"
            value={selectedMemberType}
            onValueChanged={(e) => {
              if (e.value === "Individual") {
                setShowIndividualModal(true);
              } else if (e.value === "Bulk") {
                setShowBulkModal(true);
              }
            }}
          />
        </div>
      </div>

      <div className="w-100% container px-0 mx-0">
        {isFetching ? (
          // Show Skeleton loader when data is being fetched
          <Skeleton count={10} height={40} style={{ marginBottom: '10px' }} />
        ) : (
          <DataGrid
            dataSource={mdndData}
            showBorders={true}
            columnAutoWidth={true}
            rowAlternationEnabled={true}
            width="100%"
            onPageChanged={(e) => loadMoreData((e.pageIndex - 1) * limit)}
          >
            <Scrolling mode="standard" />
            <Paging enabled={true} />
            <Pager
              visible={true}
              showPageSizeSelector={true}
              showInfo={true}
              displayMode="full"
              showNavigationButtons={true}
            />
            <Column
              width={80}
              dataField=""
              caption="S.no"
              cellRender={({ rowIndex }) => (
                <div>{currentOffset + rowIndex + 1}</div>
              )}
            />
            <Column dataField="membership_id" caption="Member ID" />
            <Column dataField="member_name" caption="Member Name" />
            <Column dataField="member_email" caption="Email" />
            <Column dataField="membership_type" caption="Member Type" />
            <Column dataField="member_mobile" caption="Phone No" />
            <Column
              caption="Edit"
              cellRender={({ data }) => (
                <div>
                  <MdModeEditOutline
                    className="EditIcon"
                    onClick={() => handleOpenEditModal(data)}
                  />
                </div>
              )}
            />
            <Column
              caption="Delete"
              cellRender={({ data }) => (
                <div>
                  <RiDeleteBinLine
                    className="DeleteIcon"
                    onClick={() => onDeleteButtonClick(data)}
                  />
                </div>
              )}
            />
            <Column dataField="member_address" caption="Address" />
            <Column dataField="ticket_qty" caption="Ticket Quantity" />
          </DataGrid>
        )}
        <EditMemberModal
          show={showMemberEditModal}
          handleClose={handleCloseEditModal}
          memberData={selectedMember}
        />
      </div>

      <IndividualMemberModal
        show={showIndividualModal}
        handleClose={() => setShowIndividualModal(false)}
      />
      <BulkMemberModal
        show={showBulkModal}
        handleClose={() => setShowBulkModal(false)}
      />
    </div>
  );
};

export default MailMember;
