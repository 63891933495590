import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TextBox, SelectBox, DateBox, Button } from 'devextreme-react';
import { makeDeleteRequest, makeGetRequest, makePostRequest, makePutRequest } from '../../../../Api/requestMethods';
import { COUNTRY, STATE, CITY, UPDATE_BLOG_CATEGORY, MAILER_MEMBERS_UPDATE_DETAIL, MAILER_MEMBERS_DELETE_DETAIL } from '../../../../Api/api';
import { useQuery, useMutation } from '@tanstack/react-query';
import notify from 'devextreme/ui/notify';
import { DURATION, ERROR, SUCCESS } from '../../../../global/ToastMsgConstants.tsx';

function EditMemberModal({ show, handleClose, memberData }) {

  console.log(memberData, "memberData");

  const [formData, setFormData] = useState({
    id: "",
    membership_id: "",
    member_name: "",
    member_email: "",
    membership_type: "",
    member_mobile: "",
    member_address: "",
    membership_date: "",
    country: null,
    state: null,
    city: null,
    stateDataSource: [],
    cityDataSource: [],
    ticket_qty: "",
    status: "Y",
  });

  const [countryFocused, setCountryFocused] = useState(false);
  const [stateFocused, setStateFocused] = useState(false);
  const [cityFocused, setCityFocused] = useState(false);

  useEffect(() => {
    if (memberData) {
      const initialFormData = {
        id: memberData.id || "",
        membership_id: memberData.membership_id || "",
        member_name: memberData.member_name || "",
        member_email: memberData.member_email || "",
        membership_type: memberData.membership_type || "",
        member_mobile: memberData.member_mobile || "",
        member_address: memberData.member_address || "",
        membership_date: memberData.membership_date || "",
        country: memberData.country || null,
        state: memberData.state || null,
        city: memberData.city || null,
        stateDataSource: [], // Initialize as empty array
        cityDataSource: [], // Initialize as empty array
        ticket_qty: memberData.ticket_qty || "",
        status: memberData.status || "Y",
      };

      if (memberData.state) {
        stateMutation.mutate(memberData.state); // Fetch state data
      }

      if (memberData.city) {
        cityMutation.mutate(memberData.city); // Fetch city data
      }

      setFormData(initialFormData);
    }
  }, [memberData]);


  const { data: countryDataSource = [] } = useQuery({
    queryKey: ["country"],
    queryFn: () => makeGetRequest(COUNTRY()).then(res => res.data),
    onError: (error) => notify(error.response.data.message, ERROR, DURATION),
  });

  const fetchStates = async (country_id) => {
    const response = await makePostRequest(STATE(), { country_id });
    return response.data;
  };

  const fetchCities = async (state_id) => {
    const response = await makePostRequest(CITY(), { state_id });
    return response.data;
  };

  const stateMutation = useMutation({
    mutationFn: (country_id) => fetchStates(country_id),
    onSuccess: (data) => {
      setFormData(prev => ({ ...prev, stateDataSource: data }));
      if (memberData && memberData.state) {
        cityMutation.mutate(memberData.state);
      }
    },
    onError: (error) => notify(error.response.data.message, ERROR, DURATION),
  });

  const cityMutation = useMutation({
    mutationFn: (state_id) => fetchCities(state_id),
    onSuccess: (data) => {
      setFormData(prev => ({ ...prev, cityDataSource: data }));
    },
    onError: (error) => notify(error.response.data.message, ERROR, DURATION),
  });


  useEffect(() => {
    if (show && formData.country) {
      stateMutation.mutate(formData.country);
    }
    if (show && formData.state) {
      cityMutation.mutate(formData.state);
    }
  }, [show]);

  useEffect(() => {
    if (countryFocused) {
      // Fetch country data if it's not already fetched
    }
  }, [countryFocused]);

  useEffect(() => {
    if (stateFocused && formData.country) {
      stateMutation.mutate(formData.country);
    }
  }, [stateFocused, formData.country]);

  useEffect(() => {
    if (cityFocused && formData.state) {
      cityMutation.mutate(formData.state);
    }
  }, [cityFocused, formData.state]);

  const handleFormSubmit = async () => {
    const { id, membership_id, member_name, member_email, membership_type, member_mobile, member_address, membership_date, country, state, city, ticket_qty, status } = formData;
    const updatedValues = {
      id,
      membership_id,
      member_name,
      member_email,
      membership_type,
      member_mobile,
      member_address,
      membership_date,
      membership_date,
      country,
      state,
      city,
      ticket_qty,
      status
    }

    try {
      await MemberDetailUpdateDetail.mutateAsync(updatedValues);
      handleClose();
    } catch (error) {
      notify(error, ERROR, DURATION);
    }

  };
  // --------------------------------------------EditMutaion------------------------------------------------
  const MemberUpdateFunction = async (data) => {
    try {
      const response = await makePutRequest(MAILER_MEMBERS_UPDATE_DETAIL(), data)
      return response
    } catch (error) {
      throw error
    }
  }
  const MemberDetailUpdateDetail = useMutation({
    mutationKey: ["memberDetail"],
    mutationFn: (memberData) => MemberUpdateFunction(memberData),
  })

  // --------------------------------------------------------------------------------------------

  console.log(formData, "formDATA")

  return (
    <Modal show={show} onHide={handleClose} size="lg">

      <Modal.Header closeButton
        className="m-0 d-flex align-items-center text-center border-light"
        style={{ padding: "10px 10px", }}
      >
        <h5 className="text-nowrap text-center m-auto">Edit Individual Member</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="row row-cols-1 row-cols-lg-2">
          {['membership_id', 'member_name', 'membership_type', 'member_email', 'member_mobile', 'member_address', 'ticket_qty'].map(field => (
            <div className="form-group mb-2" key={field}>
              <TextBox
                type="text"
                name={field}
                className="dx-field-item-required"
                required="required"
                label={field.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
                labelMode="floating"
                value={formData[field]}
                onValueChanged={(e) => setFormData(prev => ({ ...prev, [field]: e.value }))}
              />
            </div>
          ))}
          <div className="form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              dataSource={countryDataSource}
              displayExpr="country_name"
              valueExpr="country_id"
              label="Country"
              labelMode="floating"
              defaultValue={typeof formData.country === 'string' ? Number(formData.country) : formData.country}
              onValueChanged={(e) => {
                setFormData(prev => ({ ...prev, country: e.value, state: null, city: null }));
                stateMutation.mutate(e.value);
              }}
              onFocusIn={() => setCountryFocused(true)}
            />
          </div>
          <div className="form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              dataSource={formData.stateDataSource || []}
              displayExpr="state_name"
              valueExpr="state_id"
              label="State"
              labelMode="floating"
              defaultValue={typeof formData.state === 'string' ? Number(formData.state) : formData.state}
              onValueChanged={(e) => {
                setFormData(prev => ({ ...prev, state: e.value, city: null }));
                cityMutation.mutate(e.value);
              }}
              onFocusIn={() => setStateFocused(true)}
            />
          </div>
          <div className="form-group mb-2">
            <SelectBox
              className="dx-field-item-required"
              dataSource={formData.cityDataSource || []}
              displayExpr="city_name"
              valueExpr="city_id"
              label="City"
              labelMode="floating"
              defaultValue={Number(formData.city)}
              onValueChanged={(e) => setFormData(prev => ({ ...prev, city: e.value }))}
              onFocusIn={() => setCityFocused(true)}
            />
          </div>
          <div className="form-group mb-2">
            <DateBox
              type="date"
              className="dx-field-item-required"
              label="Membership Date"
              labelMode="floating"
              value={formData.membership_date}
              onValueChanged={(e) => setFormData(prev => ({ ...prev, membership_date: e.value }))}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className='CancelBtn' variant="secondary" onClick={handleClose}>
          Close
        </button>
        <button className='button' variant="primary" onClick={handleFormSubmit} disabled={MemberDetailUpdateDetail.isLoading}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditMemberModal;
