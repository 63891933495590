import DataGrid, { Column, Paging, Scrolling, Pager } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { makeGetRequest, makePostRequest, makePutRequest } from "../../../Api/requestMethods";
import { useMutation, useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { DURATION, ERROR, SUCCESS } from "../../../global/ToastMsgConstants.tsx";
import { EVENT_MAIN_CATEGORY, GET_ADDS_DETAILS, GET_PERTICULAR_USER, UPDATE_ADS_VIDEOSEDIT_DATA, UPDATE_ADS_VIDEOSEDIT_DATA_IFONLINE } from '../../../Api/api.js';
import { useState, useEffect,useRef} from 'react';
import { Modal } from 'react-bootstrap';
import { DateBox, TagBox, TextBox, SelectBox } from 'devextreme-react';
import { FaRegWindowClose } from "react-icons/fa";
import { MDnDUploadRecordsCDN } from '../../../config/config.js';
import { jwtDecode } from 'jwt-decode';
import { redirect } from 'react-router-dom';
import TopUpPopup from './TopUpData.js';
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useParams } from 'react-router-dom';
const planType = [
  { state_id: 1, state_name: "View Type" },
  { state_id: 2, state_name: "Duration Type" },
];


function ViewAds() {
  const {encodeData}= useParams();
  const [category, setCategory] = useState({
    openThePopup: false,
    hasFocusedIn: false,
    isVideoFileChange:false,
  });

  const [formData, setFormData] = useState({
    id:"",
    add_title: "",
    record_categories: [],
    plan_type: "",
   impressions_promised : "",
    start_date: "",
    payable_inr: "",
    payable_usd: "",
    total_amount:"",
    end_date: "",
    payment_mode: "",
    currency: "",
    redirect_link: "",
    cheque_number:"",
    currency: ""
  });
  const [videoUrl, setVideoUrl] = useState('');
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadedVideoURL, setUploadedVideoURL] = useState("");
  const [userInformation,setUserInformation] = useState({})
  const [info, setInfo] = useState(null);
// --------------------------------------------------------------------------------------------
const [topUpModealOpen, setTopUpModalOpen] = useState(false);
const [selectedDataForTopUp, setSelectedDataForTopUp] = useState(null);
// --------------------------------------------------------------------------------------------
  
  const fileInputRef = useRef(null);
   

  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );

 const loginid = user.login_id;
 const roleid = user.role_id;
 //------------------------------------------------------------------------------------------------------
 useEffect(() => {
  const pathname = window.location.pathname;
  const segments = pathname.split('/');
  const base64Segment = segments[segments.length - 1];
  const decodedString = atob(base64Segment);
  try {
    const json = JSON.parse(decodedString);
    setInfo(json);
  } catch (error) {
    console.error('Error decoding or parsing JSON:', error);
    setInfo(null);
  }
}, []);






// ----------------------------------FetchingDetails----------------------------------------------------------
   
  const fetchingAdsDetail = async () => await makeGetRequest(`advertisement/video/${roleid}/read_all`);
  const { data: AddsSource } = useQuery({
    queryKey: ["addsDetail"],
    queryFn: () => fetchingAdsDetail(),
    onError: (error) => {
      console.error("Error fetching ads", error);
    },
    onSuccess: (data) => {
      notify(SUCCESS, DURATION)
    }
  });

  useEffect(() => {
    if (info?.aid && AddsSource?.data) {
      // Find the ad that matches the aid
      const ad = AddsSource.data.find(ad => ad.advertisement_id === info.aid);
  
      console.log(ad, "ADDSDETAIL");
  
      if (ad) {
        let total_amount = "";
        let currency = "";
  
        if (ad.payable_inr > 0) {
          total_amount = ad.payable_inr;
          currency = "inr";
        } else if (ad.payable_usd > 0) {
          total_amount = ad.payable_usd;
          currency = "usd";
        }
  
        setFormData({
          id: ad.advertisement_id,
          add_title: ad.title,
          record_categories: ad.record_categories,
          plan_type: ad.plan_type,
          impressions_promised: ad.impressions_promised,
          start_date: ad.start_date,
          end_date: ad.end_date,
          payable_inr: ad.payable_inr,
          payable_usd: ad.payable_usd,
          total_amount: total_amount,
          payment_mode: ad.payment_mode,
          currency: currency,
          redirect_link: ad.redirect_link,
          cheque_number: ad.cheque_number,
        });
        setCategory(prev => ({ ...prev, openThePopup: true }));
      }
    }
  }, [info, AddsSource]);
  

  const getPlanType = (data) => {
    if (data.impressions_promised) {
      return "View Type";
    } else if (data.start_date && data.end_date) {
      return "Duration Type";
    } else {
      return "Unknown";
    }
  };

  const getDurationViews = (data) => {
    if (data.impressions_promised) {
      return `${data.impressions_promised} views`;
    } else if (data.start_date && data.end_date) {
      const startDate = new Date(data.start_date);
      const endDate = new Date(data.end_date);
      const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()} (${totalDays} days)`;
    } else {
      return "N/A";
    }
  };

  const onEditButtonClick = async (data) => {
    console.log(data, "this is data");
    const {
      title,
      record_categories,
      impressions_promised,
      start_date,
      end_date,
      payable_inr,
      payable_usd,
      redirect_link,
      payment_mode,
      ads_url,
      ads_title,
      advertisement_id,
      cheque_number
    } = data;
    setFormData({
      id:advertisement_id,
      add_title: title,
      record_categories: record_categories,
      impressions_promised: impressions_promised,
      start_date: start_date,
      end_date: end_date,
      payable_inr: payable_inr,
      payable_usd: payable_usd,
      redirect_link: redirect_link,
      payment_mode:payment_mode,
      cheque_number:cheque_number,
      plan_type: impressions_promised ? 1 : 2,
      currency: payable_inr ? "inr" : payable_usd ? "usd" : "",
      total_amount: payable_inr ? payable_inr : payable_usd ? payable_usd : ""

    });
    if (info?.aid) {
      setCategory(prev => ({ ...prev, openThePopup: true }));
    } else {
    setCategory(prev => ({ ...prev, openThePopup: true }));
    }
  };

  useEffect(() => {
    if (formData.impressions_promised === null) {
      setFormData(prev => ({ ...prev, plan_type: 2 }));
    } else if (formData.start_date === null && formData.end_date === null) {
      setFormData(prev => ({ ...prev, plan_type: 1 }));
    }
  }, [formData.impressions_promised, formData.start_date, formData.end_date]);

  const ExitPopup = () => {
    setCategory(prev => ({ ...prev, openThePopup: false }));
  };

  

  const TargetCategories = async () => (await makeGetRequest(EVENT_MAIN_CATEGORY));

  const {
    data: TargetCategoriesData,
    isLoading,
  } = useQuery({
    queryKey: ["tagetcategoryads"],
    queryFn: () => TargetCategories(),
    onError: (errors) => {
      notify(errors.message, ERROR, DURATION)
    }
  });
  console.log(TargetCategoriesData, "TargetCategoriesData");
// -----------------------------------------------------------------------------------------------------------
const fetchUserInformation = async (loginid) => {
  const endpoint = `${GET_PERTICULAR_USER()}=${loginid}`;
  return await makeGetRequest(endpoint);
}

const { data} = useQuery({
  queryKey: ['userInfo', loginid],
  queryFn: () => fetchUserInformation(loginid),
  onError: (error) => {
    console.log(error);
  }
});

useEffect(() => {
  setUserInformation(data?.data);
}, []);

console.log(userInformation,"USERINFORMATION")

// -------------------------------------------------------------------------------------------------------------

const handleUploadClick = () => {
  fileInputRef.current.click();
};

const handleVideoUpload = async (event) => {
  const file = event.target.files[0];
  if (file)  {
    const formData = new FormData();
    formData.append('video', file);

    try {
      // Simulate upload progress
      simulateUploadProgress(file);

      // Make POST request to CDN server to upload video
      const response = await fetch(MDnDUploadRecordsCDN, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload video');
      }

      const data = await response.json(); // Assuming server returns JSON with CDN link
      const cdnLink = data.path; // Adjust based on actual response structure

      // Update form field with CDN link and video name
      setUploadedVideo({ file, name: file.name });
      setUploadedVideoURL(cdnLink);
      
    } catch (error) {
      console.error('Error uploading video:', error);
      notify('Failed to upload video', ERROR, DURATION);
    }
  }
};

const simulateUploadProgress = (file) => {
  // Simulate progress with setInterval
  let progress = 0;
  const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);

      if (progress >= 100) {
          clearInterval(interval);
          setUploadProgress(100); // Ensure progress bar shows 100% at completion
          setUploadedVideo(file); // Store uploaded video for display

          setTimeout(() => {
            notify('Video uploaded successfully', 'SUCCESS', 'DURATION',3000);
        }, 1000);
      }
  }, 500);
};

  // -------------------------------------------
  const handleCancelUpload = () => {
    setUploadedVideo(null);
    setUploadProgress(null);
    setUploadedVideoURL("");
    fileInputRef.current.value = null;  // Clear the file input value
  };
// ------------------------------------------HandelUpdateSubmittion----------------------------------------------
const adeEditVideo = async(data)=>{
  try{
   const response = await makePutRequest(UPDATE_ADS_VIDEOSEDIT_DATA(),data);
   return response
  }catch(error){
    throw error;
  }
}
 const    addEditAddsUplodMutation = useMutation({
  mutationKey:["addsEdit"],
  mutationFn:(adsData)=>adeEditVideo(adsData),

    onSuccess: (data) => {
            if (data.status !== 200) {
              notify(data.message, ERROR, DURATION);
            }
            if (data.status === 200) {
              notify(data.message, SUCCESS);
            }
          },
          onError: (error) => {
            console.log(error, "errorpost");
            notify(error.message, ERROR, DURATION);
          },
   })
// -----------------------------------------IFonline------------------------------------------------------

const handleSubmit = async ()=>{
 try{
   if(uploadedVideo){
     const updatedValues ={
        id:formData.id,
        status: true,
        record_categories : formData.record_categories,
        redirect_link : formData.redirect_link,
        add_url: uploadedVideoURL,
     }
    try{
      await addEditAddsUplodMutation.mutateAsync(updatedValues)
    }catch(error){
      console.log(error,"Error")
    }
  }
 }catch(error){
 
 }
}
// --------------------------------------------TopDATA-----------------------------------------------------------------
  const onTopUpButtonClick=(data)=>{
    setSelectedDataForTopUp(data);
    setTopUpModalOpen(true);
  }

  const closeTopUpModel = () => {
    setSelectedDataForTopUp(null);
    setTopUpModalOpen(false);
  };
// -------------------------------------------------------------------------------------------------------------
  return (
    <div id="data-grid-demo">
      <DataGrid
        dataSource={AddsSource?.data}
        showBorders={true}
        columnAutoWidth={true}
        columnHidingEnabled={true}
        width="100%"
      >
        <Scrolling rowRenderingMode='virtual'></Scrolling>
        <Paging defaultPageSize={4} />
        <Pager
          visible={true}
          showPageSizeSelector={true}
          showInfo={true}
        />
        <Column width={80} dataField="id" caption="S.no" />
        <Column dataField="title" caption="Title" />
        <Column caption="Plan Type" cellRender={({ data }) => getPlanType(data)} />
        <Column caption="Duration/Views" cellRender={({ data }) => getDurationViews(data)} />
        <Column caption="TopUP" cellRender={({ data }) => (
          <div>
            <Button
              text="topUp"
              type='default'
              onClick={()=>onTopUpButtonClick(data)}
            />
          </div>
        )}
        />
        <Column caption="Edit" cellRender={({ data }) => (
          <div>
          <MdModeEditOutline
            className="EditIcon"
            onClick={() => onEditButtonClick(data)}
          />
        </div>
        )}
        />
        <Column caption='Delete' cellRender={({ data }) => (
          <div>
          <RiDeleteBinLine className="DeleteIcon"

          />
        </div>
          // <div>
          //   <Button
          //     icon="trash"
          //     type="default"
          //   />
          // </div>
        )}
        />
      </DataGrid>
      <Modal
        size='lg'
        show={category.openThePopup}
        centered
        onHide={ExitPopup}
      >
       
        <Modal.Header closeButton
        className="m-0 d-flex align-items-center text-center border-light"
        style={{ padding: "10px 10px", }}
      >
        <h5 className="text-nowrap text-center m-auto">Edit Ads</h5>
      </Modal.Header>
        
       
        <Modal.Body>
          <div className='row'>
            <div className="col-lg-6 form-group mb-3">
              <TextBox
                className="dx-field-item-required"
                label='Title'
                value={formData.add_title}
                labelMode='floating'
                onValueChanged={(e) => setFormData(prev => ({ ...prev, add_title: e.value }))}
              />
            </div>
            <div className="col-lg-6 form-group mb-3">
              <TagBox
                label="Target Category"
                labelMode='floating'
                dataSource={TargetCategoriesData?.data || null}
                defaultValue={formData.record_categories}
                displayExpr="main_cat_name"
                valueExpr="main_cat_id"
                onValueChanged={(e) => setFormData(prev => ({ ...prev, record_categories: e.value }))}
              />
            </div>
            <div className="col-lg-6 form-group mb-3">
              <SelectBox
                items={planType}
                className="dx-field-item-required"
                label="Plan Type"
                labelMode="floating"
                valueExpr="state_id"
                displayExpr="state_name"
                value={formData.plan_type}
                onValueChanged={(e) => setFormData(prev => ({ ...prev, plan_type: e.value }))}
                readOnly={true}
              />
            </div>
            {
            formData. plan_type === 1 &&(
            <div className="col-lg-6 form-group mb-3">
              <TextBox
                label="View count"
                className="dx-field-item-required"
                labelMode="floating"
                value={formData.impressions_promised || ""}
                onValueChanged={(e) => setFormData(prev => ({ ...prev, impressions_promised: e.value }))}
                readOnly={true}
              />
            </div>
            )}
            {
              formData.plan_type === 2 && (
                <>
            <div className="col-lg-6 form-group mb-3">
              <DateBox
                className="dx-field-item-required"
                label="Start date"
                labelMode="floating"
                value={formData.start_date}
                onValueChanged={(e) => setFormData(prev => ({ ...prev, start_date: e.value }))}
              />
            </div>
            <div className="col-lg-6 form-group mb-3">
              <DateBox
                className='dx-field-item-required'
                label='End date'
                labelMode='floating'
                value={formData.end_date}
                onValueChanged={(e) => setFormData(prev => ({ ...prev, end_date: e.value }))}
                readOnly={true}
              />
            </div>
            </>
             )} 
            <div className="col-lg-6 form-group mb-3">
              <TextBox
                label='Total Amount'
                className='dx-field-item-disabled'
                labelMode='floating'
                value={formData.total_amount}
                readOnly={true}
              />
            </div>
            <div className="col-lg-6 form-group mb-3">
              <SelectBox
                items={[
                  { id: "cheque", name: "Cheque" },
                  { id: "online", name: "Online" }
                ]}
                value={formData.payment_mode}
                label='Payment mode'
                className='dx-field-item-disabled'
                labelMode="floating"
                displayExpr="name"
                valueExpr="id"
                onValueChanged={(e) => setFormData(prev => ({ ...prev, payment_mode: e.value }))}
                readOnly={true}
              />
            </div >
            {
              formData.payment_mode === "cheque" && (
            <div className="col-lg-6 form-group mb-3">
              <TextBox
                value={formData.cheque_number}
                type="text"
                labelMode='floating'
                className='dx-field-item-required'
                required ="required"
                label="Cheque Number"
                readOnly={true}
              />
            </div>
             )} 
             <div className="col-lg-6 form-group mb-3">
              <SelectBox
                items={[
                  { id: "inr", name: "INR" },
                  { id: "usd", name: "USD" }
                ]}
                value={formData.currency}
                label='Currency'
                className='dx-field-item-required'
                labelMode='floating'
                displayExpr="name"
                valueExpr="id"
                onValueChanged={(e) => setFormData(prev => ({ ...prev, currency: e.value }))}
                readOnly={true}
              />
            </div>
            <div className="col-lg-6 form-group mb-3">
              <TextBox
                type="text"
                label='Click through link'
                labelMode="floating"
                value={formData.redirect_link}
                onValueChanged={(e) => setFormData(prev => ({ ...prev, redirect_link: e.value }))}
                readOnly={true}
              />
            </div>
   {/* ------------------------------------videoPart--------------------------------------------- */}
   <div className="col-lg-12 mx-auto">
          <div className="wrappers">
          <header className="d-flex justify-content-between align-items-center">
    <span>Upload Video</span>
           
              <FaRegWindowClose onClick={handleCancelUpload}/>
           </header>
            {uploadedVideo ? (
              <div className="mt-3">
                <video controls style={{ width: '100%' }}>
                <source src={uploadedVideoURL} type="application/x-mpegURL" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <div
                className="upload-area"
                onClick={handleUploadClick}
                style={{ cursor: 'pointer', textAlign: 'center' }}
              >
                <form action="#">
                  <i className="fas fa-cloud-upload-alt"></i>
                  <p className="mx-auto text-center">Drag and drop or browse videos to upload</p>
                  <input
                    className="file-input"
                    type="file"
                    name="file"
                    accept="video/*"
                    ref={fileInputRef}
                    onChange={handleVideoUpload}
                    hidden
                  />
                </form>

              </div>
            )}
            <p>Video Name: {uploadedVideo?.name}</p>
            <section className="progress-area">
              {uploadProgress !== null && (
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${uploadProgress}%`, backgroundColor: uploadProgress === 100 ? 'green' : 'green' }}
                    aria-valuenow={uploadProgress}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {uploadProgress}%
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
                    <button className='CancelBtn' varient="secondary" onClick={() => ExitPopup()}>
                        Cancel
                    </button>
                    <button className='button' varient="secondary" onClick={handleSubmit} >
                        Submit
                    </button>
                </Modal.Footer>
      </Modal>
      <TopUpPopup
        data={selectedDataForTopUp}
        isOpen={topUpModealOpen}
        onClose={closeTopUpModel}
      />
    </div>
  );
}

export default ViewAds;
