const DashboardRoutes = [
  {
    name: "Dashboard",
    route: "dashboard",
    icon: "fas fa-tachometer-alt",
    dashboardSubPath: ["", "", ""],
  },
  {
    name: "My Profile",
    route: "MyProfile",
    icon: "fas fa-box",
    dashboardSubPath: ["", "", ""],
  },
  // { name: "Feed", route: "feed", icon: "fa-solid fa-rss" },
  {
    name: "Create Landing page",
    route: "createLandingPage",
    icon: "fa fa-adjust",
    dashboardSubPath: [""],
  },

  {
    name: "Create Events",
    route: "createEvents",
    icon: "fas fa-calendar-days",
    dashboardSubPath: ["viewEvents", "savedDrafts"],
  },
  {
    name: "Create Season Events",
    route: "SeasonEvents",
    icon: "fa-solid fa-calendar-day",
    dashboardSubPath: ["ViewSeasonEvents", "SeasonSavedDrafts"],
  },

  {
    name: "Create Seating Layout",
    route: "createSeatingLayout",
    icon: "fa fa-archive",
  },
  {
    name: "Add-on & Promo Codes",
    route: "createPromoCode",
    icon: "fa-regular fa-square-plus",
    dashboardSubPath: ["viewPromoCode", "createAddOnCode", "viewAddOnCode"],
  },
  {
    name: "Create Forms",
    route: "createForms",
    icon: "fas fa-file",
    dashboardSubPath: ["viewForms"],
  },
  //{ name: "Write Blogs", route: "writeBlogs", icon: "fa-solid fa-blog", dashboardSubPath: ["viewBlogs"], },
  // {
  //   name: "Upload Videos",
  //   route: "uploadVideos",
  //   icon: "fa-solid fa-upload",
  //   dashboardSubPath: ["viewVideos"],
  // },
  {
    name: "Mailers",
    route: "Mailer",
    icon: "fa-regular fa-envelope",
    dashboardSubPath: ["Tamplates", "Members", "History"],
  },
  {
    name: "Ads",
    route: "Ads",
    icon: "fa-solid fa-bullhorn",
    dashboardSubPath: ["ViewAds"],
  },
];

export const ExcludedForFans = [
  "createEvents",
  "SeasonEvents",
  "createSeatingLayout",
  "createPromoCode",
  "ads",
  "uploadVideos",
];

export const ExcludedForOrganisers = []

const TransactionHeaderRoutes = [
  { name: "Tickets", route: "tickets" },
  {
    name: "Season Tickets", route: "TransactionSeasonTickets"
  },
  {
    name: "Video Purchased", route: "videoPurchased"
  },
]

const EventsHeaderRoutes = [
  { name: "Create Events", route: "createEvents" },
  {
    name: "View Events",
    route: "viewEvents",
  },
  {
    name: "Saved Draft",
    route: "savedDrafts",
  },
];



export const SeasonHeaderRoutes = [
  { name: "Season Events", route: "SeasonEvents" },
  {
    name: "View Season Events",
    route: "ViewSeasonEvents",
  },
  {
    name: "Saved Draft",
    route: "SeasonSavedDrafts",
  },
];


const FormsHeaderRoutes = [
  { name: "Create Forms", route: "createForms" },
  {
    name: "View Forms", route: "viewForms"
  },
  {name: "Saved Drafts", route: "draftForms"},
]

const BlogsHeaderRoutes = [
  { name: "Write Blog", route: "writeBlogs" },
  {
    name: "View Blog", route: "viewBlogs"
  },
]

const UploadVideosHeaderRoutes = [
  { name: "Upload Video", route: "uploadVideos" },
  {
    name: "View Video", route: "viewVideos"
  },
]

const MailerHeaderRoutes = [
  { name: "Mailer", route: "Mailer" },
  { name: "Templates", route: "Tamplates" },
  { name: "Members", route: "Members" },
  { name: "History", route: "History" },
];
const AddOnHeaderRoutes = [
  { name: "Create Promo Code", route: "createPromoCode" },
  {
    name: "View Promo Code", route: "viewPromoCode"
  },
  {
    name: "Create Add-on Code", route: "createAddOnCode"
  },
  {
    name: "View Add-on Code", route: "viewAddOnCode"
  },
]

const FormInBoxHeaderRoutes = [
  { name: "View Form In-Box", route: "viewFormInBox" },
]

const AdsHeaderRoutes = [
  { name: "Create Ads", route: "Ads" },
  { name: "View Ads", route:"ViewAds/eyJwYXltZW50Ijoic3VjY2VzcyJ9"},
  
]



// create seating layout

const createSeatingLayout = [
  { name: "Create layout", route: "createSeatingLayout" },
  { name: "Edit layout", route: "editSeatingLayout" },
];






export {
    createSeatingLayout,
    DashboardRoutes,
    TransactionHeaderRoutes,
    EventsHeaderRoutes,
    FormsHeaderRoutes,
    BlogsHeaderRoutes,
    FormInBoxHeaderRoutes,
    AddOnHeaderRoutes,
    UploadVideosHeaderRoutes,
    AdsHeaderRoutes,
    MailerHeaderRoutes,
    
}