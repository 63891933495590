import React from "react";
import { useState, useEffect, useRef } from "react";
import { VIEW_SEASON_MULTPLE_TKTLIST } from "../../Api/api";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASEPATH } from "../../config/config";
import { useMutation } from "@tanstack/react-query";
import DataGrid, {
    Column,
    MasterDetail,
    Paging,
    SearchPanel,
    Pager,
    FilterRow,
    HeaderFilter,
    FilterPanel,
    Export,
    Scrolling,
    Selection,
} from 'devextreme-react/data-grid';
import NonNumberedDetails_master from "./NonNumberedDetails_master";
import { FaTicketAlt } from "react-icons/fa";

export default function ViewSeasonTicketsMultiple() {
    const token = localStorage?.getItem("MDnD_auth");

    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);

    const country_id = queryParams?.get('country_id');
    const event_id = queryParams?.get('event_id');

    const [dataSource, setdataSource] = useState({
        finalData: ""
    });
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [grandTotal, setgrandTotal] = useState(0);

    useEffect(() => {
        if (country_id && event_id) { mutationgetIndiEvents.mutate(); }
        else {
            return;
        }


    }, [country_id, event_id])

    const Getting_MultipleLists = () => GET_MULTIPLETKTSLIST_ALL(VIEW_SEASON_MULTPLE_TKTLIST);

    const GET_MULTIPLETKTSLIST_ALL = async (VIEW_SEASON_MULTPLE_TKTLIST) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${BASEPATH}${VIEW_SEASON_MULTPLE_TKTLIST}`,
                params: {
                    event_id: event_id ? Number(event_id) : "",
                    country_id: country_id ? Number(country_id) : ""
                },
                headers: { 'MDnD_auth': `${token}` },
                timeout: 60000,
                responseType: 'json',
            });
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationgetIndiEvents = useMutation({
        mutationKey: ["get_indiv_events"],
        mutationFn: () => Getting_MultipleLists(),
        onSuccess: (data) => {
            setdataSource((prev) => ({
                ...prev,
                finalData: data?.data

            }))

        },
        onError: () => {
            setdataSource((prev) => ({
                ...prev,
                finalData: ''

            }))
        },
    });


    const dataGridRef = useRef(null);

    useEffect(() => {


        if (dataGridRef.current) {
            dataGridRef.current.instance.expandAll(-1);
        }
    }, [dataSource.finalData]);

    const onSelectionChanged = (e) => {

        e.component.expandAll(-1);
    };



    const handleEventTime = (cellInfo) => {


        let newstring1 = '';
        let newstr2 = '';

        for (let i = 0; i < cellInfo.value.length; i++) {
            const char = cellInfo?.value[i];

            if (char !== ':') {
                newstring1 += char;

            }
            else {
                break;
            }

        }
        if (newstring1) {
            for (let i = newstring1.length + 1; i < cellInfo.value.length; i++) {
                const char = cellInfo?.value[i];
                if (char !== ':') {
                    newstr2 += char;

                }
                else {
                    break;
                }
            }
        }

        return `${newstring1}:${newstr2}`;

    }

    const handleEventDate = (cellInfo) => {

        const date = new Date(cellInfo.value);
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;

        return formattedDate

    }

    const handleTotalTicketsSold = (rowData) => {

        let sum = 0;
        for (let i = 0; i < rowData?.data?.non_numbered_details?.length; i++) {
            let obj = rowData?.data?.non_numbered_details[i];
            let tkt_sold = Number(obj.total_quantity) - Number(obj.balance_tickets);

            sum += tkt_sold;

        }

        return sum;
    }

    const handleTotalSumTickets = (rowData) => {


        let sum_amt = 0;

        for (let i = 0; i < rowData?.data?.non_numbered_details?.length; i++) {
            let obj = rowData?.data?.non_numbered_details[i];

            let tkt_sold = Number(obj.total_quantity) - Number(obj.balance_tickets);
            let initial_amt = tkt_sold && tkt_sold * Number(obj.amount);
            sum_amt += initial_amt;
        }

        return sum_amt;
    }

    const handleTitle = (cellInfo) => {


        return (
            <span style={{ fontWeight: 'bold' }}>
                {cellInfo?.value}
            </span>
        );
    };

    useEffect(() => {
        if (dataSource.finalData && dataSource.finalData.length > 0) {

            let sum_amt = 0;


            for (let k = 0; k < dataSource.finalData.length; k++) {
                let index = dataSource?.finalData[k];

                for (let i = 0; i < index?.non_numbered_details?.length; i++) {
                    let obj = index?.non_numbered_details[i];

                    let tkt_sold = Number(obj?.total_quantity) - Number(obj?.balance_tickets);
                    let initial_amt = tkt_sold && tkt_sold * Number(obj?.amount);
                    sum_amt += initial_amt;
                }

            }

            if (sum_amt) { setgrandTotal(sum_amt); }
        }

    }, [dataSource.finalData])

    const onViewTicketDetails = (rowData) => {

        const countryId = rowData.country_id ? rowData.country_id : 101;
        const url = `/viewTicketDetails?event_id=${rowData.event_id}&country_id=${countryId}`;
        window.open(url, "_blank");
    }

    return (<div className="container-fluid my-4">
        <DataGrid
            dataSource={dataSource.finalData || null}
            showBorders={true}
            columnAutoWidth={true}
            keyExpr="event_id"
            ref={dataGridRef}
            columnHidingEnabled={false}
            rowAlternationEnabled={true}

            allowColumnResizing={true}
            wordWrapEnabled={true}
            onSelectionChanged={onSelectionChanged}
        >
            {/* <FilterRow visible={true} />
                <FilterPanel visible={false} />

                <HeaderFilter visible={true} /> */}
            <SearchPanel
                visible={false}
                allowSearch={true}
                allowFiltering={true}
            />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Column
                caption="S.No"
                cellRender={(cellInfo) => {
                    const { rowIndex, component, row } = cellInfo;

                    const pageSize = component.pageSize();
                    const pageIndex = component.pageIndex();
                    const totalRowsBefore = pageIndex * pageSize;

                    const serialNumber = totalRowsBefore + row.dataIndex + 1;

                    return serialNumber;
                }}
                //width={80}
                allowEditing={false}
                alignment="right"
            />
            <Column
                dataField="event_id"
                caption="Event ID"
                visible={false}
            />

            <Column
                caption="Event Title"
                dataField="event_title"
                alignment="left"
                cellRender={handleTitle}
            />

            <Column
                caption="Event Date"
                dataField="event_date"
                cellRender={handleEventDate}
                alignment="right"
            />
            <Column
                caption="Event Time"
                dataField="event_time"
                cellRender={handleEventTime}
                alignment="right"
            />

            <Column
                caption="Amount Sold"
                cellRender={handleTotalSumTickets}
                alignment="right"
            />
            <Column
                caption="View Ticket Details"
                alignment="right"
                cellRender={({ data }) => (
                    <div style={{ cursor: "pointer" }}>
                        <FaTicketAlt
                            className="EditIcon"
                            onClick={() => onViewTicketDetails(data)}
                        />
                    </div>
                )}
            />



            <Column
                dataField="non_numbered_details"
                visible={false}
            />
            <Paging defaultPageSize={500} />

            <MasterDetail
                enabled={true}
                component={(props) => <NonNumberedDetails_master {...props} />}
            />



        </DataGrid>
        <div className="d-flex justify-content-end mt-4">Total :<b>{grandTotal}</b>  </div>
    </div>)
}