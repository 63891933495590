import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./NumberedDenominationNotification.css"

const NumberedDenominationNotification = ({ show, onClose, message, imageSource }) => {
  const [visibleItems, setVisibleItems] = useState(24);

  const handleSeeMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 12);
  };

  return (
    <Modal centered show={show} onHide={onClose}>
      <div className="mx-2">
        <Modal.Header
          closeButton
          className="m-0 d-flex align-items-center text-center border-light"
          style={{ padding: "10px 10px" }}
        >
          <h5 className="text-nowrap text-center m-auto">Note</h5>
        </Modal.Header>
        <Modal.Body
          className="text-center overflowScrollBar"
          style={{ maxHeight: "80vh" }}
        >
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <img
                className="w-auto"
                style={{ maxHeight: "300px", height: "auto", maxWidth: "100%" }}
                src={imageSource}
                alt="Modal content"
              />
              <h5 className="text-start mt-2" style={{ color: "#3f00ff" }}>
                <b>Oops</b>
              </h5>
              <p className="text-start" style={{ color: "#3f00ff" }}>
                You may forgot to add Amount?
              </p>
            </div>
            <div
              className="text-center w-75"
              style={{ maxHeight: "400px", paddingLeft: "10px" }}
            >
              <div className="row">
                {message &&
                  message?.slice(0, visibleItems).map((data, index) => (
                    <div className="col-4 px-0" key={index}>
                      <p>
                        {`${data.key}-${data.row_label}-${data.seat}${
                          index % 3 !== 2 ? "," : ""
                        }`}
                      </p>
                    </div>
                  ))}
              </div>
              {visibleItems < message.length && (
                <div>
                  <button onClick={handleSeeMore} className="btn-link p-0">
                    <p
                      className="text-start "
                      style={{ color: "#3f00ff", fontSize: "1.5vh" }}
                    >
                      See More...
                    </p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default NumberedDenominationNotification;
