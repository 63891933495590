import React from "react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASEPATH } from "../../../config/config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_POSTS_PROFILE } from "../../../Api/api";
import { GET_IMAGES_PROFILE } from "../../../Api/api";
import ReactPlayer from "react-player";
import { IMAGE_URL } from "../../../Subpages/MainPage/constant";
import { ArtistallImg } from "../../../Subpages/MainPage/constant";
import { GET_USERPROFILE } from "../../../Api/api";
import { formatDistanceToNow } from 'date-fns';
import { HeadProvider, Meta } from 'react-head';
import Noimage_circle from "../../../global/NoImage_circle";
import { ORGANISER_URL, Fan_images } from "../../../Subpages/MainPage/constant";
import { imageExists, isValidImage } from "../../../global/ImageCheck";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { GET_LIKES, LIKE_SINGLE_POST, GET_POSTS_COMMENTS, DELETECOMMENT_SINGLE_POST,COMMENT_SINGLE_POST,EDITCOMMENT_SINGLE_POST,DELETE_SINGLE_POST,REPORT_POST } from "../../../Api/api";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import { BsFillSendFill } from "react-icons/bs";
import { Modal } from "react-bootstrap";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import { Helmet } from "react-helmet";
import getProfileKey from "../../../Api/getProfileKey";

export default function SpecificPost() {

  const PROFILE_KEY = getProfileKey().profileKey;


  const decodeBase64 = (encoded, key) => {
    //const prefix = "xy4AT";
    try {
        const decodedStr = atob(encoded); 
        if (decodedStr.startsWith(key)) {
            return decodedStr.slice(key.length); 
        } else {
            console.error("Decoding error: Prefix not found");
            return null;
        }
    } catch (error) {
        console.error("Decoding error:", error);
        return null;
    }
};

  const location = useLocation();
  const queryClient = useQueryClient();

  // Extract roleidstate and postid from the query parameters
  const queryParams = new URLSearchParams(location?.search);
  const roleidstate = Number(decodeBase64(queryParams?.get('roleidstate'),PROFILE_KEY.toString()));
  const postid = queryParams?.get('postid');

  const token = localStorage.getItem("MDnD_auth");

  const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loadingLikes, setLoadingLikes] = useState({});

  const [displayProfilePosts, setDisplayProfilePosts] = useState({

    newfilteredGridData: "",
    CommentsData:"",
  });

  const [counter, setCounter] = useState(0);
  const [editedComment, setEditedComment] = useState([]);
  const [arrayoflikenames, setArrayofLikeNames] = useState([]);
  const [namesofLikesforpostid, setNamesofLikesforPostid] = useState({});
  const [postCollection, setPostCollection] = useState({
    post_textbox: "",
    post_image: "",
    base64Image: "",
    videolink: "",
    audiolink: "",
    validation_msg1: "",
    validation_msg2: "",
    regex_msg_video: "",
    regex_msg_audio: "",
    something_about: "",
    id_for_delete: "",
    comment_text: "",
    id_for_comment: "",
    validation_for_comments: "",
  });

  const [checkerforMenuClassname, setcheckerforMenuClassname] = useState(true);
  const [checkereditComment, setCheckerEditComment] = useState(0);

  const [newlikedstatus, setnewLikedStatus] = useState("");

  const [popup, setPopup] = useState({
    popupVisible: false,
    deleteornot: false,
    commentbox_visible: false,
    report_visible: false,
    anon_goestologin: false,
    names_likes: false,
    //popupDetails: "",
  });
  const [newupdatedComments, setNewUpdatedComments] = useState([]);

  const [photoOrganiser, setPhotoOrganiser] = useState({});
  const [deleteComment, setDeleteComment] = useState([]);


  // DISPLAYING ALL POSTS...................................
  useEffect(() => {
    mutationgetprofile.mutate(roleidstate);
  }, [roleidstate]);

  const fetchSingleProfile = (roleid) => GETPROFILE(GET_POSTS_PROFILE, roleid);

  const mutationgetprofile = useMutation({
    mutationKey: ["fetchPostsProfile"],
    mutationFn: (roleid) => fetchSingleProfile(roleid),
    onSuccess: (data) => {
      if (data.status === "success") {

        let filteredArray = data?.data && data?.data?.filter((item) => item.postid === Number(postid));
        //console.log(filteredArray, "filteredArray");

        if (filteredArray) { setDisplayProfilePosts((prev) => ({ ...prev, newfilteredGridData: filteredArray })); }
        else {
          setDisplayProfilePosts((prev) => ({ ...prev, newfilteredGridData: "" }));
        }



      }

      else {
        setDisplayProfilePosts((prev) => ({ ...prev, newfilteredGridData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      //alert("Did not fetch profile")
    },
  });

  const GETPROFILE = async (GET_POSTS_PROFILE, roleid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_POSTS_PROFILE}`,

        data: {

          login_user_roleid: user ? Number(user.role_id) : 0,
          user_roleid: Number(roleid)
        },
        // headers: {
        //   'MDnD_auth': `${token}`,

        // },

        timeout: 60000,
        responseType: 'json',
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  //console.log(displayProfilePosts, "displayProfilePosts");

  //profiledata
  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    const fetchDataofuser = async () => {

      mutationgetUser.mutate();

    };
    fetchDataofuser();
  }, []);



  const fetchSingleUser = () => GETUSER(GET_USERPROFILE);
  const GETUSER = async (GET_USERPROFILE) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_USERPROFILE}`,

        data: {
          roleid: roleidstate,
        },
        // headers: {
        //   'MDnD_auth': `${token}`,

        // },

        timeout: 60000,
        responseType: 'json',
      });

      return response.data;
    } catch (error) {
      console.error("error during user fetch profile is", error);
    }
  };

  const mutationgetUser = useMutation({
    mutationKey: ["fetchUser"],
    mutationFn: () => fetchSingleUser(),
    onSuccess: (data) => {
      if (data.status === "success") {
        setProfileData(data?.data[0]);
      } else {
        setProfileData("");
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      setProfileData("");
    },
  });

  //for photo pic.....................................
  

  // end of profileData.........

  function formatDate(timestamp) {

    if (timestamp) { return formatDistanceToNow(new Date(timestamp), { addSuffix: true }); }
    else {
      return;
    }
  }
  //console.log(profileData, "profileData");

  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const handleImageSuccess = () => {
    setImageError(false);
  };


  const isUrl = (text) => {
    try {
      new URL(text);
      return true;
    } catch (e) {
      return false;
    }
  }

  const [imageErrorDisplayPost, setimageErrorDisplayPost] = useState(false);

  const handleimageErrorDisplayPost = () => {
    setimageErrorDisplayPost(true);
  };
  const handleimageSuccessDisplayPost = () => {
    setimageErrorDisplayPost(false);
  };

  const handleClose = () => {
    setPostCollection((prevState) => ({ ...prevState, validation_msg2: "" }));
    setPostCollection((prevState) => ({ ...prevState, regex_msg_audio: "" }));
    setPostCollection((prevState) => ({ ...prevState, regex_msg_video: "" }));
    //setPopup({ popupVisible: false });
    setPopup((prevState) => ({ ...prevState, popupVisible: false }));
    setPopup((prevState) => ({ ...prevState, deleteornot: false }));
  };

  //REPORT or BLOCK A POST......................

  const handleClose_reportpopup = () => {
    setDisplayProfilePosts((prevState) => ({
      ...prevState,
      reporttext: "",
      validationmsg_for_reporttext: "",
      postid_for_report: "",
    }));
    setPopup((prevState) => ({ ...prevState, report_visible: false }));
  };

  const handleReport = (postid) => {
    if (postid) {
      setDisplayProfilePosts((prevState) => ({
        ...prevState,
        postid_for_report: postid,
      }));



      setPopup((prev) => ({ ...prev, report_visible: true }));
    } else {
      return;
    }
  };
  const GridPostHeader_reportthispost = () => {
    let filteredGridData_popup = [];
    if (displayProfilePosts.postid_for_report) {
      filteredGridData_popup = displayProfilePosts.newfilteredGridData && displayProfilePosts.newfilteredGridData.length > 0 &&
        displayProfilePosts.newfilteredGridData.filter((item) => item.postid === displayProfilePosts.postid_for_report)
    }

    return (
      <div className="headers">
        <div className="left-info d-flex justify-content-start align-items-center gap-2">
          <div className="thumbnail">

            <div className="cast-box position-relative commentPopImg text-center">
            {profileData.rolename === "artist" ? (
                isValidImage(profileData?.photo) && imageExists(`${ArtistallImg}/${profileData?.photo}`) ? (
                  <img
                    src={`${ArtistallImg}/${profileData?.photo}`}
                    //onError={handleImageError_Header}
                    //onLoad={handleImageSuccess_Header}
                    className="img-fluid object-cover"
                    alt="Artist"
                  />
                ) : (
                  <Noimage_circle />
                )
              ) : profileData.rolename === "organiser" ? (
                isValidImage(profileData?.photo) && imageExists(`${ORGANISER_URL}/${profileData?.photo}`) ? (
                  <>
                    <img
                      src={`${ORGANISER_URL}/${profileData?.photo}`}
                      //onError={handleImageError_Header}
                     // onLoad={handleImageSuccess_Header}
                      className="img-fluid object-cover"
                      alt="Organiser"
                    />
                  </>
                ) : (
                  <Noimage_circle />
                )
              ) : profileData.rolename === "fan" ? (
                isValidImage(profileData?.photo) && imageExists(`${Fan_images}/${profileData?.photo}`) ? (
                  <>
                    <img
                      src={`${Fan_images}/${profileData?.photo}`}
                      //onError={handleImageError_Header}
                      //onLoad={handleImageSuccess_Header}
                      className="img-fluid object-cover"
                      alt="Fan"
                    />
                  </>
                ) : (
                  <Noimage_circle />
                )
              ) : (
                <Noimage_circle />
              )}
            </div>
          </div>
          <div className="name-info">
            <div className="name">
             
              {profileData?.name}
            </div>
            <div className="time" style={{ fontSize: "12px" }}>
              {formatDate(filteredGridData_popup[0].postcreateddate)}
              <i className="global-icon"></i>
            </div>
          </div>
        </div>
        <div className="right-info"></div>
      </div>
    )
  }

  const GridPostDisplayinPopup_reportthispost = () => {
    let filteredGridData_popup = [];
    if (displayProfilePosts.postid_for_report) {
      filteredGridData_popup = displayProfilePosts.newfilteredGridData && displayProfilePosts.newfilteredGridData.length > 0 &&
        displayProfilePosts.newfilteredGridData.filter((item) => item.postid === displayProfilePosts.postid_for_report)
    }
    return (
      <div>
        {filteredGridData_popup && filteredGridData_popup.length > 0 && (
          <>
            <div className="content pb-2">


              {filteredGridData_popup[0].postdescr &&
                (ReactPlayer.canPlay(filteredGridData_popup[0].postdescr) ? (
                  <ReactPlayer
                    className="w-100"
                    url={filteredGridData_popup[0].postdescr}
                    controls={true}
                  />
                ) : (
                  <p>{filteredGridData_popup[0].postdescr}</p>
                ))}

              { isValidImage(filteredGridData_popup[0].postimage) && imageExists(`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`) && (
                <img
                  src={`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`}
                  alt={filteredGridData_popup[0].postdescr}
                  className="w-100"
                />
              )}

              {filteredGridData_popup[0].postvideo && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postvideo}
                  className="w-100"
                  controls={true}
                />
              )}

              {filteredGridData_popup[0].postaudio && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postaudio}
                  className="w-100"
                  controls={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    )

  }

  const handleReportTextbox = (e) => {
    setDisplayProfilePosts((prevState) => ({
      ...prevState,
      reporttext: e.target.value,
    }));

    if (e.target.value.length > 0) {
      setDisplayProfilePosts((prev) => ({
        ...prev,
        validationmsg_for_reporttext: "",
      }));
    }
  };

  const handleReportSubmitButton = () => {
    if (displayProfilePosts.reporttext === "") {
      setDisplayProfilePosts((prevState) => ({
        ...prevState,
        validationmsg_for_reporttext: "Enter something!",
      }));
    } else {
      setDisplayProfilePosts((prevState) => ({
        ...prevState,
        validationmsg_for_reporttext: "",
      }));
      mutationReportPost.mutate(displayProfilePosts.postid_for_report);
    }
  };

  const postReport = (postid_report) =>
    postReportBackend(REPORT_POST, postid_report);

  const postReportBackend = async (REPORT_POST, postid_report) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${REPORT_POST}`,

        data: {
          postid: postid_report,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          reporttext: displayProfilePosts.reporttext
            ? displayProfilePosts.reporttext
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationReportPost = useMutation({
    mutationKey: ["PostReport"],
    mutationFn: (postid_report) => postReport(postid_report),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data, "data for report");
        SuccessNewToaster("success", "Reported Succesfully", 3000);

        setDisplayProfilePosts((prev) => ({
          ...prev,
          reporttext: "",
          validationmsg_for_reporttext: "",
          postid_for_report: "",
        }));
        setPopup((prev) => ({ ...prev, report_visible: false }));
        setCounter(counter + 1);
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      setDisplayProfilePosts((prev) => ({
        ...prev,
        reporttext: "",
        validationmsg_for_reporttext: "",
        postid_for_report: "",
      }));
    },
  });


  const handleModalDelete = (pmeter1) => {
    //console.log(pmeter1, "pmeter1");

    if (user?.role_id === roleidstate) {
      setPopup((prevState) => ({ ...prevState, deleteornot: true }));
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_delete: pmeter1,
      }));
    } else if (user) {
      return;
    } else if (!user) {
      navigate("/Login");

      return;
    } else {
      return;
    }
  };

  const handleYesDelete = () => {
    if (postCollection.id_for_delete) {
      mutationDeleteprofile.mutate(postCollection.id_for_delete);

      setPopup((prevState) => ({ ...prevState, deleteornot: false }));
    } else {
      return;
    }
  };

  const handleNoDelete = () => {
    setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));
    setPopup((prevState) => ({ ...prevState, deleteornot: false }));
  };

  const DeleteSingleProfile = (deleteid) =>
    DELETEONEPOST(DELETE_SINGLE_POST, deleteid);

  const DELETEONEPOST = async (DELETE_SINGLE_POST, deleteid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETE_SINGLE_POST}`,

        data: {
          postid: deleteid,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          posttype: 1,
          status: "N",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationDeleteprofile = useMutation({
    mutationKey: ["DeleteSingleProfile"],
    mutationFn: (deleteid) => DeleteSingleProfile(deleteid),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from DELETE");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Post Deleted Successfully", 3000);

        setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));
        setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast("error", "Did Not Delete the Post", 3000);
      setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });




   // names of all those who Liked...******************************

   const handleNamesofAllThoseLiked = async (postid) => {
    //console.log(postid, "postid");
    setPopup((prevState) => ({ ...prevState, names_likes: true }));

    const namesofLikes = async (postid) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${BASEPATH}${GET_LIKES}`,
          data: {
            postid: Number(postid),
          },
          headers: {
            MDnD_auth: `${token}`,
          },

          timeout: 60000,
          responseType: "json",
        });

        if (response?.data.status === "success") {
          let result = response?.data.data[0];
          let filterednames =
            result &&
            result.map((item) => ({
              name: item.artist_name,
              photo: item.profile_photo,
              role_name: item.role_name,
              role_id: item.role_id,
              profile_name: item.profile_name,
            }));

          let uniqueFilterednames = filterednames && filterednames.reduce((accumulator, currentItem) => {

            const isDuplicate = accumulator.some(item => item.role_id === currentItem.role_id);


            if (!isDuplicate) {
              accumulator.push(currentItem);
            }

            return accumulator;
          }, []);

          if (uniqueFilterednames) {
            setArrayofLikeNames(uniqueFilterednames);
          }

          let all_names_string =
            filterednames && filterednames.map((item) => item.name).join(", ");
          return all_names_string;
        } else {
          return "";
        }
      } catch (error) {
        console.error("Error fetching detailed data:", error);
        return "error";
      }
    };

    let names = await namesofLikes(postid);

    let like_names = {};
    like_names[postid] = names;

    setNamesofLikesforPostid(like_names);
  };
  //console.log(namesofLikesforpostid, "namesofLikesforpostid");

  const handleClose_namesLikes = () => {
    setPopup((prevState) => ({ ...prevState, names_likes: false }));
    setNamesofLikesforPostid({});
    setArrayofLikeNames([]);
  };

  const handleLikeNameLeadstoProfile = (profileData) => {
    if (profileData.role_name === "artist") {
      navigate(`/artist/${profileData.profile_name}`, {
        state: { artistId: profileData.role_id },
      });
    } else if (profileData.role_name === "organiser") {
      navigate(`/organiser/${profileData.profile_name}`, {
        state: { organiserId: profileData.role_id },
      });
    } else {
      return;
    }
  };

  // LIKE BUTTON..................................................................

  const handleLikePost = async (postId, postlikestatus) => {
    setLoadingLikes((prevState) => ({ ...prevState, [postId]: true }));
    if (user) {
      if (postId) {
        mutationLikePost.mutate({ postId, postlikestatus });
      } else {
        return;
      }
    } else if (!user) {
      setPopup((prevState) => ({ ...prevState, anon_goestologin: true }));
      //navigate("/Login");
      return;
    } else {
      return;
    }
  };

  const LikeProfile = (postId, postlikestatus) =>
    LIKEONEPOST(LIKE_SINGLE_POST, postId, postlikestatus);

  const LIKEONEPOST = async (LIKE_SINGLE_POST, postId, postlikestatus) => {
    let NLS;
    let isLiked;
    let checker_status = postlikestatus;

    //console.log(checker_status, "checker_status");



    isLiked =
      checker_status === "Y"
        ? true
        : checker_status === "N"
          ? false
          : undefined;

    //console.log(isLiked, "isLiked");
    //NLS = isLiked ? "N" : "Y";
    NLS = isLiked === true ? "N" : "Y";

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${LIKE_SINGLE_POST}`,

        data: {
          postid: postId ? postId : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          likestatus: NLS,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      //console.log(response, "response");
      //return { response, postId, isLiked };
      return response;
    } catch (error) {
      throw error;
    }
  };

  const mutationLikePost = useMutation({
    mutationKey: ["LikeAPost"],
    mutationFn: ({ postId, postlikestatus }) =>
      LikeProfile(postId, postlikestatus),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA LIKE button");

      if (data.data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        //SuccessNewToaster('success', 'Comment Edited Successfully', 3000);

        setLoadingLikes((prevState) => ({
          ...prevState,
          [data.data.data.post_id]: false,
        }));

        //setEditedComment("");
        setnewLikedStatus("");


        //setCounterLike(counterlike + 1);

        setDisplayProfilePosts(prevState => {
          const updatedPosts = prevState.newfilteredGridData.reduce((acc, post) => {
            if (post.postid === data.data.data.post_id) {

              const isLiked = data.data.data.status === "Y";

              acc.push({
                ...post, postlikestatus: (data.data.data.status).toString(),
                countpostlike: isLiked ? post.countpostlike + 1 : post.countpostlike - 1,
              });
            } else {

              acc.push(post);
            }
            return acc;
          }, []);

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });


      } else {
        setLoadingLikes((prevState) => ({
          ...prevState,
          [data.data.data.post_id]: false,
        }));
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Like the Post. Something Went Wrong!",
        3000
      );
      //setnewLikedStatus("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  // CODE FOR COMMENTS IN PROFILE.......................................

  useEffect(() => {
    if (displayProfilePosts.CommentsData) {
      let comments_for_that_id =
        displayProfilePosts.CommentsData && displayProfilePosts.CommentsData[0];
      setNewUpdatedComments(comments_for_that_id);
    }
  }, [displayProfilePosts.CommentsData]);



  const handleComment = (pmeter1) => {
    //console.log(pmeter1, "pmeter1 comment");

    if (user) {
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: pmeter1,
      }));

      setPopup((prevState) => ({ ...prevState, commentbox_visible: true }));

      if (pmeter1) {
        mutationgetComment.mutate(pmeter1);
      } else {
        return;
      }
    } else {
      setPopup((prevState) => ({ ...prevState, anon_goestologin: true }));
      //navigate("/Login");
      //handlegotoLogin();
      return;
    }
  };

  //fetch comments from backend- tanstack

  const fetchComments = (postid_comment) =>
    GETCOMMENTS(GET_POSTS_COMMENTS, postid_comment);

  const GETCOMMENTS = async (GET_POSTS_COMMENTS, postid_comment) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_POSTS_COMMENTS}`,

        data: {
          postid: postid_comment,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationgetComment = useMutation({
    mutationKey: ["fetchComments"],
    mutationFn: (postid_comment) => fetchComments(postid_comment),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data.data, "data.data");

        setDisplayProfilePosts((prev) => ({
          ...prev,
          CommentsData: data.data,
        }));
        setCounter(counter + 1);
      } else {
        setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
    },
  });

  const handleClose_commentpopup = () => {
    setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));

    setCheckerEditComment(0);
  };

  const GridPostHeader = (postid) => {
    let filteredGridData_popup =
      displayProfilePosts.newfilteredGridData &&
      displayProfilePosts.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    
    return (
      <div className="headers">
        <div className="left-info d-flex justify-content-start align-items-center gap-2">
          <div className="thumbnail">

            <div className="cast-box position-relative commentPopImg text-center">
            {profileData.rolename === "artist" ? (
                isValidImage(profileData?.photo) && imageExists(`${ArtistallImg}/${profileData?.photo}`) ? (
                  <img
                    src={`${ArtistallImg}/${profileData?.photo}`}
                    //onError={handleImageError_Header}
                    //onLoad={handleImageSuccess_Header}
                    className="img-fluid object-cover"
                    alt="Artist"
                  />
                ) : (
                  <Noimage_circle />
                )
              ) : profileData.rolename === "organiser" ? (
                isValidImage(profileData?.photo) && imageExists(`${ORGANISER_URL}/${profileData?.photo}`) ? (
                  <>
                    <img
                      src={`${ORGANISER_URL}/${profileData?.photo}`}
                      //onError={handleImageError_Header}
                     // onLoad={handleImageSuccess_Header}
                      className="img-fluid object-cover"
                      alt="Organiser"
                    />
                  </>
                ) : (
                  <Noimage_circle />
                )
              ) : profileData.rolename === "fan" ? (
                isValidImage(profileData?.photo) && imageExists(`${Fan_images}/${profileData?.photo}`) ? (
                  <>
                    <img
                      src={`${Fan_images}/${profileData?.photo}`}
                      //onError={handleImageError_Header}
                      //onLoad={handleImageSuccess_Header}
                      className="img-fluid object-cover"
                      alt="Fan"
                    />
                  </>
                ) : (
                  <Noimage_circle />
                )
              ) : (
                <Noimage_circle />
              )}
            </div>
          </div>
          <div className="name-info">
            <div className="name">
             
              {profileData?.name}
            </div>
            <div className="time" style={{ fontSize: "12px" }}>
              {formatDate(filteredGridData_popup[0].postcreateddate)}
              <i className="global-icon"></i>
            </div>
          </div>
        </div>
        <div className="right-info"></div>
      </div>
    );
  };

  const GridPostDisplayinPopup = (postid) => {
    // let filteredGridData_popup =
    //   displayProfilePosts.gridData &&
    //   displayProfilePosts.gridData.filter((item) => item.postid === postid);

    let filteredGridData_popup =
      displayProfilePosts.newfilteredGridData &&
      displayProfilePosts.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    return (
      <div>
        {filteredGridData_popup && filteredGridData_popup.length > 0 && (
          <>
            <div className="content pb-2">



              {filteredGridData_popup[0].postdescr &&
                (ReactPlayer.canPlay(filteredGridData_popup[0].postdescr) ? (
                  <ReactPlayer
                    className="w-100"
                    url={filteredGridData_popup[0].postdescr}
                    controls={true}
                  />
                ) : (
                  <p>{filteredGridData_popup[0].postdescr}</p>
                ))}

              { isValidImage(filteredGridData_popup[0].postimage) && imageExists(`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`) && (
                <img
                  src={`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`}
                  alt={filteredGridData_popup[0].postdescr}
                  className="w-100"
                />
              )}

              {filteredGridData_popup[0].postvideo && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postvideo}
                  className="w-100"
                  controls={true}
                />
              )}

              {filteredGridData_popup[0].postaudio && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postaudio}
                  className="w-100"
                  controls={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const commentsComponent = () => {
    return (
      <div>
        {newupdatedComments &&
          newupdatedComments.map((comment) => (
            <div key={comment.post_comm_id}>
              <ul className="list-inline m-0 p-0">
                <li className="py-2">
                  <div className="d-flex align-items-sm-center align-items-start borderLine">
                    <div className="about-user w-100 ms-2 d-flex flex-column gap-2 commentPopText">
                      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 ">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="flex-shrink-0 ">

                            {
                              comment.role_name === "artist" ? (
                                isValidImage(comment?.profile_photo) && imageExists(`${ArtistallImg}/${comment?.profile_photo}`)  ? (
                                  <img
                                    src={`${ArtistallImg}/${comment?.profile_photo}`}

                                    //onError={handleImageError_comment}
                                    //onLoad={handleImageSuccess_comment}
                                    className="commentImg rounded-circle"

                                    alt="Artist"
                                  />
                                ) : (
                                  <Noimage_circle />
                                )
                              ) : comment.role_name === "organiser" ? (
                                isValidImage(comment?.profile_photo) && imageExists(`${ORGANISER_URL}/${comment?.profile_photo}`)  ? (
                                  <>
                                    <img
                                      src={`${ORGANISER_URL}/${comment?.profile_photo}`}

                                      //onError={handleImageError_comment}
                                      //onLoad={handleImageSuccess_comment}
                                      className="commentImg rounded-circle"
                                      alt="Organiser"
                                    />
                                  </>) : (
                                  <Noimage_circle />
                                )
                              ) : comment.role_name === "fan" ? (
                                isValidImage(comment?.profile_photo) && imageExists(`${Fan_images}/${comment?.profile_photo}`)  ? (
                                  <>
                                    <img
                                      src={`${Fan_images}/${comment?.profile_photo}`}

                                      //onError={handleImageError_comment}
                                      //onLoad={handleImageSuccess_comment}
                                      className="commentImg rounded-circle"
                                      alt="Fan"
                                    />
                                  </>) : (
                                  <Noimage_circle />
                                )
                              ) : (
                                <Noimage_circle />
                              )
                            }



                          </div>
                          <div className="d-flex">
                            <div className="d-flex flex-column">
                              <p className="mb-0 commentPopName">
                                <b>{comment.artist_name}</b>
                                <span className="commentDayText ms-2">
                                  {formatDate(comment.created_date)}
                                </span>
                              </p>
                              <p
                                className="m-0 p-0"
                                style={{ fontSize: "12px" }}
                              >
                                {comment.role_name}
                              </p>
                            </div>

                            <span className="published-date ms-2">
                              {/* {" "}
                          {formatDate(comment.created_date)} */}
                            </span>
                          </div>
                        </div>

                        <div id="container">


                          {checkereditComment === 0 && (<>
                            <Dropdown style={{ width: "40px", height: "40px" }}>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                                className="bg-white button"
                                style={{
                                  border: "none",
                                  width: "40px",
                                  height: "40px",
                                  color: "black",
                                  padding: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <BsThreeDots style={{ color: "black" }} /> {/* Custom Icon */}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {user?.login_id === comment.login_id &&
                                  user?.role_id === comment.role_id && (
                                    <>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleEdit(comment.post_comm_id)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDeleteComment(
                                            comment.post_comm_id
                                          )
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </>
                                  )}
                              </Dropdown.Menu>
                            </Dropdown> </>)}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 mb-0 commentText">


                          {checkereditComment !== comment.post_comm_id ? (
                            comment.comments_text
                          ) : (
                            <div className="d-flex flex-column gap-2">
                              <input
                                type="text"
                                placeholder="Write a Comment!"
                                value={comment.comments_text}
                                onChange={(e) => {
                                  if (
                                    user?.login_id === comment.login_id &&
                                    user?.role_id === comment.role_id
                                  ) {
                                    handleCommentsEditing(
                                      e,
                                      comment.post_comm_id
                                    );
                                  }
                                }}
                              />
                              <div className="d-flex gap-2 align-items-center">
                                {user?.login_id === comment.login_id &&
                                  user?.role_id === comment.role_id && (
                                    <button
                                      className="button"
                                      onClick={() =>
                                        handleSaveComment(comment.post_comm_id)
                                      }
                                    >
                                      Save
                                    </button>
                                  )}

                                <button
                                  className="border-button"
                                  onClick={handleCancelforCommentEdit}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))}


      </div>
    );
  };

  const handleCommentTextbox = (e) => {
    //console.log(e, "EVENT");
    if (e) {
      setPostCollection((prevState) => ({
        ...prevState,
        comment_text: e.target.value,
      }));
      if (e?.value?.length > 0) {
        setPostCollection((prev) => ({ ...prev, validation_for_comments: "" }));
      }
    } else {
      return;
    }
  };

  //edit and delete a comment.*******************************
  const handleEdit = (pmeter1) => {
    //setCheckerEditComment(true);
    let Comment_Edit =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(Comment_Edit, "comment_edit");

    setCheckerEditComment(Comment_Edit[0].post_comm_id);
    setcheckerforMenuClassname(false);
  };

  const handleDeleteComment = (pmeter1) => {
    let DeleteC =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(DeleteComment,"Delete this Comment");

    if (DeleteC) {
      setDeleteComment(DeleteC);
      mutationDeleteComment.mutate(deleteComment);
    } else {
      return;
    }
  };

  const DeleteCommentSingleProfile = (deleteComment) =>
    DELETECOMMENTONEPOST(DELETECOMMENT_SINGLE_POST, deleteComment);

  const DELETECOMMENTONEPOST = async (
    DELETECOMMENT_SINGLE_POST,
    deleteComment
  ) => {
    //console.log(deleteComment, "deleteComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETECOMMENT_SINGLE_POST}`,

        data: {
          postid: deleteComment ? deleteComment[0].post_id : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          status: "N",
          postcommentid: deleteComment ? deleteComment[0].post_comm_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationDeleteComment = useMutation({
    mutationKey: ["DeleteComment"],
    mutationFn: () => DeleteCommentSingleProfile(deleteComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment deleted Successfully!", 3000);

        setDeleteComment("");

        //setCounter(counter + 1);
        setNewUpdatedComments((prev) =>
          prev.filter(
            (comment) => comment.post_comm_id !== data?.data?.post_comm_id
          )
        );

        setDisplayProfilePosts((prevState) => {
          const updatedPosts = prevState.newfilteredGridData.reduce(
            (acc, post) => {
              if (post.postid === data.data.post_id) {
                // Update the specific post
                acc.push({
                  ...post,
                  countpostcomment: post.countpostcomment - 1,
                });
              } else {
                acc.push(post);
              }
              return acc;
            },
            []
          );

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Delete the Comment. Something Went Wrong!",
        3000
      );
      setDeleteComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const handleCancelforCommentEdit = () => {
    setCheckerEditComment(0);
    setcheckerforMenuClassname(false);
  };

  const handleCommentsEditing = (e, commentId) => {
    const index =
      newupdatedComments &&
      newupdatedComments.findIndex(
        (comment) => comment.post_comm_id === commentId
      );

    if (index !== -1) {
      const updated = [...newupdatedComments];
      updated[index] = { ...updated[index], comments_text: e.target.value };
      setNewUpdatedComments(updated);
    }
  };

  const handleSaveComment = (pmeter1) => {
    let EditC =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(EditC, "Edit this Comments");

    if (EditC) {
      setEditedComment(EditC);

      mutationEditComment.mutate(editedComment);
    } else {
      return;
    }
  };
  const EditCommentSingleProfile = (editedComment) =>
    EDITCOMMENTONEPOST(EDITCOMMENT_SINGLE_POST, editedComment);

  const EDITCOMMENTONEPOST = async (EDITCOMMENT_SINGLE_POST, editedComment) => {
    //console.log(editedComment, "editedComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${EDITCOMMENT_SINGLE_POST}`,

        data: {
          postid: editedComment ? editedComment[0].postid : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: editedComment ? editedComment[0].comments_text : "",
          postcommentid: editedComment ? editedComment[0].post_comm_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationEditComment = useMutation({
    mutationKey: ["EditComment"],
    mutationFn: () => EditCommentSingleProfile(editedComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment Edited Successfully", 3000);

        setEditedComment("");

        setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Edit the Comment. Something Went Wrong!",
        3000
      );
      setEditedComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const handleCommentSubmitButton = () => {
    if (postCollection.comment_text === "") {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "Please write a comment",
      }));
    } else {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "",
      }));
      mutationCommentprofile.mutate(postCollection.id_for_comment);
    }
  };

  const CommentSingleProfile = (commentid) =>
    COMMENTONEPOST(COMMENT_SINGLE_POST, commentid);

  const COMMENTONEPOST = async (COMMENT_SINGLE_POST, commentid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${COMMENT_SINGLE_POST}`,

        data: {
          postid: commentid,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: postCollection.comment_text
            ? postCollection.comment_text
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationCommentprofile = useMutation({
    mutationKey: ["CommentSingleProfile"],
    mutationFn: (commentid) => CommentSingleProfile(commentid),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from Comments");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment Posted Successfully!", 3000);

        setPostCollection((prevState) => ({
          ...prevState,
          id_for_comment: "",
          comment_text: "",
        }));

        setDisplayProfilePosts((prevState) => {
          const updatedPosts = prevState.newfilteredGridData.reduce(
            (acc, post) => {
              if (post.postid === data.data.post_id) {
                // Update the specific post
                acc.push({
                  ...post,
                  countpostcomment: post.countpostcomment + 1,
                });
              } else {
                // Add the post as is
                acc.push(post);
              }
              return acc;
            },
            []
          );

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });
        setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
        //setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast("error", "Comment could not be Posted", 3000);
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: "",
        comment_text: "",
      }));
      setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const post = displayProfilePosts?.newfilteredGridData?.[0];

  return (
    <>
     {/* <HeadProvider>
        {displayProfilePosts?.newfilteredGridData && (
          <>
            <Meta property="og:url" content={window.location?.href} />
            <Meta property="og:type" content="website" />
            <Meta property="og:title" content={displayProfilePosts?.newfilteredGridData[0]?.postdescr || "Post Title"} />
            <Meta property="og:description" content={displayProfilePosts?.newfilteredGridData[0]?.postdescr || "Post Description"} />

            {displayProfilePosts?.newfilteredGridData[0]?.postimage && (
              <>
                <Meta property="og:image" content={`${BASEPATH}${GET_IMAGES_PROFILE}${displayProfilePosts?.newfilteredGridData[0]?.postimage}`} />
                <Meta property="og:image:width" content="400" />  
                <Meta property="og:image:height" content="400" />
              </>
            )}

            {isUrl(displayProfilePosts?.newfilteredGridData[0]?.postdescr) && (
              <>
                <Meta property="og:video:url" content={displayProfilePosts?.newfilteredGridData[0]?.postdescr} />
                <Meta property="og:video:width" content="640" />
                <Meta property="og:video:height" content="360" />
                <Meta property="og:video:type" content="text/html" />
              </>
            )}

            {displayProfilePosts?.newfilteredGridData[0]?.postvideo && (
              <>
                <Meta property="og:video:url" content={displayProfilePosts?.newfilteredGridData[0]?.postvideo} />
                <Meta property="og:video:width" content="640" />
                <Meta property="og:video:height" content="360" />
                <Meta property="og:video:type" content="text/html" />
              </>
            )}

            {displayProfilePosts?.newfilteredGridData[0]?.postaudio && (
              <Meta property="og:audio" content={displayProfilePosts?.newfilteredGridData[0]?.postaudio} />
            )}
          </>
        )}
      </HeadProvider>  */}



      {displayProfilePosts?.newfilteredGridData &&
        displayProfilePosts?.newfilteredGridData?.map((item) => (
          <div className="container d-flex justify-content-center">
            <div key={item.postid} className="specific-post d-flex flex-column dx-card " style={{ padding: '40px' }}>
              <div className="d-flex flex-column gap-2">





                <div className="d-flex gap-2 align-items-center">

                  {
                    profileData?.rolename === "artist" ? (
                      isValidImage(profileData?.photo) && imageExists(`${ArtistallImg}/${profileData?.photo}`) ? (
                        <img
                          src={`${ArtistallImg}/${profileData?.photo}`}

                          //onError={handleimageErrorDisplayPost}
                          //onLoad={handleimageSuccessDisplayPost}
                          className=" "
                          style={{ width: '40px', height: '40px', borderRadius: '20px', overflow: 'hidden' }}
                          alt="Artist"
                        />
                      ) : (
                        <Noimage_circle />
                      )
                    ) : profileData?.rolename === "organiser" ? (
                      isValidImage(profileData?.photo) && imageExists(`${ORGANISER_URL}/${profileData?.photo}`) ? (
                        <>
                          <img
                            src={`${ORGANISER_URL}/${profileData?.photo}`}

                            //onError={handleimageErrorDisplayPost}
                            //onLoad={handleimageSuccessDisplayPost}
                            className=" "
                            style={{ width: '40px', height: '40px', borderRadius: '20px', overflow: 'hidden' }}
                            alt="Organiser"
                          />
                        </>) : (
                        <Noimage_circle />
                      )
                    ) : profileData?.rolename === "fan" ? (
                      isValidImage(profileData?.photo) && imageExists(`${Fan_images}/${profileData?.photo}`) ? (
                        <>
                          <img
                            src={`${Fan_images}/${profileData?.photo}`}

                            //onError={handleimageErrorDisplayPost}
                            //onLoad={handleimageSuccessDisplayPost}
                            className="img-fluid object-cover"
                            alt="Fan"
                          />
                        </>) : (
                        <Noimage_circle />
                      )
                    ) : (
                      <Noimage_circle />
                    )
                  }


                  <div className="d-flex flex-column">
                    <h5 className="m-0 p-0">
                      {profileData.name || ""}
                    </h5>
                    <div className="time justify-content-start gap-2">
                      <p className="p-0 m-0">{formatDate(item.postcreateddate)}</p>

                      <i className="global-icon"></i>
                    </div>
                  </div>

                  <div id="container">
                                    <Dropdown
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="primary"
                                        id="dropdown-basic"
                                        className="bg-white button"
                                        style={{
                                          border: "none",
                                          width: "40px",
                                          height: "40px",
                                          color: "black",
                                          padding: 0,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <BsThreeDots style={{ color: "black" }} /> {/* Custom Icon */}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {user && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleReport(item.postid)
                                            }
                                          >
                                            Report this post
                                          </Dropdown.Item>
                                        )}
                                        {user?.role_id === roleidstate && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleModalDelete(item.postid)
                                            }
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>    


                </div>

              </div>
              <div
                className="w-100 d-flex flex-column justify-content-center"
              >
                {/* <p className="text-start">{item.postdescr}</p> */}
                {item.postdescr && (
                  ReactPlayer.canPlay(item.postdescr) ? (
                    <ReactPlayer
                      style={{ width: '440px' }}
                      url={item.postdescr}
                      controls={true}
                    />
                  ) : (
                    <p className="text-start">{item.postdescr}</p>
                  )
                )}
                <div className="w-100 d-flex justify-content-center ">
                  { isValidImage(item.postimage) && imageExists(`${BASEPATH}${GET_IMAGES_PROFILE}${item.postimage}`) ? (
                    <img
                      className=""
                      style={{ width: '440px' }}
                      src={`${BASEPATH}${GET_IMAGES_PROFILE}${item.postimage}`}
                      alt={item.postdescr}
                      //onError={handleImageError}
                      //onLoad={handleImageSuccess}
                    />
                  ) : (
                    null
                  )}

                  {item.postvideo && (
                    <ReactPlayer
                      style={{ width: '440px' }}
                      url={item.postvideo}
                      controls={true}
                    />
                  )}

                  {item.postaudio && (
                    <ReactPlayer
                      style={{ width: '440px' }}
                      url={item.postaudio}
                      controls={true}
                    />
                  )}
                </div>

              </div>

              <div className="feedback-info align-items-center">

<div className="threads-and-share">
  <div className="threads">
    <button
      onClick={() =>
        handleNamesofAllThoseLiked(
          item.postid,
          item.postlikestatus
        )
      }
      className="like-button"
    >
      {item.countpostlike
        ? item.countpostlike
        : 0}{" "}
      Likes,
    </button>
    {item.countpostcomment
      ? item.countpostcomment
      : 0}{" "}
    Comments
  </div>
</div>
</div>

<div className="feedback-action mt-3">
                                    <div className="fb-wrapper">
                                      {/* <i className="fb-icon thumb-up far fa-thumbs-up"></i> */}

                                      <button
                                        disabled={!!loadingLikes[item.postid]}
                                        //className={`like-btn ${item.plikepostid ? 'liked' : ''}`}
                                        onClick={() =>
                                          handleLikePost(
                                            item.postid,
                                            item.postlikestatus
                                          )
                                        }
                                      >
                                      

                                        {item.postlikestatus === "Y" && (
                                          <i class="fa-solid fa-heart"></i>
                                        )}

                                       
                                        {item.postlikestatus === "N" && (
                                          <i class="fa-regular fa-heart"></i>
                                        )}

                                       
                                        {(item.postlikestatus === null ||
                                          item.postlikestatus ===
                                          undefined) && (
                                            <i class="fa-regular fa-heart"></i>
                                          )}
                                      </button>
                                    </div>
                                    <div className="fb-wrapper">
                                      <i className="fb-icon response far fa-comment-alt"></i>

                                      <button
                                        onClick={() =>
                                          handleComment(item.postid)
                                        }
                                      >
                                        Comment
                                      </button>
                                    </div>


                                  </div>

          <>
          {popup.commentbox_visible &&
                                    postCollection.id_for_comment ===
                                    item.postid && (
                                      <div>
                                        <Modal
                                          show={popup.commentbox_visible}
                                          onHide={handleClose_commentpopup}
                                          centered
                                          className=""
                                        >
                                          <Modal.Header className="p-2 border-bottom-0">
                                            {GridPostHeader(item.postid)}
                                          </Modal.Header>
                                          <Modal.Body className="p-2 commentPopScroll">
                                            {GridPostDisplayinPopup(
                                              item.postid
                                            )}

                                            {commentsComponent()}
                                          </Modal.Body>

                                          {checkereditComment === 0 && (<>
                                            <Modal.Footer className="d-flex justify-content-end border-top-0 p-2">
                                              <div className="w-100 d-flex m-0 gap-3 align-items-center justify-content-between">

                                                {
                                                  user.rolename === "artist" ? (
                                                    isValidImage(profileData?.photo) && imageExists(`${ArtistallImg}/${profileData?.photo}`)  ? (
                                                      <img
                                                        src={`${ArtistallImg}/${profileData?.photo}`}

                                                        //onError={handleimageErrorDisplayPost}
                                                        //onLoad={handleimageSuccessDisplayPost}
                                                        className="commentImg rounded-circle"
                                                        alt="Artist"
                                                      />
                                                    ) : (
                                                      <Noimage_circle />
                                                    )
                                                  ) : user.rolename === "organiser" ? (
                                                    isValidImage(profileData?.photo) && imageExists(`${ORGANISER_URL}/${profileData?.photo}`)  ? (
                                                      <>
                                                        <img
                                                          src={`${ORGANISER_URL}/${profileData?.photo}`}

                                                          //onError={handleimageErrorDisplayPost}
                                                          //onLoad={handleimageSuccessDisplayPost}
                                                          className="commentImg rounded-circle"
                                                          alt="Organiser"
                                                        />
                                                      </>) : (
                                                      <Noimage_circle />
                                                    )
                                                  ) : user.rolename === "fan" ? (
                                                    isValidImage(profileData?.photo) && imageExists(`${Fan_images}/${profileData?.photo}`)  ? (
                                                      <>
                                                        <img
                                                          src={`${Fan_images}/${profileData?.photo}`}

                                                          //onError={handleimageErrorDisplayPost}
                                                          //onLoad={handleimageSuccessDisplayPost}
                                                          className="commentImg rounded-circle"
                                                          alt="Fan"
                                                        />
                                                      </>) : (
                                                      <Noimage_circle />
                                                    )
                                                  ) : (
                                                    <Noimage_circle />
                                                  )
                                                }


                                                <textarea
                                                  className="w-100 textBoxBorder"
                                                  //value={text}
                                                  value={
                                                    postCollection.comment_text
                                                      ? postCollection.comment_text
                                                      : ""
                                                  }
                                                  onChange={
                                                    handleCommentTextbox
                                                  }
                                                  placeholder="Leave a comment..."
                                                />
                                                <button
                                                  className="btn p-1 m-0 float-end videoPostBtn"
                                                  onClick={
                                                    handleCommentSubmitButton
                                                  }
                                                >
                                                  <BsFillSendFill />
                                                </button>
                                              </div>
                                              <div className="iq-button d-flex gap-2">
                                                {/* <button
                  className="btn p-2"
                  onClick={
                    handleCommentSubmitButton
                  }
                >
                  Submit
                </button> */}
                                                {/* <button
                  className="p-2 sendMsgBtn "
                  onClick={handleCommentReset}
                >
                  Reset
                </button> */}
                                                {postCollection.validation_for_comments && (
                                                  <span>
                                                    {
                                                      postCollection.validation_for_comments
                                                    }
                                                  </span>
                                                )}
                                              </div>
                                            </Modal.Footer>
                                          </>)}
                                        </Modal>
                                      </div>
                                    )}
          </>

          <>
                          <Modal
                            show={popup.deleteornot}
                            onHide={handleClose}
                            centered
                          >
                            <Modal.Header
                              className="m-0 d-flex align-items-center"
                              style={{ padding: "20px 12px" }}
                            >
                              <h5 className="text-nowrap">
                                Are you sure you want to delete this Post?
                              </h5>
                            </Modal.Header>
                            <Modal.Body
                              className="d-flex flex-row gap-3 justify-content-center"
                              style={{ padding: "20px 12px" }}
                            >
                              <button className="btn" onClick={handleYesDelete}>
                                Yes
                              </button>
                              <button className="btn" onClick={handleNoDelete}>
                                No
                              </button>
                            </Modal.Body>
                          </Modal>
                        </>

                        <>
                          {popup.report_visible && (
                            <div>
                              <Modal
                                show={popup.report_visible}
                                onHide={handleClose_reportpopup}
                                centered
                              >
                                <Modal.Header>
                                  {GridPostHeader_reportthispost()}
                                </Modal.Header>
                                <Modal.Body
                                  className="d-flex flex-column gap-4 justify-content-center m-0"
                                  style={{ padding: "20px" }}
                                >
                                  {GridPostDisplayinPopup_reportthispost()}

                                  <input
                                    type="text"
                                    className="w-100"
                                    //value={text}
                                    value={
                                      displayProfilePosts.reporttext
                                    }
                                    onChange={handleReportTextbox}
                                    placeholder="Report this post"
                                  />
                                  <button
                                    className="button"
                                    style={{ width: "fit-content" }}
                                    onClick={
                                      handleReportSubmitButton
                                    }
                                  >
                                    Submit
                                  </button>

                                  {displayProfilePosts.validationmsg_for_reporttext && (
                                    <span>
                                      {
                                        displayProfilePosts.validationmsg_for_reporttext
                                      }
                                    </span>
                                  )}
                                </Modal.Body>
                              </Modal>
                            </div>
                          )}
                        </>


            </div>
          </div>

        ))
      }
    </>
  );
}