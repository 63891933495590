import React from "react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { BASEPATH } from "../../../config/config";
import axios from "axios";
import { TextBox } from "devextreme-react";
import { Validator, PatternRule } from 'devextreme-react/validator';
import { ADD_ACCOUNT_DETAILS, GET_ACCOUNT_DETAILS, UPDATE_ACCOUNT_DETAILS } from "../../../Api/api";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import { useMutation } from "@tanstack/react-query";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function AccountDetails_myprofile() {

    const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);

    const token = localStorage.getItem("MDnD_auth");



    const [accountCollection, setAccountCollection] = useState({
        account_name: "",
        account_number: "",
        ifsc_code: "",
        micr_code: "",
        pan: "",
        gstin: "",
        isValid_pan: false,
        isValid_gstin: false,
        isValid_ifsc: false,
        isValid_micr: false,

        empty_validation_accountName: "",
        empty_validation_accNumber: "",
        empty_validation_ifsc: "",
        empty_validation_micr: "",
        empty_validation_pan: "",
        empty_validation_gstin: "",

        correctness_pan: "",
        correctness_gstin: "",
        correctness_ifsc: "",
        correctness_micr: "",

    });

    const [AccountfromBackend, setAccountfrombackend] = useState({

        finalData: "",
        account_name: "",
        account_number: "",
        ifsc_code: "",
        micr_code: "",
        pan: "",
        gstin: "",
        isValid_pan: true,
        isValid_gstin: true,
        isValid_ifsc: true,
        isValid_micr: true,
        correctness_pan: "",
        correctness_gstin: "",
        correctness_ifsc: "",
        correctness_micr: "",

        empty_validation_accountName: "",
        empty_validation_accNumber: "",
        empty_validation_ifsc: "",
        empty_validation_micr: "",
        empty_validation_pan: "",
        empty_validation_gstin: "",



    })
    const [checker, setChecker] = useState(true);
    const [loading, setLoading] = useState(true);


    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i;
    const gstPattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/i;
    const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/i;
    const micrPattern = /^[0-9]{9}$/;

    const [counter, setCounter] = useState(0);
    const [checkEditing, setcheckEditing] = useState(false);

    //onValueChanged functions.......................................
    const handleAccountName = (value) => {
        setAccountCollection(prevState => ({
            ...prevState,
            account_name: value
        }));


    }

    const handleAccountNumber = (value) => {
        setAccountCollection(prevState => ({
            ...prevState,
            account_number: value
        }));
    }

    const handleIFSCCode = (value) => {
        setAccountCollection(prevState => ({
            ...prevState,
            ifsc_code: value
        }));
    }

    const handleMICRCode = (value) => {
        setAccountCollection(prevState => ({
            ...prevState,
            micr_code: value
        }));
    }
    const handlePan = (value) => {
        setAccountCollection(prevState => ({
            ...prevState,
            pan: value
        }));
    }
    const handleGSTNumber = (value) => {
        setAccountCollection(prevState => ({
            ...prevState,
            gstin: value
        }));
    }

    //end of onValueCHanged functions.............................

    //correctness validation....

    const handleValidation_pan = ({ isValid }) => {
        //console.log(isValid,"isValid in start account PAN");
        setAccountCollection(prevState => {
            if (prevState.isValid_pan !== isValid) {
                return {
                    ...prevState,
                    isValid_pan: isValid
                };
            }
            return prevState;
        });
    };

    const handleValidation_gstin = ({ isValid }) => {
        setAccountCollection(prevState => {
            if (prevState.isValid_gstin !== isValid) {
                return {
                    ...prevState,
                    isValid_gstin: isValid
                };
            }
            return prevState;
        });
    };

    const handleValidation_ifsc = ({ isValid }) => {
        setAccountCollection(prevState => {
            if (prevState.isValid_ifsc !== isValid) {
                return {
                    ...prevState,
                    isValid_ifsc: isValid
                };
            }
            return prevState;
        });
    };

    const handleValidation_micr = ({ isValid }) => {
        setAccountCollection(prevState => {
            if (prevState.isValid_micr !== isValid) {
                return {
                    ...prevState,
                    isValid_micr: isValid
                };
            }
            return prevState;
        });
    };

    //console.log(accountCollection, "accountCollection");

    //SUBMIT button...............

    const handleAccountSubmit = (event) => {
        event.preventDefault();

        if (accountCollection.account_name == "") {
            setAccountCollection((prevState) => ({ ...prevState, empty_validation_accountName: "Please Enter something!" }));
            return;
        }
        if (accountCollection.account_number == "") {
            setAccountCollection((prevState) => ({ ...prevState, empty_validation_accNumber: "Please Enter something!" }));
            return;
        }
        if (accountCollection.ifsc_code == "") {
            setAccountCollection((prevState) => ({ ...prevState, empty_validation_ifsc: "Please Enter something!" }));
            return;
        }
        if (accountCollection.micr_code == "") {
            setAccountCollection((prevState) => ({ ...prevState, empty_validation_micr: "Please Enter something!" }));
            return;
        }
        if (accountCollection.pan == "") {
            setAccountCollection((prevState) => ({ ...prevState, empty_validation_pan: "Please Enter something!" }));
            return;
        }
        if (accountCollection.gstin == "") {
            setAccountCollection((prevState) => ({ ...prevState, empty_validation_gstin: "Please Enter something!" }));
            return;
        }


        if (accountCollection.isValid_pan === false) {
            setAccountCollection((prevState) => ({ ...prevState, correctness_pan: "Please Enter Valid PAN!" }));
            return;
        }
        if (accountCollection.isValid_gstin === false) {
            setAccountCollection((prevState) => ({ ...prevState, correctness_gstin: "Please Enter Valid GST!" }));
            return;
        }
        if (accountCollection.isValid_ifsc === false) {
            setAccountCollection((prevState) => ({ ...prevState, correctness_ifsc: "Please Enter Valid IFSC!" }));
            return;
        }
        if (accountCollection.isValid_micr === false) {
            setAccountCollection((prevState) => ({ ...prevState, correctness_micr: "Please Enter Valid MICR!" }));
            return;
        }

        setAccountCollection((prevState) => ({
            ...prevState,
            empty_validation_accountName: "",
            empty_validation_accNumber: "",
            empty_validation_ifsc: "",
            empty_validation_micr: "",
            empty_validation_pan: "",
            empty_validation_gstin: "",

            correctness_pan: "",
            correctness_gstin: "",
            correctness_ifsc: "",
            correctness_micr: "",
            isValid_pan: false,
            isValid_gstin: false,
            isValid_ifsc: false,
            isValid_micr: false,
        }));

        mutationAccountDetails.mutate();

    }

    const PostAccountDetails = () =>
        POST_ACCOUNTFIELDS(ADD_ACCOUNT_DETAILS);

    const POST_ACCOUNTFIELDS = async (
        ADD_ACCOUNT_DETAILS) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${ADD_ACCOUNT_DETAILS}`,
                data: {
                    login_id: user ? Number(user.login_id) : "",
                    role_id: user ? Number(user.role_id) : "",
                    account_name: accountCollection.account_name ? accountCollection.account_name : "",
                    account_number: accountCollection.account_number ? accountCollection.account_number : "",
                    ifsc_code: accountCollection.ifsc_code ? accountCollection.ifsc_code : "",
                    micr_code: accountCollection.micr_code ? accountCollection.micr_code : "",
                    pan: accountCollection.pan ? accountCollection.pan : "",
                    gstin: accountCollection.gstin ? accountCollection.gstin : "",

                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',

            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationAccountDetails = useMutation({
        mutationKey: ["add_Account_details"],
        mutationFn: () => PostAccountDetails(),

        onSuccess: (data) => {


            if (data.status === "success") {

                

                setAccountCollection((prevState) => ({
                    ...prevState,
                    account_name: "",
                    account_number: "",
                    ifsc_code: "",
                    micr_code: "",
                    pan: "",
                    gstin: "",
                    isValid_pan: false,
                    isValid_gstin: false,
                    isValid_ifsc: false,
                    isValid_micr: false,
                }));

                SuccessNewToaster("success", data?.message, 3000);
                setCounter(counter + 1);
                //setChecker(false);

            }
            else {
                setAccountCollection((prevState) => ({
                    ...prevState,
                    account_name: "",
                    account_number: "",
                    ifsc_code: "",
                    micr_code: "",
                    pan: "",
                    gstin: "",
                    isValid_pan: false,
                    isValid_gstin: false,
                    isValid_ifsc: false,
                    isValid_micr: false,
                }));

            }
        },
        onError: (error) => {

            HandleErrorToast("error", "Form could not be Submitted. Something went Wrong!", 3000);

        },
    });


    // to get all the account details of a 

    useEffect(() => {
        if (user) { mutationGetAccountDetails.mutate(); }
    }, [counter])

    const GetallAccount = () =>
        GET_ACCOUNTDETAILS_FROM_BACKEND(GET_ACCOUNT_DETAILS);

    const GET_ACCOUNTDETAILS_FROM_BACKEND = async (
        GET_ACCOUNT_DETAILS) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_ACCOUNT_DETAILS}`,

                data: {
                    role_id: user ? user.role_id : "",

                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationGetAccountDetails = useMutation({
        mutationKey: ["get_All_Account"],
        mutationFn: () => GetallAccount(),
        onSuccess: (data) => {
            if (data.status === "success") {
                setChecker(false);
                setAccountfrombackend((prevState) => ({
                    ...prevState,
                    finalData: data.data[0],
                }));
            } else {
                setAccountfrombackend((prevState) => ({
                    ...prevState,
                    finalData: "",
                }));
            }
            setLoading(false); // Set loading to false on success
        },
        onError: (error) => {
            setAccountfrombackend((prevState) => ({
                ...prevState,
                finalData: "",
            }));
            setLoading(false); // Set loading to false on error
        },
        onMutate: () => {
            setLoading(true); // Set loading to true when mutation starts
        },
    });
    

 

    // UPDATE code BEGINS HERE..............

    useEffect(() => {
        if (AccountfromBackend.finalData) {
            setAccountfrombackend((prevState) => ({
                ...prevState,
                account_name: AccountfromBackend.finalData.account_name,
                account_number: AccountfromBackend.finalData.account_number,
                ifsc_code: AccountfromBackend.finalData.ifsc_code,
                micr_code: AccountfromBackend.finalData.micr_code,
                pan: AccountfromBackend.finalData.pan,
                gstin: AccountfromBackend.finalData.gstin,

            }));
        }
        else {
            setAccountfrombackend((prevState) => ({
                ...prevState,
                account_name: "",
                account_number: "",
                ifsc_code: "",
                micr_code: "",
                pan: "",
                gstin: ""

            }));
        }

    }, [AccountfromBackend.finalData])


    // onValueChanged functions of update

    const handleAccountName_update = (value) => {
        setAccountfrombackend(prevState => ({
            ...prevState,
            account_name: value
        }));

    }

    const handleAccountNumber_update = (value) => {
        setAccountfrombackend(prevState => ({
            ...prevState,
            account_number: value
        }));
    }
    const handleIFSCCode_update = (value) => {
        setAccountfrombackend(prevState => ({
            ...prevState,
            ifsc_code: value
        }));
    }
    const handleMICRCode_update = (value) => {
        setAccountfrombackend(prevState => ({
            ...prevState,
            micr_code: value
        }));
    }
    const handlePan_update = (value) => {
        setAccountfrombackend(prevState => ({
            ...prevState,
            pan: value
        }));
    }
    const handleGSTNumber_update = (value) => {
        setAccountfrombackend(prevState => ({
            ...prevState,
            gstin: value
        }));
    }

    //correctness validation during UPDATE..

    const handleValidation_pan_update = ({ isValid }) => {
        //console.log(isValid, "isvalid update PAN");
        setAccountfrombackend(prevState => {
            if (prevState.isValid_pan !== isValid) {
                return {
                    ...prevState,
                    isValid_pan: isValid
                };
            }
            return prevState;
        });




    };

    const handleValidation_gstin_update = ({ isValid }) => {
        setAccountfrombackend(prevState => {
            if (prevState.isValid_gstin !== isValid) {
                return {
                    ...prevState,
                    isValid_gstin: isValid
                };
            }
            return prevState;
        });
    };

    const handleValidation_ifsc_update = ({ isValid }) => {
        setAccountfrombackend(prevState => {
            if (prevState.isValid_ifsc !== isValid) {
                return {
                    ...prevState,
                    isValid_ifsc: isValid
                };
            }
            return prevState;
        });
    };

    const handleValidation_micr_update = ({ isValid }) => {
        setAccountfrombackend(prevState => {
            if (prevState.isValid_micr !== isValid) {
                return {
                    ...prevState,
                    isValid_micr: isValid
                };
            }
            return prevState;
        });
    };

    //submit button for UPDATE..

    //console.log(AccountfromBackend,"AccountfromBackend");

    const handleSubmit_update = () => {
        //console.log("inside")
      

        if(AccountfromBackend.account_name === ""){
            setAccountfrombackend((prevState) => ({ ...prevState, empty_validation_accountName: "This Field is Required" }));
            return;
        }
        if(AccountfromBackend.account_number === ""){
            setAccountfrombackend((prevState) => ({ ...prevState, empty_validation_accNumber: "This Field is Required" }));
            return;
        }
        if(AccountfromBackend.ifsc_code === ""){
            setAccountfrombackend((prevState) => ({ ...prevState, empty_validation_ifsc: "This Field is Required" }));
            return;
        }
        if(AccountfromBackend.micr_code === ""){
            setAccountfrombackend((prevState) => ({ ...prevState, empty_validation_micr: "This Field is Required" }));
            return;
        }
        if(AccountfromBackend.pan === ""){
            setAccountfrombackend((prevState) => ({ ...prevState, empty_validation_pan: "This Field is Required" }));
            return;
        }
        if(AccountfromBackend.gstin === ""){
            setAccountfrombackend((prevState) => ({ ...prevState, empty_validation_gstin: "This Field is Required" }));
            return;
        }

        if (AccountfromBackend.isValid_pan === false) {
            setAccountfrombackend((prevState) => ({ ...prevState, correctness_pan: "Please Enter Valid PAN!" }));
            return;
        }
        if (AccountfromBackend.isValid_gstin === false) {
            setAccountfrombackend((prevState) => ({ ...prevState, correctness_gstin: "Please Enter Valid GST!" }));
            return;
        }
        if (AccountfromBackend.isValid_ifsc === false) {
            setAccountfrombackend((prevState) => ({ ...prevState, correctness_ifsc: "Please Enter Valid IFSC!" }));
            return;
        }
        if (AccountfromBackend.isValid_micr === false) {
            setAccountfrombackend((prevState) => ({ ...prevState, correctness_micr: "Please Enter Valid MICR!" }));
            return;
        }

        // setAccountfrombackend((prevState) => ({
        //     ...prevState,


        //     correctness_pan: "",
        //     correctness_gstin: "",
        //     correctness_ifsc: "",
        //     correctness_micr: "",
        //     isValid_pan: true,
        //     isValid_gstin: true,
        //     isValid_ifsc: true,
        //     isValid_micr: true,
        // }));

        mutationEditAccountDetails.mutate();


    }

    const EditAccountDetails = () =>
        EDIT_ACCOUNTFIELDS(UPDATE_ACCOUNT_DETAILS);

    const EDIT_ACCOUNTFIELDS = async (
        UPDATE_ACCOUNT_DETAILS) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${UPDATE_ACCOUNT_DETAILS}`,
                data: {
                    acc_id: AccountfromBackend.finalData ? Number(AccountfromBackend.finalData.acc_id) : "",
                    login_id: user ? Number(user.login_id) : "",
                    role_id: user ? Number(user.role_id) : "",
                    account_name: AccountfromBackend.account_name ? AccountfromBackend.account_name : "",
                    account_number: AccountfromBackend.account_number ? AccountfromBackend.account_number : "",
                    ifsc_code: AccountfromBackend.ifsc_code ? AccountfromBackend.ifsc_code : "",
                    micr_code: AccountfromBackend.micr_code ? AccountfromBackend.micr_code : "",
                    pan: AccountfromBackend.pan ? AccountfromBackend.pan : "",
                    gstin: AccountfromBackend.gstin ? AccountfromBackend.gstin : "",

                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',

            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationEditAccountDetails = useMutation({
        mutationKey: ["edit_Account_details"],
        mutationFn: () => EditAccountDetails(),

        onSuccess: (data) => {


            if (data.status === 200) {


                setAccountfrombackend((prevState) => ({
                    ...prevState,
                    finalData: "",
                    account_name: "",
                    account_number: "",
                    ifsc_code: "",
                    micr_code: "",
                    pan: "",
                    gstin: "",
                    isValid_pan: true,
                    isValid_gstin: true,
                    isValid_ifsc: true,
                    isValid_micr: true,
                    empty_validation_accountName: "",
        empty_validation_accNumber: "",
        empty_validation_ifsc: "",
        empty_validation_micr: "",
        empty_validation_pan: "",
        empty_validation_gstin: "",
             correctness_pan: "",
            correctness_gstin: "",
            correctness_ifsc: "",
            correctness_micr: "",
            isValid_pan: true,
            isValid_gstin: true,
            isValid_ifsc: true,
            isValid_micr: true,

                }));

                SuccessNewToaster("success", data?.message, 3000);
                setCounter(counter + 1);


            }

        },
        onError: (error) => {

            HandleErrorToast("error", "Form could not be Updated. Something went Wrong!", 3000);

        },
    });


    const handleEdit=()=>{
        setcheckEditing(true);
    }

    const handleReset=()=>{
        setcheckEditing(false);
    }

    return (
        <>


            {checker ? (
                
                <div className="row">
                    <div className="col-md-6 col-xl-4 mb-3">
                    {loading ? (
                    <>
                      <Skeleton height={40} />

                    </>
                  ) : (
                    <TextBox

                            label="Enter Account Name"

                            labelMode="floating"
                            onValueChanged={(e) => handleAccountName(e.value)}

                            value={accountCollection.account_name}
                        />
                  )}
                        
                        {
                            accountCollection.empty_validation_accountName && <span style={{ color: 'red' }} >{accountCollection.empty_validation_accountName}</span>
                        }


                    </div>
                    <br></br>
                    <div className="col-md-6 col-xl-4 mb-3">
                    {loading ? (
                    <>
                      <Skeleton height={40} />

                    </>
                  ) : (
                    <TextBox

                            label="Enter Account Number"

                            labelMode="floating"
                            onValueChanged={(e) => handleAccountNumber(e.value)}

                            value={accountCollection.account_number}
                        />
                  )}
                        
                        {
                            accountCollection.empty_validation_accNumber && <span style={{ color: 'red' }} >{accountCollection.empty_validation_accNumber}</span>
                        }

                    </div>
                    <br></br>

                    <div className="col-md-6 col-xl-4 mb-3">
                        {loading ? (
                    <>
                      <Skeleton height={40} />

                    </>
                  ) : (
                    <TextBox

                            label="Enter IFSC Code"

                            labelMode="floating"
                            onValueChanged={(e) => handleIFSCCode(e.value)}

                            value={accountCollection.ifsc_code}
                        >
                            <Validator onValidated={handleValidation_ifsc}>
                                <PatternRule
                                    message="Please enter a valid IFSC code!"
                                    pattern={ifscPattern}
                                />
                            </Validator>
                        </TextBox>
                  )}
                        
                        {
                            accountCollection.empty_validation_ifsc && <span style={{ color: 'red' }} >{accountCollection.empty_validation_ifsc}</span>
                        }

                    </div>
                    <br></br>
                    <div className="col-md-6 col-xl-4 mb-3">
                    {loading ? (
                    <>
                      <Skeleton height={40} />

                    </>
                  ) : (
                    <TextBox
                            label="Enter MICR Code"
                            labelMode="floating"
                            onValueChanged={(e) => handleMICRCode(e.value)}

                            value={accountCollection.micr_code}
                        >
                            <Validator onValidated={handleValidation_micr}>
                                <PatternRule
                                    message="Please enter a valid MICR code!"
                                    pattern={micrPattern}
                                />
                            </Validator>
                        </TextBox>
                  )}
                        
                        {
                            accountCollection.empty_validation_micr && <span style={{ color: 'red' }} >{accountCollection.empty_validation_micr}</span>
                        }



                    </div>
                    <br></br>
                    <div className="col-md-6 col-xl-4 mb-3">
                    {loading ? (
                    <>
                      <Skeleton height={40} />

                    </>
                  ) : (
                    <TextBox

                            label="Enter PAN"

                            labelMode="floating"
                            onValueChanged={(e) => handlePan(e.value)}

                            value={accountCollection.pan}
                        >

                            <Validator onValidated={handleValidation_pan}>
                                <PatternRule
                                    message="Please enter a valid PAN number!"
                                    pattern={panPattern}
                                />
                            </Validator>
                        </TextBox>
                  )}
                        

                        {
                            accountCollection.empty_validation_pan && <span style={{ color: 'red' }} >{accountCollection.empty_validation_pan}</span>
                        }

                    </div>
                    <br></br>
                    <div className="col-md-6 col-xl-4 mb-3">
                    {loading ? (
                    <>
                      <Skeleton height={40} />

                    </>
                  ) : (
                    <TextBox

                            label="Enter GST Number"

                            labelMode="floating"
                            onValueChanged={(e) => handleGSTNumber(e.value)}

                            value={accountCollection.gstin}
                        >
                            <Validator onValidated={handleValidation_gstin}>
                                <PatternRule
                                    message="Please enter a valid GST number!"
                                    pattern={gstPattern}
                                />
                            </Validator>
                        </TextBox>
                  )}
                        

                        {
                            accountCollection.empty_validation_gstin && <span style={{ color: 'red' }} >{accountCollection.empty_validation_gstin}</span>
                        }

                    </div>
                    <br></br>

                    <button className="button  ms-3 px-5" onClick={handleAccountSubmit}>Submit</button>

                </ div>
            ) : 
            (
              
                
                <div className="row">

              
               <>
                    <div className="col-md-6 col-xl-4 mb-3">
                        <TextBox

                            label="Edit your Account Name"
                            labelMode="floating"
                            onValueChanged={(e) => handleAccountName_update(e.value)}

                            value={AccountfromBackend.account_name}
                        />
                    {
                     AccountfromBackend.empty_validation_accountName && 
                    <span style={{color:'red'}}>{AccountfromBackend.empty_validation_accountName}</span>   
                    }    
                    </div>
                    <br></br>
                    <div className="col-md-6 col-xl-4 mb-3">
                        <TextBox

                            label="Edit your Bank Account Number"

                            labelMode="floating"
                            onValueChanged={(e) => handleAccountNumber_update(e.value)}

                            value={AccountfromBackend.account_number}
                        />
                  {
                     AccountfromBackend.empty_validation_accNumber && 
                    <span style={{color:'red'}}>{AccountfromBackend.empty_validation_accNumber}</span>   
                    } 

                    </div>
                    <br></br>

                    <div className="col-md-6 col-xl-4 mb-3">
                        <TextBox

                            label="Edit your IFSC Code"

                            labelMode="floating"
                            onValueChanged={(e) => handleIFSCCode_update(e.value)}

                            value={AccountfromBackend.ifsc_code}
                        >
                            <Validator onValidated={handleValidation_ifsc_update}>
                                <PatternRule
                                    message="Please enter a valid IFSC code!"
                                    pattern={ifscPattern}
                                />
                            </Validator>
                        </TextBox>
                        {
                     AccountfromBackend.empty_validation_ifsc && 
                    <span style={{color:'red'}}>{AccountfromBackend.empty_validation_ifsc}</span>   
                    } 
                     
                    </div>
                    <br></br>

                    <div className="col-md-6 col-xl-4 mb-3">
                        <TextBox

                            label="Edit your MICR Code"

                            labelMode="floating"
                            onValueChanged={(e) => handleMICRCode_update(e.value)}

                            value={AccountfromBackend.micr_code}
                        >
                            <Validator onValidated={handleValidation_micr_update}>
                                <PatternRule
                                    message="Please enter a valid MICR code!"
                                    pattern={micrPattern}
                                />
                            </Validator>
                        </TextBox>
                        {
                     AccountfromBackend.empty_validation_micr && 
                    <span style={{color:'red'}}>{AccountfromBackend.empty_validation_micr}</span>   
                    } 

                    </div>
                    <br></br>
                    <div className="col-md-6 col-xl-4 mb-3">
                        <TextBox

                            label="Edit your PAN"

                            labelMode="floating"
                            onValueChanged={(e) => handlePan_update(e.value)}

                            value={AccountfromBackend.pan}
                        >

                            <Validator onValidated={handleValidation_pan_update}>
                                <PatternRule
                                    message="Please enter a valid PAN number!"
                                    pattern={panPattern}
                                />
                            </Validator>
                        </TextBox>
                        {
                     AccountfromBackend.empty_validation_pan && 
                    <span style={{color:'red'}}>{AccountfromBackend.empty_validation_pan}</span>   
                    } 

                    </div>
                    <br></br>
                    <div className="col-md-6 col-xl-4 mb-3">
                        <TextBox

                            label="Edit your GST Number"

                            labelMode="floating"
                            onValueChanged={(e) => handleGSTNumber_update(e.value)}

                            value={AccountfromBackend.gstin}
                        >
                            <Validator onValidated={handleValidation_gstin_update}>
                                <PatternRule
                                    message="Please enter a valid GST number!"
                                    pattern={gstPattern}
                                />
                            </Validator>
                        </TextBox>

                        {
                     AccountfromBackend.empty_validation_gstin && 
                    <span style={{color:'red'}}>{AccountfromBackend.empty_validation_gstin}</span>   
                    }

                    </div>
                    
                    <button className="button ms-3 px-5" onClick={handleSubmit_update}>Save</button>
                    {/* <button className="button ms-3 px-5" onClick={handleReset}>Reset</button> */}
                    
                    </>

               


                </div> 
            )}



        </>
    )
}