import React, { useContext, useEffect, useRef } from "react";
import {
  statusBlocked,
  statusBooked,
  screenSvgMini,
} from "../../../global/constants";
import "./createSeatingLayout.css";
import { GlobalContext } from "../../../global/provider";

const LayoutMiniMap = ({ dataSource, minimapBox }) => {
  const { setEventDataSource } = useContext(GlobalContext);
  const seatingLayoutRef = useRef(null);
  const floorSpacing = 30;
  const seatWidth = 20;
  const seatHeight = 20;
  const seatSpacingX = 20;
  const seatSpacingY = 20;

  const floors = Object.keys(dataSource.layout);

  const maxCols = floors.reduce((max, floorName) => {
    const maxColsInFloor = Object.keys(dataSource.layout[floorName]).reduce(
      (maxInRow, rowLabel) => {
        return Math.max(
          maxInRow,
          dataSource.layout[floorName][rowLabel].length
        );
      },
      0
    );
    return Math.max(max, maxColsInFloor);
  }, 0);

  const svgWidth = maxCols * seatSpacingX;
  const svgHeight =
    floors.reduce(
      (acc, floorName) =>
        acc +
        Object.keys(dataSource.layout[floorName]).length * seatSpacingY +
        floorSpacing,
      0
    ) - floorSpacing;

  useEffect(() => {
    if (svgWidth !== null || svgHeight !== null)
      setEventDataSource((prev) => ({
        ...prev,
        svgWidth: svgWidth,
        svgHeight: svgHeight,
      }));
  }, [svgWidth, svgHeight]);

  return (
    <div id="seating-layout" className="seatingLayoutScroll w-100">
      <div className="minimap d-lg-none" ref={seatingLayoutRef}>
        <g className="screenSvgMini">{screenSvgMini}</g>
        <svg
          style={{ margin: "auto", display: "flex", justifyContent: "center" }}
          width="100%"
          height="auto"
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
          preserveAspectRatio="xMinYMin meet"
        >
          {
            floors.reduce(
              (acc, floorName, floorIndex) => {
                const currentFloorYPosition =
                  acc.totalHeight + floorIndex * floorSpacing;

                const screenHeight = 10;

                const floorElement = (
                  <g
                    key={floorName}
                    transform={`translate(0, ${currentFloorYPosition})`}
                  >
                    {Object.keys(dataSource.layout[floorName]).flatMap(
                      (rowLabel, rowIndex) =>
                        dataSource.layout[floorName][rowLabel].map(
                          (seat, colIndex) => (
                            <rect
                              key={`${floorName}-${rowLabel}-${colIndex}`}
                              x={colIndex * seatSpacingX}
                              y={screenHeight + rowIndex * seatSpacingY}
                              width={seatWidth}
                              height={seatHeight}
                              fill={
                                seat.status === statusBlocked
                                  ? "#3f00ff"
                                  : seat.status === statusBooked
                                  ? "#dddddd"
                                  : seat.isAisle
                                  ? "#f0f0f0"
                                  : "#ffffff"
                              }
                              stroke={seat.colorCode || "#3f00ff"}
                              strokeWidth="1"
                              visibility={seat.isAisle ? "hidden" : "visible"}
                            />
                          )
                        )
                    )}
                    {floorIndex < floors.length - 1 && (
                      <line
                        x1="0"
                        y1={
                          screenHeight +
                          Object.keys(dataSource.layout[floorName]).length *
                            seatSpacingY +
                          5
                        }
                        x2={svgWidth}
                        y2={
                          screenHeight +
                          Object.keys(dataSource.layout[floorName]).length *
                            seatSpacingY +
                          5
                        }
                        stroke="red"
                        strokeWidth="1"
                      />
                    )}
                  </g>
                );

                const totalHeightForFloor =
                  screenHeight +
                  Object.keys(dataSource.layout[floorName]).length *
                    seatSpacingY;

                return {
                  totalHeight: acc.totalHeight + totalHeightForFloor,
                  elements: [...acc.elements, floorElement],
                };
              },
              { totalHeight: 0, elements: [] }
            ).elements
          }
          <rect
            x={minimapBox.x}
            y={minimapBox.y}
            width={minimapBox.width}
            height={minimapBox.height}
            fill="rgba(198, 179, 255, 0.5)"
            stroke="rgb(63, 0, 255)"
            strokeWidth="1"
          />
        </svg>
      </div>
    </div>
  );
};

export default LayoutMiniMap;
