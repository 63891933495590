import React, { useState, useEffect, useRef } from "react";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Lookup,
} from "devextreme-react/data-grid";
import {
  seatSequence,
  SeatNumberingFormat,
  VenueStatus,
} from "../../../global/constants.js";
import { TextBox, SelectBox, NumberBox } from "devextreme-react";
import Accordion from "react-bootstrap/Accordion";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import {
  headerOptions,
  fontFamilyOptions,
  fontSizeOptions,
  headerValues,
  sizeValues,
  fontValues,
  tabs,
} from "../../../components/others.js";
import "react-profile/themes/default";
import { useFormik } from "formik";
import { forms_venueDetails } from "../../../Schemas/Forms/LoginDashboard.js";
import { validation_venueDetails } from "../../../Schemas/Events.js";
import { handleImageChange, isValidValue } from "../../../utils/commonUtils.js";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";
import { COUNTRY } from "../../../Api/api.js";
import { makeGetRequest } from "../../../Api/requestMethods.js";
import { cityFetch, stateFetch } from "../Events/EventsFetch.js";
import MapComponent from "../../../components/Maps/Map.js";
import SearchLocationInput from "../../../components/Maps/googlePlaces.js";
import { MapLocationEmbeded } from "../../../utils/commonUtils.js";
import { seatNumberingFormatNumber } from "../../../global/constants.js";
import { ValidationRule } from "devextreme-react/data-grid";
const SeatingLayoutForms = ({
  settings,
  dataSource,
  setSettings,
  setFieldValue,
  values,
  setDataSource,
  submitLayout,
  handleBlur,
  handleChange,
  errors,
  touched,
  onDataSaved,
  activeKey,
  setActiveKey,
}) => {
  const [isAccordionOpen] = useState(true);
  const [isMultiline, setIsMultiline] = useState(true);
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const [selectedLocation, setSelectedLocation] = useState({
    lat: null,
    lng: null,
  });

  // document.getElementById("container").scroll({ top: 0, behavior: "smooth" });

  useEffect(() => {
    if (selectedLocation.lat && selectedLocation.lng) {
      const MapDetails = MapLocationEmbeded(
        selectedLocation.lat,
        selectedLocation.lng
      );
      setFieldValue("location", MapDetails);
    }
  }, [selectedLocation]);

  // To Fetch Countries
  const countryEvent = async () =>
    settings.countryFocusIn && (await makeGetRequest(COUNTRY()));
  const { data: countryDataSource } = useQuery({
    queryKey: ["__country__"],
    queryFn: () => countryEvent(),
    enabled: settings.countryFocusIn,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const cityMutation = useMutation({
    mutationKey: ["__fetchcity__"],
    mutationFn: (state_id) => cityFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, cityDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const stateMutation = useMutation({
    mutationKey: ["__fetchState__"],
    mutationFn: (state_id) => stateFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, stateDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  const EventcountryFocusIn = () => {
    setSettings((prev) => ({ ...prev, countryFocusIn: true }));
  };

  useEffect(() => {
    if (isValidValue(values.country_id)) {
      const body = {
        country_id: values.country_id,
      };
      stateMutation.mutate(body);
    }
  }, [values.country_id]);

  useEffect(() => {
    if (isValidValue(values.state_id)) {
      const body = {
        state_id: values.state_id,
      };
      cityMutation.mutate(body);
    }
  }, [values.state_id]);

  return (
    <>
      <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
        <Accordion.Item
          eventKey="0"
          className="mt-4 mb-0 show"
          id="accordianOne"
        >
          <Accordion.Header className={isAccordionOpen ? "show" : "hide"}>
            Venue Details
          </Accordion.Header>
          <Accordion.Body>
            <div className="row">
              <div className="col-lg-12 pb-2">
                <TextBox
                  labelMode="floating"
                  label="Venue name"
                  value={values.venue_name}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "venue_name", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "venue_name", value: e.value },
                    })
                  }
                />
                {errors.venue_name && touched.venue_name && (
                  <p className="error-message">{errors.venue_name}</p>
                )}
              </div>
              <div className="col-lg-12 pb-2">
                <HtmlEditor
                  height="auto"
                  label="Event Description"
                  className="dx-field-item-required"
                  labelMode="floating"
                  placeholder="Event Description"
                  value={values.venue_description}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "venue_description", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "venue_description", value: e.value },
                    })
                  }
                >
                  <MediaResizing enabled={true} />
                  <ImageUpload tabs={currentTab} fileUploadMode="base64" />
                  <Toolbar multiline={isMultiline}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                      name="size"
                      acceptedValues={sizeValues}
                      options={fontSizeOptions}
                    />
                    <Item
                      name="font"
                      acceptedValues={fontValues}
                      options={fontFamilyOptions}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item
                      name="header"
                      acceptedValues={headerValues}
                      options={headerOptions}
                    />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    <Item name="separator" />
                    <Item name="insertTable" />
                    <Item name="deleteTable" />
                    <Item name="insertRowAbove" />
                    <Item name="insertRowBelow" />
                    <Item name="deleteRow" />
                    <Item name="insertColumnLeft" />
                    <Item name="insertColumnRight" />
                    <Item name="deleteColumn" />
                  </Toolbar>
                </HtmlEditor>
                {errors.venue_description && touched.venue_description && (
                  <p className="error-message">{errors.venue_description}</p>
                )}
              </div>
              <div className="col-lg-12 pb-2">
                <TextBox
                  labelMode="floating"
                  label="Address"
                  value={values.address}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "address", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "address", value: e.value },
                    })
                  }
                />
                {errors.address && touched.address && (
                  <p className="error-message">{errors.address}</p>
                )}
              </div>

              <div className="col-lg-4 pb-2">
                <SelectBox
                  labelMode="floating"
                  onFocusIn={EventcountryFocusIn}
                  displayExpr="country_name"
                  label="Country"
                  valueExpr="country_id"
                  value={values.country_id}
                  searchEnabled={true}
                  dataSource={countryDataSource?.data || null}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "country_id", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "country_id", value: e.value },
                    })
                  }
                />

                {errors.country_id && touched.country_id && (
                  <p className="error-message">{errors.country_id}</p>
                )}
              </div>

              <div className="col-lg-4 pb-2">
                <SelectBox
                  labelMode="floating"
                  label="State"
                  displayExpr="state_name"
                  valueExpr="state_id"
                  value={values.state_id}
                  searchEnabled={true}
                  dataSource={dataSource?.stateDataSource || null}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "state_id", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "state_id", value: e.value },
                    })
                  }
                />
                {errors.state_id && touched.state_id && (
                  <p className="error-message">{errors.state_id}</p>
                )}
              </div>

              <div className="col-lg-4 pb-2">
                <SelectBox
                  labelMode="floating"
                  label="City"
                  displayExpr="city_name"
                  valueExpr="city_id"
                  dataSource={dataSource?.cityDataSource || null}
                  value={values.city_id}
                  searchEnabled={true}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "city_id", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "city_id", value: e.value },
                    })
                  }
                />
                {errors.city_id && touched.city_id && (
                  <p className="error-message">{errors.city_id}</p>
                )}
              </div>
              <div className="col-lg-6 pb-2">
                <NumberBox
                  labelMode="floating"
                  label="Pincode"
                  value={values.pincode}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "pincode", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "pincode", value: e.value },
                    })
                  }
                />
              </div>

              {/* <div className="col-lg-6 pb-2">
                <FileUploader
                  labelMode="floating"
                  label="Image"
                  accept="image/*"
                  selectButtonText="Upload Event Poster / Image"
                  onValueChanged={(e) => imageOnchange(e)}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "venue_image", value: e.value },
                    })
                  }
                />
                {settings?.imageName && <p>{settings.imageName}</p>}
              </div> */}

              {selectedLocation &&
                selectedLocation.lat !== null &&
                selectedLocation.lng !== null && (
                  <div class="col-lg-4 mapPosAlign">
                    <div class="mapPosAlign">
                      <MapComponent selectedLocation={selectedLocation} />
                    </div>
                  </div>
                )}
              <div className="col-lg-6 pb-2">
                <SelectBox
                  labelMode="floating"
                  dataSource={VenueStatus}
                  label="venue status"
                  displayExpr="name"
                  valueExpr="value"
                  value={values.status}
                  onFocusOut={(e) =>
                    handleBlur({
                      target: { name: "status", value: e.value },
                    })
                  }
                  onValueChanged={(e) =>
                    handleChange({
                      target: { name: "status", value: e.value },
                    })
                  }
                />
                {errors.status && touched.status && (
                  <p className="error-message">{errors.status}</p>
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Create Venue layout</Accordion.Header>
          <Accordion.Body>
            <DataGrid
              dataSource={dataSource?.floorDetails || []}
              showBorders={true}
              columnAutoWidth={true}
              rowAlternationEnabled={true}
              onSaving={onDataSaved}
              onsa
            >
              <Paging enabled={true} />
              <Editing
                mode="row"
                allowUpdating={true}
                allowDeleting={true}
                allowAdding={true}
              />

              <Column
                dataField="floorName"
                caption="Floor Name"
                validationRules={[
                  { type: "required", message: "This field is required" },
                ]}
              />
              <Column dataField="SeatNumberingFormat" caption="Seat Format">
                <Lookup
                  dataSource={SeatNumberingFormat}
                  displayExpr="name"
                  valueExpr="value"
                />
              </Column>
              <Column
                dataField="rowName"
                caption="Row Names"
                alignment="right"
                setCellValue={(newData, value, currentRowData) => {
                  newData.rowName = value;
                  if (currentRowData.SeatNumberingFormat === "number") {
                    if (/^[\d,]+$/.test(value)) {
                      newData.rowName = value;
                    } else {
                      newData.rowName = null;
                    }
                  } else if (
                    currentRowData.SeatNumberingFormat === "alphabetic"
                  ) {
                    if (/^[a-zA-Z\s]+(?:,[a-zA-Z\s]+)*$/.test(value)) {
                      newData.rowName = value;
                    } else {
                      newData.rowName = "";
                    }
                  }

                  if (value === null || value === "") {
                    newData.rowName = "";
                  }

                  return newData;
                }}
                validationRules={[
                  { type: "required", message: "This field is required" },
                  {
                    type: "custom",
                    message:
                      "Invalid value for row name based on the seat format.",
                    validationCallback: ({ value, data }) => {
                      if (data.SeatNumberingFormat === "alphabetic") {
                        return /^[a-zA-Z\s]+(?:,[a-zA-Z\s]+)*$/.test(value);
                      } else if (data.SeatNumberingFormat === "number") {
                        return /^[\d,]+$/.test(value);
                      }
                      return false;
                    },
                  },
                ]}
              ></Column>

              <Column
                dataField="aisleRows"
                caption="Provide Aisle after (Rows)"
                width={300}
                alignment="right"
                setCellValue={(newData, value, currentRowData) => {
                  newData.aisleRows = value;
                  if (currentRowData.SeatNumberingFormat === "number") {
                    if (/^[\d,]+$/.test(value)) {
                      newData.aisleRows = value;
                    } else {
                      newData.aisleRows = null;
                    }
                  } else if (
                    currentRowData.SeatNumberingFormat === "alphabetic"
                  ) {
                    if (/^[a-zA-Z\s]+(?:,[a-zA-Z\s]+)*$/.test(value)) {
                      newData.aisleRows = value;
                    } else {
                      newData.aisleRows = "";
                    }
                  }

                  if (value === null || value === "") {
                    newData.aisleRows = "";
                  }

                  return newData;
                }}
                validationRules={[
                  { type: "required", message: "This field is required" },
                  {
                    type: "custom",
                    message:
                      "Invalid value for row name based on the seat format.",
                    validationCallback: ({ value, data }) => {
                      if (data.SeatNumberingFormat === "alphabetic") {
                        return /^[a-zA-Z\s]+(?:,[a-zA-Z\s]+)*$/.test(value);
                      } else if (data.SeatNumberingFormat === "number") {
                        return /^[\d,]+$/.test(value);
                      }
                      return false;
                    },
                  },
                ]}
              />
              <Column
                dataField="numberOfColumns"
                caption="No. of columns"
                editorType="dxNumberBox"
                editorOptions={{ min: 0 }}
                validationRules={[
                  { type: "required", message: "This field is required" },
                ]}
                alignment="right"
              />
              {/* <Column
                dataField="seatSequence"
                caption="Seat Sequence (From Stage)"
                validationRules={[
                  { type: "required", message: "This field is required" },
                ]}
              >
                <Lookup
                  dataSource={seatSequence}
                  displayExpr="name"
                  valueExpr="value"
                />
              </Column> */}
            </DataGrid>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default SeatingLayoutForms;
