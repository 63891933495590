import React, { useEffect } from "react";
import { useFormik } from "formik";
import { makePostRequest } from "../Api/requestMethods";
import { useMutation } from "@tanstack/react-query";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { LOGIN } from "../Api/api";
import notify from "devextreme/ui/notify";
import { SUCCESS, ERROR, DURATION } from "../global/ToastMsgConstants.tsx";
import { LOGIN_SCHEMA } from "../Schemas/Validations/Auth.js";
import { Link } from "react-router-dom";
import AuthContext from "../context/AuthContext.js";
import { useContext, useMemo, useState } from "react";
import { Button } from "devextreme-react";
import { CHECK_VALID_EMAIL } from "../Api/api";
import Loading from "../components/Loading.js";
import { SOMETHING_WENT_WRONG } from "../Schemas/errorMessageConstants.js";
import { EmailValidation } from "../utils/commonUtils.js";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const [passwordMode, setPasswordMode] = useState("password");
  const { LoginUserDetails } = useContext(AuthContext);
  const [emailError, setError] = useState({
    isError: false,
    errorMsg: "",
  });
  const login = async (data) => {
    try {
      const response = await makePostRequest(LOGIN, data);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const validateEmailFn = async (data) => {
    try {
      const response = await makePostRequest(CHECK_VALID_EMAIL, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const passwordButton = useMemo(
    () => ({
      icon: passwordMode === "password" ? "eyeclose" : "eyeopen",
      stylingMode: "text",
      onClick: () => {
        setPasswordMode((prevPasswordMode) =>
          prevPasswordMode === "text" ? "password" : "text"
        );
      },
    }),
    [passwordMode, setPasswordMode]
  );

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setTouched,
    setFieldError,
    setErrors,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LOGIN_SCHEMA,
    onSubmit: async (values, { setSubmitting }) => {
      if (emailError.isError && emailError.errorMsg !== "") return;
      setSubmitting(true);
      await loginMutation.mutateAsync(values);
      setSubmitting(false);
    },
  });

  const loginMutation = useMutation({
    mutationKey: ["login"],
    mutationFn: (loginData) => login(loginData),
    onSuccess: (data) => {
      if (data.status === SUCCESS) {
        LoginUserDetails(data);
        
      }
    },
    onError: (error) => {
      notify(
        error?.response?.data?.message || SOMETHING_WENT_WRONG,
        ERROR,
        DURATION
      );
      setFieldError("password", error?.response?.data?.error);
      Object.keys(errors).forEach((field) => {
        setFieldError(field, errors[field]);
        setTouched({ [field]: true });
      });
    },
  });

  const validateEmail = useMutation({
    mutationFn: (email) => validateEmailFn(email),
    onSuccess: (data) => {
      if (data.status === SUCCESS) {
        setError((prev) => ({
          ...prev,
          isError: true,
          errorMsg: data.message,
        }));
      }
    },
    onError: (error) => {
      setError((prev) => ({
        ...prev,
        isError: false,
        errorMsg: "",
      }));
    },
  });

  useEffect(() => {
    if (EmailValidation(values.email)) {
      const body = {
        email: values.email,
      };
      validateEmail.mutate(body);
    }
  }, [values.email]);

  var CHECK_CONDTION;
  if (errors.email && touched.email) {
    CHECK_CONDTION = false;
  } else {
    CHECK_CONDTION = true;
  }

  return (
    <>
      <div className="text-center">
        <h4>Login</h4>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="">
          <div className="mb-3">
            <TextBox
              type="text"
              name="email"
              label="Email"
              labelMode="floating"
              value={values.email}
              className="dx-field-item-required"
              onFocusOut={(e) =>
                handleBlur({ target: { name: "email", value: e.value } })
              }
              onValueChanged={(e) =>
                handleChange({ target: { name: "email", value: e.value } })
              }
            />
            {errors.email && touched.email && (
              <p className="error-message mt-1">{errors.email}</p>
            )}
            {CHECK_CONDTION && emailError.errorMsg !== "" && (
              <p className="error-message mt-1">{emailError.errorMsg}</p>
            )}
          </div>
          <div className="mb-3">
            <TextBox
              type="password"
              value={values.password}
              mode={passwordMode}
              name="password"
              className="dx-field-item-required"
              label="Password"
              labelMode="floating"
              onFocusOut={(e) =>
                handleBlur({ target: { name: "password", value: e.value } })
              }
              onValueChanged={(e) =>
                handleChange({ target: { name: "password", value: e.value } })
              }
            >
              <TextBoxButton
                name="password"
                location="after"
                options={passwordButton}
              />
            </TextBox>
            {errors.password && touched.password && (
              <p className="error-message mt-1">{errors.password}</p>
            )}
          </div>
          <div className="text-end mb-3">
            <Link to="/forgotpassword" style={{ color: "#3f00ff" }}>
              Forgot Password?
            </Link>
          </div>
        </div>

        <div className="full-button">
          <div className="iq-button">
            <Button
              type={"Submit"}
              disabled={isSubmitting}
              className="btn position-relative h-auto"
              text={isSubmitting ? "" : "Login"}
             
              useSubmitBehavior={true}
            >
              {isSubmitting && <Loading />}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
