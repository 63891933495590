import React, { useState, useEffect, useContext } from "react";
import { TextBox, SelectBox } from "devextreme-react";
import FileUploader from "devextreme-react/file-uploader";
import Skeleton from "react-loading-skeleton"; // Importing Skeleton
import "react-loading-skeleton/dist/skeleton.css"; // Import Skeleton CSS
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import { sizeValues, fontValues, headerValues, fontSizeOptions, fontFamilyOptions, headerOptions } from "../../../components/others";
import { markup, tabs } from "../../../components/others";
import { landingPageDetailsApi } from "../../../Api/api";
import { makePostRequest } from "../../../Api/requestMethods.js";
import { isValidValue } from "../../../utils/commonUtils";
import AuthContext from "../../../context/AuthContext.js";
import { seasonShowToPublic } from "../../../global/constants";
import notify from "devextreme/ui/notify";
import { ERROR, DURATION, SUCCESS } from "../../../global/ToastMsgConstants.tsx";

const LandingPageDetails = () => {
  const { user } = useContext(AuthContext);
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const [isMultiline, setIsMultiline] = useState(true);
  const [landingPageDataSource, setLandingPageDetails] = useState(null); // Initial state is null to show loader
  const [loading, setLoading] = useState(true); // Loading state for skeleton

  useEffect(() => {
    (async () => {
      try {
        const body = {
          roleid: user.role_id,
        };
        if (isValidValue(user.role_id)) {
          const response = await makePostRequest(landingPageDetailsApi, body);
          if (response.status === SUCCESS) {
            setLandingPageDetails(response.data && response.data[0] ? response.data[0] : []);
            setLoading(false); // Data loaded, hide skeleton
          } else {
            notify(response?.error, ERROR, DURATION);
            setLoading(false); // On error, also hide skeleton
          }
        }
      } catch (error) {
        notify(error.response.data.message, ERROR, DURATION);
        setLoading(false); // On error, hide skeleton
      }
    })();
  }, [user.role_id]);

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group my-2 ">
            <div className="widget-container">
              {loading ? (
                <Skeleton height={300} /> // Skeleton for the editor
              ) : (
                <HtmlEditor
                  height="auto"
                  defaultValue={markup}
                  label="Landing page Description"
                  className="dx-field-item-required"
                  labelMode="floating"
                  placeholder="Event Description"
                  value={landingPageDataSource.content}
                  disabled={true}
                >
                  <MediaResizing enabled={true} />
                  <ImageUpload tabs={currentTab} fileUploadMode="base64" />
                  <Toolbar multiline={isMultiline}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                      name="size"
                      acceptedValues={sizeValues}
                      options={fontSizeOptions}
                    />
                    <Item
                      name="font"
                      acceptedValues={fontValues}
                      options={fontFamilyOptions}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item
                      name="header"
                      acceptedValues={headerValues}
                      options={headerOptions}
                    />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    <Item name="separator" />
                    <Item name="insertTable" />
                    <Item name="deleteTable" />
                    <Item name="insertRowAbove" />
                    <Item name="insertRowBelow" />
                    <Item name="deleteRow" />
                    <Item name="insertColumnLeft" />
                    <Item name="insertColumnRight" />
                    <Item name="deleteColumn" />
                  </Toolbar>
                </HtmlEditor>
              )}
            </div>
            <div className="col-lg-6">
              <div className="form-group mb-2">
                {loading ? (
                  <Skeleton height={40} width="100%" /> // Skeleton for select box
                ) : (
                  <SelectBox
                    className="dx-field-item-required"
                    label="Show to public"
                    labelMode="floating"
                    dataSource={seasonShowToPublic || []}
                    readOnly={true}
                    displayExpr="name"
                    valueExpr="value"
                    value={landingPageDataSource.show_to_public}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageDetails;
