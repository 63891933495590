import React from 'react'
import Noimg from "./Noimg.png"
function RagaProfile() {
  return (
    <>
      <img src={Noimg} alt="No Data Found" style={{ width: 'auto', height: '40px'}} />
    </>
  )
}

export default RagaProfile

