import React, { useEffect, useState, useRef } from "react";
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";
import { dataSource } from "../../../global/constants";
import { Button } from "devextreme-react/button";
import { TextBox, SelectBox, TagBox, Switch } from "devextreme-react";
import { Modal } from "react-bootstrap";
import { Item } from "devextreme-react/form";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
} from "devextreme-react/html-editor";
import { markup, tabs } from "../../../components/others";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import {
  sizeValues,
  fontValues,
  headerValues,
  fontSizeOptions,
  fontFamilyOptions,
  headerOptions,
  timeLabel,
} from "../../../components/others";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
} from "../../../Api/requestMethods";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import {
  ALL_ARTIST_DETAIL,
  ALL_ORGSNISER_TAG_DETAIL,
  ARTIST_SUB_CATEGORIES,
  DELETE_RECORD_ROW_VIDEOS,
  EVENT_MAIN_CATEGORY,
  GET_BlOG_MAIN_CATEGORIES,
  GET_THUMBNAIL_VIDEOS_IN_UPLOAD,
  GET_VIDEOEDIT_DETAILS,
  UPDATEPASSWORD,
  VEDIO_VIEW_ROW_DETAIL_UPDATE,
} from "../../../Api/api";
import "./uploadVideo.css";
import { VideoImagePath } from "../../../Subpages/MainPage/constant.js";
import axios from "axios";

import notify from "devextreme/ui/notify";
import { openEditor } from "react-profile";
import { Prev } from "react-bootstrap/esm/PageItem";
import { duration } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { MDnDUploadRecordsCDN } from "../../../config/config.js";

const ViewVideos = () => {
  // -------------------------------------------------------------------------------------------------------------
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const [isMultiline, setIsMultiline] = useState(true);
  const [videoDetalil, setVideoDetail] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tagArtist, setTagArtist] = useState("");
  const [update, SetUpdate] = useState(1);
  const [tagOrganiser, setTagOrganiser] = useState("");
  const [category, setCategory] = useState({
    imageSelected: "",
    ifEditimagepoint: false,
    openThePopup: false,
    imageFileChanged: false,
    artistCategoryChanged: false,
    organiserCategoryChanged: false,
  });
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadedVideo, setUploadedVideo] = useState(null);

  const [thumbnailUrl, setThumbnailUrl] = useState("");

  const [formData, setFormData] = useState({
    video_title: "",
    video_url: "",
    thumbnail: "",
    sub_category_id: "",
    status: "",
    publisher_id: null,
    main_category_id: null,
    description: "",
    artist_ids: [],
    organiser_ids: [],
    show_to_public: true,
  });

  const [formErrors, setFormErrors] = useState({
    artist_ids: "",
    description: "",
    main_category_id: "",
    organiser_ids: "",
    publisher_id: "",
    sub_category_id: "",
    thumbnail: "",
    video_url: "",
  });
  console.log(formData, "FORMDATA");

  const [selectedArtists, setSelectedArtists] = useState([]);
  console.log(selectedArtists, selectedArtists);
  const [selectedOrganisers, setSelectedOrganisers] = useState([]);
  console.log(selectedOrganisers, "selectedOrganisers");

  const [dataSources, setDataSources] = useState({
    subCategoryVideoSource: [],
  });

  const findInput = useRef(null);
  const videoInputRef = useRef(null);
  // -------------------------------------------JWT----------------------------------------------------------------------------
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  console.log(user, "USER from jwt");

  const role_id = user?.role_id;

  //------------------------------------ViewVideosCode-----------------------------------------------------------------------------
  const fetchingVideosDetails = async () =>
    await makeGetRequest(`${GET_VIDEOEDIT_DETAILS}/${role_id}`);
  const { data: VideosSource } = useQuery({
    queryKey: ["videoDetail"],
    queryFn: () => fetchingVideosDetails(),
    onError: (error) => {
      console.error("Error fetching video:", error);
    },
    onSuccess: (data) => {
      const sortedData = data.data.sort(
        (a, b) => new Date(b.createddate) - new Date(a.createddate)
      );
      const videosWithSerialNumbers = sortedData.map((video, index) => ({
        ...video,
        serialNumber: sortedData.length - index,
      }));
      setVideoDetail(videosWithSerialNumbers);
    },
  });

  const fetchDataVideo = async () => {
    try {
      const response = await fetchingVideosDetails();
      const sortedData = response.data.sort(
        (a, b) => new Date(b.createddate) - new Date(a.createddate)
      );
      const videosWithSerialNumbers = sortedData.map((video, index) => ({
        ...video,
        serialNumber: sortedData.length - index,
      }));
      setVideoDetail(videosWithSerialNumbers);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    fetchDataVideo();
  }, [update]);

    console.log(VideosSource?.data, "VideosSource")
    // // ------------------------------------ExitPopuP-----------------------------------
    const ExitPopup = () => {
        setCategory(prev => ({ ...prev, openThePopup: false }))
    }
    // ---------------------------------OnEditButtonClick-----------------------------------------------
    const onEditButtonClick = async (data) => {
      console.log(data,"thisisEditabUTON")
        setVideoId(data.id);
        setTagArtist(data.artist_ids);
        setTagOrganiser(data.organiser_ids);
        console.log(data, "THISisEditDATA")
        const {
            artist_ids,
            organiser_ids,
            description,
            main_category_id,
            publisher_id,
            status,
            sub_category_id,
            thumbnail_url,
            video_url,
            video_title,
            id
        } = data;
        setFormData({
            video_title: video_title,
            video_url: video_url,
            thumbnail: thumbnail_url,
            sub_category_id: sub_category_id,
            status: status,
            publisher_id: publisher_id,
            main_category_id: main_category_id,
            description: description,
            artist_ids: artist_ids,
            organiser_ids : organiser_ids,
        })

    try {
      const thumbnail = await fetchThumbnail(id);
      setThumbnailUrl(thumbnail);
    } catch (error) {
      console.error("ERRROR FETCHIN THUMBNAIL", error);
      setThumbnailUrl("");
    }
    setCategory((prev) => ({ ...prev, openThePopup: true }));
  };
  // ---------------------------------OnDeleteButtonClick---------------------------------------------
  const DeleteRowUplodeVideo = async (rowid) => {
    try {
      const response = await makePutRequest(
        VEDIO_VIEW_ROW_DETAIL_UPDATE(),
        rowid
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  const deleteRowUplodMutaiton = useMutation({
    mutationKey: ["deleteVideokey"],
    mutationFn: (deleteData) => DeleteRowUplodeVideo(deleteData),
    onSuccess: (data, rowid) => {
      if (data.state === "failed") {
        notify(data.message, ERROR, DURATION);
      } else if (data.status === "success") {
        notify(data.message, SUCCESS);
      }
      setLoading(false);
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
      setLoading(false);
    },
  });

  const onDeleteButtonClick = async (data) => {
    console.log(data, "deleteDATA");
    const { id } = data;
    const updatedValues = {
      id: id,
      status: false,
      is_deleted: true,
    };
    try {
      await deleteRowUplodMutaiton.mutateAsync(updatedValues);
      const videoRow = videoDetalil
        .filter((item) => item.id !== id)
        .map((video, index, arr) => ({
          ...video,
          serialNumber: arr.length - index,
        }));
      setVideoDetail(videoRow);
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };
  // --------------------------------------------------showToPublic----------------------------------------------
  const ShowToPublicUplodeVideo = async (rowid) => {
    try {
      const response = await makePutRequest(
        VEDIO_VIEW_ROW_DETAIL_UPDATE(),
        rowid
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  const showToPublicUplodMutaiton = useMutation({
    mutationKey: ["deleteVideokey"],
    mutationFn: (deleteData) => ShowToPublicUplodeVideo(deleteData),
    onSuccess: (data, rowid) => {
      if (data.state === "failed") {
        notify(data.message, ERROR, DURATION);
      } else if (data.status === "success") {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });
  const vlaueToggled = async (e, data) => {
    //  const updatVideoData =
    setFormData((prev) => ({ ...prev, show_to_public: e.value }));

    const { id } = data;
    const updatedValues = {
      id: id,
      show_to_public: e.value ? true : false,
    };
    try {
      await showToPublicUplodMutaiton.mutateAsync(updatedValues);
    } catch (error) {}
  };
  //-----------------------------------ImageCellRender-----------------------------------------------------------
  const fetchThumbnail = async (id) => {
    try {
      const response = await makeGetRequest(
        `${GET_THUMBNAIL_VIDEOS_IN_UPLOAD}/${id}`
      );
      if (response.status === "success" && response.data.thumbnail) {
        return `data:image/png;base64,${response.data.thumbnail}`;
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error in fetchThumbnail:", error);
      throw error;
    }
  };

  const ThumbnailCell = ({ id }) => {
    const { data: imageSrc, error } = useQuery({
      queryKey: ["thumbnail", id],
      queryFn: () => fetchThumbnail(id),
    });

    if (error) {
      console.error("Error fetching thumbnail:", error);
      return <p>Error loading image</p>;
    }

    return imageSrc ? (
      <img
        src={imageSrc}
        alt="Video"
        style={{ width: "100%", height: "auto" }}
      />
    ) : (
      <p>No image</p>
    );
  };
  //------------------------------------------VideoMainCategory---------------------------------------------------------------------
  const mainCategoryVideo = async () =>
    await makeGetRequest(EVENT_MAIN_CATEGORY);
  const {
    data: mainCategoryVideoSource,
    error,
    isLoading: mainCategoryLoading,
  } = useQuery({
    queryKey: ["videomaincate"],
    queryFn: () => mainCategoryVideo(),
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });

  //------------------------------------------SubCategory--------------------------------------------------------------------------
  const subCategoryVideo = async (mainCatId) => {
    try {
      if (mainCatId) {
        const response = await makePostRequest(
          ARTIST_SUB_CATEGORIES,
          mainCatId
        );
        return response;
      }
    } catch (error) {
      throw error;
    }
  };
  const subMutation = useMutation({
    mutationKey: ["fetchSubCatdgory"],
    mutationFn: subCategoryVideo,
    onSuccess: (data) => {
      setDataSources((prev) => ({
        ...prev,
        subCategoryVideoSource: data?.data,
      }));
    },
    onError: (error) => {
      notify(error.response.data.messaege, ERROR, DURATION);
    },
  });
  useEffect(() => {
    if (
      formData.main_category_id &&
      formData.main_category_id !== "" &&
      formData.main_category_id !== null
    ) {
      const body = {
        maincatid: formData.main_category_id,
      };
      subMutation.mutate(body);
    }
  }, [formData.main_category_id]);
  // -----------------------------------------------TaggedArtist---------------------------------------------------------------
  const fetchArtistVideo = async () =>
    await makeGetRequest(ALL_ARTIST_DETAIL());
  const { data: ArtistVideoDataSource } = useQuery({
    queryKey: ["artistVideoCategory"],
    queryFn: () => fetchArtistVideo(),
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });
  //-----------------------------------------------TaggedOrgaiser------------------------------------------------------------
  const fetchOrganiserVideo = async () =>
    await makeGetRequest(ALL_ORGSNISER_TAG_DETAIL());
  const { data: OrganiserVideoDataSource } = useQuery({
    queryKey: ["organiserVideoCategory"],
    queryFn: () => fetchOrganiserVideo(),
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });

  //   -----------------------------------------------------handleEditImage---------------------------------
  const handleImageEditButtonClick = () => {
    findInput.current.click();
  };
  const handleVideoEditButtonClick = () => {
    if (videoInputRef.current) {
      videoInputRef.current.click();
    }
  };
  // ---------------------------------------------handleImageChange--------------------------------------------------------------
  const handleImageChange = async (event) => {
    console.log(event, "handleimageedit");
    try {
      const file = event.target.files?.[0];
      if (!file) {
        console.log("No file selected");
        return;
      }

      setCategory((prev) => ({
        ...prev,
        openThePopup: false,
        imageFileChanged: true,
      }));

      const blobImage = await openEditor({ src: file });
      const blob = await blobImage?.editedImage?.getBlob?.();
      if (!blob) {
        throw new Error("No new image");
      }

      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setCategory((prev) => ({
          ...prev,
          openThePopup: true,
          imageFileChanged: true,
        }));
        setFormData((prev) => ({ ...prev, thumbnail: base64String })); // Only update the thumbnail
        setThumbnailUrl(base64String);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error handling image change:", error);
    }
  };

  console.log(formData, "THISISFORMdDATA");

  // --------------------------------------------handleVideoChange-------------------------------------------------------------

  const handleVideoChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("video", file);

      try {
        simulateUploadProgress(file);
        const response = await fetch(MDnDUploadRecordsCDN, {
          method: "POST",
          body: formData,
        });
        if (!response.ok) {
          throw new Error("Failed to upload video");
        }
        const data = await response.json();
        const cdnLink = data.path;
        setFormData.video_url(cdnLink);
      } catch (error) {
        console.error("Error uploading video:", error);
        notify("Failed to upload video", ERROR, DURATION);
      }
    }
  };

  const simulateUploadProgress = (file) => {
    let progress = 0;
    const interval = setInterval(() => {
      progress += 10;
      setUploadProgress(progress);

      if (progress >= 100) {
        clearInterval(interval);
        setUploadProgress(100);
        setUploadedVideo(file);
      }
    }, 500);
  };

  const SubmitUpdatedVideoFunction = async (data) => {
    try {
      const response = await makePutRequest(
        VEDIO_VIEW_ROW_DETAIL_UPDATE(),
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const viewVideoDetailUpdate = useMutation({
    mutationKey: ["videVideo"],
    mutationFn: (viewUpdateVideoData) =>
      SubmitUpdatedVideoFunction(viewUpdateVideoData),
    onSuccess: (data) => {
      if (data.status === "failed") {
        notify(data.messaege, Error, DURATION);
      }
      if (data.status === "success") {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });
  //------------------------------------------------OnSubmitValidation-----------------------------------------
  const validationFormField = (formData) => {
    const errors = {};
    if (!formData.video_title) errors.video_title = "Video Title is required";
    if (!formData.main_category_id)
      errors.main_category_id = "Main Category is required";
    if (!formData.sub_category_id)
      errors.sub_category_id = "Sub Category is required";
    if (!formData.artist_ids) errors.artist_ids = "TagArtist is required";
    if (!formData.organiser_ids)
      errors.organiser_ids = "TagOrganiser is required";
    if (!formData.description) errors.description = "Description is required";
    if (!formData.thumbnail) errors.thumbnail = "Thumbnail is required";
    if (!formData.video_url) errors.video_url = "Video is required";
  };
  // ---------------------------------------------------handleUpdateRowData------------------------------------

  const handleUpdatRowData = async (e) => {
    const { status, artist_ids, organiser_ids, ...rest } = formData;

    console.log(e, "submitData");

    const updatedStatus = status === 1;

    const updatedValues = {
      ...rest,
      // video_url: videoUrl,
      role_id: role_id,
      id: videoId,
      status: updatedStatus,
    };

    if (!category.imageFileChanged) {
      delete updatedValues.thumbnail;
    }
    if (category.artistCategoryChanged) {
      updatedValues.artist_ids = artist_ids;
    }
    if (category.organiserCategoryChanged) {
      updatedValues.organiser_ids = organiser_ids;
    }

    try {
      await viewVideoDetailUpdate.mutateAsync(updatedValues);
      SetUpdate(update + 1);
      setVideoDetail((prevVideo) =>
        prevVideo.map((videodata) =>
          videodata.id === videoId
            ? { ...videodata, ...updatedValues }
            : videodata
        )
      );
    } catch (error) {
      console.error("Error updating row data:", error);
    } finally {
      setCategory((prev) => ({ ...prev, openThePopup: false }));
    }
  };
    //--------------------------------------------------------------------------------------------------------------
    return (
        <div id="data-grid-demo">
                 {/* {loading ? (
        <Skeleton count={10} height={60} />
      ) : ( */}
            <DataGrid
                dataSource={VideosSource?.data}
                showBorders={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                columnHidingEnabled={true}
                width="100%"
            >
                <Scrolling rowRenderingMode='virtual'></Scrolling>
                <Paging defaultPageSize={4} />
                <Pager
                    visible={true}
                    showPageSizeSelector={true}
                    showInfo={true}
                />
                <Column dataField="serialNumber" caption='Sl.No.'

                />
                <Column dataField="video_title" caption='Video Title' />
                <Column dataField="created_at" dataType="date" caption='Created-On' />
                <Column allowSorting={false}
                    caption='Video Image'
                    width={140}
                    cellRender={({ data }) => <ThumbnailCell id={data.id} />}
                />
                <Column dataField='main_category_id' caption="Main Category" hidingPriority={1}
                    cellRender={({ data }) => {
                        const mainCategoryId = data.main_category_id ? Number(data.main_category_id) : null;
                        console.log(mainCategoryId, "mainCategoryId");
                        const mainCategoryName = mainCategoryVideoSource?.data.find(category => category.main_cat_id === mainCategoryId)?.main_cat_name || '';
                        return <div>{mainCategoryName}</div>;
                    }}
                />
                <Column dataField="status"
                    cellRender={({ data }) => (
                        <Switch
                            defaultValue={data.show_to_public === 1}
                            onValueChanged={(e) => vlaueToggled(e, data)}
                        />
                    )}
                />
                <Column caption="Edit" cellRender={({ data }) => (
                    <div>
                      <MdModeEditOutline
                className="EditIcon align-item-baseline"
                onClick={() => onEditButtonClick(data)}
              />
                    </div>
                )} />
                <Column caption="Delete" cellRender={({ data }) => (
                    <div>
                      <RiDeleteBinLine
                className="DeleteIcon"
                onClick={() => onDeleteButtonClick(data)}
              />
                    </div>
                )} />
                
            </DataGrid>
                  {/* )} */}
            <Modal
                size="lg"
                show={category.openThePopup}
                centered
            >

                <Modal.Header onClick={ExitPopup} closeButton >
           <h5 className='text-center m-auto border-light'> Edit Records</h5>
        </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-6 form-group mb-3">
                            <TextBox
                                label="Video Title"
                                labelMode="floating"
                                value={formData.video_title}
                                onValueChanged={(e) => setFormData((prev) => ({ ...prev, video_title: e.value }))}
                            />
                        </div>
                        <div className="col-lg-6 form-group mb-3">
                            <SelectBox
                                label='Main Category'
                                labelMode='floating'
                                dataSource={mainCategoryVideoSource?.data}
                                displayExpr="main_cat_name"
                                valueExpr="main_cat_id"
                                value={formData.main_category_id}
                                onValueChanged={(e) => setFormData((prev) => ({ ...prev, main_category_id: e.value }))}
                            />
                        </div>

            <div className="mt-4 widget-container">
              <HtmlEditor
                height="auto"
                defaultValue={markup}
                label="Blog Description"
                value={formData.description}
                onValueChanged={(e) =>
                  setFormData((prev) => ({ ...prev, description: e.value }))
                }
              >
                <MediaResizing enabled={true} />
                <ImageUpload tabs={currentTab} fileUploadMode="base64" />
                <Toolbar multiline={isMultiline}>
                  <Item name="undo" />
                  <Item name="redo" />
                  <Item name="separator" />
                  <Item
                    name="size"
                    acceptedValues={sizeValues}
                    options={fontSizeOptions}
                  />
                  <Item
                    name="font"
                    acceptedValues={fontValues}
                    options={fontFamilyOptions}
                  />
                  <Item name="separator" />
                  <Item name="bold" />
                  <Item name="italic" />
                  <Item name="strike" />
                  <Item name="underline" />
                  <Item name="separator" />
                  <Item name="alignLeft" />
                  <Item name="alignCenter" />
                  <Item name="alignRight" />
                  <Item name="alignJustify" />
                  <Item name="separator" />
                  <Item name="orderedList" />
                  <Item name="bulletList" />
                  <Item name="separator" />
                  <Item
                    name="header"
                    acceptedValues={headerValues}
                    options={headerOptions}
                  />
                  <Item name="separator" />
                  <Item name="color" />
                  <Item name="background" />
                  <Item name="separator" />
                  <Item name="link" />
                  <Item name="image" />
                  <Item name="separator" />
                  <Item name="clear" />
                  <Item name="codeBlock" />
                  <Item name="blockquote" />
                  <Item name="separator" />
                  <Item name="insertTable" />
                  <Item name="deleteTable" />
                  <Item name="insertRowAbove" />
                  <Item name="insertRowBelow" />
                  <Item name="deleteRow" />
                  <Item name="insertColumnLeft" />
                  <Item name="insertColumnRight" />
                  <Item name="deleteColumn" />
                </Toolbar>
              </HtmlEditor>
            </div>
            <div className="col-lg-4 form-group mb-2">
              <label className="mb-2">
                <span className="text-danger">*</span>
              </label>

              <div className="">
                <img
                  src={thumbnailUrl}
                  alt="image"
                  class="img-thumbnail w-75 h-auto"
                />
                <MdModeEditOutline
                className="EditIcon align-item-baseline justify-content-end"
                onClick={handleImageEditButtonClick}
              />
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: "none" }}
                  ref={findInput}
                />
              </div>
            </div>

            <div className="col-lg-8 mt-4 h-100">
              <video
                id="video-preview"
                className="img-thumbnail w-100 h-100"
                controls
              >
                <source src={videoUrl || formData.video_url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <div className="d-flex justify-content-end">
                <section className="progress-area w-100 me-2">
                  {uploadProgress !== null && (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${uploadProgress}%`,
                          backgroundColor:
                            uploadProgress === 100 ? "green" : "#88ff88",
                        }}
                        aria-valuenow={uploadProgress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {uploadProgress}%
                      </div>
                    </div>
                  )}
                </section>
                <MdModeEditOutline
                className="EditIcon align-item-baseline"
                onClick={handleVideoEditButtonClick}
              />

              </div>

              <p>Video Name: {uploadedVideo?.name}</p>

              <input
                type="file"
                accept="video/*"
                onChange={handleVideoChange}
                style={{ display: "none" }}
                ref={videoInputRef}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button  className='CancelBtn' varient="secondary" onClick={() => ExitPopup()}>
            Cancel
          </Button>
          <Button   className='button' varient="secondary" onClick={(e) => handleUpdatRowData(e)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewVideos;
