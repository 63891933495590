import React, { useEffect, useState, useContext } from "react";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { makePostRequest } from "../../../Api/requestMethods";
import notify from "devextreme/ui/notify";
import {
  DURATION,
  ERROR,
  SUCCESS,
} from "../../../global/ToastMsgConstants.tsx";
import { useMutation } from "@tanstack/react-query";
import {
  DELETE_PROMO_CARD,
  GET_PROMOCODE_CATEGORIES,
  UPDATE_PROMO_CARD,
} from "../../../Api/api.js";
import { Button } from "devextreme-react/button";
import { Modal } from "react-bootstrap";
import { NumberBox, SelectBox, TextBox } from "devextreme-react";
import { DiscountType } from "../../../global/constants.js";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { jwtDecode } from "jwt-decode";
import Skeleton from "react-loading-skeleton";
import AuthContext from "../../../context/AuthContext.js";
import "react-loading-skeleton/dist/skeleton.css";
import * as Yup from "yup";

const ViewPromoCode = () => {
  const { user } = useContext(AuthContext);
  const [promoCodeData, setPromoCodeData] = useState([]);
  const [promoCodeId, setPromoCodeid] = useState();
  const [selectedOption, setSelectOption] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [category, setCategory] = useState({
    addCategory: "",
    updateCategory: "",
    openThePopup: false,
    selectallData: "",
  });
  const [popUpData, setPopUpData] = useState({
    promo_code: "",
    description: "",
    discount_type: "",
    discount_value: "",
  });
  const [loading, setLoading] = useState(true);

  const viewPromoCodeMutation = useMutation({
    mutationKey: "promoCode",
    mutationFn: (role_id) =>
      makePostRequest(GET_PROMOCODE_CATEGORIES(), { role_id }),
    onSuccess: (data) => {
      setPromoCodeData(data.data);
      setLoading(false);
      if (data.status === "success") {
        const sortedData = data.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        const proCodeWithSerialNumber = sortedData.map((promo, index) => ({
          ...promo,
          serialNumber: sortedData.length - index,
        }));
        setPromoCodeData(proCodeWithSerialNumber);
      } else if (data.status === "success") {
      }
    },
    onError: (error) => {
      setLoading(false);
      console.log(error);
    },
  });

  useEffect(() => {
    const role_id = user.role_id;
    viewPromoCodeMutation.mutate(role_id);
  }, []);

  // ---------------------------------------------------------------------------------------
  const onEditButtonClick = (data) => {
    setPromoCodeid(data.id);
    setCategory((prev) => ({ ...prev, openThePopup: true }));
    const { promo_code, description, discount_type, discount_value } = data;
    setPopUpData({
      promo_code: promo_code,
      description: description,
      discount_type: discount_type,
      discount_value: discount_value,
    });
  };

  // -----------------------------------------------------------------------------------------------
  const ExitPopup = () => {
    setCategory((prev) => ({ ...prev, openThePopup: false }));
    setValidationErrors({});
  };

  //------------------------HandleUpdate-------------------------------------------------------------------------
  const promocodeUpdatedFunction = async (data) => {
    try {
      const response = await makePostRequest(UPDATE_PROMO_CARD(), data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const promoCardDetailsUpdate = useMutation({
    mutationKey: ["updateprom"],
    mutationFn: (promeUpdatedData) =>
      promocodeUpdatedFunction(promeUpdatedData),
    onSuccess: (data) => {
      if (data.status === "failed") {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === "success") {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });
  const validateFields = () => {
    const errors = {};
    if (!popUpData.promo_code) errors.promo_code = "This Field is required.";
    if (!popUpData.description) errors.description = "This Field is required.";
    if (!popUpData.discount_type)
      errors.discount_type = "This Field is required.";
    if (!popUpData.discount_value) {
      errors.discount_value = "Discount value is required.";
    } else if (
      popUpData.discount_type === "% of ticket value" &&
      (popUpData.discount_value < 0 || popUpData.discount_value > 100)
    ) {
      errors.discount_value = "Discount value must be between 0 and 100.";
    }

    return errors;
  };
  const handleUpdatRowData = async () => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const updateValues = {
      ...popUpData,
      promocode_id: promoCodeId,
      role_id: user.role_id,
    };
    try {
      await promoCardDetailsUpdate.mutateAsync(updateValues);
      viewPromoCodeMutation.mutate(user.role_id);
    } catch (error) {
      console.log("error", error);
    } finally {
      console.log("finally is runninng");
    }
    setCategory((prev) => ({ ...prev, openThePopup: false }));
  };

  //-------------------------------------------------------------------------------------------------
  const handleOptionChange = (e) => {
    setSelectOption(e);

    setPopUpData((prev) => ({
      ...prev,
      discount_type: e,
      discount_value: "",
    }));
  };

  // --------------------------------------------Delete----------------------------
  const onDeleteButtonClick = async (data) => {
    const deletevalu = {
      promocode_id: data.id,
      role_id: data.role_id,
      status: "N",
    };
    try {
      await promoCardDetailsDelete.mutateAsync(deletevalu);
      viewPromoCodeMutation.mutate(user.role_id);
    } catch (error) {
      console.log("error", error);
    } finally {
      console.log("finally is runninng");
    }
  };

  const promocodeDeletedFunction = async (data) => {
    try {
      const response = await makePostRequest(DELETE_PROMO_CARD(), data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const promoCardDetailsDelete = useMutation({
    mutationKey: ["deletepromo"],
    mutationFn: (promeUpdatedData) =>
      promocodeDeletedFunction(promeUpdatedData),
    onSuccess: (data) => {
      if (data.status === "failed") {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === "success") {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });

  //------------------------------------------------------------------------------
  return (
    <div>
      {loading ? (
        <Skeleton count={10} height={40} />
      ) : (
        <DataGrid
          id=""
          dataSource={promoCodeData}
          showBorders={true}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          rowAlternationEnabled={true}
          onRowUpdated={(e) => handleUpdatRowData(e)}
        >
          <Column width={80} dataField="serialNumber" caption="Sl.no" />
          <Column dataField="promo_code" caption="Promocode" />
          <Column dataField="description" caption="description" />
          <Column dataField="discount_type" caption="Discount Type" />
          <Column dataField="discount_value" caption="Discount Value" />
          <Column dataField="eligible_amount" caption="Eligible Amount" />
          <Column
            caption="Edit"
            cellRender={({ data }) => (
              <div>
                <MdModeEditOutline
                  className="EditIcon"
                  onClick={() => onEditButtonClick(data)}
                />
              </div>
            )}
            width={60}
          />
          <Column
            caption="Delete"
            cellRender={({ data }) => (
              <div>
                <RiDeleteBinLine
                  className="DeleteIcon"
                  onClick={() => onDeleteButtonClick(data)}
                />
              </div>
            )}
          />
        </DataGrid>
      )}
      <Modal size="lg" show={category.openThePopup} centered>
        <Modal.Header
          className="m-0 d-flex align-items-center text-center border-light"
          style={{ padding: "10px 10px" }}
        >
          <h5 className="text-nowrap text-center m-auto">
            {" "}
            Edit view promo code
          </h5>
        </Modal.Header>

        <Modal.Body>
          <div className="row mt-0 pb-0">
            <div className="col-lg-6">
              <TextBox
                label="Code"
                labelMode="floating"
                onValueChanged={(e) =>
                  setPopUpData((prev) => ({ ...prev, promo_code: e.value }))
                }
                value={popUpData.promo_code}
              />
              {validationErrors.promo_code && (
                <div className="text-danger">{validationErrors.promo_code}</div>
              )}
            </div>

            <div className="col-lg-6">
              <TextBox
                label="Description"
                labelMode="floating"
                onValueChanged={(e) =>
                  setPopUpData((prev) => ({ ...prev, description: e.value }))
                }
                value={popUpData.description}
              />
              {validationErrors.description && (
                <div className="text-danger">
                  {validationErrors.description}
                </div>
              )}
            </div>

            <div className="col-lg-6">
              <SelectBox
                dataSource={DiscountType}
                displayExpr="type"
                valueExpr="value"
                labelMode="floating"
                label="Discount Type"
                value={popUpData.discount_type}
                onValueChanged={(e) => {
                  handleOptionChange(e.value);
                }}
              />
              {validationErrors.discount_type && (
                <div className="text-danger">
                  {validationErrors.discount_type}
                </div>
              )}
            </div>
            <div className="col-lg-6">
              <NumberBox
                label={
                  popUpData.discount_type === "% of ticket value"
                    ? "% Discount Value"
                    : "Discount Value"
                }
                labelMode="floating"
                min={
                  popUpData.discount_type === "% of ticket value"
                    ? 0
                    : undefined
                }
                max={
                  popUpData.discount_type === "% of ticket value"
                    ? 100
                    : undefined
                }
                onValueChanged={(e) => {
                  const newValue =
                    popUpData.discount_type === "% of ticket value" &&
                    e.value > 100
                      ? 100
                      : e.value;
                  setPopUpData((prev) => ({
                    ...prev,
                    discount_value: newValue,
                  }));
                }}
                value={popUpData.discount_value}
              />
              {validationErrors.discount_value && (
                <div className="text-danger">
                  {validationErrors.discount_value}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="CancelBtn"
            variant="secondary"
            onClick={() => ExitPopup()}
          >
            Cancel
          </button>
          <button
            className="button"
            variant="secondary"
            onClick={handleUpdatRowData}
          >
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewPromoCode;
