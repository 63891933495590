import React from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { dataSource } from '../../../global/constants';


const ViewFormInBox = () => {
  return (
      <DataGrid
          dataSource={dataSource}
          showBorders={true}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          rowAlternationEnabled={true}
      >
          <Column width={80} dataField="ID" caption='S.no' />
          <Column
              dataField="StartDate"
              dataType="Purchase Date"
          />
          <Column
              dataField="Status"
              caption='Transaction ID'
          />
          <Column dataField="Priority" />
          <Column dataField="Subject" />
          <Column dataField="Status" />
      </DataGrid>
  )
}

export default ViewFormInBox