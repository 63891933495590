import React, { useEffect, useContext, useState } from "react";
import cartImg from "../assets/images/genre/01.webp";
import "./AddtoCart.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import AuthContext from "../context/AuthContext";
import { SUCCESS, ERROR, DURATION } from "../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { makePostRequest } from "../Api/requestMethods";
import { getCartList } from "../Api/api";
import { ALLEVENT_DETAILS_IMAGES } from "../global/constants.js";
import { addToCart } from "../Api/api";
import { GlobalContext } from "../global/provider.js";
import { deleteCartItem, GET_EVENT_DETAILS } from "../Api/api";
import {
  calculateRowTotal,
  calculateOverAllTotal,
} from "../utils/commonUtils.js";
import EmptyCart from "../Layout/Static/emptyCart.png";
import { useNavigate } from "react-router-dom";
import { seatingPlanNumbered } from "../global/constants.js";

function AddtoCart() {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { cartDetails, setCartDetails } = useContext(GlobalContext);
  const sessionId = localStorage.getItem("mdnd_cId");
  const [dataSource, setDataSource] = useState({
    cartListDataSource: "",
  });
  const fetchCartList = async () => {
    try {
      if (user || sessionId) {
        const body = {
          session_id: user?.role_id ? "" : sessionId,
          role_id: user?.role_id || "",
          country_id: 101,
        };
        const response = await makePostRequest(getCartList, body);
        if (response && response.status === SUCCESS) {
          setDataSource((prev) => ({
            ...prev,
            cartListDataSource: response?.data,
          }));
        } else {
          notify(response.response.data.message, ERROR, DURATION);
        }
      }
    } catch (error) {
      notify(error.response.data.message, ERROR, DURATION);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCartList();
  }, [sessionId, user?.role_id]);

  const proceedToCheckOut = async () => {
    setCartDetails((prev) => ({ ...prev, fromCheckOutCart: true }));
    navigate("/checkOut");
  };

  const deleteSingleDenomination = async (data, slugId) => {
    const updatedSlug = dataSource.cartListDataSource
      .map((item) => {
        let updated = false;
        const updatedSlugs = item.slug.map((slugData) => {
          if (!updated && slugData.id === slugId.id) {
            updated = true;
            return { ...slugData, ticket_quantity: "0" };
          }
          return slugData;
        });
        return updatedSlugs;
      })
      .find((item) => item);

    const ticketData = updatedSlug?.map((data) => {
      return {
        id: data.id,
        qty: data.ticket_quantity,
      };
    });



    const body = {
      role_id: Number(user?.role_id) || "",
      login_id: Number(user?.login_id) || "",
      session_id: !user?.role_id && !user?.login_id ? sessionId : null,
      country_id: Number(data.country_id),
      item_id: Number(data.item_id),
      cart_purpose: "ticket",
      total_quantity: ticketData,
      season_flag: data.season_flag,
    };

    try {
      if (user?.role_id || sessionId) {
        const response = await makePostRequest(addToCart, [body]);
        if (response && response?.status === SUCCESS) {
          fetchCartList();
          const cartCount = response?.data && response?.data?.cart_count;
          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const entierRowData = async (entireRowData) => {
    const body = {
      role_id: Number(user?.role_id) || "",
      session_id: !user?.role_id && !user?.login_id ? sessionId : null,
      cart_id: entireRowData.cart_id,

    };
    try {
      if (user?.role_id || sessionId) {
        const response = await makePostRequest(deleteCartItem, body);
        if (response && response?.status === SUCCESS) {
          fetchCartList();
          const cartCount = response?.data && response?.data?.cart_count;
          setCartDetails((prev) => ({
            ...prev,
            cartValue: cartCount,
          }));
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="section-padding pt-lg-3">
      {cartDetails.cartValue > 0 ? (
        <div className="container">
          <h4>My Cart</h4>
          <div className="row">
            <div className="col-lg-8">
              <div className="">
                <div className="table-responsive cartTable">
                  <table className="table cart-table">
                    <thead className="border-bottom">
                      <tr>
                        <th scope="col" className="font-size-18 fw-500">
                          Event details
                        </th>
                        <th
                          scope="col"
                          align="center"
                          className="font-size-18 fw-500 text-center"
                        >
                          ₹
                        </th>
                        <th scope="col" className="font-size-18 fw-500">
                          Sub-total
                        </th>
                        <th scope="col" className="font-size-18 fw-500">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataSource &&
                        dataSource?.cartListDataSource &&
                        dataSource?.cartListDataSource?.length > 0 &&
                        dataSource?.cartListDataSource?.map(
                          (data, dataIndex) => (
                            <tr data-item="list" key={dataIndex}>
                              <td>
                                <div className="product-thumbnail cartMobImg d-block d-lg-flex align-items-center gap-3 ms-3">
                                  <p className="d-block mb-2" href="">
                                    <img
                                      className="avatar-80 rounded"
                                      src={`${ALLEVENT_DETAILS_IMAGES}${data?.event_image}`}
                                      alt=""
                                    />
                                  </p>
                                  <span className="">{data?.event_title}</span>
                                </div>
                              </td>
                              <td>
                                <div className="table-responsive cartTable">
                                  <table className="table">
                                    {data &&
                                      data?.slug &&
                                      data?.slug?.map((item, itemIndex) => {
                                        const nonZeroTicketCount =
                                          data?.slug?.filter(
                                            (slugItem) =>
                                              slugItem?.ticket_quantity !==
                                              "0" &&
                                              slugItem?.ticket_quantity !== 0
                                          )?.length;

                                        return (
                                          item?.ticket_quantity !== "0" &&
                                          item?.ticket_quantity !== 0 && (
                                            <tr key={itemIndex}>
                                              <td>
                                                {data.seating_option ===
                                                  seatingPlanNumbered
                                                  ? `${item?.row_name}-${item.seat}`
                                                  : item?.row_name}
                                              </td>
                                              <td align="end">
                                                ₹{item?.amount}
                                              </td>
                                              <td className="w-25 text-center">
                                                {item.ticket_quantity}
                                              </td>
                                              <td>
                                                {nonZeroTicketCount > 1 && (
                                                  <IoCloseCircleOutline
                                                    className=""
                                                    onClick={() =>
                                                      deleteSingleDenomination(
                                                        data,
                                                        item
                                                      )
                                                    }
                                                  />
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        );
                                      })}
                                  </table>
                                </div>
                              </td>
                              <td align="end">
                                <span className="fw-500">
                                  ₹{calculateRowTotal(data?.slug)}
                                </span>
                              </td>
                              <td>
                                <button
                                  className="btn btn-icon btn-danger delete-btn text-end bg-transparent text-body border-0"
                                  onClick={() => entierRowData(data)}
                                >
                                  <span className="btn-inner">
                                    <i className="far fa-trash-alt text-warning"></i>
                                  </span>
                                </button>
                              </td>
                            </tr>
                          )
                        )}
                      <tr data-item="list">
                        <td align="end" colSpan={2}>
                          Total
                        </td>
                        <td align="end">
                          <b>
                            ₹
                            {calculateOverAllTotal(
                              dataSource,
                              undefined,
                              undefined
                            )}
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                className="coupon-main d-flex justify-content-between  gap-5 flex-wrap align-items-center pt-4 pb-5 border-bottom"
                onClick={proceedToCheckOut}
              >
                <div className=""></div>
                <div className="button-primary">
                  <div className="iq-button">
                    <p className="btn text-uppercase position-relative">
                      <span className="button-text">Proceed to checkout</span>
                      <i className="fa-solid fa-play"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row justify-content-center">
          <img className="emptyCart" src={EmptyCart}></img>
        </div>
      )}
    </div>
  );
}

export default AddtoCart;
