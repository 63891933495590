import React, { useState } from 'react';
import { Modal } from 'react-bootstrap'; // Import Modal from react-bootstrap
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react';
import HtmlEditor, { Toolbar, MediaResizing, ImageUpload } from "devextreme-react/html-editor";
import { Item } from 'devextreme-react/form';
import { useFormik } from 'formik'; // Import Formik
import { sizeValues, fontValues, headerValues, fontSizeOptions, fontFamilyOptions, headerOptions, markup, tabs } from '../../../../components/others';
import {jwtDecode} from 'jwt-decode';
import { useMutation } from '@tanstack/react-query';
import { DURATION, ERROR, SUCCESS } from '../../../../global/ToastMsgConstants.tsx';
import { makePostRequest } from '../../../../Api/requestMethods';
import { INSERT_TEMPLATE_DETALI } from '../../../../Api/api.js';
import * as Yup from 'yup';
import notify from 'devextreme/ui/notify';

const initialValues = {
  name: "",
  content: ""
};
// Validation schema using Yup
const validationSchema = Yup.object({
  name: Yup.string()
    .required('Template name is required')
    .min(3, 'Template name should be at least 3 characters long'),
  content: Yup.string()
    .required('Content is required')
    .min(10, 'Content should be at least 10 characters long')
});
// Utility function to encode to Base64
const encodeBase64 = (input) => {
  return btoa(unescape(encodeURIComponent(input)));
};
const CreateNewTempletes = ({ show, handleClose }) => {
  const [currentTab, setCurrentTab] = useState(tabs[2].value);
  const [isMultiline, setIsMultiline] = useState(true);

  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const updatedValues = {
        ...values,
        role_id: user.role_id,
        login_id: user.login_id,
        status: true,
        content: encodeBase64(values.content) // Encode content to Base64
      };
      try {
        await TemplateUploadMutationDetail.mutateAsync(updatedValues);
      } catch (error) {
        console.log('Error', "error");
      } finally {
        setSubmitting(false);
        handleClose();
        resetForm();
      }
    }
  });
  //  -------------------------------------------------------------------------------------------
  const InsertTemplateDetail = async (data) => {
    try {
      const endpoint = await makePostRequest(INSERT_TEMPLATE_DETALI(), data);
      return endpoint;
    } catch (error) {
      throw error;
    }
  };
  const TemplateUploadMutationDetail = useMutation({
    mutationKey: ["TemplateDetail"],
    mutationFn: (templateData) => InsertTemplateDetail(templateData),
    onSuccess: (data) => {
      console.log(data,"thisismessagedata")
      if (data.status !== "created") {
        notify(data.message, ERROR, DURATION);
        // setSuccessMessage(data.message);
      }
      if (data.status === "created") {
        notify(data.message,'SUCCESS');
      }
      resetForm();
      console.log(data, 'data');
    },
    onError: (error) => {
      console.log(error, "errorpost");
      notify(error.message, ERROR, DURATION);
    }
  });
  //  -------------------------------------------------------------------------------------------
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
           <h5 className='text-center m-auto border-light'>Create New Template</h5>
          </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-2">
            <TextBox
              type="text"
              name="templateName"
              className="dx-field-item-required"
              required={true}
              labelMode="floating"
              label="Template Name"
              value={values.name}
              onValueChanged={(e) => handleChange({
                target: { name: "name", value: e.value },
              })}
              onFocusOut={(e) => handleBlur({ target: { name: "name", value: e.value } })}
            />
             {touched.name && errors.name ? (
              <div className="text-danger">{errors.name}</div>
            ) : null}
          </div>
          <div className="mt-4 widget-container">
            <HtmlEditor 
              height="auto" 
              defaultValue={markup} 
              label="Template Content"
              onValueChanged={(e) => handleChange({
                target: { name: "content", value: e.value }
              })}
              onFocusOut={(e) => handleBlur({ target: { name: "content", value: e.value } })}
            >
              <MediaResizing enabled={true} />
              <ImageUpload tabs={currentTab} fileUploadMode="base64" />
              <Toolbar multiline={isMultiline}>
                <Item name="undo" />
                <Item name="redo" />
                <Item name="separator" />
                <Item name="size" acceptedValues={sizeValues} options={fontSizeOptions} />
                <Item name="font" acceptedValues={fontValues} options={fontFamilyOptions} />
                <Item name="separator" />
                <Item name="bold" />
                <Item name="italic" />
                <Item name="strike" />
                <Item name="underline" />
                <Item name="separator" />
                <Item name="alignLeft" />
                <Item name="alignCenter" />
                <Item name="alignRight" />
                <Item name="alignJustify" />
                <Item name="separator" />
                <Item name="orderedList" />
                <Item name="bulletList" />
                <Item name="separator" />
                <Item name="header" acceptedValues={headerValues} options={headerOptions} />
                <Item name="separator" />
                <Item name="color" />
                <Item name="background" />
                <Item name="separator" />
                <Item name="link" />
                <Item name="image" />
                <Item name="separator" />
                <Item name="clear" />
                <Item name="codeBlock" />
                <Item name="blockquote" />
                <Item name="separator" />
                <Item name="insertTable" />
                <Item name="deleteTable" />
                <Item name="insertRowAbove" />
                <Item name="insertRowBelow" />
                <Item name="deleteRow" />
                <Item name="insertColumnLeft" />
                <Item name="insertColumnRight" />
                <Item name="deleteColumn" />
              </Toolbar>
            </HtmlEditor>
            {touched.content && errors.content && <div className="text-danger">{errors.content}</div>}
          </div>
          <Modal.Footer>
            <button className='button' type="button" variant="secondary" onClick={handleClose}>
              Close
            </button>
            <button className='CancelBtn' type="submit" variant="primary" onClick={handleSubmit}>
              Save
            </button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
};
export default CreateNewTempletes;
