import React from "react";
import { useState, useEffect, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASEPATH } from "../../config/config";
import axios from "axios";
import { GET_COUNTRIES, GET_CITIES_BY_COUNTRIES, GET_EVENTS } from "../../Api/api";
import { useMutation } from "@tanstack/react-query";
import { FaFilter } from "react-icons/fa";
import { SelectBox } from "devextreme-react";
import DateBox from 'devextreme-react/date-box';
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import NodataforEvent from "../../global/NodataforEvent";
import EventNoimage from "../../global/EventNoimage";
import { ALLEVENT_DETAILS_IMAGES } from "../../global/constants";
import { customEncrypt } from "../../utils/encryptAndDecrypt";
import getEventKeys from "../../Api/getEventKeys";
import { isValidImage } from "../../global/ImageCheck";
import { imageExists } from "../../global/ImageCheck";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoChevronBack } from "react-icons/io5";
import AuthContext from "../../context/AuthContext";

export default function EventsFilterPage() {

    const COUNTRY_KEY = getEventKeys().countryKey;
    const EVENT_KEY = getEventKeys().eventKey;

    const token = localStorage.getItem("MDnD_auth");

    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location?.search);




    const countryid = queryParams?.get('ci');
    const city = queryParams?.get('c');
    const mc = queryParams?.get('mc');
    const mcName = location?.pathname?.split('/').pop();



    const [loading, setLoading] = useState(true);

    const [dataSource, setdataSource] = useState({
        selectedCountry: "",
        selectedCity: "",
        selectedMainCategory: "",
        selectedEventMode: "",
        selectedDate: "",
        maincategory_all: "",
        mainCatName: "",
        country: "",
        city: "",
        from_date: "",
        to_date: "",
        formattedFrom_date: "",
        formattedTo_Date: "",
        tempEventData: "",
        max_date: "",
        min_date: "",
        oldmaincatName: "",
        oldmaincatid: "",
        validation_fromdate: "",
        validation_todate: "",

    });



    useEffect(() => {

        if (countryid && city && mc && mcName) {
            const params = new URLSearchParams(location?.search);

            setdataSource((prev) => ({
                ...prev,
                selectedCountry: Number(countryid),
                selectedCity: Number(city),
                selectedMainCategory: Number(mc),
                selectedDate: params?.get("Date") && params?.get("Date") !== "All" ? "CustomDates" : "All",
                selectedEventMode: params?.get("eMode") || "Offline",
                mainCatName: mcName,
                formattedFrom_date: params?.get("Date") && params?.get("Date") !== "All" ? splitstring_fromdate(params?.get("Date")) : '',
                from_date: params?.get("Date") && params?.get("Date") !== "All" ? convertToISOdate(splitstring_fromdate(params?.get("Date"))) : '',
                formattedTo_Date: params?.get("Date") && params?.get("Date") !== "All" ? splitstring_todate(params?.get("Date")) : '',
                to_date: params?.get("Date") && params?.get("Date") !== "All" ? convertToISOdate(splitstring_todate(params?.get("Date"))) : '',

            }));

        }

    }, []);

    const splitstring_fromdate = (string) => {
        let newstring = '';
        for (let i = 0; i < string.length; i++) {

            if (string[i] === 't' && string[i + 1] === 'o') {
                break;

            }
            else {
                newstring += string[i];
            }
        }

        return newstring;
    }

    const convertToISOdate = (fromDate) => {

        const date = new Date(fromDate + 'T00:00:00+0530');


        if (isNaN(date)) {
            console.error("Invalid date format");
            return null;
        }

        return date;
    };



    const splitstring_todate = (string) => {
        let newstring = '';
        for (let i = 0; i < string.length; i++) {

            if (string[i] === 't' && string[i + 1] === 'o') {
                for (let k = i + 2; k < string.length; k++) {
                    newstring += string[k];

                }

                break;

            }
            else {
                continue;
            }
        }

        return newstring;
    }

    useMemo(() => {
        if (mcName) {
            setdataSource((prev) => ({
                ...prev,
                oldmaincatName: mcName

            }));

        }
        if (mc) {
            setdataSource((prev) => ({
                ...prev,
                oldmaincatid: Number(mc)

            }));
        }

    }, [])


    // dynamic URL...
    useEffect(() => {

        if (
            dataSource.selectedDate &&
            // dataSource.formattedFrom_date &&
            // dataSource.formattedTo_Date &&
            dataSource.selectedEventMode &&
            dataSource.mainCatName) {

            const queryParams = new URLSearchParams({
                ci: dataSource.selectedCountry
                    ? Number(dataSource.selectedCountry)
                    : "",

                c: dataSource.selectedCity ? Number(dataSource.selectedCity) : "",
                eMode: dataSource.selectedEventMode ? dataSource.selectedEventMode : "",

                Date: dataSource.selectedDate === "All" ? "All"
                    : dataSource.selectedDate === "CustomDates"
                        ? `${dataSource.formattedFrom_date}to${dataSource.formattedTo_Date}`

                        : "",
                mc: dataSource.selectedMainCategory
                    ? Number(dataSource.selectedMainCategory)
                    : "",

                mcName: dataSource.mainCatName ? dataSource.mainCatName : "",
            });

            navigate(`/ViewEvents/${dataSource.mainCatName}?${queryParams}`);

        }
        // -----------------------------------------------------------------------------------------
        const metaTitle = `Top Upcoming ${dataSource.mainCatName} events|Free & Ticketed`;
        document.title = metaTitle;

        const metaDescription = `Explore the top upcoming ${dataSource.mainCatName} events happening near you and buy tickets for them`;
        const descriptionTag = document.querySelector('meta[name="description"]');
        if (descriptionTag) {
            descriptionTag.setAttribute("content", metaDescription);
        } else {
            const meta = document.createElement("meta");
            meta.name = "description";
            meta.content = metaDescription;
            document.head.appendChild(meta);
        }
        // -----------------------------------------------------------------------------------------

    }, [

        //dataSource.selectedDate,
        dataSource.selectedEventMode,
        dataSource.mainCatName,
        dataSource.formattedFrom_date,
        dataSource.formattedTo_Date

    ])



    //country fetch..
    useEffect(() => {
        mutationCountry.mutate();
    }, []);

    const GetCountries = () => GET_COUNTRY();

    const GET_COUNTRY = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${BASEPATH}events/get_available_event_countries`,
                headers: {
                    MDnD_auth: `${token}`,
                },

                timeout: 60000,
                responseType: "json",
            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationCountry = useMutation({
        mutationKey: ["get_Country_events"],
        mutationFn: () => GetCountries(),

        onSuccess: (data) => {
            if (data.status === "success") {
                setdataSource((prevState) => ({ ...prevState, country: data.data }));
            } else {
                setdataSource((prevState) => ({ ...prevState, country: "" }));
            }
        },
        onError: (error) => {
            setdataSource((prevState) => ({ ...prevState, country: "" }));
        },
    });

    const handleCountry = (e) => {

        if (e.value) {
            setdataSource((prevState) => ({
                ...prevState,
                selectedCountry: e.value,
                city: '',
                selectedCity: "",
                maincategory_all: '',
                selectedMainCategory: "",
                mainCatName: '',
                selectedDate: "All",
                from_date: "",
                to_date: "",
                formattedFrom_date: "",
                formattedTo_Date: "",
                max_date: "",
                min_date: "",

            }));

        }


    };




    // cities - all fetch...
    useEffect(() => {
        const GETCITIESDATA = async () => {
            try {
                const response = await axios.post(
                    `${BASEPATH}${GET_CITIES_BY_COUNTRIES}`,
                    {
                        country_id: dataSource.selectedCountry,
                    },
                    {
                        headers: {
                            MDnD_auth: `${token}`,
                        },

                        timeout: 60000,
                        responseType: "json",
                    }
                );

                if (response.data.status == "success") {
                    setdataSource((prevState) => ({
                        ...prevState,
                        city: response.data.data,
                    }));
                } else {
                    setdataSource((prevState) => ({ ...prevState, city: "" }));
                }
            } catch (error) {
                console.error("error fetching States for this country", error);
            }
        };
        if (dataSource.selectedCountry) {
            GETCITIESDATA();
        }
    }, [dataSource.selectedCountry]);


    const handleCity = (value) => {
        if (value) {

            setdataSource((prevState) => ({
                ...prevState,
                selectedCity: Number(value),
                mainCatName: '',
                maincategory_all: '',
                selectedMainCategory: "",
                selectedDate: "All",
                from_date: "",
                to_date: "",
                formattedFrom_date: "",
                formattedTo_Date: "",
                max_date: "",
                min_date: "",

            }));

        }
    };





    // maincategories- all fetch....
    useEffect(() => {
        if (dataSource.selectedCountry && dataSource.selectedCity) {

            mutationMainCategory.mutate();
        } else {

            return;
        }
    }, [dataSource.selectedCountry, dataSource.selectedCity]);

    const GetMainCategory = () => GET_MainCategory(GET_EVENTS);

    const GET_MainCategory = async (GET_EVENTS) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_EVENTS}`,
                data: {
                    country_id: dataSource.selectedCountry
                        ? Number(dataSource.selectedCountry)
                        : "",
                    city_id: dataSource.selectedCity
                        ? Number(dataSource.selectedCity)
                        : "",
                },
                timeout: 60000,
                responseType: "json",
            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationMainCategory = useMutation({
        mutationKey: ["get_maincategory_events"],
        mutationFn: () => GetMainCategory(),

        onSuccess: (data) => {
            if (data.status === "success") {
                setdataSource((prevState) => ({
                    ...prevState,
                    maincategory_all: data.data,
                }));
            } else {
                setdataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
            }
        },
        onError: (error) => {
            setdataSource((prevState) => ({ ...prevState, maincategory_all: "" }));
        },
    });


    const handleMainCategory = (e) => {

        if (e) {

            // setdataSource((prevState) => ({
            //     ...prevState,
            //     selectedMainCategory: e.value,

            // }))

            setdataSource((prevState) => {
                if (

                    prevState.selectedMainCategory !== e?.value
                ) {
                    return {
                        ...prevState,
                        selectedMainCategory: e.value,
                        selectedEventMode: "Offline",
                        selectedDate: "All",
                        from_date: "",
                        to_date: "",
                        formattedFrom_date: "",
                        formattedTo_Date: "",
                        max_date: "",
                        min_date: "",
                    };
                } else if (prevState.selectedMainCategory === e?.value) {
                    return {
                        ...prevState,
                        selectedMainCategory: e.value,
                        selectedDate: prevState.selectedDate,
                        selectedEventMode: "Offline",

                    };
                }
            });





        }


    };


    //for setting selectedMainCategory..
    useEffect(() => {
        if (dataSource.selectedMainCategory === ''
            && dataSource.mainCatName === ''
            && dataSource.maincategory_all) {
            setdataSource((prevState) => ({
                ...prevState,
                selectedMainCategory: dataSource.maincategory_all[0].main_cat_id
            }));
        }

    }, [dataSource.maincategory_all])

    //for setting MainCatName..
    useEffect(() => {
        if (dataSource.selectedMainCategory && dataSource.maincategory_all) {

            let filteredArray =
                dataSource.maincategory_all &&
                dataSource.maincategory_all.filter(
                    (item) => item.main_cat_id === Number(dataSource.selectedMainCategory)
                );


            if (filteredArray) {
                setdataSource((prevState) => ({
                    ...prevState,
                    mainCatName: filteredArray[0]?.main_cat_name,
                }));

            }
        }

    }, [dataSource.selectedMainCategory, dataSource.maincategory_all])




    //eventMode- offline vs online..
    const handleEvent_offline = () => {
        setdataSource((prev) => ({
            ...prev,
            selectedEventMode: "Offline",
            selectedDate: "All",
            from_date: "",
            to_date: "",
            formattedFrom_date: "",
            formattedTo_Date: "",
            max_date: "",
            min_date: "",
        }));
    };

    const handleEvent_online = () => {
        setdataSource((prev) => ({
            ...prev,
            selectedEventMode: "Online",
            selectedDate: "All",
            from_date: "",
            to_date: "",
            formattedFrom_date: "",
            formattedTo_Date: "",
            max_date: "",
            min_date: "",

        }));

    };


    //start Date and end Date..
    const dateLabel = { 'aria-label': 'Date' };
    const now = new Date();


    const handleDate_all = () => {
        setdataSource((prev) => ({
            ...prev,

            selectedDate: "All",
            from_date: "",
            to_date: "",
            formattedFrom_date: "",
            formattedTo_Date: "",
            max_date: "",
            min_date: "",

        }));
    }

    const handleDate_CustomDates = () => {

        setdataSource((prev) => ({
            ...prev,
            selectedDate: "CustomDates",

        }));
    }

    const handleFromDate = (value) => {
        setdataSource((prev) => ({
            ...prev,
            from_date: value,
            validation_fromdate: '',
            to_date: '',
            formattedFrom_date: '',
            formattedTo_Date: ''


        }));

    }

    useEffect(() => {
        if (dataSource.to_date === '') {
            setdataSource((prev) => ({
                ...prev,
                validation_todate: "This field is required",

            }));

        }
        else {
            setdataSource((prev) => ({
                ...prev,
                validation_todate: '',

            }));
        }

    }, [dataSource.to_date])



    const handletoDate = (value) => {
        setdataSource((prev) => ({
            ...prev,
            to_date: value,
            validation_todate: ''

        }));

    }

    useEffect(() => {
        if (dataSource.from_date === '') {
            setdataSource((prev) => ({
                ...prev,
                validation_fromdate: "This field is required",

            }));
        }
        else {
            setdataSource((prev) => ({
                ...prev,
                validation_fromdate: '',

            }));
        }

    }, [dataSource.from_date])

    const formatthisDate = (date) => {
        return new Promise((resolve, reject) => {
            if (date) {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                resolve(`${year}-${month}-${day}`);
            } else {
                reject('Invalid date');
            }
        });
    };

    useEffect(() => {

        const mydates = async () => {
            if (dataSource.from_date && dataSource.to_date) {


                const formattedDate1 = await formatthisDate(dataSource.from_date);
                const fomattedDate2 = await formatthisDate(dataSource.to_date);

                if (formattedDate1 && fomattedDate2) {
                    setdataSource((prev) => ({
                        ...prev,
                        formattedFrom_date: formattedDate1,
                        formattedTo_Date: fomattedDate2

                    }));
                }

            }

        }


        mydates();

    }, [dataSource.from_date, dataSource.to_date])


    //fetching events from backend..
    useEffect(() => {
        const loadMore = async () => {
            let from_date = "";
            let to_date = "";


            if (dataSource.selectedDate === "All") {
                const today = new Date();
                const formattedDate = today && today.toISOString().split("T")[0];
                from_date = formattedDate;

                const nextYear = today && new Date(today);
                nextYear.setFullYear(today.getFullYear() + 1);
                to_date = nextYear && nextYear.toISOString().split("T")[0];


            }
            else if (dataSource.selectedDate === "CustomDates") {

                from_date = dataSource.formattedFrom_date;
                to_date = dataSource.formattedTo_Date;

            }

            else {
                return;

            }

            if (from_date && to_date) {
                setLoading(true);
                mutationgetFetchParticularEvents.mutate({ from_date, to_date });
            } else {
                //setLoading(true);
                return;
            }


        };

        if (
            dataSource.selectedCity &&
            dataSource.selectedMainCategory &&
            dataSource.selectedEventMode
        ) {

            loadMore();
        } else {
            return;
        }
    }, [
        dataSource.selectedCity,
        dataSource.selectedMainCategory,
        dataSource.selectedEventMode,

        dataSource.selectedDate,
        dataSource.formattedFrom_date,
        dataSource.formattedTo_Date

    ]);

    const GetParticularEvents = ({ from_date, to_date }) =>
        FETCH_EVENTS_FROM_BACKEND(from_date, to_date);

    const FETCH_EVENTS_FROM_BACKEND = async (from_date, to_date) => {
        try {

            const response = await axios({
                method: "POST",
                url: `${BASEPATH}events/get_events_by_filterdata`,

                data: {
                    main_cat_id: dataSource.selectedMainCategory
                        ? dataSource.selectedMainCategory
                        : "",

                    country_id: dataSource.selectedCountry
                        ? dataSource.selectedCountry
                        : "",
                    city_id: dataSource.selectedCity ? dataSource.selectedCity : "",
                    event_mode: dataSource.selectedEventMode
                        ? dataSource.selectedEventMode
                        : "",
                    from_date: from_date,
                    to_date: to_date,
                },

                timeout: 60000,
                responseType: "json",
            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationgetFetchParticularEvents = useMutation({
        mutationKey: ["get_particular_events"],
        mutationFn: GetParticularEvents,

        onSuccess: (data) => {


            if (data.status === "success") {


                setdataSource((prevState) => ({
                    ...prevState,
                    tempEventData: data.data,

                }));
                if (dataSource.selectedDate === "All" && data?.data?.length > 0) {
                    setdataSource((prevState) => ({
                        ...prevState,

                        min_date: data?.data?.[0].min_event_date,
                        max_date: data?.data?.[0].max_event_date,
                    }));
                }
                setLoading(false);
            }
        },
        onError: (error) => {
            setdataSource((prevState) => ({ ...prevState, tempEventData: "" }));
            setLoading(false);
        },
    });

    const handleResetFilter = () => {



        setdataSource((prev) => ({
            ...prev,
            selectedCountry: 101,
            selectedCity: 3659,
            selectedMainCategory: dataSource.selectedMainCategory,
            selectedDate: "All",
            selectedEventMode: "Offline",
            mainCatName: dataSource.mainCatName

        }));

        // const queryParams = new URLSearchParams({
        //     ci: dataSource.selectedCountry
        //         ? Number(dataSource.selectedCountry)
        //         : 101,

        //     c: dataSource.selectedCity ? Number(dataSource.selectedCity) : 3659,
        //     eMode: "Offline",

        //     Date: "All",
        //     mc: dataSource.oldmaincatid,
        //     mcName: dataSource.oldmaincatName,
        // });

        //navigate(`/ViewEventsTest/${dataSource.oldmaincatName}?${queryParams}`);
    }



    const [filter, setFilter] = useState(false);
    const doFilter = () => {
        setFilter(!filter);
    };

    const encodeBase64_new = (str, key) => {
        const prefix = key?.toString();
        const combinedStr = prefix + str.toString();
        return btoa(combinedStr);
    };

    const selectedDetailsEvent = (event_id, title) => {
        const encyptedData = customEncrypt("no");
        const modifiedTitle = title ? title.split(" ").join("_") : "Event";
        navigate(
            `/event/${modifiedTitle}?ci=${encodeBase64_new(Number(dataSource.selectedCountry), COUNTRY_KEY)}&e=${encodeBase64_new(event_id, EVENT_KEY)}&editEvent=${encyptedData}`
        );
    };

    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };


    //BREADCRUMBS...........


    const [breadCrumbCollection, setbreadCrumbCollection] = useState({
        breadCrumbCountry: "",
        breadCrumbCity: "",
        breadCrumbEventMode: "",
        breadCrumbDate: "",
        breadCrumbPricing: "",
        breadCrumbCategory: "",
        breadCrumbCurrent: "",
    });

    useEffect(() => {
        if (
            dataSource?.selectedCountry &&
            dataSource?.selectedCountry != "" &&
            dataSource.country
        ) {
            const filteredData =
                dataSource.country &&
                dataSource.country.filter(
                    (item) => item.country_id === Number(dataSource.selectedCountry)
                );

            if (filteredData && filteredData.length > 0) {
                //setbreadcrumbCountry(filteredData[0]?.country_name)

                setbreadCrumbCollection((prevState) => ({
                    ...prevState,
                    breadCrumbCountry: filteredData[0]?.country_name,
                    breadCrumbCurrent: "Country",
                }));
            } else {
                setbreadCrumbCollection((prevState) => ({
                    ...prevState,
                    breadCrumbCountry: "",
                }));
            }
        }
    }, [dataSource?.selectedCountry, dataSource.country]);

    useEffect(() => {
        if (
            dataSource?.selectedCity &&
            dataSource?.selectedCity !== "" &&
            dataSource?.city
        ) {
            const cityData =
                dataSource?.city &&
                dataSource?.city.filter(
                    (item) => item?.city_id === dataSource.selectedCity
                );

            if (cityData && cityData.length > 0) {
                //setbreadCrumbCity(cityData[0].city_name);

                setbreadCrumbCollection((prevState) => ({
                    ...prevState,
                    breadCrumbCity: cityData[0].city_name,
                    breadCrumbCurrent: "City",
                }));
            } else {
                setbreadCrumbCollection((prevState) => ({
                    ...prevState,
                    breadCrumbCity: "",
                }));
            }
        }
    }, [dataSource?.selectedCity, dataSource?.city]);

    useEffect(() => {
        if (dataSource.selectedEventMode) {
            //setbreadCrumbEventMode(dataSource.selectedEventMode);

            setbreadCrumbCollection((prevState) => ({
                ...prevState,
                breadCrumbEventMode: dataSource.selectedEventMode,
                breadCrumbCurrent: "Event Mode",
            }));
        } else {
            setbreadCrumbCollection((prevState) => ({
                ...prevState,
                breadCrumbEventMode: "",
            }));
        }
    }, [dataSource.selectedEventMode]);


    useEffect(() => {
        if (dataSource.selectedDate === "All") {


            setbreadCrumbCollection((prevState) => ({
                ...prevState,
                breadCrumbDate: `${dataSource.selectedDate} Dates`,
                breadCrumbCurrent: "Date",
            }));
        } else if (
            dataSource.formattedFrom_date !== '' &&
            dataSource.formattedTo_Date !== '' &&
            dataSource.selectedDate !== "All"
        ) {

            setbreadCrumbCollection((prevState) => ({
                ...prevState,
                breadCrumbDate: `${dataSource.formattedFrom_date} to ${dataSource.formattedTo_Date}`,
                breadCrumbCurrent: "Date",
            }));
        }
        else {
            setbreadCrumbCollection((prevState) => ({
                ...prevState,
                breadCrumbDate: "",
            }));
        }
    }, [

        dataSource.selectedDate,
        dataSource.formattedFrom_date,
        dataSource.formattedTo_Date

    ]);

    useEffect(() => {
        if (dataSource.mainCatName) {
            setbreadCrumbCollection((prevState) => ({
                ...prevState,
                breadCrumbCategory: dataSource.mainCatName,
                breadCrumbCurrent: "Main Category",
            }));
        }

    }, [dataSource.mainCatName])



    const handlebackButton = () => {
        navigate("/events");
    };

    const { setPrevURL } = useContext(AuthContext);

    useEffect(() => {
        setPrevURL(`${location.pathname}${location.search}`);
    }, [location]);


    return (

        <>
            <div>
                <div className="section-padding pt-lg-4">
                    <div className="container-fluid mt-5 mt-lg-0">
                        <div className="row">
                            <div className="d-lg-none filter-sticky d-flex flex-nowrap align-items-center gap-3 justify-content-between">
                                {/*Mobile breadcrumb */}
                                <div
                                    className="w-100 d-flex align-items-center hide-scrollbar breadcrumbCountryWarp"
                                    style={{ gap: "2px", overflow: "scroll" }}
                                >
                                    <div
                                        className="p-auto me-3 rounded d-md-block backBtn cursor-pointer"
                                        style={{ border: "1px solid #ddd" }}
                                    >
                                        <IoChevronBack
                                            style={{ fontSize: "24px" }}
                                            onClick={handlebackButton}
                                        /> 
                                    </div>
                                   

                                    <p
                                        className="m-0 p-0"
                                        style={{
                                            color:
                                                breadCrumbCollection.breadCrumbCurrent === "Country"
                                                    ? "#3F00FF"
                                                    : undefined,
                                        }}
                                    >
                                        {breadCrumbCollection.breadCrumbCountry}
                                    </p>
                                    <i style={{ fontSize: "16px" }}>
                                        <MdKeyboardArrowRight />
                                    </i>
                                    <p
                                        className="m-0 p-0"
                                        style={{
                                            color:
                                                breadCrumbCollection.breadCrumbCurrent === "City"
                                                    ? "#3F00FF"
                                                    : undefined,
                                        }}
                                    //style={{ color: "#3F00FF" }}
                                    >
                                        {breadCrumbCollection.breadCrumbCity}
                                    </p>


                                    <i style={{ fontSize: "16px" }}>
                                        <MdKeyboardArrowRight />
                                    </i>
                                    <p
                                        className="m-0 p-0"
                                        style={{
                                            color:
                                                breadCrumbCollection.breadCrumbCurrent === "Main Category"
                                                    ? "#3F00FF"
                                                    : undefined,
                                        }}

                                    >
                                        {breadCrumbCollection.breadCrumbCategory}
                                    </p>

                                    <i style={{ fontSize: "16px" }}>
                                        <MdKeyboardArrowRight />
                                    </i>

                                    <p
                                        className="m-0 p-0"
                                        style={{
                                            color:
                                                breadCrumbCollection.breadCrumbCurrent === "Event Mode"
                                                    ? "#3F00FF"
                                                    : undefined,
                                        }}
                                    //style={{ color: "#3F00FF" }}
                                    >
                                        {breadCrumbCollection.breadCrumbEventMode}
                                    </p>

                                    <i style={{ fontSize: "16px" }}>
                                        <MdKeyboardArrowRight />
                                    </i>
                                    <p
                                        className="m-0 p-0"
                                        style={{
                                            color:
                                                breadCrumbCollection.breadCrumbCurrent === "Date"
                                                    ? "#3F00FF"
                                                    : undefined,
                                        }}
                                    //style={{ color: "#3F00FF" }}
                                    >
                                        {breadCrumbCollection.breadCrumbDate}
                                    </p>



                                </div>
                                <div
                                    className="filter-button align-items-center"
                                    onClick={doFilter}
                                >
                                    <FaFilter />
                                    Filters
                                </div>
                            </div>
                            <div className="col-lg-3 col-xl-2 sideBarFilter">
                                <div className="filterBg pb-2">
                                    <div className="">
                                        <h5>
                                            <FaFilter /> Filters
                                        </h5>
                                    </div>

                                    <>
                                        <div className="w-100">


                                            <div className="mt-4 d-flex flex-column gap-2">
                                                <h6 className="mb-0">Location</h6>
                                                <div className="d-flex flex-column gap-2">
                                                    <div>
                                                        <SelectBox
                                                            dataSource={dataSource.country || []}
                                                            displayExpr="country_name"
                                                            valueExpr="country_id"
                                                            label="Select Country"
                                                            searchEnabled={true}
                                                            onValueChanged={handleCountry}
                                                            labelMode="floating"
                                                            value={dataSource.selectedCountry}
                                                        />
                                                    </div>
                                                    <div>
                                                        <SelectBox
                                                            dataSource={dataSource.city || []}
                                                            label="Select City"
                                                            labelMode="floating"
                                                            displayExpr="city_name"
                                                            valueExpr="city_id"
                                                            searchEnabled={true}
                                                            onValueChanged={(e) => handleCity(e.value)}
                                                            value={dataSource.selectedCity}
                                                        />
                                                    </div>
                                                    <div className="mt-4 d-flex flex-column gap-2">
                                                        <h6 className="m-0">Select Category</h6>
                                                        <SelectBox
                                                            dataSource={dataSource.maincategory_all || []}
                                                            displayExpr="main_cat_name"
                                                            valueExpr="main_cat_id"
                                                            label="Change Category"
                                                            searchEnabled={true}
                                                            onValueChanged={handleMainCategory}
                                                            labelMode="floating"

                                                            value={dataSource.selectedMainCategory}
                                                        />
                                                    </div>
                                                </div>
                                            </div> &nbsp; <br></br>
                                            <div className="d-flex flex-column gap-2">
                                                <h6 className="m-0 p-0">Event Mode</h6>
                                                <div className="d-flex gap-2">
                                                    <button
                                                        onClick={handleEvent_offline}
                                                        className={
                                                            dataSource.selectedEventMode === "Offline"
                                                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                        }
                                                    >
                                                        Offline
                                                    </button>

                                                    <button
                                                        onClick={handleEvent_online}
                                                        className={
                                                            dataSource.selectedEventMode === "Online"
                                                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                        }
                                                    >
                                                        Online
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="w-100 mt-4 d-flex flex-column gap-2">
                                                <h6>Date</h6>
                                                <div className="w-100 d-flex gap-2 align-items-center flex-wrap">
                                                    <button
                                                        onClick={handleDate_all}
                                                        className={
                                                            dataSource.selectedDate === "All"
                                                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                        }
                                                    >
                                                        All
                                                    </button>{" "}



                                                    <button
                                                        onClick={handleDate_CustomDates}
                                                        className={
                                                            dataSource.selectedDate === "CustomDates"
                                                                ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                        }
                                                    >
                                                        Custom Dates
                                                    </button>



                                                    {
                                                        dataSource.selectedDate === "CustomDates" ?
                                                            (
                                                                <div >
                                                                    <div>
                                                                        <DateBox

                                                                            min={dataSource.min_date || now}
                                                                            max={dataSource.max_date || null}
                                                                            type="date"
                                                                            label="From Date"
                                                                            labelMode="floating"
                                                                            value={dataSource.from_date || ''}
                                                                            displayFormat="dd-MM-yyyy"
                                                                            acceptCustomValue={false}
                                                                            onValueChanged={(e) => handleFromDate(e.value)}
                                                                        />
                                                                        <span style={{ color: 'red', fontSize: '12px' }}>{dataSource.validation_fromdate}</span>
                                                                    </div>

                                                                    <div> <DateBox
                                                                        min={dataSource.from_date || dataSource.min_date || now}
                                                                        max={dataSource.max_date || null}
                                                                        label="To Date"
                                                                        labelMode="floating"
                                                                        value={dataSource.to_date || ''}
                                                                        onValueChanged={(e) => handletoDate(e.value)}
                                                                        type="date"
                                                                        displayFormat="dd-MM-yyyy"
                                                                        acceptCustomValue={false}
                                                                    />
                                                                        <span style={{ color: 'red', fontSize: '12px' }}>{dataSource.validation_todate}</span>
                                                                    </div> </div>
                                                            ) : (
                                                                null
                                                            )

                                                    }






                                                </div>


                                            </div>

                                        </div>
                                    </>

                                </div>
                            </div>

                            <div className="col-lg-9 col-xl-10 filtermh">
                                <div>
                                    <Modal className="filterPopUp" show={filter} onHide={doFilter}>
                                        <Modal.Header
                                            closeButton
                                            className="m-0 d-flex align-items-center text-center border-light"
                                            style={{ padding: "10px 10px" }}
                                        >
                                            <div className="w-100 filterBg pb-2">
                                                <h5 className="d-flex align-items-center gap-2">
                                                    <FaFilter /> Filters
                                                </h5>
                                            </div>
                                        </Modal.Header>
                                        <Modal.Body className="p-3">
                                            <div className="w-100">


                                                <div className="mt-4 d-flex flex-column gap-2">
                                                    <h6 className="mb-0">Location</h6>
                                                    <div className="d-flex flex-column gap-2">
                                                        <div>
                                                            <SelectBox
                                                                dataSource={dataSource.country || []}
                                                                displayExpr="country_name"
                                                                valueExpr="country_id"
                                                                label="Select Country"
                                                                searchEnabled={true}
                                                                onValueChanged={handleCountry}
                                                                labelMode="floating"
                                                                value={dataSource.selectedCountry}
                                                            />
                                                        </div>
                                                        <div>
                                                            <SelectBox
                                                                dataSource={dataSource.city || []}
                                                                label="Select City"
                                                                labelMode="floating"
                                                                displayExpr="city_name"
                                                                valueExpr="city_id"
                                                                searchEnabled={true}
                                                                onValueChanged={(e) => handleCity(e.value)}
                                                                value={dataSource.selectedCity}
                                                            />
                                                        </div>
                                                        <div className="mt-4 d-flex flex-column gap-2">
                                                            <h6 className="m-0">Select Category</h6>
                                                            <SelectBox
                                                                dataSource={dataSource.maincategory_all || []}
                                                                displayExpr="main_cat_name"
                                                                valueExpr="main_cat_id"
                                                                label="Change Category"
                                                                searchEnabled={true}
                                                                onValueChanged={handleMainCategory}
                                                                labelMode="floating"

                                                                value={dataSource.selectedMainCategory}
                                                            />
                                                        </div>
                                                    </div>
                                                </div> &nbsp; <br></br>
                                                <div className="d-flex flex-column gap-2">
                                                    <h6 className="m-0 p-0">Event Mode</h6>
                                                    <div className="d-flex gap-2">
                                                        <button
                                                            onClick={handleEvent_offline}
                                                            className={
                                                                dataSource.selectedEventMode === "Offline"
                                                                    ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                    : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                            }
                                                        >
                                                            Offline
                                                        </button>

                                                        <button
                                                            onClick={handleEvent_online}
                                                            className={
                                                                dataSource.selectedEventMode === "Online"
                                                                    ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                    : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                            }
                                                        >
                                                            Online
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="w-100 mt-4 d-flex flex-column gap-2">
                                                    <h6>Date</h6>
                                                    <div className="w-100 d-flex gap-2 align-items-center flex-wrap">
                                                        <button
                                                            onClick={handleDate_all}
                                                            className={
                                                                dataSource.selectedDate === "All"
                                                                    ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                    : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                            }
                                                        >
                                                            All
                                                        </button>{" "}



                                                        <button
                                                            onClick={handleDate_CustomDates}
                                                            className={
                                                                dataSource.selectedDate === "CustomDates"
                                                                    ? "btn py-1 px-3 act filterBtn m-0 text-nowrap"
                                                                    : "btn py-1 px-3 filterBtn m-0 text-nowrap"
                                                            }
                                                        >
                                                            Custom Dates
                                                        </button>



                                                        {
                                                            dataSource.selectedDate === "CustomDates" ?
                                                                (
                                                                    <div >
                                                                        <div>
                                                                            <DateBox

                                                                                min={dataSource.min_date || now}
                                                                                max={dataSource.max_date || null}
                                                                                type="date"
                                                                                label="From Date"
                                                                                labelMode="floating"
                                                                                value={dataSource.from_date || ''}
                                                                                displayFormat="dd-MM-yyyy"
                                                                                acceptCustomValue={false}
                                                                                onValueChanged={(e) => handleFromDate(e.value)}
                                                                            />
                                                                            <span style={{ color: 'red', fontSize: '12px' }}>{dataSource.validation_fromdate}</span>
                                                                        </div>

                                                                        <div> <DateBox
                                                                            min={dataSource.from_date || dataSource.min_date || now}
                                                                            max={dataSource.max_date || null}
                                                                            label="To Date"
                                                                            labelMode="floating"
                                                                            value={dataSource.to_date || ''}
                                                                            onValueChanged={(e) => handletoDate(e.value)}
                                                                            type="date"
                                                                            displayFormat="dd-MM-yyyy"
                                                                            acceptCustomValue={false}
                                                                        />
                                                                            <span style={{ color: 'red', fontSize: '12px' }}>{dataSource.validation_todate}</span>
                                                                        </div> </div>
                                                                ) : (
                                                                    null
                                                                )

                                                        }






                                                    </div>


                                                </div>

                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer className="py-1 px-2 border-0">
                                            <button className="button" onClick={doFilter}>
                                                Apply
                                            </button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* ---------------------------------------------------------- */}
                                </div>


                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active">

                                        <div className="d-none d-lg-block">
                                            {/* website breadcrumb  */}
                                            <div
                                                className="w-100 d-flex align-items-center hide-scrollbar breadcrumbCountryWarp"
                                                style={{ gap: "2px", overflow: "scroll" }}
                                            >
                                                <div
                                                    className="p-auto me-3 rounded d-none d-md-block backBtn cursor-pointer"
                                                    style={{ border: "1px solid #ddd" }}
                                                >
                                                    <IoChevronBack
                                                        style={{ fontSize: "24px" }}
                                                        onClick={handlebackButton}
                                                    />
                                                </div>
                                                <p
                                                    className="m-0 p-0"
                                                    style={{
                                                        color:
                                                            breadCrumbCollection.breadCrumbCurrent === "Country"
                                                                ? "#3F00FF"
                                                                : undefined,
                                                    }}
                                                >
                                                    {breadCrumbCollection.breadCrumbCountry}
                                                </p>
                                                <i style={{ fontSize: "16px" }}>
                                                    <MdKeyboardArrowRight />
                                                </i>
                                                <p
                                                    className="m-0 p-0"
                                                    style={{
                                                        color:
                                                            breadCrumbCollection.breadCrumbCurrent === "City"
                                                                ? "#3F00FF"
                                                                : undefined,
                                                    }}
                                                //style={{ color: "#3F00FF" }}
                                                >
                                                    {breadCrumbCollection.breadCrumbCity}
                                                </p>


                                                <i style={{ fontSize: "16px" }}>
                                                    <MdKeyboardArrowRight />
                                                </i>
                                                <p
                                                    className="m-0 p-0"
                                                    style={{
                                                        color:
                                                            breadCrumbCollection.breadCrumbCurrent === "Main Category"
                                                                ? "#3F00FF"
                                                                : undefined,
                                                    }}

                                                >
                                                    {breadCrumbCollection.breadCrumbCategory}
                                                </p>

                                                <i style={{ fontSize: "16px" }}>
                                                    <MdKeyboardArrowRight />
                                                </i>

                                                <p
                                                    className="m-0 p-0"
                                                    style={{
                                                        color:
                                                            breadCrumbCollection.breadCrumbCurrent === "Event Mode"
                                                                ? "#3F00FF"
                                                                : undefined,
                                                    }}
                                                //style={{ color: "#3F00FF" }}
                                                >
                                                    {breadCrumbCollection.breadCrumbEventMode}
                                                </p>

                                                <i style={{ fontSize: "16px" }}>
                                                    <MdKeyboardArrowRight />
                                                </i>
                                                <p
                                                    className="m-0 p-0"
                                                    style={{
                                                        color:
                                                            breadCrumbCollection.breadCrumbCurrent === "Date"
                                                                ? "#3F00FF"
                                                                : undefined,
                                                    }}
                                                //style={{ color: "#3F00FF" }}
                                                >
                                                    {breadCrumbCollection.breadCrumbDate}
                                                </p>



                                            </div>
                                        </div>


                                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 ">
                                            {loading ? (
                                                Array(8)
                                                    .fill(0)
                                                    .map((_, index) => (
                                                        <div className="col overflow-hidden" key={index}>
                                                            <Skeleton height={250} />
                                                            <Skeleton height={15} />
                                                            <Skeleton height={15} />
                                                            <Skeleton height={30} />
                                                        </div>
                                                    ))
                                            ) : (
                                                <>
                                                    {dataSource.tempEventData &&
                                                        dataSource.tempEventData.length > 0 ? (
                                                        dataSource.tempEventData.map((data) => (
                                                            <div
                                                                className="col events-bg mb-1 bg-gray bg-sm-none"
                                                                key={data.event_id}
                                                            >
                                                                <div className="product-block mb-3 mb-lg-4">
                                                                    <div
                                                                        className="image-wrap "
                                                                        style={{
                                                                            borderRadius: "6px",
                                                                            overflow: "hidden",
                                                                        }}
                                                                        onClick={() =>
                                                                            selectedDetailsEvent(
                                                                                data?.event_id,
                                                                                data?.event_title
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="product-image-container">
                                                                            {
                                                                                isValidImage(data?.event_image) &&
                                                                                    imageExists(
                                                                                        `${ALLEVENT_DETAILS_IMAGES}${data?.event_image}`
                                                                                    ) &&
                                                                                    data?.event_image
                                                                                    && !imageError

                                                                                    ? (
                                                                                        <img
                                                                                            src={`${ALLEVENT_DETAILS_IMAGES}${data?.event_image}`}
                                                                                            className="w-100"
                                                                                            alt="productImg-"
                                                                                            loading="lazy"
                                                                                            onError={handleImageError}
                                                                                        />
                                                                                    ) : (
                                                                                        <EventNoimage />
                                                                                    )}
                                                                        </div>
                                                                        <div class="align-items-center blog-description bg-white p-2">
                                                                            <h5
                                                                                className="mb-0 text-ellipsis"
                                                                                title={data?.event_title}
                                                                                style={{
                                                                                    maxHeight: "48px",
                                                                                    fontSize: "16px",
                                                                                    overflow: "hidden",
                                                                                }}
                                                                            >
                                                                                {data?.event_title}
                                                                            </h5>
                                                                            <p
                                                                                className="mb-0 text-ellipsis"
                                                                                style={{
                                                                                    maxHeight: "24px",
                                                                                    overflow: "hidden",
                                                                                }}
                                                                            >
                                                                                {data?.venue_name}
                                                                            </p>
                                                                            <div className="justify-content-between d-flex align-items-end gap-2">
                                                                                <div className="pe-0">
                                                                                    <p class="mb-0 iq-title text-black">
                                                                                        {data?.event_date}
                                                                                        <br />
                                                                                        {data?.event_time}
                                                                                    </p>
                                                                                </div>
                                                                                <p
                                                                                    className="button m-0 text-center"
                                                                                    style={{
                                                                                        padding: "6px 24px",
                                                                                        height: "auto",
                                                                                        fontSize: "12px",
                                                                                        width: "40%",
                                                                                        backgroundColor:
                                                                                            data?.ticketing_option === "free" ||
                                                                                                data?.ticketing_option ===
                                                                                                "register"
                                                                                                ? "green"
                                                                                                : "#3f00ff",
                                                                                    }}
                                                                                >
                                                                                    {data?.ticketing_option_name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="d-flex flex-column align-items-center justify-content-center w-100">
                                                            <NodataforEvent />
                                                            <button className="button" onClick={handleResetFilter}>Reset Filters</button>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </div>



                            </div>





                        </div></div></div>
            </div>
        </>)
}