import { useNavigate, useLocation } from "react-router-dom";

const useCustomSearchParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const updateSearchParams = (key, value) => {
    queryParams.set(key, value);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const insertOrUpdateParams = (key, value) => {
    if (queryParams.has(key)) {
      queryParams.set(key, value);
    } else {
      queryParams.append(key, value);
    }
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const removeParams = (key) => {
    queryParams.delete(key);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return { updateSearchParams, insertOrUpdateParams, removeParams };
};

export default useCustomSearchParams;
