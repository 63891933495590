// ShareModal.js
import React from 'react';
import { Modal } from 'react-bootstrap';

const ShareModalRaga = ({ show, handleClose, currentUrl, handleCopy, inputRef, mlogo }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header
                        closeButton
                        className="m-0 d-flex align-items-center text-center border-light"
                        style={{ padding: "10px 10px" }}
                      >
                        <h5 className="text-nowrap text-center m-auto">
                          Share
                        </h5>
                      </Modal.Header>
      <Modal.Body className="px-2 pb-4">
        <div className="d-flex justify-content-around gap-3">
          <div className="share-icon">
            <img src={mlogo} alt="mdnd" />
          </div>

          <div className="share-icon whatsapp">
            <i className="fa-brands fa-whatsapp" aria-hidden="true"></i>
          </div>

          <div className="share-icon facebook">
            <i className="fa-brands fa-facebook" aria-hidden="true"></i>
          </div>

          <div className="share-icon twitter">
            <i className="fa-brands fa-x-twitter"></i>
          </div>
        </div>

        <div className="copy-link-container">
          <div className="copy-header"></div>
          <div className="copy-link">
            <div className="copy-link-inner">
              <form onSubmit={handleCopy}>
                <input
                  type="text"
                  value={currentUrl}
                  ref={inputRef}
                  onClick={() => inputRef.current.select()}
                  readOnly
                />
                <input type="submit" value="Copy" />
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModalRaga;
