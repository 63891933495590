import React, { useState, useEffect } from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { Button } from 'devextreme-react/button';
import { Modal } from "react-bootstrap";
import { NumberBox, SelectBox, TextBox } from 'devextreme-react';
import { useMutation } from '@tanstack/react-query';
import notify from 'devextreme/ui/notify';
import { DURATION, ERROR, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import { makePostRequest } from '../../../Api/requestMethods';
import { DELETE_ADDON_CART, GET_ADDON_CATEGORIES, UPDATE_ADDON_CARD } from '../../../Api/api';
import { Addoncodetype } from '../../../global/constants.js';
import { jwtDecode } from 'jwt-decode';
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ViewBlogs = () => {
  const [addondetail, setAddonDetail] = useState([]);
  const [selectOption, setSelectOption] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  const [category, setCategory] = useState({
    addCategory: '',
    updateCategory: "",
    openThePopup: false,
    selectallData: ""
  });
  const [formData, setFormData] = useState({
    id: "",
    addon_id: "",
    addon_code: "",
    description: "",
    addon_type: "",
    original_value: "",
    amount: "",
    status: "",
    question: ""
  });
  const [touchedFields, setTouchedFields] = useState({});
  const [loading, setLoading] = useState(true);

  const user = localStorage.getItem("MDnD_auth") ? jwtDecode(localStorage.getItem("MDnD_auth")) : null;
  const roleid = user ? user.role_id : null;

  const validateFields = () => {
    const errors = {};
    if (!formData.addon_code) errors.addon_code = "This field is required.";
    if (!formData.description) errors.description = "This field is required.";
    if (!formData.addon_type) errors.addon_type = "This field is required.";
    if (!formData.original_value) errors.original_value = "This field is required.";
    if (!formData.amount) errors.amount = "This field is required.";
    if (formData.addon_type === "question" && !formData.question) {
      errors.question = "Question is required when Addon Type is 'Question'.";
    }
    return errors;
  };

  const onEditButtonClick = (data) => {
    const { addon_id, addon_code, description, addon_type, original_value, amount, status, question } = data;
    setFormData({
      id: data.id,
      addon_id: addon_id,
      addon_code: addon_code,
      description: description,
      addon_type: addon_type,
      original_value: original_value,
      amount: amount,
      status: status,
      question: question
    });
    setCategory(prev => ({ ...prev, openThePopup: true }));
  };

  const ExitPopup = () => {
    setCategory(prev => ({ ...prev, openThePopup: false }));
    setFormData({
      id: "",
      addon_id: "",
      addon_code: "",
      description: "",
      addon_type: "",
      original_value: "",
      amount: "",
      status: "",
      question: ""
    });
    setValidationErrors({});
  };

  const viewAddonMutation = useMutation({
    mutationKey: 'viewBlog',
    mutationFn: (role_id) => makePostRequest(GET_ADDON_CATEGORIES(), { role_id }),
    onSuccess: (data) => {
      if (data?.status === "status") {
        notify(data.message, "SUCCESS");
        const sortedData = data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const addonWithSerialNumbers = sortedData.map((addon, index) => ({
          ...addon,
          serialNumber: sortedData.length - index
        }));
        setAddonDetail(addonWithSerialNumbers);
      } else {
      }
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    }
  });

  useEffect(() => {
    viewAddonMutation.mutate(roleid, {
      onSuccess: (data) => {
        if (data.data !== "") {
          const sortedData = data?.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

          const addonWithSerialNumbers = data?.data?.map((addon, index) => ({
            ...addon,
            serialNumber: sortedData.length - index
          }));
          setAddonDetail(addonWithSerialNumbers);
        }
        if (data.status === 'failed') {
          notify(data.message, ERROR, DURATION);
        } else if (data.status === 'success') {
        }
      },
    });
  }, []);



  const adingandupdate = (flag, e) => {
    flag === "Update" && setCategory(prev => ({ ...prev, ADD_CATEGORY_OR_UPDATE: flag, selectallData: e.data }));
    flag === "Create" && setCategory(prev => ({ ...prev, ADD_CATEGORY_OR_UPDATE: flag }));
  };

  const addonUpdatedFunction = async (data) => {
    try {
      const response = await makePostRequest(UPDATE_ADDON_CARD(), data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const addonCardDetailsUpdate = useMutation({
    mutationKey: ["viewBlog"],
    mutationFn: (addonUpdatedData) => addonUpdatedFunction(addonUpdatedData),
    onSuccess: (data) => {
      if (data.status === 'failed') {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === 'success') {
        notify(data.message, SUCCESS);
        setAddonDetail((prevData) =>
          prevData.map((item) => (item.id === data.id ? data : item))
        );
      }
    },
    onError: (error) => {
    }
  });

  const handleOptionChange = (e) => {
    setSelectOption(e);
    setTouchedFields(prev => ({ ...prev, addon_type: true }));
    setFormData(prev => ({ ...prev, addon_type: e }));
  };

  const handleFieldChange = (field, value) => {
    setTouchedFields(prev => ({ ...prev, [field]: true }));
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleUpdatRowData = async (e) => {
    const errors = validateFields();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    const updatedValues = { ...formData, roleid: roleid };
    const fieldsToUpdate = {
      id: updatedValues.id,
      role_id: roleid,
      ...Object.keys(touchedFields).reduce((acc, key) => {
        if (touchedFields[key]) {
          acc[key] = updatedValues[key];
        }
        return acc;
      }, {})
    };

    try {
      await addonCardDetailsUpdate.mutateAsync(fieldsToUpdate);
    } catch (error) {
      console.log("error", error);
    }
  };

  const addonDeleteFunctionApi = async (data) => {
    try {
      const response = await makePostRequest(DELETE_ADDON_CART(), data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const addonDeleteDetailUpdate = useMutation({
    mutationKey: ["DeleteAdd"],
    mutationFn: (deleteData) => addonDeleteFunctionApi(deleteData),
    onSuccess: (data) => {
      if (data.status === 'failed') {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === 'success') {
        notify(data.message, SUCCESS);
      }
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    }
  });
  const onDeleteButtonClick = async (data) => {
    const fieldsToDelete = {
      role_id: roleid,
      id: data.id,
      status: "N"
    };
    try {
      setAddonDetail((prevData) =>
        prevData
          .filter((item) => item.id !== data.id)
          .map((item, index, arr) => ({
            ...item,
            serialNumber: arr.length - index
          }))
      );

      await addonDeleteDetailUpdate.mutateAsync(fieldsToDelete);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div id="data-grid-demo">
      {loading ? (
        <Skeleton count={10} height={40} />
      ) : (
        <DataGrid
          dataSource={addondetail}
          showBorders={true}
          onInitNewRow={(e) => adingandupdate('Create', e)}
          onEditingStart={(e) => adingandupdate("Update", e)}
        >
          <Column dataField="serialNumber" width={50} caption='Sl' />
          <Column dataField="addon_code" caption="Code" />
          <Column dataField="description" caption="Description" />
          <Column dataField="addon_type" caption="Type" />
          <Column dataField="original_value" caption="Original Value" />
          <Column dataField="amount" caption="Amounts(INR)" />
          <Column dataField="question" caption="Question" />
          <Column
            caption="Edit"
            cellRender={({ data }) => (
              <div>
                <MdModeEditOutline
                  className="EditIcon"
                  onClick={() => onEditButtonClick(data)}
                />
              </div>
            )}
            width={60}
          />
          <Column
            caption="Delete"
            cellRender={({ data }) => (
              <div>
                <RiDeleteBinLine
                  className="DeleteIcon"
                  onClick={() => onDeleteButtonClick(data)}
                />
              </div>
            )}
          />
        </DataGrid>
      )}
      <Modal
        size="lg"
        show={category.openThePopup}
        centered
      >
        <Modal.Header onHide={() => ExitPopup()} closeButton>
          <Modal.Title>
            {category.ADD_CATEGORY_OR_UPDATE} View Add-on
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-6">
                <TextBox
                  label='Add-on Code'
                  labelMode='floating'
                  value={formData.addon_code}
                  onValueChanged={(e) => handleFieldChange('addon_code', e.value)}
                />
                {validationErrors.addon_code && (
                  <div className="text-danger">{validationErrors.addon_code}</div>
                )}              </div>
              <div className="col-md-6">
                <TextBox
                  label="Add-On Description"
                  labelMode='floating'
                  value={formData.description}
                  onValueChanged={(e) => handleFieldChange('description', e.value)}
                />
                {validationErrors.description && (
                  <div className="text-danger">{validationErrors.description}</div>
                )}              </div>
              <div className="col-md-6">
                <SelectBox
                  dataSource={Addoncodetype}
                  displayExpr="type"
                  valueExpr="value"
                  label="Add_on Type"
                  labelMode='floating'
                  value={formData.addon_type}
                  onValueChanged={(e) => handleOptionChange(e.value)}
                />
                {validationErrors.addon_type && <div className="text-danger">{validationErrors.addon_type}</div>}
              </div>
              {formData.addon_type !== "Question" &&
                <div className="col-md-6">
                  <NumberBox
                    label="Original Value"
                    labelMode='floating'
                    value={formData.original_value}
                    onValueChanged={(e) => handleFieldChange('original_value', e.value)}
                  />
                  {validationErrors.original_value && (
                    <div className="text-danger">{validationErrors.original_value}</div>
                  )}              </div>
              }
              <div className="col-md-6">
                <NumberBox
                  label="Amount (INR)"
                  labelMode='floating'
                  value={formData.amount}
                  onValueChanged={(e) => handleFieldChange('amount', e.value)}
                />
                {validationErrors.amount && (
                  <div className="text-danger">{validationErrors.amount}</div>
                )}              </div>
              {formData.addon_type === "Question" &&
                <div className="col-md-6">
                  <TextBox
                    label="Question"
                    labelMode='floating'
                    value={formData.question}
                    onValueChanged={(e) => handleFieldChange('question', e.value)}
                  />
                  {validationErrors.question && (
                    <div className="text-danger">{validationErrors.question}</div>
                  )}              </div>
              }
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='CancelBtn' type="button" variant="secondary" onClick={() => ExitPopup()}>
            Close
          </button>
          <button className='button' type="button" variant="primary" onClick={() => handleUpdatRowData()}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewBlogs;
