import React from "react";
import IMAGE1 from "../../assets/images/genre/New/Events.png";
import IMAGE2 from "../../assets/images/genre/New/Artists.png";
import IMAGE3 from "../../assets/images/genre/New/Organisers.png";
import IMAGE4 from "../../assets/images/genre/New/Franchisees.png";
import IMAGE12 from "../../assets/images/genre/New/MDnD Store.png";
import IMAGE13 from "../../assets/images/genre/New/Season Tickets.png";
import IMAGE7 from "../../assets/images/genre/New/MDnD Records.png";
import IMAGE10 from "../../assets/images/genre/New/Raga Bank.png";

function About() {
  return (
    <>
      {/* <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">About</h2>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      <section className={`section-padding pt-lg-3`}>
        <div className="container px-3 aboutTextPara">
          <div className="row align-items-center mb-3">
              <div className="text-center">
                <h2>About</h2>
                <p className="text-center">MDnD stands for ‘Music, Dance and Drama’.</p>
                {/* <p>
                  On <a href="https://www.mdnd.in">www.mdnd.in</a>, you can-
                </p> */}
              </div>
          </div>
          <div className="row about-us-detail">
          <div className="col-lg-3 col-md-6 col-12 mb-4">
              <div className="image-box">
                <img src={IMAGE1} className="img-fluid w-100 rounded-3" alt="Events" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Events</h5>
                <p>
                Discover upcoming music, dance or drama events in Indian Performing Arts, happening anywhere in the World, whether FREE or ticketed and whether Offline (Physical) or Online (Virtual). You can search by country, city, category, date, and ticket price.
                </p>
                <p>
                The best part is you can browse and buy tickets without having to register.
                </p>
                <p className="underline">
                List your event
                </p>
                <p>
                Organisers / event managers can register at 
                  <a href="www.mdnd.in/register"> &nbsp;www.mdnd.in/register</a>&nbsp;
                   and list their events Free of cost. For support, please mail to 
                  <a href="events@mdnd.in">&nbsp;events@mdnd.in</a>.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <div className="image-box">
                <img src={IMAGE13} className="img-fluid w-100 rounded-3" alt="Season Tickets" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Season Tickets</h5>
                <p>
                Discover music, dance and drama festivals around the World. Whether it is the World-famous December Music Season at Chennai or the Rama Navami Concerts in Bengaluru or the Cleveland Tyagaraja Festival in the US, you can discover many such festivals. 
                </p>
                <p className="underline">
                List your festival
                </p>
                <p>
                Organisers / event managers of such festivals can register at 
                  <a href="www.mdnd.in/register">&nbsp; www.mdnd.in/register</a>&nbsp;
                  and list their festivals Free of cost. For support, please mail to 
                  <a href="events@mdnd.in">&nbsp;events@mdnd.in</a>.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <div className="image-box">
                <img src={IMAGE7} className="img-fluid w-100 rounded-3" alt="MDnD Records" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">MDnD Records</h5>
                <p>
                Discover and watch for FREE, videos by artists, young and old, across a range of genres. Please register and leave your comments on the videos that you watch. Your feedback means a lot to the artists. Share the videos that you like so that your friends and family too enjoy the videos. 
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <div className="image-box">
                <img src={IMAGE10} className="img-fluid w-100 rounded-3" alt="Raga Bank" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Raga Bank</h5>
                <p>
                Raga Bank is a large collection of Carnatic songs that you can search by raga, tala, type, language, artist and composer. Listen to your favourite raga or artist for FREE while you are doing your chores or while on a road trip. 
                </p>
                <p>
                We welcome Carnatic musicians and organisers to share their collection of recordings on Raga Bank and earn from them. Email to 
                  <a href="ragabank@mdnd.in.">&nbsp; ragabank@mdnd.in.</a>
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <div className="image-box">
                <img src={IMAGE12} className="img-fluid w-100 rounded-3" alt="MDnD Store" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">MDnD Store</h5>
                <p>
                Discover and watch for FREE, videos by artists, young and old, across a range of genres. Please register and leave your comments on the videos that you watch. Your feedback means a lot to the artists. Share the videos that you like so that your friends and family too enjoy the videos. 
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <div className="image-box">
                <img src={IMAGE2} className="img-fluid w-100 rounded-3" alt="Discover Artists" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Discover Artists</h5>
                <p>
                Discover talented performing artists spread across the World. Login and ‘Follow’ the artists you like and get updates on their events and videos. You can use filters to search by young and senior, location and across over 30 categories and 200 sub-categories.                </p>
                
                <p className="underline">
                Get featured on the Artists page                </p>
                <p>
                Artists who want to be featured on MDnD can do so for FREE. First, register as ‘Artist’ and create your profile and upload your photo. Your profile will then appear in the Artists page for fans to see and follow you. Share the profile link to family and friends and start building your fanbase. 
                  <a href="www.mdnd.in/register"> &nbsp;Click here to start now!</a>.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4">
              <div className="image-box">
                <img src={IMAGE3} className="img-fluid w-100 rounded-3" alt="Discover Organisers" />
              </div>
              <div className="box-content">
                <h5 className="mt-1">Discover Organisers</h5>
                <p>
                Discover organisers who organise events and festivals. Login and ‘Follow’ them to get updates on events, tickets and offers.            </p>
                
                <p className="underline">
                Get featured on the Organiser page              </p>
                <p>
                Organisers list themselves for FREE. First, register as ‘Organiser’ and create your profile and upload your logo image. Your profile will then appear in the Organisers page for artists and fans to see and follow you. Share the profile link to artists and customers and start building your fanbase. You can then create events, do ticketing, create seasons, create forms, do bulk emailing and much more. 
                  <a href="www.mdnd.in/register"> &nbsp;Click here to start now!</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
