import * as Yup from "yup";
import { COMMONFIELDREQUIRED } from "../../../../global/ToastMsgConstants.tsx";

const adsValidationSchema = Yup.object().shape({
  title: Yup.string().required(COMMONFIELDREQUIRED),
//   add_url: Yup.string().url('Invalid URL format').required(COMMONFIELDREQUIRED),
//   description: Yup.string().required(COMMONFIELDREQUIRED), // Corrected "discription" to "description"
//   start_date: Yup.date().required(COMMONFIELDREQUIRED),
//   end_date: Yup.date().min(Yup.ref('start_date'), 'End date must be later than start date').required(COMMONFIELDREQUIRED),
  payment_mode: Yup.string().required(COMMONFIELDREQUIRED),
  plan_type: Yup.string().required(COMMONFIELDREQUIRED),
  payable_inr: Yup.number().typeError('Payable amount must be a number').required(COMMONFIELDREQUIRED),
  record_categories: Yup.array()
    .of(Yup.string().required('Category must be a string')) // Assuming categories are strings
    .min(1, 'At least one category is required')
    .required(COMMONFIELDREQUIRED),
//   current: Yup.string().required(COMMONFIELDREQUIRED),
//   cheque_file: Yup.string().url('Invalid URL format').required(COMMONFIELDREQUIRED) // Assuming cheque_file is a URL
});

export default adsValidationSchema;
