import React, { useState, useEffect, useMemo } from "react";
import DataGrid, {
  Column,
  Paging,
  Editing,
  Lookup,
} from "devextreme-react/data-grid";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { formatDate } from "devextreme/localization";
import { mainAndSubCategory } from "../../../Api/api";
import { makeGetRequest } from "../../../Api/requestMethods";
import { ERROR, DURATION } from "../../../global/ToastMsgConstants.tsx";

const JointEventGrid = ({
  artistAndAccomDs,
  mainCategoryDs,
  subCategoryDs,
  organiserDs,
  props,
  values,
  dataSource,
  setFieldValue,
}) => {
  const [localDataSource, setLocalDataSource] = useState(
    values.joint_event_datasource || []
  );

  const seasonStartDate = useMemo(() => new Date(), []);

  const timeColumnConfig = useMemo(
    () => ({
      dataField: "event_time",
      caption: "Event Time",
      dataType: "datetime",
      format: "dd/MM/yyyy hh:mm a",
      editorOptions: {
        type: "datetime",
        acceptCustomValue: false,
        labelMode: "floating",
        displayFormat: "hh:mm a",
        min: seasonStartDate,
        pickerType: "calendar",
        useMaskBehavior: true,
        openOnFieldClick: true,
        showClearButton: true,
        calendarOptions: {
          visible: false,
        },
      },
      validationRules: [{ type: "required" }],
      setCellValue: (newData, value) => {
        if (value) {
          newData.event_time = new Date(value);
        }
      },
    }),
    [seasonStartDate]
  );

  const cellRender = useMemo(
    () => (data) => {
      if (!data.value) return "";
      try {
        const date = new Date(data.value);
        return formatDate(date, "hh:mm a");
      } catch (error) {
        console.error("Date formatting error:", error);
        return "";
      }
    },
    []
  );

  const mainAndSubFetch = async () => await makeGetRequest(mainAndSubCategory);
  const { data: mainAndSubCatDs } = useQuery({
    queryKey: ["__mainandSubCategory__"],
    queryFn: mainAndSubFetch,
    onError: (error) => {
      notify(error.response.data.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (props?.isEditEvent) {
      try {
        const parsedEventDetails = JSON.parse(
          dataSource.editEventDetailsDs.event_desc
        );
        setLocalDataSource(parsedEventDetails);
      } catch (error) {
        console.error("Parsing error in event description:", error);
      }
    } else if (props?.data?.data?.draft_data?.joint_event_datasource) {
      setLocalDataSource(props.data.data.draft_data.joint_event_datasource);
    }
  }, [
    props?.isEditEvent,
    props?.data?.data?.draft_data?.joint_event_datasource,
  ]);

  useEffect(() => {
    if (localDataSource && localDataSource?.length > 0) {
      setFieldValue("joint_event_datasource", localDataSource);
    }
  }, [localDataSource]);

  const findRowIndex = (dataSource, e) => {
    try {
      return dataSource.findIndex((data) => data.__KEY__ === e.data.__KEY__);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="JointEvent">
      <DataGrid
        dataSource={localDataSource}
        showBorders={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        onRowUpdated={(e) => {
          const updatedData = [...localDataSource];
          const rowIndex = findRowIndex(updatedData, e);
          updatedData[rowIndex] = e.data;
          setFieldValue("joint_event_datasource", updatedData);
        }}
        onRowRemoved={(e) => {
          const updatedData = localDataSource.filter(
            (row) => row?.__KEY__ !== e?.data?.__KEY__
          );
          setFieldValue("joint_event_datasource", updatedData);
        }}
      >
        <Editing
          mode="row"
          allowUpdating={true}
          allowDeleting={true}
          allowAdding={true}
        />
        <Paging enabled={true} />

        <Column {...timeColumnConfig} cellRender={cellRender} width={200} />

        <Column dataField="title" caption="Title" width={200} />

        <Column dataField="event_type" caption="Event Type" width={250}>
          <Lookup
            dataSource={mainAndSubCatDs?.data || []}
            displayExpr="category_name"
            valueExpr="category_name"
          />
        </Column>

        <Column dataField="artists" caption="Artists" width={220}>
          <Lookup
            dataSource={artistAndAccomDs}
            displayExpr="artist_name"
            valueExpr="artist_name"
          />
        </Column>

        <Column dataField="quick_add_artists" caption="Quick add Artists" />

        <Column dataField="accompanists" caption="Accompanists" width={220}>
          <Lookup
            dataSource={artistAndAccomDs}
            displayExpr="artist_name"
            valueExpr="artist_name"
          />
        </Column>

        <Column
          dataField="quick_add_accompanists"
          caption="Quick add Accompanists"
        />
      </DataGrid>
    </div>
  );
};

export default JointEventGrid;
