import React, { useState } from 'react';

const ReadMore = ({ html }) => {
  const charLimit = 300; // Set character limit
  const readMoreText = " ...read more";
  const readLessText = " read less";
  const [isReadMore, setIsReadMore] = useState(true);

  // Toggle between showing more or less content
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // Strip HTML tags from the content
  const textContent = html?.replace(/(<([^>]+)>)/gi, "");
  
  // If content is short enough, no need for "read more" functionality
  if (!textContent || textContent.length <= charLimit) {
    return (
      <div
        className="add-read-more"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  // Split the content into two parts: first part and the remaining
  const firstPart = textContent.substring(0, charLimit);
  const secondPart = textContent.substring(charLimit);

  return (
    <div className="add-read-more">
      {/* Display first part of the content */}
      <span dangerouslySetInnerHTML={{ __html: firstPart }} />
      
      {/* If "read more" is clicked, show the second part */}
      {!isReadMore && (
        <span dangerouslySetInnerHTML={{ __html: secondPart }} className="second-section" />
      )}

      {/* Toggle between read more and read less */}
      <span className="read-more" onClick={toggleReadMore} title="Click to Show More">
        {isReadMore ? readMoreText : ""}
      </span>
      <span className="read-less" onClick={toggleReadMore} title="Click to Show Less">
        {!isReadMore ? readLessText : ""}
      </span>
    </div>
  );
};

export default ReadMore;
