import React, { Suspense, lazy } from "react";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import GlobalSearch from "./GlobalSearch/GlobalSearch.js";
import Artist from "./Subpages/MainPage/Artist.js";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";

import Landing from "./Dashboard/Landing";
import Organisers from "./Pages/Organisers/Organisers.js";
import ListingEvents from "./Pages/Filterpage/Filterpage.js";
import Event from "./Subpages/MainPage/Event.js";
import ProfileSingle from "./Subpages/profiles/ProfileSingle.js";
import Franchisees from "./Pages/Franchisees/Franchisees.js";
import Blogfourcolmn from "./Pages/Blogs/component/BlogCategory.js";
import Blog from "./Pages/Blogs/Blogs.js";
import LoginDashboard from "./LoginDashboard/LoginDashboard.js";
import RegisterInHeader from "./Auth/RegisterInHeader.js";

// newly added
import SpecificPost from "./LoginDashboard/components/Profile/SpecificPost.js";
import Success from "./Pages/invoice/SuccessPage.js";
import Failed from "./Pages/invoice/Failed.js";
import MDndStore from "./MDndStore/MDndStore.js";
import AddtoCart from "./AddtoCart/AddtoCart.js";
import VideoStore from "./MDndStore/VideoStore.js";
import MDnDRecords from "./MDnDRecords/MDnDRecords.js";
import MDnDRecordsV from "./MDnDRecords/Vimeolikn/MDnDRecordsV.js";
import RecordsVideo from "./MDnDRecords/RecordsVideo.js";
import RecordsVimeo from "./MDnDRecords/Vimeolikn/RecordsVimeo.js";
import RagaBank from "./RagaBank/RagaBank.js";
import RagaBankVideo from "./RagaBank/CdnFol/RagaBankVideo.js";
import MDnDLive from "./Pages/MDnDLive/MDnDLive.js";
import SingleBlog from "./Pages/Blogs/component/SingleBlog.js";
import ProductDetails from "./Pages/productDetails/productDetails.js";
import ProductSummary from "./Pages/ProductSummary/ProductSummary.js";
import SeasonTickets from "./SeasonTickets/SeasonTickets";
import SeasonEventDetails from "./SeasonTickets/components/SeasonEventDetails.js";
import OrganizerLandingPage from "./SeasonTickets/components/OrganizerLandingPage.js";
import ProductCountdown from "./Pages/ProductCountdown/ProductCountdown.js";
import Front from "./Auth/Front.js";
import Forgot from "./Auth/Forgot.js";
import Registration from "./Auth/Registration.js";
import { ThemeProvider } from "./Theme/themeProvider.js";
import Dashboard from "./LoginDashboard/components/Dashboard/Dashboard.js";
import PageNotFound from "./Pages/PageNotFound/PageNotFound.js";
import EventsHeader from "./LoginDashboard/components/Events/EventsHeader.js";
import CreateEvents from "./LoginDashboard/components/Events/createEvents.js";
import ViewEvents from "./LoginDashboard/components/Events/ViewEvents.js";
import FormsHeader from "./LoginDashboard/components/Forms/FormsHeader.js";
import CreateForms from "./LoginDashboard/components/Forms/CreateForms.js";
import ViewForms from "./LoginDashboard/components/Forms/ViewForms.js";
import RegisterForm from "./LoginDashboard/components/Forms/RegisterForm.js";
import ListForms from "./LoginDashboard/components/Forms/ListForms.js";
import WriteBlogs from "./LoginDashboard/components/Blogs/WriteBlogs.js";
import ViewBlogs from "./LoginDashboard/components/Blogs/ViewBlogs.js";
import BlogsHeader from "./LoginDashboard/components/Blogs/BlogsHeader.js";
import UploadVideosHeader from "./LoginDashboard/components/Uploadvideos/UploadVideosHeader.js";
import UploadVideos from "./LoginDashboard/components/Uploadvideos/UploadVideos.js";
import ViewVideos from "./LoginDashboard/components/Uploadvideos/ViewVideos.js";
import Feed from "./LoginDashboard/components/Feed/Feed.js";
import AddOnPromoCodeHeader from "./LoginDashboard/components/Add-onPromoCode/Add-onPromoCodeHeader.js";
import CreateAddOnCode from "./LoginDashboard/components/Add-onPromoCode/CreateAddOnCode.js";
import ViewAddOnCode from "./LoginDashboard/components/Add-onPromoCode/ViewAddOnCode.js";
import CreatePromoCode from "./LoginDashboard/components/Add-onPromoCode/CreatePromoCode.js";
import ViewPromoCode from "./LoginDashboard/components/Add-onPromoCode/ViewPromoCode.js";
import FormInBoxHeader from "./LoginDashboard/components/FormInBox/FormInBoxHeader.js";
import ViewFormInBox from "./LoginDashboard/components/FormInBox/ViewFormInBox.js";
import TransactionsHeader from "./LoginDashboard/components/Transactions/TransactionsHeader.js";
import Tickets from "./LoginDashboard/components/Transactions/Tickets.js";
import TransactionSeasonTickets from "./LoginDashboard/components/Transactions/SeasonTickets.js";
import VideoPurchased from "./LoginDashboard/components/Transactions/VideoPurchased.js";
import { AuthProvider } from "./context/AuthContext.js";
import PrivateRoute from "./utils/PrivateRoute.js";
import VerificationRegistration from "./Auth/VerificationRegistration.js";
import UpdatePassword from "./Auth/UpdatePassword.js";
import ArtistPerticularDetails from "./Pages/Artists/components/ArtistPerticularDetails.js";
import ArtistProfile from "./Pages/Artists/components/ArtistProfile.js";
import About from "./assets/FooterTermsPage/About.js";
import Cancellation from "./assets/FooterTermsPage/Cancellation.js";
import Contact from "./assets/FooterTermsPage/Contact.js";
import Faqs from "./assets/FooterTermsPage/Faqs.js";
import PrivacyPolicy from "./assets/FooterTermsPage/PrivacyPolicy.js";
import Terms from "./assets/FooterTermsPage/Terms.js";
import AllRecords from "./Pages/MDnDRecords/AllRecords.js";
import SeasonEvents from "./LoginDashboard/components/SeasonEvents/SeasonEvents.js";
import ViewSeasonEvents from "./LoginDashboard/components/SeasonEvents/viewSeasonEvents.js";
import SeasonHeader from "./LoginDashboard/components/SeasonEvents/SeasonHeader.js";
import MyProfile from "./LoginDashboard/components/Profile/MyProfile.js";
import Invoice from "./Pages/invoice/invoice.js";
import CreateSeatingHeader from "./LoginDashboard/components/createSeatingLayouts/createSeatingLayoutHeader.js";
import CreateSeatingLayouts from "./LoginDashboard/components/createSeatingLayouts/createSeatingLayouts.js";
import SavedDrafts from "./LoginDashboard/components/Events/savedDrafts.js";
import DashBoardNotifications from "./LoginDashboard/components/Dashboard/components/DashBoardNotifications.js";
import DashBoardMyFavourites from "./LoginDashboard/components/Dashboard/components/DashBoardMyFavourites.js";
import AdsHeader from "./LoginDashboard/components/Ads/AdsHeader.js";
import Ads from "./LoginDashboard/components/Ads/Ads.js";
import ViewAds from "./LoginDashboard/components/Ads/ViewAds.js";
import { useNavigate } from "react-router-dom";
import MailHeader from "./LoginDashboard/components/Mailers/MailHeader.js";
import MailMember from "./LoginDashboard/components/Mailers/Members/Members.js";
import Templates from "./LoginDashboard/components/Mailers/Templates/Templates.js";
import History from "./LoginDashboard/components/Mailers/History.js";
import Mailer from "./LoginDashboard/components/Mailers/Mailer/Mailer.js";
import Stream from "./Pages/StreamMedia/Stream.js";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import NumberedSeatingPurchase from "./Pages/numbered_seating_purchase/numberedSeatingPurchase.js";
import SavedDraftsForms from "./LoginDashboard/components/Forms/SavedDraftsForms.js";
import ViewTicketDetails from "./Pages/viewTicketDetails/viewTicketDetails.js";
import AllRecordsTest from "./MDnDRecords/Vimeolikn/AllRecordsTest.js";
import RagaBankTest from "./RagaBank/RagaBankTest.js";
import RagaBankVimeo from "./RagaBank/RagaBankVimeo.js";

import TestPage from "./testPage.js";
import axios from "axios";
import { BASEPATH } from "./config/config.js";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { customEncrypt } from "./utils/encryptAndDecrypt.js";
import RagaBankVimeoVideo from "./RagaBank/RagaBankVimeoVideo.js";
import { SHAREBASEPATH_API } from "./config/config.js";
import { useSearchParams } from "react-router-dom";
import { SHARE_BASEPATH } from "./config/config.js";
import getEventKeys from "./Api/getEventKeys.js";

import { useLocation } from "react-router-dom";
import getSeasonTicketKey from "./Api/getSeasonTicketKey.js";
import LandingPageHeader from "./LoginDashboard/components/LandingPageDetails/LandingPageHeader.tsx";
import CreateLandingPageDetails from "./LoginDashboard/components/LandingPageDetails/LandingPageDetails.js";
import config from "devextreme/core/config";
//import { licenseKey } from "./config/devextremeLicence.tsx";
import SeasonOrganizerLandingPage from "./SeasonTickets/SeasonOrganizerLandingPage.js";
import Fan from "./Subpages/Fan/Fan.js";

import ViewSeasonTicketDetails from "./Pages/viewTicketDetails/viewSeasonTicketDetails.js";
import SuccessFormPage from "./LoginDashboard/components/Forms/SuccessFormPage.js";
import ViewSeasonTicketsMultiple from "./Pages/viewTicketDetails/ViewSeasonTicketsMultiple.js";
//Raga Riddle
import Firstpage from "./Pages/RagaRiddle/Firstpage.jsx";
import Playbyraganame from "./Pages/RagaRiddle/Playbyraganame.jsx";
import Playbyswara from "./Pages/RagaRiddle/Playbyswara.jsx";

import ViewTicketPdf from "./Pages/viewTicketDetails/ViewTicketPdf.js";
import SuccessRegisterPage from "./Auth/SuccessRegisterPage.js";

import SeasonSavedDrafts from "./LoginDashboard/components/SeasonEvents/SeasonSavedDrafts.jsx";
import EventsFilterPage from "./Pages/Filterpage/EventsFilterPage.js";
import EditNumberedLayout from "./LoginDashboard/components/Events/EditNumberedLayout.jsx";

import EditSeatingLayout from "./LoginDashboard/components/createSeatingLayouts/editSeatingLayout.jsx";
const licenseKey = process.env.REACT_APP_DEVEXTREME_LICENCE_KEY;
config({ licenseKey });

function App() {
  const SEASONTICKETCOUNTRYKEY = getSeasonTicketKey().SEASONTICKET_KEY;
  const SEASONEVENTKEY = getSeasonTicketKey().SEASONEVENT_KEY;

  const encodeBase64 = (str) => {
    const prefix = "xy4AT";
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  const encodeBase64_new = (str, key) => {
    const prefix = key?.toString();
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  function ExternalRedirect() {
    const navigate = useNavigate();
    const { slug } = useParams();
    useEffect(() => {
      if (slug) {
        try {
          const decodedString = atob(slug);
          const [someValue, anotherValue, ci, e] = decodedString.split("#");

          if (someValue === "9838") {
            navigate(
              `/event/Evergreen_Melodies_of_Bharat_Ratna_Smt_M_S_Subbulakshmi_-_Kaatrinile_Varum_Geetham_by_Dr._Nithyasree_Mahadevan?ci=${encodeBase64(
                101
              )}&e=${encodeBase64(2)}&editEvent=cXI=`
            );
          } else if (someValue === "9986") {
            navigate(
              `/event/ProductDetails?ci=${encodeBase64(101)}&e=${encodeBase64(
                1
              )}&editEvent=cXI=`
            );
          } else if (someValue === "9846") {
            navigate(
              `/event/Indian_Premiere_of_F._J._Haydn’s_oratorio_“THE_CREATION”?ci=${encodeBase64(
                101
              )}&e=${encodeBase64(54)}&editEvent=cXI=`
            );
          } else if (someValue === "9837") {
            navigate(
              `/event/Special_Concert_-_Maestro_Ilayaraaja_Golden_Hits_by_Melody_Queens?ci=${encodeBase64(
                101
              )}&e=${encodeBase64(41)}&editEvent=cXI=`
            );
          } else if (someValue === "9836") {
            navigate(
              `/event/Hits_of_Harris_-_Veenai_in_Nayagan_-_Rajhesh_Vaidhya_Live_in_Concert?ci=${encodeBase64(
                101
              )}&e=${encodeBase64(44)}&editEvent=cXI=`
            );
          } else if (someValue === "10083") {
            navigate(
              `/event/ProductDetails?ci=${encodeBase64(101)}&e=${encodeBase64(
                4
              )}&editEvent=cXI=`
            );
          } else if (someValue === "9770") {
            navigate(
              `/event/Indian_Premiere_of_F._J._Haydn’s_oratorio_“THE_CREATION”?ci=${encodeBase64(
                101
              )}&e=${encodeBase64(54)}&editEvent=cXI=`
            );
          } else if (someValue === "7294") {
            navigate(`/events`);
          }
        } catch (error) {
          console.error("Error decoding the slug:", error);
        }
      }
    }, [slug]);
    return null;
  }

  function ExternalFormRedirect() {
    const { formId, anotherId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
      if (anotherId === "3659") {
        navigate(
          "/Form/Registration_form_-_Kala_Vidya_-_Music_competition_for_the_differently-abled?fid=eHk0QVQ1Mg=="
        );
      }
    }, [anotherId, navigate]);
    return null;
  }

  //SEASON TICKET REDIRECT.
  const encodeBase64_season = (str, key) => {
    const prefix = key?.toString();
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  function ExternalSeasonRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const searchParams = new URLSearchParams(location?.search);
      const ci = searchParams?.get("ci");
      const se = searchParams?.get("se");

      if (ci && se) {
        const encodedCi = encodeBase64_season(ci, SEASONTICKETCOUNTRYKEY);
        const encodedSe = encodeBase64_season(se, SEASONEVENTKEY);

        if (ci == 101 && se == 16) {
          const redirectUrl = `/SeasonTickets/BRAHMA_GANA_SABHA'S_HISTORICAL_DRAMA_FESTIVAL'_?ci=${encodedCi}&se=${encodedSe}`;
          navigate(redirectUrl, { replace: true });
        } else {
          const redirectUrl = `/SeasonTickets/SeasonEvent?ci=${encodedCi}&se=${encodedSe}`;
          navigate(redirectUrl, { replace: true });
        }
      }
    }, [location, navigate]);

    return null;
  }

  function BasicEventRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/events`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function IndexRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalArtistRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/artist`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalOrganiserRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/organisers`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalMDNDrecordsRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/MDnDRecords`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalSeasonFilterpage() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/SeasonTickets/?ci=101&c=3659&eMode=Offline&Date=All&pr=All`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalragaBankRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/RagaBank`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalBlogsRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalPrivacyRedirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      const redirectUrl = `/PrivacyPolicy`;

      navigate(redirectUrl, { replace: true });
    }, []);
  }

  function ExternalRagaRiddleRedirect(){
    const navigate = useNavigate();

    
    useEffect(()=>{
      const redirectUrl = `/ragariddle`;
      navigate(redirectUrl, { replace: true });
    },[]);
  }

  return (
    <>
      <HelmetProvider>
        <ThemeProvider>
          <Router>
            <AuthProvider>
              <Header />
              {/* <ErrorBoundary fallback={<div>Something went wrong!</div>}> */}
              <div className="overallMinHeight">
                <Routes>
                  <Route path="*" element={<PageNotFound />} />
                  <Route
                    path="/"
                    element={
                      <>
                        <Landing />
                      </>
                    }
                  />
                  <Route
                    path="/newevent/viewevent/:slug"
                    element={<ExternalRedirect />}
                  />
                  <Route path="/event" element={<BasicEventRedirect />} />
                  <Route
                    path="/event/newevent"
                    element={<BasicEventRedirect />}
                  />
                  <Route
                    path="/event/view/:2063"
                    element={<BasicEventRedirect />}
                  />
                  <Route
                    path="/event/seeevents/:slug"
                    element={<BasicEventRedirect />}
                  />
                  <Route
                    path="/events/online"
                    element={<BasicEventRedirect />}
                  />
                  <Route path="/index.html" element={<IndexRedirect />} />
                  <Route path="/artists" element={<ExternalArtistRedirect />} />
                  <Route
                    path="/artists/:SandhyaShankar"
                    element={<ExternalArtistRedirect />}
                  />
                  <Route
                    path="/artistlistall/Carnatic_3_junior"
                    element={<ExternalArtistRedirect />}
                  />
                  <Route
                    path="/artist/wall"
                    element={<ExternalArtistRedirect />}
                  />
                  <Route
                    path="/profile/:AmrithNarayan/:3567/artist"
                    element={<ExternalArtistRedirect />}
                  />
                  <Route
                    path="/profile/:6StringsLiveBand/:393/organizer"
                    element={<ExternalOrganiserRedirect />}
                  />
                  <Route
                    path="/mdndrecords/index"
                    element={<ExternalMDNDrecordsRedirect />}
                  />
                  <Route
                    path="/season_ticket/mutipleSeason/:OTAjMzUjMTAx"
                    element={<ExternalSeasonFilterpage />}
                  />
                  <Route
                    path="/ragabank/index"
                    element={<ExternalragaBankRedirect />}
                  />
                  <Route
                    path="viewEvents/:categoryname"
                    element={<EventsFilterPage />}
                  />
                  <Route path="/blogs" element={<ExternalBlogsRedirect />} />
                  <Route
                    path="/bloglist/all/Carnatic"
                    element={<ExternalBlogsRedirect />}
                  />
                  <Route
                    path="/blogs/w/:Shankar20Venkataraman/artist/:3303/:RGVlcGx5IHJvb3RlZCBpbiB0cmFkaXRpb24=/:NDc="
                    element={<ExternalBlogsRedirect />}
                  />
                  <Route
                    path="/tutorial/:9"
                    element={<ExternalBlogsRedirect />}
                  />
                  <Route path="/video/:5" element={<ExternalBlogsRedirect />} />
                  <Route
                    path="/venue/:1/:rajaannamalaichettiarhall"
                    element={<ExternalBlogsRedirect />}
                  />
                  <Route
                    path="/home/organiser/:brahmaganasabha"
                    element={<ExternalSeasonFilterpage />}
                  />
                  <Route
                    path="/youngartists"
                    element={<ExternalArtistRedirect />}
                  />
                  <Route
                    path="/privacy"
                    element={<ExternalPrivacyRedirect />}
                  />
                  <Route
                    path="/registration/form/:formId/:anotherId"
                    element={<ExternalFormRedirect />}
                  />
                  <Route
                    path="/:shabaName/:encryptedData"
                    element={<TestPage />}
                  />
                  <Route path="/Login" element={<Front />} />
                  <Route path="/Register" element={<Front />} />
                  <Route path="/ViewTicketPdf" element={<ViewTicketPdf />} />
                  <Route path="/ragariddle" element={<Firstpage />} />{" "}
                  <Route
                    path="/ragariddle/FirstPage"
                    element={<ExternalRagaRiddleRedirect />}
                  />
                  {/* Raga Riddle */}
                  <Route
                    path="/ragariddle/playbyraganame"
                    element={<Playbyraganame />}
                  />
                  <Route
                    path="/ragariddle/playbyswara"
                    element={<Playbyswara />}
                  />
                  <Route path="/RegisterHere" element={<RegisterInHeader />} />
                  <Route path="/forgotpassword" element={<Forgot />} />
                  <Route path="/updatePassword" element={<UpdatePassword />} />
                  {/* newly changed here  */}
                  <Route path="/GlobalSearch" element={<GlobalSearch />} />
                  <Route path="/artist" element={<Artist />} />
                  <Route
                    path="/SeasonTickets/organiserListing"
                    element={<SeasonOrganizerLandingPage />}
                  />
                  <Route
                    path="/viewTicketDetails"
                    element={<ViewTicketDetails />}
                  />
                  <Route
                    path="/viewSeasonTicketDetails"
                    element={<ViewSeasonTicketDetails />}
                  />
                  {/*newly added */}
                  <Route
                    path="/viewSeasonTicketsMultiple"
                    element={<ViewSeasonTicketsMultiple />}
                  />
                  {/*new change */}{" "}
                  <Route path="/Creators" element={<ArtistProfile />} />
                  <Route
                    path="/artist/:artistProfile"
                    element={<ArtistPerticularDetails />}
                  />
                  <Route path="/Fan/:fanName" element={<Fan />} />
                  <Route path="/successForm" element={<SuccessFormPage />} />
                  <Route
                    path="/successRegister"
                    element={<SuccessRegisterPage />}
                  />
                  <Route path="/events" element={<Event />} />
                  <Route path="Success" element={<Success />} />
                  <Route path="Failed" element={<Failed />} />
                  <Route
                    path="MyProfile"
                    element={
                      <PrivateRoute>
                        <MyProfile />
                      </PrivateRoute>
                    }
                  />
                  {/* newly added    */}
                  <Route
                    path="MyProfile/:divid"
                    element={
                      <PrivateRoute>
                        <MyProfile />
                      </PrivateRoute>
                    }
                  />
                  {/* end of newly added */}
                  {/* newly added on sep 4  */}
                  <Route path="Form/:formName" element={<RegisterForm />} />
                  <Route path="SpecificPost" element={<SpecificPost />} />
                  <Route
                    path="ListForms"
                    element={
                      <PrivateRoute>
                        <ListForms />
                      </PrivateRoute>
                    }
                  />
                  {/* PRIVATE ROUTES */}
                  <Route
                    path=""
                    element={
                      <PrivateRoute>
                        <LoginDashboard />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path="dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="dashboard/notifications"
                      element={
                        <PrivateRoute>
                          <DashBoardNotifications />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="dashboard/myFavourites"
                      element={
                        <PrivateRoute>
                          <DashBoardMyFavourites />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="feed"
                      element={
                        <PrivateRoute>
                          <Feed />
                        </PrivateRoute>
                      }
                    ></Route>

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <SeasonHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="SeasonEvents"
                        element={
                          <PrivateRoute>
                            <SeasonEvents />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="ViewSeasonEvents"
                        element={
                          <PrivateRoute>
                            <ViewSeasonEvents />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="/SeasonSavedDrafts"
                        element={
                          <PrivateRoute>
                            <SeasonSavedDrafts />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    {
                      /* Create Landing page */

                      <Route
                        path=""
                        element={
                          <PrivateRoute>
                            <LandingPageHeader />
                          </PrivateRoute>
                        }
                      >
                        <Route
                          path="createLandingPage"
                          element={
                            <PrivateRoute>
                              <CreateLandingPageDetails />
                            </PrivateRoute>
                          }
                        />
                      </Route>
                    }

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <EventsHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="createEvents"
                        element={
                          <PrivateRoute>
                            <CreateEvents />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="viewEvents"
                        element={
                          <PrivateRoute>
                            <ViewEvents />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="savedDrafts"
                        element={
                          <PrivateRoute>
                            <SavedDrafts />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <TransactionsHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="tickets"
                        element={
                          <PrivateRoute>
                            <Tickets />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="TransactionSeasonTickets"
                        element={
                          <PrivateRoute>
                            <TransactionSeasonTickets />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="videoPurchased"
                        element={
                          <PrivateRoute>
                            <VideoPurchased />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route path="" element={<FormsHeader />}>
                      <Route
                        path="createForms"
                        element={
                          <PrivateRoute>
                            <CreateForms />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="viewForms"
                        element={
                          <PrivateRoute>
                            <ViewForms />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="draftForms"
                        element={
                          <PrivateRoute>
                            <SavedDraftsForms />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <BlogsHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="writeBlogs"
                        element={
                          <PrivateRoute>
                            <WriteBlogs />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="viewBlogs"
                        element={
                          <PrivateRoute>
                            <ViewBlogs />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <UploadVideosHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="uploadVideos"
                        element={
                          <PrivateRoute>
                            <UploadVideos />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="viewVideos"
                        element={
                          <PrivateRoute>
                            <ViewVideos />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                    {/* -----------------------------------------------------Mailer---------------------------------------------- */}
                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <MailHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="Mailer"
                        element={
                          <PrivateRoute>
                            <Mailer />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="Tamplates"
                        element={
                          <PrivateRoute>
                            <Templates />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="Members"
                        element={
                          <PrivateRoute>
                            <MailMember />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="History"
                        element={
                          <PrivateRoute>
                            <History />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                    {/* ------------------------------------------------------------------------------------------------------------------ */}
                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <UploadVideosHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="uploadVideos"
                        element={
                          <PrivateRoute>
                            <UploadVideos />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="viewVideos"
                        element={
                          <PrivateRoute>
                            <ViewVideos />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <AddOnPromoCodeHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="createAddOnCode"
                        element={
                          <PrivateRoute>
                            <CreateAddOnCode />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="viewAddOnCode"
                        element={
                          <PrivateRoute>
                            <ViewAddOnCode />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="createPromoCode"
                        element={
                          <PrivateRoute>
                            <CreatePromoCode />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="viewPromoCode"
                        element={
                          <PrivateRoute>
                            <ViewPromoCode />
                          </PrivateRoute>
                        }
                      />
                    </Route>

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <FormInBoxHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="viewFormInBox"
                        element={
                          <PrivateRoute>
                            <ViewFormInBox />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                    {/* ------------------------------------------------Mailer----------------------------------------------------------- */}
                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <MailHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="Mailer"
                        element={
                          <PrivateRoute>
                            <Mailer />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="Tamplates"
                        element={
                          <PrivateRoute>
                            <Templates />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="Members"
                        element={
                          <PrivateRoute>
                            <MailMember />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="History"
                        element={
                          <PrivateRoute>
                            <History />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                    {/* ---------------------------------------------------------------------------------------------------- */}
                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <AdsHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="Ads"
                        element={
                          <PrivateRoute>
                            <Ads />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path="ViewAds/:base64"
                        element={
                          <PrivateRoute>
                            <ViewAds />
                          </PrivateRoute>
                        }
                      />
                      {/* <Route
                      path="UploadAds"
                      element={
                        <PrivateRoute>
                          <UploadAds />
                        </PrivateRoute>
                      }
                    /> */}
                    </Route>

                    {/* Create seating layout */}

                    <Route
                      path=""
                      element={
                        <PrivateRoute>
                          <CreateSeatingHeader />
                        </PrivateRoute>
                      }
                    >
                      <Route
                        path="createSeatingLayout"
                        element={
                          <PrivateRoute>
                            <CreateSeatingLayouts />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path="editSeatingLayout"
                        element={
                          <PrivateRoute>
                            <EditSeatingLayout />
                          </PrivateRoute>
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="EditNumberedLayout"
                    element={
                      <PrivateRoute>
                        <EditNumberedLayout />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/franchisees" element={<Franchisees />} />
                  <Route
                    path="/franchisees/:artistProfile"
                    element={<ProfileSingle />}
                  />
                  <Route path="/organisers" element={<Organisers />} />
                  <Route
                    path="/organiser/:artistProfile"
                    element={<ProfileSingle />}
                  />
                  <Route path="/stream/:slug" element={<Stream />} />
                  {/* <Route path="viewEvents" element={<ListingEvents />}>
                  
                    <Route
                      path=":selectedEvent"
                      element={<ListingEvents />}
                    ></Route>
                  </Route> */}
                  {/* changed here  */}
                  <Route path="event/:title" element={<ProductDetails />} />
                  <Route
                    path="events/numberedSeatings"
                    element={<NumberedSeatingPurchase />}
                  />
                  <Route path="/invoice" element={<Invoice />} />
                  <Route path="/checkOut" element={<ProductSummary />} />
                  <Route
                    path="/ProductCountdown"
                    element={<ProductCountdown />}
                  />
                  {/* <Route path="SeasonTickets" element={<SeasonTickets />} /> */}
                  <Route
                    path="SeasonTickets/"
                    element={<SeasonOrganizerLandingPage />}
                  />
                  <Route
                    path="SeasonTickets/:seasonEventDetails"
                    element={<SeasonEventDetails />}
                  />
                  <Route
                    path="SeasonTickets/seasonEventDetails"
                    element={<ExternalSeasonRedirect />}
                  />
                  <Route
                    path="SeasonTickets/OrganizerLandingPage"
                    element={<OrganizerLandingPage />}
                  />
                  <Route path="/MDndStore" element={<MDndStore />} />
                  <Route path="/myCart" element={<AddtoCart />} />
                  <Route path="/VideoStore" element={<VideoStore />} />
                  {/* -----------------------OriginalCDN------------------------------ */}
                  <Route path="/MDnDRecords" element={<AllRecords />} />
                  <Route
                    path="/MDnDRecords/:category"
                    element={<MDnDRecords />}
                  />
                  <Route
                    path="/RecordsVideo/videos/:slug"
                    element={<RecordsVideo />}
                  />
                  {/* -------------------------ForVimeoLink----------------------------*/}
                  <Route path="/MDndRecordsTest" element={<AllRecordsTest />} />
                  <Route
                    path="/MDnDRecordsTest/:category"
                    element={<MDnDRecordsV />}
                  />
                  <Route
                    path="/RecordsVideo/vimeo/:slug"
                    element={<RecordsVimeo />}
                  />
                  {/* -------------------------------------------------------------- */}
                  <Route path="/RagaBank" element={<RagaBankVimeo />} />
                  <Route
                    path="/RagaBank/:song_title/:slug"
                    element={<RagaBankVimeoVideo />}
                  />
                  <Route
                    path="/RagaBank/filter/:slug"
                    element={<RagaBankVimeo />}
                  />
                  <Route path="/RagaBankTest" element={<RagaBankTest />} />
                  <Route
                    path="/RagaTest/:song_title/:slug"
                    element={<RagaBankVideo />}
                  />
                  <Route path="/MDnDLive" element={<MDnDLive />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route
                    path="/blogs/:artistProfile"
                    element={<Blogfourcolmn />}
                  />
                  <Route path="/blog/:title" element={<SingleBlog />} />
                  <Route
                    path="/confirmRegistration"
                    element={<Registration />}
                  />
                  <Route path="/About" element={<About />} />
                  <Route path="/Cancellation" element={<Cancellation />} />
                  <Route path="/Contact" element={<Contact />} />
                  <Route path="/Faqs" element={<Faqs />} />
                  <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                  <Route path="/Terms" element={<Terms />} />
                  {/* <Route path="/test" element={<ToggleButton/>}/> */}
                </Routes>
              </div>
              <Footer />
              {/* </ErrorBoundary> */}
            </AuthProvider>
          </Router>
        </ThemeProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
