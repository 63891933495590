import getHostAPIUrl from "../Api/getHostApiUrl";
export const BASEPATH = getHostAPIUrl().basePath;
export const ADMIN_BACK_BASEPATH = `https://adminapi.evrythingez.com/`;
export const RAGABANK_IMAGEPATH = `${ADMIN_BACK_BASEPATH}public/images/ragabank_thumbnails`;
export const ADMIN_BASEPATH = `http://192.168.0.42:8002/`;
const ViewEventImagePath = `public/images/event_images/`;
export const SHARE_BASEPATH = getHostAPIUrl().sharePath;
export const SHAREBASEPATH_API = getHostAPIUrl().basePath;
export const LogoPath = `${BASEPATH}public/qrcode/`;

// Free Online stream
export const FREE_ONLINE_STREAM = (base64UrlEncrypt) =>
  `https://mdnd.in/stream/${base64UrlEncrypt()}`;
// CDN
const MDnDUploadRecordsCDN = `https://origin.mdndrecords.in/upload`;
export { ViewEventImagePath, MDnDUploadRecordsCDN };
