import React, { useState, useEffect } from 'react';
import './Stream.css';

function StreamCountDownPage({ targetDate, itsData, targetTime, onEventStart }) {
  const targetDateTime = new Date(`${targetDate}T${targetTime}`);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDateTime));

  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetDateTime);
      setTimeLeft(newTimeLeft);  
      if (newTimeLeft.total <= 0) {
        clearInterval(timer); 
        onEventStart(true); 
      }
    }, 1000);

    return () => clearInterval(timer); 
  }, [targetDateTime, onEventStart]);

  function calculateTimeLeft(targetDateTime) {
    const now = new Date();
    const difference = targetDateTime - now;

    if (difference <= 0) {
      return { total: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const total = difference;
    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return { total, days, hours, minutes, seconds };
  }

  return (
    <div>
      <div className="section-padding height-auto-100 image-flip-rtl countDownImg">
        <h2 className="fw-500 mb-0 text-center heading-primary">
          {itsData?.event_title}
        </h2>
        <div className="container h-100">
          <div className="d-flex flex-column justify-content-center h-100">
            <h3 className="fw-500 mb-0 text-center text-md-center heading-secondary">Countdown to event</h3>
            <ul className="countdown d-flex justify-content-center list-inline count-down my-5 py-3">
              <li className="position-relative d-inline-lock">
                <span className="numberDisplay text-accent">{timeLeft.days}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-light">Days</span>
              </li>
              <li className="position-relative d-inline-block">
                <span className="numberDisplay text-accent">{timeLeft.hours}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-light">Hours</span>
              </li>
              <li className="position-relative d-inline-block">
                <span className="numberDisplay text-accent">{timeLeft.minutes}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-light">Minutes</span>
              </li>
              <li className="position-relative d-inline-block">
                <span className="numberDisplay text-accent">{timeLeft.seconds}</span>
                <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-light">Seconds</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StreamCountDownPage;
