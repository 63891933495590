
import React from "react";
import "./NonNumberedDetails_master.css";
import { useState, useEffect } from "react";

import DataGrid, {
    Column,
    Paging,
    SearchPanel,
    Scrolling,
    Summary,
    TotalItem

} from 'devextreme-react/data-grid';

export default function NonNumberedDetails_master({ data }) {

    const [totalsum, settotalsum] = useState(0);



    const handleTicketsSold = (rowData) => {


        let tkts_sold = Number(rowData?.data?.total_quantity) - Number(rowData?.data?.balance_tickets);

        return tkts_sold;


    }

    const handleTotalAmountSold = (rowData) => {
        let total_amt_sold = Number(rowData?.data.amount) * (Number(rowData?.data?.total_quantity) - Number(rowData?.data?.balance_tickets))

        return total_amt_sold;
    }

    useEffect(() => {
        if (data?.data?.non_numbered_details) {
            let sum_amt =0;

            for(let i=0;i<data?.data?.non_numbered_details?.length;i++){
                let obj= data?.data?.non_numbered_details[i];
    
                let tkt_sold = Number(obj.total_quantity) - Number(obj.balance_tickets);
                let initial_amt = tkt_sold && tkt_sold * Number(obj.amount);
                sum_amt += initial_amt;
            }

            if(sum_amt){
                settotalsum(sum_amt);
            }
    
      
        }

    }, [data?.data?.non_numbered_details])



    return (
    <>
    <div
        className="d-flex justify-content-center"
    >
        <DataGrid
            dataSource={data?.data?.non_numbered_details || null}
            showBorders={true}
            columnAutoWidth={true}
            keyExpr="event_id"
            columnHidingEnabled={false}
            rowAlternationEnabled={true}
            
            allowColumnResizing={true}
            wordWrapEnabled={true}
            className="SubGridWith"
        >
            {/* <SearchPanel
                visible={false}
                allowSearch={true}
                allowFiltering={true}
            /> */}
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Column
                dataField="row_name"
                caption="Row Name"
                alignment="left"
            />
            <Column
                dataField="amount"
                caption="Denomination"
                alignment="right"
            />
            <Column
                dataField="total_quantity"
                caption="Total Quantity"
                alignment="right"
            />
            <Column
                caption="Tickets Sold"
                cellRender={handleTicketsSold}
                alignment="right"
            />
            <Column
                dataField="balance_tickets"
                caption="Balance Tickets"
                alignment="right"
            />
            <Column
                caption="Amount Sold"
                cellRender={handleTotalAmountSold}
                alignment="right"
            >

            </Column>


        </DataGrid>
       



    </div>
    <div className=" d-flex justify-content-center mt-4">
    <div className="d-flex justify-content-end SubGridWith">Total : <b>{totalsum}</b></div>
    </div>
    </>)
}