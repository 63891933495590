import { useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import Button from "react-bootstrap/Button";
import {
  SelectBox,
  TextBox,
  RadioGroup,
  DateBox,
  TagBox,
} from "devextreme-react";
import PhoneInput from "react-phone-input-2";
import "./styles/Login.css";
import {
  RegistrationRole,
  FAN_ROLE,
  ORGANISER_ROLE,
  ARTIST_ROLE,
} from "../global/constants";
import { RegistrationSchema } from "../Schemas/Forms/Auth.js";
import { PROFILE_REGISTRATION } from "../Api/api";
import { DURATION, SUCCESS, ERROR } from "../global/ToastMsgConstants.tsx";
import notify from "devextreme/ui/notify";
import { makeGetRequest } from "../Api/requestMethods";
import { COUNTRY } from "../Api/api";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import {
  stateFetch,
  cityFetch,
} from "../LoginDashboard/components/Events/EventsFetch";
import {
  EVENT_MAIN_CATEGORY,
  ARTIST_MAIN_CATEGORIES,
  ARTIST_SUB_CATEGORIES,
} from "../Api/api";
import { subCategoryFetch } from "../LoginDashboard/components/Events/EventsFetch";
import { REGISTRATION_SCHEMA } from "../Schemas/Validations/Auth.js";
import { GENDER } from "../global/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { PROFILE_URL } from "../Api/api";
import { SOMETHING_WENT_WRONG } from "../Schemas/errorMessageConstants.js";
import { makePostRequest } from "../Api/requestMethods";
import { DATE_MONTH_YEAR } from "../utils/dateFormatUtils.js";
import AuthContext from "../context/AuthContext.js";
import { PREFERENCE_MUST } from "../Schemas/errorMessageConstants.js";
import { jwtDecode } from "jwt-decode";

//NEWLY ADDED

import { BASEPATH } from "../config/config.js";
import axios from "axios";
import {
  COUNTRIES_FROM_BACKEND,
  STATES_FROM_BACKEND,
  CITIES_FROM_BACKEND,
  POST_ADVERTISER,
} from "../Api/api";
import HandleErrorToast from "../Toaster/Failure/Failure.js";

// END OF NEWLY ADDED

export default function Registration() {
  const phoneRef = useRef(null);
  const { user, setUser, authTokens, setAuthTokens, logoutUser } =
    useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const loginId = searchParams.get("loginId");
  const token = localStorage.getItem("MDnD_auth");
  const [Preference, setPreference] = useState([]);
  let rolename = location?.state?.rolename;
  let loginid_user = location?.state?.loginid_user;
  const capitalizeFirstLetter = (string) => {
    return string && string.charAt(0)?.toUpperCase() + string?.slice(1);
  };
  let rolename_caps = capitalizeFirstLetter(rolename);
  const [settings, setSettings] = useState({
    initialValues: RegistrationSchema()["Fan"].body,
    roleOption: "",
    requestURL: "",
    countryFocusIn: false,
    genderEnable: false,
    hasFocusedIn: false,
    isCategoriesEnabled: false,
    isDobEnabled: false,
    isPreferenceEnabled: false,
    dummy: false,
  });

  const [dataSource, setDataSource] = useState({
    stateDataSource: "",
    cityDataSource: "",
    subCategoryDataSource: "",
    tempUser: "",
  });

  useEffect(() => {
    if (
      dataSource.tempUser &&
      dataSource.tempUser.country_code &&
      dataSource.tempUser.mobile
    ) {
      const countryCode = dataSource.tempUser.country_code;
      const phoneNumber = dataSource.tempUser.mobile;
      setFieldValue("mobile", `+${countryCode}${phoneNumber}`);
    }
  }, [dataSource.tempUser]);

  // newly added by vignesh..
  useEffect(() => {
    if (dataSource.tempUser && dataSource.tempUser.name) {
      setFieldValue("name", dataSource.tempUser.name);
    }
  }, [dataSource.tempUser]);

  //changed here in this useEffect..... added loginid_user
  useEffect(() => {
    if (loginId) {
      profileUrlMutation.mutate(loginId);
    } else if (loginid_user) {
      profileUrlMutation.mutate(loginid_user);
    }
  }, [loginId, loginid_user]);

  const profileUrlEvent = async (loginId) =>
    await makeGetRequest(PROFILE_URL(loginId));

  const profileUrlMutation = useMutation({
    mutationFn: (loginId) => profileUrlEvent(loginId),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, tempUser: data?.data }));
    },
    onError: (error) => {
      notify(
        error?.response?.data?.message || SOMETHING_WENT_WRONG,
        ERROR,
        DURATION
      );
      navigate("/");
    },
  });

  useEffect(() => {
    if (settings.roleOption && settings.roleOption === ARTIST_ROLE) {
      setSettings((prev) => ({
        ...prev,
        genderEnable: true,
        isCategoriesEnabled: true,
        isDobEnabled: true,
        isPreferenceEnabled: false,
      }));
    }
    if (settings.roleOption && settings.roleOption === ORGANISER_ROLE) {
      setSettings((prev) => ({
        ...prev,
        genderEnable: false,
        isCategoriesEnabled: false,
        isDobEnabled: false,
        isPreferenceEnabled: false,
      }));
    }
    if (settings.roleOption && settings.roleOption === FAN_ROLE) {
      setSettings((prev) => ({
        ...prev,
        genderEnable: true,
        isCategoriesEnabled: false,
        isDobEnabled: false,
        isPreferenceEnabled: true,
      }));
    }
  }, [settings.roleOption]);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setTouched,
    errors,
    touched,
    isSubmitting,
    setValues,
    setFieldValue,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: settings.initialValues,
    validationSchema: REGISTRATION_SCHEMA(settings.roleOption),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      if (
        settings.roleOption === FAN_ROLE &&
        Preference &&
        Preference.length === 0
      ) {
        notify(PREFERENCE_MUST, ERROR, DURATION);
        setSubmitting(false);
        return;
      }
      await createProfile(values);
      setSubmitting(false);
    },
  });

  const checkValidations = () => {
    setSettings((prev) => ({ ...prev, dummy: true }));
  };

  useEffect(() => {
    if (errors) {
      if (settings.roleOption === ARTIST_ROLE) {
        const newErrors = {
          artist_main_cat: "",
          artist_sub_cat: "",
          dob: "",
        };
        const errorFields = Object.keys(newErrors);
        const touchedFields = { ...touched };
        errorFields.forEach((field) => {
          touchedFields[field] = true;
        });

        setTouched(touchedFields);
      }
    }
  }, [settings.dummy]);

  const countryFocusIn = () => {
    setSettings((prev) => ({ ...prev, countryFocusIn: true }));
  };

  const countryEvent = async () =>
    settings.countryFocusIn && (await makeGetRequest(COUNTRY()));
  const { data: countryDataSource } = useQuery({
    queryKey: ["country"],
    queryFn: () => countryEvent(),
    enabled: settings.countryFocusIn,
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });

  const stateMutation = useMutation({
    mutationKey: ["fetchState"],
    mutationFn: (state_id) => stateFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, stateDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });

  const cityMutation = useMutation({
    mutationKey: ["fetchcity"],
    mutationFn: (state_id) => cityFetch(state_id),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, cityDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (
      values.country_id &&
      values.country_id !== "" &&
      values.country_id !== null
    ) {
      const body = {
        country_id: values.country_id,
      };
      stateMutation.mutate(body);
    }
  }, [values.country_id]);

  useEffect(() => {
    if (values.state_id && values.state_id !== "" && values.state_id !== null) {
      const body = {
        state_id: values.state_id,
      };
      cityMutation.mutate(body);
    }
  }, [values.state_id]);

  const handleOptionChange = (option) => {
    setErrors({});
    setSettings((prevSettings) => ({
      ...prevSettings,
      roleOption: option,
      initialValues: RegistrationSchema()[option]?.body,
      requestURL: PROFILE_REGISTRATION()[option]?.URL,
      dummy: false,
    }));
    handleChange({ target: { name: "roleOption", value: option } });

    //new code..
    setAdvertiserCollection((prevState) => ({
      ...prevState,
      advertiserName: "",
      advertiserEmail: "",
      mobile_test: "",
      mobile: "",
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      empty_validation_name: "",

      empty_validation_country: "",
      empty_validation_state: "",
      empty_validation_city: "",
    }));

    setTenRuleForMobile("");
  };

  const handleMobileBlur = (value) => {
    handleBlur({
      target: {
        name: "mobile",
        value: value,
      },
    });
  };

  const handleMobileChange = (value) => {
    if (phoneRef && phoneRef !== null) {
      const countryCode = phoneRef.current?.state?.selectedCountry?.countryCode;
      setFieldValue("country_code", `${countryCode}`);
    }
    handleChange({ target: { name: "mobile", value: value } });
  };

  const mainCategoryEvent = async () =>
    await makeGetRequest(EVENT_MAIN_CATEGORY);
  const {
    data: mainCategoryDataSource,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["RegmainCategory"],
    queryFn: () => mainCategoryEvent(),
    enabled: settings.roleOption === FAN_ROLE,
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });

  const ArtistMainCategoryEvent = async () =>
    await makeGetRequest(ARTIST_MAIN_CATEGORIES);
  const { data: ArtistmainCategoryDataSource } = useQuery({
    queryKey: ["ArtistmainCategory"],
    queryFn: () => ArtistMainCategoryEvent(),
    enabled: settings.hasFocusedIn,
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });

  const mutation = useMutation({
    mutationKey: ["RegfetchSubquery"],
    mutationFn: (mainCatId) =>
      values.artist_main_cat !== "" &&
      values.artist_main_cat !== null &&
      subCategoryFetch(mainCatId),
    onSuccess: (data) => {
      setDataSource((prev) => ({ ...prev, subCategoryDataSource: data?.data }));
    },
    onError: (error) => {
      notify(error.message, ERROR, DURATION);
    },
  });

  useEffect(() => {
    if (
      values.artist_main_cat &&
      values.artist_main_cat !== "" &&
      values.artist_main_cat !== null
    ) {
      const body = {
        maincatid: values.artist_main_cat,
      };
      mutation.mutate(body);
    }
  }, [values.artist_main_cat]);

  const handleFocusIn = (e) => {
    setSettings((prev) => ({ ...prev, hasFocusedIn: true }));
  };

  const preferenceEvent = (e) => {
    const categoryId = e?.currentTarget?.getAttribute("data-id");
    if (categoryId && !Preference.includes(Number(categoryId))) {
      setPreference([...Preference, Number(categoryId)]);
    } else {
      setPreference(Preference.filter((id) => id !== Number(categoryId)));
    }
  };

  //newly changed
  const createProfile = async (values) => {
    try {
      let nameKey = "";
      let emailKey = "";

      if (settings.roleOption === FAN_ROLE) {
        nameKey = "fan_name";
        emailKey = "fan_email";
      } else if (settings.roleOption === ORGANISER_ROLE) {
        nameKey = "organiser_name";
        emailKey = "email";
      } else if (settings.roleOption === ARTIST_ROLE) {
        nameKey = "artist_name";
        emailKey = "email";
      }
      values.fan_main_cat = Preference;

      if (values !== null) {
        const body = {
          //login_id: Number(loginId),
          login_id: loginid_user
            ? Number(loginid_user)
            : loginId
            ? Number(loginId)
            : null,
          [emailKey]: dataSource.tempUser.email,
          country_code: Number(
            dataSource.tempUser.country_code || `${values.country_code}`
          ),
          //[nameKey]: dataSource.tempUser.name,
          [nameKey]: values.name ? values.name : dataSource.tempUser.name,
          mobile: Number(dataSource.tempUser.mobile || values.mobile),
        };

        const updatedValues = {
          ...values,
          mobile: dataSource.tempUser.mobile,
          dob: DATE_MONTH_YEAR(new Date(values.dob)),
        };

        const newBody = { ...updatedValues, ...body };
        const response = await makePostRequest(settings.requestURL, newBody);
        if (response && response.status === SUCCESS) {
          setAuthTokens(response.MDnD_auth);
          setUser(jwtDecode(response.MDnD_auth));
          localStorage.setItem("MDnD_auth", response.MDnD_auth);
          localStorage.setItem("welcome", response.message);
          navigate(`/dashboard`);
          //resetForm();
          // setPreference([]);
          notify(response.message, SUCCESS, DURATION);
        } else {
          notify(response.message, ERROR, DURATION);
        }
        //console.log(response);
      }
    } catch (error) {
      notify(
        error.response.data.message || SOMETHING_WENT_WRONG,
        ERROR,
        DURATION
      );
      console.log("Error occurred while creating profile:", error);
    }
  };

  // useEffect(() => {
  //   setSettings((prev) => ({ ...prev, roleOption: FAN_ROLE }));
  // }, []);

  // changed here...
  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      roleOption: rolename_caps || FAN_ROLE,
    }));
  }, [rolename_caps]);

  //new code by vignesh from 12th july..

  const [AdvertiserCollection, setAdvertiserCollection] = useState({
    advertiserName: "",
    advertiserEmail: "",
    mobile_test: "",
    country_code: "",
    mobile: "",
    selectedCountry: "",
    selectedState: "",
    selectedCity: "",

    empty_validation_name: "",

    empty_validation_country: "",
    empty_validation_state: "",
    empty_validation_city: "",
  });

  useEffect(() => {
    if (dataSource.tempUser && settings.roleOption === "Advertiser") {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        advertiserEmail: dataSource.tempUser.email
          ? dataSource.tempUser.email
          : "",
        country_code: dataSource.tempUser.country_code
          ? dataSource.tempUser.country_code
          : "",
        mobile_test: dataSource.tempUser.mobile
          ? dataSource.tempUser.mobile
          : "",
        mobile:
          dataSource.tempUser.country_code && dataSource.tempUser.mobile
            ? `${dataSource.tempUser.country_code}-${dataSource.tempUser.mobile}`
            : dataSource.tempUser.mobile || "",
      }));
    }
  }, [dataSource.tempUser, settings.roleOption]);

  const [tenruleformobile, setTenRuleForMobile] = useState("");
  console.log(AdvertiserCollection, "AdvertiserCollection");

  const handleAdvertiserName = (value) => {
    setAdvertiserCollection((prevState) => ({
      ...prevState,
      advertiserName: value,
    }));

    if (value) {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        empty_validation_name: "",
      }));
    }
  };

  //country, city, state....

  const [address, setAddress] = useState({
    country: "",
    city: "",
    state: "",
  });

  useEffect(() => {
    mutationCountry.mutate();
  }, []);

  const GetCountries = () => GET_COUNTRY(COUNTRIES_FROM_BACKEND);

  const GET_COUNTRY = async (COUNTRIES_FROM_BACKEND) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${COUNTRIES_FROM_BACKEND}`,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationCountry = useMutation({
    mutationKey: ["get_Country"],
    mutationFn: () => GetCountries(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        setAddress((prevState) => ({ ...prevState, country: data.data }));
      } else {
        setAddress((prevState) => ({ ...prevState, country: "" }));
      }
    },
    onError: (error) => {
      setAddress((prevState) => ({ ...prevState, country: "" }));
    },
  });

  const handleCountry = (e) => {
    if (e.value) {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        selectedCountry: e.value,
      }));
      setAddress((prevState) => ({ ...prevState, city: [] }));
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        selectedState: "",
        selectedCity: "",
      }));
    }
    if (e.value) {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        empty_validation_country: "",
      }));
    }
  };

  useEffect(() => {
    const GETSTATESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${STATES_FROM_BACKEND}`,
          {
            country_id: AdvertiserCollection.selectedCountry,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {
          setAddress((prevState) => ({
            ...prevState,
            state: response.data.data,
          }));
        } else {
          setAddress((prevState) => ({ ...prevState, state: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };

    //mutationGetStates.mutate();
    if (AdvertiserCollection.selectedCountry) {
      GETSTATESDATA();
    }
  }, [AdvertiserCollection.selectedCountry]);

  const handleState = (e) => {
    if (e.value) {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        selectedState: e.value,
        empty_validation_state: "",
      }));
    }
  };

  useEffect(() => {
    const GETCITIESDATA = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}${CITIES_FROM_BACKEND}`,
          {
            state_id: AdvertiserCollection.selectedState,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );

        if (response.data.status == "success") {
          setAddress((prevState) => ({
            ...prevState,
            city: response.data.data,
          }));
        } else {
          setAddress((prevState) => ({ ...prevState, city: "" }));
        }
      } catch (error) {
        console.error("error fetching States for this country", error);
      }
    };
    if (AdvertiserCollection.selectedState) {
      GETCITIESDATA();
    }
  }, [AdvertiserCollection.selectedState]);

  const handleCity = (e) => {
    if (e.value) {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        selectedCity: e.value,
        empty_validation_city: "",
      }));
    }
  };

  const handleSubmit_advertiser = (event) => {
    event.preventDefault();
    if (AdvertiserCollection.advertiserName === "") {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        empty_validation_name: "Please Enter something!",
      }));
      return;
    }

    if (AdvertiserCollection.selectedCountry === "") {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        empty_validation_country: "Please Enter something!",
      }));
      return;
    }

    if (AdvertiserCollection.selectedState === "") {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        empty_validation_state: "Please Enter something!",
      }));
      return;
    }
    if (AdvertiserCollection.selectedCity === "") {
      setAdvertiserCollection((prevState) => ({
        ...prevState,
        empty_validation_city: "Please Enter something!",
      }));
      return;
    }

    setAdvertiserCollection((prevState) => ({
      ...prevState,
      empty_validation_name: "",

      empty_validation_country: "",
      empty_validation_state: "",
      empty_validation_city: "",
    }));

    mutationAdvertiserData.mutate();
  };

  const PostAdvertiserFields = () => POST_ADVERTISERFIELDS(POST_ADVERTISER);

  const POST_ADVERTISERFIELDS = async (POST_ADVERTISER) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${POST_ADVERTISER}`,
        data: {
          login_id: loginId ? Number(loginId) : "",
          advertiser_name: AdvertiserCollection.advertiserName
            ? AdvertiserCollection.advertiserName
            : "",
          email: AdvertiserCollection.advertiserEmail
            ? AdvertiserCollection.advertiserEmail
            : "",
          country_code: AdvertiserCollection.country_code
            ? Number(AdvertiserCollection.country_code)
            : "",
          mobile: AdvertiserCollection.mobile_test
            ? Number(AdvertiserCollection.mobile_test)
            : "",
          country_id: AdvertiserCollection.selectedCountry
            ? Number(AdvertiserCollection.selectedCountry)
            : "",
          state_id: AdvertiserCollection.selectedState
            ? Number(AdvertiserCollection.selectedState)
            : "",
          city_id: AdvertiserCollection.selectedCity
            ? Number(AdvertiserCollection.selectedCity)
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationAdvertiserData = useMutation({
    mutationKey: ["add_advertiserprofile"],
    mutationFn: () => PostAdvertiserFields(),

    onSuccess: (data) => {
      if (data.status === "success") {
        setAdvertiserCollection((prevState) => ({
          ...prevState,
          advertiserName: "",
          advertiserEmail: "",
          mobile_test: "+91",
          mobile: "",
          selectedCountry: "",
          selectedState: "",
          selectedCity: "",
        }));

        setAuthTokens(data.MDnD_auth);
        setUser(jwtDecode(data.MDnD_auth));
        localStorage.setItem("MDnD_auth", data.MDnD_auth);
        localStorage.setItem("welcome", data.message);
        navigate(`/dashboard`);
      } else {
        setAdvertiserCollection((prevState) => ({
          ...prevState,
          advertiserName: "",
          advertiserEmail: "",
          mobile_test: "+91",
          mobile: "",
          selectedCountry: "",
          selectedState: "",
          selectedCity: "",
        }));
        notify(data.message, ERROR, DURATION);
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Advertiser Form could not be Submitted. Something went Wrong!",
        3000
      );
    },
  });

  // new code..

  const handleNameChange = (value) => {
    setFieldValue("name", `${value}`);
    handleChange({ target: { name: "name", value: value } });
  };

  const handleNameBlur = (value) => {
    handleBlur({
      target: {
        name: "name",
        value: value,
      },
    });
  };

  const currentDate = new Date();

  return (
    <>
      <div className="loginBgBanner d-flex ">
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center height-self-center mt-lg-5">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 col-md-12 align-self-center">
              <div className="user-login-card bg-body shadow-sm">
                <div className="text-center">
                  <div className="text-center">
                    {/* <form onSubmit={handleSubmit}> */}
                    <form>
                      <h5>Create your Profile!</h5>
                      <div className="mb-3">
                        <SelectBox
                          label="Select Your Role"
                          className="sm"
                          labelMode="floating"
                          dataSource={RegistrationRole}
                          displayExpr="type"
                          valueExpr="value"
                          value={settings.roleOption}
                          onValueChanged={(e) => handleOptionChange(e.value)}
                        ></SelectBox>

                        {errors.roleOption && touched.roleOption && (
                          <p className="error-message mt-1">
                            {errors.roleOption}
                          </p>
                        )}
                      </div>

                      {settings.roleOption === "Advertiser" ? (
                        <div>
                          <div>
                            <TextBox
                              label="Enter Advertiser Name"
                              labelMode="floating"
                              onValueChanged={(e) =>
                                handleAdvertiserName(e.value)
                              }
                              value={AdvertiserCollection.advertiserName}
                            />

                            {AdvertiserCollection.empty_validation_name && (
                              <span style={{ color: "red" }}>
                                {AdvertiserCollection.empty_validation_name}
                              </span>
                            )}
                          </div>

                          <div>
                            <TextBox
                              type="email"
                              label="Email"
                              labelMode="floating"
                              readOnly={true}
                              value={AdvertiserCollection.advertiserEmail}
                            ></TextBox>
                          </div>

                          <PhoneInput
                            //defaultCountry="IN"
                            readOnly={true}
                            placeholder="Phone number"
                            value={AdvertiserCollection.mobile}
                            name="Advertisermobile"
                            //onChange={(e) => handleAdvertiserMobile(e)}
                            inputProps={{
                              readOnly: true,
                            }}
                            countryCodeEditable={false}
                            enableSearch={true}
                            className="dx-texteditor-input phoneFieldBox phoneNumberFieldPadForArtist mt-2 bg-white"
                            areaCodes={true}
                          />

                          <div>
                            <SelectBox
                              dataSource={address.country}
                              displayExpr="country_name"
                              valueExpr="country_id"
                              label="Select Country"
                              value={AdvertiserCollection.selectedCountry}
                              searchEnabled={true}
                              onValueChanged={handleCountry}
                              labelMode="floating"
                            />

                            {AdvertiserCollection.empty_validation_country && (
                              <span style={{ color: "red" }}>
                                {AdvertiserCollection.empty_validation_country}
                              </span>
                            )}
                          </div>

                          <div>
                            <SelectBox
                              dataSource={address.state || []}
                              label="Select State"
                              displayExpr="state_name"
                              valueExpr="state_id"
                              labelMode="floating"
                              searchEnabled={true}
                              onValueChanged={handleState}
                              value={AdvertiserCollection.selectedState}
                              //ref={element => inputRefs.current[key] = element}
                            />
                            {AdvertiserCollection.empty_validation_state && (
                              <span style={{ color: "red" }}>
                                {AdvertiserCollection.empty_validation_state}
                              </span>
                            )}
                          </div>

                          <div>
                            <SelectBox
                              dataSource={address.city || []}
                              label="Select City"
                              labelMode="floating"
                              displayExpr="city_name"
                              valueExpr="city_id"
                              searchEnabled={true}
                              onValueChanged={handleCity}
                              value={AdvertiserCollection.selectedCity}
                              //ref={element => inputRefs.current[key] = element}
                            />

                            {AdvertiserCollection.empty_validation_city && (
                              <span style={{ color: "red" }}>
                                {AdvertiserCollection.empty_validation_city}
                              </span>
                            )}
                          </div>

                          <div>
                            <button onClick={handleSubmit_advertiser}>
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            {/* newly added    */}
                            <div className="mb-3">
                              <TextBox
                                label="Enter Name"
                                labelMode="floating"
                                onValueChanged={(e) =>
                                  handleNameChange(e.value)
                                }
                                value={values.name}
                                onBlur={(e) => {
                                  handleNameBlur(e.value);
                                }}
                              />
                              {errors.name && touched.name && (
                                <p className="error-message mt-1">
                                  {errors.name}
                                </p>
                              )}
                            </div>

                            <div className="mb-3">
                              <PhoneInput
                                defaultCountry="IN"
                                placeholder="Enter Phone number"
                                value={values.mobile}
                                name="mobile"
                                onChange={(e) => handleMobileChange(e)}
                                onBlur={(e) => {
                                  handleMobileBlur(e);
                                }}
                                countryCodeEditable={false}
                                enableSearch={true}
                                className="dx-texteditor-input phoneFieldBox phoneNumberFieldPadForArtist mt-2 bg-white"
                                areaCodes={true}
                                ref={phoneRef}
                                disabled={loginId ? true : false}
                              />
                              {errors.mobile && touched.mobile && (
                                <p className="error-message mt-1">
                                  {errors.mobile}
                                </p>
                              )}
                            </div>
                            {settings.genderEnable && (
                              <div className="radioSection m-2">
                                <RadioGroup
                                  layout="horizontal"
                                  defaultValue={"M"}
                                  items={GENDER}
                                  displayExpr="gender"
                                  valueExpr="value"
                                  onValueChanged={(e) =>
                                    handleChange({
                                      target: {
                                        name: "gender",
                                        value: e.value,
                                      },
                                    })
                                  }
                                />
                                {errors.gender && touched.gender && (
                                  <p className="error-message mt-1">
                                    {errors.gender}
                                  </p>
                                )}
                              </div>
                            )}

                            {settings?.isDobEnabled && (
                              <div className="mb-3">
                                <DateBox
                                  label="Date of Birth"
                                  labelMode="floating"
                                  value={values.dob || null}
                                  onValueChanged={(e) =>
                                    handleChange({
                                      target: { name: "dob", value: e.value },
                                    })
                                  }
                                  onFocusOut={(e) => {
                                    handleBlur({
                                      target: {
                                        name: "dob",
                                        value: e.value,
                                      },
                                    });
                                  }}
                                  acceptCustomValue={false}
                                  max={currentDate}
                                  displayFormat="dd-MM-yyyy"
                                />
                                {errors.dob && touched.dob && (
                                  <p className="error-message mt-1">
                                    {errors.dob}
                                  </p>
                                )}
                              </div>
                            )}

                            {settings?.isCategoriesEnabled &&
                              settings?.isCategoriesEnabled && (
                                <>
                                  <div className="mb-3">
                                    <SelectBox
                                      label="Main Category"
                                      labelMode="floating"
                                      onFocusIn={handleFocusIn}
                                      type="text"
                                      dataSource={
                                        ArtistmainCategoryDataSource?.data ||
                                        null
                                      }
                                      displayExpr="main_cat_name"
                                      valueExpr="main_cat_id"
                                      value={values.artist_main_cat}
                                      onValueChanged={(e) =>
                                        handleChange({
                                          target: {
                                            name: "artist_main_cat",
                                            value: e.value,
                                          },
                                        })
                                      }
                                      onFocusOut={(e) =>
                                        handleBlur({
                                          target: {
                                            name: "artist_main_cat",
                                            value: e.value,
                                          },
                                        })
                                      }
                                      searchEnabled={true}
                                    />
                                    {errors.artist_main_cat &&
                                      touched.artist_main_cat && (
                                        <p className="error-message mt-1">
                                          {errors.artist_main_cat}
                                        </p>
                                      )}
                                  </div>

                                  <div className="mb-3">
                                    <SelectBox
                                      label="Sub Category"
                                      labelMode="floating"
                                      type="text"
                                      dataSource={
                                        dataSource?.subCategoryDataSource ||
                                        null
                                      }
                                      displayExpr="sub_cat_name"
                                      valueExpr="sub_cat_id"
                                      value={values.artist_sub_cat}
                                      onValueChanged={(e) =>
                                        handleChange({
                                          target: {
                                            name: "artist_sub_cat",
                                            value: e.value,
                                          },
                                        })
                                      }
                                      onFocusOut={(e) =>
                                        handleBlur({
                                          target: {
                                            name: "artist_sub_cat",
                                            value: e.value,
                                          },
                                        })
                                      }
                                      searchEnabled={true}
                                    />
                                    {errors.artist_sub_cat &&
                                      touched.artist_sub_cat && (
                                        <p className="error-message mt-1">
                                          {errors.artist_sub_cat}
                                        </p>
                                      )}
                                  </div>
                                </>
                              )}

                            <div className="mb-3">
                              <SelectBox
                                label="Country"
                                onFocusIn={countryFocusIn}
                                displayExpr="country_name"
                                labelMode="floating"
                                valueExpr="country_id"
                                value={values.country_id}
                                dataSource={countryDataSource?.data || null}
                                onValueChanged={(e) =>
                                  handleChange({
                                    target: {
                                      name: "country_id",
                                      value: e.value,
                                    },
                                  })
                                }
                                onFocusOut={(e) =>
                                  handleBlur({
                                    target: {
                                      name: "country_id",
                                      value: e.value,
                                    },
                                  })
                                }
                                searchEnabled={true}
                              />
                              {errors.country_id && touched.country_id && (
                                <p className="error-message mt-1">
                                  {errors.country_id}
                                </p>
                              )}
                            </div>

                            <div className="mb-3">
                              <SelectBox
                                label="State"
                                displayExpr="state_name"
                                labelMode="floating"
                                valueExpr="state_id"
                                value={values.state_id}
                                dataSource={dataSource.stateDataSource || null}
                                onValueChanged={(e) =>
                                  handleChange({
                                    target: {
                                      name: "state_id",
                                      value: e.value,
                                    },
                                  })
                                }
                                onFocusOut={(e) =>
                                  handleBlur({
                                    target: {
                                      name: "state_id",
                                      value: e.value,
                                    },
                                  })
                                }
                                searchEnabled={true}
                              />
                              {errors.state_id && touched.state_id && (
                                <p className="error-message mt-1">
                                  {errors.state_id}
                                </p>
                              )}
                            </div>

                            <div className="mb-3">
                              <SelectBox
                                label="City"
                                displayExpr="city_name"
                                valueExpr="city_id"
                                labelMode="floating"
                                value={values.city_id}
                                dataSource={dataSource.cityDataSource || null}
                                onValueChanged={(e) =>
                                  handleChange({
                                    target: {
                                      name: "city_id",
                                      value: e.value,
                                    },
                                  })
                                }
                                onFocusOut={(e) =>
                                  handleBlur({
                                    target: {
                                      name: "city_id",
                                      value: e.value,
                                    },
                                  })
                                }
                                searchEnabled={true}
                              />
                              {errors.city_id && touched.city_id && (
                                <p className="error-message mt-1">
                                  {errors.city_id}
                                </p>
                              )}
                            </div>
                            {settings?.isPreferenceEnabled && (
                              <>
                                <p>Select one or more preferences</p>
                                <div className="chips__filter d-flex flex-wrap">
                                  {mainCategoryDataSource &&
                                    mainCategoryDataSource?.data?.map(
                                      (category) => (
                                        <div
                                          key={category.main_cat_id}
                                          className={`chip ${
                                            Preference &&
                                            Preference.includes(
                                              category.main_cat_id
                                            )
                                              ? "selected"
                                              : ""
                                          }`}
                                          data-status="0"
                                          data-id={category.main_cat_id}
                                          onClick={preferenceEvent}
                                        >
                                          {Preference &&
                                            Preference.includes(
                                              category.main_cat_id
                                            ) && (
                                              <div className="icon icon--leadind chip--check mr-2 d-flex text-success">
                                                ✓
                                              </div>
                                            )}
                                          <div>{category.main_cat_name}</div>
                                        </div>
                                      )
                                    )}
                                </div>
                              </>
                            )}

                            <div className="full-button">
                              <div className="iq-button">
                                <Button
                                  disabled={isSubmitting}
                                  onClick={() =>
                                    handleSubmit(checkValidations())
                                  }
                                  className="btn position-relative h-auto"
                                  text="Submit"
                                >
                                  Submit
                                </Button>
                              </div>
                              <p className="mt-3">
                                You have chosen to create profile as{" "}
                                {settings.roleOption}.
                              </p>
                            </div>
                          </div>{" "}
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>
    </>
  );
}
