import React from "react";
import Switch from "devextreme-react/switch";
import {
  APPLICABLE,
  handlingChargesPercentage,
  handlingChargesAmount,
} from "../global/constants";
import { BASEPATH } from "../config/config";
import FollowIcon from "../assets/Icons/FollowIcon.svg";
import FollowingIcon from "../assets/Icons/FollowingIcon.svg";

const EmailValidation = (email) => {
  try {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  } catch (error) {
    console.log();
  }
};

const joinValues = (...values) => {
  try {
    return values?.filter(Boolean).join(", ");
  } catch (error) {
    console.log(error);
  }
};

function capitalizeFirstLetter(str) {
  try {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  } catch (error) {
    console.log(error);
  }
}

const generateAlphabetCharacters = (name) => {
  if (name) {
    const firstLetter = name?.charAt(0).toUpperCase();
    return (
      <h1 style={{ color: "#ffffff", fontSize: "4rem" }}>{firstLetter}</h1>
    );
  }
  return null;
};

export const shareCurrentpage = (data) => `${BASEPATH}${data}`;

export const getArtistOrgAccomNames = (dataSource, artistOrOrganisers) => {
  if (!dataSource || dataSource.length === 0) {
    return null;
  }

  const uniqueData = dataSource.reduce((acc, current) => {
    const x = acc.find(
      (item) => item[artistOrOrganisers] === current[artistOrOrganisers]
    );
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  return uniqueData.map((data) =>
    artistOrOrganisers === "artist_id" ? (
      <div key={data.artist_id} className="d-flex pb-2 j">
        {data.artist_name}
        <button>
          <img src={FollowIcon} alt="Follow" />
        </button>
        <button>
          <img src={FollowingIcon} alt="Following" />
        </button>
        <br />{" "}
      </div>
    ) : (
      <div key={data.organiser_id} className="d-flex pb-2">
        {data.organiser_name}
        <button>
          <img src={FollowIcon} alt="Follow" />
        </button>
        <button>
          <img src={FollowingIcon} alt="Following" />
        </button>
        <br />{" "}
      </div>
    )
  );
};

const combineUserDetails = (dataSource, isartist) => {
  try {
    let combineData1 = "";
    let combineData2 = "";
    if (isartist === "artists") {
      combineData1 = dataSource && dataSource?.eventDetails?.[0]?.Artists;
      combineData2 =
        dataSource && dataSource?.eventDetails?.[0]?.quickadd_artists;
    } else {
      combineData1 =
        dataSource && dataSource?.eventDetails?.[0]?.quickadd_accompanists;
      combineData2 = dataSource && dataSource?.eventDetails?.[0]?.Accompanists;
    }

    return joinValues(combineData1, combineData2);
  } catch (error) {
    console.error(error);
  }
};

// Utility function to find a specific value in an array of objects
const getUserNameById = (dataSource, id, parameter, data) => {
  try {
    // Check if dataSource is an array and not empty
    if (dataSource && dataSource.length > 0) {
      // Find the object in the array where the specified parameter matches the given id
      const result = dataSource.find((data) => data[parameter] === id);
      // Return the value of the specified data field if found, otherwise return null
      return result ? result[data] : null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

const MapLocationEmbeded = (lat, lng) => {
  const MapLocation = `https://maps.google.com/maps?q=${lat},${lng}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
  return MapLocation;
};

const generateReverseSerialNumber = (rowData, dataSource) => {
  try {
    if (dataSource) {
      const totalRows = dataSource?.length || 0;
      const rowIndex = dataSource?.indexOf(rowData) || 0;
      return totalRows - rowIndex;
    }
    return "";
  } catch (error) {}
};

export const getPercentage = (originalAmount, fakeAmount) => {
  const original = parseFloat(originalAmount);
  const fake = parseFloat(fakeAmount);

  if (isNaN(original) || isNaN(fake) || original === 0) {
    return 0;
  }
  const percentage = ((original - fake) / original) * 100;
  return percentage;
};

const switchElement = (row) => {
  const isApplicable = row.value === APPLICABLE;
  return <Switch defaultValue={isApplicable} />;
};

/* 
    Render Image have two parameteres one for image path and another one is datasource
  */
const ImageRender = (imagePath, datasource, dataField) => {
  const IMAGE = datasource?.data && datasource?.data[dataField];
  return IMAGE !== "" ? (
    <img src={`${BASEPATH}${imagePath}${IMAGE}`} className="commonGridImage" />
  ) : (
    <p>No image</p>
  );
};

const generateRowLabels = (numRows, format) => {
  try {
    const labels = [];
    if (format === "number") {
      for (let i = 1; i <= numRows; i++) {
        labels.push(i.toString());
      }
    } else if (format === "alphabetic") {
      for (let i = 0; i < numRows; i++) {
        let label = "";
        let n = i;
        while (n >= 0) {
          label = String.fromCharCode((n % 26) + 65) + label;
          n = Math.floor(n / 26) - 1;
        }
        labels.push(label);
      }
    }
    return labels;
  } catch (error) {
    console.log("error", error);
  }
};

export function jsonToBase64(object) {
  try {
    const stringifyObj = JSON.stringify(object);
    const b64Str = btoa(stringifyObj);
    return b64Str;
  } catch (error) {
    console.log(error);
  }
}

const handleImageChange = async (
  event,
  openEditor,
  setFieldValue,
  imageField,
  settingsUpdater
) => {
  const imageFile = event?.value[0];
  settingsUpdater((prev) => ({ ...prev, imageName: imageFile?.name }));
  try {
    if (!imageFile) {
      console.error("No image file provided");
      return;
    }
    const initialCrop = {
      x: 0,
      y: 0,
      width: 400,
      height: 400,
      unit: "px",
    };

    const editedImageBlob = await openEditor({
      src: URL.createObjectURL(imageFile),
      cropOptions: {
        aspectRatio: 1,
        maxWidth: 400,
        maxHeight: 400,
        minWidth: 400,
        minHeight: 400,
        center: true,
      },
      initCrop: initialCrop,
    });

    if (
      editedImageBlob &&
      typeof editedImageBlob.editedImage.getBlob === "function"
    ) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setFieldValue(imageField, base64String);
      };
      editedImageBlob.editedImage
        .getBlob()
        .then((blob) => {
          reader.readAsDataURL(blob);
        })
        .catch((err) => {
          console.error("Error getting blob from edited image:", err);
        });
    } else {
      console.error(
        "Failed to obtain the edited image Blob or getBlob method is not available."
      );
    }
  } catch (error) {
    console.error("Error while processing image:", error);
  }
};

const JsonParser = async (data) => {
  return await JSON.parse(data);
};

export const calculateRowTotal = (slugArray) => {
  try {
    let total = 0;
    if (slugArray && slugArray.length > 0) {
      slugArray &&
        slugArray?.forEach((item) => {
          if (item.ticket_quantity !== "0" && item.ticket_quantity !== 0)
            total += parseFloat(item.amount * Number(item?.ticket_quantity));
        });
    }
    return total.toFixed(2);
  } catch (error) {}
};

export const calculateGSTSlugTotal = (slugArray) => {
  try {
    var gstTotal = 0;
    if (slugArray && slugArray.length > 0) {
      slugArray.forEach((item) => {
        if (
          item.ticket_quantity !== "0" &&
          item.ticket_quantity !== 0 &&
          item.gst_amount !== null
        ) {
          const gstAmount = parseFloat(item.gst_amount);
          gstTotal += gstAmount;
        }
      });
    }
    return gstTotal.toFixed(2);
  } catch (error) {
    console.error(error);
    return (gstTotal = "0.00");
  }
};

/**
 * The first parameter is datasource, which is an array of objects.
 * The second and third parameters are optional. They should only be defined when you invoke this function for promocode total calculation. In all other cases, these parameters will be undefined.
 *
 */
export const calculateOverAllTotal = (dataSource, ispercentage, amount) => {
  try {
    let total = 0;
    if (
      dataSource?.cartListDataSource &&
      dataSource.cartListDataSource.length > 0
    ) {
      dataSource.cartListDataSource.forEach((mainData) => {
        mainData.slug.forEach((slugData) => {
          // only doing the math where the ticket_quantity not equal to 0
          if (
            slugData.ticket_quantity !== "0" &&
            slugData.ticket_quantity !== 0
          ) {
            total += parseFloat(
              slugData.amount * Number(slugData.ticket_quantity)
            );
          }
        });
      });
      // Apply discount based on the ispercentage flag
      if (ispercentage !== undefined && ispercentage !== undefined) {
        if (ispercentage) {
          total -= total * (amount / 100) || 0; // Apply percentage discount
        } else {
          total -= amount || 0; // Subtract fixed amount
        }
      }
    }
    return total.toFixed(2);
  } catch (error) {
    console.error(error);
    return "0.00";
  }
};
export const appliedCouponCodeStructure = (dataSource) => {
  try {
    if (
      dataSource?.cartListDataSource &&
      dataSource.cartListDataSource.length > 0
    ) {
      return dataSource.cartListDataSource.map((data) => {
        return {
          event_id: data.item_id,
          CouponCodeApplied: "",
          couponValue: "",
        };
      });
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};

// isValidValue resuable function to check the value doesnot having undefined, empty string and not null
const isValidValue = (value) => {
  try {
    return value !== null && value !== undefined && value !== "";
  } catch (error) {}
};

const isValidArray = (arr) => {
  try {
    return Array.isArray(arr) && arr.length > 0;
  } catch (error) {
    console.error("Error checking the array:", error);
    return false;
  }
};

function sortSeatingLayouts(lookupDetails, propsdatasource) {
  try {
    const sortedPropsDataSource = {};
    for (const property in propsdatasource) {
      if (!propsdatasource.hasOwnProperty(property)) continue;
      const order = lookupDetails
        .filter((item) => item[property])
        .map((item) => item[property]);

      const dataKeys = Object.keys(propsdatasource[property]);
      const sortedKeys = dataKeys.sort((a, b) => {
        const indexA = order.indexOf(a);
        const indexB = order.indexOf(b);
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        return 0;
      });
      sortedPropsDataSource[property] = {};
      sortedKeys.forEach((key) => {
        sortedPropsDataSource[property][key] = propsdatasource[property][key];
      });
    }

    return sortedPropsDataSource;
  } catch (error) {console.error(error)}
}

export {
  isValidArray,
  isValidValue,
  JsonParser,
  handleImageChange,
  generateRowLabels,
  ImageRender,
  switchElement,
  generateReverseSerialNumber,
  MapLocationEmbeded,
  EmailValidation,
  combineUserDetails,
  capitalizeFirstLetter,
  generateAlphabetCharacters,
  getUserNameById,
  sortSeatingLayouts,
};
