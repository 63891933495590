import React from "react";
import { EVENT_IMAGES_DETAILS } from "../../global/constants.js";
import {
  DateBox,
  TextBox,
  SelectBox,
  TagBox,
  NumberBox,
} from "devextreme-react";
import notify from "devextreme/ui/notify";
import { useFormik } from "formik";
import { useState, useEffect, useContext, useRef } from "react";
import { makePostRequest } from "../../Api/requestMethods.js";
import { SUCCESS, ERROR, DURATION } from "../../global/ToastMsgConstants.tsx";
import {
  getMemberType,
  getMemberDetails,
  getOrganiserLandingPageDetails,
  landingPageDetailsApi,
} from "../../Api/api.js";
import { isValidValue } from "../../utils/commonUtils.js";
import AuthContext from "../../context/AuthContext.js";
import { organiser_landing_page_form } from "../../Schemas/Forms/LoginDashboard.js";
import { useSearchParams, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { ORGANISER_URL } from "../../Subpages/MainPage/constant.js";
import { ArtistallImg } from "../../Subpages/MainPage/constant.js";
import Skeleton from "react-loading-skeleton";
import {
  ALLEVENT_DETAILS_IMAGES,
  countryParams,
  countryIdIndia,
  seasonEventId,
} from "../../global/constants.js";
import Noimage from "../../global/NoImage.js";
import getSeasonTicketKey from "../../Api/getSeasonTicketKey.js";
import { GlobalContext } from "../../global/provider.js";
import axios from "axios";
import { INDI_EVENTS_GET } from "../../Api/api.js";
import { BASEPATH } from "../../config/config.js";
import { useMutation } from "@tanstack/react-query";
import { customEncrypt } from "../../utils/encryptAndDecrypt.js";
import getEventKeys from "../../Api/getEventKeys.js";
import { isValidImage } from "../../global/ImageCheck.js";
import { imageExists } from "../../global/ImageCheck.js";
import EventNoimage from "../../global/EventNoimage.js";

function SkeletonLoader() {
  return (
    <div>
      <Skeleton height={250} />
      <Skeleton height={15} />
      <Skeleton height={15} />
      <Skeleton height={30} />
    </div>
  );
}

const OrganizerLandingPage = () => {
  const [imageError, setImageError] = useState(false);
  const { setEventDataSource, setCartDetails } = useContext(GlobalContext);
  const SEASONTICKETCOUNTRYKEY = getSeasonTicketKey().SEASONTICKET_KEY;
  const SEASONEVENTKEY = getSeasonTicketKey().SEASONEVENT_KEY;
  const [isLoading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const textContainerRef = useRef(null);
  const [dataSource, setDataSource] = useState({
    membersType: "",
    landingPageDetails: "",
    eventDetails: "",
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const country_id = searchParams.get("ci");
  const role_id = searchParams.get("r_id");
  const { user } = useContext(AuthContext);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: organiser_landing_page_form,
    //validationSchema: LandingPageSchema,
    onSubmit: async (values, { setSubmitting }) => {
      //  await eventSubmit();
      setSubmitting(false);
    },
  });

  const handleImageError = () => {
    setImageError(true);
  };

  const encodeBase64 = (str, key) => {
    const prefix = key?.toString();
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  const seasonEventsHandler = (data) => {
    const modifiedTitle = data.season_event_title
      ? data.season_event_title.split(" ").join("_")
      : "Season_Event";

    navigate(
      `/SeasonTickets/${modifiedTitle}?${countryParams}=${encodeBase64(
        countryIdIndia,
        SEASONTICKETCOUNTRYKEY
      )}&${seasonEventId}=${encodeBase64(
        data?.season_event_id,
        SEASONEVENTKEY
      )}&r_id=${role_id}&is_multiple=${data?.multiple_tickets}`
    );

    setEventDataSource((prev) => ({
      ...prev,
      multiple_tickets: data.multiple_tickets,
    }));
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (isValidValue(country_id) && isValidValue(role_id)) {
          const body = {
            country_id: country_id,
            role_id: Number(role_id),
          };

          const Landingbody = {
            roleid: Number(role_id),
          };
          const response = await makePostRequest(
            getOrganiserLandingPageDetails,
            body
          );

          const landingPageDetails = await makePostRequest(
            landingPageDetailsApi,
            Landingbody
          );

          if (response.message === SUCCESS) {
            setDataSource((prev) => ({
              ...prev,
              eventDetails: response?.data,
              landingPageDetails:
                landingPageDetails.data && landingPageDetails.data[0]
                  ? landingPageDetails.data[0]
                  : [],
            }));
            setLoading(false);
          } else {
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [role_id, country_id]);

  useEffect(() => {
    (async () => {
      try {
        const body = {
          role_id: Number(role_id),
        };
        if (isValidValue(user.role_id)) {
          const response = await makePostRequest(getMemberType, body);
          if (response.status === SUCCESS) {
            setDataSource((prev) => ({ ...prev, membersType: response?.data }));
          } else {
            notify(response?.error, ERROR, DURATION);
          }
        }
      } catch (error) {
        //notify(error?.response?.data?.message, ERROR, DURATION);
      }
    })();
  }, []);

  const sanitizedHTML =
    dataSource?.landingPageDetails &&
    DOMPurify.sanitize(dataSource?.landingPageDetails?.content);

  const imagePath =
    dataSource?.landingPageDetails.role_name === "organiser"
      ? ORGANISER_URL
      : ArtistallImg;

      useEffect(() => {
        const checkHeight = () => {
          if (textContainerRef.current) {
            const lineHeight = parseFloat(getComputedStyle(textContainerRef.current).lineHeight);
            const maxLines = 5;
            const maxHeight = lineHeight * maxLines;
      
            // Check if the container exceeds the height of 5 lines
            if (textContainerRef.current.scrollHeight > maxHeight) {
              setShowReadMore(true);
            } else {
              setShowReadMore(false);
            }
          }
        };
      
        // Initial height check
        checkHeight();
      
        // Throttle resize event handler
        let resizeTimeout;
        const handleResize = () => {
          if (resizeTimeout) {
            clearTimeout(resizeTimeout);
          }
          resizeTimeout = setTimeout(() => {
            checkHeight();
          }, 300); // Throttle by 300ms
        };
      
        // Add event listener for window resize
        window.addEventListener("resize", handleResize);
      
        // Cleanup event listener on unmount
        return () => {
          window.removeEventListener("resize", handleResize);
          if (resizeTimeout) {
            clearTimeout(resizeTimeout);
          }
        };
      }, [sanitizedHTML]); // Add other dependencies if necessary
      
      const toggleReadMore = () => {
        setIsExpanded((prev) => !prev);
      };
      

  //new code..

  const COUNTRY_KEY = getEventKeys().countryKey;
  const EVENT_KEY = getEventKeys().eventKey;

  const[indData,setindData] = useState({
    finalData:""
  });

  const token = localStorage.getItem("MDnD_auth");

  // useEffect(()=>{
  //  mutationgetIndiEvents.mutate();


  // },[])

  const Getting_IndiEvents = () => GET_INDIEVENTS_ALL(INDI_EVENTS_GET);

  const GET_INDIEVENTS_ALL = async (INDI_EVENTS_GET) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${INDI_EVENTS_GET}`,
        data: { 
          role_id: role_id ? Number(role_id) : "" ,
          country_id : country_id ? Number(country_id) : ""
        },
        headers: { 'MDnD_auth': `${token}` },
        timeout: 60000,
        responseType: 'json',
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetIndiEvents = useMutation({
    mutationKey: ["get_indiv_events"],
    mutationFn: () => Getting_IndiEvents(),
    onSuccess: (data) => {
     setindData((prev)=>({
      ...prev,
      finalData: data?.data

     }))
    
    },
    onError: () => {
      setindData((prev)=>({
        ...prev,
        finalData: ''
  
       }))
    },
  });

  const encodeBase64_new = (str, key) => {
    const prefix = key?.toString();
    const combinedStr = prefix + str.toString();
    return btoa(combinedStr);
  };

  const selectedDetailsEvent = (event_id, title) => {
    
    const encyptedData = customEncrypt("no");
    const modifiedTitle = title ? title.split(" ").join("_") : "Event";
    navigate(
        `/event/${modifiedTitle}?ci=${encodeBase64_new(Number(country_id), COUNTRY_KEY)}&e=${encodeBase64_new(event_id, EVENT_KEY)}&editEvent=${encyptedData}`
    );
};



  return (
    <div className="container pb-5 pb-lg-2">
       {isLoading ? (
        <div className="d-block d-lg-flex w-100 SkeletonWidth mt-5 mt-lg-2 mb-5 overflow-hidden">
          <div className="d-flex justify-content-center col-12 col-md-2  me-4">
          <Skeleton height={180} width={180} className="rounded-circle " />
          </div>
          <div className="col-12 SkeletonWidthDiv  col-md-10">
          <Skeleton count={8} height={20}  />
          </div>
        </div>
      ) : (
        <div className="row mt-4 mt-5 mt-lg-3 mb-5">
      <div className="col-12 col-md-2">
        <img
          src={`${imagePath}/${dataSource?.landingPageDetails?.profile_photo}`}
          className="img-fluid product-detail-image rounded"
          alt="product-slide-image"
        />
      </div>

      <div className="col-12 col-md-10">
        <div
          className={`despSecondWidth mb-0 ${isExpanded ? "expanded" : "collapsed"}`}
          ref={textContainerRef}
          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          style={{ maxHeight: isExpanded ? "none" : "calc(2em * 5)", overflow: "hidden" }} // Adjust 1.5em according to your line height
        ></div>

        {showReadMore && (
          <button onClick={toggleReadMore} className="read-more-btn">
            {isExpanded ? "Read Less" : "Read More"}
          </button>
        )}
      </div>
    </div>
      )}

      <div className="tab-content" id="pills-tabContent">
        <div className="tab-pane fade show active">
         <h4>Season Events</h4>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5 filtermh">
            
            {isLoading
              ? ( Array(15)
                  .fill(null)
                  .map((_, index) => (
                    <div className="col mb-2 mb-lg-4 px-2" key={index}>
                      <SkeletonLoader />
                    </div>
                  )))
              :(<> 
              {/* <h4 
                className="col-12 w-100"
              >Season Events</h4>  */}
              {
                dataSource?.eventDetails &&
                dataSource?.eventDetails.map((data, index) => (
                  <div
                    className="col mb-2 mb-lg-4 px-2"
                    key={data?.season_event_id}
                  >
                    <div className="product-block mb-3 mb-lg-4">
                      <div
                        className="image-wrap"
                        onClick={() => seasonEventsHandler(data)}
                      >
                        <div className="product-image-container">
                          {data.event_image && !imageError ? (
                            <img
                              src={`${ALLEVENT_DETAILS_IMAGES}${data.event_image}`}
                              className="img-fluid w-100"
                              alt="productImg-"
                              loading="lazy"
                              onError={handleImageError}
                            />
                          ) : (
                            <Noimage />
                          )}
                        </div>
                        <div class="align-items-center blog-description bg-white p-2">
                          <h5
                            className="mb-0 text-ellipsis"
                            title={data?.season_event_title}
                            style={{
                              maxHeight: "48px",
                              fontSize: "16px",
                              overflow: "hidden",
                            }}
                          >
                            {" "}
                            {data?.season_event_title}
                          </h5>

                          <div className="justify-content-between d-flex align-items-center gap-2">
                            <div className="pe-0" style={{ width: "60%" }}>
                              <p
                                className="mb-0 text-ellipsis"
                                style={{
                                  maxHeight: "24px",
                                  overflow: "hidden",
                                }}
                                title={data?.venue_name}
                              >
                                {data?.venue_name}
                              </p>

                              <p class="mb-0 iq-title">
                                {data?.event_start_date}
                              </p>
                            </div>
                            <p
                              className="button m-0 text-center"
                              style={{
                                padding: "6px 6px",
                                height: "auto",
                                fontSize: "12px",
                                width: "40%",
                              }}
                            >
                              {data?.ticketing_option === "ticketed"
                                ? "Ticketed"
                                : data?.ticketing_option}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="eventsDetails">
                        <div className="eventsImgText py-2">
                          <div className="row px-2">
                            <ul className="d-flex align-items-center justify-content-between list-inline mb-0">
                              <li className="d-flex align-items-center justify-content-center">
                                <a
                                  className="cursor-pointer"
                                  data-bs-toggle="modal"
                                  data-bs-target="#woosq-popup"
                                ></a>
                              </li>
                              <li className="d-flex align-items-center justify-content-center"></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <br></br><br></br>
                {/* {indData.finalData && indData.finalData.length > 0 ?(  <h4 className="col-12 w-100">Individual Events</h4>):(null)}

            {
              indData.finalData && indData.finalData.length > 0 ?

              indData.finalData.map((data)=>(
                <div
                className="col events-bg mb-1 bg-gray bg-sm-none"
                key={data.event_id}
            >
                <div className="product-block mb-3 mb-lg-4">
                    <div
                        className="image-wrap "
                        style={{
                            borderRadius: "6px",
                            overflow: "hidden",
                        }}
                        onClick={() =>
                            selectedDetailsEvent(
                                data?.event_id,
                                data?.event_title
                            )
                        }
                    >
                        <div className="product-image-container">
                            {
                                isValidImage(data?.event_image) &&
                                    imageExists(
                                        `${ALLEVENT_DETAILS_IMAGES}${data?.event_image}`
                                    ) &&
                                    data?.event_image
                                    && !imageError

                                    ? (
                                        <img
                                            src={`${ALLEVENT_DETAILS_IMAGES}${data?.event_image}`}
                                            className="w-100"
                                            alt="productImg-"
                                            loading="lazy"
                                            onError={handleImageError}
                                        />
                                    ) : (
                                        <EventNoimage />
                                    )}
                        </div>
                        <div class="align-items-center blog-description bg-white p-2">
                            <h5
                                className="mb-0 text-ellipsis"
                                title={data?.event_title}
                                style={{
                                    maxHeight: "48px",
                                    fontSize: "16px",
                                    overflow: "hidden",
                                }}
                            >
                                {data?.event_title}
                            </h5>
                            <p
                                className="mb-0 text-ellipsis"
                                style={{
                                    maxHeight: "24px",
                                    overflow: "hidden",
                                }}
                            >
                                {data?.venue_name}
                            </p>
                            <div className="justify-content-between d-flex align-items-end gap-2">
                                <div className="pe-0">
                                    <p class="mb-0 iq-title text-black">
                                        {data?.event_start_date}
                                        
                                    </p>
                                </div>
                                <p
                                    className="button m-0 text-center"
                                    style={{
                                        padding: "6px 24px",
                                        height: "auto",
                                        fontSize: "12px",
                                        width: "40%",
                                        backgroundColor:
                                            data?.ticketing_option === "free" ||
                                                data?.ticketing_option ===
                                                "register"
                                                ? "green"
                                                : "#3f00ff",
                                    }}
                                >
                                    {data?.ticketing_option === "free" ? "Free" : "Ticketed"}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              )) :(null)
            } */}
                
                
                
                </>)}



          </div>
       
       
        

       </div>
       

       


      </div>




    </div>
  );
};

export default OrganizerLandingPage;
