import React, { useEffect, useState, useRef } from "react";
import DataGrid, { Column, Editing, Paging } from "devextreme-react/data-grid";

const QuickAddGrid = ({
  dataSource,
  condition,
  setSettings,
  props,
  setDataSource,
  isJointEvent,
}) => {
  const [gridData, setGridData] = useState(dataSource.quickAddDataSource || []);
  const gridRef = useRef(null);

  useEffect(() => {
    if (condition) {
      gridRef.current?.instance.addRow();
    }
  }, [condition]);

  const onRowSaving = (e) => {
    try {
      const changes = e && e?.changes[0];
      const artists = changes?.data?.quickadd_artists;
      const accompanists = changes?.data?.quickadd_accompanists;
      const organisers = changes?.data?.quickadd_organisers;

      const isEmpty = (value) =>
        value === undefined || value === "" || value === null;

      if (
        changes.type === "insert" &&
        isEmpty(artists) &&
        isEmpty(accompanists) &&
        isEmpty(organisers)
      ) {
        e.cancel = true;
        e.promise = Promise.reject(
          "At least one field (Artists, Accompanists, Organisers) must be filled."
        );
      } else {
        setSettings((prev) => ({
          ...prev,
          quickAddTrigger: prev.quickAddTrigger + 1,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRowRemovedTrigger = () => {
    setSettings((prev) => ({
      ...prev,
      quickAddTrigger: prev.quickAddTrigger + 1,
    }));
  };

  useEffect(() => {
    try {
      if (props && Object.keys(props).length !== 0) {
        const draftData =
          props && props.data && props.data.data && props.data.data.draft_data;
        if (draftData) {
          const {
            quickadd_accompanists,
            quickadd_organisers,
            quickadd_artists,
          } = draftData;
          const quicKAddDetails = [];
          const maxLength = Math.max(
            quickadd_accompanists.length,
            quickadd_organisers.length,
            quickadd_artists.length
          );
          for (let i = 0; i < maxLength; i++) {
            const item = {
              quickadd_accompanists: quickadd_accompanists[i] || null,
              quickadd_organisers: quickadd_organisers[i] || null,
              quickadd_artists: quickadd_artists[i] || null,
            };
            quicKAddDetails.push(item);
          }
          setGridData(quicKAddDetails);
          setDataSource((prev) => ({
            ...prev,
            quickAddDataSource: quicKAddDetails,
          }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [props]);

  const onRowUpdatingData = (e) => {
    try {
      const updatedData = e.newData;
      const artists = updatedData?.quickadd_artists;
      const accompanists = updatedData?.quickadd_accompanists;
      const organisers = updatedData?.quickadd_organisers;

      const isEmpty = (value) =>
        value === undefined || value === "" || value === null;
      if (isEmpty(artists) && isEmpty(accompanists) && isEmpty(organisers)) {
        e.cancel = true;
        e.promise = Promise.reject(
          "At least one field (Artists, Accompanists, Organisers) must be filled."
        );
      } else {
        setSettings((prev) => ({
          ...prev,
          quickAddTrigger: prev.quickAddTrigger + 1,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DataGrid
      ref={gridRef}
      dataSource={gridData}
      showBorders={true}
      columnAutoWidth={true}
      rowAlternationEnabled={true}
      onSaving={onRowSaving}
      onRowRemoved={onRowRemovedTrigger}
      onRowUpdating={onRowUpdatingData}
      id="gridContainer"
    >
      <Paging enabled={true} />
      <Editing
        mode="row"
        allowUpdating={true}
        allowDeleting={true}
        allowAdding={true}
      />
      <Column
        dataField="quickadd_artists"
        caption="Artists"
        visible={!isJointEvent}
      />
      <Column
        dataField="quickadd_accompanists"
        caption="Accompanists"
        visible={!isJointEvent}
      />
      <Column dataField="quickadd_organisers" caption="Organisers" />
    </DataGrid>
  );
};

export default QuickAddGrid;
