import { BASEPATH } from "../../config/config";
import { ADMIN_BASEPATH } from "../../config/config";

export const IMAGE_URL = `${BASEPATH}src/assets/images/noimage`;

export const ORGANISER_URL= `${BASEPATH}public/images/organiser_images`;

export const Fan_images = `${BASEPATH}public/images/fan_images`;

export const FranchiseesImg = `${BASEPATH}uploads/franchisees`;

export const AristiImg = `${BASEPATH}public/images/blog_images`;

export const ArtistallImg =`${BASEPATH}public/images/artist_images`;

export const BlogImage = `${BASEPATH}public/images/blog_images`;
// export const BlogImage = `http://localhost:3001/public/images/blog_images`;

export const RecordImage = `${BASEPATH}public/images/category_images`;

export const BlogCategoryImage = `${ADMIN_BASEPATH}uploads/maincategory`;
// export const BlogCategoryImage = `http://localhost:3001/uploads/maincategory`;

export const VideoImagePath = `${BASEPATH}public/images/record_thumbnails`;

//added new
export const EventImg = `${BASEPATH}public/images/event_images`;
export const AdvertiserImg = `${BASEPATH}public/images/advertiser_images`;

export const VideoRecords = `${BASEPATH}public/images/record_thumbnails`;
