import React, { useState, useEffect, useRef } from 'react';
import './Playbyswara.css';
import { useNavigate } from 'react-router-dom';
import Gamecomponent from './Gamecomponent';
import ShareComponent from './ShareComponent';
import HowToPlay from './HowToPlay';
import { Helmet } from "react-helmet";
//import Confetti from "react-confetti";
import saSound from './sounds/Sa_new.wav';
import reSound from './sounds/Re_new.wav';
import gaSound from './sounds/Ga_new.wav';
import maSound from './sounds/Ma_new.wav';
import paSound from './sounds/Pa_new.wav';
import dhaSound from './sounds/Dha_new.wav';
import niSound from './sounds/Ni_new.wav';

const swaraSounds = {
  S: saSound,
  R: reSound,
  G: gaSound,
  M: maSound,
  P: paSound,
  D: dhaSound,
  N: niSound
};

// Function to play a sound file
const playSound = (swara) => {
  const audio = new Audio(swaraSounds[swara]);
  audio.play();
};


const Playbyswara = () => {
  const navigate = useNavigate(); // Hook to navigate to different routes
  const [solution, setSolution] = useState({ arohanam: [], avarohanam: [] });
  const [swara_raga_name, setSwaraRagaName] = useState('');
  const totalAttempts = 5; // 5 rows for 5 attempts
  // Initialize guesses and colors
  const [guesses, setGuesses] = useState(Array.from({ length: totalAttempts }, () => []));
  const [colors, setColors] = useState(Array.from({ length: totalAttempts }, () => []));
  const [totalCells,setTotalCells] =  useState(0);
  const [currentAttempt, setCurrentAttempt] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [feedback, setFeedback] = useState(''); // Feedback message
  const [isGameOver, setIsGameOver] = useState(false); // Track if the game is over
  const [focusedCell, setFocusedCell] = useState({ row: 0, col: 0 });
  const focusedCellRef = useRef(null);
  const [showHowTo, setShowHowTo] = useState(false);
  
  useEffect(() => {
    if (focusedCellRef.current) {
      focusedCellRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }, [focusedCell]);

  const getTodayDateString = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  };
  useEffect(() => {
    const todayDate = getTodayDateString();
    const savedDate = localStorage.getItem("swaraRiddleLastPlayedDate");
    

    if (savedDate !== todayDate) {
      console.log("initial no date");
      localStorage.removeItem("playBySwaraState");
      localStorage.removeItem("swaraguesses");
      localStorage.removeItem("swaracolors");
      localStorage.setItem("swaraRiddleLastPlayedDate", todayDate);
      localStorage.setItem("swaraInitailized", JSON.stringify(false));
      const ini = localStorage.getItem("swaraInitialized");
    console.log("ini:",ini);
    }
      else
      {
        console.log("initial date");
        const savedgamestate = JSON.parse(localStorage.getItem("playBySwaraState"));
        const savedGuesses = JSON.parse(localStorage.getItem("swaraguesses"));
        const savedcolors = JSON.parse(localStorage.getItem("swaracolors"));
        if(savedgamestate){
          setCurrentAttempt(savedgamestate.currentAttempt);
          setCurrentIndex(savedgamestate.currentIndex);
          setIsGameOver(savedgamestate.isGameOver);
          setFeedback(savedgamestate.feedback);
          setSwaraRagaName(savedgamestate.swara_raga_name);
          setTotalCells(savedgamestate.totalCells);
          setFocusedCell(savedgamestate.focusedCell);
        }
          if(savedGuesses){
          setGuesses(savedGuesses);
          if(savedcolors.length > 0)
          setColors(savedcolors);
          localStorage.setItem("swaraInitailized", true);
       }
      }
      
  }, []);
// Save the game state to localStorage whenever relevant data changes
useEffect(() => {
  localStorage.setItem("swaraguesses", JSON.stringify(guesses));
}, [guesses]);
useEffect(() => {
  localStorage.setItem("swaracolors", JSON.stringify(colors));
},[colors]);
useEffect(() => {
  const gameState = { currentAttempt, currentIndex,totalCells, swara_raga_name,isGameOver, feedback,focusedCell };
  localStorage.setItem("playBySwaraState", JSON.stringify(gameState));
}, [currentAttempt, currentIndex, totalCells,swara_raga_name,  isGameOver, feedback,focusedCell]);
  // Available swaras
  const swaras = [
    ["S", "R₁", "G₁", "M₁", "P", "D₁", "N₁", "Ṡ"],
    ["", "R\u2082", "G\u2082", "M\u2082", "", "D\u2082", "N\u2082",""],
    ["", "R\u2083", "G\u2083", "", "", "D\u2083", "N\u2083",""],
];


  // Handle data fetch from Gamecomponent
  const handleDataFetch = (data) => {
   const initial = localStorage.getItem('swaraInitialized');
    console.log("initial:",initial);
    if (initial) return;
    console.log("data fetch");
    let arohanam = data.arohanam.replace(/\u00A0/g, ' ').trim().split(' ');
    let avarohanam = data.avarohanam.replace(/\u00A0/g, ' ').trim().split(' ');
    let raga_name = data.raga;
    setSwaraRagaName(raga_name);
    setSolution({ arohanam, avarohanam });
    // Initialize guesses and colors
    const totalCells = arohanam.length + avarohanam.length;
    setTotalCells(totalCells);
    const guessesinlocal = JSON.parse(localStorage.getItem('swaraguesses'));
    const areAllEmpty = guessesinlocal.every(row => Array.isArray(row) && row.length === 0);
    
    if(areAllEmpty)
      {
        setGuesses(Array.from({ length: totalAttempts }, () => Array(totalCells).fill('')));
         setColors(Array.from({ length: totalAttempts }, () => Array(totalCells).fill(''))); 
      }
     //localStorage.setItem("swaraInitailized", JSON.stringify(true)); 
  };

  const handleSwaraPress = (swara) => {
    const totalCells = solution.arohanam.length + solution.avarohanam.length; // Calculate total cells
    if (currentAttempt >= totalAttempts || currentIndex >= totalCells) return;
    //   playSound(swara);
    setGuesses((prevGuesses) => {
      const updatedGuesses = prevGuesses.map((row, index) => (index === currentAttempt ? [...row] : row));
      updatedGuesses[currentAttempt][currentIndex] = swara;
      return updatedGuesses;
    });

    setCurrentIndex((prevIndex) => prevIndex + 1);
    setFocusedCell({ row: currentAttempt, col: currentIndex + 1 });

  };

  // Handle backspace functionality
  const handleBackspace = () => {
    if (currentIndex > 0) {
      setGuesses((prevGuesses) => {
        const updatedGuesses = prevGuesses.map((row, index) => (index === currentAttempt ? [...row] : row));
        updatedGuesses[currentAttempt][currentIndex - 1] = ''; // Clear the last swara
        return updatedGuesses;
      });
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setFocusedCell({ row: currentAttempt, col: currentIndex - 1 });

    }
  };

  // Validate the current guess
  const validateGuess = () => {
    if (isGameOver) return;
    const newColors = colors.map((row, index) => (index === currentAttempt ? [...row] : row));
    const currentRow = guesses[currentAttempt];
    const isRowComplete = currentRow.every(cell => cell !== '');
    if (!isRowComplete) return;

    const arohanamCount = {};
    const avarohanamCount = {};

    solution.arohanam.forEach(swara => {
      arohanamCount[swara] = (arohanamCount[swara] || 0) + 1;
    });

    solution.avarohanam.forEach(swara => {
      avarohanamCount[swara] = (avarohanamCount[swara] || 0) + 1;
    });

    // Validate Arohanam guesses
    currentRow.slice(0, solution.arohanam.length).forEach((swara, index) => {
      if (swara === solution.arohanam[index]) {
        newColors[currentAttempt][index] = 'correct';
        arohanamCount[swara]--;
      }
    });

    // Validate Avarohanam guesses
    currentRow.slice(solution.arohanam.length).forEach((swara, index) => {
      const actualIndex = index + solution.arohanam.length;
      if (swara === solution.avarohanam[index]) {
        newColors[currentAttempt][actualIndex] = 'correct';
        avarohanamCount[swara]--;
      }
    });

    // Second pass to mark present swaras
    currentRow.slice(0, solution.arohanam.length).forEach((swara, index) => {
      if (newColors[currentAttempt][index] !== 'correct' && arohanamCount[swara] > 0) {
        newColors[currentAttempt][index] = 'present';
        arohanamCount[swara]--;
      } else if (newColors[currentAttempt][index] === '') {
        newColors[currentAttempt][index] = 'incorrect';
      }
    });

    currentRow.slice(solution.arohanam.length).forEach((swara, index) => {
      const actualIndex = index + solution.arohanam.length;
      if (newColors[currentAttempt][actualIndex] !== 'correct' && avarohanamCount[swara] > 0) {
        newColors[currentAttempt][actualIndex] = 'present';
        avarohanamCount[swara]--;
      } else if (newColors[currentAttempt][actualIndex] === '') {
        newColors[currentAttempt][actualIndex] = 'incorrect';
      }
    });

    setColors(newColors);

    // Check if the guess is correct
    const isArohanamCorrect = currentRow.slice(0, solution.arohanam.length).every((swara, index) => swara === solution.arohanam[index]);
    const isAvarohanamCorrect = currentRow.slice(solution.arohanam.length).every((swara, index) => swara === solution.avarohanam[index]);

    if (isArohanamCorrect && isAvarohanamCorrect) {
      setFeedback(`<h3>Congratulations!</h3> <h5> You've guessed correctly!</h5><h5><br>Raga:${swara_raga_name}<br> Arohanam : ${solution.arohanam.join(' ')} <br> Avarohanam : ${solution.avarohanam.join(' ')}</h5>`);
      setIsGameOver(true); // End the game if the guess is correct
    } else if (currentAttempt === totalAttempts - 1) {
      setFeedback(`<h3>Game Over!</h3><h5>The correct raga is ${swara_raga_name}.<br> Arohanam : ${solution.arohanam.join(' ')} <br> Avarohanam : ${solution.avarohanam.join(' ')}</h5>`);
      setIsGameOver(true); // End the game if the last attempt is reached
    }

    // Move to the next attempt
    if (currentAttempt < totalAttempts - 1) {
      setCurrentAttempt((prevAttempt) => prevAttempt + 1);
      setCurrentIndex(0);
      setFocusedCell({ row: currentAttempt + 1, col: 0 });
    }
  };
  const handleCellClick = (rowIndex, cellIndex) => {
    if (rowIndex === currentAttempt && !isGameOver) {
      setCurrentIndex(cellIndex); // Set the selected cell as the current index for input
      setFocusedCell({ row: currentAttempt, col: cellIndex });
    }
  };



  // Render the grid
  const renderGrid = () => {
    return (
      <>
       <Helmet>
    <title>RagaRiddle - Play by Swara</title>
    <meta
          name="description"
          content="RagaRiddle - Play by Swara mode - Guess today's raga name in 5 chances.Start by selecting the swaras and guess the raga name based on resultant colors of your guess."
        />
    </Helmet>
        <div className="container">
          <div className="overflow-auto" style={{ display: "grid" }}>
            <div className="container d-flex justify-content-around">
              <div className="space d-none d-md-block"></div>
              <div className="header-title DisplayColumn">Arohanam</div>
              <div className="space"></div>
              <div className="header-title DisplayColumn">Avarohanam</div>
              <div className="space d-none d-md-block"></div>
            </div>
            {guesses.map((row, rowIndex) => (
              <div key={`guess-row-${rowIndex}`} className="swara-grid-row">
                <div className="arohanam-column DisplayColumn">
                  {row.slice(0, solution.arohanam.length).map((swara, ar_cellIndex) => (
                    <div key={`arohanam-cell-${rowIndex}-${ar_cellIndex}`} className="d-flex flex-column">
                      {rowIndex === 0 && <p className="text-center">{ar_cellIndex + 1}</p>}
                      <div className={`swara-grid-cell ${colors[rowIndex][ar_cellIndex]}`}>
                        {swara}
                      </div>
                    </div>
                  ))}
                </div>
  
                <div className="space"></div>
                <div className="avarohanam-column DisplayColumn">
                  {row
                    .slice(solution.arohanam.length)
                    .map((swara, av_cellIndex) => (
                      <div key={`avarohanam-cell-${rowIndex}-${av_cellIndex}`} className="d-flex flex-column">
                        {rowIndex === 0 && <p className="text-center">{av_cellIndex + 1}</p>}
                        <div
                          ref={
                            focusedCell.row === rowIndex &&
                            focusedCell.col === av_cellIndex + solution.arohanam.length
                              ? focusedCellRef
                              : null
                          }
                          className={`swara-grid-cell ${colors[rowIndex][av_cellIndex + solution.arohanam.length]}
                          ${focusedCell.row === rowIndex && focusedCell.col === av_cellIndex + solution.arohanam.length ? 'focused' : ''}`}
                          onClick={() => handleCellClick(rowIndex, av_cellIndex + solution.arohanam.length)}
                        >
                          {swara}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };
  

  // Handle Play Swara Mode (navigate to another route)
  const handlePlayRaga = () => {
    navigate('/ragariddle/playbyraganame'); // Navigate to PlaybySwara page
  };

  // Handle closing the result message
  const handleCloseResultMessage = () => {
  //  setFeedback(''); // Clear the result message when close button is pressed
  document.getElementsByClassName("feedback-message-container")[0].style.display = 'none';
  };
  const generateSwaraShareableMessage = () => {
    let msg = "Checkout RagaRiddle - A fun puzzle!\n"; // Initial message
    const greenEmoji = "🟩";  // Correct position
    const blueEmoji = '\u{1F7E6}'; // Close, but wrong position
    const grayEmoji = "⬜";   // Incorrect letter

    for (let i = 0; i < 5; i++) {
      let row = ""; // Initialize a row string
      for (let j = 0; j < solution.arohanam.length + solution.avarohanam.length; j++) {
        // Map the cell state to the corresponding emoji
        if (colors[i][j] === 'correct') {
          row += greenEmoji;
        } else if (colors[i][j] === 'present') {
          row += blueEmoji;
        } else {
          row += grayEmoji;
        }
      }
      msg += row + '\n'; // Add the row to the message with a new line
    }

    return msg.trim(); // Return the final message
  };
  const toggleHowTo = () => {
    setShowHowTo(!showHowTo);
  };


  return (
    <div className="play-by-swara RagaRiddle container-fluid">
      <h1>RagaRiddle</h1>
      <h6 className="mb-4 ">Play by swara</h6>
      {/* Fetch the solution using the Gamecomponent */}
      <Gamecomponent gameType="swaras" onDataFetch={handleDataFetch} />
      {/* Show How to Play Instructions */}
      {showHowTo && <HowToPlay onClose={toggleHowTo} />}

      {renderGrid()}
      <div className="virtual-keyboard">

        {swaras.map((row, rowIndex) => (
          <div key={rowIndex} className="keyboard-row">
            {row.map((swara, index) => (
              <button key={index} 
              className={swara ? "swara-key keyboard-key" : "empty-swara-key" }
              onClick={() => handleSwaraPress(swara)} disabled={!swara||isGameOver}>
                {swara}

              </button>
            ))}
          </div>
        ))}
        <div className='d-flex justify-content-between'>
          {/* Add a Backspace key */}
          <button className="wide-key keyboard-key"
          onClick={validateGuess}>Enter</button>
          <button className="wide-key keyboard-key"
          onClick={handleBackspace} disabled={isGameOver || currentIndex === 0}>
            Backspace
          </button>
           </div>
      </div>

      {feedback && (<div className='feedback-message-container text-center '>
        <button className="close-button" onClick={handleCloseResultMessage}>
          &times;
        </button>
        <div className="feedback-message text-center px-2" dangerouslySetInnerHTML={{ __html: feedback }} />
        <div className='d-flex justify-content-center'>
          <button className="button mt-4" onClick={handlePlayRaga}>
            Play by Raga name
          </button>
        </div>
        <div className='my-3'>
          {/* <p className='text-decoration-underline'>Share</p> */}
        <ShareComponent shareMessage={generateSwaraShareableMessage()}></ShareComponent>
        </div>
      </div>)}
      <div className="share-icons">
      {!showHowTo && (
          <button style={{color:"#3f00ff"}} className="mt-3 rounded  d-flex  justify-content-end" onClick={toggleHowTo}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path  d="M7.879 5.519C9.05 4.494 10.95 4.494 12.121 5.519C13.293 6.544 13.293 8.206 12.121 9.231C11.918 9.41 11.691 9.557 11.451 9.673C10.706 10.034 10.001 10.672 10.001 11.5V12.25M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 15.25H10.008V15.258H10V15.25Z" 
            stroke="#3f00ff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
      )}
      </div>
     
    </div>

  );
}

export default Playbyswara;
