import notify from "devextreme/ui/notify";

function HandleErrorToast( error, message, timeout) {
  return notify({
    message: message,
    type: error,
    displayTime: timeout,
    height: 50,
    width: 600
  });
  
}

export default HandleErrorToast;
