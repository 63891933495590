import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { IoChevronBack } from "react-icons/io5";
import About_artist from "./About_artist.js";
import { profilebuttons } from "../../../Subpages/profiles/constent.js";
import AuthContext from "../../../context/AuthContext.js";
import { ArtistallImg } from "../../../Subpages/MainPage/constant.js";
import MyCalendar_artist from "./MyCalendar_artist.js";
import { IoShareSocial } from "react-icons/io5";


import { useNavigate } from "react-router-dom";
import { FaShare } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { BsFillSendFill } from "react-icons/bs";
import { BASEPATH } from "../../../config/config.js";
import ReactPlayer from "react-player";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster.js";
import TextBox from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import Contact_artist from "./Contact_artist.js";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import HandleErrorToast from "../../../Toaster/Failure/Failure.js";
import TaggedBlogs_artist from "./TaggedBlogs_artist.js";
import Videos_artist from "./Videos_artist.js";
import ShareToSocialMedia_artist from "./ShareToSocialMedia_artist.js";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SHARE_BASEPATH } from "../../../config/config.js";
import {
  GET_IMAGES_PROFILE,
  LIKE_SINGLE_POST,
  DELETECOMMENT_SINGLE_POST,
  EDITCOMMENT_SINGLE_POST,
  COMMENT_SINGLE_POST,
  GET_POSTS_PROFILE,
  DELETE_SINGLE_POST,
  REPORT_POST,
  GET_LIKES,
  GET_POSTS_COMMENTS,
  GET_LIKES_BY_USER,
  FOLLOW_A_USER,
  UNFOLLOW_A_USER,
  FOLLOW_INITIAL_CHECK,
  GET_USERPROFILE
} from "../../../Api/api.js";

import { Helmet } from "react-helmet";

//import mlogo from "./mlogo.png";

import Front from "../../../Auth/Front.js";
import "./Styles/artistDetail.css";
import Followers_artist from "./Followers_artist.js";
import Following_artist from "./Following_artist.js";

//newly added-
import { formatDistanceToNow } from "date-fns";
import { Dropdown } from "react-bootstrap";
import Noimage_circle from "../../../global/NoImage_circle.js";
import { Fan_images } from "../../../Subpages/MainPage/constant.js";
import { ORGANISER_URL } from "../../../Subpages/MainPage/constant.js";
import { FaArrowLeft } from 'react-icons/fa';
import Noimage_profilepic from "../../../global/NoImage_profilepic.js";
import NoDataFound from "../../../global/NoDataFound.js";
import { isValidImage, imageExists } from "../../../global/ImageCheck.js";
import Noimage from "../../../global/NoImage.js";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, } from 'react-share';
import MyForms_artist from "./MyForms_artist.js";
import getProfileKey from "../../../Api/getProfileKey.js";

export default function ArtistPerticularDetails() {

  const ARTIST_KEY = getProfileKey().profileKey;
  //console.log(ARTIST_KEY, "profilekey");
  const [loadingLikes, setLoadingLikes] = useState({});

  //
  const eventhandleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const token = localStorage.getItem("MDnD_auth");

  // -----------------------------------------------------------------------------------------
  const [show, setShow] = useState(false);

  const ehandleShow = () => setShow(false);
  const eventhandleShow = () => setShow(true);

  const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputRef.current.value).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  // ------------------------------------------Delete Comments--------------------------------------------------------------------

  // const useAuth = () => useContext(AuthContext);
  // const { user1 } = useAuth();
  // console.log(user1,"USER");
  const { user, setUser, authTokens, setAuthTokens, logoutUser } =
    useContext(AuthContext);

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [selectedGenre, setSelectedGenre] = useState("");
  const [likedPosts, setLikedPosts] = useState({});
  const currentDate = new Date(2021, 2, 28);
  const views = ["week", "month"];

  const [activeTab, setActiveTab] = useState("post");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [isHovered, setIsHovered] = useState(false);
  const [checkereditComment, setCheckerEditComment] = useState(0);
  const [likenamescounter, setlikenamescounter] = useState(0);

  const [time, setTime] = useState();
  const [finalDate, setFinalDate] = useState("");

  const [organiserData, setOrganiserData] = useState({});
  const fileInputRef = useRef();
  const [counter, setCounter] = useState(0);
  const [arrayoflikenames, setArrayofLikeNames] = useState([]);

  const [checkerforMenuClassname, setcheckerforMenuClassname] = useState(true);

  //another way of token-
  // const [user, setUser] = useState(() =>
  //   localStorage.getItem("MDnD_auth")
  //     ? jwtDecode(localStorage.getItem("MDnD_auth"))
  //     : null
  // );
  //console.log(user, "USER from jwt");

  const [postCollection, setPostCollection] = useState({
    post_textbox: "",
    post_image: "",
    base64Image: "",
    videolink: "",
    audiolink: "",
    validation_msg1: "",
    validation_msg2: "",
    regex_msg_video: "",
    regex_msg_audio: "",
    something_about: "",
    id_for_delete: "",
    comment_text: "",
    id_for_comment: "",
    validation_for_comments: "",
  });

  const [displayProfilePosts, setDisplayProfilePosts] = useState({
    gridData: "",
    CommentsData: "",
    LikesData: "",
    id_for_like: "",
    likeStatus: "",
    LikesData_likeorUnlike: "",
    gridTrigger: false,
    reporttext: "",
    validationmsg_for_reporttext: "",
    postid_for_report: "",
    newfilteredGridData: "",

  });

  const [updatedComments, setUpdatedComments] = useState([]);
  const [namesofLikesforpostid, setNamesofLikesforPostid] = useState({});
  const [hoveredPostId, setHoveredPostId] = useState(null);
  const [postLikeStatus, setPostLikeStatus] = useState({});

  const [editedComment, setEditedComment] = useState([]);
  const [deleteComment, setDeleteComment] = useState([]);
  const [newlikedstatus, setnewLikedStatus] = useState("");
  const [counterlike, setCounterLike] = useState(0);
  const [newupdatedComments, setNewUpdatedComments] = useState([]);

  const [checkinitialFollow, setCheckInitialFollow] = useState("");
  const [counterforFollow, setcounterforFollow] = useState(0);

  const [photoOrganiser, setPhotoOrganiser] = useState({});

  const [filteredPostCollection, setFilteredPostCollection] = useState({});
  //const [formDataFinal, setFormDataFinal] = useState(new FormData());
  const [popup, setPopup] = useState({
    popupVisible: false,
    deleteornot: false,
    commentbox_visible: false,
    report_visible: false,
    anon_goestologin: false,
    names_likes: false,
    //popupDetails: "",
  });
  const [counteruserprofile_follow, setCounterUserProfile_follow] = useState(0);

  const location = useLocation();
  //let roleid_fromprevious = location.state.profileData.role_id;

  const urlParams = new URLSearchParams(window.location?.search);


  const decodeBase64 = (encoded, key) => {
    //const prefix = "xy4AT";
    try {
      const decodedStr = atob(encoded);
      if (decodedStr.startsWith(key)) {
        return decodedStr.slice(key.length);
      } else {
        console.error("Decoding error: Prefix not found");
        return null;
      }
    } catch (error) {
      console.error("Decoding error:", error);
      return null;
    }
  };



  const rid = urlParams?.get("rid");
  const decodedrid = rid && decodeBase64(rid, ARTIST_KEY);


  //const role = location.state.role;
  const profileData = location.state?.profileData;
  const artistId = location.state?.artistId;
  const followerId = location.state?.follower_id;

  //let roleid_fromprevious = profileData ? profileData.role_id : artistId || "";
  let roleid_fromprevious =
    profileData?.role_id ?? artistId ?? followerId ?? "";

  //console.log(roleid_fromprevious, "roleid_fromprevious from useLocation");

  //console.log(roleid_fromprevious, "roleid_fromprevious from useLocation");

  const [roleidstate, setroleidstate] = useState("");
  const [loginidstate, setloginidstate] = useState("");
  const [namestate, setnamestate] = useState("");

  const [contact, setContact] = useState("");

  useEffect(() => {
    if (roleid_fromprevious) {
      setroleidstate(roleid_fromprevious);
    } else if (decodedrid) {
      setroleidstate(Number(decodedrid));
    }
  }, [roleid_fromprevious, decodedrid]);

  //console.log(roleidstate, "roleidstate");
  //console.log(decodedrid, "decodedrid");

  const groups = ["employeeID"];

  //social media links- useEffect-......................

  const [socialmediaCollection, setSocialmediaCollection] = useState({
    facebook_link: "",
    twitter_link: "",
    instagram_link: "",
  });

  useEffect(() => {
    const fetchSocialMedia = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}profiles/get_profilesocial`,
          {
            roleid: roleidstate,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );
        if (response.data !== null) {
          let filteredData_facebook =
            response.data.data &&
            response.data.data.filter((item) => item.media_type == "facebook");
          //console.log(filteredData_facebook, "filteredData facebook");

          if (filteredData_facebook && filteredData_facebook.length > 0) {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              facebook_link: filteredData_facebook[0].link,
            }));
          } else {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              facebook_link: "",
            }));
          }
          //twitter
          let filteredData_twitter =
            response.data.data &&
            response.data.data.filter((item) => item.media_type == "twitter");

          if (filteredData_twitter && filteredData_twitter.length > 0) {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              twitter_link: filteredData_twitter[0].link,
            }));
          } else {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              twitter_link: "",
            }));
          }
          // instagram..
          let filteredData_instagram =
            response.data.data &&
            response.data.data.filter((item) => item.media_type == "instagram");

          if (filteredData_instagram && filteredData_instagram.length > 0) {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              instagram_link: filteredData_instagram[0].link,
            }));
          } else {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              instagram_link: "",
            }));
          }
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("error fetching social media links", error);
      }
    };
    if (roleidstate !== "") {
      fetchSocialMedia();
    }
  }, [roleidstate]);

  //console.log(socialmediaCollection, "socialmediaCollection");

  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };
  // end of social media links..............

  //get Profile deatils......

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}profiles/get_user_profiledetails`,
          {
            roleid: roleidstate,
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );
        //console.log(response.data.data[0].createddate, "this is date need");
        if (response.data !== null) {
          setTime(response.data.data[0].createddate);
          setOrganiserData(response.data);
        } else {
          setTime("");
          setOrganiserData("");
        }
      } catch (error) {
        console.error("Error fetching organiser data:", error);
      }
    };
    if (roleidstate) {
      fetchData();
    }
  }, [roleidstate, counteruserprofile_follow]);

  // to get the login id
  useEffect(() => {
    if (organiserData.data) {
      setloginidstate(organiserData.data[0].login_id);
    } else {
      setloginidstate("");
    }
  }, [organiserData]);

  //to get the name
  useEffect(() => {
    if (organiserData.data) {
      setnamestate(organiserData.data[0].name);
    } else {
      setnamestate("");
    }
  }, [organiserData]);

  //to set Contacts/messages..

  useEffect(() => {
    if (user?.role_id !== roleidstate) {
      setContact("Contact");
    } else if (user?.role_id === roleidstate) {
      setContact("Messages");
    }
  }, [roleidstate]);

  //console.log(time, "time");

  useEffect(() => {
    if (time) {
      const dateObj = new Date(time);
      const day = dateObj.getUTCDate();
      const month = dateObj.getUTCMonth() + 1;
      const year = dateObj.getUTCFullYear();
      const finalDate_d = `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month
        }-${year}`;

      if (finalDate_d) {
        setFinalDate(finalDate_d);
      } else {
        setFinalDate("");
      }
    }
  }, [time]);

  //functions

  //console.log(organiserData, "OG of DInesh");

  //for photo pic.....................................
  useEffect(() => {
    if (organiserData && organiserData?.data?.[0]?.photo) {
      setPhotoOrganiser(organiserData.data[0]);
    } else {
      setPhotoOrganiser("");
    }
  }, [organiserData]);

  const handleClose = () => {
    setPostCollection((prevState) => ({ ...prevState, validation_msg2: "" }));
    setPostCollection((prevState) => ({ ...prevState, regex_msg_audio: "" }));
    setPostCollection((prevState) => ({ ...prevState, regex_msg_video: "" }));
    //setPopup({ popupVisible: false });
    setPopup((prevState) => ({ ...prevState, popupVisible: false }));
    setPopup((prevState) => ({ ...prevState, deleteornot: false }));
  };

  const handleCloseafterSubmit = () => {
    setPostCollection({
      post_textbox: "",
      post_image: "",
      base64Image: "",
      videolink: "",
      audiolink: "",
      validation_msg1: "",
      validation_msg2: "",
      regex_msg_video: "",
      regex_msg_audio: "",
      something_about: "",
    });
  };

  //console.log(postCollection, "postCollection");

  //CODE FOR DISPLAYING THE POSTS IN PROFILE.......................
  useEffect(() => {
    if (roleidstate) { mutationgetprofile.mutate(roleidstate); }
    else {
      return;
    }
  }, [counter, roleidstate, counterlike]);

  const fetchSingleProfile = (roleid) => GETPROFILE(GET_POSTS_PROFILE, roleid);

  const mutationgetprofile = useMutation({
    mutationKey: ["fetchPostsProfile"],
    mutationFn: (roleid) => fetchSingleProfile(roleid),
    onSuccess: (data) => {
      if (data.status === "success") {
        setDisplayProfilePosts((prev) => ({
          ...prev,
          newfilteredGridData: data.data,
        }));
      } else {
        setDisplayProfilePosts((prev) => ({
          ...prev,
          newfilteredGridData: "",
        }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      //alert("Did not fetch profile")
    },
  });

  const GETPROFILE = async (GET_POSTS_PROFILE, roleid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_POSTS_PROFILE}`,

        data: {
          login_user_roleid: user ? Number(user.role_id) : 0,
          user_roleid: Number(roleid),
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };
  //console.log(user, "user in Artists page");
  //console.log(displayProfilePosts.gridData, "displayProfilePosts.gridData");
  //to DELETE A POST...........................

  const handleModalDelete = (pmeter1) => {
    //console.log(pmeter1, "pmeter1");

    if (user?.role_id === roleidstate) {
      setPopup((prevState) => ({ ...prevState, deleteornot: true }));
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_delete: pmeter1,
      }));
    } else if (user) {
      return;
    } else if (!user) {
      navigate("/Login");

      return;
    } else {
      return;
    }
  };

  const handleYesDelete = () => {
    if (postCollection.id_for_delete) {
      mutationDeleteprofile.mutate(postCollection.id_for_delete);

      setPopup((prevState) => ({ ...prevState, deleteornot: false }));
    } else {
      return;
    }
  };

  const DeleteSingleProfile = (deleteid) =>
    DELETEONEPOST(DELETE_SINGLE_POST, deleteid);

  const DELETEONEPOST = async (DELETE_SINGLE_POST, deleteid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETE_SINGLE_POST}`,

        data: {
          postid: deleteid,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          posttype: 1,
          status: "N",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationDeleteprofile = useMutation({
    mutationKey: ["DeleteSingleProfile"],
    mutationFn: (deleteid) => DeleteSingleProfile(deleteid),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from DELETE");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Post Deleted Successfully", 3000);

        setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));
        setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast("error", "Did Not Delete the Post", 3000);
      setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const handleNoDelete = () => {
    setPostCollection((prevState) => ({ ...prevState, id_for_delete: "" }));
    setPopup((prevState) => ({ ...prevState, deleteornot: false }));
  };

  //console.log(displayProfilePosts, "displayProfilePosts");

  // CODE FOR COMMENTS IN PROFILE.......................................

  const handleComment = (pmeter1) => {
    //console.log(pmeter1, "pmeter1 comment");

    if (user) {
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: pmeter1,
      }));

      setPopup((prevState) => ({ ...prevState, commentbox_visible: true }));

      if (pmeter1) {
        mutationgetComment.mutate(pmeter1);
      } else {
        return;
      }
    } else {
      setPopup((prevState) => ({ ...prevState, anon_goestologin: true }));
      //navigate("/Login");
      //handlegotoLogin();
      return;
    }
  };

  //fetch comments from backend- tanstack

  const fetchComments = (postid_comment) =>
    GETCOMMENTS(GET_POSTS_COMMENTS, postid_comment);

  const GETCOMMENTS = async (GET_POSTS_COMMENTS, postid_comment) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_POSTS_COMMENTS}`,

        data: {
          postid: postid_comment,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationgetComment = useMutation({
    mutationKey: ["fetchComments"],
    mutationFn: (postid_comment) => fetchComments(postid_comment),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data.data, "data.data");

        setDisplayProfilePosts((prev) => ({
          ...prev,
          CommentsData: data.data,
        }));
        setCounter(counter + 1);
      } else {
        setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
    },
  });

  const handleCommentTextbox = (e) => {
    //console.log(e, "EVENT");
    if (e) {
      setPostCollection((prevState) => ({
        ...prevState,
        comment_text: e.value,
      }));
      if (e?.value?.length > 0) {
        setPostCollection((prev) => ({ ...prev, validation_for_comments: "" }));
      }
    } else {
      return;
    }
  };

  const handleCommentReset = () => {
    setPostCollection((prevState) => ({
      ...prevState,
      comment_text: "",
      id_for_comment: "",
      validation_for_comments: "",
    }));

    setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
  };

  const handleCommentSubmitButton = () => {
    if (postCollection.comment_text === "") {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "Please write a comment",
      }));
    } else {
      setPostCollection((prevState) => ({
        ...prevState,
        validation_for_comments: "",
      }));
      mutationCommentprofile.mutate(postCollection.id_for_comment);
    }
  };

  const CommentSingleProfile = (commentid) =>
    COMMENTONEPOST(COMMENT_SINGLE_POST, commentid);

  const COMMENTONEPOST = async (COMMENT_SINGLE_POST, commentid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${COMMENT_SINGLE_POST}`,

        data: {
          postid: commentid,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: postCollection.comment_text
            ? postCollection.comment_text
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationCommentprofile = useMutation({
    mutationKey: ["CommentSingleProfile"],
    mutationFn: (commentid) => CommentSingleProfile(commentid),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from Comments");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment Posted Successfully!", 3000);

        setPostCollection((prevState) => ({
          ...prevState,
          id_for_comment: "",
          comment_text: "",
        }));

        setDisplayProfilePosts((prevState) => {
          const updatedPosts = prevState.newfilteredGridData.reduce(
            (acc, post) => {
              if (post.postid === data.data.post_id) {
                // Update the specific post
                acc.push({
                  ...post,
                  countpostcomment: post.countpostcomment + 1,
                });
              } else {
                // Add the post as is
                acc.push(post);
              }
              return acc;
            },
            []
          );

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });
        setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));
        //setCounter(counter + 1);

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast("error", "Comment could not be Posted", 3000);
      setPostCollection((prevState) => ({
        ...prevState,
        id_for_comment: "",
        comment_text: "",
      }));
      setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  //console.log(postCollection, "postCollection");
  //console.log(displayProfilePosts.gridData, "displayProfilePosts.gridData");
  //console.log(updatedComments,"updatedComments");

  // new changes

  const uniquePostIds = [];

  let filteredGridData =
    displayProfilePosts.gridData &&
    displayProfilePosts.gridData.filter((item) => {
      if (!uniquePostIds.includes(item.postid)) {
        uniquePostIds.push(item.postid);
        return true;
      }
      return false;
    });



  useEffect(() => {
    if (displayProfilePosts.CommentsData) {
      let comments_for_that_id =
        displayProfilePosts.CommentsData && displayProfilePosts.CommentsData[0];
      setNewUpdatedComments(comments_for_that_id);
    }
  }, [displayProfilePosts.CommentsData]);

  const handleCommentsEditing = (e, commentId) => {
    const index =
      newupdatedComments &&
      newupdatedComments.findIndex(
        (comment) => comment.post_comm_id === commentId
      );

    if (index !== -1) {
      const updated = [...newupdatedComments];
      updated[index] = { ...updated[index], comments_text: e.target.value };
      setNewUpdatedComments(updated);
    }
  };

  function formatDate(timestamp) {
    if (timestamp) {
      return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    } else {
      return;
    }
  }

  const handleEdit = (pmeter1) => {
    //setCheckerEditComment(true);
    let Comment_Edit =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(Comment_Edit, "comment_edit");

    setCheckerEditComment(Comment_Edit[0].post_comm_id);
    setcheckerforMenuClassname(false);
  };

  const handleCancelforCommentEdit = () => {
    setCheckerEditComment(0);
    setcheckerforMenuClassname(false);
  };

  const handlemenuchange = () => {
    setcheckerforMenuClassname(true);
  };



  //newly added..
  const [imageErrorComment, setImageErrorComment] = useState(false);

  const handleImageError_comment = () => {
    setImageErrorComment(true);
  };



  const commentsComponent = () => {
    return (
      <div>
        {newupdatedComments &&
          newupdatedComments.map((comment) => (
            <div key={comment.post_comm_id}>
              <ul className="list-inline m-0 p-0">
                <li className="py-2">
                  <div className="d-flex align-items-sm-center align-items-start borderLine">
                    <div className="about-user w-100 ms-2 d-flex flex-column gap-2 commentPopText">
                      <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 ">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="flex-shrink-0 ">

                            {
                              comment.role_name === "artist" ? (
                                isValidImage(comment?.profile_photo) && imageExists(`${ArtistallImg}/${comment?.profile_photo}`) ? (
                                  <img
                                    src={`${ArtistallImg}/${comment?.profile_photo}`}

                                    //onError={handleImageError_comment}
                                    className="commentImg rounded-circle"

                                    alt="Artist"
                                  />
                                ) : (
                                  <Noimage_circle />
                                )
                              ) : comment.role_name === "organiser" ? (
                                isValidImage(comment?.profile_photo) && imageExists(`${ORGANISER_URL}/${comment?.profile_photo}`) ? (
                                  <>
                                    <img
                                      src={`${ORGANISER_URL}/${comment?.profile_photo}`}


                                      className="commentImg rounded-circle"
                                      alt="Organiser"
                                    />
                                  </>) : (
                                  <Noimage_circle />
                                )
                              ) : comment.role_name === "fan" ? (
                                isValidImage(comment?.profile_photo) && imageExists(`${Fan_images}/${comment?.profile_photo}`) ? (
                                  <>
                                    <img
                                      src={`${Fan_images}/${comment?.profile_photo}`}


                                      className="commentImg rounded-circle"
                                      alt="Fan"
                                    />
                                  </>) : (
                                  <Noimage_circle />
                                )
                              ) : (
                                <Noimage_circle />
                              )
                            }



                          </div>

                          <div className="d-flex">
                            <div className="d-flex flex-column">
                              <p className="mb-0 commentPopName">
                                <b>{comment.artist_name}</b>
                                <span className="commentDayText ms-2">
                                  {formatDate(comment.created_date)}
                                </span>
                              </p>
                              <p
                                className="m-0 p-0"
                                style={{ fontSize: "12px" }}
                              >
                                {comment.role_name}
                              </p>
                            </div>

                            <span className="published-date ms-2">
                              {/* {" "}
                          {formatDate(comment.created_date)} */}
                            </span>
                          </div>
                        </div>

                        <div id="container">


                          {checkereditComment === 0 && (<>
                            <Dropdown style={{ width: "40px", height: "40px" }}>
                              <Dropdown.Toggle
                                variant="primary"
                                id="dropdown-basic"
                                className="bg-white button"
                                style={{
                                  border: "none",
                                  width: "40px",
                                  height: "40px",
                                  color: "black",
                                  padding: 0,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <BsThreeDots style={{ color: "black" }} /> {/* Custom Icon */}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {user?.login_id === comment.login_id &&
                                  user?.role_id === comment.role_id && (
                                    <>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleEdit(comment.post_comm_id)
                                        }
                                      >
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleDeleteComment(
                                            comment.post_comm_id
                                          )
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    </>
                                  )}
                              </Dropdown.Menu>
                            </Dropdown> </>)}
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 mb-1 commentText">


                          {checkereditComment !== comment.post_comm_id ? (
                            comment.comments_text
                          ) : (
                            <div className="d-flex flex-column gap-2">
                              <input
                                type="text"
                                placeholder="Write a Comment!"
                                value={comment.comments_text}
                                onChange={(e) => {
                                  if (
                                    user?.login_id === comment.login_id &&
                                    user?.role_id === comment.role_id
                                  ) {
                                    handleCommentsEditing(
                                      e,
                                      comment.post_comm_id
                                    );
                                  }
                                }}
                              />
                              <div className="d-flex gap-2 align-items-center">
                                {user?.login_id === comment.login_id &&
                                  user?.role_id === comment.role_id && (
                                    <button
                                      className="button"
                                      onClick={() =>
                                        handleSaveComment(comment.post_comm_id)
                                      }
                                    >
                                      Save
                                    </button>
                                  )}

                                <button
                                  className="border-button"
                                  onClick={handleCancelforCommentEdit}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          ))}


      </div>
    );
  };

  const handleSaveComment = (pmeter1) => {
    let EditC =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(EditC, "Edit this Comments");

    if (EditC) {
      setEditedComment(EditC);

      mutationEditComment.mutate(editedComment);
    } else {
      return;
    }
  };

  const EditCommentSingleProfile = (editedComment) =>
    EDITCOMMENTONEPOST(EDITCOMMENT_SINGLE_POST, editedComment);

  const EDITCOMMENTONEPOST = async (EDITCOMMENT_SINGLE_POST, editedComment) => {
    //console.log(editedComment, "editedComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${EDITCOMMENT_SINGLE_POST}`,

        data: {
          postid: editedComment ? editedComment[0].postid : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          comments: editedComment ? editedComment[0].comments_text : "",
          postcommentid: editedComment ? editedComment[0].post_comm_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationEditComment = useMutation({
    mutationKey: ["EditComment"],
    mutationFn: () => EditCommentSingleProfile(editedComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment Edited Successfully", 3000);

        setEditedComment("");

        setCounter(counter + 1);
        handleCancelforCommentEdit();
        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Edit the Comment. Something Went Wrong!",
        3000
      );
      setEditedComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const handleDeleteComment = (pmeter1) => {
    let DeleteC =
      newupdatedComments &&
      newupdatedComments.filter((item) => item.post_comm_id == pmeter1);

    //console.log(DeleteComment,"Delete this Comment");

    if (DeleteC) {
      setDeleteComment(DeleteC);
      mutationDeleteComment.mutate(deleteComment);
    } else {
      return;
    }
  };

  const DeleteCommentSingleProfile = (deleteComment) =>
    DELETECOMMENTONEPOST(DELETECOMMENT_SINGLE_POST, deleteComment);

  const DELETECOMMENTONEPOST = async (
    DELETECOMMENT_SINGLE_POST,
    deleteComment
  ) => {
    //console.log(deleteComment, "deleteComment");
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DELETECOMMENT_SINGLE_POST}`,

        data: {
          postid: deleteComment ? deleteComment[0].post_id : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          status: "N",
          postcommentid: deleteComment ? deleteComment[0].post_comm_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationDeleteComment = useMutation({
    mutationKey: ["DeleteComment"],
    mutationFn: () => DeleteCommentSingleProfile(deleteComment),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA from EDITING A COMMENT");

      if (data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        SuccessNewToaster("success", "Comment deleted Successfully!", 3000);

        setDeleteComment("");

        //setCounter(counter + 1);
        setNewUpdatedComments((prev) =>
          prev.filter(
            (comment) => comment.post_comm_id !== data?.data?.post_comm_id
          )
        );

        setDisplayProfilePosts((prevState) => {
          const updatedPosts = prevState.newfilteredGridData.reduce(
            (acc, post) => {
              if (post.postid === data.data.post_id) {
                // Update the specific post
                acc.push({
                  ...post,
                  countpostcomment: post.countpostcomment - 1,
                });
              } else {
                acc.push(post);
              }
              return acc;
            },
            []
          );

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });

        //alert("successfully updated by vicki");
        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Delete the Comment. Something Went Wrong!",
        3000
      );
      setDeleteComment("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  const commentsCount = (postid) => {
    let postComments_main =
      updatedComments &&
      updatedComments.filter((comment) => comment.postcommandpostid === postid);
    let postCommentsCount = postComments_main ? postComments_main.length : 0;

    return postCommentsCount;
  };


  //newly added..
  const [imageErrorHeader, setImageErrorHeader] = useState(false);

  const handleImageError_Header = () => {
    setImageErrorHeader(true);
  };

  const GridPostHeader = (postid) => {
    let filteredGridData_popup =
      displayProfilePosts.newfilteredGridData &&
      displayProfilePosts.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    //console.log(organiserData.data,"GOPI");
    return (
      <div className="headers">
        <div className="left-info d-flex justify-content-start align-items-center gap-2">
          <div className="thumbnail">

            <div className="cast-box position-relative commentPopImg text-center">
              {isValidImage(photoOrganiser?.photo) && imageExists(`${ArtistallImg}/${photoOrganiser?.photo}`) ? (
                <>
                  <img
                    src={
                      `${ArtistallImg}/${photoOrganiser?.photo}`}
                    className="img-fluid object-cover"

                    alt="person"

                  />
                </>) : (
                <Noimage_circle />
              )}
            </div>
          </div>
          <div className="name-info">
            <div className="name">
              {/* <a href="">{organiserData?.result?.[0]?.organiser_name}</a> */}
              {organiserData?.data?.[0]?.name}
            </div>
            <div className="time" style={{ fontSize: "12px" }}>
              {formatDate(filteredGridData_popup[0].postcreateddate)}
              <i className="global-icon"></i>
            </div>
          </div>
        </div>
        <div className="right-info"></div>
      </div>
    );
  };

  const GridPostDisplayinPopup = (postid) => {
    // let filteredGridData_popup =
    //   displayProfilePosts.gridData &&
    //   displayProfilePosts.gridData.filter((item) => item.postid === postid);

    let filteredGridData_popup =
      displayProfilePosts.newfilteredGridData &&
      displayProfilePosts.newfilteredGridData.filter(
        (item) => item.postid === postid
      );

    return (
      <div>
        {filteredGridData_popup && filteredGridData_popup.length > 0 && (
          <>
            <div className="content pb-2">



              {filteredGridData_popup[0].postdescr &&
                (ReactPlayer.canPlay(filteredGridData_popup[0].postdescr) ? (
                  <ReactPlayer
                    className="w-100"
                    url={filteredGridData_popup[0].postdescr}
                    controls={true}
                  />
                ) : (
                  <p>{filteredGridData_popup[0].postdescr}</p>
                ))}

              {isValidImage(filteredGridData_popup[0].postimage) && imageExists(`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`) && (
                <img
                  src={`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`}
                  alt={filteredGridData_popup[0].postdescr}
                  className="w-100"
                />
              )}

              {filteredGridData_popup[0].postvideo && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postvideo}
                  className="w-100"
                  controls={true}
                />
              )}

              {filteredGridData_popup[0].postaudio && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postaudio}
                  className="w-100"
                  controls={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const handleClose_commentpopup = () => {
    setPopup((prevState) => ({ ...prevState, commentbox_visible: false }));

    setCheckerEditComment(0);
    setPostCollection((prevState) => ({
      ...prevState,
      validation_for_comments: "",
    }));
  };

  //console.log(postCollection, "postCollection");

  // LIKE BUTTON..................................................................

  const handleLikePost = async (postId, postlikestatus) => {
    setLoadingLikes((prevState) => ({ ...prevState, [postId]: true }));
    if (user) {
      if (postId) {
        mutationLikePost.mutate({ postId, postlikestatus });
      } else {
        return;
      }
    } else if (!user) {
      setPopup((prevState) => ({ ...prevState, anon_goestologin: true }));
      //navigate("/Login");
      return;
    } else {
      return;
    }
  };

  const LikeProfile = (postId, postlikestatus) =>
    LIKEONEPOST(LIKE_SINGLE_POST, postId, postlikestatus);

  const LIKEONEPOST = async (LIKE_SINGLE_POST, postId, postlikestatus) => {
    let NLS;
    let isLiked;
    let checker_status = postlikestatus;

    //console.log(checker_status, "checker_status");



    isLiked =
      checker_status === "Y"
        ? true
        : checker_status === "N"
          ? false
          : undefined;

    //console.log(isLiked, "isLiked");
    //NLS = isLiked ? "N" : "Y";
    NLS = isLiked === true ? "N" : "Y";

    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${LIKE_SINGLE_POST}`,

        data: {
          postid: postId ? postId : "",
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          likestatus: NLS,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      //console.log(response, "response");
      //return { response, postId, isLiked };
      return response;
    } catch (error) {
      throw error;
    }
  };

  const mutationLikePost = useMutation({
    mutationKey: ["LikeAPost"],
    mutationFn: ({ postId, postlikestatus }) =>
      LikeProfile(postId, postlikestatus),

    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["fetchPostsProfile"],
      });
      //console.log(data, "DATA LIKE button");

      if (data.data.status === "success") {
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        //SuccessNewToaster('success', 'Comment Edited Successfully', 3000);

        setLoadingLikes((prevState) => ({
          ...prevState,
          [data.data.data.post_id]: false,
        }));

        //setEditedComment("");
        setnewLikedStatus("");


        //setCounterLike(counterlike + 1);

        setDisplayProfilePosts(prevState => {
          const updatedPosts = prevState.newfilteredGridData.reduce((acc, post) => {
            if (post.postid === data.data.data.post_id) {

              const isLiked = data.data.data.status === "Y";

              acc.push({
                ...post, postlikestatus: (data.data.data.status).toString(),
                countpostlike: isLiked ? post.countpostlike + 1 : post.countpostlike - 1,
              });
            } else {

              acc.push(post);
            }
            return acc;
          }, []);

          return {
            ...prevState,
            newfilteredGridData: updatedPosts,
          };
        });


      } else {
        setLoadingLikes((prevState) => ({
          ...prevState,
          [data.data.data.post_id]: false,
        }));
      }
    },
    onError: (error) => {
      HandleErrorToast(
        "error",
        "Could Not Like the Post. Something Went Wrong!",
        3000
      );
      //setnewLikedStatus("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  //console.log(likedPosts, "likedPosts CHECKING");

  const dynamicLikeorUnlike = (postid) => {
    let specificarray =
      displayProfilePosts.gridData &&
      displayProfilePosts.gridData.filter(
        (item) => item.plikeloginid === user?.login_id && item.postid === postid
      );
    //console.log(specificarray, "specificarray");
    return specificarray.length > 0 && specificarray[0].plikeloginid ? (
      <div>Unlike</div>
    ) : (
      <div>Like</div>
    );
  };

  // names of all those who Liked...

  const handleNamesofAllThoseLiked = async (postid) => {
    //console.log(postid, "postid");
    setPopup((prevState) => ({ ...prevState, names_likes: true }));

    const namesofLikes = async (postid) => {
      try {
        const response = await axios({
          method: "POST",
          url: `${BASEPATH}${GET_LIKES}`,
          data: {
            postid: Number(postid),
          },
          headers: {
            MDnD_auth: `${token}`,
          },

          timeout: 60000,
          responseType: "json",
        });

        if (response?.data.status === "success") {
          let result = response?.data.data[0];
          let filterednames =
            result &&
            result.map((item) => ({
              name: item.artist_name,
              photo: item.profile_photo,
              role_name: item.role_name,
              role_id: item.role_id,
              profile_name: item.profile_name,
            }));

          let uniqueFilterednames = filterednames && filterednames.reduce((accumulator, currentItem) => {

            const isDuplicate = accumulator.some(item => item.role_id === currentItem.role_id);


            if (!isDuplicate) {
              accumulator.push(currentItem);
            }

            return accumulator;
          }, []);

          if (uniqueFilterednames) {
            setArrayofLikeNames(uniqueFilterednames);
          }

          let all_names_string =
            filterednames && filterednames.map((item) => item.name).join(", ");
          return all_names_string;
        } else {
          return "";
        }
      } catch (error) {
        console.error("Error fetching detailed data:", error);
        return "error";
      }
    };

    let names = await namesofLikes(postid);

    let like_names = {};
    like_names[postid] = names;

    setNamesofLikesforPostid(like_names);
  };
  //console.log(namesofLikesforpostid, "namesofLikesforpostid");

  const handleClose_namesLikes = () => {
    setPopup((prevState) => ({ ...prevState, names_likes: false }));
    setNamesofLikesforPostid({});
    setArrayofLikeNames([]);
  };

  const handleLikeNameLeadstoProfile = (profileData) => {
    if (profileData.role_name === "artist") {
      navigate(`/artist/${profileData.profile_name}`, {
        state: { artistId: profileData.role_id },
      });
    } else if (profileData.role_name === "organiser") {
      navigate(`/organiser/${profileData.profile_name}`, {
        state: { organiserId: profileData.role_id },
      });
    } else {
      return;
    }
  };

  const getDisplayNames = (names) => {
    if (!names) return "";
    const nameArray = names.split(", ");
    if (nameArray.length > 0) {
      return `${nameArray[0]} ...`;
    }
    return names;
  };

  //REPORT or BLOCK A POST......................

  const handleReport = (postid) => {
    if (postid) {
      setDisplayProfilePosts((prevState) => ({
        ...prevState,
        postid_for_report: postid,
      }));



      setPopup((prev) => ({ ...prev, report_visible: true }));
    } else {
      return;
    }
  };

  const [imageErrorHeaderReportthispost, setimageErrorHeaderReportthispost] = useState(false);

  const handleImageError_HeaderReportthispost = () => {
    setimageErrorHeaderReportthispost(true);
  };

  const GridPostHeader_reportthispost = () => {
    let filteredGridData_popup = [];
    if (displayProfilePosts.postid_for_report) {
      filteredGridData_popup = displayProfilePosts.newfilteredGridData && displayProfilePosts.newfilteredGridData.length > 0 &&
        displayProfilePosts.newfilteredGridData.filter((item) => item.postid === displayProfilePosts.postid_for_report)
    }

    return (
      <div className="headers">
        <div className="left-info d-flex justify-content-start align-items-center gap-2">
          <div className="thumbnail">

            <div className="cast-box position-relative commentPopImg text-center">
              {isValidImage(photoOrganiser?.photo) && imageExists(`${ArtistallImg}/${photoOrganiser?.photo}`) ? (
                <>
                  <img
                    src={
                      `${ArtistallImg}/${photoOrganiser?.photo}`}
                    className="img-fluid object-cover"
                    //onError={handleImageError_HeaderReportthispost}
                    alt="person"

                  />
                </>) : (
                <Noimage_circle />
              )}
            </div>
          </div>
          <div className="name-info">
            <div className="name">
              {/* <a href="">{organiserData?.result?.[0]?.organiser_name}</a> */}
              {organiserData?.data?.[0]?.name}
            </div>
            <div className="time" style={{ fontSize: "12px" }}>
              {formatDate(filteredGridData_popup[0].postcreateddate)}
              <i className="global-icon"></i>
            </div>
          </div>
        </div>
        <div className="right-info"></div>
      </div>
    )
  }

  const GridPostDisplayinPopup_reportthispost = () => {
    let filteredGridData_popup = [];
    if (displayProfilePosts.postid_for_report) {
      filteredGridData_popup = displayProfilePosts.newfilteredGridData && displayProfilePosts.newfilteredGridData.length > 0 &&
        displayProfilePosts.newfilteredGridData.filter((item) => item.postid === displayProfilePosts.postid_for_report)
    }
    return (
      <div>
        {filteredGridData_popup && filteredGridData_popup.length > 0 && (
          <>
            <div className="content pb-2">


              {filteredGridData_popup[0].postdescr &&
                (ReactPlayer.canPlay(filteredGridData_popup[0].postdescr) ? (
                  <ReactPlayer
                    className="w-100"
                    url={filteredGridData_popup[0].postdescr}
                    controls={true}
                  />
                ) : (
                  <p>{filteredGridData_popup[0].postdescr}</p>
                ))}

              {isValidImage(filteredGridData_popup[0].postimage) && imageExists(`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`) && (
                <img
                  src={`${BASEPATH}${GET_IMAGES_PROFILE}${filteredGridData_popup[0].postimage}`}
                  alt={filteredGridData_popup[0].postdescr}
                  className="w-100"
                />
              )}

              {filteredGridData_popup[0].postvideo && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postvideo}
                  className="w-100"
                  controls={true}
                />
              )}

              {filteredGridData_popup[0].postaudio && (
                <ReactPlayer
                  url={filteredGridData_popup[0].postaudio}
                  className="w-100"
                  controls={true}
                />
              )}
            </div>
          </>
        )}
      </div>
    )

  }

  const handleReportTextbox = (e) => {
    setDisplayProfilePosts((prevState) => ({
      ...prevState,
      reporttext: e.target.value,
    }));

    if (e.target.value.length > 0) {
      setDisplayProfilePosts((prev) => ({
        ...prev,
        validationmsg_for_reporttext: "",
      }));
    }
  };

  const handleClose_reportpopup = () => {
    setDisplayProfilePosts((prevState) => ({
      ...prevState,
      reporttext: "",
      validationmsg_for_reporttext: "",
      postid_for_report: "",
    }));
    setPopup((prevState) => ({ ...prevState, report_visible: false }));
  };

  const handleReportSubmitButton = () => {
    if (displayProfilePosts.reporttext === "") {
      setDisplayProfilePosts((prevState) => ({
        ...prevState,
        validationmsg_for_reporttext: "Enter something!",
      }));
    } else {
      setDisplayProfilePosts((prevState) => ({
        ...prevState,
        validationmsg_for_reporttext: "",
      }));
      mutationReportPost.mutate(displayProfilePosts.postid_for_report);
    }
  };

  const postReport = (postid_report) =>
    postReportBackend(REPORT_POST, postid_report);

  const postReportBackend = async (REPORT_POST, postid_report) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${REPORT_POST}`,

        data: {
          postid: postid_report,
          loginid: user ? user.login_id : "",
          roleid: user ? user.role_id : "",
          reporttext: displayProfilePosts.reporttext
            ? displayProfilePosts.reporttext
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationReportPost = useMutation({
    mutationKey: ["PostReport"],
    mutationFn: (postid_report) => postReport(postid_report),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data, "data for report");
        SuccessNewToaster("success", "Reported Succesfully", 3000);

        setDisplayProfilePosts((prev) => ({
          ...prev,
          reporttext: "",
          validationmsg_for_reporttext: "",
          postid_for_report: "",
        }));
        setPopup((prev) => ({ ...prev, report_visible: false }));
        setCounter(counter + 1);
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      setDisplayProfilePosts((prev) => ({
        ...prev,
        reporttext: "",
        validationmsg_for_reporttext: "",
        postid_for_report: "",
      }));
    },
  });

  const handleClose_anon_goestologin = () => {
    setPopup((prevState) => ({ ...prevState, anon_goestologin: false }));
  };

  //FOLLOW/ UNFOLLOW A USER.......................................

  const handleFollow = () => {
    if(user)
   { if (checkinitialFollow === "Follow") {
      mutationFollow.mutate();
      //console.log("inside follow click");
    } else if (checkinitialFollow === "Unfollow") {
      //console.log("2nd part");
      mutationUnfollow.mutate();
    }}
     else {
      HandleErrorToast("error","Please Login to Follow", 4000);
    }
  };

  const Follow_function = () => FollowtoBackend(FOLLOW_A_USER);

  const FollowtoBackend = async (FOLLOW_A_USER) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${FOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? user.login_id : "",
          user2_loginid: organiserData.data[0].login_id
            ? Number(organiserData.data[0].login_id)
            : "",
          user1_roleid: user ? user.role_id : "",
          user2_roleid: organiserData.data[0].role_id
            ? Number(organiserData.data[0].role_id)
            : "",
          follower_roleid: user ? user.role_id : "",
          following_roleid: organiserData.data[0].role_id
            ? Number(organiserData.data[0].role_id)
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationFollow = useMutation({
    mutationKey: ["FollowUser"],
    mutationFn: () => Follow_function(),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data, "data for follow");
        //SuccessNewToaster("success", "Followed Succesfully", 3000);
        setcounterforFollow(counterforFollow + 1);
        setCounterUserProfile_follow(counteruserprofile_follow + 1);
        queryClient.invalidateQueries({
          queryKey: ["FollowIniitalStatus"],
        });

        //setDisplayProfilePosts((prev) => ({ ...prev, reporttext: "", validationmsg_for_reporttext: "", postid_for_report: "" }));
        //setPopup((prev) => ({ ...prev, report_visible: false }));
        //setCounter(counter + 1);
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      //setDisplayProfilePosts((prev) => ({ ...prev, reporttext: "", validationmsg_for_reporttext: "", postid_for_report: "" }));
    },
  });

  // UNFOLLOW...
  const UnFollow_function = () => UnFollowtoBackend(UNFOLLOW_A_USER);

  const UnFollowtoBackend = async (UNFOLLOW_A_USER) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${UNFOLLOW_A_USER}`,

        data: {
          user1_loginid: user ? user.login_id : "",
          user2_loginid: organiserData.data[0].login_id
            ? Number(organiserData.data[0].login_id)
            : "",
          user1_roleid: user ? user.role_id : "",
          user2_roleid: organiserData.data[0].role_id
            ? Number(organiserData.data[0].role_id)
            : "",
          unfollower_roleid: user ? user.role_id : "",
          unfollowing_roleid: organiserData.data[0].role_id
            ? Number(organiserData.data[0].role_id)
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "Response");

      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const mutationUnfollow = useMutation({
    mutationKey: ["UnFollowUser"],
    mutationFn: () => UnFollow_function(),
    onSuccess: (data) => {
      if (data.status === "success") {
        //console.log(data, "data for follow");
        SuccessNewToaster("success", "UnFollowed Succesfully", 3000);
        setcounterforFollow(counterforFollow + 1);

        setCounterUserProfile_follow(counteruserprofile_follow + 1);
        queryClient.invalidateQueries({
          queryKey: ["FollowIniitalStatus"],
        });

        //setDisplayProfilePosts((prev) => ({ ...prev, reporttext: "", validationmsg_for_reporttext: "", postid_for_report: "" }));
        //setPopup((prev) => ({ ...prev, report_visible: false }));
        //setCounter(counter + 1);
      } else {
        //setDisplayProfilePosts((prev) => ({ ...prev, CommentsData: "" }));
      }
    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      //setDisplayProfilePosts((prev) => ({ ...prev, reporttext: "", validationmsg_for_reporttext: "", postid_for_report: "" }));
    },
  });

  // to find out initial status of follow..
  useEffect(() => {
    const fetchFollow = async () => {
      mutationcheckfollowInitial.mutate();
    };
    if (organiserData) {
      fetchFollow();
    }
  }, [organiserData, counterforFollow]);

  const FollowIniitalStatus = () => FOLLOWPROFILE(FOLLOW_INITIAL_CHECK);

  const FOLLOWPROFILE = async (FOLLOW_INITIAL_CHECK) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${FOLLOW_INITIAL_CHECK}`,

        data: {
          user1_roleid: user ? Number(user.role_id) : "",
          user2_roleid: organiserData?.data[0]
            ? Number(organiserData?.data[0].role_id)
            : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error is ", error);
    }
  };

  const mutationcheckfollowInitial = useMutation({
    mutationKey: ["FollowIniitalStatus"],
    mutationFn: () => FollowIniitalStatus(),

    onSuccess: (data) => {
      //console.log(data, "DATA CHECK FOLLOW from backend");

      if (data.status === "success") {
        //console.log("checked follow status");
        setCheckInitialFollow("Unfollow");


      } else {
        setCheckInitialFollow("Follow");
      }
    },
    onError: (error) => {
      // HandleErrorToast(
      //   "error",
      //   "Could Not Check whether User has Followed. Something Went Wrong!",
      //   3000
      // );
      setCheckInitialFollow("Follow");
      //setnewLikedStatus("");

      //alert("Did not post profile");
      //handleCloseafterSubmit();
    },
  });

  // send message button.

  const handleSendMessage = () => {
    if(user)
   { setActiveTab("contact");}
    else{
      HandleErrorToast("error","Please login to send a Message", 4000);
    }
  };

  // whatsapp share-main
  const handleOpenWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? "whatsapp://send"
      : "https://web.whatsapp.com/";
    window.open(whatsappUrl, "_blank");
  };

  // aug 2nd- share new code-

  const [showModal, setShowModal] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);
  const [ShareSocialMediaURL, setShareSocialMediaURL] = useState("");



  const handleShareClick = (post) => {
    //console.log(post, "current post");
    setCurrentPost(post);
    setShowModal(true);
    const shareUrl = `${window.location?.origin}/SpecificPost?roleidstate=${encodeBase64(roleidstate, ARTIST_KEY)}&postid=${post.postid}`;
    setShareSocialMediaURL(shareUrl);
  };

  const handleClose_share = () => {
    setShowModal(false);
    setCurrentPost(null);
    setShareSocialMediaURL("");
  };

  const handlePostClick = (postid, roleidstate) => {
    navigate(`/SpecificPost?roleidstate=${roleidstate}&postid=${postid}`);
  };

  const [imageErrorMainPhoto, setimageErrorMainPhoto] = useState(false);

  const handleImageError_MainPhoto = () => {
    setimageErrorMainPhoto(true);
  };

  const [imageErrorPostUser, setimageErrorPostUser] = useState(false);

  const handleImageError_displayPostUser = () => {
    setimageErrorPostUser(true);
  };

  const [imageErrorNamesLikes, setimageErrorNamesLikes] = useState(false);

  const handleimageErrorNamesLikes = () => {
    setimageErrorNamesLikes(true)
  }

  const handlebackButton = () => {

    navigate(-1);
  }

  const [imageErrorDisplayPost, setimageErrorDisplayPost] = useState(false);

  const handleimageErrorDisplayPost = () => {
    setimageErrorDisplayPost(true);
  };



  // new code..
  const [profileUser, setProfileUser] = useState({});

  useEffect(() => {
    const fetchDataofuser = async () => {
      mutationgetUser.mutate();
    };
    if (user) { fetchDataofuser(); }
    else {
      return;
    }
  }, []);

  const fetchSingleUser = () => GETUSER(GET_USERPROFILE);

  const mutationgetUser = useMutation({
    mutationKey: ["fetchUser"],
    mutationFn: () => fetchSingleUser(),
    onSuccess: (data) => {
      if (data.status === "success") {
        setProfileUser(data?.data[0]);
      } else {
        setProfileUser("");
      }

    },
    onError: (error) => {
      console.error("Error fetching single profile:", error);
      setProfileUser("");

    },
  });



  const GETUSER = async (GET_USERPROFILE) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_USERPROFILE}`,

        data: {
          roleid: user?.role_id,
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });

      return response.data;
    } catch (error) {
      console.error("error during user fetch profile is", error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);


  };

  const encodeBase64 = (str, key) => {
    //const prefix = "xy4AT";
    const combinedStr = key + str.toString();
    return btoa(combinedStr);
  };


  return (
    <>

      {/* <Helmet>
        <meta property="og:url" content={window.location?.href} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content= {organiserData?.data?.[0]?.name} />
        <meta property="og:description" content="Follow me on MDnD" />
        <meta property="og:image" content={`${ArtistallImg}/${photoOrganiser.photo}`} />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="400" />
      </Helmet> */}





      <div className="section-padding p-lg-5">
        <div className="container-fluid ps-0 pe-0">
          <div className="row">


            <div className="col-md-5 col-lg-4 col-xl-3">
              <div>
                <div className="mb-2 p-auto rounded d-none d-md-block backBtn cursor-pointer" style={{ border: "1px solid #ddd" }}>
                  <IoChevronBack
                    style={{ fontSize: "24px" }}
                    onClick={handlebackButton} />
                </div>
              </div>
              <div className="shadow-sm p-3 mb-2">


                <div className="cast-box position-relative profileImg text-center">

                  {isValidImage(photoOrganiser?.photo) && imageExists(`${ArtistallImg}/${photoOrganiser?.photo}`) ? (
                    <>
                      <img
                        src={
                          `${ArtistallImg}/${photoOrganiser.photo}`}
                        className="img-fluid object-cover rounded-circle"
                        style={{ maxHeight: "200px", maxWidth: "200px" }}
                        //onError={handleImageError_MainPhoto}
                        alt="person"

                      />
                    </>) : (
                    <Noimage_profilepic />
                  )}


                  {/* <i class="fas fa-camera cameraIcon"></i> */}
                </div>

                <div className="seperator my-3 text-center">
                  <h4 className="fw-semibold">
                    {organiserData?.data?.[0]?.name} </h4>
                  {/* <p className="fw-semibold mb-0">{organiserData?.data?.[0]?.main}</p> */}
                  <p className="fw-semibold mb-0">
                    {capitalizeFirstLetter(organiserData?.data?.[0]?.rolename)}
                  </p><p className="fw-semibold mb-0">
                    {organiserData?.data?.[0]?.cityname},{" "}
                    {organiserData?.data?.[0]?.countryname}
                  </p>
                  {/* <p className="fw-semibold">
                    {organiserData?.data?.[0]?.email}
                  </p> */}
                  {/* <span className="fw-semibold">{organiserData?.result?.[0]?.organiser_name}</span> */}
                </div>

                {/* <ul className="p-0 m-0 list-unstyled widget_social_media position-relative w-100 text-center">
                  <li className="">
                    {socialmediaCollection.facebook_link ? (
                      <a
                        href={ensureProtocol(
                          socialmediaCollection.facebook_link
                        )}
                        target="_blank"
                        className="position-relative text-dark"
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                    ) : (
                      <span className="position-relative text-dark">
                        <i className="fab fa-facebook"></i>
                      </span>
                    )}
                  </li>
                  <li className="">
                    {socialmediaCollection.twitter_link ? (
                      <a
                        href={ensureProtocol(
                          socialmediaCollection.twitter_link
                        )}
                        target="_blank"
                        className="position-relative text-dark"
                      >
                        <i className="fa-brands fa-x-twitter"></i>
                      </a>
                    ) : (
                      <span className="position-relative text-dark">
                        <i className="fa-brands fa-x-twitter"></i>
                      </span>
                    )}
                  </li>

                </ul> */}
              </div>

              <div className="shadow-sm p-3 my-2">
                <div className="row">
                  <div className="d-flex justify-content-around">
                    <div className="text-center">
                      <h4 className="mb-0">
                        <b>{organiserData?.data?.[0]?.totpost || 0}</b>
                      </h4>
                      <p className="">Posts</p>
                    </div>

                    <div className="text-center mouseHover">
                      <h4 className="mb-0">
                        <b>{organiserData?.data?.[0]?.totfollowers || 0}</b>
                      </h4>
                      <p className="">Followers</p>
                    </div>

                    <div className="text-center mouseHover">
                      <h4 className="mb-0">
                        <b>{organiserData?.data?.[0]?.totfollowing || 0}</b>
                      </h4>
                      <p className="">Following</p>
                    </div>
                  </div>
                </div>
                <div className="w-100 iq-button d-flex flex-wrap gap-3 justify-content-center mt-2">
                  {/* <button className="btn w-lg-25">Follow</button> */}
                  

           <button className="button" onClick={handleFollow}>
                      {checkinitialFollow}
                    </button>
                  {user?.role_id !== roleidstate && (
                    <button
                      className="border-button"
                      onClick={handleSendMessage}
                    >
                      Send Message
                    </button>
                  )}
                  <button
                    className="button d-flex align-items-center"
                    variant="primary"
                    onClick={eventhandleShow}
                  >
                    <i aria-hidden="true"> <IoShareSocial /></i>
                    <span className="Likenumber">Share</span>
                  </button>
                  <Modal show={show} onHide={eventhandleClose} centered>
                    <Modal.Header
                      closeButton
                      className="m-0 d-flex align-items-center text-center border-light"
                      style={{ padding: "10px 10px" }}
                    >
                      <h5 className="text-nowrap text-center m-auto">
                        Share
                      </h5>
                    </Modal.Header>
                    <Modal.Body className="px-2 pb-4">

                      <div className="d-flex gap-3 justify-content-around mx-3">

                        <WhatsappShareButton style={{ color: "green" }}
                          url={`${BASEPATH}profiles/artist/${organiserData?.data?.[0]?.name.replace(/[\s.]+/g, '') || ""
                            }?rid=${encodeBase64(roleidstate, ARTIST_KEY)}`}>
                          <i
                            className="fa-brands fa-whatsapp"
                            style={{
                              fontSize: "40px",
                              cursor: "pointer",
                            }}
                          ></i>
                        </WhatsappShareButton>

                        <FacebookShareButton style={{ color: "blue" }}
                          url={`${BASEPATH}profiles/artist/${organiserData?.data?.[0]?.name.replace(/[\s.]+/g, '') || ""
                            }?rid=${encodeBase64(roleidstate, ARTIST_KEY)}`} >



                          <i className="fa-brands fa-facebook" style={{
                            fontSize: "40px",
                            cursor: "pointer",
                          }}></i>
                        </FacebookShareButton>

                        <TwitterShareButton style={{ color: "black" }}
                          url={`${BASEPATH}profiles/artist/${organiserData?.data?.[0]?.name.replace(/[\s.]+/g, '') || ""
                            }?rid=${encodeBase64(roleidstate, ARTIST_KEY)}`} >

                          <i className="fa-brands fa-x-twitter" style={{
                            fontSize: "40px",
                            cursor: "pointer",
                          }}></i>
                        </TwitterShareButton>

                      </div>

                      <div className="copy-link-container">
                        <div className="copy-header"></div>
                        <div className="copy-link">
                          <div className="copy-link-inner">
                            <form onSubmit={handleCopy} className="d-flex">
                              <input
                                type="text"
                                value={`${BASEPATH}profiles/artist/${organiserData?.data?.[0]?.name.replace(/[\s.]+/g, '') || ""
                                  }?rid=${encodeBase64(roleidstate, ARTIST_KEY)}`}
                                ref={inputRef}
                                className="w-100 text-nowrap overflow-hidden"
                                onClick={() => inputRef.current.select()}
                                readOnly
                              />
                              <input
                                type="submit"
                                className="text-nowrap"
                                value="Copy"
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>

              <div className="w-100 rounded-4 mt-2">

              </div>
            </div>


            <div className="col-md-7 col-lg-8 col-xl-9 mt-sm-2 mt-md-0">
              <div className="d-none">
                <div className="content-details trending-info bi-cursor ">
                  <ul
                    className="nav nav-underline d-flex nav-pills align-items-center text-center mb-lg-3 gap-lg-5 gap-2 m-0px"
                    role="tablist"
                  >
                    {profilebuttons.map((button, index) => (
                      <li className="nav-item bi-cursor" key={index}>
                        <a
                          className={`nav-link ${index === 0 ? "active show" : ""
                            }`}
                          data-bs-toggle="pill"
                          href={`#${button.id}`}
                          role="tab"
                          aria-selected={index === 0 ? "true" : "false"}
                        >
                          {button.buttonName}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* follower Section Start */}

              {/* follower Section End */}

              <div className="content-details trending-info">
                <div>
                  <ul
                    className="nav nav-underline d-flex nav nav-pills align-items-center text-center mb-lg-0 gap-lg-4 gap-2 py-lg-2 mt-lg-0 mt-sm-3 justify-md
                    -content-between"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "about" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("about")}
                        role="tab"
                        aria-selected={activeTab === "about"}
                      >
                        About
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "post" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("post")}
                        role="tab"
                        aria-selected={activeTab === "post"}
                      >
                        Posts
                      </button>
                    </li>
                    {/* <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "contact" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("contact")}
                        role="tab"
                        aria-selected={activeTab === "contact"}
                      >
                        {user?.role_id !== Number(roleidstate)
                          ? "Contact"
                          : "Messages"}
                      </button>
                    </li> */}
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "calender" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("calender")}
                        role="tab"
                        aria-selected={activeTab === "calender"}
                      >
                        Calender
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "videos" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("videos")}
                        role="tab"
                        aria-selected={activeTab === "videos"}
                      >
                        Videos
                      </button>
                    </li>
                    {/* <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === 'blogs' ? 'active show' : ''}`}
                        onClick={() => handleTabClick('blogs')}
                        role="tab"
                        aria-selected={activeTab === 'blogs'}
                      >
                        Blogs
                      </button>
                    </li> */}
                    {/* <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "taggedblogs" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("taggedblogs")}
                        role="tab"
                        aria-selected={activeTab === "taggedblogs"}
                      >
                        Tagged Blogs
                      </button>
                    </li> */}
                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "followers" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("followers")}
                        role="tab"
                        aria-selected={activeTab === "followers"}
                      >
                        Followers
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "following" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("following")}
                        role="tab"
                        aria-selected={activeTab === "following"}
                      >
                        Following
                      </button>
                    </li>

                    {/* <li className="nav-item">
                      <button
                        className={`nav-link ${activeTab === "forms" ? "active show" : ""
                          }`}
                        onClick={() => handleTabClick("forms")}
                        role="tab"
                        aria-selected={activeTab === "forms"}
                      >
                        My Forms
                      </button>
                    </li> */}



                  </ul>
                </div>

                <div className="tab-content tabBodyBg">
                  {activeTab === "about" && (
                    <div
                      id="about"
                      //className="tab-pane animated fadeInUp active show"
                      role="tabpanel"
                    >
                      <div className="description-content">
                        <div className="">
                          {/* <h5 className="mt-2 mb-4 fw-500">About</h5> */}

                          {/* <div className="d-flex mt-2">
                                <h6 className="font-size-18 fw-500">Joined:</h6>
                                <div className="seperator d-flex align-items-center flex-wrap ms-3 mb-3">
                                  {finalDate}
                                </div>
                              </div> */}
                          {/* <p>{organiserData?.data?.[0]?.description}</p> */}
                        </div>
                      </div>
                      {roleidstate ? (
                        <About_artist roleid={roleidstate} />
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  )}

                  {activeTab === "post" && (
                    <div
                      id="post"
                      //className="tab-pane animated fadeInUp"
                      role="tabpanel"
                    >
                      <div className="description-content">


                        {displayProfilePosts.newfilteredGridData && displayProfilePosts.newfilteredGridData.length > 0 ? (
                          displayProfilePosts.newfilteredGridData.map((item) => (
                            <>
                              <div id="app">
                                <div className="headers">
                                  <div className="left-info">
                                    <div className="thumbnail">
                                      {isValidImage(photoOrganiser?.photo) && imageExists(`${ArtistallImg}/${photoOrganiser?.photo}`) ? (
                                        <>
                                          <img
                                            src={
                                              `${ArtistallImg}/${photoOrganiser?.photo}`}
                                            className="img-fluid object-cover"
                                            //onError={handleImageError_displayPostUser}
                                            alt="person"

                                          />
                                        </>) : (
                                        <Noimage_circle />
                                      )}

                                    </div>
                                    <div className="name-info">
                                      {organiserData?.data?.[0]?.name}
                                      <div className="name">
                                        <a href="">
                                          {
                                            organiserData?.result?.[0]
                                              ?.organiser_name
                                          }
                                        </a>
                                      </div>
                                      <div className="time d-flex align-items-center gap-2">
                                        {formatDate(item.postcreateddate)}
                                        <i className="global-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="right-info"></div> */}

                                  <div id="container">
                                    <Dropdown
                                      style={{
                                        width: "40px",
                                        height: "40px",
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="primary"
                                        id="dropdown-basic"
                                        className="bg-white button"
                                        style={{
                                          border: "none",
                                          width: "40px",
                                          height: "40px",
                                          color: "black",
                                          padding: 0,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <BsThreeDots style={{ color: "black" }} /> {/* Custom Icon */}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {user && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleReport(item.postid)
                                            }
                                          >
                                            Report this post
                                          </Dropdown.Item>
                                        )}
                                        {user?.role_id === roleidstate && (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleModalDelete(item.postid)
                                            }
                                          >
                                            Delete
                                          </Dropdown.Item>
                                        )}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div
                                  className="content"
                                  key={item.postid}
                                //onClick={() => handlePostClick(item.postid, roleidstate)}
                                >
                                  {/* <p>{item.postdescr}</p> */}
                                  {item.postdescr &&
                                    (ReactPlayer.canPlay(item.postdescr) ? (
                                      <ReactPlayer
                                        className="w-100"
                                        url={item.postdescr}
                                        controls={true}
                                      />
                                    ) : (
                                      <p>{item.postdescr}</p>
                                    ))}

                                  {isValidImage(item.postimage) && imageExists(`${BASEPATH}${GET_IMAGES_PROFILE}${item.postimage}`) && (
                                    <img
                                      className="w-100"
                                      src={`${BASEPATH}${GET_IMAGES_PROFILE}${item.postimage}`}
                                      alt={item.postdescr}
                                    />
                                  )}

                                  {item.postvideo && (
                                    <ReactPlayer
                                      className="w-100"
                                      url={item.postvideo}
                                      controls={true}
                                    />
                                  )}
                                  {item.postaudio && (
                                    <ReactPlayer
                                      className="w-100"
                                      url={item.postaudio}
                                      controls={true}
                                    />
                                  )}

                                  <div className="feedback-info align-items-center">

                                    <div className="threads-and-share">
                                      <div className="threads">
                                        <button
                                          onClick={() =>
                                            handleNamesofAllThoseLiked(
                                              item.postid,
                                              item.postlikestatus
                                            )
                                          }
                                          className="like-button"
                                        >
                                          {item.countpostlike
                                            ? item.countpostlike
                                            : 0}{" "}
                                         <i
                                                  style={{ fontSize: "1.5vh" }}
                                                  class="fa-solid fa-heart ms-1"
                                                ></i>
                                        </button>
                                        {item.countpostcomment
                                          ? item.countpostcomment
                                          : 0}{" "}
                                         <i className="fb-icon response far fa-comment-alt ms-1"></i>
                                      </div>
                                    </div>

                                    <button
                                      
                                      style={{ padding: "4px 8px" }}
                                      onClick={() => handleShareClick(item)}
                                    >
                                      <IoShareSocial />
                                    </button>
                                  </div>

                                  <div className="feedback-action mt-3">
                                    <div className="fb-wrapper">
                                      {/* <i className="fb-icon thumb-up far fa-thumbs-up"></i> */}

                                      <button
                                        disabled={!!loadingLikes[item.postid]}
                                        //className={`like-btn ${item.plikepostid ? 'liked' : ''}`}
                                        onClick={() =>
                                          handleLikePost(
                                            item.postid,
                                            item.postlikestatus
                                          )
                                        }
                                      >
                                        {/* {item.plikepostid && item.plikeloginid === user.loginid ? "Unlike" : "Like"} */}
                                        {/* {dynamicLikeorUnlike(item.postid)} */}

                                        {item.postlikestatus === "Y" && (
                                          <i class="fa-solid fa-heart"></i>
                                        )}

                                        {/* {postLikeStatus[item.postid] === "N" &&
              <i class="fa-regular fa-heart"></i>
              // <div>Like</div>
            } */}
                                        {item.postlikestatus === "N" && (
                                          <i class="fa-regular fa-heart"></i>
                                        )}

                                        {/* {(postLikeStatus[item.postid] === null || postLikeStatus[item.postid] === "error" || postLikeStatus[item.postid] === undefined || postLikeStatus[item.postid] === "") &&
              <i class="fa-regular fa-heart"></i>
              // <div>Like</div>
            } */}
                                        {(item.postlikestatus === null ||
                                          item.postlikestatus ===
                                          undefined) && (
                                            <i class="fa-regular fa-heart"></i>
                                          )}
                                      </button>
                                    </div>
                                    <div className="fb-wrapper">
                                      <i className="fb-icon response far fa-comment-alt"></i>

                                      <button
                                        onClick={() =>
                                          handleComment(item.postid)
                                        }
                                      >
                                        Comment
                                      </button>
                                    </div>


                                  </div>

                                  {popup.commentbox_visible &&
                                    postCollection.id_for_comment ===
                                    item.postid && (
                                      <div>
                                        <Modal
                                          show={popup.commentbox_visible}
                                          onHide={handleClose_commentpopup}
                                          centered
                                          className=""
                                        >
                                          <Modal.Header className="p-2 border-bottom-0">
                                            {GridPostHeader(item.postid)}
                                          </Modal.Header>
                                          <Modal.Body className="p-2 commentPopScroll">
                                            {GridPostDisplayinPopup(
                                              item.postid
                                            )}

                                            {commentsComponent()}
                                          </Modal.Body>

                                          {checkereditComment === 0 && (<>
                                            <Modal.Footer className="d-flex justify-content-end border-top-0 p-2">
                                              <div className="w-100 d-flex m-0 gap-3 align-items-center justify-content-between">

                                                {
                                                  user.rolename === "artist" ? (
                                                    isValidImage(profileUser?.photo) && imageExists(`${ArtistallImg}/${profileUser?.photo}`) ? (
                                                      <img
                                                        src={`${ArtistallImg}/${profileUser?.photo}`}

                                                        //onError={handleimageErrorDisplayPost}
                                                        className="commentImg rounded-circle"
                                                        alt="Artist"
                                                      />
                                                    ) : (
                                                      <Noimage_circle />
                                                    )
                                                  ) : user.rolename === "organiser" ? (
                                                    isValidImage(profileUser?.photo) && imageExists(`${ORGANISER_URL}/${profileUser?.photo}`) ? (
                                                      <>
                                                        <img
                                                          src={`${ORGANISER_URL}/${profileUser?.photo}`}

                                                          //onError={handleimageErrorDisplayPost}
                                                          className="commentImg rounded-circle"
                                                          alt="Organiser"
                                                        />
                                                      </>) : (
                                                      <Noimage_circle />
                                                    )
                                                  ) : user.rolename === "fan" ? (
                                                    isValidImage(profileUser?.photo) && imageExists(`${Fan_images}/${profileUser?.photo}`) ? (
                                                      <>
                                                        <img
                                                          src={`${Fan_images}/${profileUser?.photo}`}

                                                          //onError={handleimageErrorDisplayPost}
                                                          className="commentImg rounded-circle"
                                                          alt="Fan"
                                                        />
                                                      </>) : (
                                                      <Noimage_circle />
                                                    )
                                                  ) : (
                                                    <Noimage_circle />
                                                  )
                                                }


                                                <TextBox
                                                  className="w-100 textBoxBorder"
                                                  //value={text}
                                                  value={
                                                    postCollection.comment_text
                                                      ? postCollection.comment_text
                                                      : ""
                                                  }
                                                  onValueChanged={
                                                    handleCommentTextbox
                                                  }
                                                  //placeholder="Leave a comment..."
                                                  label="Leave a comment!"
                                                  labelMode="floating"
                                                />
                                                <button
                                                  className="btn p-1 m-0 float-end videoPostBtn"
                                                  onClick={
                                                    handleCommentSubmitButton
                                                  }
                                                >
                                                  <BsFillSendFill />
                                                </button>
                                              </div>
                                              <div className="iq-button d-flex gap-2">
                                                {/* <button
                  className="btn p-2"
                  onClick={
                    handleCommentSubmitButton
                  }
                >
                  Submit
                </button> */}
                                                {/* <button
                  className="p-2 sendMsgBtn "
                  onClick={handleCommentReset}
                >
                  Reset
                </button> */}
                                                {postCollection.validation_for_comments && (
                                                  <span style={{color:'red'}}>
                                                    {
                                                      postCollection.validation_for_comments
                                                    }
                                                  </span>
                                                )}
                                              </div>
                                            </Modal.Footer>
                                          </>)}
                                        </Modal>
                                      </div>
                                    )}
                                </div>
                              </div>




                            </>
                          ))
                        ) : (
                          <NoDataFound />
                        )}


                        <>
                          <Modal
                            show={popup.deleteornot}
                            onHide={handleClose}
                            centered
                          >
                            <Modal.Header
                              className="m-0 d-flex align-items-center"
                              style={{ padding: "20px 12px" }}
                            >
                              <h5 className="text-nowrap">
                                Are you sure you want to delete this Post?
                              </h5>
                            </Modal.Header>
                            <Modal.Body
                              className="d-flex flex-row gap-3 justify-content-center"
                              style={{ padding: "20px 12px" }}
                            >
                              <button className="btn" onClick={handleYesDelete}>
                                Yes
                              </button>
                              <button className="btn" onClick={handleNoDelete}>
                                No
                              </button>
                            </Modal.Body>
                          </Modal>
                        </>

                        <>
                          {popup.report_visible && (
                            <div>
                              <Modal
                                show={popup.report_visible}
                                onHide={handleClose_reportpopup}
                                centered
                              >
                                <Modal.Header>
                                  {GridPostHeader_reportthispost()}
                                </Modal.Header>
                                <Modal.Body
                                  className="d-flex flex-column gap-4 justify-content-center m-0"
                                  style={{ padding: "20px" }}
                                >
                                  {GridPostDisplayinPopup_reportthispost()}

                                  <input
                                    type="text"
                                    className="w-100"
                                    //value={text}
                                    value={
                                      displayProfilePosts.reporttext
                                    }
                                    onChange={handleReportTextbox}
                                    placeholder="Report this post"
                                  />
                                  <button
                                    className="button"
                                    style={{ width: "fit-content" }}
                                    onClick={
                                      handleReportSubmitButton
                                    }
                                  >
                                    Submit
                                  </button>

                                  {displayProfilePosts.validationmsg_for_reporttext && (
                                    <span>
                                      {
                                        displayProfilePosts.validationmsg_for_reporttext
                                      }
                                    </span>
                                  )}
                                </Modal.Body>
                              </Modal>
                            </div>
                          )}
                        </>


                        <>
                          {currentPost && showModal && (
                            <>
                              <Modal
                                show={showModal}
                                onHide={handleClose_share}
                                centered
                              >
                                <Modal.Header
                                  className="m-0 d-flex align-items-center"
                                  style={{ padding: "20px 12px" }}
                                >
                                  <h5 className="text-nowrap m-0 p-0">
                                    Share Post
                                  </h5>
                                </Modal.Header>
                                <Modal.Body
                                  className="d-flex flex-column gap-3"
                                  style={{ padding: "20px 12px" }}
                                >


                                  <ShareToSocialMedia_artist
                                    postdescr={currentPost.postdescr}
                                    postimage={
                                      currentPost.postimage
                                        ? `${BASEPATH}${GET_IMAGES_PROFILE}${currentPost.postimage}`
                                        : currentPost.postvideo
                                          ? currentPost.postvideo
                                          : currentPost.postaudio
                                            ? currentPost.postaudio
                                            : null
                                    }
                                    shareUrl={ShareSocialMediaURL}
                                  />

                                </Modal.Body>
                                <Modal.Footer>
                                  <button
                                    className="border-button"
                                    variant="secondary"
                                    onClick={handleClose_share}
                                  >
                                    Close
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </>
                          )}
                        </>

                        {popup.anon_goestologin && (
                          <>
                            <div>
                              <Modal
                                show={popup.anon_goestologin}
                                onHide={handleClose_anon_goestologin}
                                centered
                              >
                                <Modal.Header></Modal.Header>
                                <Modal.Body>
                                  <Front />
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        )}

                        {popup.names_likes && (
                          <>
                            <div>
                              <Modal
                                show={popup.names_likes}
                                onHide={handleClose_namesLikes}
                                onClose={handleClose_namesLikes}
                                centered
                              >
                                <Modal.Header
                                  className="m-0 d-flex align-items-center"
                                  style={{ padding: "20px 24px" }}
                                >
                                  <h5 className="text-nowrap">Total Likes</h5>
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      onClick={handleClose_namesLikes}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </Modal.Header>
                                <Modal.Body
                                  className="d-flex flex-column gap-3"
                                  style={{ padding: "20px 24px" }}
                                >
                                  {arrayoflikenames &&
                                    arrayoflikenames.map((item) => (
                                      <>
                                        <div className="flex-shrink-0 d-flex gap-2 align-items-center "
                                          onClick={() =>
                                            handleLikeNameLeadstoProfile(
                                              item
                                            )
                                          }
                                        >

                                          {
                                            item?.role_name === "artist" ? (

                                              isValidImage(item?.photo) && imageExists(`${ArtistallImg}/${item?.photo}`) ? (
                                                <img
                                                  src={`${ArtistallImg}/${item?.photo}`}
                                                  onClick={() =>
                                                    handleLikeNameLeadstoProfile(
                                                      item
                                                    )
                                                  }

                                                  className="commentImg rounded-circle me-2"
                                                  style={{
                                                    borderRadius: "50%",
                                                    overflow: "hidden",
                                                  }}
                                                  alt="Artist"
                                                />
                                              ) : (
                                                <Noimage_circle />
                                              )
                                            ) : item?.role_name === "organiser" ? (
                                              isValidImage(item?.photo) && imageExists(`${ORGANISER_URL}/${item?.photo}`) ? (
                                                <>
                                                  <img
                                                    src={`${ORGANISER_URL}/${item?.photo}`}
                                                    onClick={() =>
                                                      handleLikeNameLeadstoProfile(
                                                        item
                                                      )
                                                    }
                                                    //onError={handleimageErrorNamesLikes}
                                                    className="commentImg rounded-circle me-2"
                                                    style={{
                                                      borderRadius: "50%",
                                                      overflow: "hidden",
                                                    }}
                                                    alt="Organiser"
                                                  />
                                                </>) : (
                                                <Noimage_circle />
                                              )
                                            ) : item?.role_name === "fan" ? (
                                              isValidImage(item?.photo) && imageExists(`${Fan_images}/${item.photo}`) ? (
                                                <>
                                                  <img
                                                    src={`${Fan_images}/${item.photo}`}
                                                    onClick={() =>
                                                      handleLikeNameLeadstoProfile(
                                                        item
                                                      )
                                                    }
                                                    //onError={handleimageErrorNamesLikes}
                                                    className="commentImg rounded-circle me-2"
                                                    style={{
                                                      borderRadius: "50%",
                                                      overflow: "hidden",
                                                    }}
                                                    alt="Fan"
                                                  />
                                                </>) : (
                                                <Noimage_circle />
                                              )
                                            ) : (
                                              <Noimage_circle />
                                            )
                                          }

                                          {" "}
                                          <p
                                            className="p-0 m-0"
                                            onClick={() =>
                                              handleLikeNameLeadstoProfile(
                                                item
                                              )
                                            }
                                          >{item.name}</p>
                                        </div>
                                      </>
                                    ))}
                                </Modal.Body>
                                <Modal.Footer></Modal.Footer>
                              </Modal>
                            </div>
                          </>
                        )}



                        <div className="comments">
                          <div className="my-comment-wrapper">

                            <div className="my-comment">
                              {/* <div className="my-comment-placeholder">
                                <input
                                  type="text"
                                  placeholder="Write a comment..."
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === "contact" && (
                    <div
                      id="contact"
                      //className="tab-pane animated fadeInUp"
                      role="tabpanel"
                    >
                      {roleidstate && loginidstate && namestate ? (
                        <Contact_artist
                          roleid={roleidstate}
                          loginid={loginidstate}
                          name={namestate}
                        />
                      ) : (
                        <div>Check...... </div>
                      )}
                    </div>
                  )}

                  {activeTab === "calender" && (
                    <div
                      id="calender"
                      //className="tab-pane animated fadeInUp"
                      role="tabpanel"
                    >
                      {roleidstate ? (
                        <MyCalendar_artist roleid={roleidstate} />
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  )}

                  {activeTab === "videos" && (
                    <div
                      id="videos"
                      //className="tab-pane animated fadeInUp"
                      role="tabpanel"
                    >
                      {roleidstate ? (
                        <Videos_artist roleid={roleidstate} />
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  )}
                  {activeTab === "taggedblogs" && (
                    <div
                      id="taggedblogs"
                      //className="tab-pane animated fadeInUp"
                      role="tabpanel"
                    >
                      {roleidstate ? (
                        <TaggedBlogs_artist roleid={roleidstate} />
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  )}

                  {activeTab === "followers" && (
                    <div id="followers" role="tabpanel">
                      {roleidstate ? (
                        <Followers_artist roleid={roleidstate} />
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  )}
                  {activeTab === "following" && (
                    <div id="followers" role="tabpanel">
                      {roleidstate ? (
                        <Following_artist roleid={roleidstate} />
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  )}

                  {activeTab === "forms" && (
                    <div id="forms" role="tabpanel">
                      {roleidstate ? (
                        <MyForms_artist roleid={roleidstate} />
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                  )}




                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
