import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useContext, useEffect, useRef } from "react";
import { TextBox } from "devextreme-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BASEPATH } from "../../../config/config";
import { SOCIAL_MEDIA_LINK, GET_SOCIALMEDIA } from "../../../Api/api";
import AuthContext from "../../../context/AuthContext";
import { IoShareSocial } from "react-icons/io5";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, } from 'react-share';

export default function SocialMedia_myprofile(props) {
  const { user, setUser, authTokens, setAuthTokens, logoutUser } =
    useContext(AuthContext);
  const token = localStorage.getItem("MDnD_auth");
  let { profilelink } = props;
  //console.log(user,"user jwt");
  const queryClient = useQueryClient();

  const [popup, setPopup] = useState({
    fb_popup: false,
    twitter_popup: false,
    instagram_popup: false,
  });

  const [socialMediaCollection, setSocialMediaCollection] = useState({
    fb_link: "",
    twitter_link: "",
    instagram_link: "",
    validationmsg_fb: "",
    validationmsg_twitter: "",
    validation_instagram: "",
    general_validation: "",
  });

  const [datatoBackend, setDatatoBackend] = useState({});

  const [Aboutfrombackend, setAboutfrombackend] = useState({
    first_text_facebook: "",
    first_text_twitter: "",
    first_text_instagram: "",
    description_facebook: "",
    description_twitter: "",
    description_instagram: "",

    validationmsg_fb_valuechange: "",
    validationmsg_twitter_valuechange: "",
    validation_instagram_valuechange: "",

    validation_first_text: "",
    general_validation: "",
  });
  const [checkEditing, setcheckEditing] = useState(false);
  const [checkerCollection, setCheckerCollection] = useState({
    checker_facebook: true,
    checker_twitter: true,
    checker_instagram: true,
  });
  const [counter, setCounter] = useState(0);

  const handleFB = () => {
    setPopup((prevState) => ({ ...prevState, fb_popup: true }));
  };

  const handleTwitter = () => {
    setPopup((prevState) => ({ ...prevState, twitter_popup: true }));
  };

  const handleInsta = () => {
    setPopup((prevState) => ({ ...prevState, instagram_popup: true }));
  };

  //closing function.....
  const handleClose_fb = () => {
    setPopup((prevState) => ({ ...prevState, fb_popup: false }));
    setSocialMediaCollection((prev) => ({
      ...prev,
      validationmsg_fb: "",
      fb_link: "",
      general_validation: "",
    }));
    setAboutfrombackend((prev) => ({
      ...prev,
      description_facebook: Aboutfrombackend.first_text_facebook,
      validationmsg_fb_valuechange: "",
      validationmsg_twitter_valuechange: "",
      validation_instagram_valuechange: "",

      validation_first_text: "",
      general_validation: "",
    }));
  };

  const handleClose_twitter = () => {
    setPopup((prevState) => ({ ...prevState, twitter_popup: false }));
    setSocialMediaCollection((prev) => ({
      ...prev,
      twitter_link: "",
      validationmsg_twitter: "",
      twitter_popup: "",
      general_validation: "",
    }));
    setAboutfrombackend((prev) => ({
      ...prev,
      description_twitter: Aboutfrombackend.first_text_twitter,
      validationmsg_fb_valuechange: "",
      validationmsg_twitter_valuechange: "",
      validation_instagram_valuechange: "",

      validation_first_text: "",
      general_validation: "",
    }));
  };

  const handleClose_instagram = () => {
    setPopup((prevState) => ({ ...prevState, instagram_popup: false }));
    setSocialMediaCollection((prev) => ({
      ...prev,
      instagram_link: "",
      validation_instagram: "",
      instagram_popup: "",
      general_validation: "",
    }));
    setAboutfrombackend((prev) => ({
      ...prev,
      description_instagram: Aboutfrombackend.first_text_instagram,
      validationmsg_fb_valuechange: "",
      validationmsg_twitter_valuechange: "",
      validation_instagram_valuechange: "",

      validation_first_text: "",
      general_validation: "",
    }));
  };

  // on value changed functions..

  const handleFBlinkValue = (e) => {
    const fbLinkPattern =
      /^(https?:\/\/)?(www\.|m\.)?(facebook\.com|fb\.com)\/[A-Za-z0-9._%-]+\/?$/;
    const value = e.value;

    if (fbLinkPattern.test(value)) {
      setSocialMediaCollection((prev) => ({ ...prev, fb_link: value }));
      setSocialMediaCollection((prev) => ({ ...prev, validationmsg_fb: "" }));
    } else {
      setSocialMediaCollection((prev) => ({
        ...prev,
        validationmsg_fb: "Please enter a valid Facebook link!",
      }));
    }

    if (value.length > 0) {
      setSocialMediaCollection((prev) => ({ ...prev, general_validation: "" }));
    }
  };

  const handleFBlinkduringUpdate = (e) => {
    const fbLinkPattern =
      /^(https?:\/\/)?(www\.|m\.)?(facebook\.com|fb\.com)\/[A-Za-z0-9._%-]+\/?$/;
    let value = e.value;

    //console.log(value, "e.value");

    if (fbLinkPattern.test(value)) {
      setAboutfrombackend((prev) => ({
        ...prev,
        description_facebook: e.value,
      }));
      setAboutfrombackend((prev) => ({
        ...prev,
        validationmsg_fb_valuechange: "",
      }));
    } else {
      setAboutfrombackend((prev) => ({ ...prev, description_facebook: "" }));
      setAboutfrombackend((prev) => ({
        ...prev,
        validationmsg_fb_valuechange: "Please enter a valid Facebook link!",
      }));
    }
    //setAboutfrombackend((prev) => ({ ...prev, description_facebook: e.value }));

    if (value.length > 0) {
      setAboutfrombackend((prev) => ({ ...prev, general_validation: "" }));
    }
  };

  const handleTwitterlinkValue = (e) => {
    //const twitterLinkPattern = /^(https?:\/\/)?(www\.|mobile\.)?twitter\.com\/[A-Za-z0-9_]+\/?$/;
    const twitterLinkPattern =
      /^(https?:\/\/)?(www\.|mobile\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]+\/?$/;
    const value = e.value;

    if (twitterLinkPattern.test(value)) {
      setSocialMediaCollection((prev) => ({ ...prev, twitter_link: value }));
      setSocialMediaCollection((prev) => ({
        ...prev,
        validationmsg_twitter: "",
      }));
    } else {
      setSocialMediaCollection((prev) => ({
        ...prev,
        validationmsg_twitter: "Please enter a valid Twitter link!",
      }));
    }
    if (value.length > 0) {
      setSocialMediaCollection((prev) => ({ ...prev, general_validation: "" }));
    }
  };

  const handleTwitterLinkduringUpdate = (e) => {
    const twitterLinkPattern =
      /^(https?:\/\/)?(www\.|mobile\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]+\/?$/;
    const value = e.value;

    if (twitterLinkPattern.test(value)) {
      setAboutfrombackend((prev) => ({ ...prev, description_twitter: value }));
      setAboutfrombackend((prev) => ({
        ...prev,
        validationmsg_twitter_valuechange: "",
      }));
    } else {
      setAboutfrombackend((prev) => ({ ...prev, description_twitter: "" }));
      setAboutfrombackend((prev) => ({
        ...prev,
        validationmsg_twitter_valuechange: "Please enter a valid Twitter link!",
      }));
    }
    if (value.length > 0) {
      setAboutfrombackend((prev) => ({ ...prev, general_validation: "" }));
    }
  };

  const handleInstagramlinkValue = (e) => {
    //const instagramLinkPattern = /^(https?:\/\/)?(www\.|m\.)?instagram\.com\/[A-Za-z0-9._%-]+\/?$/;
    const instagramLinkPattern =
      /^(https?:\/\/)?(www\.|m\.)?instagram\.com\/[A-Za-z0-9._%-]+\/?(\?hl=[A-Za-z0-9._%-]+)?$/;
    const value = e.value;

    if (instagramLinkPattern.test(value)) {
      setSocialMediaCollection((prev) => ({ ...prev, instagram_link: value }));
      setSocialMediaCollection((prev) => ({
        ...prev,
        validation_instagram: "",
      }));
    } else {
      setAboutfrombackend((prev) => ({ ...prev, description_instagram: "" }));
      setSocialMediaCollection((prev) => ({
        ...prev,
        validation_instagram: "Please enter a valid Instagram link!",
      }));
    }
    if (value.length > 0) {
      setSocialMediaCollection((prev) => ({ ...prev, general_validation: "" }));
    }
  };

  const handleInstaLinkDuringUpdate = (e) => {
    //const instagramLinkPattern = /^(https?:\/\/)?(www\.|m\.)?instagram\.com\/[A-Za-z0-9._%-]+\/?$/;
    const instagramLinkPattern =
      /^(https?:\/\/)?(www\.|m\.)?instagram\.com\/[A-Za-z0-9._%-]+\/?(\?hl=[A-Za-z0-9._%-]+)?$/;
    const value = e.value;

    if (instagramLinkPattern.test(value)) {
      setAboutfrombackend((prev) => ({
        ...prev,
        description_instagram: value,
      }));
      setAboutfrombackend((prev) => ({
        ...prev,
        validation_instagram_valuechange: "",
      }));
    } else {
      setAboutfrombackend((prev) => ({ ...prev, description_instagram: "" }));
      setAboutfrombackend((prev) => ({
        ...prev,
        validation_instagram_valuechange:
          "Please enter a valid Instagram link!",
      }));
    }
    if (value.length > 0) {
      setAboutfrombackend((prev) => ({ ...prev, general_validation: "" }));
    }
  };

  //submit button...........

  const handleSubmit_fb = () => {
    const data = {};

    if (checkerCollection.checker_facebook) {
      if (!socialMediaCollection.fb_link) {
        setSocialMediaCollection((prev) => ({
          ...prev,
          validationmsg_fb: "",
          general_validation: "Please enter something!",
        }));
        //data = {};
        setDatatoBackend({});
      } else {
        setSocialMediaCollection((prev) => ({
          ...prev,
          general_validation: "",
          validationmsg_fb: "",
        }));

        data.mediatype = "facebook";
        data.link = socialMediaCollection.fb_link;
        data.roleid = user ? user.role_id : "";
        setDatatoBackend(data);
        mutationsocialmedia.mutate(datatoBackend);
      }
    }

    //twitter

    //insta
  };

  const handleSubmit_twitter = () => {
    const data = {};
    if (checkerCollection.checker_twitter) {
      if (!socialMediaCollection.twitter_link) {
        setSocialMediaCollection((prev) => ({
          ...prev,
          general_validation: "Please enter something!",
          validationmsg_twitter: "",
        }));
        //data = {};
        setDatatoBackend({});
      } else {
        setSocialMediaCollection((prev) => ({
          ...prev,
          general_validation: "",
          validationmsg_twitter: "",
        }));

        data.mediatype = "twitter";
        data.link = socialMediaCollection.twitter_link;
        data.roleid = user ? user.role_id : "";
        setDatatoBackend(data);

        mutationsocialmedia.mutate(datatoBackend);
      }
    }
  };

  const handleSubmit_instagram = () => {
    const data = {};

    if (checkerCollection.checker_instagram) {
      if (!socialMediaCollection.instagram_link) {
        setSocialMediaCollection((prev) => ({
          ...prev,
          general_validation: "Please enter something!",
          validation_instagram: "",
        }));
        //data = {};
        setDatatoBackend({});
      } else {
        setSocialMediaCollection((prev) => ({
          ...prev,
          general_validation: "",
          validation_instagram: "",
        }));
        data.mediatype = "instagram";
        data.link = socialMediaCollection.instagram_link;
        data.roleid = user ? user.role_id : "";
        setDatatoBackend(data);
        mutationsocialmedia.mutate(datatoBackend);
      }
    }
  };

  const handleEdit_fb = () => {
    const data = {};

    if (
      Aboutfrombackend.description_facebook ===
        Aboutfrombackend.first_text_facebook &&
      Aboutfrombackend.description_facebook !== "" &&
      Aboutfrombackend.first_text_facebook !== ""
    ) {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_first_text: "Please enter new text!",
      }));
      return;
    } else if (!Aboutfrombackend.description_facebook) {
      setAboutfrombackend((prev) => ({
        ...prev,
        validationmsg_fb: "",
        general_validation: "Please enter something!",
      }));
    } else {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_first_text: "",
      }));
      setAboutfrombackend((prev) => ({
        ...prev,
        general_validation: "",
        validationmsg_fb_valuechange: "",
      }));

      // do data here..

      if (popup.fb_popup) {
        if (checkerCollection.checker_facebook !== true) {
          if (Aboutfrombackend.description_facebook) {
            data.mediatype = "facebook";
            data.link = Aboutfrombackend.description_facebook;
            data.roleid = user ? user.role_id : "";
            //console.log(data, "data updated foe facebook");
            setDatatoBackend(data);
          } else {
            //data = {};
            setDatatoBackend({});
          }
        }
      }

      mutationsocialmedia.mutate(datatoBackend);
    }
  };

  const handleEdit_twitter = () => {
    const data = {};
    if (
      Aboutfrombackend.description_twitter ===
      Aboutfrombackend.first_text_twitter
    ) {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_for_description: "Please enter new text!",
      }));
    } else if (!Aboutfrombackend.description_twitter) {
      setSocialMediaCollection((prev) => ({
        ...prev,
        validationmsg_fb: "",
        general_validation: "Please enter something!",
      }));
    } else {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_for_description: "",
      }));
      setSocialMediaCollection((prev) => ({
        ...prev,
        general_validation: "",
        validationmsg_fb: "",
      }));
      if (popup.twitter_popup) {
        if (checkerCollection.checker_twitter !== true) {
          if (Aboutfrombackend.description_twitter) {
            data.mediatype = "twitter";
            data.link = Aboutfrombackend.description_twitter;
            data.roleid = user ? user.role_id : "";
            setDatatoBackend(data);
          } else {
            //data = {};
            setDatatoBackend({});
          }
        }
      }
      mutationsocialmedia.mutate(datatoBackend);
    }
  };

  const handleEdit_insta = () => {
    const data = {};
    if (
      Aboutfrombackend.description_instagram ===
      Aboutfrombackend.first_text_instagram
    ) {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_for_description: "Please enter new text!",
      }));
    } else if (!Aboutfrombackend.description_instagram) {
      setSocialMediaCollection((prev) => ({
        ...prev,
        validationmsg_fb: "",
        general_validation: "Please enter something!",
      }));
    } else {
      setAboutfrombackend((prevState) => ({
        ...prevState,
        validation_for_description: "",
      }));
      setSocialMediaCollection((prev) => ({
        ...prev,
        general_validation: "",
        validationmsg_fb: "",
      }));

      if (popup.instagram_popup) {
        if (checkerCollection.checker_instagram !== true) {
          if (Aboutfrombackend.description_instagram) {
            data.mediatype = "instagram";
            data.link = Aboutfrombackend.description_instagram;
            data.roleid = user ? user.role_id : "";
            setDatatoBackend(data);
          } else {
            //data = {};
            setDatatoBackend({});
          }
        }
      }
      mutationsocialmedia.mutate(datatoBackend);
    }
  };

  const handleCloseafterSubmit = () => {
    setSocialMediaCollection((prev) => ({
      ...prev,

      fb_link: "",
      twitter_link: "",
      instagram_link: "",
      validationmsg_fb: "",
      validationmsg_twitter: "",
      validation_instagram: "",
    }));
    setAboutfrombackend((prev) => ({
      ...prev,

      first_text_facebook: "",
      first_text_twitter: "",
      first_text_instagram: "",
      description_facebook: "",
      description_twitter: "",
      description_instagram: "",

      validation_for_description: "",
    }));

    setPopup((prevState) => ({
      ...prevState,
      fb_popup: false,
      twitter_popup: false,
      instagram_popup: false,
    }));
  };

  const SOCMEDIAFUNCTION = async (SOCIAL_MEDIA_LINK, datatoBackend) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${SOCIAL_MEDIA_LINK}`,

        data: datatoBackend,
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "RESPONSE");
      return response.data;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const SocialmedialinkPost = () =>
    SOCMEDIAFUNCTION(SOCIAL_MEDIA_LINK, datatoBackend);

  const mutationsocialmedia = useMutation({
    mutationKey: ["mutateSocialMedia"],
    mutationFn: (datatoBackend) => SocialmedialinkPost(datatoBackend),

    onSuccess: (data) => {
      //console.log(data, "DATA");

      if (data.status === "success") {
        //       queryClient.invalidateQueries({
        //     queryKey: ["get_All_SocialMedia"],
        //   });
        //SuccessToaster('success', 'Post Created Successfully', 3000);
        //SuccessNewToaster("success", "Post Created Successfully", 3000);
        setDatatoBackend({});
        handleCloseafterSubmit();

        setCounter(counter + 1);

        //alert("successfully updated by vicki");
      }
    },
    onError: (error) => {
      //HandleErrorToast("error", "Did Not post profile", 3000);
      //alert("Did not post profile");
      handleCloseafterSubmit();
    },
  });

  // fetching socialmedia link...........................................

  useEffect(() => {
    if (user) {
      mutationgetSocialMedia.mutate();
    }
  }, [counter]);

  const GetallSocialMedia = () => GET_SOCIALMEDIA_FROM_BACKEND(GET_SOCIALMEDIA);

  const GET_SOCIALMEDIA_FROM_BACKEND = async (GET_SOCIALMEDIA) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_SOCIALMEDIA}`,

        data: {
          roleid: user ? user.role_id : "",
        },
        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      //console.log(response, "RESPONSE");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetSocialMedia = useMutation({
    mutationKey: ["get_All_SocialMedia"],
    mutationFn: () => GetallSocialMedia(),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });

      //console.log(data, "DATA");

      if (data.status === "success") {
        //console.log(data, "data social media");
        let filteredData_facebook =
          data.data &&
          data.data.filter((item) => item.media_type == "facebook");
        //console.log(filteredData_facebook, "filteredData facebook");

        if (filteredData_facebook && filteredData_facebook.length > 0) {
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description_facebook: filteredData_facebook[0].link,
            first_text_facebook: filteredData_facebook[0].link,
          }));

          setCheckerCollection((prevState) => ({
            ...prevState,
            checker_facebook: false,
          }));
        } else {
          //setChecker(false);
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description_facebook: "",
            first_text_facebook: "",
          }));
        }
        // twitter
        let filteredData_twitter =
          data.data && data.data.filter((item) => item.media_type == "twitter");

        if (filteredData_twitter && filteredData_twitter.length > 0) {
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description_twitter: filteredData_twitter[0].link,
            first_text_twitter: filteredData_twitter[0].link,
          }));
          setCheckerCollection((prevState) => ({
            ...prevState,
            checker_twitter: false,
          }));
        } else {
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description_twitter: "",
            first_text_twitter: "",
          }));
        }

        //instagram...
        let filteredData_instagram =
          data.data &&
          data.data.filter((item) => item.media_type == "instagram");
        //console.log(filteredData_instagram, "filteredData_instagram");

        if (filteredData_instagram && filteredData_instagram.length > 0) {
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description_instagram: filteredData_instagram[0].link,
            first_text_instagram: filteredData_instagram[0].link,
          }));
          setCheckerCollection((prevState) => ({
            ...prevState,
            checker_instagram: false,
          }));
        } else {
          setAboutfrombackend((prevState) => ({
            ...prevState,
            description_instagram: "",
            first_text_instagram: "",
          }));
        }

        //handleCloseafterSubmit();
      }
    },
    onError: (error) => {
      //alert("Did not post profile");
      //handleCloseafterSubmit();

      setcheckEditing(false);
      setAboutfrombackend((prevState) => ({
        ...prevState,
        first_text_facebook: "",
        first_text_twitter: "",
        first_text_instagram: "",
        description_facebook: "",
        description_twitter: "",
        description_instagram: "",
      }));
    },
  });

  //new code...........
  const [show, setShow] = useState(false);
  const eventhandleShow = () => setShow(true);
  const eventhandleClose = () => setShow(false);

  const inputRef = useRef(null);

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(inputRef.current.value).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  //social media links- useEffect-......................

  const [socialmediaCollection, setSocialmediaCollection] = useState({
    facebook_link: "",
    twitter_link: "",
    instagram_link: "",
  });

  useEffect(() => {
    const fetchSocialMedia = async () => {
      try {
        const response = await axios.post(
          `${BASEPATH}profiles/get_profilesocial`,
          {
            roleid: user.role_id ? user.role_id : "",
          },
          {
            headers: {
              MDnD_auth: `${token}`,
            },

            timeout: 60000,
            responseType: "json",
          }
        );
        //console.log(response, "Response socialmedia links");
        if (response.data.status === "success") {
          let filteredData_facebook =
            response.data.data &&
            response.data.data.filter((item) => item.media_type == "facebook");
          //console.log(filteredData_facebook, "filteredData facebook");

          if (filteredData_facebook && filteredData_facebook.length > 0) {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              facebook_link: filteredData_facebook[0].link,
            }));
          } else {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              facebook_link: "",
            }));
          }
          //twitter
          let filteredData_twitter =
            response.data.data &&
            response.data.data.filter((item) => item.media_type == "twitter");

          if (filteredData_twitter && filteredData_twitter.length > 0) {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              twitter_link: filteredData_twitter[0].link,
            }));
          } else {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              twitter_link: "",
            }));
          }
          // instagram..
          let filteredData_instagram =
            response.data.data &&
            response.data.data.filter((item) => item.media_type == "instagram");

          if (filteredData_instagram && filteredData_instagram.length > 0) {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              instagram_link: filteredData_instagram[0].link,
            }));
          } else {
            setSocialmediaCollection((prevState) => ({
              ...prevState,
              instagram_link: "",
            }));
          }
        } else if (response.data.status === "failed") {
          setSocialmediaCollection((prevState) => ({
            ...prevState,
            instagram_link: "",
            twitter_link: "",
            facebook_link: "",
          }));
        }
      } catch (error) {
        console.error("error fetching social media links", error);
      }
    };
    if (show === true) {
      fetchSocialMedia();
    }
  }, [show]);

  const ensureProtocol = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };
  // end of social media links..............

  const handleOpenWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? "whatsapp://send"
      : "https://web.whatsapp.com/";
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div className="w-100 d-flex flex-sm-column justify-content-between align-items-center gap-2">
      <div>
        <ul className="p-0 m-0 list-unstyled widget_social_media position-relative w-100 text-center mb-2">
          <li className="">
            <i className="fab fa-facebook" onClick={handleFB}></i>
          </li>
          <li className="">
            <i className="fa-brands fa-x-twitter" onClick={handleTwitter}></i>
          </li>
          {/* <li className="">
            <i className="fab fa-instagram" onClick={handleInsta}></i>
          </li> */}
        </ul>

        {popup.fb_popup && (
          <>
            {checkerCollection.checker_facebook ? (
              <>
                <Modal show={popup.fb_popup} onHide={handleClose_fb} centered>
                  <div className="social-media-modal">
                    <p className="title">Enter your Facebook Profile Link</p>
                    <div className="d-flex flex-column">
                      <TextBox
                        value={socialMediaCollection.fb_link}
                        //readOnly={true}
                        label="Enter your Facebook Profile Link:"
                        labelMode="floating"
                        className="input-field"
                        onValueChanged={handleFBlinkValue}
                      />
                      {socialMediaCollection.validationmsg_fb && (
                        <p className="error-message p-0 m-0">
                          {socialMediaCollection.validationmsg_fb}
                        </p>
                      )}
                      {socialMediaCollection.general_validation && (
                        <p className="error-message p-0 m-0">
                          {socialMediaCollection.general_validation}
                        </p>
                      )}
                    </div>

                    <div className="button-row">
                      <button
                        onClick={handleSubmit_fb}
                        //onClick={handleEdit_fb}
                        className="button"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Modal>
              </>
            ) : (
              <>
                <Modal show={popup.fb_popup} onHide={handleClose_fb} centered>
                  <Modal.Header
                    closeButton
                    className="m-0 d-flex align-items-center"
                    style={{ padding: "20px 24px 10px 24px" }}
                  >
                    <h5 className="w-100 text-center text-wrap">
                      <b>Edit your Facebook Profile Link</b>
                    </h5>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex flex-column">
                      <TextBox
                        value={Aboutfrombackend.description_facebook}
                        //readOnly={true}
                        label="Edit your Facebook Profile Link:"
                        labelMode="floating"
                        className="input-field"
                        onValueChanged={handleFBlinkduringUpdate}
                      />
                      {Aboutfrombackend.validationmsg_fb_valuechange && (
                        <p className="error-message p-0 m-0">
                          {Aboutfrombackend.validationmsg_fb_valuechange}
                        </p>
                      )}
                      {Aboutfrombackend.validation_first_text && (
                        <p className="error-message p-0 m-0">
                          {Aboutfrombackend.validation_first_text}
                        </p>
                      )}
                      {Aboutfrombackend.general_validation && (
                        <p className="error-message p-0 m-0">
                          {Aboutfrombackend.general_validation}
                        </p>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer className=" py-0">
                    <div className="button-row d-flex justify-content-end me-2">
                      <button onClick={handleEdit_fb} className="button">
                        Edit
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </>
            )}
          </>
        )}

        {popup.twitter_popup && (
          <>
            {checkerCollection.checker_twitter ? (
              <>
                <Modal
                  show={popup.twitter_popup}
                  onHide={handleClose_twitter}
                  centered
                >
                  <div className="social-media-modal">
                    <p className="title">Enter your Twitter Profile Link</p>
                    <div className="d-flex flex-column">
                      <TextBox
                        value={socialMediaCollection.twitter_link}
                        //readOnly={true}
                        label="Enter your Twitter Link:"
                        labelMode="floating"
                        className="input-field"
                        onValueChanged={handleTwitterlinkValue}
                      />
                      {socialMediaCollection.validationmsg_twitter && (
                        <div className="error-message m-0 p-0">
                          {socialMediaCollection.validationmsg_twitter}
                        </div>
                      )}
                      {socialMediaCollection.general_validation && (
                        <div className="error-message p-0 m-0">
                          {socialMediaCollection.general_validation}
                        </div>
                      )}
                    </div>
                    <div className="button-row">
                      <button onClick={handleSubmit_twitter} className="button">
                        Save
                      </button>
                    </div>
                  </div>
                </Modal>
              </>
            ) : (
              <>
                <Modal
                  show={popup.twitter_popup}
                  onHide={handleClose_twitter}
                  centered
                >
                  <div className="social-media-modal">
                    <p className="title">Edit your Twitter Profile Link</p>
                    <div className="d-flex flex-column">
                      <TextBox
                        value={Aboutfrombackend.description_twitter}
                        //readOnly={true}
                        label="Edit your Twitter Link:"
                        labelMode="floating"
                        className="input-field"
                        onValueChanged={handleTwitterLinkduringUpdate}
                      />
                      {socialMediaCollection.validationmsg_twitter && (
                        <div className="error-message">
                          {socialMediaCollection.validationmsg_twitter}
                        </div>
                      )}
                      {socialMediaCollection.general_validation && (
                        <div className="error-message">
                          {socialMediaCollection.general_validation}
                        </div>
                      )}
                    </div>

                    <div className="button-row">
                      <button onClick={handleEdit_twitter} className="button">
                        Save
                      </button>
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </>
        )}

        {popup.instagram_popup && (
          <>
            {checkerCollection.checker_instagram ? (
              <>
                <Modal
                  show={popup.instagram_popup}
                  onHide={handleClose_instagram}
                  centered
                >
                  <div class="social-media-modal">
                    <p className="title">Enter your Instagram Link</p>
                    <div className="d-flex flex-column">
                      <TextBox
                        value={socialMediaCollection.instagram_link}
                        label="Enter your Instagram Profile Link:"
                        labelMode="floating"
                        className="input-field"
                        onValueChanged={handleInstagramlinkValue}
                      />
                      {socialMediaCollection.validation_instagram && (
                        <div className="error-message p-0 m-0">
                          {socialMediaCollection.validation_instagram}
                        </div>
                      )}
                      {socialMediaCollection.general_validation && (
                        <div className="error-message p-0 m-0">
                          {socialMediaCollection.general_validation}
                        </div>
                      )}
                    </div>

                    <div className="button-row">
                      <button
                        onClick={handleSubmit_instagram}
                        className="button"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Modal>
              </>
            ) : (
              <>
                <Modal
                  show={popup.instagram_popup}
                  onHide={handleClose_instagram}
                  centered
                >
                  <div className="social-media-modal">
                    <p className="title">Edit your Instagram Profile Link</p>
                    <div className="d-flex flex-column">
                      <TextBox
                        value={Aboutfrombackend.description_instagram}
                        label="Edit your Instagram Profile Link:"
                        labelMode="floating"
                        className="input-field"
                        onValueChanged={handleInstaLinkDuringUpdate}
                      />
                      {socialMediaCollection.validation_instagram && (
                        <div className="error-message m-0 p-0">
                          {socialMediaCollection.validation_instagram}
                        </div>
                      )}
                      {socialMediaCollection.general_validation && (
                        <div className="error-message m-0 p-0">
                          {socialMediaCollection.general_validation}
                        </div>
                      )}
                    </div>

                    <div className="button-row">
                      <button onClick={handleEdit_insta} className="button">
                        Edit
                      </button>
                    </div>
                  </div>
                </Modal>
              </>
            )}
          </>
        )}
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          
          
          { user?.rolename !=="advertiser" &&
          <button
            className="button d-flex align-items-center"
            variant="primary"
            onClick={eventhandleShow}
          >
            <i aria-hidden="true">
              {" "}
              <IoShareSocial />
            </i>
            <span className="Likenumber">Share</span>
          </button>}
        </div>

        <Modal show={show} onHide={eventhandleClose} centered>
          <Modal.Header
            closeButton
            className="m-0 d-flex align-items-center text-center border-light"
            style={{ padding: "10px 10px" }}
          >
            <h5 className="text-nowrap text-center m-auto">Share</h5>
          </Modal.Header>
          <Modal.Body
            className="d-flex flex-column gap-3"
            style={{ padding: "20px 12px 30px 12px" }}
          >
            <div className="d-flex gap-3 justify-content-around mx-3">
              

<WhatsappShareButton style={{color: "green"}} url={profilelink ? profilelink : `https://www.mdnd.in/`}>
            <i
                                          className="fa-brands fa-whatsapp"
                                          style={{
                                            fontSize: "40px",
                                            cursor: "pointer",
                                          }}
                                        ></i>
            </WhatsappShareButton>

            <FacebookShareButton style={{color: "blue"}} url={profilelink ? profilelink : `https://www.mdnd.in/`} >
            <i className="fa-brands fa-facebook" style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}></i>
            </FacebookShareButton>
            <TwitterShareButton style={{color: "black"}} url={profilelink ? profilelink : `https://www.mdnd.in/`} >
            
              <i className="fa-brands fa-x-twitter" style={{
                  fontSize: "40px",
                  cursor: "pointer",
                }}></i>
              </TwitterShareButton>


            </div>

            <div className="copy-link-container">
              <div className="copy-header"></div>
              <div className="copy-link">
                <div className="copy-link-inner">
                  <form onSubmit={handleCopy} className="d-flex gap-2">
                    <input
                      type="text"
                      value={profilelink ? profilelink : `https://www.mdnd.in/`}
                      ref={inputRef}
                      className="w-100 overflow-hidden"
                      onClick={() => inputRef.current.select()}
                      readOnly
                    />
                    <input
                      type="submit"
                      value="Copy"
                      style={{ fontSize: "14px" }}
                    />
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {/*    */}
    </div>
  );
}
