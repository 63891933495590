import React, { useState, useEffect,useRef } from "react";
import MyVerticallyCenteredModal from "./PlanPopup";
import { BASEPATH } from "../../../config/config.js";
import {
  DateBox,
  TextBox,
  SelectBox,
  TagBox,
  NumberBox,
  TextArea,
} from "devextreme-react";
import HtmlEditor, {
  Toolbar,
  MediaResizing,
  ImageUpload,
  Item,
} from "devextreme-react/html-editor";
import {
  sizeValues,
  fontValues,
  headerValues,
  fontSizeOptions,
  fontFamilyOptions,
  headerOptions,
} from "../../../components/others";
import "./Ads.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import adsValidationSchema from "./Validation/Auth";
import { useFormik } from "formik";
import { makeGetRequest, makePostRequest } from "../../../Api/requestMethods";
import { EVENT_MAIN_CATEGORY, GET_PERTICULAR_USER, UPDATE_ADS_UPLOAD } from "../../../Api/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { DURATION, ERROR, SUCCESS } from "../../../global/ToastMsgConstants.tsx";
import Button from "devextreme-react/button";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { useMatch } from "react-router-dom";
import RazorpayPayment from "../../../Pages/paymentGateWay/razorPay.js";
import moment from "moment/moment";
const planType = [
  { state_id: 1, state_name: "View Type" },
  { state_id: 2, state_name: "Duration Type" },
];

const paymentModes = [
  { payment_id: "online", payment_name: "online" },
];

const Currency =[
  {currency_id:1 , currency_name: "INR"} ,
  {currency_id:2 , currency_name:"USD"}
]

const initialValues = {
  title: "",
  start_date: "",
  end_date: "",
  payment_mode: "",
  payable_inr: "",
  record_categories: "",
  plan_type: "",
  impressions_promised:"",
  redirect_link:""
};

const viewCountToPriceMapping = [
  { viewCount: 100, rate: 500 },
  { viewCount: 500, rate: 1500 },
  { viewCount: 1000, rate: 2500 },
];

const durationRates = [
  { minDays: 1, maxDays: 14, ratePerDay: 50 },
  { minDays: 15, maxDays: 30, ratePerDay: 40 },
  { minDays: 31, maxDays: Infinity, ratePerDay: 30 },
];

function Ads() {
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(false);
  const [settings, setSettings] = useState({
    hasFocusedIn: false,
   })
    
   const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );

 const loginid = user.login_id;
 const roleid = user.role_id;
 const [userInformation,setUserInformation] = useState({})
 const razorpayRef = useRef(null);

 // Your existing code...
 
 const { values, handleBlur, handleChange, handleSubmit, errors, touched, isSubmitting, setFieldValue, resetForm } = 
  useFormik({
    initialValues,
    validationSchema:adsValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      
      const { currency, plan_type, start_date, end_date, ...filteredValues } = values;  // Destructure and exclude currency and plan_type
      
      // Format start_date and end_date to remove timezone
      const formattedStartDate = start_date ? moment(start_date).format('YYYY-MM-DD') : null;
      const formattedEndDate = end_date ? moment(end_date).format('YYYY-MM-DD') : null;
 
      const updatedValues = {
        ...filteredValues,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        user_name: userInformation.name,
        user_email: userInformation.email,
        user_mobile: userInformation.mobile,
        user_country_code: userInformation.country_code,
        payment_gateway: "razorpay",
        payment_for: "advertisement",
        role_id: roleid,
        login_id: loginid,
      };
 
      if (values.plan_type === 1) {
        delete updatedValues.start_date;
        delete updatedValues.end_date;
      } else if (values.plan_type === 2) {
        delete updatedValues.impressions_promised;
      }
    try {
      const response = await addUploadMutation.mutateAsync(updatedValues);
    
      if (response.data.status === 200) {
        var success_url = "";
        var advertisement_data = {"payment":"success","aid":response.data.advertisement_id};
        var jsonString = JSON.stringify(advertisement_data); 
        var btoa_encode = btoa(jsonString); 
        response.data.success_url = `/ViewAds/${btoa_encode}`;
        razorpayRef.current.triggerPayment(response.data);
      } else {
        notify(response.message, ERROR, DURATION);
      }
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setSubmitting(false);
      resetForm();
    }
    },
  });

  // -------------------------------ADDSuplodingThing----------------------------------------------------------
  const adsPostfunction = async (data)=>{
    try{
      const endpoint = await makePostRequest(UPDATE_ADS_UPLOAD(),data);
      return endpoint;
    }catch(error){
      throw error
    }
  }

  const addUploadMutation = useMutation({
    mutationKey:["addsDetail"],
    mutationFn:(adsData)=>adsPostfunction(adsData),
    onSuccess:(data)=>{
      if(data.status !== 200){
        notify(data.message,ERROR,DURATION)
      }
      if (data.status === 200) {
        notify(data.message, SUCCESS);
    }
    },
    onError: (error) => {
      console.log(error, "errorpost");
      notify(error.message, ERROR, DURATION);
  }
  })

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handlePopup = () => {
    setPopup(true);
  };


  const handleInputChange = (field, value) => {
    setFieldValue(field, value);
    if (field === "impressions_promised" || field === "plan_type" || field === "start_date" || field === "end_date") {
      calculateTotalAmount(field, value);
    }

    if (field === "plan_type") {
      setFieldValue("payable_inr", ""); 

      if (value === 1) {
        setFieldValue("impressions_promised", "");
        setFieldValue("start_date", "");
        setFieldValue("end_date", "");
      } else if (value === 2) {
        setFieldValue("impressions_promised", "");
        setFieldValue("start_date", "");
        setFieldValue("end_date", "");
      }
    }
  
    
  };

  const getPerViewRate = (viewCount) => {
    let lowerIndex = 0;
    let upperIndex = viewCountToPriceMapping.length - 1;

    for (let i = 0; i < viewCountToPriceMapping.length; i++) {
      if (viewCountToPriceMapping[i].viewCount <= viewCount) {
        lowerIndex = i;
      }
      if (viewCountToPriceMapping[i].viewCount > viewCount) {
        upperIndex = i;
        break;
      }
    }

    const lowerViewCount = viewCountToPriceMapping[lowerIndex].viewCount;
    const upperViewCount = viewCountToPriceMapping[upperIndex].viewCount;
    const lowerRate = viewCountToPriceMapping[lowerIndex].rate;
    const upperRate = viewCountToPriceMapping[upperIndex].rate;

    if (lowerViewCount === upperViewCount) {
      return lowerRate / lowerViewCount;
    }

    const rate =
      lowerRate +
      ((viewCount - lowerViewCount) * (upperRate - lowerRate)) /
        (upperViewCount - lowerViewCount);
    return rate / viewCount;
  };

  const calculateDurationRate = (duration) => {
    for (const rate of durationRates) {
      if (duration >= rate.minDays && duration <= rate.maxDays) {
        return rate.ratePerDay;
      }
    }
    return 0;
  };

  const calculateTotalAmount = () => {
    const { plan_type, impressions_promised, start_date, end_date } = values;
     
    if (plan_type === 1 && impressions_promised) {
      // Plan Type: View Type
      const perViewRate = getPerViewRate(impressions_promised);
      const price = perViewRate * impressions_promised;
      setFieldValue("payable_inr", price.toFixed(2));
    } else if (plan_type === 2 && start_date && end_date) {
      // Plan Type: Duration Type
      const startDate = dayjs(start_date);
      const endDate = dayjs(end_date);
      const duration = endDate.diff(startDate, "day") + 1;
      const ratePerDay = calculateDurationRate(duration);
      const price = ratePerDay * duration;
      setFieldValue("payable_inr", price.toFixed(2));
    }
  };
  useEffect(() => {
    if (values.plan_type && values.start_date && values.end_date) {
      calculateTotalAmount();
    }
  }, [values.start_date, values.end_date]);

  useEffect(() => {
    if (values.plan_type && values.impressions_promised) {
      calculateTotalAmount();
    }
  }, [values.impressions_promised]);

  //-----------------------------------------TargetCategories--------------------------------------------------------
  const TargetCategories = async()=> settings.hasFocusedIn && (await makeGetRequest(EVENT_MAIN_CATEGORY));

  const {
    data : TargetCategoriesData,
    isLoading,
  } = useQuery({
    queryKey :["tagetcategory"],
    queryFn : ()=> TargetCategories(),
    enabled:settings.hasFocusedIn,
    onError:()=>{
      notify(errors.message,ERROR,DURATION)
    }
  })
  // -------------------------------------handleFocusIn--------------------------------------------------------------
  const handleFocusIn = (e) => {
    setSettings((prev) => ({ ...prev, hasFocusedIn: true }));
    handleBlur({ target: { name: "event_title", value: e.value } });
  };
  // -----------------------------------------GetUserDetail-------------------------------------------------------------
  const fetchUserInformation = async (loginid) => {
    const endpoint = `${GET_PERTICULAR_USER()}=${loginid}`;
    return await makeGetRequest(endpoint);
  }

  const { data} = useQuery({
    queryKey: ['userInfo', loginid],
    queryFn: () => fetchUserInformation(loginid),
    onError: (error) => {
      console.log(error);
    }
  });

  useEffect(() => {
    setUserInformation(data?.data);
  }, [data]);


  const handleRazorpaySuccess = (response) => {
    notify("Payment Successful!", "success");
  };

  const handleRazorpayFailure = () => {
    notify("Payment Failed or Cancelled", "error");
  };

  //-----------------------------------------------------------------------------------------------------------------
  return (
    <>
    <form onSubmit={handleSubmit}>
      <div className="tab-content tabBodyBg">
        <div className="d-flex justify-content-end d-lg-none d-xl-none">
          <button className="button" variant="primary" onClick={handlePopup}>
            Plan details
          </button>

          <MyVerticallyCenteredModal show={popup} onHide={() => setPopup(false)} />
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-12">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <TextBox
                      type="text"
                      labelMode="floating"
                      className="dx-field-item-required"
                      required="required"
                      mode="floating"
                      label="Title"
                      value={values.title}
                      onValueChanged={(e) => handleInputChange("title", e.value)}
                    />
                    {errors.title && touched.title && <div className="text-danger">{errors.title}</div>}
                  </div>
                )}
              </div>
              {/* Repeat similar structure for all fields */}
              <div className="col-lg-6">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <TagBox
                      label="Target categories"
                      className="dx-field-item-required"
                      onFocusIn={handleFocusIn}
                      dataSource={TargetCategoriesData?.data || null}
                      labelMode="floating"
                      displayExpr="main_cat_name"
                      valueExpr="main_cat_id"
                      searchEnabled={true}
                      value={values.record_categories}
                      onValueChanged={(e) => handleInputChange("record_categories", e.value)}
                    />
                    {errors.record_categories && touched.record_categories && (
                      <div className="text-danger">{errors.record_categories}</div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <SelectBox
                      items={planType}
                      label="Plan type"
                      displayExpr="state_name"
                      className="dx-field-item-required"
                      labelMode="floating"
                      valueExpr="state_id"
                      value={values.plan_type}
                      onValueChanged={(e) => handleInputChange("plan_type", e.value)}
                    />
                    {errors.plan_type && touched.plan_type && <div className="text-danger">{errors.plan_type}</div>}
                  </div>
                )}
              </div>
              {values.plan_type === 1 && (
              <div className="col-lg-6">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <NumberBox
                      label="View count"
                      displayExpr="state_name"
                      className="dx-field-item-required"
                      labelMode="floating"
                      valueExpr="state_id"
                      showSpinButtons={true}
                      showClearButton={true}
                      min={0}
                      value={values.impressions_promised}
                      // disabled={values.plan_type === 2}
                      onValueChanged={(e) => handleInputChange("impressions_promised", e.value)}
                    />
                    {errors.impressions_promised && touched.impressions_promised && <div className="text-danger">{errors.view_count}</div>}
                  </div>
                )}
              </div>
              )}
              {values.plan_type === 2 && (
                <>
              <div className="col-lg-6">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <DateBox
                      className="dx-field-item-required"
                      label="Start date"
                      labelMode="floating"
                      value={values.start_date}
                      disabled={values.plan_type === 1}
                      onValueChanged={(e) => handleInputChange("start_date", e.value)}
                    />
                    {errors.start_date && touched.start_date && <div className="text-danger">{errors.start_date}</div>}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <DateBox
                      label="End date"
                      labelMode="floating"
                      value={values.end_date}
                      disabled={values.plan_type === 1}
                      onValueChanged={(e) => handleInputChange("end_date", e.value)}
                    />
                    {errors.end_date && touched.end_date && <div className="text-danger">{errors.end_date}</div>}
                  </div>
                )}
              </div>
              </>
            )}
              <div className="col-lg-6">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <TextBox
                      type="text"
                      labelMode="floating"
                      className="dx-field-item-required"
                      required="required"
                      label="Total amount"
                      readOnly
                      value={values.payable_inr}
                      onValueChanged={(e) => handleInputChange("payable_inr", e.value)}
                    />
                    {errors.payable_inr && touched.payable_inr && <div className="text-danger">{errors.payable_inr}</div>}
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                {loading ? (
                  <Skeleton height={40} className="pb-3" />
                ) : (
                  <div className="form-group mb-2">
                    <SelectBox
                        dataSource={paymentModes}
                        label="Payment mode"
                        name="payment_mode"
                         placeholder="Choose Payment Mode"
                        displayExpr="payment_name"
                        className="dx-field-item-required"
                        labelMode="floating"
                        valueExpr="payment_id"
                        value={values.payment_mode}
                        onValueChanged={(e) => handleInputChange("payment_mode", e.value)}
                        />
                        {errors.payment_mode && touched.payment_mode && <div className="text-danger">{errors.payment_mode}</div>}
                      </div>
                    )}
                  </div>
                  {values.payment_mode === "cheque" && (
                  <div className="col-lg-6" >
                     {loading?(
                       <Skeleton height={40} className="pb-3" />
                     ):(
                      <TextBox
                      type="text"
                      labelMode="floating"
                      className="dx-field-item-required"
                      required="required"
                      label="Cheque Number"
                      value={values.cheque_file}
                      onValueChanged={(e) => handleInputChange("cheque_file", e.value)}
                    />
                     )}
                  </div>
                  )}
                  <div className="col-lg-6">
                    {loading ? (
                      <Skeleton height={40} className="pb-3" />
                    ) : (
                      <div className="form-group mb-2">
                        <SelectBox
                          items={Currency}
                          label="Currency"
                          displayExpr="currency_name"
                          className="dx-field-item-required"
                          labelMode="floating"
                          valueExpr="currency_id"
                          value={values.currency}
                          onValueChanged={(e) => handleInputChange("currency", e.value)}
                        />
                        {errors.currency && touched.currency && <div className="text-danger">{errors.currency}</div>}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6">
                    {loading ? (
                      <Skeleton height={40} className="pb-3" />
                    ) : (
                      <div className="form-group mb-2">
                        <TextBox
                          type="number"
                          labelMode="floating"
                          className="dx-field-item-required"
                          required="required"
                          mode="floating"
                          label="Click through link"
                          value={values.redirect_link}
                          onValueChanged={(e) => handleInputChange("redirect_link", e.value)}
                        />
                        {errors.redirect_link && touched.redirect_link && <div className="text-danger">{errors.click_through_link}</div>}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12 d-flex">
                    {loading ? (
                      <Skeleton className="skeleton-loader-btn" />
                    ) : (
                      <div className="d-flex justify-content-end mt-4 iq-button">
                        <Button
                          type="submit"
                          text="PayNow"
                          className="btn position-relative h-auto"
                          disabled={isSubmitting}
                          onClick={handleSubmit}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-none d-lg-block">
                {loading ? (
                  <Skeleton height={40} />
                ) : (
                  <h6 className="text-center py-2">
                    <b>Plan details</b>
                  </h6>
                )}
    
                <div className="table-responsive">
                  {loading ? (
                    <Skeleton className="skeleton-loader-box" />
                  ) : (
                    <table className="table table-primary tableBorder">
                      <thead>
                        <tr>
                          <th colSpan="2" scope="col" className="text-center">
                            Views
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="">
                          <td className="w-50">
                            <b>View count</b>
                          </td>
                          <td className="w-50 text-end">
                            <b>Price(INR)</b>
                          </td>
                        </tr>
                        {viewCountToPriceMapping.map((item, index) => (
                          <tr className="" key={index}>
                            <td scope="row">{item.viewCount}</td>
                            <td className="text-end">₹{item.rate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="table-responsive">
                  {loading ? (
                    <Skeleton className="skeleton-loader-box" />
                  ) : (
                    <table className="table table-primary tableBorder">
                      <thead>
                        <tr>
                          <th colSpan="2" scope="col" className="text-center">
                            Duration
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="">
                          <td className="w-50">
                            <b>Days</b>
                          </td>
                          <td className="w-50 text-end">
                            <b>Price(INR)</b>
                          </td>
                        </tr>
                        <tr>
                          <td scope="row">1-14 days</td>
                          <td className="text-end">₹50/day</td>
                        </tr>
                        <tr className="">
                          <td scope="row">15-30 days</td>
                          <td className="text-end">₹40/day</td>
                        </tr>
                        <tr className="">
                          <td scope="row">Above 30 days</td>
                          <td className="text-end">₹30/day</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
        <RazorpayPayment
        ref ={razorpayRef}
        onSuccess={handleRazorpaySuccess}
        onFailure={handleRazorpayFailure}
        />
        </>
      );
    }
    
    export default Ads;
    
