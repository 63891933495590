export const isValidImage = (filename) => {
    const validExtensions = ['jpg', 'jpeg', 'png','webp'];
    if (!filename) return false;
    const extension = filename.split('.').pop().toLowerCase();
    return validExtensions.includes(extension);
  };

  
 export const imageExists = (url) => {
   
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    });
  };
  