// Forgot.js
import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { FORGOT_PASSWORD_SCHEMA } from "../Schemas/Validations/Auth.js";
import "./styles/Login.css";
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD } from "../Api/api";
import { Button, TextBox } from "devextreme-react";
import { useMutation } from "@tanstack/react-query";
import { makePostRequest } from "../Api/requestMethods";
import notify from "devextreme/ui/notify";
import { DURATION, ERROR, SUCCESS } from "../global/ToastMsgConstants.tsx";
import { ForgotPasswordSchema } from "../Schemas/Forms/Auth.js";
import Notification from "../components/Notification.js";
import SuccessImage from "../Auth/static/Tick.png";
import { CHECK_VALID_EMAIL } from "../Api/api";
import { EmailValidation } from "../utils/commonUtils.js";
import Loading from "../components/Loading.js";


export default function ForgotPassword() {
  const [showModal, setShowModal] = useState(false);
  const [emailError, setError] = useState({
    isError: false,
    errorMsg: "",
  });
  const [popmessage, setMessage] = useState("");
  const [lineMessage, setLineMessage] = useState("");
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
   
  } = useFormik({
    initialValues: ForgotPasswordSchema,
    validationSchema: FORGOT_PASSWORD_SCHEMA,
    onSubmit: async (values) => {
      if (emailError.isError && emailError.errorMsg !== "") return;
      await forgotMutation.mutateAsync(values);
    },
  });

  

  const validateEmailFn = async (data) => {
    try {
      const response = await makePostRequest(CHECK_VALID_EMAIL, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const forgothandler = async (data) => {
    try {
      const response = await makePostRequest(FORGOT_PASSWORD, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const forgotMutation = useMutation({
    mutationKey: ["forgot"],
    mutationFn: (forgotData) => forgothandler(forgotData),
    onSuccess: (data) => {
      if (data.status === "failed") {
        notify(data.message, ERROR, DURATION);
      }
      if (data.status === "success") {
        setShowModal(true);


        setIsSubmitted(true);

        handleChange({  target: { name: "email", value: '' },});
       
        setMessage(data.message);
      }
    },
    onError: (error) => {
      setLineMessage(error.response.data.message);
    },
  });
  const ExitPopup = () => {
    setShowModal(false);
  };

  const validateEmail = useMutation({
    mutationFn: (email) => validateEmailFn(email),
    onSuccess: (data) => {
      if (data.status === SUCCESS) {
        setError((prev) => ({
          ...prev,
          isError: true,
          errorMsg: data.message,
        }));
      }
    },
    onError: (error) => {
      setError((prev) => ({
        ...prev,
        isError: false,
        errorMsg: "",
      }));
    },
  });

  useEffect(() => {
    if (EmailValidation(values.email)) {
      const body = {
        email: values.email,
      };
      validateEmail.mutate(body);
    }
  }, [values.email]);

  var CHECK_CONDTION;
  if (errors.email && touched.email) {
    CHECK_CONDTION = false;
  } else {
    CHECK_CONDTION = true;
  }

  //new code...
  const [isSubmitted, setIsSubmitted] = useState(false); 
  

  return (
    <div className="loginBgBanner d-flex ">
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center height-self-center mt-lg-5">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 col-md-12 align-self-center mx-auto">
            <div className="user-login-card bg-body shadow-sm">
              <div className="text-center">
                <h4>Forgot Password</h4>
                <p className="text-secondary">
                  Please enter the email address that you used to register. We
                  will email you a link to reset your password.
                </p>
              </div>
              <form onSubmit={handleSubmit}>
                <Notification
                  show={showModal}
                  onClose={ExitPopup}
                  message={popmessage}
                  imageSource={SuccessImage}
                  isWelcomeEnabled={false}
                />
                <div className="">
                  <div className="mb-3">
                    <TextBox
                      type="text"
                      name="email"
                      label="Email"
                      labelMode="floating"
                      className="dx-field-item-required"
                      value={values.email}
                   
                      onFocusOut={(e) =>
                        handleBlur({
                          target: { name: "email", value: e.value },
                        })
                      }
                      onValueChanged={(e) =>
                        handleChange({
                          target: { name: "email", value: e.value },
                        })
                      }
                    />
                    {errors.email && touched.email && (
                      <p className="error-message mt-1">{errors.email}</p>
                    )}
                    {CHECK_CONDTION && emailError.errorMsg !== "" && (
                      <p className="error-message mt-1">{emailError.errorMsg}</p>
                    )}
                  </div>
                </div>
                <div className="text-end mb-3">
                  <Link to="/login" style={{ color: "#3f00ff" }}>
                    Back to login
                  </Link>
                </div>
                <div className="full-button">
                  <div className="iq-button">
                    <Button
                      type="submit"
                      className="btn  position-relative h-auto"
                      text={isSubmitting ? "" : "Submit"}
                    
                      useSubmitBehavior={true}
                      disabled={isSubmitted}
                    >
                      {isSubmitting && <Loading />}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </div>
  );
}
