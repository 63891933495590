import React, { useState, useEffect } from 'react';
import Virtualkeyboard from './Virtualkeyboard';
import './Grid.css';

const Creategrid = ({ wordLength, gridColors, currentRow, onGuessSubmit, isGameOver, setCurrentRow, keyboardColors }) => {
  const rows = 5;
  const [grid, setGrid] = useState(Array.from({ length: rows }, () => Array(wordLength).fill('')));
  const [activeCol, setActiveCol] = useState(0); // Track the current column
  const getTodayDateString = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  }

  useEffect(() => {
    const savedDate = localStorage.getItem("ragaRiddleLastPlayedDate");
    const todayDate = getTodayDateString();
    
    if (savedDate !== todayDate) {
      localStorage.removeItem("ragaRiddleGrid");
      localStorage.removeItem("ragaRiddleCurrentRow");
      localStorage.setItem("ragaRiddleLastPlayedDate", todayDate);
    }
      else{
    const savedGrid = JSON.parse(localStorage.getItem('ragaRiddleGrid'));
    const savedRow = parseInt(localStorage.getItem('ragaRiddleCurrentRow'), 10);
        const savedActiveCol = parseInt(localStorage.getItem('ragaRiddleActiveCol'),0);
    if (savedGrid) {
      setGrid(savedGrid);
    }
    if (!isNaN(savedRow)) {
      setCurrentRow(savedRow);
    }
    if (!isNaN(savedActiveCol)){
    setActiveCol(savedActiveCol);
    }
  }
  }, []);
  // Save grid state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("ragaRiddleGrid", JSON.stringify(grid));
    localStorage.setItem("ragaRiddleCurrentRow", currentRow);
    localStorage.setItem("ragaRiddleActiveCol",activeCol);
  }, [grid, currentRow,activeCol]);
  // UseEffect for physical keyboard key events
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isGameOver) return; // Prevent any input if the game is over
      const key = event.key.toUpperCase();
      handleInput(key); // Unified handling for physical keyboard input
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeCol, currentRow, isGameOver, grid]); // Listen for state updates

  // Unified function to handle both keyboard and virtual keyboard input
  const handleInput = (key) => {
    if (isGameOver) return; // Disable further input if the game is over

    const colIndex = activeCol;
    const newGrid = [...grid];

    if (key === 'ENTER') {
      // Check if all columns of the current row are filled
      if (grid[currentRow].every((letter) => letter !== '')) {
        onGuessSubmit(grid[currentRow], currentRow); // Submit the guess
        setActiveCol(0); // Reset to the first column for the next row
        setCurrentRow((prevRow) => prevRow + 1); // Move to the next row
      }
    } else if (key === 'BACKSPACE') {
      if (newGrid[currentRow][colIndex] === '') {
        // Move back to the previous column if no letter is in the current column
        newGrid[currentRow][colIndex - 1] = '';
        setGrid(newGrid);
        setActiveCol(colIndex > 0 ? colIndex - 1 : 0);
      } else {
        // Remove the letter from the current column
        newGrid[currentRow][colIndex] = '';
        setGrid(newGrid);
        if (colIndex === ( wordLength - 1)){
          setActiveCol(colIndex);
        }
        else{
        setActiveCol(colIndex > 0 ? colIndex - 1 : 0); // Move back to the previous column
        }
      }
    } else if (/^[A-Z]$/.test(key)) {
      // Check if the key is a valid letter (A-Z)
      if (colIndex < wordLength) {
        newGrid[currentRow][colIndex] = key; // Set the letter in the grid
        setGrid(newGrid);
        setActiveCol(colIndex < wordLength - 1 ? colIndex + 1 : colIndex); // Move to the next column
      }
    }
  };

  return (
    <div>
      <div className="grid">
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} className="grid-row" style={{ '--word-length': wordLength }}>
            {row.map((letter, colIndex) => (
              <div
                key={colIndex}
                className={`grid-cell ${gridColors[rowIndex][colIndex]}`}
                // style={{
                //   backgroundColor: gridColors[rowIndex] ? gridColors[rowIndex][colIndex] : 'white',
                //   border: '1px solid black',
                //   width: '40px',
                //   height: '40px',
                //   display: 'flex',
                //   justifyContent: 'center',
                //   alignItems: 'center',
                //   fontSize: '24px',
                //   pointerEvents: isGameOver ? 'none' : 'auto',
                // }}
              >
                {letter}
              </div>
            ))}
          </div>
        ))}
      </div>
      {/* <Virtualkeyboard handleKeyClick={handleInput} /> Use the unified input handler */}
      <Virtualkeyboard handleKeyClick={handleInput} keyboardColors={keyboardColors} /> {/* Pass keyboardColors to Virtualkeyboard */}
    </div>
  );
};

export default Creategrid;
