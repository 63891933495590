import React, { useCallback , useRef} from "react";
import IMAGE1 from "../assets/images/genre/02.webp";
import { RAGABANK_IMAGEPATH } from "../config/config";
import "./RagaBank.css";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ALL_ARTIST_DETAIL, ALL_ARTIST_DETAIL_RAGA, COMPOSERS_ITEM, GET_RAGABANK_RECORD, GET_RAGABANK_THUMBNAIL_RECORD, GET_THUMNAIL_FOR_RAGA, LANGUAGE_ITEMS, RAGA_ITEMS, THALA_ITEM, V_TYPE_ITEMS } from "../Api/api";
import { makeGetRequest } from "../Api/requestMethods";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { parseISO, differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears } from 'date-fns';
import { useNavigate,useLocation} from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import mdndimage from "./RagaImage/mdnd.png";
import ragaimage from "./RagaImage/ragaicon.png";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FaFilter } from "react-icons/fa";
import SelectBox from "devextreme-react/select-box";
import { Modal, Button } from "react-bootstrap";
import kauveyLogo from "../global/Kauvery_Hospital_logo";
import Kauvery_Hospital_logo from "../global/Kauvery_Hospital_logo";
import RagaBank_logo from "../global/RagaBank";
import { GrPowerReset } from "react-icons/gr";
import { RiExternalLinkLine } from "react-icons/ri";
import { IoShareSocial } from "react-icons/io5";
import ShareModalRaga from "./ShareModelRaga.js";
import { debounce } from "lodash";
import { DURATION, ERROR } from "../global/ToastMsgConstants.tsx";
import { CountrySelectorDropdown } from "react-international-phone";
import mlogo from "../MDnDRecords/Static/mlogo.png";

function RagaBankVimeo() {
  // -------------------------------------StatesMaintain----------------------------------------
  const [currentOffset, setCurrentOffset] = useState(0); 
  const [isFetching, setIsFetching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const limit = 15;
  const [mdndData, setMdndData] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [show, setShow] = useState(false);
  const [shareButtonShow,setShareButtonShow]= useState(false);
  
  const navigate = useNavigate();
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const initialFilterState = {
    ragaId: null,
    thalaId: null,
    artistId: null,
    vtypeId: null,
    languageId: null,
    composerId: null,
  };
  const [ragaFilter, setFilterRaga] = useState(initialFilterState);
  const inputRef = useRef(null);
  const handleFilterChange = (name, value) => {
    setFilterRaga((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const resetFilters = () => {
    setFilterRaga(initialFilterState); 
  };
  
  //  ---------------------------------------------------
  const location = useLocation();
  const currentUrl = window.location.href;

  const handleCopy = (e) => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand("copy");
  };
// ------------------------------------------FetchingRagaDetail-------------------------------
  const fetchingRagaVideos = async (limit, offset, ragaFilter) => {
    const { ragaId, thalaId, vtypeId, languageId, composerId,artistId } = ragaFilter;
    const data = { limit, offset };
  
    if (ragaId !== null) data.raga_id = ragaId;
    if (thalaId !== null) data.thala_id = thalaId;
    if (vtypeId !== null) data.vtype_id = vtypeId;
    if (languageId !== null) data.language_id = languageId;
    if (composerId !== null) data.composer_id = composerId;
    if (artistId !== null) data.artist_id = artistId;
  
    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString);
    const GET_MDND_RAGABANK_RECORD = `videos/raga_bank/filter/${base64String}`;
  
    try {
      const response = await makeGetRequest(GET_MDND_RAGABANK_RECORD);
      const records = response.data;
      const ragaWithThumbnails = await Promise.all(
        records.map(async (record) => {
          const thumbnailResponse = await makeGetRequest(
            `${GET_THUMNAIL_FOR_RAGA()}/${record?.id}`
          );
          return {
            ...record,
            thumbnail: thumbnailResponse?.data?.thumbnail,
          };
        })
      );
      return ragaWithThumbnails;
    } catch (error) {
      throw new Error(`Error fetching videos: ${error.message}`);
    }
  };
//-------------------------------------------------------------------
  const loadMoreVideos = useCallback(
    async (resetOffset = false) => {
      if (isFetching) return;
  
      setIsFetching(true);
      try {
        const offset = resetOffset ? 0 : currentOffset;
        const newRecords = await fetchingRagaVideos(limit, offset, ragaFilter);
        setMdndData((prevData) => {
          if (resetOffset) return newRecords;
          const existingIds = new Set(prevData.map((record) => record.id));
          const filteredNewRecords = newRecords.filter(
            (record) => !existingIds.has(record.id)
          );
  
          return [...prevData, ...filteredNewRecords];
        });
        if (!resetOffset) {
          setCurrentOffset((prevOffset) => prevOffset + limit);
        }
      } catch (error) {
        notify(error.message);
      } finally {
        setIsFetching(false);
        setIsLoading(false);
      }
    },
    [currentOffset, ragaFilter]
  );
  // -----------------------------------ifFilterChanges-----------------------------------------------------
  const toCamelCase = (filter) => {
    const snakeToCamel = {
      raga_id: 'ragaId',
      thala_id: 'thalaId',
      artist_id: 'artistId',
      vtype_id: 'vtypeId',
      language_id: 'languageId',
      composer_id: 'composerId',
    };
    return Object.fromEntries(
      Object.entries(filter).map(([key, value]) => [snakeToCamel[key] || key, value])
    );
  };
  const toSnakeCase = (filter) => {
    const camelToSnake = {
      ragaId: 'raga_id',
      thalaId: 'thala_id',
      artistId: 'artist_id',
      vtypeId: 'vtype_id',
      languageId: 'language_id',
      composerId: 'composer_id',
    };
    return Object.fromEntries(
      Object.entries(filter).map(([key, value]) => [camelToSnake[key] || key, value])
    );
  };
  useEffect(() => {
    const urlPath = location.pathname;
    const filterBase64 = urlPath.split('/filter/')[1];
  
    if (filterBase64) {
      try {
        const decodedFilter = JSON.parse(atob(filterBase64)); // Decoding base64 and parsing JSON
        console.log(decodedFilter, "DecodedFilter");
        const camelCaseFilter = toCamelCase(decodedFilter);
        console.log(camelCaseFilter, "camelCaseFilter");
        setFilterRaga((prevFilters) => ({ ...prevFilters, ...camelCaseFilter }));
        fetchingRagaVideos(camelCaseFilter )
        
      } catch (error) {
        console.error("Invalid filter data in URL:", error);
      }
    }
  }, [location.pathname]);
  // ----------------------------------------- 
  useEffect(() => {
    const filteredRagaFilter = Object.fromEntries(
      Object.entries(ragaFilter).filter(([_, value]) => value !== null)
    );
    if (Object.keys(filteredRagaFilter).length > 0) {
      const snakeCaseData = toSnakeCase(filteredRagaFilter);
      console.log(snakeCaseData,"snakeCaseData")
      const jsonString = JSON.stringify({ limit, offset: 0, ...snakeCaseData });
      const base64String = btoa(jsonString);
      const newUrl = `/RagaBank/filter/${base64String}`;
      navigate(newUrl, { replace: true });
      setShareButtonShow(false);
    } else {
      navigate('/RagaBank', { replace: true });
      setShareButtonShow(false);
    }
    loadMoreVideos(true);
  }, [ragaFilter, navigate]);
  //------------------------------------------
  useEffect(() => {
    loadMoreVideos(true); 
  }, [ragaFilter]); 
  
  useEffect(() => {
    setShowModal(true);
  }, []);
  
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.scrollHeight &&
        !isFetching
      ) {
        loadMoreVideos(false); 
      }
    };
    const debounceScroll = debounce(handleScroll, 200); 
    window.addEventListener('scroll', debounceScroll); 
    return () => window.removeEventListener('scroll', debounceScroll);
  }, [loadMoreVideos, isFetching]);
  // ---------------------------------------------ForRouting----------------------------------------------------
    const createSlug = (title) => {
      return title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '_') 
        .replace(/(^-|-$)/g, ''); 
    };
    const handleRagaClick = (data) => {
      console.log(data, "thajosul");
      const json_string = JSON.stringify(
        {
          video_id: data?.id,
          role_id: user?.role_id ?? null
        }
      );
      const slug = btoa(json_string);
      const songTitleSlug = createSlug(data?.song_title);
      navigate(`/RagaBank/${songTitleSlug}/${slug}`);
    }
    //-----------------------------------
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  // -------------------------------------------DateFormat-------------------------------------------
  const formatTime = (dateString) => {
    if (!dateString) return "Invalid date";
    const date = parseISO(dateString);
    const now = new Date();
    const seconds = differenceInSeconds(now, date);
    const minutes = differenceInMinutes(now, date);
    const hours = differenceInHours(now, date);
    const days = differenceInDays(now, date);
    const weeks = differenceInWeeks(now, date);
    const months = differenceInMonths(now, date);
    const years = differenceInYears(now, date);
    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (days < 7) {
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
    } else if (months < 12) {
      return `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
      return `${years} year${years !== 1 ? 's' : ''} ago`;
    }
  };
  // -----------------------------------Raga'sFilterApi------------------------------------------------------------------------------------------------
  const RagaDataOption = async () => await makeGetRequest(RAGA_ITEMS);
  const { data: RagaDataSource } = useQuery({
    queryKey: ["RagaItem"],
    queryFn: () => RagaDataOption(),
    onError: () => {
      notify("No Raga Items", ERROR, DURATION);
    },
  });
  // --------------------------------------Language's FilterApi-------------------------------------------------------------
  const LanguageDataOPtion = async () => await makeGetRequest(LANGUAGE_ITEMS)

  const { data: LanguageDataSource } = useQuery({
    queryKey: ["LanguageItem"],
    queryFn: () => LanguageDataOPtion(),
    onError: () => {
      notify("No Language Item");
    },
  });
  //---------------------------------------VTypeApi's FilterApi--------------------------------------------------------------
  const VTypeDataOption = async () => await makeGetRequest(V_TYPE_ITEMS)
  const { data: VTypeDataSource } = useQuery({
    queryKey: ["VTypeItem"],
    queryFn: () => VTypeDataOption(),
    onError: () => {
      notify("No vType Item");
    },
  });
  //--------------------------------------ComposerDataSource----------------------------------------------------------------
  const ComposerDataOption = async () => await makeGetRequest(COMPOSERS_ITEM);
  const { data: ComposerDataSource } = useQuery({
    queryKey: ["ComposersItem"],
    queryFn: () => ComposerDataOption(),
    onError: () => {
      notify("No Composer Item");
    },
  })
  //---------------------------------ArtistDataSource----------------------------------
  const ArtistDataOption = async () => await makeGetRequest(ALL_ARTIST_DETAIL_RAGA());
  const { data: ArtistDataSource } = useQuery({
    queryKey: ["ArtisNameItem"],
    queryFn: () => ArtistDataOption(),
    onError: () => {
      notify("No Artist Items");
    },
  });
  console.log(ArtistDataSource?.data,"ArtistSource")
  //---------------------------------ThalaDataSource----------------------------------
  const TalaDataOption = async()=> await makeGetRequest(THALA_ITEM);
  const {data:TalaDataSource} = useQuery({
    queryKey:["TalaNameItem"],
    queryFn : () => TalaDataOption(),
    onError:() => {
      notify("No Tala Itme")
    }
  })
  //----------------------------------------HandleGoBack-------------------------------------------------------
  const handleGoBack = () => {
    navigate(-1); 
  };


  // ----------------------------------------------------------------------------------------------------------
  return (
    <div>
            <div className="filter-page iq-breadcrumb RagaBankHeaderBG">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12  d-none d-lg-flex align-items-center justify-content-between">
              <span className="main-title d-flex align-items-center justify-content-center">
                <RagaBank_logo />
                {/* <p className="ms-2 mb-0"> Powered by <b> Kauvery Hospital </b></p> */}
              </span>
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  RagaBank
                </h2>
                <h5 className="mt-4 mt-lg-0 text-white">
                  Search by raga, tala, artist, composer, type and language.
                </h5>
                <a href="https://www.kauveryhospital.com/" target="_blank">
                  <h5 className="mt-2 mt-lg-0 kaveryColor" >
                    <b>
                      Powered by <span className="underline">Kauvery Hospital <RiExternalLinkLine /></span></b>
                  </h5>
                </a>
              </nav>
              <span className="me-2 me-md-3">
                <Kauvery_Hospital_logo />
              </span>
            </div>
            <div className="col-sm-12 align-items-center d-block d-lg-none">
            <div className="d-flex justify-content-between">
              <span className="main-title d-flex align-items-center justify-content-center">
                <RagaBank_logo />
                {/* <p className="ms-2 mb-0"> Powered by <b> Kauvery Hospital </b></p> */}
              </span>
              <span className="me-2 me-md-3">
                <Kauvery_Hospital_logo />
              </span>
              </div>
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  RagaBank
                </h2>
                <h5 className="mt-2 mt-lg-0 text-white">
                  Search by raga, tala, artist, composer, type and language.
                </h5>
                <a href="https://www.kauveryhospital.com/" target="_blank">
                  <h5 className="mt-2 mt-lg-0 kaveryColor" >
                    <b>
                      Powered by <span className="underline">Kauvery Hospital <RiExternalLinkLine /></span></b>
                  </h5>
                </a>
              </nav>

            </div>
          </div>
        </div>
      </div>
      <section className="continue-watching-block pt-lg-3">
        <div className="container-fluid">
          <div className="overflow-hidden">

            <div className="d-md-flex d-block col-12 justify-content-between my-3 mt-md-0 noFade">
            <div className="d-flex align-items-center justify-content-end px-1 col-12">
                
                <div className="d-flex align-items-center">
                  {shareButtonShow && 
                <button
                          className="button d-flex align-items-center me-2"
                          style={{ borderRadius: "4px", height: "33px" }}
                          variant="primary"
                          onClick={handleShow}
                        >
                          <i aria-hidden="true">
                            {" "}
                            <IoShareSocial />
                          </i>
                          <span className="Likenumber">Share</span>
                </button>
                    } 
                        <ShareModalRaga
                          show={show}
                          handleClose={handleClose}
                          currentUrl={currentUrl}
                          handleCopy={handleCopy}
                          inputRef={inputRef}
                          mlogo={mlogo}
                        />
                 {/* SideBar */}
                 <button className="button" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" data-bs-backdrop="false">
                  <FaFilter /> Filters
                </button>
                </div>

              </div>

                <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                  <div className="offcanvas-header d-flex">
                    <div className="d-flex align-items-center">
                      <button type="button" className="btn-close me-2" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                      <h5 className="offcanvas-title " style={{color:"#ff2e2e"}} id="offcanvasWithBothOptionsLabel"> <FaFilter /> Filters</h5>
                    </div>
                    <button className="button" type="button" onClick={resetFilters }>
                      <GrPowerReset className="me-1"/> Reset
                    </button>
                  </div>
                  <div className="offcanvas-body">
                    <ul className="p-0">
                      <li>
                      <SelectBox
                        dataSource={{
                          store: {
                            type: 'array',
                            data: RagaDataSource?.data,
                            key: 'id', 
                          },
                          sort: [{ selector: 'raaga_name', desc: false }], 
                        }}
                        value={ragaFilter.ragaId}
                        searchEnabled={true}
                        className="mb-2"
                        label="Raga"
                        labelMode="floating"
                        displayExpr="raaga_name"
                        valueExpr="id"
                        onValueChanged={(e) =>
                          handleFilterChange("ragaId", e.value)
                        }
                      />
                      </li>
                      <li>
                        <SelectBox
                          dataSource={{
                            store: {
                              type: 'array',
                              data: ArtistDataSource?.data || [],
                              key: 'id'
                            },
                            sort: [{ selector: "artistId", desc: false }]
                          }}
                          value={ragaFilter?.artistId || null}
                          searchEnabled={true}
                          className="mb-2"
                          displayExpr="artist_name"
                          valueExpr="artist_id"
                          label="Artist"
                          labelMode="floating"
                          onValueChanged={(e) =>
                            handleFilterChange("artistId", e.value)
                          }
                        />
                      </li>
                      <li>
                        <SelectBox
                          dataSource={{
                            store: {
                              type: 'array',
                              data: TalaDataSource?.data || [],
                              key: 'id'
                            },
                            sort: [{ selector: "talaId", desc: false }]
                          }}
                          value={ragaFilter?.thalaId || null}
                          searchEnabled={true}
                          className="mb-2"
                          displayExpr="thala_name"
                          valueExpr="id"
                          label="Tala"
                          labelMode="floating"
                          onValueChanged={(e) =>
                            handleFilterChange("thalaId", e.value)
                          }
                        />
                      </li>
                      <li>
                        {" "}
                        <SelectBox
                          dataSource={{
                            store: {
                              type: 'array',
                              data: VTypeDataSource?.data,
                              key: 'id'
                            },
                            sort: [{ selector: 'vtype_name', desc: false }]
                          }}
                          value={ragaFilter.vtypeId}
                          searchEnabled={true}
                          displayExpr="vtype_name"
                          valueExpr="id"
                          className="mb-2"
                          label="Song Type"
                          labelMode="floating"
                          onValueChanged={(e) =>
                            handleFilterChange("vtypeId", e.value)
                          }
                        />
                      </li>
                      <li>
                        {" "}
                        <SelectBox
                          dataSource={{
                            store: {
                              type: 'array',
                              data: ComposerDataSource?.data,
                              key: 'id'
                            },
                            sort: [{ selector: "composer_name", desc: false }]
                          }}
                          value={ragaFilter.composerId}
                          searchEnabled={true}
                          className="mb-2"
                          displayExpr="composer_name"
                          valueExpr="id"
                          label="Composer"
                          labelMode="floating"
                          onValueChanged={(e) =>
                            handleFilterChange("composerId", e.value)
                          }
                        />
                      </li>
                      <li>
                        <SelectBox
                          dataSource={{
                            store: {
                              type: "array",
                              data: LanguageDataSource?.data,
                              key: 'id'
                            },
                            sort: [{ selector: 'song_language_name', desc: false }]
                          }}
                          value={ragaFilter.languageId}
                          searchEnabled={true}
                          displayExpr="song_language_name"
                          valueExpr="id"
                          label="Language"
                          labelMode="floating"
                          className="mb-2"
                          onValueChanged={(e) =>
                            handleFilterChange("languageId", e.value)
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </div>           
            </div>

            <div className="row row-cols-1 row-cols-sm-3 row-cols-md-3 row-cols-lg-5">
              {isLoading
                ? Array.from({ length: 10 }).map((_, index) => (
                  <div className="iq-watching-block mb-lg-3" key={index}>
                    <Skeleton height={180} />
                    <div className="d-flex justify-content-between p-2">
                      <Skeleton circle={true} height={50} width={50} />
                      <div className="w-100 ms-2">
                        <Skeleton count={2} />
                      </div>
                    </div>
                  </div>
                ))
                : mdndData &&
                mdndData?.length > 0 &&
                mdndData?.map((raga, index) => (
                  <div className="iq-watching-block mb-lg-3" key={index}>
                    <div
                      className="block-images position-relative"
                      onClick={() => handleRagaClick(raga)}
                    >
                      <div
                        className="iq-image-box overly-images"
                        style={{
                          borderRadius: "8px",
                          overflow: "hidden",
                          position: "relative",
                          width: "100%",
                          paddingTop: "56.25%",
                        }}
                      >
                        <img
                          src={`${RAGABANK_IMAGEPATH}/${raga?.thumbnail_url}`}
                          alt={raga?.raaga_name}
                          className="img-fluid object-cover w-100 d-block border-0"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>

                      <div className="iq-preogress">
                        <div className="d-flex justify-content-between px-2 mb-1">
                          <p className="mb-0 videoViewBg">

                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <div className="w-100 ms-2">
                        <h6
                          style={{
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          className="mb-0"
                        >
                          <b>
                            {raga?.song_title}
                          </b>
                        </h6>
                        {/* <p className="mb-0 videoMinuts">
                          {formatTime(raga?.created_date)}
                        </p> */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RagaBankVimeo;
