import React from "react";
import { Link } from "react-router-dom";
import LinkExpiredImg from "../../../global/LinkExpired.png"

const LinkExpired = ({ linkExpiredData }) => {
  return (
    <div className="d-flex justify-content-center flex-column mt-5">
      <img src={LinkExpiredImg} className="d-flex justify-content-center m-auto mt-5" style={{ width: 'auto', height: '100%', maxHeight:'300px', maxWidth:'300px' }}/>
      <div className="text-center mt-5">
        <h5>
        {linkExpiredData?.errorMsg || "Oops! This link as expired"}</h5>
        <p className="mt-2">
        <Link style={{ color: "#3f00ff", fontSize: "16px" }} to={`/events?ci=101&c=3659`}>
        Back to Events
        </Link>
        </p>
      </div>
    </div>
  );
};

export default LinkExpired;
