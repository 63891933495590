import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Subpages/MainPage/artist.css";
import { makeGetRequest } from "../../Api/requestMethods.js";
import { useQuery } from "@tanstack/react-query";
import notify from "devextreme/ui/notify";
import { GET_CATEGORY_FOR_RECORDS } from "../../Api/api.js";
import { RecordImage } from "../../Subpages/MainPage/constant.js";
import NoImage from "../../../src/components/static/noImage.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AllRecordsTest = () => {
  const navigate = useNavigate();
  const [selectedGenre, setSelectedGenre] = useState("");

  const handleClick = (genreName) => {
    console.log(genreName, "din");
    setSelectedGenre(genreName);
    navigate(`/MDnDRecordsTest/${genreName.main_cat_name}`, {
      state: { genreName, categoryId: genreName.main_cat_id },
    });
  };

  // ----------------------------FetchingData-----------------------------------------------------------------
  const fetchCategory = async () => {
    try {
      const response = await makeGetRequest(GET_CATEGORY_FOR_RECORDS);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch category data");
    }
  };

  const {
    data: categoryData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["categoryData"],
    queryFn: fetchCategory,
    onError: () => {
      notify("ERROR", "Failed to fetch category data");
    },
  });

  if (isLoading) {
    return (
      <>
        <Skeleton height={160} className="mb-4" />
        <div className="container-fluid">
          <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
            {Array(10)
              .fill(0)
              .map((_, index) => (
                <div className="col mb-4" key={index}>
                  <div className="">
                    <div className="block-images position-relative w-100">
                      <div className="img-box rounded position-relative">
                        <Skeleton height={180} />
                        <div className="blog-description"></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  }

  if (error) {
    return <div>Error loading category data</div>;
  }

  return (
    <>
      <div className="iq-breadcrumb mdndrecord-Banner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  MDnD Records
                </h2>
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  We'll have all the videos up in a few days. Please bear with
                  us.
                </h2>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className="section-padding pt-lg-3">
        <div className="container-fluid">
          <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
            {categoryData.map((genre) => (
              <div className="col mb-4" key={genre.main_cat_id}>
                <div
                  className="iq-card-geners card-hover-style-two"
                  onClick={() => handleClick(genre)}
                >
                  <div className="block-images position-relative w-100">
                    <div className="img-box rounded position-relative">
                      <img
                        src={
                          genre?.image_name !== ""
                            ? `${RecordImage}/${genre?.img_name}`
                            : NoImage
                        }
                        alt="genre-img"
                        className="img-fluid object-cover w-100 rounded"
                      />
                      <div className="blog-description">
                        <h6 className="mb-0 iq-title text-black">
                          {genre.main_cat_name}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default AllRecordsTest;
