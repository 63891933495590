import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import IMAGE1 from "../../assets/images/genre/02.webp";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react";
import { FaFilter } from "react-icons/fa";
import "./MDnDLive.css";

const dataSource = [
  { value: "value1", text: "Text 1" },
  { value: "value2", text: "Text 2" },
  { value: "value3", text: "Text 3" },
  // Add more items as needed
];

function OffCanvasExample({ name, show, handleClose, ...props }) {
  return (
    <>
      <Offcanvas
        className="commentMobileHeight"
        show={show}
        onHide={handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Comments</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-inline m-0 p-0">
            <li className="pb-3 mb-3">
              <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
                <div className="flex-shrink-0 videoCommentImg">
                  <img src={IMAGE1} alt="user-image" className="img-fluid" />
                </div>
                <div className="about-user">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <h5 className="mb-0">Jack Stark</h5>
                    <span className="text-uppercase fst-italic fw-semibold published-date">
                      <i className="fas fa-minus fa-xs"></i> march 2, 2022
                    </span>
                  </div>
                  <p className="mt-2 mb-0">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form.
                  </p>
                </div>
              </div>
            </li>
            <li className="mb-3">
              <div className="d-flex flex-sm-row align-items-sm-center align-items-start gap-2">
                <div className="flex-shrink-0 videoCommentImg">
                  <img src={IMAGE1} alt="user-image" className="img-fluid" />
                </div>
                <div className="about-user">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <h5 className="mb-0">Jhon Deo</h5>
                    <span className="text-uppercase fst-italic fw-semibold published-date">
                      <i className="fas fa-minus fa-xs"></i> march 2, 2022
                    </span>
                  </div>
                  <p className="mt-2 mb-0">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
              </div>
            </li>
          </ul>
          <div className="d-flex align-items-center mt-2 mobileCommFixed">
            <div className="videoCommentImg">
              <img src={IMAGE1}></img>
            </div>
            <div className="ms-2 w-100">
              <textarea
                className="w-100 textareaBorder"
                placeholder="Add a comment..."
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

function MDnDLive() {
  const values = [true];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(true);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  return (
    <>
      {values.map((v, idx) => (
        <Button
          key={idx}
          className="me-2 mb-2 border-0 bg-white"
          onClick={() => handleShow(v)}
        >
          Full screen
        </Button>
      ))}
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header className="p-0 popUpCloseBtn" closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div>
            <div className="section-padding height-auto-100 image-flip-rtl countDownImg">
              <div className="container h-100">
                <div className="d-flex flex-column justify-content-center h-100">
                  <h4 className="fw-500 mb-0 text-center text-md-start text-white">
                    40th year Ramanavami Utsavam - Discourses by Trichy Sri
                    Kalyanaraman
                  </h4>
                  <h2 className="fw-500 mb-0 text-center text-md-start text-white">
                    Countdown to event
                  </h2>
                  <ul
                    className="countdown d-flex align-items-center list-inline count-down my-5 py-3"
                    data-date="May 30 2025 20:20:22"
                  >
                    <li className="position-relative d-inline-lock">
                      <span data-days className="numberDisplay">
                        23
                      </span>
                      <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">
                        Days
                      </span>
                    </li>
                    <li className="position-relative d-inline-block">
                      <span data-hours className="numberDisplay">
                        140
                      </span>
                      <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">
                        Hours
                      </span>
                    </li>
                    <li className="position-relative d-inline-block">
                      <span data-minutes className="numberDisplay">
                        60
                      </span>
                      <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">
                        Minutes
                      </span>
                    </li>
                    <li className="position-relative d-inline-block">
                      <span data-seconds className="numberDisplay">
                        35
                      </span>
                      <span className="text-center text-capitalize fw-semibold d-block pt-1 letter-spacing-1 text-white">
                        Seconds
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <div className="section-padding pt-lg-3">
          <div className="container-fluid">
            <h6>Raga Bank / Dharmavathi / Video Title</h6>
            <div className="row gx-lg-4 gx-md-3 gx-0 poSticky">
              <div className="col-lg-9">
                <img src={IMAGE1} className="rounded poStickyImg"></img>
                <div className="mt-1">
                  <h4>
                    <b>Video Title</b>
                  </h4>
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="videoCommentImg">
                        <img src={IMAGE1}></img>
                      </div>
                      <div className="w-100 ms-2">
                        <h6 className="mb-0">
                          <b>Channel Name</b>
                        </h6>
                        <p className="mb-0 videoMinuts">31 minutes ago</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button className="btn py-1 px-3 filterBtn d-flex align-items-center">
                        <i
                          className="fa fa-thumbs-up me-2"
                          aria-hidden="true"
                        ></i>
                        31K
                      </button>
                      <button className="btn py-1 px-3 filterBtn d-flex align-items-center">
                        <i className="fa fa-thumbs-down" aria-hidden="true"></i>
                      </button>
                      <button className="btn py-1 px-3 filterBtn d-flex align-items-center">
                        <i
                          className="fa-solid fa-share me-2"
                          aria-hidden="true"
                        ></i>
                        Share
                      </button>
                    </div>
                  </div>
                  <div className="shadow-sm p-2 mt-2">
                    <p>
                      <b>182K views 5 days ago</b>
                    </p>
                    <p className="mb-0">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <h5>
                      <b>105 Comments</b>
                    </h5>
                    <div className="ms-4">
                      <i className="fa-solid fa-sort me-2"></i>
                      Sort
                    </div>
                  </div>
                  <div className="product-review-list">
                    <ul className="list-inline m-0 p-0">
                      <li className="pb-3 mb-3">
                        <div className="d-flex align-items-center mt-2">
                          <div className="videoCommentImg">
                            <img src={IMAGE1}></img>
                          </div>
                          <div className="ms-2 w-100">
                            <OffCanvasExample
                              name="bottom"
                              show={showOffcanvas}
                              handleClose={handleCloseOffcanvas}
                              placement="bottom"
                            />

                            <textarea
                              className="w-100 commentLargeView textareaBorder"
                              placeholder="Add a comment..."
                            />

                            <textarea
                              className="w-100 commentMobileView textareaBorder"
                              placeholder="Add a comment..."
                              onClick={handleShowOffcanvas}
                            />
                          </div>
                        </div>
                      </li>

                      <li className="pb-3 mb-3 d-noneSmallDevice">
                        <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-2">
                          <div className="flex-shrink-0 videoCommentImg">
                            <img
                              src={IMAGE1}
                              alt="user-image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="about-user">
                            <div className="d-flex align-items-center flex-wrap gap-2">
                              <h5 className="mb-0">Jack Stark</h5>
                              <span className="text-uppercase fst-italic fw-semibold published-date">
                                <i className="fas fa-minus fa-xs"></i> march 2,
                                2022
                              </span>
                            </div>
                            <p className="mt-2 mb-0">
                              There are many variations of passages of Lorem
                              Ipsum available, but the majority have suffered
                              alteration in some form.
                            </p>
                          </div>
                        </div>
                      </li>
                      <li className="mb-3 d-noneSmallDevice">
                        <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-start gap-2">
                          <div className="flex-shrink-0 videoCommentImg">
                            <img
                              src={IMAGE1}
                              alt="user-image"
                              className="img-fluid"
                            />
                          </div>
                          <div className="about-user">
                            <div className="d-flex align-items-center flex-wrap gap-2">
                              <h5 className="mb-0">Jhon Deo</h5>
                              <span className="text-uppercase fst-italic fw-semibold published-date">
                                <i className="fas fa-minus fa-xs"></i> march 2,
                                2022
                              </span>
                            </div>
                            <p className="mt-2 mb-0">
                              It is a long established fact that a reader will
                              be distracted by the readable content of a page
                              when looking at its layout.
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MDnDLive;
