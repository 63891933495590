import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IMAGE_URL } from '../../Subpages/MainPage/constant';
import { BASEPATH } from '../../config/config';


const Franchisees = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const handleClick = (profileData,role) => {
    navigate(`/franchisees/${profileData.profile_name}`, { state: { profileData,userId:profileData.organiser_id,role} });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const role = 'frenchises';
        const response = await axios.post(
          `${BASEPATH}profiles/get_all_organisers`,
          {
            user_role: role,
          }
        );
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">Franchisees</h2>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className={`section-padding`}>
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-6">
            {data.map((person, index) => (
              <div className="col" key={index}>
                <div className="iq-cast" onClick={() => handleClick(person,"frenchises")}>
                <img 
                src={`${person.profile_photo === " " ? `${IMAGE_URL}/${person.profile_photo}` : `${IMAGE_URL}/noimage.png`}`} 
                 className="img-fluid" 

                 />                   <div className="card-img-overlay iq-cast-body">
                    <h6 className="cast-title fw-500 text-white">
                      {person.organiser_name}
                    </h6>
                    {/* <span className="cast-subtitle">
                      {person.profile_name}
                    </span> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default  Franchisees ;
