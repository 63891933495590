import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const StreamPopup = ({ showModal}) => {
    const navigate = useNavigate();
    const handleClose =()=>{
        navigate('/login')
    }
  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Access Denied !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        "You are not allow to watch this video."
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StreamPopup;
