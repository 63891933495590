
const RegistrationSchema = () => {
    return {
      Artist: {
        body: {
          artist_main_cat: null,
          artist_sub_cat: null,
          country_code: null,
          country_id: null,
          state_id: null,
          city_id: null,
          gender: "M",
          dob: "",
        },
      },
      Organisers: {
        body: {
          country_code: null,
          country_id: null,
          state_id: null,
          city_id: null,
        },
      },

      Fan: {
        body: {
          gender: "M",
          fan_main_cat: "",
          country_id: null,
          state_id: null,
          city_id: null,
        },
      },
    };
};

const ForgotPasswordSchema = {
  email: "",
};
export {
    RegistrationSchema,
    ForgotPasswordSchema

}