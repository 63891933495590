import React from "react";
import "../assets/vendor/font-awesome/css/all.min.css";
import "../assets/images/favicon.ico";
import "../assets/css/core/libs.min.css";
import "../assets/vendor/iconly/css/style.css";
import { Link, NavLink, useLocation } from "react-router-dom";

import "../assets/vendor/animate.min.css";
import "../assets/vendor/swiperSlider/swiper.min.css";
import "../assets/vendor/sweetalert2/sweetalert2.min.css";
import "../assets/css/streamit.min.css?v=1.0.0";
import "../assets/css/rtl.min.css?v=1.0.0";
import "../assets/css/custom.min.css?v=1.0.0";
import logo from "../Layout/Static/logo.png";
import { headerDisabledPath } from "../global/constants";

const Footer = () => {
  const location = useLocation();
  const currentLocation = location.pathname;
  return (
    <>
      {currentLocation === "/" && (
        <footer
          className="footer-merchandise p-4"
          style={{ backgroundColor: "#f6f6f6" }}
        >
          <div className="container w-100 mb-3 mb-lg-0">
            <div className="w-100 row my-4 g-3">
              <div className="col-lg-4 col-xl-3 col-sm-6 d-flex flex-column gap-2">
                <div className="w-50" style={{ height: "28px" }}>
                  <img className="cartIcon" src={logo}></img>
                </div>
                <p className="text-black">
                  149/1, Luz Church Road, Mylapore, Chennai - 600004 , Tamil nadu, India
                </p>
              </div>
              <div className="col-lg-4 col-xl-3 col-sm-6">
                <p className="letter-spacing-1 font-size-14 text-black fw-bold">
                  Customer Service
                </p>
                <h4 className="mb-0 contact font-size-14 text-black  ">
                  <span className="font-size-14 text-black">
                    WhatsApp "Hi" to{" "}
                  </span>
                  &nbsp;+91 8072 336688
                </h4>
                <span className="font-size-14 text-black">Email us: </span>
                <span className="d-inline-block m-2 mb-3 font-size-14 text-black underline">
                  <a
                    href="events@mdnd.in"
                    className="text-black "
                    target="_blank"
                  >
                    events@mdnd.in
                  </a>
                </span>
              </div>
              <div className="col-lg-4 col-xl-3 col-sm-6  col-6 d-flex flex-column gap-2">
                <p className="m-0 p-0 font-size-14 text-black fw-bold">
                  Company
                </p>
                <ul className="list-unstyled footer-menu d-flex flex-column gap-1">
                  <li>
                    <NavLink to="About" className="ms-3 text-black">
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="Faqs" className="ms-3 text-black">
                      FAQs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="Contact" className="ms-3 text-black">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-xl-3 col-sm-6 col-6 d-flex flex-column gap-2">
                <p className="m-0 p-0 font-size-14 text-black fw-bold">
                  Policies
                </p>
                <ul className="list-unstyled footer-menu d-flex flex-column gap-1">
                  <li>
                    <NavLink to="Terms" className="ms-3 text-black">
                      Terms
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="PrivacyPolicy" className="ms-3 text-black">
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="Cancellation" className="ms-3 text-black">
                      Cancellation Policy
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="footer-bottom border-top py-4 "
              style={{ backgroundColor: "#f6f6f6" }}
            >
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-md-12 text-md-start text-center">
                    <div className="d-flex flex-column flex-md-row m-0 font-size-14 text-capitalize justify-content-center text-black gap-md-2">
                      Copyright © 2017 - 2024{" "}
                      <span>MDnD Entertainment Private Limited</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
