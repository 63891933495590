import { DataGrid } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Scrolling, Pager, Paging, Column } from 'devextreme-react/data-grid';
import { makeGetRequest } from '../../../Api/requestMethods';
import { HISTORY_DETAIL_MAIL } from '../../../Api/api';
import { useQuery } from '@tanstack/react-query';
import notify from 'devextreme/ui/notify';
import { DURATION, SUCCESS } from '../../../global/ToastMsgConstants.tsx';
import { jwtDecode } from 'jwt-decode'; // Fix the import to use named import
import Skeleton from 'react-loading-skeleton'; // Import Skeleton component
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton styles

function History() {
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );
  const role_id = user?.role_id;

  const fetchingHistoryDetail = async () =>
    await makeGetRequest(`${HISTORY_DETAIL_MAIL()}/${role_id}`);

  const {
    data: HistoryDetail,
    isLoading, // Add isLoading to check loading state
  } = useQuery({
    queryKey: ["historyDetail"],
    queryFn: () => fetchingHistoryDetail(),
    onError: (error) => {
      console.error("Error fetching history:", error);
    },
    onSuccess: (data) => {
      notify(data.message, SUCCESS, DURATION);
    },
  });

  console.log(HistoryDetail, "historyDetail");

  return (
    <div id="data-grid-demo">
      {isLoading ? (
        // Show Skeleton Loader when data is loading
        <Skeleton count={10} height={40} style={{ marginBottom: '10px' }} />
      ) : (
        // Show DataGrid when data is loaded
        <DataGrid
          id="gridContainer"
          dataSource={HistoryDetail?.data}
          rowAlternationEnabled={true}
          showBorders={true}
        >
          <Scrolling rowRenderingMode="virtual" />
          <Paging defaultPageSize={4} />
          <Pager visible={true} showPageSizeSelector={true} showInfo={true} />
          <Column dataField="id" caption="SL" />
          <Column dataField="mail_ids" caption="Mail_Id" />
          <Column dataField="membership_type" caption="Member_Type" />
          <Column dataField="message" caption="Message" />
          <Column dataField="subject" caption="Subject" />
          <Column dataField="sent_to_profile" caption="Send_Date" />
          <Column dataField="send_at" dataType="date" caption="Send At Date" />
        </DataGrid>
      )}
    </div>
  );
}

export default History;
