import React from 'react'
import { useNavigate } from 'react-router-dom'

const DashBoardMyFavourites = () => {
    const navigate = useNavigate()

    const backButton = () => {
        navigate("/dashboard")


    }
  return (
    <>
      <button onClick={backButton}>Back Button</button>
      <div>DashBoardMyFavourites</div>
    </>
  );
}

export default DashBoardMyFavourites