const INVALID_EMAIL = `Invalid Email`;
const PASSWORD_LENGTH = `Password must be atleast 2 characters`;
const SOMETHING_WENT_WRONG = `Something went wrong!`;
const PREFERENCE_MUST = `Please select atleast one preference`
const CITY_MUST = `Please select atleast one city`;
const LIMITATION_ERROR = (limit, row) =>
  `your transaction limit for row ${row} is ${limit}`;
const ticketQtyRequired = `Ticket quantity is required`;
const profileDetailsNotFound = `Profile details not found!`


export {
  profileDetailsNotFound,
  INVALID_EMAIL,
  PASSWORD_LENGTH,
  SOMETHING_WENT_WRONG,
  PREFERENCE_MUST,
  CITY_MUST,
  LIMITATION_ERROR,
  ticketQtyRequired,
};