import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ShareComponent from "./ShareComponent";
import "./Firstpage.css";
import HowToPlay from "./HowToPlay";
import { Helmet } from "react-helmet";
import ragaRiddle from "../../assets/Icons/Raga Riddle 03.png"
import adImage from "../../assets/Icons/RagaRiddleAd.png";

function Firstpage() {
  const navigate = useNavigate();
  const [advertisementMessage, setAdvertisementMessage] = useState(null);
  const [advertisementLink, setAdvertisementLink] = useState(null);
  const [showHowTo, setShowHowTo] = useState(false);
  const [navigateTo, setNavigateTo] = useState('null');

  const handlePlayByRagaName = () => {
    //setAdvertisementMessage('Your Advertisement here!!!');
    setAdvertisementMessage(adImage);
    setAdvertisementLink('/SeasonTickets/organiserListing'); 
    setNavigateTo('/ragariddle/playbyraganame'); 
  };

  const handlePlayBySwara = () => {
   // setAdvertisementMessage('Your Advertisement here!!!');
   setAdvertisementMessage(adImage);
   setAdvertisementLink('/SeasonTickets/organiserListing'); 
    setNavigateTo('/ragariddle/playbyswara'); 
  };

  const handleCloseAdvMessage = () => {
    //setAdvertisementMessage(''); 
    setAdvertisementMessage(null);
    console.log(advertisementMessage);
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  const handleStartGame = () => {
    handleCloseAdvMessage(); // Close the advertisement first
    if (navigateTo) {
      navigate(navigateTo); // Navigate to the stored route
    }
  };

  const toggleHowTo = () => {
    setShowHowTo(!showHowTo);
  };

  return (
    <>
    <Helmet>
    <title>RagaRiddle - Solve the riddle in 5 chances.</title>
    <meta
          name="description"
          content="RagaRiddle - A fun word puzzle in which the user has to guess the raga of the day in 5 chances.This puzzle can be played in 2 modes,namely in Raga mode or Swara mode."
        />
    </Helmet>
    <div className="RagaRiddle container-fluid">
      {/* How to Play Button */}
      
      {/* Show How to Play Instructions */}
      {showHowTo && <HowToPlay onClose={toggleHowTo} />}

      {/* Advertisement Message Container */}
      {advertisementMessage && (
        <div className="container  d-flex justify-content-center flex-column" style={{width:"fit-content"}}>
          
          <div className="advertisement-msg d-flex justify-content-center m-auto"  style={{width:"fit-content"}}>
          <a href={advertisementLink} target="_self">
          
          <img src={advertisementMessage} alt="Advertisement" className="ad-image" />
          </a>
          <button className="adv-close-button" onClick={handleCloseAdvMessage}>
            &times; {/* Close button */}
          </button>
         
          </div>
          <div className="d-flex justify-content-center justify-content-md-end gap-2 gameBTn">
            <button className="StartGame rounded" onClick={handleStartGame}>
              Proceed to play
            </button>
          </div>
        </div>
      )}

      {/* Main Page with Play Mode Buttons */}
      {!advertisementMessage && !showHowTo && (
        <div className="bg-white rounded-3 p-4 m-auto text-center shadow">
          <img style={{height: "250px", width: "250px"}} src={ragaRiddle}/>
          <h2><b>RagaRiddle</b></h2>
          <h6 className="mb-4">Solve the riddle in 5 Chances</h6>
          <div className="d-md-flex d-block">
          
            <button
              id="ragaButton"
              className="play-riddle-button me-0 me-md-4 rounded-pill"
              onClick={handlePlayByRagaName}
            >
              Play by Raga name
            </button><br />
            <button
              id="swaraButton"
              className="play-riddle-button rounded-pill"
              onClick={handlePlayBySwara}
            >
              Play by Swaras
            </button>
          </div>
          <p className="text-center">Version 1.0.1</p>
        </div>
      )}
      <div className="share-icons">
      

      <ShareComponent  shareMessage="Checkout RagaRiddle - A fun puzzle!"/>
      {!showHowTo && (
          <button style={{color:"#3f00ff"}} className="mt-3 rounded  d-flex  justify-content-end" onClick={toggleHowTo}>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path  d="M7.879 5.519C9.05 4.494 10.95 4.494 12.121 5.519C13.293 6.544 13.293 8.206 12.121 9.231C11.918 9.41 11.691 9.557 11.451 9.673C10.706 10.034 10.001 10.672 10.001 11.5V12.25M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 15.25H10.008V15.258H10V15.25Z" 
     stroke="#3f00ff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</button>
      )}
      </div>
    </div>
    </>
  );
}

export default Firstpage;
