import React from "react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import { GET_DRAFTS_FROM_BACKEND } from "../../../Api/api";
import { useMutation } from "@tanstack/react-query";
import { BASEPATH } from "../../../config/config";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import axios from "axios";
import { DataGrid } from "devextreme-react";
import { Column, MasterDetail } from "devextreme-react/data-grid";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import EditDraftForms from "./EditDraftForms";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export default function SavedDraftsForms() {

    const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);
    const token = localStorage.getItem("MDnD_auth");
    const [loading, setLoading] = useState(true);

    const [isMasterDetailVisible, setIsMasterDetailVisible] = useState(true); // Initially open

    const handleSuccessFromBackend = () => {
        setIsMasterDetailVisible(false);
    };
    const navigate = useNavigate();
    const [splcounter, setsplcounter] = useState(0);
    const [formsFromBackend, setFormsFromBackend] = useState({
        formData: "",
        profileDetails: "",
        singleData: "",
        parsedData: "",
    })

    useEffect(() => {
        if (user) {
            mutationgetDrafts.mutate();
        }

    }, [splcounter])

    const GetAllDrafts = () =>
        GET_DRAFTS(GET_DRAFTS_FROM_BACKEND);

    const GET_DRAFTS = async (
        GET_DRAFTS_FROM_BACKEND) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_DRAFTS_FROM_BACKEND}`,
                data: {
                    role_id: user ? user.role_id : "",
                    draft_from: "form"
                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',

            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationgetDrafts = useMutation({
        mutationKey: ["get_all_drafts"],
        mutationFn: () => GetAllDrafts(),

        onSuccess: (data) => {
            setLoading(false);
            //   queryClient.invalidateQueries({
            //     queryKey: ["fetchUser"],
            //   });


            //console.log(data, "DATA");

            if (data.status === "success") {

                //let filteredArray = data.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setFormsFromBackend((prevState) => ({ ...prevState, formData: data.data }));


            }
            else {
                setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
            }
        },
        onError: (error) => {
            setLoading(false);
            //setTokenerProfileCollection((prevState) => ({ ...prevState, message: "", validation_msg: "" }));
            HandleErrorToast("error", "Form Fields Could not be Fetched. Something went Wrong!", 3000);
            setFormsFromBackend((prevState) => ({ ...prevState, formData: "" }));
        },
    });

   



    useEffect(() => {
        if (formsFromBackend.formData && formsFromBackend.formData.length > 0) {
            let newformdata = [];
            for (let i = 0; i < formsFromBackend?.formData?.length; i++) {
                let initialobj = formsFromBackend?.formData[i];
                newformdata[i] = {};

                let filteredObject = {};
                try {
                    filteredObject = formsFromBackend?.formData[i]['draft_data']
                        ? JSON.parse(formsFromBackend?.formData[i]['draft_data'])
                        : {};
                } catch (error) {
                    console.error("Error parsing JSON:", error, formsFromBackend?.formData[i]['draft_data']);
                    filteredObject = {};
                }

                if (filteredObject) {
                    newformdata[i] = filteredObject;
                    // newformdata[i]['draft_id'] = initialobj['draft_id']; // Uncomment if needed
                }
            }


           // console.log(newformdata, "newformdata")

            if (newformdata) {
                setFormsFromBackend((prevState) => ({ ...prevState, parsedData: newformdata }));
            }


        }

    }, [formsFromBackend.formData])

    // useEffect(() => {
    //     if (formsFromBackend.formData && formsFromBackend.formData.length > 0) {
    //         let newformdata = [];
    //         for (let i = 0; i < formsFromBackend?.formData?.length; i++) {
    //             let initialobj = formsFromBackend?.formData[i];
    //             newformdata[i] = {};

    //             if (initialobj && initialobj['draft_data']) {
    //                 try {
    //                     // Use regex to extract and remove the formimage field from the string
    //                     const formimageMatch = initialobj['draft_data'].match(/"formimage":"(data:image\/[^"]+)"/);
    //                     let formimage = formimageMatch ? formimageMatch[1] : null;

    //                     // Remove the formimage from the JSON string
    //                     let draftDataWithoutImage = initialobj['draft_data'].replace(/"formimage":"data:image\/[^"]*"/g, '');

    //                     // Parse the JSON without the formimage field
    //                     const filteredObject = JSON.parse(draftDataWithoutImage);

    //                     // Store the parsed data without formimage
    //                     newformdata[i] = filteredObject;

    //                     // Re-add the formimage field if it was found
    //                     if (formimage) {
    //                         newformdata[i]['formimage'] = formimage;
    //                     }

    //                 } catch (error) {
    //                     // Log the error and problematic draft_data
    //                     console.error("Error parsing JSON for draft_data:", initialobj['draft_data'], error);
    //                 }
    //             }
    //         }

    //         console.log(newformdata, "newformdata");

    //         if (newformdata) {
    //             setFormsFromBackend((prevState) => ({ ...prevState, parsedData: newformdata }));
    //         }
    //     }
    // }, [formsFromBackend.formData]);



    //console.log(formsFromBackend, "formsFromBackend");

    const handleEdit = (cellInfo) => {
        return (
            <>
                <MdModeEditOutline
                    className="EditIcon me-4"
                    onClick={() => handleEdit_final(cellInfo)}
                />
            </>)
    }

    const handledelete = (cellInfo) => {

        return (<>
            <RiDeleteBinLine
                className="DeleteIcon"
                onClick={() => handleDelete_final(cellInfo)}
            />
        </>
        )

    }

    const handleEdit_final = (cellInfo) => {


        const rowData = cellInfo.data;
        const dataGrid = cellInfo.component;
        dataGrid.expandRow(rowData);


    }

    const handleDelete_final = (cellInfo) => {



    }



    const handleCounterUpdate = () => {
        setsplcounter(splcounter + 1);
    };


    return (<>
        {loading ? (
            <Skeleton count={10} height={60} />
        ) : (
            <DataGrid
                dataSource={formsFromBackend.parsedData || []}
                showBorders={true}
                columnAutoWidth={true}
                //columnHidingEnabled={true}
                rowAlternationEnabled={true}
            >

                <Column
                    caption="Sl.No"
                    cellRender={(cellInfo) => {
                       
                        const { rowIndex, component } = cellInfo;
                        const pageSize = component.pageSize();
                        const pageIndex = component.pageIndex();
                        const totalCount = component.totalCount();


                        const descendingIndex = totalCount - (pageIndex * pageSize + rowIndex);

                        return descendingIndex;
                    }}
                    width={80}
                    allowEditing={false}
                />
                <Column
                    dataField="formmode"
                    caption="Form Mode"
                />
                <Column
                    dataField="draft_id"
                    visible={false}
                />

                <Column
                    dataField="formtitle"
                    caption="Form Title"
                />
                <Column
                    caption="Edit"
                    cellRender={handleEdit}
                />

                {isMasterDetailVisible && (
                    <MasterDetail
                        enabled={true}
                        component={(props) => <EditDraftForms {...props} onCounterUpdate={handleCounterUpdate} onSuccess={handleSuccessFromBackend} />}
                    />)}



            </DataGrid>
        )}


    </>)
}