import { useState } from "react";
import { TextBox } from "devextreme-react";
import Offcanvas from "react-bootstrap/Offcanvas";
import mdndMlog from "../../../assets/images/MDND_M_LOGO.png";

function PromoCodeSummary({
  isOpen,
  applyIndidualPromoCode,
  promoCodeDataSource,
  applyPromoCode,
  checkPromoCodeAvailability,
  availablePromoCodeError,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <>
      <Offcanvas
        show={isOpen}
        onHide={() => applyIndidualPromoCode(true)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> Discount</Offcanvas.Title>
        </Offcanvas.Header>
        <hr></hr>
        <Offcanvas.Body>
          <div className="gap-3 pd-2 ">
            <div className="d-flex flex-row gap-3 align-items-start  justify-content-between">
              <div className="d-flex flex-column w-100">
                <TextBox
                  className="w-100"
                  type="text"
                  label="Promo code"
                  labelMode="floating"
                  style={{ width: "100%", margin: "0" }}
                  onValueChanged={(e) => setSearchQuery(e.value)}
                  value={searchQuery}
                ></TextBox>
                <p
                  className="p-0 m-0 error-message mt-1"
                  style={{ color: "red" }}
                >
                  {availablePromoCodeError !== ""
                    ? availablePromoCodeError
                    : ""}
                </p>
              </div>
              <button
                className="button mt-1"
                onClick={() => checkPromoCodeAvailability(searchQuery)}
              >
                Apply
              </button>
            </div>
          </div>
          <div className="d-flex flex-column gap-3 mt-2">
            {promoCodeDataSource &&
              promoCodeDataSource.map((data, index) => (
                <div
                  key={index}
                  className="d-flex flex-column gap-3"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    cursor: !data.promoCodeEnabled ? "not-allowed" : "pointer",
                  }}
                  title={
                    !data.promoCodeEnabled
                      ? "You are not eligible to apply this promo code"
                      : ""
                  }
                >
                  <div
                    className="w-100 d-flex gap-2 p-2 py-3 align-items-center "
                    style={{
                      borderStyle: "solid",
                      borderColor: "#ddd",
                      borderWidth: "1px",
                      borderBottom: "none",
                      borderRadius: "6px",
                      cursor: !data.promoCodeEnabled
                        ? "not-allowed"
                        : "pointer",
                    }}
                  >
                    <img src={mdndMlog} style={{ width: "28px" }} />
                    <p
                      className="w-100 p-0 m-0"
                      style={{ fontWeight: "500", overflow: "hidden" }}
                    >
                      {data.description}
                    </p>
                  </div>
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{
                      width: "105%",
                      left: "-10px",
                      top: "40%",
                      position: "absolute",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        borderStyle: "Solid",
                        borderColor: "#ddd",
                        borderWidth: "1px",
                        cursor: !data.promoCodeEnabled
                          ? "not-allowed"
                          : "pointer",
                      }}
                    ></div>
                    <div className="dashed-border"></div>
                    <div
                      style={{
                        width: "24px",
                        height: "24px",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        borderStyle: "Solid",
                        borderColor: "#ddd",
                        borderWidth: "1px",
                        cursor: !data.promoCodeEnabled
                          ? "not-allowed"
                          : "pointer",
                      }}
                    ></div>
                  </div>
                  <div
                    className="d-flex flex-row gap-2 p-3 justify-content-between"
                    style={{
                      borderStyle: "solid",
                      borderColor: "#ddd",
                      borderWidth: "1px",
                      borderTop: "none",
                      borderRadius: "6px",
                      cursor: !data.promoCodeEnabled
                        ? "not-allowed"
                        : "pointer",
                    }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-between py-1 px-3 border border-light mdnd-gray"
                      style={{ fontSize: "14px", borderRadius: "4px" }}
                    >
                      {data.promo_code}
                    </div>
                    <button
                      style={{
                        cursor: !data.promoCodeEnabled
                          ? "not-allowed"
                          : "pointer",
                      }}
                      disabled={!data.promoCodeEnabled}
                      className="border-button"
                      onClick={() => applyPromoCode(data, false)}
                    >
                      Apply Coupon
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default PromoCodeSummary;
