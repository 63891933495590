import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div>
      {/* <div className="iq-breadcrumb organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="title text-white">Privacy Policy</h2>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid mt-5">
      <div className="row align-items-center mb-4">
              <div className="text-center">
                <h2>Privacy Policy</h2>
                {/* <p className="text-center">MDnD stands for ‘Music, Dance and Drama’.</p> */}
                {/* <p>
                  On <a href="https://www.mdnd.in">www.mdnd.in</a>, you can-
                </p> */}
              </div>
          </div>
        <div className=" pt-lg-4">
          <p className="fs-6">This privacy policy sets out how MDnD Entertainment Pvt Ltd (MDnD) uses and protects any information that you give &nbsp;<a style={{color:"#3f00ff"}} href='www.mdnd.in'>www.mdnd.in</a> , www.musicofmadras.in, “Music of Madras”, “Music of Bangalore”, “Music IN CA”, “Music of Bombay”, Raga Bank, MDnD Records, & Music of Madras App (Android & iOS) when you use them </p>
          <p className="fs-6">MDnD is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using the Music of Madras Apps & website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
          <p className="fs-6">MDnD may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy has been in effect since 31st October, 2017.</p>
          <p className="fs-6 mb-0"><b>What we collect</b></p>
          <p className="fs-6 mb-0">We may collect the following information: </p>
          <ol>
            <li><p className="mb-0">Name</p></li>
            <li><p className="mb-0">Contact information including email address and mobile number</p></li>
            <li><p className="mb-0">Address, pin-code & location</p></li>
            <li><p className="mb-0">Areas of specialisation, services and other information relating to your profession, vocation or business.</p></li>
            <li><p className="mb-0">Photographs</p></li>
            <li><p className="mb-0">Other information relevant to customer surveys and/or offers</p></li>
          </ol>
          <p className="fs-6 mb-0"><b>What we do with the information we gather</b></p>
          <p className="fs-6 mb-0">We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
          <ol>
            <li><p className="mb-0">Internal record keeping.</p></li>
            <li><p className="mb-0">To help promote you and your services among the target customer groups.</p></li>
            <li><p className="mb-0">To improve our products and services.</p></li>
            <li><p className="mb-0">We may periodically send promotional emails and messages about different programmes related to Music, Dance and Drama which we think you may find interesting using the email address which you have provided.</p></li>
            <li><p className="mb-0">From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</p></li>
          </ol>
          <p className="fs-6 mb-0"><b>Security</b></p>
          <p className="fs-6">We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
          <p className="fs-6 mb-0"><b>How we use cookies</b></p>
          <p className="fs-6">Cookies allow us to tailor our operations to your needs, likes and dislikes by gathering and remembering information about your preferences. We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes. Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>
          <p className="fs-6 mb-0"><b>Links to other websites</b></p>
          <p className="fs-6">Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
          <p className="fs-6 mb-0"><b>Controlling your personal information</b></p>
          <ol>
            <li><p className="mb-0">We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.</p></li>
            <li><p>We may use your personal information to send you promotional information about events, seasons, artists, organisers, videos and other content on our website from third parties which we think you may find interesting.</p></li>
          </ol>
          <p className="fs-6">If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
          <p className="fs-6 mb-0"><b>Contacting Us</b></p>
          <p className="fs-6 mb-0">If there are any questions regarding this privacy policy you may contact us using the information below:</p>
          <ul>
            <li><p className="mb-0">MDnD Entertainment Private Limited,</p></li>
            <li><p className="mb-0">No.149/2 (Second Floor)</p></li>
            <li><p className="mb-0">Luz Church Road, Mylapore,</p></li>
            <li><p className="mb-0">Chennai – 600004, Tamil Nadu, India</p></li>
            <li><p className="mb-0"><a style={{color:"#3f00ff"}} href='events@mdnd.in'>events@mdnd.in</a></p></li>
          </ul>
        </div>
      </div>

    </div>
  )
}

export default PrivacyPolicy