import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./artist.css";
import { makeGetRequest } from "../../Api/requestMethods";
import { ARTIST_CATEGORIES } from "../../Api/api";
import { ERROR, DURATION } from "../../global/ToastMsgConstants.tsx";
import { MAIN_CATEGORY_IMAGES } from "../../global/constants.js";
import { isValidImage, imageExists } from "../../global/ImageCheck.js";
import Noimage from "../../global/NoImage.js";

import notify from "devextreme/ui/notify";
import { generateAlphabetCharacters } from "../../utils/commonUtils.js";

const Artist = () => {
  const navigate = useNavigate();
  const handleClick = (artistName) => {
    const genreName = artistName && artistName.main_cate_name;

    if (genreName) {
      navigate(`/Creators`, {
        state: {
          mainCatId: artistName.main_cate_id,
          mainCatName: artistName.main_cate_name,
        },
      });
    }
  };

  const fetchArtist = async () => await makeGetRequest(ARTIST_CATEGORIES);
  const {
    data: ArtistCatDataSource,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["artistCategory"],
    queryFn: () => fetchArtist(),
    onError: () => {
      notify(error.message, ERROR, DURATION);
    },
  });

  const [ArtistArray, setArtistArray] = useState([]);

  useEffect(() => {
    if (ArtistCatDataSource) {
      setArtistArray(ArtistCatDataSource.data);
    } else {
      setArtistArray([]);
    }
  }, [ArtistCatDataSource]);

  //console.log(ArtistArray,"ArtistArray");

  return (
    <>
      <div className="iq-breadcrumb artBanner organBanner">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb" className="text-center">
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  Artists
                </h2>
                <h2 className="mt-4 mt-lg-0 header-title text-white">
                  All artist profiles will be up in a few days. Thank you for
                  your patience.
                </h2>
              </nav>
            </div>
          </div>
        </div>
      </div>

 {/*comment from here*/}

    {/* <section className={`pt-lg-3`}>
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between mb-4"></div>
          <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
            {isLoading
              ? Array.from({ length: 10 }).map((_, index) => (
                  <div className="col mb-2 mb-lg-4 px-2" key={index}>
                    <div className="">
                      <div className="block-images position-relative w-100">
                        <div className="img-box rounded position-relative">
                          <Skeleton height={200} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ArtistArray.map((item, index) => (
                  <div className="col mb-2 mb-lg-4 px-2" key={index}>
                    <div
                      className="iq-card-geners card-hover-style-two"
                      onClick={() => handleClick(item)}
                    >
                      <div className="block-images position-relative w-100">
                        <div className="img-box rounded position-relative">
                        {  isValidImage(item?.main_cate_img) && imageExists(`${MAIN_CATEGORY_IMAGES}${item?.main_cate_img}`) ?    
              (<img
                src={`${MAIN_CATEGORY_IMAGES}${item?.main_cate_img}`}
                
                alt="genre-img"
                className="img-fluid object-cover w-100 rounded"
                loading="lazy"
              />):(
                 <Noimage/>
              )}
                          <div className="blog-description">
                            <h5
                              className="mb-0 text-white"
                              style={{ fontWeight: "400", marginTop: "4px" }}
                            >
                              {item?.main_cate_name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>     */}


      {/*end comment here today*/}
    </>
  );
};

export default Artist;

