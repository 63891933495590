import React, { useEffect, useState, useCallback } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "../MDnDRecords.css";
import {
  format,
  formatDistanceToNow,
  parseISO,
  formatDistanceStrict,
} from "date-fns";
import { makeGetRequest } from "../../Api/requestMethods";
import { GET_PROFILE_FOR_RECORD, GET_THUMBNAITL_RECORDS } from "../../Api/api";
import notify from "devextreme/ui/notify";
import { useLocation } from "react-router-dom";
import profile from "../Static/user.png";
import Noimage from "../Static/NoImage.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const fetchProfileImage = async (roleId) => {
  const endPoint = `${GET_PROFILE_FOR_RECORD()}/${roleId}`;
  const response = await makeGetRequest(endPoint);
  return response.data.profile_image;
};

const ProfileImage = ({ roleId }) => {
  const {
    data: profileImage,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["profileImage", roleId],
    queryFn: () => fetchProfileImage(roleId),
    onError: (error) => {
      console.log(`Error occurred for roleId: ${roleId}`);
      console.error(error);
    },
  });

  if (isLoading) {
    return <Skeleton circle={true} height={40} width={40} />;
  }

  if (error) {
    return <div>Error loading profile image</div>;
  }

  return (
    <div className="videoProfileImg">
      <img
        src={
          profileImage && profileImage?.status !== 404
            ? `data:image/png;base64,${profileImage}`
            : profile
        }
        alt="Profile"
      />
    </div>
  );
};

const ContinueWatching = () => {
  const [mdndData, setMdndData] = useState([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const limit = 20;
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(() =>
    localStorage.getItem("MDnD_auth")
      ? jwtDecode(localStorage.getItem("MDnD_auth"))
      : null
  );

  const handleRecordClick = (data) => {
    const json_string = JSON.stringify({
      video_id: data?.id,
      role_id: user?.role_id ?? null,
      maincatid: data.main_category_id,
    });
    const slug = btoa(json_string);
    navigate(`/RecordsVideo/vimeo/${slug}`);
  };

  const main_cat_id = location.state.categoryId;

  const fetchVideos = async (limit, offset) => {
    const data = { limit, offset, main_cat_id };
    const jsonString = JSON.stringify(data);
    const base64String = btoa(jsonString);
    const GET_MDND_RECORDS = `videos/record/filter/${base64String}`;

    try {
      const response = await makeGetRequest(GET_MDND_RECORDS);
      const records = response.data;

      const recordsWithThumbnails = await Promise.all(
        records.map(async (record) => {
          const thumbnailResponse = await makeGetRequest(
            `${GET_THUMBNAITL_RECORDS()}/${record.id}`
          );
          return {
            ...record,
            thumbnail: thumbnailResponse?.data?.thumbnail,
          };
        })
      );

      return recordsWithThumbnails;
    } catch (error) {
      throw new Error(`Error fetching videos: ${error.message}`);
    }
  };

  const loadMoreVideos = useCallback(async () => {
    if (isFetching) return;

    setIsFetching(true);
    try {
      const newRecords = await fetchVideos(limit, currentOffset);
      setMdndData((prevData) => [...prevData, ...newRecords]);
      setCurrentOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      notify(error.message);
    } finally {
      setIsFetching(false);
    }
  }, [currentOffset, isFetching]);

  useEffect(() => {
    loadMoreVideos();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
          document.documentElement.scrollHeight &&
        !isFetching
      ) {
        loadMoreVideos();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreVideos, isFetching]);

  const formatTime = (dateString) => {
    const date = parseISO(dateString);
    const now = new Date();
    const diffInDays = (now - date) / (1000 * 60 * 60 * 24);

    if (diffInDays < 1) {
      return format(date, "p");
    } else if (diffInDays < 7) {
      return formatDistanceToNow(date, { addSuffix: true });
    } else if (diffInDays < 30) {
      return formatDistanceStrict(date, now, { unit: "day", addSuffix: true });
    } else if (diffInDays < 365) {
      return formatDistanceStrict(date, now, {
        unit: "month",
        addSuffix: true,
      });
    } else {
      return formatDistanceStrict(date, now, { unit: "year", addSuffix: true });
    }
  };

  return (
    <section className="continue-watching-block section-padding-top pt-lg-3">
      <div className="container-fluid">
        <div className="overflow-hidden">
          <div className="d-flex align-items-center justify-content-between px-1 mb-3 mb-lg-0">
            <h6 className="main-title text-capitalize"></h6>
          </div>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5">
            {mdndData && mdndData?.length > 0
              ? mdndData?.map((record, index) => (
                  <div className="iq-watching-block mb-lg-3" key={index}>
                    <div
                      className="block-images position-relative"
                      onClick={() => handleRecordClick(record)}
                    >
                      <div className="iq-image-box overly-images">
                        <img
                          src={
                            record?.thumbnail &&
                            record?.thumbnail.status !== 404
                              ? `data:image/png;base64,${record?.thumbnail}`
                              : Noimage
                          }
                          alt={record.video_tittle}
                          className="img-fluid object-cover w-100 d-block border-0"
                        />
                      </div>
                      <div className="iq-preogress">
                        <div className="d-flex justify-content-between px-2 mb-1">
                          <p className="mb-0 videoViewBg">
                            {record.views} views
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <ProfileImage roleId={record.role_id} />
                      <div className="w-100 ms-2">
                        <h6 className="mb-0">
                          <b>{record.video_title} </b>
                        </h6>
                        <p className="mb-0">
                          <b>{record.profile_name}</b>
                        </p>
                        <p className="mb-0 videoMinuts">
                          {formatTime(record.created_at)}
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              : Array.from({ length: 10 }).map((_, index) => (
                  <div className="iq-watching-block mb-lg-3" key={index}>
                    <Skeleton height={220} />
                    <div className="d-flex justify-content-between p-2">
                      <Skeleton circle={true} height={50} width={50} />
                      <div className="w-100 ms-2">
                        <Skeleton count={3} />
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContinueWatching;
