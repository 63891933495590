import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { Helmet } from "react-helmet";

const ShareModal = ({
  show,
  handleClose,
  currentUrl,
  handleCopy,
  inputRef,
  mlogo,
  postdescr,
  postimage,
}) => {
  // useEffect(() => {
  //   const ogUrl = document.querySelector('meta[property="og:url"]');
  //   const ogType = document.querySelector('meta[property="og:type"]');
  //   const ogTitle = document.querySelector('meta[property="og:title"]');
  //   const ogDescription = document.querySelector(
  //     'meta[property="og:description"]'
  //   );
  //   const ogImage = document.querySelector('meta[property="og:image"]');

  //   if (ogUrl) ogUrl.setAttribute("content", currentUrl);
  //   if (ogType) ogType.setAttribute("content", "article");
  //   if (ogTitle) ogTitle.setAttribute("content", postdescr);
  //   if (ogDescription) ogDescription.setAttribute("content", postdescr);
  //   if (ogImage && postimage) ogImage.setAttribute("content", postimage);
  // }, [currentUrl, postdescr, postimage]);

  const handleOpenWhatsApp = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const whatsappUrl = isMobile
      ? `whatsapp://send?text=${encodeURIComponent(
          postdescr + " " + currentUrl
        )}`
      : `https://web.whatsapp.com/send?text=${encodeURIComponent(
          postdescr + " " + currentUrl
        )}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleOpenInstagram = () => {
    window.open("https://www.instagram.com/", "_blank");
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      {/* <Helmet>
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={postdescr} />
        <meta property="og:description" content={postdescr} />
        {postimage && <meta property="og:image" content={postimage} />}
      </Helmet> */}
      <Modal.Header
                        closeButton
                        className="m-0 d-flex align-items-center text-center border-light"
                        style={{ padding: "10px 10px" }}
                      >
                        <h5 className="text-nowrap text-center m-auto">
                          Share
                        </h5>
                      </Modal.Header>
      <Modal.Body className="px-2 pb-4">
        <div className="d-flex justify-content-around gap-3">
          <FacebookShareButton url={currentUrl} quote={postdescr}>
            <div className="share-icon facebook">
              <i className="fa-brands fa-facebook" aria-hidden="true" />
            </div>
          </FacebookShareButton>
          <TwitterShareButton url={currentUrl} title={postdescr}>
            <div className="share-icon twitter">
              <i className="fa-brands fa-x-twitter" />
            </div>
          </TwitterShareButton>

          <div className="share-icon whatsapp" onClick={handleOpenWhatsApp}>
            <i
              className="fa-brands fa-whatsapp"
              aria-hidden="true"
              style={{
                fontSize: "32px",
                cursor: "pointer",
                borderRadius: "50%",
              }}
            ></i>
          </div>

          <div className="share-icon instagram" onClick={handleOpenInstagram}>
            <i
              className="fa-brands fa-instagram"
              aria-hidden="true"
              style={{
                fontSize: "32px",
                cursor: "pointer",
                borderRadius: "50%",
              }}
            ></i>
          </div>
        </div>

        <div className="copy-link-container">
          <div className="copy-header"></div>
          <div className="copy-link">
            <div className="copy-link-inner">
              <form onSubmit={handleCopy} className="d-flex gap-3">
                <input
                  type="text"
                  value={currentUrl}
                  className="w-100 overflow-hidden "
                  ref={inputRef}
                  onClick={() => inputRef.current.select()}
                  readOnly
                />
                <input type="submit" className="button" value="Copy" />
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModal;
