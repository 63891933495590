import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import Creategrid from './Creategrid';
import ShareComponent from "./ShareComponent";
import Gamecomponent from './Gamecomponent';
import HowToPlay from './HowToPlay';
import { Helmet } from "react-helmet";
import './Grid.css';
import Confetti from "react-confetti";

const Playbyraganame = () => {
  const [solution, setSolution] = useState(''); // Solution fetched from the backend
  const [arohanam,setArohanam] = useState('');
  const [avarohanam,setAvarohanam] = useState('');
  const [currentRow, setCurrentRow] = useState(0);
  const [gridColors, setGridColors] = useState(Array.from({ length: 5 }, () => Array(0).fill(''))); 
  const [keyboardColors, setKeyboardColors] = useState({}); // Keyboard key colors
  const [resultMessage, setResultMessage] = useState('');
  const [wordLength, setWordLength] = useState(0); // Set dynamically based on the solution
  const [isGameOver, setGameOver] = useState(false);
  const [showHowTo, setShowHowTo] = useState(false);
  const navigate = useNavigate(); // Hook to navigate to different routes
  
  const getTodayDateString = () => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  };

  // Load game state from localStorage on component mount
  useEffect(() => {
    const savedDate = localStorage.getItem("ragaRiddleLastPlayedDate");
    const todayDate = getTodayDateString();

    // If it's a new day or no date is saved, clear previous game state
    if (savedDate !== todayDate) {
      localStorage.removeItem("ragaRiddleGameState");
      
    } else {
      // Load saved game state if it's the same day
      const savedState = JSON.parse(localStorage.getItem("ragaRiddleGameState"));
      if (savedState) {
        setCurrentRow(savedState.currentRow);
        setGridColors(savedState.gridColors);
        setKeyboardColors(savedState.keyboardColors);
        setResultMessage(savedState.resultMessage);
        setWordLength(savedState.wordLength);
        setGameOver(savedState.isGameOver);
      }
    }
  }, []);

  // Save game state to localStorage whenever it changes
  useEffect(() => {
    const gameState = {
      currentRow,
      gridColors,
      keyboardColors,
      resultMessage,
      wordLength,
      isGameOver
    };
    localStorage.setItem("ragaRiddleGameState", JSON.stringify(gameState));
  }, [currentRow, gridColors, keyboardColors, resultMessage, wordLength, isGameOver]);

  // Callback function to handle data fetched from Gamecomponent
  const handleDataFetch = (data) => {
    setSolution(data.raga); // Set the fetched raga (solution) in the state
    setArohanam(data.arohanam);
    setAvarohanam(data.avarohanam);
    setWordLength(data.raga.length); // Set the word length dynamically
   // console.log(data);
  };

  // Function to handle guess submission
  
  const handleGuessSubmit = (guess, rowIndex) => {
    let newGridColors = [...gridColors];
    let newKeyboardColors = { ...keyboardColors };
    const guessArray = guess.join('');

    if (guessArray === solution) {
        // If the guess is correct, color all green
        newGridColors[rowIndex] = guess.map(() => 'green');
        guess.forEach((letter) => {
            newKeyboardColors[letter] = 'green'; // Mark key as green
        });
        setGridColors(newGridColors);
        setResultMessage(`<h3>Congratulations!</h3> <h5> You've guessed correctly!</h5><h5><br>Raga:${solution}<br> Arohanam : ${arohanam} <br> Avarohanam : ${avarohanam}</h5>`);
        setGameOver(true);
    } else {
        const solutionArray = solution.split('');
        const guessColor = Array(wordLength).fill('grey'); // Initialize all as grey

        // First pass: Check for correct positions (green)
        guess.forEach((letter, index) => {
            if (letter === solutionArray[index]) {
                guessColor[index] = 'green';
                solutionArray[index] = null; // Remove matched letter from solution
            }
        });

        // Second pass: Check for letters in wrong positions (yellow)
        guess.forEach((letter, index) => {
            if (guessColor[index] !== 'green' && solutionArray.includes(letter)) {
                guessColor[index] = 'blue';
                solutionArray[solutionArray.indexOf(letter)] = null; // Remove matched letter
            }
        });

        // Update grid colors
        newGridColors[rowIndex] = guessColor;

        // Update keyboard colors
        guess.forEach((letter, index) => {
            // Set key color based on current guess status
            if (guessColor[index] === 'green') {
                newKeyboardColors[letter] = 'green'; // If the letter is green, mark it as such
            } else if (guessColor[index] === 'blue') {
                if (newKeyboardColors[letter] !== 'green') { // Only change to yellow if not already green
                    newKeyboardColors[letter] = 'blue'; // Set to yellow if the letter is not already green
                }
            } else {
                if (newKeyboardColors[letter] !== 'green' && newKeyboardColors[letter] !== 'blue') {
                    newKeyboardColors[letter] = 'grey'; // Set to grey if it's not already green or yellow
                }
            }
        });

        setGridColors(newGridColors);
        setKeyboardColors(newKeyboardColors);

        // Check if the game is lost
        if (rowIndex === 4) {
            setGameOver(true);
            setResultMessage(`<h3>Game Over!</h3><h5>The correct raga is ${solution}.<br> Arohanam : ${arohanam} <br> Avarohanam : ${avarohanam}</h5> `);
        }
    }
};



  // Handle Play Swara Mode (navigate to another route)
  const handlePlaySwara = () => {
    navigate('/ragariddle/playbyswara'); // Navigate to PlaybySwara page
  };

  // Handle closing the result message
  const handleCloseResultMessage = () => {
   // setResultMessage(''); // Clear the result message when close button is pressed
   document.getElementsByClassName("feedback-message-container")[0].style.display = 'none';
  };

  
  const generateShareableMessage = () => {
    let msg1 = "Checkout RagaRiddle - A fun puzzle\n";  // Initial message
    const greenEmoji = "🟩";  // Correct position
    //const yellowEmoji = "🟨"; // Close, but wrong position
    const blueEmoji = '\u{1F7E6}';
    const grayEmoji = "⬜";   // Incorrect letter

    for (let i = 0; i < gridColors.length; i++) {
      let row = "";  // Initialize a row string
      for (let j = 0; j < wordLength; j++) {
        // Assuming gridColors holds the state of each cell for each row
        const cellColor = gridColors[i][j];

        // Map the cell state to the corresponding emoji
        if (cellColor === "green") {
          row += greenEmoji;
        } else if (cellColor === "blue") {
          row += blueEmoji;
        } else {
          row += grayEmoji;
        }
      }
      msg1 += row + '\n';  // Add the row to the message with a new line
    }
    
    return msg1;
  };
  const toggleHowTo = () => {
    setShowHowTo(!showHowTo);
  };
  // const handleRagaButtonClick = async () => {
   
  //    navigate('/Ragabank');
  // };
  return (
    <>
    <Helmet>
    <title>RagaRiddle - Play by Raga Name</title>
    <meta
          name="description"
          content="RagaRiddle - Play by Raga Name mode - Guess today's raga name in 5 chances.Start by filling in the blanks and guess the raga name based on resultant colors of your guess."
        />
    </Helmet>
    <div className="RagaRiddle container-fluid">
      <h1>RagaRiddle</h1>
      <h6 className="mb-4 ">Play by Raga name</h6>
      {/* {!showHowTo && (
        <div className="">
          <button className="play-riddle-button howToPlay rounded" onClick={toggleHowTo}>
            How to Play
          </button>
        </div>
      )} */}

      {/* Show How to Play Instructions */}
      {showHowTo && <HowToPlay onClose={toggleHowTo} />}
      {/* Fetch the solution using the Gamecomponent */}
  <Gamecomponent gameType="raga" onDataFetch={handleDataFetch} />

  {/* Render the game grid once the solution is fetched */}
  {wordLength > 0 && (
    <Creategrid
      wordLength={wordLength}
      currentRow={currentRow}
      gridColors={gridColors}
      isGameOver={isGameOver}
      onGuessSubmit={handleGuessSubmit}
      setCurrentRow={setCurrentRow}
      keyboardColors={keyboardColors}
      className="Creategrid"
    />
  )}

  {/* Display the result message in a container if the game is over */}
 
      {resultMessage.includes('Congratulation') && 
    <Confetti className='confetti-class' />}
     {resultMessage && (
    <>
      <div className="feedback-message-container text-center ">
        {/* Close button on the top right */}
        <button className="close-button" onClick={handleCloseResultMessage}>
          &times;
        </button>
        <div className="feedback-message text-center px-2"  dangerouslySetInnerHTML={{ __html: resultMessage }} />

        {/* Always show the Play Swara button */}
        <div className='d-flex justify-content-center'>
        <button className="button  mt-4" onClick={handlePlaySwara}>
          Play by Swara
        </button>
        </div>
        {/* <div className='d-flex justify-content-center'>
          <button className="button  mt-4" onClick={handleRagaButtonClick}>Listen to songs in this raga</button>
        </div> */}
      
      <div className='my-3 '>
      {/* <p className='text-decoration-underline'>Share</p> */}
          <ShareComponent shareMessage={generateShareableMessage()}></ShareComponent>
          </div>
        
          </div>
      
    
    </>
  )}
  <div className="share-icons justify-content-center">
  {!showHowTo && (
  <button style={{color:"#3f00ff"}} className="mt-3 rounded  d-flex  justify-content-center" onClick={toggleHowTo}>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
    
<path  d="M7.879 5.519C9.05 4.494 10.95 4.494 12.121 5.519C13.293 6.544 13.293 8.206 12.121 9.231C11.918 9.41 11.691 9.557 11.451 9.673C10.706 10.034 10.001 10.672 10.001 11.5V12.25M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 15.25H10.008V15.258H10V15.25Z" 
stroke="#3f00ff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  </button>
          )}
</div>
</div>
</>
  );
}

export default Playbyraganame;
