import React from "react";
import { useState, useEffect, useContext } from "react";
import { BASEPATH } from "../../../config/config";
import AuthContext from "../../../context/AuthContext";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GET_USERPROFILE, COUNTRIES_FROM_BACKEND, STATES_FROM_BACKEND, CITIES_FROM_BACKEND, EDIT_YOUR_PROFILE } from "../../../Api/api";
import { SelectBox, TextBox } from "devextreme-react";
import PhoneInput from "react-phone-input-2";
import SuccessNewToaster from "../../../Toaster/Success/SuccessNewToaster";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
export default function Edit_myprofile({ onProfileUpdateSuccess }) {

    const { user, setUser, authTokens, setAuthTokens, logoutUser } = useContext(AuthContext);
    const token = localStorage.getItem("MDnD_auth");
    const queryClient = useQueryClient();
    const [profileCollection, setprofileCollection] = useState({
        finalData: "",
        country: "",
        selectedCountry: "",
        state: "",
        selectedState: "",
        city: "",
        selectedCity: "",
        mobile: "",
        countryCode: "",
        mobile_to_backend: "",

        empty_validation_name: "",
        empty_validation_mobile: "",
    });
    const [checkEditing, setcheckEditing] = useState(false);
    const [counter, setCounter] = useState(0);
    const [loading, setLoading] = useState(true);


    //fetching the user's profile

    useEffect(() => {
        if (user) { mutationgetProfile.mutate(); }


    }, [counter])

    const GetProfile = () =>
        GET_PROFILE_FROM_BACKEND(GET_USERPROFILE);

    const GET_PROFILE_FROM_BACKEND = async (
        GET_USERPROFILE) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${GET_USERPROFILE}`,

                data: {
                    roleid: user ? user.role_id : "",

                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',
            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationgetProfile = useMutation({
        mutationKey: ["get_the_profile"],
        mutationFn: () => GetProfile(),

        onSuccess: (data) => {


            if (data.status === "success") {

                setprofileCollection((prevState) => ({
                    ...prevState,
                    finalData: data.data[0]

                }));

            }
            else {
                setprofileCollection((prevState) => ({
                    ...prevState,
                    finalData: data.data

                }));
            }
            setLoading(false); // Set loading to false on success
        },
        onError: (error) => {

            //alert("Did not post profile");
            //handleCloseafterSubmit();
            setprofileCollection((prevState) => ({
                ...prevState,
                finalData: ""

            }));
            setLoading(false); // Set loading to false on error
        },
        onMutate: () => {
            setLoading(true); // Set loading to true when mutation starts
        },
    });

    //console.log(profileCollection, "PC");

    //setting initial states...

    useEffect(() => {
        if (profileCollection.finalData) {
            setprofileCollection((prevState) => ({
                ...prevState,
                selectedCountry: profileCollection.finalData.countryid ? profileCollection.finalData.countryid : "",
                selectedState: profileCollection.finalData.stateid ? profileCollection.finalData.stateid : "",
                selectedCity: profileCollection.finalData.cityid ? profileCollection.finalData.cityid : "",
                mobile: profileCollection.finalData.countrycode && profileCollection.finalData.mobile
                    ? `${profileCollection.finalData.countrycode}-${profileCollection.finalData.mobile}`
                    : profileCollection.finalData.mobile || "",

                name: profileCollection.finalData.name ? profileCollection.finalData.name : "",
                mobile_to_backend: profileCollection.finalData.mobile ? profileCollection.finalData.mobile : "",
                countrycode_to_backend: profileCollection.finalData.countrycode ? profileCollection.finalData.countrycode : "",

            }));
        }
    }, [profileCollection.finalData])

    //country, state , city................................

    useEffect(() => {
        mutationCountry.mutate();
    }, [])

    const GetCountries = () =>
        GET_COUNTRY(COUNTRIES_FROM_BACKEND);

    const GET_COUNTRY = async (
        COUNTRIES_FROM_BACKEND) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${BASEPATH}${COUNTRIES_FROM_BACKEND}`,
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',

            });

            return response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const mutationCountry = useMutation({
        mutationKey: ["get_Country"],
        mutationFn: () => GetCountries(),

        onSuccess: (data) => {


            if (data.status === "success") {

                setprofileCollection((prevState) => ({ ...prevState, country: data.data, }));

            }
            else {
                setprofileCollection((prevState) => ({ ...prevState, country: "", }));
            }
        },
        onError: (error) => {
            setprofileCollection((prevState) => ({ ...prevState, country: "", }));

        },
    });


    //fetch the states..
    useEffect(() => {
        const GETSTATESDATA = async () => {
            try {
                const response = await axios.post(
                    `${BASEPATH}${STATES_FROM_BACKEND}`,
                    {
                        country_id: profileCollection.selectedCountry,
                    },
                    {
                        headers: {
                            'MDnD_auth': `${token}`,

                        },

                        timeout: 60000,
                        responseType: 'json',
                    },
                );


                if (response.data.status == "success") {

                    setprofileCollection((prevState) => ({ ...prevState, state: response.data.data, }));
                }
                else {
                    setprofileCollection((prevState) => ({ ...prevState, state: "", }));
                }



            }

            catch (error) {
                console.error("error fetching States for this country", error);
            }
        }


        //mutationGetStates.mutate();
        if (profileCollection.selectedCountry) { GETSTATESDATA(); }
    }, [profileCollection.selectedCountry])

    //fetch the cities..
    useEffect(() => {
        const GETCITIESDATA = async () => {
            try {
                const response = await axios.post(
                    `${BASEPATH}${CITIES_FROM_BACKEND}`,
                    {
                        state_id: profileCollection.selectedState,
                    }, {
                    headers: {
                        'MDnD_auth': `${token}`,

                    },

                    timeout: 60000,
                    responseType: 'json',
                },
                );


                if (response.data.status == "success") {

                    setprofileCollection((prevState) => ({ ...prevState, city: response.data.data, }));
                }
                else {
                    setprofileCollection((prevState) => ({ ...prevState, city: "", }));
                }



            }

            catch (error) {
                console.error("error fetching States for this country", error);
            }
        }
        if (profileCollection.selectedState) { GETCITIESDATA(); }
    }, [profileCollection.selectedState])


    //onValuechanged functions...

    const handleCountry = (e) => {

        //console.log("im inside");
        if (e.value) {
            setprofileCollection((prevState) => ({ ...prevState, selectedCountry: e.value }));
            setprofileCollection((prevState) => ({ ...prevState, city: [] }));
            setprofileCollection((prevState) => ({ ...prevState, selectedState: "", selectedCity: "", }));


        }
    }

    const handleState = (value) => {

        if (value) {
            setprofileCollection((prevState) => ({ ...prevState, selectedState: value }));


        }
    }


    const handleCity = (value) => {


        if (value) {
            setprofileCollection((prevState) => ({ ...prevState, selectedCity: value }));

        }

    }

    const handleAdvertiserMobile = (phoneNumber, countryData) => {
        const countryCode = `${countryData.dialCode}`;
        const mobile = phoneNumber.startsWith(countryData.dialCode)
            ? phoneNumber.slice(countryData.dialCode.length)
            : phoneNumber;

        setprofileCollection((prevState) => ({
            ...prevState,
            mobile: `${countryCode}-${mobile}`,
            countrycode_to_backend: countryCode,
            mobile_to_backend: `${mobile}`,
        }));

        if (mobile && mobile.length > 0) {
            setprofileCollection((prev) => ({ ...prev, empty_validation_mobile: "" }));
        }

    }

    const handleName = (e) => {
        if (e) {
            setprofileCollection((prevState) => ({
                ...prevState,
                name: e.value,
            }));
        }

        if (e?.value.length > 0) {
            setprofileCollection((prev) => ({ ...prev, empty_validation_name: "" }));
        }
    }

    const handleEdit = () => {
        setcheckEditing(true);
    }

    const handleReset = () => {
        setcheckEditing(false);
    }



    const handleSubmit_update = () => {
        if (profileCollection.name === "") {
            setprofileCollection((prevState) => ({
                ...prevState,
                empty_validation_name: "This field is required!",
            }));
            return;
        }
        else if (profileCollection.mobile_to_backend === "") {
            setprofileCollection((prevState) => ({
                ...prevState,
                empty_validation_mobile: "This field is required!",
            }));
            return;
        }
        setprofileCollection((prevState) => ({
            ...prevState,
            empty_validation_mobile: "",
            empty_validation_name: "",

        }));

        mutationEditProfile.mutate();

    }

    const PostEditProfile = () =>
        EDITPROFILE(EDIT_YOUR_PROFILE);

    const mutationEditProfile = useMutation({
        mutationKey: ["edit_profile_backend"],
        mutationFn: () => PostEditProfile(),

        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: ["fetchUser"],
            });


            if (data.status === "success") {
                SuccessNewToaster("success", "Succesfully Updated Your Profile! ", 3000);

                setCounter(counter + 1);
                setcheckEditing(false);
                onProfileUpdateSuccess();
            }
        },
        onError: (error) => {

            HandleErrorToast("error", "Could Not Update Profile. Something went Wrong!", 3000);
        },
    });

    const EDITPROFILE = async (
        EDIT_YOUR_PROFILE) => {
        try {
            const response = await axios({
                method: "POST",
                url: `${BASEPATH}${EDIT_YOUR_PROFILE}`,

                data: {
                    name: profileCollection.name ? profileCollection.name : "",
                    country_id: profileCollection.selectedCountry
                        ? profileCollection.selectedCountry
                        : "",
                    state_id: profileCollection.selectedState
                        ? profileCollection.selectedState
                        : "",
                    city_id: profileCollection.selectedCity
                        ? profileCollection.selectedCity
                        : "",
                    country_code: profileCollection.countrycode_to_backend
                        ? profileCollection.countrycode_to_backend : "",
                    mobile: profileCollection.mobile_to_backend
                        ? profileCollection.mobile_to_backend : "",
                    role_id: user?.role_id ? user.role_id : "",
                    user_id: profileCollection.finalData.id
                        ? profileCollection.finalData.id : "",


                },
                headers: {
                    'MDnD_auth': `${token}`,

                },

                timeout: 60000,
                responseType: 'json',
            });
            //console.log(response, "RESPONSE");
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <>
            <div>

                {/*{checkEditing ? (  */}
                <>
                    <div className="row">
                        <div className="col-md-6 col-xl-4 mb-3">
                            {loading ? (
                                <>
                                    <Skeleton height={40} />

                                </>
                            ) : (
                                <TextBox
                                    type="text"
                                    label="Edit Name"
                                    labelMode="floating"
                                    onValueChanged={handleName}
                                    value={profileCollection.name}
                                ></TextBox>
                            )}

                            {
                                profileCollection.empty_validation_name && <span style={{ color: 'red' }} >{profileCollection.empty_validation_name}</span>
                            }
                        </div>

                        <div className="col-md-6 col-xl-4 mb-3">
                            {loading ? (
                                <>
                                    <Skeleton height={40} />

                                </>
                            ) : (
                                <SelectBox
                                    dataSource={profileCollection.country}
                                    displayExpr="country_name"
                                    valueExpr="country_id"
                                    label="Select Country"
                                    searchEnabled={true}
                                    onValueChanged={handleCountry}
                                    labelMode="floating"
                                    value={profileCollection.selectedCountry}
                                //ref={element => inputRefs.current[key] = element}
                                />
                            )}

                        </div>
                        <div className="col-md-6 col-xl-4 mb-3">
                            {loading ? (
                                <>
                                    <Skeleton height={40} />

                                </>
                            ) : (
                                <SelectBox
                                    dataSource={profileCollection.state || []}
                                    label="Select State"
                                    displayExpr="state_name"
                                    valueExpr="state_id"
                                    labelMode="floating"
                                    searchEnabled={true}
                                    onValueChanged={(e) => handleState(e.value)}
                                    value={profileCollection.selectedState}
                                //ref={element => inputRefs.current[key] = element}
                                />
                            )}
                        </div>
                        <div className="col-md-6 col-xl-4 mb-3">
                        {loading ? (
                                <>
                                    <Skeleton height={40} />

                                </>
                            ) : (
                                <SelectBox
                                dataSource={profileCollection.city || []}
                                label="Select City"
                                labelMode="floating"
                                displayExpr="city_name"
                                valueExpr="city_id"
                                searchEnabled={true}
                                onValueChanged={(e) => handleCity(e.value)}
                                value={profileCollection.selectedCity}
                            //ref={element => inputRefs.current[key] = element}
                            />
                            )}
                            
                        </div>

                        <div className="col-md-6 col-xl-4 mt-2">
                        {loading ? (
                                <>
                                    <Skeleton height={40} />

                                </>
                            ) : (
                                <PhoneInput
                                //defaultCountry="IN"
                                readOnly={true}
                                placeholder="Phone number"
                                value={profileCollection.mobile}
                                name="Mobile"
                                onChange={(phoneNumber, countryData) => handleAdvertiserMobile(phoneNumber, countryData)}
                                //   inputProps={{
                                //     readOnly: true,
                                //   }}

                                countryCodeEditable={false}
                                enableSearch={true}
                                className="dx-texteditor-input phoneFieldBox phoneNumberFieldPadForArtist"
                                areaCodes={true}
                            />
                            )}
                            
                            {
                                profileCollection.empty_validation_mobile && <span style={{ color: 'red' }} >{profileCollection.empty_validation_mobile}</span>
                            }
                        </div>

                        <div className="d-flex col-12">
                            <button className="button mt-3 px-5" onClick={handleSubmit_update}>Update</button>
                            {/* <button className="button ms-3 px-5" onClick={handleReset}>Reset</button> */}
                        </div>
                    </div>
                </>
                {/*          ) 
                        : 
                (
                   
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-2" style={{padding: '8px 8px'}}>
                         
                       <label>Name : {profileCollection.finalData.name || ""}</label> <br></br>
                    <label>Country : {profileCollection.finalData.countryname || ""}</label><br></br>
                      <label>State : {profileCollection.finalData.statename || ""} </label><br></br>
                    <label>City : {profileCollection.finalData.cityname || ""}</label><br></br>
                        <label>Mobile: {profileCollection.finalData.mobile || ""}</label><br></br>
                 </div>
                 <div>
                      <button className="button ms-3 px-5" onClick={handleEdit}>Edit</button>
                       </div>
                        
                         </div>
                     
                 )}    */}
            </div>

        </>
    )

}