const FORMATED_DATE_AND_TIME = (newDate) => {
  const date = new Date(newDate);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const formattedTime = `${hours}:${minutes} ${ampm}`;
  const formatedDateAndTime = {
    formattedDate,
    formattedTime,
  };
  return formatedDateAndTime;
};

// dd-mm-yy
const DATE_MONTH_YEAR = (date) => {
  try {
    if (!(date instanceof Date) || isNaN(date)) {
      console.log("Invalid date provided");
    }
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  } catch (error) {
    console.error("Error occurred while formatting date:", error.message);
    return null;
  }
};

// Thu Jun 20 2024 00:00:00 GMT+0530 (India Standard Time) to 2024-06-10 this format
function yearMonthAndDay(dateString) {
  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

// Thu Jun 20 2024 00:00:00 GMT+0530 (India Standard Time) to 10-06-2024 this format
function convertDayMonthYear(dateString) {
  const year = dateString.getFullYear();
  const month = String(dateString.getMonth() + 1).padStart(2, "0");
  const day = String(dateString.getDate()).padStart(2, "0");
  return {
    fromDate: `${day}-${month}-${year}`,
    toDate: `${day}-${month}-${year}`,
  };
}

const convertDayMonthYearArray = (value) => {
  const year = value.getFullYear();
  const month = String(value.getMonth() + 1).padStart(2, "0");
  const day = String(value.getDate()).padStart(2, "0");
  return `${day}${month}${year}`;
};

//  "2024-06-04T18:30:00.000Z" - this format saturday 19 May
function dayStringMonth(dateString) {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dateObj = new Date(dateString);
  const dayOfWeek = daysOfWeek[dateObj.getDay()];
  const day = dateObj.getDate();
  const month = monthsOfYear[dateObj.getMonth()];

  return `${dayOfWeek}, ${day} ${month}`;
}

//" 2024-06-27 this format to Wednesday, 6th March 2024
function dayDayinNumberAndYear(dateString) {
  try {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  } catch (error) {}
}

// this "02:44:00" time to this format 2:44 am
function timeFormater(timeString) {
  try {
    const [hours, minutes, seconds] = timeString.split(":");
    const formattedHours = parseInt(hours) % 12 || 12;
    const amPm = parseInt(hours) < 12 ? "AM" : "PM";
    const formattedMinutes = minutes.padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  } catch (error) {}
}

//2024-06-17T18:30:00.000Z format to 17-06-2024
function convertDateFormatDDMMYYYY(isoDate) {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return {
    fromDate: `${day}-${month}-${year}`,
    toDate: `${day}-${month}-${year}`,
  };
}

// This function will return the weekend dates from current date
const getWeekendDates = (date = new Date()) => {
  const day = date.getDay();
  const fromDate = new Date(date);
  let toDate = new Date(date);

  // Calculate the "from" and "to" dates based on the current day
  switch (day) {
    case 5: // Friday
      toDate.setDate(date.getDate() + 2); // Sunday
      break;
    case 6: // Saturday
      toDate.setDate(date.getDate() + 1); // Sunday
      break;
    case 0: // Sunday
      toDate = fromDate; // Sunday
      break;
    default: // Monday to Thursday
      const daysToFriday = 5 - day; // Days to next Friday
      fromDate.setDate(date.getDate() + daysToFriday);
      toDate.setDate(fromDate.getDate() + 2); // Sunday
      break;
  }

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return {
    fromDate: formatDate(fromDate),
    toDate: formatDate(toDate),
  };
};

export {
  convertDayMonthYearArray,
  getWeekendDates,
  convertDayMonthYear,
  convertDateFormatDDMMYYYY,
  FORMATED_DATE_AND_TIME,
  DATE_MONTH_YEAR,
  yearMonthAndDay,
  dayStringMonth,
  dayDayinNumberAndYear,
  timeFormater,
};
