import React, { useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CropModal = ({ show, handleClose, crop, setCrop, onImageLoad, imageBase64, completedCrop, setCompletedCrop, handleSaveCrop, imgRef }) => {

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Crop Your Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {imageBase64 && (
          <ReactCrop
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={16 / 9}  // Lock aspect ratio to 16:9
          >
            <img
              src={imageBase64}
              onLoad={onImageLoad}
              alt="Uploaded for cropping"
              style={{ width: '100%', height: 'auto' }}
              ref={imgRef}
            />
          </ReactCrop>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveCrop}>
          Save Crop
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CropModal;
