import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { DYNAMIC_PARTICULAR_LIST, GET_DETAILED_FORM_FROM_BACKEND } from "../../../Api/api";
import HandleErrorToast from "../../../Toaster/Failure/Failure";
import { useMutation } from "@tanstack/react-query";
import { BASEPATH } from "../../../config/config";
import axios from "axios";
import DataGrid, {
  Column,
  Export,
  Paging

} from 'devextreme-react/data-grid';

//new code
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import NoDataFound from "../../../global/NoDataFound";



export default function ListForms({ data }) {

  //console.log(data, "data inside");




  const [formidmain, setFormidmain] = useState(null);

  const [formtablename, setFormTableName] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formtableName = queryParams.get('tableName');
  const token = localStorage.getItem("MDnD_auth");

  //console.log(formtableName, "formtableName");

  const [finalList, setfinalList] = useState({
    data: [],
    formMode: "",
  })

  useEffect(() => {

    if (data) {
      setFormidmain(data?.form_id);

      setFormTableName(data?.table_name);
    }
  }, [data]);

  //console.log(formidmain, "formidmain in LIST");

  //code to find formMode- free or paid

  useEffect(() => {
    if (formidmain) {
      mutationgetFormMode.mutate(formidmain);
    }
    else {
      return;
    }

  }, [formidmain])

  const Getting_form_mode = (fid) =>
    GET_FORM_MODE(GET_DETAILED_FORM_FROM_BACKEND, fid);

  const GET_FORM_MODE = async (
    GET_DETAILED_FORM_FROM_BACKEND, fid) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${GET_DETAILED_FORM_FROM_BACKEND}`,
        data: {
          form_id: fid ? fid : ""
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetFormMode = useMutation({
    mutationKey: ["get_form_mode"],
    mutationFn: (fid) => Getting_form_mode(fid),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });


      //console.log(data, "DATA");

      if (data.status === "success") {


        setfinalList((prevState) => ({ ...prevState, formMode: data.data[0].form_mode }));
      }
      else {
        setfinalList((prevState) => ({ ...prevState, formMode: "" }));
      }
    },
    onError: (error) => {


      setfinalList((prevState) => ({ ...prevState, formMode: "" }));
    },
  });




  //end of code to find formMode

  //console.log(finalList, "finalList");

  useEffect(() => {
    if (formtablename && finalList.formMode) {
      mutationgetAllForms.mutate(formtablename);
    }
    else {
      return;
    }


  }, [formtablename, finalList.formMode])

  const Dynamic_List = (ftablename) =>
    GET_FORMS(DYNAMIC_PARTICULAR_LIST, ftablename);

  const GET_FORMS = async (
    DYNAMIC_PARTICULAR_LIST, ftablename) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${BASEPATH}${DYNAMIC_PARTICULAR_LIST}`,
        data: {
          form_tbl_name: ftablename ? ftablename : "",
          form_mode: finalList.formMode ? finalList.formMode : ""
        },
        headers: {
          'MDnD_auth': `${token}`,

        },

        timeout: 60000,
        responseType: 'json',

      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetAllForms = useMutation({
    mutationKey: ["get_all_dynamic_list"],
    mutationFn: (ftablename) => Dynamic_List(ftablename),

    onSuccess: (data) => {
      //   queryClient.invalidateQueries({
      //     queryKey: ["fetchUser"],
      //   });


      //console.log(data, "DATA");

      if (data.status === "success") {



        let filteredArray = [];
        for (let i = 0; i < data?.data?.length; i++) {
          filteredArray[i] = {};
          filteredArray[i]['created_date'] = formatDate(data.data[i]['created_date']);


          Object.keys(data?.data[i]).forEach(key => {
            if (key !== 'created_date') {
              filteredArray[i][key] = data.data[i][key];
            }
          });
        }
        //console.log(filteredArray, "filteredArray");

        if (filteredArray) {
          setfinalList((prevState) => ({ ...prevState, data: filteredArray }));
        }

      }
      else {
        setfinalList((prevState) => ({ ...prevState, data: "" }));
      }
    },
    onError: (error) => {

      //HandleErrorToast("error", "Form Fields Could not be Fetched. Something went Wrong!", 3000);
      setfinalList((prevState) => ({ ...prevState, data: "" }));
    },
  });


  //console.log(finalList,"finalList");


  // const createColumns = () => {
  //   if (!finalList || !finalList.data || finalList.data.length === 0) {
  //     console.error("finalList.data is not defined or empty");
  //     return [];
  //   }


  //   const excludedFields = ['id', 'updated_date', 'created_date'];


  //   const keys = Object.keys(finalList.data[0]).filter(key => !excludedFields.includes(key));


  //   const columns = [
  //     <Column width={80} key="sno" dataField="sno" caption="Sl.No" />,
  //     ...keys.map((key, index) => (
  //       <Column key={index} dataField={key} caption={key} />
  //     )),
  //   ];
  //   return columns;
  // };

  const createColumns = () => {
    if (!finalList || !finalList.data || finalList.data.length === 0) {
      console.error("finalList.data is not defined or empty");
      return [];
    }
  
    
    const excludedFields = ['id', 'updated_date', 'created_date'];
  
    const keys = Object.keys(finalList.data[0]).filter(key => !excludedFields.includes(key));
  
    const isISODateString = (value) => {
      
      return typeof value === 'string' && !isNaN(Date.parse(value));
    };
  
    const isImageFile = (value) => {
      
      return typeof value === 'string' && /\.(jpg|jpeg|png|gif|bmp|webp)$/.test(value.toLowerCase());
    };
    
    const isUrl = (text) => {
      try {
        new URL(text);
        return true;
      } catch (e) {
        return false;
      }
    }
  
    const columns = [
      <Column width={80} key="sno" dataField="sno" caption="Sl.No" />,
      ...keys.map((key, index) => (
        <Column
          key={index}
          dataField={key}
          caption={key}
          cellRender={({ value }) => {
            // Check if the value is a date
            if (isISODateString(value)) {
              const date = new Date(value);
              const formattedDate = date.toLocaleDateString("en-GB"); // dd-mm-yyyy format
              return <span>{formattedDate}</span>;
            }
  
          
            if (isImageFile(value)) {
              return (
                <img
                  src={`${BASEPATH}public/images/form_register/${value}`}
                  alt="Image"
                  style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
              );
            }
            if (isUrl(value)) {
              return (
                <a href={value} target="_blank" rel="noopener noreferrer">
                  {value}
                </a>
              );
            }
  
            
            return <span>{value}</span>;
          }}
        />
      )),
    ];
  
    return columns;
  };

  const addSerialNumbers = (data) => {
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return [];
    }

    return data.map((item, index) => ({
      ...item,
      sno: data.length - index,
    }));
  };

  const finalListWithSerialNumbers = {
    ...finalList,
    data: addSerialNumbers(finalList.data),
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };


  // new code..
  const onExporting1 = (e) => {

    //console.log(e, "event");
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: e?.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
      });
    });

  };

  


  return(
    <div className="container px-0">
   { finalListWithSerialNumbers?.data && finalListWithSerialNumbers?.data.length > 0 ? (
    <>
        <DataGrid
        dataSource={finalListWithSerialNumbers?.data}
        showBorders={true}
        columnAutoWidth={true}
        rowAlternationEnabled={true}
        //onToolbarPreparing={onToolbarPreparing}
        onExporting={onExporting1}
      //   export={{
      //     enabled: true,
      //     allowExportSelectedData: false, 
      // }}

        >
         
          {createColumns()}
          <Column
            dataField="created_date"
            caption="Created Date"
          
          />
          
          <Paging defaultPageSize={10} />
          <Export
            enabled={true}
            //allowExportSelectedData={true}
          />

        </DataGrid></>) : (<>
          <NoDataFound />
        </>)}
    </div>)
}