import React from "react";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";

import { GET_MY_VIDEOS, GET_THUMBNAIL_VIDEOS_IN_UPLOAD } from "../../Api/api";
import { BASEPATH } from "../../config/config";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { IMAGE_URL } from "../MainPage/constant";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../global/NoDataFound";
import Noimage from "../../global/NoImage";

export default function Videos_organiser(props) {
  let { roleid } = props;

  const token = localStorage.getItem("MDnD_auth");
  const navigate = useNavigate();

  const [thumbnail, setThumbnail] = useState({});
  const [profilephoto, setProfilephoto] = useState({});

  const [imageUrls, setImageUrls] = useState({});

  const [videoCollection, setVideoCollection] = useState({
    finalData: "",
  });

  useEffect(() => {
    if (roleid) {
      mutationgetMyVideos.mutate();
    }
  }, [roleid]);

  const GetallVideos = () => GET_VIDEOS_FROM_BACKEND(GET_MY_VIDEOS);

  const GET_VIDEOS_FROM_BACKEND = async (GET_MY_VIDEOS) => {
    let param1 = roleid ? Number(roleid) : "";
    try {
      const response = await axios({
        method: "GET",
        url: `${BASEPATH}${GET_MY_VIDEOS}/${param1}`,

        headers: {
          MDnD_auth: `${token}`,
        },

        timeout: 60000,
        responseType: "json",
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const mutationgetMyVideos = useMutation({
    mutationKey: ["get_All_MyVideos"],
    mutationFn: () => GetallVideos(),

    onSuccess: (data) => {
      if (data.status === "success") {
        setVideoCollection((prevState) => ({
          ...prevState,
          finalData: data.data,
        }));
      } else {
        setVideoCollection((prevState) => ({
          ...prevState,
          finalData: "",
        }));
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  // THUMBNAIL FOR VIDEOS..
  useEffect(() => {
    const fetchThumbnail = async (item, index) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${BASEPATH}${GET_THUMBNAIL_VIDEOS_IN_UPLOAD}/${item.id}`,
          headers: {
            MDnD_auth: `${token}`,
          },

          timeout: 60000,
          responseType: "json",
        });

        if (response.data.status === "success") {
          const base64Data = response.data.data.thumbnail;
          const dataURL = `data:image/jpeg;base64,${base64Data}`;

          const blob = await (await fetch(dataURL)).blob();

          const url = URL.createObjectURL(blob);

          if (url) {
            setThumbnail((prevThumbnail) => ({
              ...prevThumbnail,
              [index]: url,
            }));
          }
        } else {
          setThumbnail((prevThumbnail) => ({
            ...prevThumbnail,
            [index]: "",
          }));
        }
      } catch (error) {
        console.error("Thumbnail pictures cannot be fetched", error);

        setThumbnail((prevThumbnail) => ({
          ...prevThumbnail,
          [index]: "",
        }));
      }
    };

    if (videoCollection.finalData && videoCollection.finalData.length > 0) {
      videoCollection?.finalData?.forEach((item, index) => {
        fetchThumbnail(item, index);
      });
    }
  }, [videoCollection.finalData]);

  //to fetch profile picture..

  useEffect(() => {
    const fetchProfilePic = async (item, index) => {
      try {
        const response = await axios({
          method: "GET",
          url: `${BASEPATH}profiles/get_user_profile/${item.role_id}`,
          headers: {
            MDnD_auth: `${token}`,
          },

          timeout: 60000,
          responseType: "json",
        });

        if (response.data.status === "success") {
          const base64Data = response.data?.data?.profile_image;
          const dataURL = `data:image/jpeg;base64,${base64Data}`;

          const blob = await (await fetch(dataURL)).blob();

          const url = URL.createObjectURL(blob);

          if (url) {
            setProfilephoto((prev) => ({
              ...prev,
              [index]: url,
            }));
          }
        } else {
          setProfilephoto((prev) => ({
            ...prev,
            [index]: "",
          }));
        }
      } catch (error) {
        console.error("Thumbnail pictures cannot be fetched", error);

        setProfilephoto((prev) => ({
          ...prev,
          [index]: "",
        }));
      }
    };

    if (videoCollection.finalData && videoCollection.finalData.length > 0) {
      videoCollection?.finalData?.forEach((item, index) => {
        fetchProfilePic(item, index);
      });
    }
  }, [videoCollection.finalData]);

  const handleMDNDRecords = (item) => {
    const json_string = JSON.stringify({
      video_id: item?.id,
      role_id: item?.role_id ?? null,
      maincatid: item?.main_category_id,
    });
    const slug = btoa(json_string);
    navigate(`/RecordsVideo/videos/${slug}`);
  };

  function timeDifference(createdAt) {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);

    const diffMs = currentDate - createdDate; // Difference in milliseconds
    const diffSeconds = Math.floor(diffMs / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    if (diffSeconds < 60) {
      return `${diffSeconds} seconds ago`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minutes ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hours ago`;
    } else if (diffDays < 7) {
      return `${diffDays} days ago`;
    } else if (diffWeeks < 4) {
      return `${diffWeeks} weeks ago`;
    } else if (diffMonths < 12) {
      return `${diffMonths} months ago`;
    } else {
      return `${diffYears} years ago`;
    }
  }

  //new code but dont delete

  // const fetchImageWithHeaders = async (url) => {
  //     const response = await fetch(url, {
  //         //responseType: 'blob',
  //         headers: {
  //             'MDnD_auth': `${token}`,

  //         },
  //         timeout: 60000,
  //         responseType: 'json',
  //     });
  //     console.log(response,"response");
  //     const blob = new Blob([response.data]);
  //     return URL.createObjectURL(blob);
  // };

  // useEffect(() => {
  //     if (videoCollection.finalData) {
  //         const fetchImages = async () => {
  //             const urls = {};
  //             await Promise.all(
  //                 videoCollection.finalData.map(async (item, index) => {

  //                     const url = `${VideoRecords}/${item.thumbnail_url}`;
  //                     console.log(url,"url before fetching");
  //                     const blobUrl = await fetchImageWithHeaders(url);
  //                     if(blobUrl)
  //                     {urls[index] = blobUrl;}
  //                 })
  //             );
  //             setImageUrls(urls);
  //         };

  //         fetchImages();
  //     }
  // }, [videoCollection.finalData]);

    //console.log(videoCollection.finalData, "finaldata");

  const [ThumbnailImageError, setThumbnailImageError] = useState(false);

  const handleThumbnailImageError_myvideo = () => {
    setThumbnailImageError(true);
  };

  const [profileError, setprofileError] = useState(false);

  const handleprofileError_myvideo = () => {
    setprofileError(true);
  };

  return (
    <>
      <div className="overflow-hidden">
        {/* <div className="d-flex align-items-center justify-content-between my-2">
                    <h5 className="main-title text-capitalize mb-0">MDnD Records</h5>
                </div> */}

        {videoCollection.finalData && videoCollection.finalData.length > 0 ? (
          <>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
              {videoCollection?.finalData?.map((item, index) => (
                <div className="col" key={index}>
                  <div className="iq-watching-block mb-lg-3">
                    <div className="block-images position-relative">
                      <div className="iq-image-box overly-images">
                        {thumbnail[index] ? (
                          <img
                            src={thumbnail[index] || ""}
                            onClick={() => handleMDNDRecords(item)}
                            className="img-fluid object-cover w-100 d-block border-0"
                            //onError={handleThumbnailImageError_myvideo}
                          />
                        ) : (
                          <div onClick={() => handleMDNDRecords(item)}>
                            <Noimage />
                          </div>
                        )}
                      </div>
                      <div className="iq-preogress">
                        <div className="d-flex justify-content-between px-2 mb-1">
                          <p className="mb-0 videoViewBg">350 views</p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <div className="videoProfileImg">
                        {profilephoto[index] ? (
                          <div>
                            <img
                              src={profilephoto[index] || ""}
                              className="img-fluid"
                              style={{ width: "36px", height: "36px" }}
                              //onError={handleprofileError_myvideo}
                            />
                          </div>
                        ) : (
                          <div
                            style={{ width: "36px", height: "36px" }}
                            onClick={() => handleMDNDRecords(item)}
                          >
                            <Noimage />
                          </div>
                        )}
                      </div>
                      <div className="w-100 ms-2">
                        <h6 className="mb-0">
                          <b>{item?.video_title}</b>
                        </h6>
                        <p className="mb-0">{item?.publisher?.profile_name}</p>
                        <p className="mb-0 videoMinuts">
                          {timeDifference(item?.created_at)}
                        </p>
                      </div>
                      <div>
                        <i className="fas fa-ellipsis-v fs-5"></i>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
}
